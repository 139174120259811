import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent, AdminInnerBox } from '../../atoms/AdminPageParts';

import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';

export type SuperUserData = {
  id: string;
  userName: string;
  temporaryPassword: string;
};

export type SuperUserError = {
  type: 'UserNameAlreadyExists';
  message: string;
};

export const SuperUserForm = (props: {
  loading: boolean;
  onSubmit: (input: SuperUserData) => Promise<SuperUserError[] | void>;
  defaultData?: SuperUserData; // 新規登録は defaultDataなし
  children?: ReactElement;
  requirePassword?: boolean;
}) => {
  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
    reset,
    watch,
  } = useForm<SuperUserData>({
    defaultValues: props.defaultData,
    resolver: joiResolver(
      joi.object<SuperUserData>({
        userName: joi.string().trim().max(50).required(),
        temporaryPassword: props.requirePassword
          ? joi.string().max(50).required()
          : joi.string().max(50).allow(''),
      })
    ),
  });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.defaultData) {
      return;
    }
    const defaultData: Omit<SuperUserData, 'id'> = {
      userName: props.defaultData.userName,
      temporaryPassword: props.defaultData.temporaryPassword,
    };
    reset(defaultData);
  }, [props.defaultData, reset, setValue]);

  return (
    <Box>
      <LoadingBackdrop open={props.loading} />
      <BreadcrumbBar keyFrom="/admin/superuser" />
      <AdminContent>
        <AdminInnerBox>
          {props.defaultData ? (
            <Typography variant="h4">{`${props.defaultData.userName} ${tCommon(
              'command.edit'
            )}`}</Typography>
          ) : (
            <Typography variant="h4">{tCommon('command.new')}</Typography>
          )}
        </AdminInnerBox>
        <AdminInnerBox
          component="form"
          onSubmit={handleSubmit(async (input, ev) => {
            ev?.preventDefault();
            const errors = await props.onSubmit(input);
            if (errors != null) {
              errors.forEach((e) => {
                if (e.type === 'UserNameAlreadyExists') {
                  setError('userName', {
                    message: 'ユーザー名は既に存在します',
                  });
                }
              });
            }
          })}
          sx={{ bgcolor: (t) => t.palette.common.white }}
        >
          <Stack sx={{ maxWidth: '320px', mx: 'auto', mt: 4 }} spacing={3}>
            {/* ユーザー名 */}
            <TextField
              size="small"
              {...register('userName')}
              label={t('company.email')}
              error={'userName' in errors}
              helperText={errors.userName?.message}
              InputLabelProps={{ shrink: !!watch('userName') }}
            />
            {/* ユーザー一時パスワード */}
            <TextField
              type="password"
              size="small"
              {...register('temporaryPassword')}
              label={t('company.tempraryPassword')}
              error={'temporaryPassword' in errors}
              helperText={errors.temporaryPassword?.message}
              InputLabelProps={{ shrink: !!watch('temporaryPassword') }}
            />
          </Stack>

          <Stack direction={'row'} justifyContent={'center'} sx={{ py: 3 }}>
            <CancelButton
              onClick={() => navigate('/admin/superuser')}
              sx={{ mx: 'auto', display: 'block', m: 1 }}
            />
            <Button
              type="submit"
              sx={{ mx: 'auto', display: 'block', m: 1, width: '260px' }}
              disabled={!isDirty}
            >
              {props.defaultData
                ? tCommon('command.saveChanges')
                : tCommon('command.register')}
            </Button>
          </Stack>
        </AdminInnerBox>
        {props.children}
      </AdminContent>
    </Box>
  );
};
