import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import LogDownloadDialog from '@/components/organisms/logDownloadDialog/LogDownloadDialog';
import { useDownloadCsvByTenantCompanyIdQuery } from '@/types/graphql';

const LogDownloadByTenantCompanyIdDialog = (props: {
  onClose: () => void;
  isOpenKey: string;
  tenantCompanyId: string;
}) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);

  const result = useDownloadCsvByTenantCompanyIdQuery({
    variables: { input: { tenantCompanyId: props.tenantCompanyId } },
  });

  return (
    <LogDownloadDialog
      open={isOpen}
      onClose={props.onClose}
      loading={result.loading}
      hasError={result.error != null}
      downloadUrl={result.data?.downloadCsvByTenantCompanyId.downloadUrl}
    />
  );
};

const useLogDownloadByTenantCompanyIdDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: async (props: { tenantCompanyId: string }) => {
      await openAwaiter.open(
        <LogDownloadByTenantCompanyIdDialog
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(true)}
          {...props}
        />
      );
    },
  };
};
export default useLogDownloadByTenantCompanyIdDialog;
