import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { assertNotNull } from '@remote-voice/utilities';
import csvtojson from 'csvtojson';
import isMobile from 'is-mobile';
import fileDownload from 'js-file-download';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AddButton from '@/components/atoms/AddButton';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import DeleteButton from '@/components/atoms/DeleteButton';
import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { CSVOperationMenu } from '@/components/molecules/admin/CSVOperationMenu';
import { DeletionFooterMenu } from '@/components/molecules/admin/DeletionFooterMenu';
import { ResponsiveFormHeader } from '@/components/molecules/admin/ResponsiveFormHeader';
import { useCategoryDialog } from '@/components/organisms/categoryDialog/useCategoryDialog';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import useConfirmOkDialog from '@/components/organisms/confirmDialog/useConfirmOkDialog';
import useDropzoneDialog from '@/components/organisms/dropzoneDialog/useDropzoneDialog';
import ImportErrorDialog from '@/components/organisms/ImportErrorDialog';
import {
  ImportHomophone,
  useEditHomphoneCategoryMutation,
  useHomophoneCategoriesQuery,
  useHomophonesLazyQuery,
  useImportHomophonesMutation,
  useRemoveHomophoneCategorysMutation,
} from '@/types/graphql';
import homophoneCsvStringify from '@/utils/homophoneCsvStringify';

const HomophoneCategoryIndex = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.homophoneAdmin'));

  const homophoneCategoriesResult = useHomophoneCategoriesQuery({
    variables: { input: {} },
  });
  const [removeCategories, removeCategoriesResult] =
    useRemoveHomophoneCategorysMutation();
  const [editHomophone, editHomophoneResult] =
    useEditHomphoneCategoryMutation();
  const [getHomophones, getHomophonesResult] = useHomophonesLazyQuery();
  const [importHomophones, importHomophonesResult] =
    useImportHomophonesMutation();

  // インポート結果表示用
  const [importedHomophones, setImportedHomophones] = useState<
    ImportHomophone[] | undefined
  >();

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const categoryDialog = useCategoryDialog();

  const [isInDeleteState, setIsInDeleteState] = useState(false);
  const [deletee, setDeletee] = useState<string[]>([]);
  const confirmDelete = useConfirmDeleteDialog();
  const dropzoneDialog = useDropzoneDialog();
  const confirmOkDialog = useConfirmOkDialog();

  return (
    <Box>
      <LoadingBackdrop open={homophoneCategoriesResult.loading} />
      <AdminContent>
        <ResponsiveFormHeader
          title={t('homophone.correctionRecordList')}
          titleVarient="h4"
          toolbuttons={[
            <AddButton key="add" onClick={() => navigate('add')} />,
            // PC端末の時のみCSV操作ボタンを表示
            ...(isMobile() === false
              ? [
                  <PopupState key="csv" variant="popover">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          {t('template.csvOperation')}
                        </Button>
                        <CSVOperationMenu
                          {...bindMenu(popupState)}
                          onClickDownload={() => {
                            popupState.close();
                            // 空のCSVをダウンロード
                            fileDownload(
                              homophoneCsvStringify([]),
                              'rg-homophone.csv'
                            );
                          }}
                          onClickExport={async () => {
                            popupState.close();
                            const result = await getHomophones({
                              variables: {
                                input: {},
                              },
                            });
                            if (result.error) {
                              throw result.error;
                            }
                            assertNotNull(result.data);

                            const text = homophoneCsvStringify(
                              result.data.homophones.map((x) => ({
                                ID: x.id,
                                categoryId: x.homophoneCategory.id,
                                categoryName: x.homophoneCategory.name,
                                enabled: !x.disabled,
                                kana: x.kana,
                                sourceWord: x.sourceWord,
                                targetWord: x.targetWord,
                              }))
                            );
                            fileDownload(text, 'rg-export.csv');
                          }}
                          onClickImport={async () => {
                            popupState.close();
                            const fileList = await dropzoneDialog.open({});
                            const file = fileList?.[0];

                            if (file) {
                              const text = await file.text();
                              const records: {
                                [index: string]: string | undefined;
                              }[] = await csvtojson().fromString(text);

                              // カラムチェック
                              if (
                                records.some((record) => {
                                  const keys = Object.keys(record);
                                  const columns = [
                                    'ID',
                                    'categoryId',
                                    'categoryName',
                                    'kana',
                                    'sourceWord',
                                    'targetWord',
                                    'enabled',
                                  ];
                                  return (
                                    // 余計なカラムがあるか
                                    keys.filter(
                                      (x) =>
                                        columns.find((y) => x === y) == null
                                    ).length >= 1 ||
                                    // カラムが足りないか
                                    columns.filter(
                                      (x) => keys.find((y) => x === y) == null
                                    ).length >= 1
                                  );
                                })
                              ) {
                                showSnackbar(
                                  'error',
                                  t('template.csvImportColumnError')
                                );
                                return;
                              }

                              const result = await (async () => {
                                try {
                                  const result = await importHomophones({
                                    variables: {
                                      input: {
                                        homophones: records.map((r) => ({
                                          homophoneId: r.ID ?? '',
                                          homophoneCategoryId:
                                            r.categoryId ?? '',
                                          homophoneCategoryName:
                                            r.categoryName ?? '',
                                          kana: r.kana ?? '',
                                          sourceWord: r.sourceWord ?? '',
                                          targetWord: r.targetWord ?? '',
                                          disabled: r.enabled === '',
                                        })),
                                      },
                                    },
                                  });
                                  if (result.errors?.[0] != null) {
                                    throw result.errors[0];
                                  }
                                  return result;
                                } catch (err: any) {
                                  console.error(err);
                                  showSnackbar(
                                    'error',
                                    tCommon('message.unexpectedError')
                                  );
                                }
                              })();
                              if (result == null) {
                                return;
                              }
                              assertNotNull(result.data);

                              if (
                                result.data.importHomophones.find(
                                  (x) => x.errorCode !== 0
                                )
                              ) {
                                // 結果を表示する
                                setImportedHomophones(
                                  result.data.importHomophones
                                );
                              } else {
                                // 成功したので画面更新
                                homophoneCategoriesResult.refetch();
                                await confirmOkDialog.open({
                                  message: t('template.csvImportedMsg'),
                                  title: '',
                                });
                              }
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                  </PopupState>,
                ]
              : []),
            // 削除モードの時は削除ボタンを非表示
            ...(isInDeleteState === false
              ? [
                  <DeleteButton
                    key="remove"
                    onClick={() => setIsInDeleteState(true)}
                  />,
                ]
              : []),
          ]}
        />

        {homophoneCategoriesResult.data?.homophoneCategories.length === 0 ? (
          <Stack alignItems="center" pt={15} spacing={1}>
            <BookmarksOutlinedIcon sx={{ fontSize: 60 }} htmlColor="#D7DBDD" />
            <Typography variant="h5" color={(t) => t.palette.grey[500]}>
              {t('homophone.nocategoryTitle')}
            </Typography>
            <Typography variant="body1" color={(t) => t.palette.grey[500]}>
              {t('homophone.nocategoryDescription')}
            </Typography>
          </Stack>
        ) : (
          <>
            <Divider sx={{ mt: 4 }} />
            <MyDataGrid
              autoHeight
              columnHeaderHeight={40}
              columns={[
                {
                  field: 'name',
                  headerName: t('template.category'),
                  minWidth: 150,
                  flex: 1,
                  sortable: false,
                  renderCell: (params) => (
                    <HomophoneCategoryNameCell
                      name={params.value}
                      onClickEdit={async () => {
                        const name = await categoryDialog.open({
                          title: t('homophone.editCategoryName'),
                        });
                        if (name != null) {
                          await editHomophone({
                            variables: {
                              input: { id: params.id.toString(), name: name },
                            },
                          });
                          await homophoneCategoriesResult.refetch();
                        }
                      }}
                    />
                  ),
                },
                {
                  field: 'homophonesCount',
                  headerName: isInDeleteState ? '' : t('template.count'),
                  width: 55,
                  minWidth: 55,
                  sortable: false,
                  valueGetter: (params) =>
                    params.value + t('template.countUnit'),
                },
                ...(isInDeleteState
                  ? [
                      {
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        renderHeader: () => tCommon('command.delete'),
                      },
                    ]
                  : []),
              ]}
              sx={{
                '& .MuiDataGrid-virtualScrollerContent': {
                  backgroundColor: 'transparent',
                },
                '& .MuiDataGrid-cell[data-field="templateMessagesCount"]': {
                  justifyContent: 'flex-end',
                },
              }}
              rows={homophoneCategoriesResult.data?.homophoneCategories ?? []}
              rowHeight={60}
              hideFooter
              disableColumnMenu
              disableMultipleColumnsSorting
              onRowClick={(params) => {
                navigate(`${params.id}/`);
              }}
              onRowSelectionModelChange={(selection) =>
                setDeletee(selection.map((x) => x.toString()))
              }
              checkboxSelection={isInDeleteState}
              loading={
                removeCategoriesResult.loading ||
                editHomophoneResult.loading ||
                getHomophonesResult.loading ||
                importHomophonesResult.loading ||
                homophoneCategoriesResult.loading
              }
            />
          </>
        )}
      </AdminContent>
      {isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={deletee.length === 0}
          deleteButtonText={t('template.deleteSelectedCategory')}
          onClickDelete={async () => {
            const result = await confirmDelete.open({
              title: t('template.deleteSelectedCategoryMsg'),
              message: t('template.unbackable'),
              okColor: 'primary',
            });
            if (result) {
              await removeCategories({
                variables: { input: { ids: deletee } },
              });
              setDeletee([]);
              setIsInDeleteState(false);
              homophoneCategoriesResult.refetch();
              showSnackbar('success', t('template.deletedSelectedCategory'));
            }
          }}
          onClickCancel={() => setIsInDeleteState(false)}
        />
      )}
      <ImportErrorDialog
        open={importedHomophones != null}
        onClose={() => setImportedHomophones(undefined)}
        data={importedHomophones ?? []}
        msgToError={(message) => {
          if (message.errorCode === 0) {
            return t('template.importError.none');
          } else if (message.errorCode === 1) {
            return t('template.importError.categoryIsNull');
          } else if (message.errorCode === 2) {
            return t('template.importError.invalidCSVFormat');
          } else if (message.errorCode === 3) {
            return t('template.importError.idNotFound');
          } else if (message.errorCode === 4) {
            return t('template.importError.categoryIdNotFound');
          } else if (message.errorCode === 5) {
            return t('homophone.required');
          } else {
            return t('template.importError.other');
          }
        }}
      />
    </Box>
  );
};
export default HomophoneCategoryIndex;

const HomophoneCategoryNameCell = (props: {
  name: string;
  onClickEdit: () => void;
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <Stack
      direction="row"
      width={1}
      spacing={1}
      alignItems="center"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <FolderIcon sx={{ fontSize: '1.8rem' }} htmlColor="#9dc5f5" />
      <LineClampTypography variant="body1" rowCount={2}>
        {props.name}
      </LineClampTypography>
      {hovering && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();

            props.onClickEdit();
          }}
          size="small"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};
