import { useState, useEffect, MouseEvent } from 'react';

export const useLongPress = (callback: () => void, ms: number) => {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout | undefined;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: (e: MouseEvent<HTMLDivElement>) => {
      if (e.button === 0) {
        setStartLongPress(true);
      }
    },
    // onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
    onMouseMove: () => setStartLongPress(false),
    onTouchMove: () => setStartLongPress(false),
  };
};
