import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useMemo } from 'react';

import { Shortcut, shortcutItems, tuple } from '@/utils/chart';

export type DateRangeParams = {
  shortcut: Shortcut;
  dateRange: [number, number] | null;
};

// Mui-daterangePickerのshortcutをdaterangeに変換するためのHooks
export const useResolveDateRange = (
  initialAtom: ReturnType<typeof atomWithStorage<DateRangeParams>>
) => {
  const [srcValue, setSrcValue] = useAtom(initialAtom);

  return tuple(
    {
      shortcut: srcValue.shortcut,
      dateRange: useMemo(
        () =>
          srcValue.shortcut === 'custom'
            ? tuple(
                dayjs(srcValue.dateRange?.[0]),
                dayjs(srcValue.dateRange?.[1])
              )
            : shortcutItems[srcValue.shortcut].getValue(),
        [srcValue.dateRange, srcValue.shortcut]
      ),
    },
    useMemo(
      () => (shortcut: Shortcut, dateRange: DateRange<Dayjs>) => {
        setSrcValue({
          shortcut,
          dateRange:
            shortcut === 'custom'
              ? [
                  dateRange[0]?.valueOf() ?? Date.now(),
                  dateRange[1]?.valueOf() ?? Date.now(),
                ]
              : null,
        });
      },
      [setSrcValue]
    )
  );
};
