import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridLogicOperator,
} from '@mui/x-data-grid-pro';
import { assertNotNull } from '@remote-voice/utilities';
import csvtojson from 'csvtojson';
import fileDownload from 'js-file-download';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AddButton from '@/components/atoms/AddButton';
import AdminDataGrid from '@/components/atoms/AdminDataGrid';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import DeleteButton from '@/components/atoms/DeleteButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { CSVOperationMenu } from '@/components/molecules/admin/CSVOperationMenu';
import { DeletionFooterMenu } from '@/components/molecules/admin/DeletionFooterMenu';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import useConfirmOkDialog from '@/components/organisms/confirmDialog/useConfirmOkDialog';
import useDropzoneDialog from '@/components/organisms/dropzoneDialog/useDropzoneDialog';
import ImportErrorDialog from '@/components/organisms/ImportErrorDialog';
import {
  ImportNgWord,
  useImportNgWordsMutation,
  useNgWordsLazyQuery,
  useNgWordsQuery,
  useRemoveNgWordsMutation,
} from '@/types/graphql';
import ngWordCsvStringify from '@/utils/ngWordCsvStringify';

const NGWordCategoryIndex = () => {
  useCheckPermission({ superAdmin: true });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.ngWord'));

  const params = useParams();
  const categoryId = params.categoryId;

  const ngWordsResult = useNgWordsQuery({
    variables: { input: { categoryId } },
  });
  const [removeNgWords, removeNgWordsResult] = useRemoveNgWordsMutation();
  const [getNgWords, getNgWordsResult] = useNgWordsLazyQuery();
  const [importNgWords, importNgWordsResult] = useImportNgWordsMutation();

  // インポート結果表示用
  const [importedNgWords, setImportedNgWords] = useState<
    ImportNgWord[] | undefined
  >();

  const confirmOkDialog = useConfirmOkDialog();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const [isInDeleteState, setIsInDeleteState] = useState(false);
  const [deletee, setDeletee] = useState<string[]>([]);
  const confirmDelete = useConfirmDeleteDialog();
  const dropzoneDialog = useDropzoneDialog();

  const [searchingWord, setSearchingWord] = useState('');
  const [allSearchConditions, setAllSearchConditions] = useState<
    { field: string; value: string }[]
  >([]);

  return (
    <AdminContent>
      <LoadingBackdrop open={ngWordsResult.loading} />
      <Box bgcolor={(t) => t.palette.common.white} px={2} py={4}>
        <Typography variant="h5">NGワード一覧</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <TextField
            label="フリーワード検索"
            size="small"
            onChange={(e) => {
              setSearchingWord(e.target.value);
            }}
            onBlur={() => {
              setAllSearchConditions([{ field: 'word', value: searchingWord }]);
            }}
          />
          <Stack direction="row" spacing={1}>
            <AddButton
              key="add"
              onClick={() => navigate('/admin/ngword/add')}
            />
            <PopupState key="csv" variant="popover">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="outlined" {...bindTrigger(popupState)}>
                    {t('template.csvOperation')}
                  </Button>
                  <CSVOperationMenu
                    {...bindMenu(popupState)}
                    onClickDownload={() => {
                      popupState.close();
                      // 空のCSVをダウンロード
                      fileDownload(ngWordCsvStringify([]), 'rg-ngword.csv');
                    }}
                    onClickExport={async () => {
                      popupState.close();
                      const result = await getNgWords({
                        variables: {
                          input: {},
                        },
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      assertNotNull(result.data);

                      const text = ngWordCsvStringify(
                        result.data.ngWords.map((x) => ({
                          ID: x.id,
                          categoryId: x.ngWordCategory.id,
                          categoryName: x.ngWordCategory.name,
                          word: x.word,
                        }))
                      );
                      fileDownload(text, 'rg-export.csv');
                    }}
                    onClickImport={async () => {
                      popupState.close();
                      const fileList = await dropzoneDialog.open({});
                      const file = fileList?.[0];

                      if (file) {
                        const text = await file.text();
                        const records: {
                          [index: string]: string | undefined;
                        }[] = await csvtojson().fromString(text);

                        // カラムチェック
                        if (
                          records.some((record) => {
                            const keys = Object.keys(record);
                            const columns = [
                              'ID',
                              'categoryId',
                              'categoryName',
                              'word',
                            ];
                            return (
                              // 余計なカラムがあるか
                              keys.filter(
                                (x) => columns.find((y) => x === y) == null
                              ).length >= 1 ||
                              // カラムが足りないか
                              columns.filter(
                                (x) => keys.find((y) => x === y) == null
                              ).length >= 1
                            );
                          })
                        ) {
                          showSnackbar(
                            'error',
                            t('template.csvImportColumnError')
                          );
                          return;
                        }

                        const result = await (async () => {
                          try {
                            const result = await importNgWords({
                              variables: {
                                input: {
                                  ngWords: records.map((record) => ({
                                    ngWordId: record.ID ?? '',
                                    ngWordCategoryId: record.categoryId ?? '',
                                    ngWordCategoryName:
                                      record.categoryName ?? '',
                                    word: record.word ?? '',
                                  })),
                                },
                              },
                            });
                            if (result.errors?.[0] != null) {
                              throw result.errors[0];
                            }
                            return result;
                          } catch (err: any) {
                            console.error(err);
                            showSnackbar(
                              'error',
                              tCommon('message.unexpectedError')
                            );
                          }
                        })();
                        if (result == null) {
                          return;
                        }
                        assertNotNull(result.data);

                        if (
                          result.data.importNgWords.find(
                            (x) => x.errorCode !== 0
                          )
                        ) {
                          // 結果を表示する
                          setImportedNgWords(result.data.importNgWords as any);
                        } else {
                          // 成功したので画面更新
                          ngWordsResult.refetch();
                          await confirmOkDialog.open({
                            message: t('template.csvImportedMsg'),
                            title: '',
                          });
                        }
                      }
                    }}
                  />
                </React.Fragment>
              )}
            </PopupState>
            {isInDeleteState === false && (
              <DeleteButton
                key="remove"
                onClick={() => setIsInDeleteState(true)}
              />
            )}
          </Stack>
        </Stack>
        <Divider sx={{ mt: 4 }} />
        <AdminDataGrid
          autoHeight
          columnHeaderHeight={40}
          columns={[
            {
              field: 'word',
              headerName: 'NGワード',
              minWidth: 150,
              flex: 1,
              sortable: false,
            },
            isInDeleteState
              ? {
                  ...GRID_CHECKBOX_SELECTION_COL_DEF,
                  renderHeader: () => tCommon('command.delete'),
                }
              : {
                  field: 'edit',
                  headerName: '編集',
                  type: 'actions',
                  getActions: ({ id }) => [
                    <GridActionsCellItem
                      key={id}
                      icon={<EditIcon />}
                      label="edit"
                      onClick={() =>
                        navigate(`/admin/ngword/${categoryId}/${id}`)
                      }
                    />,
                  ],
                },
          ]}
          sx={{
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'transparent',
            },
          }}
          rows={ngWordsResult.data?.ngWords ?? []}
          rowHeight={60}
          hideFooter
          disableColumnMenu
          disableMultipleColumnsSorting
          onRowSelectionModelChange={(selection) =>
            setDeletee(selection.map((x) => x.toString()))
          }
          checkboxSelection={isInDeleteState}
          loading={
            removeNgWordsResult.loading ||
            getNgWordsResult.loading ||
            importNgWordsResult.loading ||
            ngWordsResult.loading
          }
          filterModel={{
            items: allSearchConditions.map((condition, index) => ({
              id: index,
              field: condition.field,
              operator: 'contains',
              value: condition.value,
            })),
            logicOperator: GridLogicOperator.And,
          }}
        />
      </Box>

      {isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={deletee.length === 0}
          deleteButtonText="選択したNGワードを削除する"
          onClickDelete={async () => {
            const result = await confirmDelete.open({
              title: '選択したNGワードを削除しますか？',
              message: '削除後は元に戻すことはできません。',
              okColor: 'primary',
            });
            if (result) {
              await removeNgWords({
                variables: { input: { ids: deletee } },
              });
              setDeletee([]);
              setIsInDeleteState(false);
              ngWordsResult.refetch();
              showSnackbar('success', '選択したNGワードを削除しました');
            }
          }}
          onClickCancel={() => setIsInDeleteState(false)}
        />
      )}
      <ImportErrorDialog
        open={importedNgWords != null}
        onClose={() => setImportedNgWords(undefined)}
        data={importedNgWords ?? []}
        msgToError={(message: ImportNgWord) => {
          if (message.errorCode === 0) {
            return t('template.importError.none');
          } else if (message.errorCode === 1) {
            return t('template.importError.categoryIsNull');
          } else if (message.errorCode === 2) {
            return t('template.importError.invalidCSVFormat');
          } else if (message.errorCode === 3) {
            return t('template.importError.idNotFound');
          } else if (message.errorCode === 4) {
            return t('template.importError.categoryIdNotFound');
          } else if (message.errorCode === 5) {
            return t('template.importError.messageNotSpecified');
          } else {
            return t('template.importError.other');
          }
        }}
      />
    </AdminContent>
  );
};
export default NGWordCategoryIndex;
