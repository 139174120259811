import { useAtomValue } from 'jotai';

import { openableElementsAtom } from '../hooks/useOpenableElement';

const OpenableElementsProvider = () => {
  const elements = useAtomValue(openableElementsAtom);
  return (
    <>
      {elements.map((x) => (
        <div key={x.key}>{x.element}</div>
      ))}
    </>
  );
};

export default OpenableElementsProvider;
