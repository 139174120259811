import { assertNotNull } from '@remote-voice/utilities';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { ChatRoomData, RoomForm } from '@/components/molecules/admin/RoomForm';
import {
  ChatSessionState,
  useAddChatRoomMutation,
  useAddChatSessionMutation,
  useOwnerCompaniesQuery,
} from '@/types/graphql';

const RoomAdd = () => {
  const permission = useCheckPermission({ tenantCompanyAdmin: true });

  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('admin');

  useSetHeaderTitle(commonT('breadcrumb.roomAdd'));

  const snackbar = useSnackbar();

  const [addChatRoom, addChatRoomResult] = useAddChatRoomMutation();
  const [addChatSession, addChatSessionResult] = useAddChatSessionMutation();
  const ownerCompanyResult = useOwnerCompaniesQuery({
    variables: { input: {} },
  });

  const navigate = useNavigate();

  const onSubmit = async (input: ChatRoomData) => {
    const tenantCompanyId = permission.tenantCompanyId;
    assertNotNull(tenantCompanyId);

    const { entryCode, ...chatRoomInput } = input;
    const chatRoom = await addChatRoom({
      variables: {
        input: {
          tenantCompanyId: tenantCompanyId,
          ...chatRoomInput,
        },
      },
    });
    if (chatRoom.errors) throw chatRoom.errors;
    assertNotNull(chatRoom.data);

    if (chatRoom.data.addChatRoom.__typename === 'ChatRoom') {
      const chatSession = await addChatSession({
        variables: {
          input: {
            name: chatRoom.data.addChatRoom.name,
            chatRoomId: chatRoom.data?.addChatRoom.id,
            entryCode: entryCode,
            state: ChatSessionState.NotStarted,
          },
        },
      });
      if (chatSession.errors) throw chatSession.errors;

      navigate('/admin/room');
      snackbar('success', commonT('message.registered'));
    } else if (
      chatRoom.data.addChatRoom.__typename === 'ExcessRoomCreationLimit'
    ) {
      snackbar('error', t('room.excessRoomCreationLimitErrorMsg'));
    }
  };

  return (
    <RoomForm
      onSubmit={onSubmit}
      loading={
        addChatRoomResult.loading ||
        addChatSessionResult.loading ||
        ownerCompanyResult.loading
      }
      canUseSimulteneous={
        ownerCompanyResult.data?.ownerCompanies[0]?.canUseSimulteneous ?? false
      }
    />
  );
};
export default RoomAdd;
