import { assertNotNull } from '@remote-voice/utilities';
import { useCallback, useEffect, useRef } from 'react';

import { convertHeicToJpeg } from '@/utils/convertHeicToJpeg';

const useOpenFile = () => {
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  useEffect(() => {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    document.body.appendChild(input);
    inputRef.current = input;
    return () => {
      document.body.removeChild(input);
      input.remove();
    };
  }, []);

  const open = useCallback(
    (props: {
      accept?: string;
      multiple?: boolean;
      onOpen: (fileArray: File[]) => void;
    }) => {
      const input = inputRef.current;
      assertNotNull(input);
      input.accept = props.accept ?? '*';
      input.value = '';
      input.multiple = props.multiple ?? false;
      input.onchange = async (ev: any) => {
        const files = (ev as React.ChangeEvent<HTMLInputElement>).currentTarget
          .files;
        if (files != null) {
          Promise.all(
            Array.from(files).map(async (f) => {
              if (f.type === 'image/heic') {
                return await convertHeicToJpeg(f);
              }
              return f;
            })
          ).then((fileArray) => props.onOpen(fileArray));
        }
      };
      input.click();

      // 本当はawait式にしたかったけど、キャンセルが拾えないので諦め
    },
    []
  );
  return open;
};
export default useOpenFile;
