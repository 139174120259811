import { useTranslation } from 'react-i18next';

const useDisplayLanguage = () => {
  const { t: tCommon } = useTranslation('common');

  return (language: string) => {
    // stringでは型エラーになってしまうため、switchで限定
    switch (language) {
      case 'ja':
      case 'en':
      case 'ko':
      case 'zh':
      case 'zh-TW':
      case 'es':
      case 'fr':
      case 'id':
      case 'my':
      case 'th':
      case 'vi':
      case 'pt-BR':
      case 'fil':
        return tCommon(`languages.${language}`);
      default:
        return language;
    }
  };
};

export default useDisplayLanguage;
