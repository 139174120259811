import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useTenantCompaniesQuery } from '@/types/graphql';

const DynamicTenantBreadcrumbs = (props: BreadcrumbComponentProps) => {
  const tenant = useTenantCompaniesQuery({
    variables: {
      input: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: props.match.params.tenantId!,
      },
    },
    skip: props.match.params.tenantId == null,
  });

  return <>{tenant.data?.tenantCompanies[0].guideManagerName}</>;
};

export default DynamicTenantBreadcrumbs;
