import { joiResolver } from '@hookform/resolvers/joi';
import AddIcon from '@mui/icons-material/Add';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Joi from 'joi';
import Enumerable from 'linq';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { useEditDataGridCellOnSingleClick } from '@/components/hooks/useEditDataGridCellOnSingleClick';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import { useHomophoneKanaDialog } from '@/components/organisms/homophoneKanaDialog/useHomophoneKanaDialog';
import {
  useEditChatMessageFlagMutation,
  useFlagMessagesQuery,
  useHomophoneCategoriesQuery,
} from '@/types/graphql';

export type HomophoneData = {
  id?: string;
  kana: string;
  sourceWord: string;
  targetWord: string;
};

export type HomophoneFormData = {
  categoryId: string;
  categoryName: string;
  homophones: (HomophoneData & { disabled: boolean })[];
};

export const HomophoneForm = (props: {
  loading: boolean;
  onSubmit: (input: HomophoneFormData) => Promise<void>;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');

  const homophoneCategory = useHomophoneCategoriesQuery({
    variables: { input: {} },
  });
  const flagMessages = useFlagMessagesQuery();
  const [setFlag, setFlagResult] = useEditChatMessageFlagMutation();

  const joi = useLocalizedJoi();
  const form = useForm<HomophoneFormData>({
    resolver: joiResolver(
      joi
        .object<HomophoneFormData>({
          categoryName: Joi.string().trim().max(100).required(),
          homophones: Joi.array()
            .items(
              Joi.object({
                kana: Joi.string().trim().max(100).required(),
                sourceWord: Joi.string().trim().max(50).required(),
                targetWord: Joi.string().trim().max(50).required(),
              }).unknown(true)
            )
            .required(),
        })
        .unknown(true)
    ),
    defaultValues: {
      categoryId: '',
      categoryName: '',
      homophones: [
        {
          kana: '',
          sourceWord: '',
          targetWord: '',
          disabled: false,
        },
      ],
    },
  });

  const { cellModesModel, handleCellClick, handleCellModesModelChange } =
    useEditDataGridCellOnSingleClick();

  const homophoneKanaDialog = useHomophoneKanaDialog();

  return (
    <>
      <LoadingBackdrop
        open={
          homophoneCategory.loading ||
          flagMessages.loading ||
          setFlagResult.loading
        }
      />
      <Box
        component="form"
        onSubmit={form.handleSubmit(async (input, ev) => {
          ev?.preventDefault();

          let homophones = input.homophones;
          // 同じ読みの項目があればダイアログを表示
          const sameKanaEntries = Enumerable.from(homophones)
            .groupBy((x) => x.kana)
            .toArray()
            .filter((x) => x.count() >= 2)
            .map((x) =>
              // ２番目以降の要素は無効にしておく
              x.toArray().map((y, i) => ({
                ...y,
                disabled: i >= 1,
              }))
            )
            .flat();
          if (sameKanaEntries.length > 0) {
            const results = await homophoneKanaDialog.open({
              defaultData: sameKanaEntries,
            });
            if (results == null) return;
            else
              homophones = [
                ...homophones.filter(
                  (h) => results.find((r) => r.kana === h.kana) == null
                ),
                ...results,
              ];
          }
          props.onSubmit({
            ...input,
            homophones,
          });
        })}
        sx={{
          height: 'calc(100vh - 56px)', // AdminLayout のコンテンツ部がHight100%ではないので、タイトルバーの分を引いて高さを計算する
          display: 'grid',
          // バックドロップ＋それ以下
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <BreadcrumbBar keyFrom="/admin/homophone" maxWidth={1} />
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            display: 'grid',
            gridTemplateColumns: '280px 1fr',
          }}
        >
          <Box
            // ラベル一覧
            sx={{ height: '100%', overflow: 'auto' }}
            bgcolor={(t) => t.palette.background.paper}
            borderRight={(t) => `1px solid ${t.palette.divider}`}
          >
            <Stack p={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Stack direction="row">
                  <BookmarkIcon color="primary" />
                  <Typography variant="h6">
                    {t('homophone.selectedSentence')}
                  </Typography>
                </Stack>
                <Typography variant="body1">
                  {flagMessages.data?.flagMessages.length ?? ''}
                  {t('template.countUnit')}
                </Typography>
              </Stack>
              <Divider />

              {flagMessages.data != null &&
              flagMessages.data.flagMessages.length >= 1 ? (
                <List sx={{ mr: -2 }}>
                  {flagMessages.data?.flagMessages.map((msg) => (
                    <HomophoneListItem
                      key={msg.id}
                      roomName={msg.chatRoomName}
                      text={
                        msg.languages.find((l) => l.language === 'ja')
                          ?.message ?? ''
                      }
                      onClickDelete={async () => {
                        await setFlag({
                          variables: {
                            input: {
                              chatMessageId: msg.id.toString(),
                              isMessageFlag: false,
                            },
                          },
                        });
                        await flagMessages.refetch();
                      }}
                    />
                  ))}
                </List>
              ) : (
                <Stack alignItems="center" pt={5}>
                  <BookmarkAddOutlinedIcon
                    sx={{ fontSize: 60 }}
                    htmlColor="#D7DBDD"
                  />
                  <Typography
                    variant="body1"
                    color={(t) => t.palette.grey[500]}
                  >
                    {t('homophone.nolabel')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>

          <Box // 右サイド
            sx={{
              height: 1,
              display: 'grid',
              gridTemplateRows: 'auto 1fr auto',
            }}
          >
            <Stack p={4} spacing={3} width={1} alignItems="flex-start">
              <Typography variant="h4">{commonT('command.new')}</Typography>
              <TextField
                {...form.register('categoryName')}
                label={t('template.categoryName')}
                error={'categoryName' in form.formState.errors}
                helperText={form.formState.errors.categoryName?.message}
                name={'categoryName'}
                InputLabelProps={{ shrink: !!form.watch('categoryName') }}
              />
            </Stack>
            <MyDataGrid
              sx={{
                width: 'calc(100vw - 286px)', // 親へのパーセント指定がなぜか効かないので全体から計算
                px: 4,
                '& .MuiDataGrid-columnHeaders': {
                  bgcolor: (t) => t.palette.secondary.main,
                },
                '& .MuiDataGrid-cell': {
                  bgcolor: (t) => t.palette.background.paper,
                },
              }}
              hideFooter
              columnHeaderHeight={40}
              columns={[
                {
                  field: 'kana',
                  headerName: t('homophone.reading'),
                  editable: true,
                  flex: 1,
                },
                {
                  field: 'sourceWord',
                  headerName: t('homophone.sourceWord'),
                  editable: true,
                  flex: 1,
                },
                {
                  field: 'targetWord',
                  headerName: t('homophone.targetWord'),
                  editable: true,
                  flex: 1,
                },
              ]}
              rows={
                form.watch('homophones')?.map((r, i) => ({
                  // 配列のインデックスを表内でのみIDとする
                  id: i.toString(),
                  kana: r.kana,
                  sourceWord: r.sourceWord,
                  targetWord: r.targetWord,
                })) ?? []
              }
              processRowUpdate={(newRow) => {
                form.setValue(
                  'homophones',
                  form.getValues('homophones').map((h, i) =>
                    i.toString() === newRow.id
                      ? {
                          kana: newRow.kana,
                          sourceWord: newRow.sourceWord,
                          targetWord: newRow.targetWord,
                          disabled: false,
                        }
                      : h
                  ),
                  {
                    shouldDirty: true,
                  }
                );
                return newRow;
              }}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellClick={(params, ev) => handleCellClick(params as any, ev)}
            />
            <Stack // フッター。新規ボタン・キャンセル・保存
              width={1}
              spacing={3}
              pt={2}
            >
              <Stack px={4} width={1} alignItems="flex-start">
                <Button
                  variant="text"
                  onClick={() => {
                    form.setValue(
                      'homophones',
                      form.getValues().homophones.concat([
                        {
                          kana: '',
                          sourceWord: '',
                          targetWord: '',
                          disabled: false,
                        },
                      ]),
                      { shouldDirty: true }
                    );
                  }}
                >
                  <AddIcon />
                  <Typography textAlign="left">
                    {commonT('command.new')}
                  </Typography>
                </Button>
              </Stack>
              <Stack
                width={1}
                direction="row"
                spacing={2}
                py={2}
                justifyContent="center"
                bgcolor="rgba(255, 255, 255, 0.6)"
              >
                <CancelButton onClick={() => navigate('/admin/homophone')} />
                <Button
                  variant="contained"
                  sx={{ flex: 1, maxWidth: 300 }}
                  disabled={!form.formState.isDirty}
                  type="submit"
                >
                  {commonT('command.saveChanges')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const HomophoneListItem = (props: {
  roomName: string;
  text: string;
  onClickDelete: () => void;
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <ListItem
      secondaryAction={
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();

            props.onClickDelete();
          }}
          size="small"
          sx={{ visibility: hovering ? 'visible' : 'hidden' }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      disablePadding
      disableGutters
    >
      <ListItemText
        primary={
          <Typography variant="subtitle2" color={(t) => t.palette.grey[500]}>
            {props.roomName}
          </Typography>
        }
        secondary={<Typography variant="body1">{props.text}</Typography>}
        sx={{ mr: 3 }}
      />
    </ListItem>
  );
};
