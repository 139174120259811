import { sleep } from '@remote-voice/utilities';
import Enumerable from 'linq';
import { useEffect, useRef, useState } from 'react';

import MiniTranslationHandler from '@/utils/MiniTranslationHandler';

import Vad from '@/utils/Vad';

export type SegmentType = 'chunk' | 'sentence' | 'chunkAndSentence';
export type OnDataReceivedValue = {
  isTyping: boolean;
  languages: {
    language: string;
    message: string;
  }[];
  utteranceId: number;
};
export type OnDataReceived = (value: OnDataReceivedValue) => void;

// 最大文字を超えるようであれば分割する
const maxMessageLength = 1000;

const useMiniTranslation = (props: { miniApiToken?: string }) => {
  const { miniApiToken } = props;

  /**
   * VADが終了とみなしてから発言が終わったとみなすまでのミリ秒
   */
  const sendTimerMs = 500;

  /**
   * Mini APIの接続モジュールへのリファレンス
   */
  const miniApiRef = useRef<MiniTranslationHandler>();

  /**
   * VADモジュールへのリファレンス
   */
  const vadRef = useRef<Vad>();

  /**
   * のMediaStreamへのリファレンス
   */
  const streamRef = useRef<MediaStream>();

  /**
   * VADが終了を検知してから，発言が終わったとみなすまでのタイマー
   */
  const speechTimeoutRef = useRef<number>(0);

  /**
   * 発言中 OR NOT
   */
  const [speeching, setSpeeching] = useState(false);

  /**
   * クリーンナップ
   */
  useEffect(() => {
    return () => endVoiceInput();
  }, []);

  /**
   * 音声入力の可否を判断する
   */
  const [availableVoiceInput, setAvailableVoiceInput] = useState(false);
  useEffect(() => {
    if (miniApiToken) {
      setAvailableVoiceInput(true);
    } else {
      setAvailableVoiceInput(false);
    }
  }, [miniApiToken]);

  // utteranceId毎に全てのメッセージを蓄積し、認識と翻訳が全て終わった段階で、isTypingじゃないデータを送る。

  // utteranceIdがサイクルするが、外部に伝えるIDはサイクルさせないためのオフセット
  const utteranceIdOffset = useRef(0);
  const utteranceIdMax = useRef(0);

  type Utterance = {
    languages: {
      language: string;
      message: string;
      isSentence?: boolean;
      isComplete: boolean;
    }[];
  };

  // key = utteranceId
  const utteranceDictionaly = useRef<{
    [key: string]: Utterance;
  }>({});

  const addAndSendMessage = (value: {
    utteranceId: number;
    message: string;
    language: string;
    isSentence?: boolean;
    isComplete: boolean;
    segmentType: SegmentType;
    allLanguages: string[];
    onDataReceived: OnDataReceived;
  }) => {
    if (value.message.length > maxMessageLength) {
      throw new Error(
        'It is not possible to exceed the upper limit with one input'
      );
    }
    const segmentType = value.segmentType;
    const utteranceId = value.utteranceId + utteranceIdOffset.current;

    // 既存データの取得と生成
    let utterance = utteranceDictionaly.current[utteranceId];
    if (utterance == null) {
      utterance = {
        languages: [],
      };
      utteranceDictionaly.current[utteranceId] = utterance;
    }
    let lang = utterance.languages.find(
      (x) => x.language === value.language && x.isSentence === value.isSentence
    );
    if (lang == null) {
      lang = {
        message: '',
        language: value.language,
        isSentence: value.isSentence,
        isComplete: false,
      };
      utterance.languages.push(lang);
    }

    // 言語によって、認識結果の前後スペースが失われてしまうので追加
    const separator =
      value.language === 'en' || value.language === 'ko' ? ' ' : '';
    const newMessage = lang.message.trim() + separator + value.message.trim();

    // メッセージの追記で上限を超えてしまうかチェック
    if (newMessage.length > maxMessageLength) {
      // 上限を超えていたら、メッセージは追記せずに、ここまでのデータを一度送信する。
      if (utteranceIdMax.current < utteranceId) {
        utteranceIdMax.current = utteranceId;
      }
      // 本来のutteranceIdから1つずらす
      utteranceIdOffset.current++;

      // 現在のデータを送信
      value.onDataReceived({
        languages: value.allLanguages
          .map(
            (l) =>
              utterance.languages.find(
                (x) =>
                  x.language === l &&
                  (x.isSentence == null ||
                    (x.isSentence === true &&
                      (segmentType === 'sentence' ||
                        segmentType === 'chunkAndSentence')) ||
                    (x.isSentence === false && segmentType === 'chunk'))
              ) ?? {
                message: '',
                language: l,
              }
          )
          .map((x) => ({
            message: x.message,
            language: x.language,
          })),
        isTyping: false,
        utteranceId: utteranceId,
      });
      // 次のutteranceIdにコピーしてメッセージだけ削除
      utteranceDictionaly.current[utteranceId + 1] =
        utteranceDictionaly.current[utteranceId];
      for (const l of utteranceDictionaly.current[utteranceId + 1].languages) {
        l.message = '';
      }
      // 完了した utterance は削除
      delete utteranceDictionaly.current[utteranceId];
      // 自信を再度呼び出してロジックを抜ける
      addAndSendMessage(value);
      return;
    } else {
      lang.message = newMessage;
    }

    if (value.isComplete === false) {
      // 完了形以外の送信
      if (
        value.isSentence == null ||
        (value.isSentence === true && segmentType === 'sentence') ||
        (value.isSentence === false &&
          (segmentType === 'chunk' || segmentType === 'chunkAndSentence'))
      ) {
        if (utteranceIdMax.current < utteranceId) {
          utteranceIdMax.current = utteranceId;
        }
        value.onDataReceived({
          languages: [
            {
              message: lang.message,
              language: lang.language,
            },
          ],
          isTyping: true,
          utteranceId: utteranceId,
        });
      }
    } else {
      lang.isComplete = true;

      // 完了形の送信は、全ての言語が完了したらまとめて行う
      const completedLanguages = utterance.languages.filter(
        (x) =>
          x.isComplete &&
          (x.isSentence == null ||
            (x.isSentence === true &&
              (segmentType === 'sentence' ||
                segmentType === 'chunkAndSentence')) ||
            (x.isSentence === false && segmentType === 'chunk'))
      );
      const restLanguages = Enumerable.from(value.allLanguages)
        .except(completedLanguages.map((x) => x.language))
        .toArray();
      if (restLanguages.length === 0) {
        if (utteranceIdMax.current < utteranceId) {
          utteranceIdMax.current = utteranceId;
        }
        value.onDataReceived({
          languages: completedLanguages.map((x) => ({
            message: x.message,
            language: x.language,
          })),
          isTyping: false,
          utteranceId: utteranceId,
        });
        // 完了した utterance は削除
        delete utteranceDictionaly.current[utteranceId];
      }
    }
  };

  /**
   * 音声入力を開始する
   */
  const startVoiceInput = async (params: {
    sourceLanguage: string;
    targetLanguages: string[];
    segmentType: SegmentType;
    onDataReceived: OnDataReceived;
    onSendSlowError: () => void;
    deviceId?: string;

    smoothingTimeConstant?: number;
    energyOffset?: number;
    energyThresholdRatioPos?: number;
    energyThresholdRatioNeg?: number;
    energyIntegration?: number;
  }) => {
    if (miniApiToken === undefined) return;
    utteranceDictionaly.current = {};
    utteranceIdOffset.current = utteranceIdMax.current;

    try {
      // インスタンス化されていなければ作成
      if (miniApiRef.current === undefined) {
        miniApiRef.current = new MiniTranslationHandler();
      }

      // マイクを有効化
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          deviceId: params.deviceId,
          sampleRate: 48000,
        },
      });
      // VADを有効化
      vadRef.current = new Vad({
        // 音声入力開始を検知した場合
        voiceStart: () => {
          setSpeeching(true);
          clearTimeout(speechTimeoutRef.current);
        },
        // 音声入力終了を検知した場合
        voiceStop: () => {
          speechTimeoutRef.current = window.setTimeout(() => {
            miniApiRef.current?.breakSendMediaStream();
          }, sendTimerMs);
        },
        context: Howler.ctx,
        source: Howler.ctx.createMediaStreamSource(streamRef.current),

        smoothingTimeConstant: params.smoothingTimeConstant,
        energyOffset: params.energyOffset,
        energyThresholdRatioPos: params.energyThresholdRatioPos,
        energyThresholdRatioNeg: params.energyThresholdRatioNeg,
        energyIntegration: params.energyIntegration,
      });

      // vadRef.current.start();
      await miniApiRef.current?.startSendMediaStream({
        stream: streamRef.current,
        sourceLanguage: params.sourceLanguage,
        targetLanguages: params.targetLanguages,
        accessToken: miniApiToken,
        onDataReceivedCallback: (result) => {
          if (
            result.status === 'interpret-recog-partial-result' ||
            result.status === 'interpret-recog-result'
          ) {
            addAndSendMessage({
              utteranceId: result.utteranceId,
              isComplete: result.status === 'interpret-recog-result',
              language: result.sourceLang,
              message: result.result,
              onDataReceived: params.onDataReceived,
              segmentType: params.segmentType,
              allLanguages: [params.sourceLanguage, ...params.targetLanguages],
            });
          } else if (
            result.status === 'interpret-trans-partial-result' ||
            result.status === 'interpret-trans-result'
          ) {
            addAndSendMessage({
              utteranceId: result.utteranceId,
              isComplete: result.status === 'interpret-trans-result',
              language: result.targetLang,
              message: result.result,
              isSentence: result.segmentType === 'sentence',
              onDataReceived: params.onDataReceived,
              segmentType: params.segmentType,
              allLanguages: [params.sourceLanguage, ...params.targetLanguages],
            });
          }
        },
        onSendSlowError: params.onSendSlowError,
      });

      // 音声を1つも送信せずに停止した時、結果が何も返ってこない。その処理を書くのが面倒なので、準備中期間として 0.5秒 追加する。
      await sleep(500);
    } catch (e: any) {
      setAvailableVoiceInput(false);
      endVoiceInput();
      throw e;
    }
  };

  /**
   * 音声入力を終了する
   */
  const endVoiceInput = () => {
    // API切断
    miniApiRef.current?.endSendMediaStream();
    miniApiRef.current = undefined;
    // VAD無効化
    vadRef.current?.pause();
    vadRef.current = undefined;
    // マイクの無効化
    streamRef.current?.getTracks().forEach((t) => t.stop());
    streamRef.current = undefined;
  };

  return {
    /**
     * 音声入力の可否
     */
    availableVoiceInput,
    /**
     * 音声入力の状態
     */
    speeching,
    /**
     * 音声入力を開始する
     */
    startVoiceInput,
    /**
     * 音声入力を終了する
     */
    endVoiceInput,
  };
};

export default useMiniTranslation;
