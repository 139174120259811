import { Box } from '@mui/material';

export const ReactionQuestionIcon = (props: {
  width: number;
  height: number;
}) => {
  return (
    <Box
      width={`${props.width}px`}
      height={`${props.height}px`}
      sx={{
        background: `url(/assets/questionIcon.svg)`,
        backgroundSize: `${props.width}px ${props.height}px`,
      }}
    ></Box>
  );
};
