import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { LineChart, PieChart } from '@mui/x-charts';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { assertNotNull } from '@remote-voice/utilities/lib/guard';
import dayjs from 'dayjs';
import { atomFamily, atomWithStorage } from 'jotai/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminDataGrid, {
  customPagination,
} from '@/components/atoms/AdminDataGrid';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import {
  DateRangeParams,
  useResolveDateRange,
} from '@/components/hooks/useResolveDateRange';
import { PieCenterLabel } from '@/components/molecules/PieCenterLabel';
import { AnalyticsRoomDetailTooltip } from '@/components/organisms/tooltip/AnalyticsRoomDetailTooltip';
import { AnalyticsUserCountTooltip } from '@/components/organisms/tooltip/AnalyticsUserCountTooltip';
import { useAnalyticsUserCountMulti } from '@/components/templates/analytics/useAnalyticsUserCountMulti';
import {
  AnalyticsRoom,
  Interval,
  useAnalyticsUsageByTimeQuery,
  useAnalyticsUserLanguageQuery,
} from '@/types/graphql';
import {
  dateRangePickerCustmize,
  formatDate,
  pieChartCustomize,
  pieChartSeriesCustmize,
  Shortcut,
  shortcutItems,
} from '@/utils/chart';

// AtomFamily動的にキー名を指定できるようにする
const dateRangeParamsAtomFamily = atomFamily((ownerCompanyId: string) =>
  atomWithStorage<DateRangeParams>(`dateRangeParamsOwner_${ownerCompanyId}`, {
    shortcut: 'today',
    dateRange: null,
  })
);

const OwnerAnalyticsTemplate = (props: { id: string; companyName: string }) => {
  // Util関連
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.ownerAdmin'));
  const ownerId = props.id;
  const openAwaiter = useOpenAwaiter();

  // デイトピッカー
  const [dateRangeParams, setDateRangeParams] = useResolveDateRange(
    dateRangeParamsAtomFamily(ownerId)
  );
  const dateStart = dayjs(dateRangeParams.dateRange[0]).toISOString();
  const dateEnd = dayjs(dateRangeParams.dateRange[1]).toISOString();

  // ユーザー数折れ線グラフ
  const userCount = useAnalyticsUserCountMulti({
    dateStart,
    dateEnd,
    ownerCompanyIds: useMemo(() => [ownerId], [ownerId]),
    skip: dateStart === '' || dateEnd === '' || ownerId === '',
  });
  const xAxisData = userCount.results?.[0]?.result.data?.analyticsUserCount;

  // 累計利用者数
  const sumUserCount = userCount.results?.every((x) => x.result.data != null)
    ? userCount.results?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (a, b) => a + b.result.data!.analyticsUserCount.sumUserCount,
        0
      )
    : undefined;

  // 時間ごと利用者数推移
  const usageByTime = useAnalyticsUsageByTimeQuery({
    variables: {
      input: { dateRange: { dateStart, dateEnd }, ownerCompanyId: ownerId },
    },
    skip: dateStart === '' || dateEnd === '' || ownerId === '',
  });
  const usageByTimeData = usageByTime.data?.analyticsUsageByTime.data;

  // ルーム詳細
  const [isDrillDowned, setIsDrillDowned] = useState(false);

  // 言語円グラフ
  const languageCount = useAnalyticsUserLanguageQuery({
    variables: {
      input: { dateRange: { dateStart, dateEnd }, ownerCompanyId: ownerId },
    },
    skip: dateStart === '' || dateEnd === '' || ownerId === '',
  });
  const languageCountData = languageCount.data?.analyticsUserLanguage;
  const languageCountSum = languageCountData?.reduce((a, b) => a + b.count, 0);

  return (
    <>
      <Stack
        p={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="body2" color={(t) => t.palette.grey[600]}>
          {shortcutItems[dateRangeParams.shortcut].label}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs as any}>
          <DateRangePicker
            {...dateRangePickerCustmize}
            sx={{ flex: 1 }}
            value={dateRangeParams.dateRange}
            onChange={(value, context) => {
              const shortcut = Object.entries(shortcutItems).find(
                ([_, value]) => value.label === context.shortcut?.label
              )?.[0] as Shortcut;
              setDateRangeParams(shortcut ?? 'custom', value);
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Divider />

      <Stack p={2} spacing={2}>
        <Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">利用者数</Typography>
            <Typography
              variant="body2"
              p={1}
              sx={{
                backgroundColor: (t) => t.palette.grey[200],
                borderRadius: 2,
              }}
            >
              {'累計利用者数 '}
              <b>{sumUserCount}</b>人
            </Typography>
          </Stack>
          <LineChart
            height={300}
            tooltip={{
              trigger: 'axis',
              axisContent: (props) => (
                <AnalyticsUserCountTooltip
                  {...props}
                  interval={xAxisData?.interval ?? Interval.Dayly}
                />
              ),
            }}
            series={
              userCount.results?.map((x) => ({
                curve: 'linear',
                showMark: false,
                color: '#59A6F4',
                data:
                  x.result.data?.analyticsUserCount.data.map((d) => d.count) ??
                  [],
              })) ?? []
            }
            xAxis={[
              {
                data: xAxisData?.data.map((d) => d.date) ?? [],
                scaleType: 'point',
                valueFormatter: (val) => {
                  const interval = xAxisData?.interval;
                  assertNotNull(interval);
                  return formatDate(val, interval);
                },
              },
            ]}
          />
        </Stack>

        {isDrillDowned === false && (
          <Stack spacing={1}>
            <Typography variant="h6">{`${props.companyName}の利用者数・ルーム数`}</Typography>
            <AdminDataGrid
              pageSizeOptions={[10]}
              autoHeight
              hideFooter
              slots={{
                pagination: customPagination,
              }}
              onRowClick={(params) => {
                if (params.id === '累積利用ルーム数') {
                  setIsDrillDowned(true);
                }
              }}
              columns={[
                {
                  field: 'id',
                  headerName: '',
                  width: 150,
                },
                ...(usageByTimeData?.map((x) => ({
                  field: x.date,
                  headerName: formatDate(
                    x.date,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    usageByTime.data!.analyticsUsageByTime.interval
                  ),
                  width: 75,
                })) ?? []),
              ]}
              rows={[
                {
                  id: '最大同時利用者数',
                  ...Object.fromEntries(
                    usageByTimeData?.map((x) => [x.date, x.maxUserCount]) ?? []
                  ),
                },
                {
                  id: '累積利用者数',
                  ...Object.fromEntries(
                    usageByTimeData?.map((x) => [x.date, x.sumUserCount]) ?? []
                  ),
                },
                {
                  id: '累積利用ルーム数',
                  ...Object.fromEntries(
                    usageByTimeData?.map((x) => [
                      x.date,
                      x.activeRooms.length,
                    ]) ?? []
                  ),
                },
              ]}
            />
          </Stack>
        )}
        {isDrillDowned === true && (
          <Stack spacing={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">{`${props.companyName}のルーム詳細`}</Typography>
              <Button
                variant="text"
                startIcon={<ArrowCircleUpIcon />}
                onClick={() => setIsDrillDowned(false)}
              >{`${props.companyName}の利用者数・ルーム数へ`}</Button>
            </Stack>
            <AdminDataGrid
              pageSizeOptions={[10]}
              autoHeight
              hideFooter
              slots={{
                pagination: customPagination,
              }}
              columns={[
                {
                  field: 'name',
                  headerName: '',
                  width: 150,
                },
                ...((usageByTimeData?.map((x) => ({
                  field: x.date,
                  headerName: formatDate(
                    x.date,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    usageByTime.data!.analyticsUsageByTime.interval,
                    {
                      hourly: 'HH:mm',
                      dayly: 'MM/DD',
                      weekly: 'MM/DD',
                      monthly: 'YYYY/MM',
                      yearly: 'YYYY',
                    }
                  ),
                  width: 75,
                  renderCell: (params) =>
                    params.value != null ? (
                      <Box
                        bgcolor={(t) => t.palette.secondary.main}
                        width={1}
                        height={1}
                        onMouseEnter={async (ev) => {
                          await openAwaiter.open(
                            <AnalyticsRoomDetailTooltip
                              {...(params.value as AnalyticsRoom)}
                              date={formatDate(
                                params.field,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                usageByTime.data!.analyticsUsageByTime.interval,
                                {
                                  hourly: 'MM/DD HH:mm',
                                  dayly: 'YYYY/MM/DD',
                                  weekly: 'YYYY/MM/DD',
                                  monthly: 'YYYY/MM',
                                  yearly: 'YYYY',
                                }
                              )}
                              isOpenKey={openAwaiter.key}
                              ancherEl={ev.currentTarget}
                            />
                          );
                        }}
                        onMouseLeave={() => openAwaiter.close(null)}
                      />
                    ) : undefined,
                })) ?? []) as DataGridProProps['columns']),
              ]}
              rows={Array.from(
                new Map(
                  usageByTimeData
                    ?.flatMap((x) => x.activeRooms)
                    .map((x) => [x.chatRoomId, x]) ?? []
                )
              ).map(([roomId, room]) => ({
                id: roomId,
                name: room.name,
                ...Object.fromEntries(
                  usageByTimeData?.map((x) => [
                    x.date,
                    x.activeRooms.find((y) => y.chatRoomId === roomId),
                  ]) ?? []
                ),
              }))}
            />
          </Stack>
        )}

        <Stack spacing={2}>
          <Typography variant="h6">言語使用率</Typography>
          <PieChart
            {...pieChartCustomize}
            series={[
              {
                ...pieChartSeriesCustmize,
                arcLabel: (item) =>
                  languageCountSum != null
                    ? `${Math.round((item.value / languageCountSum) * 100)}%`
                    : '',
                data:
                  languageCountData?.map((d) => ({
                    id: d.language,
                    value: d.count,
                    label: tCommon(`languages.${d.language}` as any),
                  })) ?? [],
              },
            ]}
          >
            {languageCountData != null && languageCountData.length >= 1 && (
              <PieCenterLabel count={languageCountData.length ?? 0} />
            )}
          </PieChart>
        </Stack>
      </Stack>
    </>
  );
};
export default OwnerAnalyticsTemplate;
