import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { InputAdornment } from '@mui/material';
import { pieArcLabelClasses } from '@mui/x-charts';
import { PieChartProps } from '@mui/x-charts/PieChart/PieChart';
import {
  DateRangePickerProps,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

import { Interval } from '@/types/graphql';

// タプルの型推論をするための関数
export function tuple<T1, T2>(t1: T1, t2: T2): [T1, T2] {
  return [t1, t2];
}

/** DateRangePicker */
export type Shortcut =
  | 'today'
  | 'yesterday'
  | 'thisWeek'
  | 'lastWeek'
  | 'last7days'
  | 'last28days'
  | 'last30days'
  | 'last90days'
  | 'last12months'
  | 'custom';
// shourtcutからdaterangeへの変換関数
export const shortcutItems: Record<
  Shortcut,
  {
    label: string;
    getValue: () => [Dayjs, Dayjs];
  }
> = {
  today: {
    label: '今日',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
  yesterday: {
    label: '昨日',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  },
  thisWeek: {
    label: '今週',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  lastWeek: {
    label: '先週',
    getValue: () => {
      const prevWeek = dayjs().subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  last7days: {
    label: '過去7日間',
    getValue: () => {
      const today = dayjs();
      const pre7day = today.subtract(7, 'day');
      return [pre7day.startOf('day'), today.endOf('day')];
    },
  },
  last28days: {
    label: '過去28日間',
    getValue: () => {
      const today = dayjs();
      const pre28day = today.subtract(28, 'day');
      return [pre28day.startOf('day'), today.endOf('day')];
    },
  },
  last30days: {
    label: '過去30日間',
    getValue: () => {
      const today = dayjs();
      const pre30day = today.subtract(30, 'day');
      return [pre30day.startOf('day'), today.endOf('day')];
    },
  },
  last90days: {
    label: '過去90日間',
    getValue: () => {
      const today = dayjs();
      const pre90day = today.subtract(90, 'day');
      return [pre90day.startOf('day'), today.endOf('day')];
    },
  },
  last12months: {
    label: '過去12ヵ月間',
    getValue: () => {
      const today = dayjs();
      const pre12month = today.subtract(12, 'month');
      return [pre12month.startOf('day'), today.endOf('day')];
    },
  },
  custom: {
    label: 'カスタム',
    // 以下は使用されることはない
    getValue: () => [dayjs(), dayjs()],
  },
};

export const dateRangePickerCustmize: DateRangePickerProps<Dayjs> = {
  slots: { field: SingleInputDateRangeField },
  slotProps: {
    textField: {
      variant: 'standard',
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <ArrowDropDownIcon />
          </InputAdornment>
        ),
      },
      sx: {
        width: 260,
      },
    },
    shortcuts: {
      items: Object.entries(shortcutItems)
        .filter(([shortcut]) => shortcut !== 'custom')
        .map((x) => x[1]),
    },
  },
  format: 'YYYY年M月D日',
};

// 日付間隔を元にフォーマット
export const formatDate = (
  val: string | number,
  interval: Interval,
  custom?: {
    hourly?: string;
    dayly?: string;
    weekly?: string;
    monthly?: string;
    yearly?: string;
  }
) => {
  const date = dayjs(val);
  switch (interval) {
    case Interval.Hourly:
      return date.format(custom?.hourly ?? 'HH:mm');
    case Interval.Dayly:
      return date.format(custom?.dayly ?? 'M月D日');
    case Interval.Weekly:
      return date.format(custom?.weekly ?? 'M月D日');
    case Interval.Monthly:
      return date.format(custom?.monthly ?? 'YYYY年M月');
    case Interval.Yearly:
      return date.format(custom?.yearly ?? 'YYYY年');
  }
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CHART_COLORS = [
  '#0068B7',
  '#E59A0D',
  '#79D124',
  '#20CAD1',
  '#732DD0',
  '#EB6D1E',
  '#E84396',
  '#7A88C8',
  '#616161',
  '#E47C77',
  '#259FED',
  '#A69B8F',
];

/** PieChart */
export const pieChartCustomize = {
  colors: CHART_COLORS.slice(),
  height: 300,
  sx: {
    p: 3,
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  },
};

export const pieChartSeriesCustmize: PieChartProps['series'][number] = {
  arcLabelMinAngle: 45,
  innerRadius: 60,
  highlightScope: { faded: 'global', highlighted: 'item' },
  faded: {
    additionalRadius: -10,
  },
  data: [],
};
