import { Button, IconButton, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ReactionQuestionIcon } from '@/components/atoms/ReactionQuestionIcon';
import { ChatReactionType } from '@/types/graphql';

const ReactionSelector = (props: {
  onReaction: (reactionType: ChatReactionType) => void;
  enabledReactions?: ChatReactionType[];
  hideText?: boolean;
}) => {
  const { t } = useTranslation('chat');
  return (
    <Stack
      direction="row"
      sx={{
        '&.MuiButton-startIcon': {
          fontSize: '30px',
        },
      }}
    >
      {[
        { type: ChatReactionType.Like, icon: '👍' },
        { type: ChatReactionType.Clap, icon: '👏' },
        { type: ChatReactionType.Love, icon: '💛' },
        { type: ChatReactionType.Happy, icon: '😆' },
        { type: ChatReactionType.Struggle, icon: '😟' },
        {
          type: ChatReactionType.Question,
          icon: <ReactionQuestionIcon width={30} height={30} />,
          text: t('reactionQuestion'),
        },
      ].map(
        (x) =>
          (props.enabledReactions == null ||
            props.enabledReactions.includes(x.type)) &&
          (x.text != null && !props.hideText ? (
            <Button
              fullWidth
              key={x.type}
              color="primary"
              variant="text"
              startIcon={<span style={{ fontSize: '20px' }}>{x.icon}</span>}
              onClick={() => props.onReaction(x.type)}
            >
              <Typography variant="body1" color={(t) => t.palette.grey[600]}>
                {x.text}
              </Typography>
            </Button>
          ) : (
            <IconButton
              key={x.type}
              color="primary"
              onClick={() => props.onReaction(x.type)}
            >
              {x.icon}
            </IconButton>
          ))
      )}
    </Stack>
  );
};
export default ReactionSelector;
