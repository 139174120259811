import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import MyDataGrid from '@/components/atoms/MyDataGrid';

type ImportErrorDialogProps<T> = DialogProps & {
  data: T[];
};

// 定型文・同音異義語・NGワードのCSV操作で使う汎用的なダイアログ
const ImportErrorDialog = <T extends { errorCode: number }>(
  props: ImportErrorDialogProps<T> & { msgToError: (row: T) => string }
) => {
  const { data, ...dialogProps } = props;
  const { t } = useTranslation('admin');

  return (
    <Dialog maxWidth="xl" fullWidth {...dialogProps}>
      <DialogTitle>{t('template.importError.errorMsg')}</DialogTitle>
      <DialogContent>
        <MyDataGrid
          sx={{ height: '60vh' }}
          rows={
            data
              ?.map((x, i) => Object.assign({}, x, { id: i + 1 }))
              ?.filter((x) => x.errorCode !== 0) ?? []
          }
          columns={[
            {
              headerName: t('template.importError.row'),
              field: 'id',
              width: 50,
            },
            {
              headerName: t('template.importError.id'),
              field: 'templateMessageId',
              valueGetter: (param) =>
                param.row.templateMessageId.trim() === ''
                  ? t('template.importError.new')
                  : param.row.templateMessageId,
              width: 300,
            },
            {
              field: t('template.importError.body'),
              valueGetter: (param) => props.msgToError(param.row),
              flex: 1,
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ImportErrorDialog;
