import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * チャットメッセージのヘッダー部分を表現する
 * @param props
 * @returns
 */
const ChatHeader = (props: {
  roomName: string;
  joiningUsersCount: number;
  waitingUsersCount: number;
  isQuestionMode?: boolean;
  onChangeQuestionMode: (isQuestionMode: boolean) => void;
  onOpenMenu: () => void;
  onClickParticipants?: () => void;
  onClickTemplateMessage?: () => void;
}) => {
  const { t } = useTranslation('chat');
  return (
    <AppBar
      position="static"
      sx={{ boxShadow: 0, borderRadius: 0 }}
      color="default"
    >
      <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center">
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr"
          gridTemplateRows="auto auto"
        >
          <Button
            color={'inherit'}
            sx={{
              width: '36px',
              height: '36px',
              minWidth: 0,
              m: 1,
              gridRow: '1/3',
            }}
            onClick={() => props.onOpenMenu()}
          >
            <MenuIcon fontSize="small" />
          </Button>
          <Typography
            variant="subtitle1"
            gridColumn={2}
            gridRow={1}
            mt="5px"
            mr={1}
            noWrap
          >
            {props.roomName}
          </Typography>
          <Box gridColumn={2} gridRow={2} mt="-8px">
            <Box
              sx={{ cursor: props.onClickParticipants ? 'pointer' : undefined }}
              onClick={() => props.onClickParticipants?.()}
              width="fit-content"
            >
              <Typography variant="body2">
                <PeopleAltIcon
                  sx={{ fontSize: 15, color: 'gray', mb: '-3px', mr: '2px' }}
                />
                {`${props.joiningUsersCount} ${
                  props.waitingUsersCount > 0
                    ? t('wating.headerWaitingUsers', {
                        count: props.waitingUsersCount,
                      })
                    : ''
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack direction="row">
          {props.isQuestionMode != null && (
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={props.isQuestionMode ? 'question' : 'explanation'}
              exclusive
              onChange={(_, value) =>
                props.onChangeQuestionMode(value === 'question')
              }
              aria-label="Platform"
              sx={{ m: 1 }}
            >
              <ToggleButton
                sx={{
                  minWidth: 60,
                }}
                value="explanation"
              >
                {t('explanationMode')}
              </ToggleButton>
              <ToggleButton
                sx={{
                  minWidth: 60,
                  '&.Mui-selected': {
                    backgroundColor: '#D57400',
                    '&:hover': {
                      backgroundColor: '#D57400',
                    },
                  },
                }}
                value="question"
              >
                {t('questionMode')}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {props.onClickTemplateMessage && (
            <IconButton onClick={() => props.onClickTemplateMessage?.()}>
              <MenuBookIcon color="primary" />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Divider />
    </AppBar>
  );
};
export default ChatHeader;
