import { TablePaginationProps, styled } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import MyDataGrid from '@/components/atoms/MyDataGrid';

const AdminDataGrid = styled(MyDataGrid)(({ theme }) => ({
  height: '95%',
  '& .MuiDataGrid-virtualScrollerContent': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#F2F6F8',
  },
  '& .MuiTablePagination-root': {
    width: '100%',
  },
  '& .MuiToolbar-root': {
    justifyContent: 'center',
  },
  '& .MuiTablePagination-spacer': {
    flex: 'inherit',
  },
  '& .MuiTablePagination-displayedRows': {
    display: 'none',
  },
}));

export default AdminDataGrid;

export const Pagination = ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

export function customPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
