import { Box, Typography } from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities/lib/guard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  useOwnerCompaniesQuery,
  useRemoveOwnerCompanyMutation,
} from '../../../types/graphql';

import { AdminContent, AdminInnerBox } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import DetailView from '@/components/molecules/admin/DetailView';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import OwnerAnalyticsTemplate from '@/components/templates/analytics/OwnerAnalyticsTemplate';

const OwnerDetail = () => {
  // Util関連
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.ownerAdmin'));
  useCheckPermission({ superAdmin: true });
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const params = useParams();
  const ownerId = params.ownerId ?? '';
  const confirmDeleteDialog = useConfirmDeleteDialog();

  // 企業詳細情報関連データ
  const ownerCompanyResult = useOwnerCompaniesQuery({
    variables: { input: { id: ownerId } },
  });
  const [removeOwnerCompany, removeOwnerCompanyResult] =
    useRemoveOwnerCompanyMutation();
  const data = ownerCompanyResult.data?.ownerCompanies[0];

  return (
    <Box>
      <LoadingBackdrop
        open={ownerCompanyResult.loading || removeOwnerCompanyResult.loading}
      />
      <BreadcrumbBar keyFrom="/admin/owner" />
      <AdminContent>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {data?.name}
        </Typography>
        <DetailView
          companyData={[
            { name: t('company.companyCode'), data: data?.code ?? '' },
            { name: t('company.companyName'), data: data?.name ?? '' },
            { name: t('company.email'), data: data?.userName ?? '' },
            { name: t('company.userLimit'), data: data?.userLimit ?? 0 },
            { name: t('company.roomLimit'), data: data?.roomLimit ?? 0 },
            {
              name: t('translate.translateMethod'),
              data: t(
                `translate.${
                  data?.canUseSimulteneous ? 'simulteneous' : 'consecutive'
                }` as any
              ),
            },
            { name: t('common.note'), data: data?.note ?? undefined },
          ]}
          title={t('company.ownerCompanyInfo')}
          onEdit={() => navigate('edit')}
          onDelete={async () => {
            // 確認ダイアログ表示
            if (
              (await confirmDeleteDialog.open({
                title: t('common.removeMessage', { name: data?.name }),
              })) === false
            ) {
              return; // キャンセル
            }
            assertNotNull(data?.id);
            await removeOwnerCompany({
              variables: { input: { id: data?.id } },
            });

            navigate('/admin/owner');
            snackbar('success', '削除しました');
          }}
        />
      </AdminContent>

      <AdminContent mt={3}>
        <Typography variant="h4">アナリティクス</Typography>
        <AdminInnerBox sx={{ bgcolor: (t) => t.palette.common.white }}>
          <OwnerAnalyticsTemplate id={ownerId} companyName={data?.name ?? ''} />
        </AdminInnerBox>
      </AdminContent>
    </Box>
  );
};
export default OwnerDetail;
