import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SxProps, Theme } from '@mui/material';

import GuideIcon from '@/components/atoms/GuideIcon';
import { ChatUserRole } from '@/types/graphql';
import { isChatHost } from '@/utils/userRole';

const ChatUserAvator = (props: {
  userRole: ChatUserRole;
  sx?: SxProps<Theme>;
}) => {
  return isChatHost(props.userRole) ? (
    <GuideIcon sx={{ width: '32px', height: '32px', p: '2px', ...props.sx }} />
  ) : (
    <AccountCircleIcon
      sx={{
        // color: `hsl(${i * 36 + 205}, 56%, 60%)`,
        color: (t) => t.palette.primary.light,
        width: '32px',
        height: '32px',
        ...props.sx,
      }}
    />
    // <RecordVoiceOverOutlinedIcon
    //   sx={{
    //     gridRow: '1/3',
    //     mr: 2,
    //     color: `hsl(${i * 36 + 205}, 56%, 60%)`,
    //   }}
    // />
  );
};

export default ChatUserAvator;
