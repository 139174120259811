import { ChatUserRole } from '@/types/graphql';

// チャットルームで管理権限を持つユーザー
export const isChatHost = (role?: ChatUserRole) =>
  role === ChatUserRole.TenantCompanyAdmin || role === ChatUserRole.Guide;

// チャットルームでゲストとなるユーザー
export const isChatGuest = (role?: ChatUserRole) => role === ChatUserRole.Guest;

// チャットルームで編集権限を持つユーザー
export const isChatEditor = (
  isGuestInputRestricted: boolean,
  role?: ChatUserRole
) =>
  isChatHost(role) || (role === ChatUserRole.Guest && !isGuestInputRestricted);

export const isParticipant = (role?: ChatUserRole) =>
  isChatHost(role) || role === ChatUserRole.Guest;
