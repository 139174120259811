import {
  Dialog,
  Button,
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Radio,
} from '@mui/material';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { HomophoneData } from '@/components/molecules/admin/HomophoneForm';

export type HomophoneKanaDialogProps = {
  open: boolean;
  defaultData: (HomophoneData & { disabled: boolean })[];
  onRegist: (data: (HomophoneData & { disabled: boolean })[]) => void;
  onCancel: () => void;
};

const HomophoneKanaDialog = (props: HomophoneKanaDialogProps) => {
  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const [data, setData] = useState(props.defaultData);

  return (
    <Dialog open={props.open}>
      <DialogTitle>{t('homophone.kanaDialogTitle')}</DialogTitle>
      <DialogContent>
        <Stack spacing={0.5} mb={1}>
          <Typography variant="body1">
            {t('homophone.kanaDialogDescription1')}
          </Typography>
          <Typography variant="body1">
            {t('homophone.kanaDialogDescription2')}
          </Typography>
          <Typography variant="body2">
            {t('homophone.kanaDialogDescription3')}
          </Typography>
        </Stack>
        <MyDataGrid
          sx={{
            width: 1,
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: (t) => t.palette.secondary.main,
            },
            '& .MuiDataGrid-cell': {
              bgcolor: (t) => t.palette.background.paper,
            },
          }}
          autoHeight
          hideFooter
          columnHeaderHeight={40}
          columns={[
            {
              field: 'priority',
              headerName: t('homophone.priority'),
              headerAlign: 'center',
              align: 'center',
              width: 60,
              renderCell: (params) => {
                // 同じ読みのワードがあればRadioButtonを表示
                const idx = Number(params.id);
                const sameKanas = props.defaultData
                  .map((h, i) => ({ ...h, idx: i }))
                  .filter((h, i) => i !== idx && h.kana === params.row['kana']);
                return (
                  <Radio
                    checked={params.value}
                    onChange={(_, checked) => {
                      // false⇒trueの場合のみ処理
                      if (checked === true) {
                        const nextData = data.slice();
                        // 自分を有効に
                        nextData[idx].disabled = false;
                        // 同じ読みの項目を無効に
                        sameKanas.forEach((s) => {
                          nextData[s.idx].disabled = true;
                        });

                        setData(nextData);
                      }
                    }}
                  />
                );
              },
            },
            {
              field: 'kana',
              headerName: t('homophone.reading'),
              flex: 1,
            },
            {
              field: 'sourceWord',
              headerName: t('homophone.sourceWord'),
              flex: 1,
            },
            {
              field: 'targetWord',
              headerName: t('homophone.targetWord'),
              flex: 1,
            },
          ]}
          rows={
            props.defaultData.map((r, i) => ({
              id: i.toString(),
              priority: r.disabled === false,
              kana: r.kana,
              sourceWord: r.sourceWord,
              targetWord: r.targetWord,
            })) ?? []
          }
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={1}
          sx={{ mt: 2, width: 1 }}
        >
          <CancelButton
            onClick={() => {
              props.onCancel();
            }}
          />
          <Button onClick={() => props.onRegist(data)}>
            {tCommon('command.register')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default HomophoneKanaDialog;
