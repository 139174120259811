import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';

import useDisplayLanguage from '@/components/hooks/useDisplayLanguage';
import ChatUserAvator from '@/components/molecules/ChatUserAvator';
import { ChatSessionUser } from '@/types/graphql';

const ChatUserList = (props: {
  users: ChatSessionUser[];
  onClickApprove?: (user: ChatSessionUser) => void;
  onClickReject?: (user: ChatSessionUser) => void;
}) => {
  const language = useDisplayLanguage();
  return (
    <List sx={{ pt: 0, px: 2, pb: 0 }}>
      {props.users.map((u) => (
        <Box key={u.userId}>
          {/* {index > 0 && <Divider />} */}
          <ListItem sx={{ py: 0.5, px: 0 }}>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr auto auto auto"
              width={1}
              height={1}
              alignItems="center"
            >
              <ChatUserAvator
                userRole={u.userRole}
                sx={{ gridRow: '1/3', mr: 2 }}
              />
              <Stack>
                <Typography
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  sx={{ fontWeight: 600 }}
                >
                  {u.userName}
                </Typography>
                <Typography variant="caption" gridColumn={2} gridRow={2}>
                  {language(u.language)}
                </Typography>
              </Stack>
              <MicOffIcon
                sx={{
                  mx: 0.5,
                  visibility: u.canUseMic ? 'hidden' : 'visible',
                }}
              />
              <VolumeOffIcon
                sx={{
                  mx: 0.5,
                  visibility: u.canUseSpeaker ? 'hidden' : 'visible',
                }}
              />
              <Stack direction="row" spacing={1} alignItems="center">
                {props.onClickApprove != null && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.onClickApprove?.(u)}
                    size="small"
                    sx={{ minWidth: 80 }}
                  >
                    許可
                  </Button>
                )}
                {props.onClickReject != null && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => props.onClickReject?.(u)}
                    size="small"
                    sx={{ minWidth: 80 }}
                  >
                    拒否
                  </Button>
                )}
              </Stack>
            </Box>
          </ListItem>
          <Divider />
        </Box>
      ))}
    </List>
  );
};
export default ChatUserList;
