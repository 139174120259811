import { assertNotNull, sleep } from '@remote-voice/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';

import MiniRecognitionHandler, {
  OnDataReceivedCallback,
} from '../../utils/MiniRecognitionHandler';

import Vad from '@/utils/Vad';

export type AudioDeviceEventCallbacks = {
  onEnded: (ev: Event) => void;
  onMuted: (ev: Event) => void;
  onUnmuted: (ev: Event) => void;
};
const useMiniRecognition = (props: {
  audioDeviceEventCallbacks?: AudioDeviceEventCallbacks;
  miniApiToken?: string;
}) => {
  const { miniApiToken } = props;

  /**
   * VADが終了とみなしてから発言が終わったとみなすまでのミリ秒
   */
  const sendTimerMs = 500;

  /**
   * 音声検知の最大時間
   */
  const maxDetectionTimerMs = 22000;

  /**
   * Mini APIの接続モジュールへのリファレンス
   */
  const miniApiRef = useRef<MiniRecognitionHandler>();

  /**
   * VADモジュールへのリファレンス
   */
  const vadRef = useRef<Vad>();

  /**
   * getUserMediaのMediaStreamへのリファレンス
   */
  const streamRef = useRef<MediaStream>();

  /**
   * 音声入力中かどうか
   */
  const isVoiceInputProgressingRef = useRef(false);

  /**
   * VADが終了を検知してから，発言が終わったとみなすまでのタイマー
   */
  const speechTimeoutRef = useRef<number>(0);

  /**
   * 音声検知時間を監視するタイマー
   */
  const maxDetectionTimeoutRef = useRef<number>(0);
  const maxDetectionIntervalRef = useRef<number>(0);

  /**
   * 停止中 OR NOT
   */
  const [ending, setEnding] = useState(false);

  // 終了時に呼び出すため保持します。
  const keepOnDataReceived = useRef<OnDataReceivedCallback | undefined>(
    undefined
  );
  // WebSocketがオープンかどうか
  const socketStarted = useRef(false);
  // マイクをオフにしたときにオーディオを切断するかどうか
  const audioDisconnectOnMicOff = props.audioDeviceEventCallbacks == null;

  /**
   * 音声入力を終了する
   */
  const endVoiceInput = useCallback((audioDisconnect: boolean) => {
    if (socketStarted.current) {
      setEnding(true);
    }

    // 最大検知時間検出用のタイマーをリセット
    clearInterval(maxDetectionIntervalRef.current);
    clearTimeout(maxDetectionTimeoutRef.current);

    if (miniApiRef.current) {
      // API切断
      miniApiRef.current.endSendMediaStream();
    }

    if (socketStarted.current && miniApiRef.current) {
      // do nothing
    } else {
      keepOnDataReceived.current?.('', true);
    }
    miniApiRef.current = undefined;
    socketStarted.current = false;

    // VAD無効化
    vadRef.current?.pause();
    vadRef.current = undefined;

    // マイクデバイスとのミュート連携しない場合はマイクを無効化
    if (audioDisconnect) {
      streamRef.current?.getTracks().forEach((t) => t.stop());
      streamRef.current = undefined;
    } else {
      // ミュート連携する場合はマイクデバイスからのイベントを監視する必要があるため、マイクを掴んでおく
    }

    isVoiceInputProgressingRef.current = false;
  }, []);

  /**
   * 音声入力の終了を検知した際の処理
   */
  const handleVoiceStop = useCallback(
    async (continuous: boolean) => {
      if (continuous) {
        clearInterval(maxDetectionIntervalRef.current);
        // API切断
        if (socketStarted.current && miniApiRef.current) {
          miniApiRef.current.endSendMediaStream();
          miniApiRef.current = undefined;
          socketStarted.current = false;
          // バッファリングをすぐにリスタートする。
          if (streamRef.current) {
            miniApiRef.current = new MiniRecognitionHandler();
            await miniApiRef.current.startBuffering(streamRef.current);
          }
        }
      } else {
        endVoiceInput(audioDisconnectOnMicOff);
      }
    },
    [endVoiceInput, audioDisconnectOnMicOff]
  );

  /**
   * 音声入力の開始を検知した際の処理
   */
  const handleVoiceStart = useCallback(
    (params: StartVoiceInputParams) => {
      assertNotNull(miniApiToken);
      clearTimeout(speechTimeoutRef.current);
      if (socketStarted.current === false) {
        (async () => {
          if (miniApiRef.current && streamRef.current) {
            const keepMini = miniApiRef.current;
            try {
              await miniApiRef.current.startSendMediaStream({
                stream: streamRef.current,
                onDataReceivedCallback: (result, final) => {
                  if (result === '' && final) {
                    // do nothing
                  } else {
                    params.onDataReceived(result, final);
                  }
                  // 既に手動で停止されている場合、空欄finalを送信
                  if (final && !isVoiceInputProgressingRef.current) {
                    params.onDataReceived('', true);
                  }
                  if (final) {
                    setEnding(false);
                  }
                },
                userId: params.userId,
                accessToken: miniApiToken,
                language: params.language,
                onSendSlowError: params.onSendSlowError,
              });

              // 最大検知時間が設定されている場合
              if (maxDetectionTimerMs > 0) {
                // 終了検知後も継続するなら、最大検知時間ごとに認識中断コマンドを送る
                if (params.continuous) {
                  maxDetectionIntervalRef.current = window.setInterval(() => {
                    miniApiRef.current?.breakSendMediaStream();
                  }, maxDetectionTimerMs);
                }
                // 継続しないなら、最大検知時間後に音声認識終了
                else {
                  maxDetectionTimeoutRef.current = window.setTimeout(() => {
                    endVoiceInput(audioDisconnectOnMicOff);
                  }, maxDetectionTimerMs);
                }
              }
            } catch (e: any) {
              // setAvailableVoiceInput(false);
              console.error(e);
              endVoiceInput(audioDisconnectOnMicOff);
              params.onSocketError(e);
            }
            if (keepMini === miniApiRef.current) {
              // 再生成（停止 → 開始）されていなければ開始状態とする
              socketStarted.current = true;
            }
          }
        })();
      }
    },
    [endVoiceInput, miniApiToken, audioDisconnectOnMicOff]
  );

  /**
   * マイクストリームの取得とデバイスミュート連携時のイベント設置
   */
  const initializeDeviceMediaStream = useCallback(
    async (deviceId?: string) => {
      if (streamRef.current == null || !streamRef.current.active) {
        streamRef.current?.getTracks().forEach((t) => t.stop());
        streamRef.current = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            deviceId: deviceId,
            sampleRate: 48000,
          },
        });

        if (props.audioDeviceEventCallbacks != null) {
          streamRef.current.getAudioTracks().forEach((track) => {
            assertNotNull(props.audioDeviceEventCallbacks);

            track.onended = (ev) => {
              if (socketStarted.current && miniApiRef.current) {
                props.audioDeviceEventCallbacks?.onEnded(ev);
              }
            };
            track.onmute = (ev) => {
              if (socketStarted.current && miniApiRef.current) {
                props.audioDeviceEventCallbacks?.onMuted(ev);
              }
            };
            track.onunmute = async (ev) => {
              props.audioDeviceEventCallbacks?.onUnmuted(ev);
            };
          });
        }
      }

      // オーディオコンテキストが停止している場合は再開
      if (Howler.ctx.state === 'suspended') await Howler.ctx.resume();
    },
    [props.audioDeviceEventCallbacks]
  );

  /**
   * 音声入力の可否を判断する
   */
  const [availableVoiceInput, setAvailableVoiceInput] = useState(false);
  useEffect(() => {
    if (miniApiToken) {
      setAvailableVoiceInput(true);
    } else {
      setAvailableVoiceInput(false);
    }
  }, [miniApiToken]);

  /**
   * クリーンナップ
   */
  useEffect(() => {
    // クリーンナップ時は強制的にオーディオを切断
    return () => endVoiceInput(true);
  }, [endVoiceInput]);

  /**
   * デバイスのON/OFF待機をする場合、即マイクをスタートさせておく
   */
  useEffect(() => {
    if (props.audioDeviceEventCallbacks != null) {
      initializeDeviceMediaStream();
    }
  }, [initializeDeviceMediaStream, props.audioDeviceEventCallbacks]);

  /**
   * ブラウザがバックグラウンドになった時・復帰した時の処理
   * （特にiOS Safariで特有の問題をケアするための処理）
   */
  useEffect(() => {
    const onChangeVisibilityState = async () => {
      if (document.visibilityState === 'visible') {
        // マイクの再取得とミュート監視が必要な場合は監視リスタート
        if (props.audioDeviceEventCallbacks != null) {
          await initializeDeviceMediaStream();
        }
      } else {
        // バックグラウンド移行時にはオーディオを切断
        endVoiceInput(true);
      }
    };
    document.addEventListener('visibilitychange', onChangeVisibilityState);
    return () =>
      document.removeEventListener('visibilitychange', onChangeVisibilityState);
  }, [
    endVoiceInput,
    initializeDeviceMediaStream,
    props.audioDeviceEventCallbacks,
  ]);

  /**
   * オーディオコンテキストのステートが変化した時の処理
   * （特にiOS Safariで特有の問題をケアするための処理）
   */
  useEffect(() => {
    const onChangeAudioContextState = async () => {
      // iOS Safariだと、バックグラウンドになったりマイクを他のアプリに取られたりするとinterruptedステートになる
      // この場合次回オーディオ再開時にHowlerから音声を上手く取得できない場合があるので、unloadして再構成を促すようにする
      if ((Howler.ctx.state as string) === 'interrupted') {
        Howler.ctx.removeEventListener(
          'statechange',
          onChangeAudioContextState
        );
        Howler.unload();
        await sleep(100);
        Howler.ctx.addEventListener('statechange', onChangeAudioContextState);
      }
    };

    Howler.ctx.addEventListener('statechange', onChangeAudioContextState);
    return () =>
      Howler.ctx.removeEventListener('statechange', onChangeAudioContextState);
  }, []);

  /**
   * 音声入力を開始する
   */
  const startVoiceInput = useCallback(
    async (params: StartVoiceInputParams) => {
      if (miniApiToken === undefined) return;

      // 手動停止時に使用したいので保持
      keepOnDataReceived.current = params.onDataReceived;

      setEnding(false);
      try {
        // マイクを有効化
        await initializeDeviceMediaStream(params.deviceId);
        assertNotNull(streamRef.current);
        isVoiceInputProgressingRef.current = true;

        // バッファリングをすぐにスタートする
        miniApiRef.current = new MiniRecognitionHandler();
        await miniApiRef.current.startBuffering(streamRef.current);

        // VADが無効化されている場合はすぐに音声認識を開始
        if (params.disableVad) {
          handleVoiceStart(params);
        }
        // VADを有効化
        else {
          vadRef.current = new Vad({
            // 音声入力開始を検知した場合
            voiceStart: () => handleVoiceStart(params),
            // 音声入力終了を検知した場合
            voiceStop: () => {
              speechTimeoutRef.current = window.setTimeout(() => {
                handleVoiceStop(params.continuous);
              }, sendTimerMs);
            },
            context: Howler.ctx,
            source: Howler.ctx.createMediaStreamSource(streamRef.current),

            smoothingTimeConstant: params.smoothingTimeConstant,
            energyOffset: params.energyOffset,
            energyThresholdRatioPos: params.energyThresholdRatioPos,
            energyThresholdRatioNeg: params.energyThresholdRatioNeg,
            energyIntegration: params.energyIntegration,
          });
        }
      } catch (e: any) {
        setAvailableVoiceInput(false);
        endVoiceInput(audioDisconnectOnMicOff);
        throw e;
      }
    },
    [
      audioDisconnectOnMicOff,
      endVoiceInput,
      handleVoiceStart,
      handleVoiceStop,
      initializeDeviceMediaStream,
      miniApiToken,
    ]
  );

  return {
    /**
     * 音声入力の可否
     */
    availableVoiceInput,

    /**
     * 停止中 OR NOT
     */
    ending,

    /**
     * 音声入力を開始する
     */
    startVoiceInput,

    /**
     * 音声入力を終了する
     */
    endVoiceInput,
  };
};
export default useMiniRecognition;

type StartVoiceInputParams = {
  language: string;
  onDataReceived: OnDataReceivedCallback;
  onSendSlowError: () => void;
  onSocketError: (err: any) => void;
  userId: string;
  deviceId?: string;
  continuous: boolean;
  disableVad?: boolean;
  smoothingTimeConstant?: number;
  energyOffset?: number;
  energyThresholdRatioPos?: number;
  energyThresholdRatioNeg?: number;
  energyIntegration?: number;
};
