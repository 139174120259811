import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, styled } from '@mui/material';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarKey,
  useSnackbar,
} from 'notistack';
import { MaterialDesignContent } from 'notistack';
import { ReactNode } from 'react';

// 表示のカスタム。テーマに合わせつつ、文字と背景を反転する
const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent': {
      backgroundColor: theme.palette.grey[600] + 'F0',
      color: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      paddingTop: 3,
      paddingBottom: 3,
      borderRadius: 6,
      // '& > div': {
      //   width: '100%!important',
      // },
    },
    // // default はアイコンなしなのでセンター表示
    // '&.notistack-MuiContent-default': {
    //   '& > div': {
    //     textAlign: 'center!important',
    //     display: 'inline-block',
    //   },
    // },
    // // success はアイコンなしなのでセンター表示
    // '&.notistack-MuiContent-success': {
    //   '& > div': {
    //     textAlign: 'center!important',
    //     display: 'inline-block',
    //   },
    // },
  })
);

const SnackbarCloseButton = (props: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
      <CloseIcon
        sx={{
          color: (t) => t.palette.primary.contrastText,
        }}
        fontSize="small"
      />
    </IconButton>
  );
};

const SnackbarProvider = (props: { children: ReactNode }) => {
  return (
    <Box
      width={1}
      height={1}
      sx={{
        // チャットで一番下に表示されると操作が隠れてしまうので少し上にあげている。
        // Admin画面と表示方法を分けたほうがいいかも。
        '& .notistack-SnackbarContainer': {
          bottom: '100px',
        },
      }}
    >
      <NotistackSnackbarProvider
        dense
        autoHideDuration={10000}
        // preventDuplicate
        maxSnack={3}
        Components={{
          default: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          success: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
      >
        {props.children}
      </NotistackSnackbarProvider>
    </Box>
  );
};
export default SnackbarProvider;
