import { Howler } from 'howler';

import { env } from '@/env';

class MiniTranslationHandler {
  private accessToken = '';
  private onDataReceivedCallback?: OnDataReceivedCallback;
  private language = '';
  private targetLanguages: string[] = [];

  private socket?: WebSocket;
  private mediaStreamAudioSourceNode?: MediaStreamAudioSourceNode;
  private audioWorkletNode?: AudioWorkletNode;
  private sendIntervalTimerId?: number;
  private opened = false;

  // private miniBaseURL = 'wss://g2-service-v4.mimi.fd.ai';
  private miniBaseURL = env.REACT_APP_MINI_SIMULTANEOUS_TRANSLATION_URL;

  private simultaneousTranslationResponseTimeout = 10000;
  private lastReceiveTime = 0;
  private hasFirstResponse = false;

  private context = Howler.ctx;

  private contentType = [
    'audio/x-pcm',
    'bit=16',
    'rate=48000',
    'channels=1',
  ].join(';');

  constructor() {
    // src/audioworklet/pcm.tsのビルドされたものを読み込む
    this.context.audioWorklet.addModule('/pcm-encode-audioworklet.js');

    const timeout = Number(
      env.REACT_APP_SIMULTANEOUS_TRANSLATION_RESPONSE_TIMEOUT
    );
    if (isNaN(timeout) === false && timeout != null) {
      this.simultaneousTranslationResponseTimeout = timeout;
    }
  }

  startSendMediaStream = async (params: {
    stream: MediaStream;
    sourceLanguage: string;
    targetLanguages: string[];
    onDataReceivedCallback: OnDataReceivedCallback;
    onSendSlowError: () => void;
    accessToken: string;
  }) => {
    // ローカルコピー
    this.language = params.sourceLanguage;
    this.targetLanguages = params.targetLanguages.concat();
    this.onDataReceivedCallback = params.onDataReceivedCallback;
    this.accessToken = params.accessToken;
    // Websocketに接続
    const socket = new WebSocket(this.buildTraWsUrl());

    this.lastReceiveTime = Date.now();
    socket.onmessage = (event: MessageEvent<string>) => {
      const data = JSON.parse(event.data) as MiniTransApiResult;
      if (
        data.status === 'interpret-recog-partial-result' ||
        data.status === 'interpret-trans-partial-result'
      ) {
        this.lastReceiveTime = Date.now();
        this.hasFirstResponse = true;
      }
      this.onDataReceivedCallback?.(data);
    };
    this.socket = socket;

    return new Promise<void>((resolve, reject) => {
      socket.onerror = reject;
      socket.onopen = () => {
        this.opened = true;
        try {
          const sendQueue: Int16Array[] = [];

          this.mediaStreamAudioSourceNode =
            this.context.createMediaStreamSource(params.stream);

          this.audioWorkletNode = new AudioWorkletNode(
            this.context,
            'PcmEncodeAudioWorkletProcessor'
          );

          this.audioWorkletNode.port.onmessage = (
            ev: MessageEvent<Int16Array>
          ) => {
            sendQueue.push(ev.data);
          };

          this.mediaStreamAudioSourceNode.connect(this.audioWorkletNode);

          // 処理Loopスタート
          if (this.sendIntervalTimerId) {
            clearInterval(this.sendIntervalTimerId);
          }
          this.sendIntervalTimerId = window.setInterval(async () => {
            if (
              // バッファに未送信データが一定量以上たまっていればエラーとする
              socket.bufferedAmount > 300000
            ) {
              this.socket?.close();
              this.opened = false;
              this.endSendMediaStream();
              params.onSendSlowError();
            } else if (
              // 一定時間応答がない場合はいったん返還を依頼する。
              this.hasFirstResponse &&
              Date.now() - this.lastReceiveTime >
                this.simultaneousTranslationResponseTimeout
            ) {
              this.breakSendMediaStream();
              this.lastReceiveTime = Date.now();
            } else {
              const readlyToSend = sendQueue.splice(0);
              const data = await (async () =>
                new Blob(readlyToSend, { type: 'audio/pcm' }))();
              this.socket?.send(data);
            }
          }, 300);
        } catch (e: any) {
          this.endSendMediaStream();
          reject(e);
        }
        resolve();
      };
    });
  };

  breakSendMediaStream = () => {
    this.lastReceiveTime = Date.now();
    this.hasFirstResponse = false;
    if (this.opened) {
      this.socket?.send(JSON.stringify({ command: 'interpret-break' }));
    }
  };

  endSendMediaStream = () => {
    // AudioworkletProcessorのprocessを終了
    this.audioWorkletNode?.port.postMessage('kill-process');
    //音声が終了したことをサーバーに通知
    if (this.opened) {
      this.socket?.send(JSON.stringify({ command: 'interpret-finish' }));
    }
    //音声をサーバーに投げる処理をストップ
    if (this.sendIntervalTimerId) clearInterval(this.sendIntervalTimerId);
    //このあと，サーバー側から切断されるのでこちらからSocket.closeする必要はない
    this.mediaStreamAudioSourceNode?.disconnect();
    this.audioWorkletNode?.disconnect();
  };

  private buildTraWsUrl = () => {
    const url =
      this.miniBaseURL +
      '/?output-language=' +
      encodeURIComponent(this.targetLanguages.join(',')) +
      '&segment-type=' +
      encodeURIComponent(['sentence', 'chunk'].join(',')) +
      '&content-type=' +
      encodeURIComponent(this.contentType) +
      '&access-token=' +
      encodeURIComponent(this.accessToken) +
      '&input-language=' +
      encodeURIComponent(this.language);
    return url;
  };
}

type MiniTransApiResult =
  | InterpretStart
  | InterpretRecogResult
  | InterpretTransResult;

type InterpretStart = {
  status: 'interpret-start';
  sessionId: string;
};

type InterpretRecogResult = {
  status:
    | 'interpret-recog-tmp-result'
    | 'interpret-recog-partial-result'
    | 'interpret-recog-result';
  sessionId: string;
  result: string;
  sourceLang: string;
  utteranceId: number;
  time: number;
};

type InterpretTransResult = {
  status: 'interpret-trans-partial-result' | 'interpret-trans-result';
  sessionId: string;
  result: string;
  source: string;
  targetLang: string;
  sourceLang: string;
  utteranceId: number;
  segmentType: 'sentence' | 'chunk';
};

export type OnDataReceivedCallback = (result: MiniTransApiResult) => void;
export default MiniTranslationHandler;
