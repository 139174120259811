import { assertNotNull } from '@remote-voice/utilities';
import axios from 'axios';
import { Howler, Howl } from 'howler';
import { useCallback, useRef, useState } from 'react';

import useMiniRecognition, {
  AudioDeviceEventCallbacks,
} from '@/components/hooks/useMiniRecognition';
import useMiniTranslation, {
  OnDataReceivedValue,
  SegmentType,
} from '@/components/hooks/useMiniTranslation';
import { UpsertMessageParams } from '@/components/organisms/chat/useChatMessages';
import { env } from '@/env';
import {
  TtsService,
  useChatCommandExecutedSubscription,
  useTranslateApiTokenQuery,
} from '@/types/graphql';
import SystemCommand from '@/types/SystemCommand';

const useChatMini = (props: {
  userId: string;
  chatRoomId: string;
  sessionEntryCode: string;
  segmentType: SegmentType;
  sourceLanguage: string;
  targetLanguages: string[];
  ttsService?: TtsService;
  audioDeviceEventCallbacks?: AudioDeviceEventCallbacks;
  onTextChange: (text: string) => void;
  onUpsertMessage: (
    value: UpsertMessageParams
  ) => Promise<{ id: string } | undefined>;
  onSendSlowError: () => void;
}) => {
  // Mini APIのトークン取得
  const {
    data,
    loading: dataLoading,
    refetch: refetchMiniApiToken,
  } = useTranslateApiTokenQuery({
    variables: {
      input: {
        chatRoomId: props.chatRoomId,
        sessionEntryCode: props.sessionEntryCode,
      },
    },
    pollInterval: 1000 * 60 * 30, // 30 min (= expire/2)おきに更新
    fetchPolicy: 'cache-first', // キャッシュを有効化
  });
  const { data: realtimeData, loading: realtimeDataLoading } =
    useTranslateApiTokenQuery({
      variables: {
        input: {
          chatRoomId: props.chatRoomId,
          sessionEntryCode: props.sessionEntryCode,
          realtime: true,
        },
      },
      pollInterval: 1000 * 60 * 30, // 30 min (= expire/2)おきに更新
      fetchPolicy: 'cache-first', // キャッシュを有効化
    });
  const miniApiToken = data?.translateApiToken.accessToken;
  const miniRealtimeApiToken = realtimeData?.translateApiToken.accessToken;
  const onTextChange = props.onTextChange;
  const onUpsertMessage = props.onUpsertMessage;
  const onSendSlowError = props.onSendSlowError;

  // マイクミュートのコマンドもここで受信してキャンセル処理を行う。
  // チャットコマンド
  useChatCommandExecutedSubscription({
    variables: {
      input: {
        chatRoomId: props.chatRoomId,
        sessionEntryCode: props.sessionEntryCode,
      },
    },
    onData: async (result) => {
      const commandResult = result.data.data?.chatCommandExecuted;
      assertNotNull(commandResult);
      const command = JSON.parse(commandResult) as SystemCommand;

      // マイクのミュート
      if (command.commandType === 'muteUserMic') {
        assertNotNull(command.userId);
        // 自分が対象外であれば無視
        if (
          command.userId === props.userId ||
          (command.targetUserId != null &&
            command.targetUserId !== props.userId)
        ) {
          return;
        }
        if (usingRef.current === false) return;
        setUsing(false);
        usingRef.current = false;
        clearTimeout(sendDelayTimerId.current);
        voiceInput.endVoiceInput(props.audioDeviceEventCallbacks == null);
        voiceTransInput.endVoiceInput();
        onTextChange('');
        setRecording(false);
      } else if (command.commandType === 'changeQuestionMode') {
        // 質問モードの切り替え時、自分を含めたすべてのマイクをミュートする
        if (usingRef.current === false) return;
        setUsing(false);
        usingRef.current = false;
        clearTimeout(sendDelayTimerId.current);
        voiceInput.endVoiceInput(props.audioDeviceEventCallbacks == null);
        voiceTransInput.endVoiceInput();
        onTextChange('');
        setRecording(false);
      }
    },
    skip: props.chatRoomId === '',
  });

  const voiceInput = useMiniRecognition({
    miniApiToken,
    audioDeviceEventCallbacks: props.audioDeviceEventCallbacks,
  });
  const voiceTransInput = useMiniTranslation({
    miniApiToken: miniRealtimeApiToken,
  });

  const play = useCallback(
    async (text: string, language: string, _deviceId?: string) => {
      const urlParams = new URLSearchParams();
      urlParams.append('text', text);
      urlParams.append('engine', 'nict');
      urlParams.append('lang', language);
      urlParams.append('audio_format', env.REACT_APP_TTS_FORMAT); // WAV or MP3
      urlParams.append('audio_endian', 'Little');
      urlParams.append('gender', 'unknown');
      const miniBackendId = env.REACT_APP_MINI_BACKEND_ID ?? undefined;
      if (miniBackendId) {
        urlParams.append('backend_id', miniBackendId);
      }

      const endpoint = (() => {
        switch (props.ttsService) {
          case TtsService.Azure:
            return env.REACT_APP_MINI_TTS_AZURE_URL;
          case TtsService.Feat:
            return env.REACT_APP_MIMI_TTS_FEAT_URL;
          case TtsService.Mimi:
            return env.REACT_APP_MINI_TTS_MIMI_URL;
          default:
            return '';
        }
      })();

      const requestTts = (token: string | undefined) =>
        axios.post(endpoint, urlParams, {
          headers: {
            Authorization: `Bearer ${token ?? ''}`,
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
          },
          responseType: 'arraybuffer',
        });

      let result;
      try {
        result = await requestTts(miniApiToken);
      } catch (e: any) {
        // Tokenが失効している場合は再生成を試みる
        if (
          e.response?.status === 401 &&
          e.response?.statusText === 'Unauthorized'
        ) {
          const { data } = await refetchMiniApiToken();
          result = await requestTts(data.translateApiToken.accessToken);
        } else {
          throw e;
        }
      }

      assertNotNull(result);
      const soundBlob = new Blob([new Uint8Array(result.data)], {
        type: result.headers['content-type'], // 'audio/wav' など,
      });

      // // 音声データを検証用にファイルに保存
      // {
      //   const a = document.createElement('a');
      //   document.body.appendChild(a);
      //   const url = window.URL.createObjectURL(soundBlob);
      //   a.href = url;
      //   a.download = text;
      //   a.click();
      //   window.URL.revokeObjectURL(url);
      //   document.body.removeChild(a);
      // }

      return new Promise<void>((resolve, reject) => {
        Howler.stop();
        const soundBlobUrl = URL.createObjectURL(soundBlob);
        const ttsSound = new Howl({
          html5: true, // これを入れるとバックグラウンド再生エラーは出ない様子
          src: [soundBlobUrl],
          format: ['mp3'],
          onstop: () => {
            resolve();
            URL.revokeObjectURL(soundBlobUrl);
            ttsSound.unload();
          },
          onend: () => {
            resolve();
            URL.revokeObjectURL(soundBlobUrl);
            ttsSound.unload();
          },
          onplayerror: () => {
            reject();
            URL.revokeObjectURL(soundBlobUrl);
            ttsSound.unload();
          },
        });
        ttsSound.play();
      });
    },
    [miniApiToken, props.ttsService, refetchMiniApiToken]
  );

  const stop = useCallback(() => {
    Howler.stop();
  }, []);

  // utteranceId -> chatMessageId
  const transingDictionaly = useRef<{
    [utteranceId: number]: string;
  }>({});

  // 順番通りに送信できるようリストで保持
  const transeReceivedSending = useRef(false);
  const transeReceivedValues = useRef<OnDataReceivedValue[]>([]);

  const [using, setUsing] = useState(false);
  const [recording, setRecording] = useState(false);
  const [ending, setEnding] = useState(false);
  const endingRef = useRef(false);
  const usingRef = useRef(false);
  const sendDelayTimerId = useRef(0);

  // 音声認識開始
  const startVoiceRecognition = useCallback(
    async (params: {
      userId: string;
      deviceId?: string;
      smoothingTimeConstant?: number;
      energyOffset?: number;
      energyThresholdRatioPos?: number;
      energyThresholdRatioNeg?: number;
      energyIntegration?: number;
      continuous: boolean;
      disableVad?: boolean;
      onSocketError: () => void;
    }) => {
      // すでに音声認識開始中なら何もしない
      if (!endingRef.current && usingRef.current) {
        return;
      }

      setEnding(false);
      endingRef.current = false;
      setUsing(true);
      usingRef.current = true;

      try {
        await voiceInput.startVoiceInput({
          language: props.sourceLanguage,
          onDataReceived: (result, final) => {
            if (!usingRef.current) {
              return; // UIが音声入力中じゃなければ無視する
            }
            // テキスト更新
            clearTimeout(sendDelayTimerId.current);
            onTextChange(result);
            if (final) {
              if (result !== '') {
                onUpsertMessage({
                  languages: [
                    {
                      message: result,
                      language: props.sourceLanguage,
                    },
                  ],
                });
              } else {
                setUsing(false);
                usingRef.current = false;
                setRecording(false);
              }
              onTextChange('');
            }
          },
          onSendSlowError,
          onSocketError: () => {
            setUsing(false);
            usingRef.current = false;
            params.onSocketError();
          },
          userId: params.userId,
          deviceId: params.deviceId,
          smoothingTimeConstant: params.smoothingTimeConstant,
          energyOffset: params.energyOffset,
          energyThresholdRatioPos: params.energyThresholdRatioPos,
          energyThresholdRatioNeg: params.energyThresholdRatioNeg,
          energyIntegration: params.energyIntegration,
          continuous: params.continuous,
          disableVad: params.disableVad,
        });
        setRecording(true);
      } catch (e: any) {
        setUsing(false);
        usingRef.current = false;
        throw e;
      }
    },
    [
      voiceInput,
      onSendSlowError,
      onTextChange,
      onUpsertMessage,
      props.sourceLanguage,
    ]
  );

  // 音声認識キャンセル
  const cancelVoiceRecognition = useCallback(() => {
    if (usingRef.current === false) return;
    setUsing(false);
    usingRef.current = false;
    setRecording(false);
    clearTimeout(sendDelayTimerId.current);
    voiceInput.endVoiceInput(props.audioDeviceEventCallbacks == null);
    onTextChange('');
  }, [voiceInput, props.audioDeviceEventCallbacks, onTextChange]);

  // 音声認識停止。ここまでの音声は処理される。
  const endVoiceRecognition = useCallback(() => {
    setEnding(true);
    endingRef.current = true;
    voiceInput.endVoiceInput(props.audioDeviceEventCallbacks == null);
  }, [props.audioDeviceEventCallbacks, voiceInput]);

  const sendTransMessage = useCallback(
    async (value: OnDataReceivedValue) => {
      // キューの末尾に値を追加する
      transeReceivedValues.current.push(value);
      // 送信中であれば処理を抜ける
      if (transeReceivedSending.current) return;
      try {
        // 送信中状態にする
        transeReceivedSending.current = true;
        while (transeReceivedValues.current.length > 0) {
          // 先頭を取得して削除
          const value = transeReceivedValues.current[0];
          transeReceivedValues.current.shift();
          // メッセージ送信。オリジナルが空であれば送信しない
          let msgId: string | undefined;
          if (
            (value.languages.find((x) => x.language === props.sourceLanguage)
              ?.message.length ?? 0) > 0
          ) {
            msgId = (
              await onUpsertMessage({
                overwriteChatMessageId:
                  transingDictionaly.current[value.utteranceId],
                languages: value.languages,
                isTyping: value.isTyping,
              })
            )?.id;
          }
          // 完了であればID辞書を削除
          if (value.isTyping === false) {
            delete transingDictionaly.current[value.utteranceId];
          } else if (msgId != null) {
            transingDictionaly.current[value.utteranceId] = msgId;
          }
          // 終了中に完了が来た場合、終了を確定する
          if (endingRef.current && value.isTyping === false) {
            setUsing(false);
            usingRef.current = false;
            setRecording(false);
          }
        }
      } finally {
        transeReceivedSending.current = false;
      }
    },
    [onUpsertMessage, props.sourceLanguage]
  );

  // 音声同通開始
  const startVoiceTranslation = useCallback(
    async (params: {
      deviceId?: string;
      smoothingTimeConstant?: number;
      energyOffset?: number;
      energyThresholdRatioPos?: number;
      energyThresholdRatioNeg?: number;
      energyIntegration?: number;
    }) => {
      // 同時通訳のターゲット言語を指定する
      let targetLanguages: string[];
      switch (props.sourceLanguage) {
        case 'ja':
          // ターゲット言語がなければ英語を指定，その他はどの言語にも翻訳できる
          targetLanguages =
            props.targetLanguages.length === 0 ? ['en'] : props.targetLanguages;
          break;
        case 'en':
          // ターゲット言語がなければ日本語を指定，その他はどの言語にも翻訳できる
          targetLanguages =
            props.targetLanguages.length === 0 ? ['ja'] : props.targetLanguages;
          break;
        default:
          // すべて日本語に翻訳し，逐次のAPIでサーバーに送る
          targetLanguages = ['ja'];
          break;
      }

      setEnding(false);
      endingRef.current = false;
      setUsing(true);
      usingRef.current = true;

      try {
        await voiceTransInput.startVoiceInput({
          sourceLanguage: props.sourceLanguage,
          targetLanguages: targetLanguages,
          segmentType: props.segmentType,
          onDataReceived: (result) => {
            if (!usingRef.current) return; // UIが音声入力中じゃなければ無視する
            sendTransMessage(result);
          },
          onSendSlowError: () => {
            cancelVoiceRecognition();
            onSendSlowError();
          },
          deviceId: params.deviceId,

          smoothingTimeConstant: params.smoothingTimeConstant,
          energyOffset: params.energyOffset,
          energyThresholdRatioPos: params.energyThresholdRatioPos,
          energyThresholdRatioNeg: params.energyThresholdRatioNeg,
          energyIntegration: params.energyIntegration,
        });
        setRecording(true);
      } catch (e: any) {
        setUsing(false);
        usingRef.current = false;
        throw e;
      }
    },
    [
      voiceTransInput,
      sendTransMessage,
      cancelVoiceRecognition,
      onSendSlowError,
      props.sourceLanguage,
      props.targetLanguages,
      props.segmentType,
    ]
  );

  // // 音声同通停止（このキャンセル操作は発言中のままになってしまうので不要なはず）
  // const cancelVoiceTranslation = useCallback(() => {
  //   if (usingRef.current === false) return;
  //   setUsing(false);
  //   usingRef.current = false;
  //   setRecording(false);
  //   voiceTransInput.endVoiceInput();
  // }, [voiceTransInput]);

  // 音声同通停止。ここまでの音声は処理される。
  const endVoiceTranslation = useCallback(() => {
    setEnding(true);
    endingRef.current = true;
    voiceTransInput.endVoiceInput();
  }, [voiceTransInput]);

  return {
    play,
    stop,
    initializing: (using && !recording) || dataLoading || realtimeDataLoading,
    recording,
    ending: (ending || voiceInput.ending) && using,
    canVoiceRecognition: voiceInput.availableVoiceInput,
    canVoiceTranslation: voiceTransInput.availableVoiceInput,
    startVoiceRecognition,
    cancelVoiceRecognition,
    endVoiceRecognition,
    startVoiceTranslation,
    endVoiceTranslation,
  };
};
export default useChatMini;
