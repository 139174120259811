import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Checkbox, Divider, Radio } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCell,
  GridCellProps,
  GridColDef,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { assertNotNull } from '@remote-voice/utilities';
import Joi from 'joi';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AddButton from '@/components/atoms/AddButton';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import DeleteButton from '@/components/atoms/DeleteButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useEditDataGridCellOnSingleClick } from '@/components/hooks/useEditDataGridCellOnSingleClick';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { DeletionFooterMenu } from '@/components/molecules/admin/DeletionFooterMenu';
import {
  HomophoneData,
  HomophoneFormData,
} from '@/components/molecules/admin/HomophoneForm';
import { ResponsiveFormHeader } from '@/components/molecules/admin/ResponsiveFormHeader';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import {
  useHomophoneCategoriesQuery,
  useHomophonesQuery,
  useImportHomophonesMutation,
  useRemoveHomophonesMutation,
} from '@/types/graphql';

type HomophoneEditFormData = Omit<HomophoneFormData, 'homophones'> & {
  homophones: (HomophoneData & { disabled: boolean; usageCount: number })[];
};

const HomophoneIndex = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('admin');
  useSetHeaderTitle(tCommon('breadcrumb.homophoneAdmin'));

  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const categoryId = params.categoryId;
  assertNotNull(categoryId);

  const [isInDeleteState, setIsInDeleteState] = useState(false);
  const [deletee, setDeletee] = useState<string[]>([]);
  const confirmDelete = useConfirmDeleteDialog();

  const [importHomophones, importHomophonesResult] =
    useImportHomophonesMutation();
  const [removeHomophones, removeHomophonesResult] =
    useRemoveHomophonesMutation();
  const homophones = useHomophonesQuery({
    variables: { input: { categoryId: categoryId } },
  });
  const categories = useHomophoneCategoriesQuery({
    variables: { input: { id: categoryId } },
  });
  const category = categories.data?.homophoneCategories[0];

  const { cellModesModel, handleCellClick, handleCellModesModelChange } =
    useEditDataGridCellOnSingleClick();

  const joi = useLocalizedJoi();
  const form = useForm<HomophoneEditFormData>({
    resolver: joiResolver(
      joi
        .object<HomophoneEditFormData>({
          categoryName: Joi.string().required(),
          homophones: Joi.array()
            .items(
              Joi.object({
                kana: Joi.string().required().max(100),
                sourceWord: Joi.string().required().max(50),
                targetWord: Joi.string().required().max(50),
                disabled: Joi.boolean().required(),
              }).unknown(true)
            )
            .required(),
        })
        .unknown(true)
    ),
  });

  const defaultData = useMemo(
    () => ({
      categoryId: category?.id ?? '',
      categoryName: category?.name ?? '',
      homophones:
        homophones.data?.homophones
          ?.map((h) => ({
            id: h.id,
            kana: h.kana,
            sourceWord: h.sourceWord,
            targetWord: h.targetWord,
            disabled: h.disabled,
            usageCount: h.usageCount,
          }))
          // 変換後文字コード順 ⇒ 利用回数順 ⇒ かな文字コード順 ⇒ 利用のチェック順にソート
          .sort((a, b) => (a.targetWord < b.targetWord ? 1 : -1))
          .sort((a, b) => (a.usageCount < b.usageCount ? 1 : -1))
          .sort((a, b) => (a.kana < b.kana ? 1 : -1))
          .sort((a, b) =>
            homophones.data?.homophones
              .filter((h) => h.kana === a.kana)
              .every((h) => h.disabled) &&
            homophones.data?.homophones
              .filter((h) => h.kana === b.kana)
              .some((h) => !h.disabled)
              ? 1
              : -1
          ) ?? [],
    }),
    [category?.id, category?.name, homophones.data?.homophones]
  );

  useEffect(() => {
    if (defaultData != null) form.reset(defaultData);
  }, [form, defaultData]);

  const columns = useMemo(() => {
    const defCols = [
      {
        field: 'kana',
        headerName: t('homophone.reading'),
        editable: true,
        flex: 1,
      },
      {
        field: 'sourceWord',
        headerName: t('homophone.sourceWord'),
        editable: true,
        flex: 1,
      },
      {
        field: 'targetWord',
        headerName: t('homophone.targetWord'),
        editable: true,
        flex: 1,
      },
      {
        field: 'usageCount',
        headerName: t('homophone.usageCount'),
        headerAlign: 'center',
        align: 'center',
        width: 100,
      },
    ];

    if (isInDeleteState === false)
      return [
        {
          field: 'disabled',
          headerName: t('homophone.use'),
          headerAlign: 'center',
          align: 'center',
          width: isInDeleteState ? 0 : 60,
          renderCell: (params) => {
            const idx = Number(params.id);
            const sameKanas = form
              .getValues('homophones')
              .map((h, i) => ({ ...h, idx: i }))
              .filter((h, i) => i !== idx && h.kana === params.row['kana']);
            return (
              <Checkbox
                checked={
                  // 自分か、同じ読みの項目のどれかが有効ならチェック
                  !params.value ||
                  (sameKanas.length >= 1 &&
                    sameKanas.some((s) => s.disabled === false))
                }
                onChange={(_, checked) => {
                  const idx = Number(params.id);
                  form.setValue(`homophones.${idx}.disabled`, !checked, {
                    shouldDirty: true,
                  });
                  if (checked === false) {
                    // 同じ読みがある場合は両方ともオフにする
                    if (sameKanas.length >= 1) {
                      sameKanas.forEach((s) =>
                        form.setValue(`homophones.${s.idx}.disabled`, true)
                      );
                    }
                  }
                }}
              />
            );
          },
        },
        {
          field: 'priority',
          headerName: t('homophone.priority'),
          headerAlign: 'center',
          align: 'center',
          width: isInDeleteState ? 0 : 60,
          renderCell: (params) => {
            // 同じ読みのワードがあればRadioButtonを表示
            const idx = Number(params.id);
            const sameKanas = form
              .getValues('homophones')
              .map((h, i) => ({ ...h, idx: i }))
              .filter((h, i) => i !== idx && h.kana === params.row['kana']);
            if (sameKanas.length >= 1) {
              return (
                <Radio
                  checked={params.row['disabled'] === false}
                  disabled={
                    params.row['disabled'] === true &&
                    sameKanas.every((s) => s.disabled === true)
                  }
                  onChange={(_, checked) => {
                    // false⇒trueの場合のみ処理
                    if (checked === true) {
                      // 自分を有効に
                      form.setValue(`homophones.${idx}.disabled`, false, {
                        shouldDirty: true,
                      });
                      // 同じ読みの項目を無効に
                      sameKanas.forEach((s) =>
                        form.setValue(`homophones.${s.idx}.disabled`, true)
                      );
                    }
                  }}
                />
              );
            } else {
              return '-';
            }
          },
        },
        ...defCols,
      ] as GridColDef[];
    else
      return [
        ...defCols,
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          renderHeader: () => tCommon('command.delete'),
        },
      ] as GridColDef[];
  }, [tCommon, form, isInDeleteState, t]);

  return (
    <Box>
      <BreadcrumbBar keyFrom="/admin/homophone" maxWidth={1} />
      <AdminContent>
        <LoadingBackdrop
          open={
            homophones.loading ||
            categories.loading ||
            removeHomophonesResult.loading ||
            importHomophonesResult.loading
          }
        />
        <ResponsiveFormHeader
          title={category?.name ?? ''}
          titleVarient="h4"
          toolbuttons={[
            <AddButton
              key="add"
              onClick={() => {
                form.setValue(
                  'homophones',
                  form.getValues().homophones.concat([
                    {
                      kana: '',
                      sourceWord: '',
                      targetWord: '',
                      disabled: false,
                      usageCount: 0,
                    },
                  ]),
                  {
                    shouldDirty: true,
                  }
                );
              }}
            />,
            <DeleteButton
              key="delete"
              onClick={() => setIsInDeleteState(!isInDeleteState)}
            />,
          ]}
        />
        <Divider sx={{ mt: 4 }} />
        <MyDataGrid
          sx={{
            width: 1,
            '& .MuiDataGrid-columnHeaders': {
              width: '100%',
              bgcolor: (t) => t.palette.secondary.main,
            },
            '& .MuiDataGrid-cell': {
              bgcolor: (t) => t.palette.background.paper,
            },
          }}
          autoHeight
          hideFooter
          columns={columns}
          rows={
            form.watch('homophones')?.map((r, i) => {
              const errorMsgs: string[] = [];
              if (form.formState.errors.homophones?.[i] != null) {
                const errors = form.formState.errors.homophones[i];
                if (errors?.kana?.message != null)
                  errorMsgs.push(errors.kana.message);
                if (errors?.sourceWord?.message != null)
                  errorMsgs.push(errors.sourceWord.message);
                if (errors?.targetWord?.message != null)
                  errorMsgs.push(errors.targetWord.message);
                if (errors?.disabled?.message != null)
                  errorMsgs.push(errors.disabled.message);
              }

              let rowSpan = 1;
              // 前の要素が同じ読みならRowSpanしているはずなので何もしない
              if (form.getValues(`homophones.${i - 1}`)?.kana === r.kana) {
                // do nothing
              } else {
                for (let j = i; ; j++) {
                  const next = form.getValues(`homophones.${j + 1}`);
                  // 次の要素が同じ読みの項目ならRowSpan
                  if (next != null && next.kana === r.kana) rowSpan++;
                  else break;
                }
              }

              return {
                // 配列のインデックスを表内でのみIDとする
                id: i.toString(),
                dbId: r.id,
                disabled: r.disabled,
                kana: r.kana,
                sourceWord: r.sourceWord,
                targetWord: r.targetWord,
                usageCount: r.usageCount,
                // 次の要素が同じ読みの項目ならRowSpan
                ...(rowSpan >= 2 ? { rowSpan: { disabled: rowSpan } } : {}),
                // errormsg: errorMsgs.join(', '),
              };
            }) ?? []
          }
          processRowUpdate={(newRow) => {
            const idx = Number(newRow.id);

            let disabled = newRow.disabled;
            // 同じ読みの項目がある場合、チェックが排他的になるようにする
            const sameKanas = form
              .getValues('homophones')
              .filter((h, i) => i !== idx && h.kana === (newRow.kana ?? ''));
            if (sameKanas.length >= 1) {
              if (sameKanas.some((same) => same.disabled === false))
                disabled = true;
              else disabled = false;
            }
            form.setValue(
              `homophones.${idx}`,
              {
                id: newRow.dbId,
                disabled: disabled,
                kana: newRow.kana,
                sourceWord: newRow.sourceWord,
                targetWord: newRow.targetWord,
                usageCount: newRow.usageCount,
              },
              {
                shouldDirty: true,
              }
            );

            return newRow;
          }}
          onRowSelectionModelChange={(selection) =>
            setDeletee(selection.map((x) => x.toString()))
          }
          rowSelection={isInDeleteState}
          checkboxSelection={isInDeleteState}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={(params, ev) => handleCellClick(params as any, ev)}
          slots={{ cell: MyCell }}
        />
      </AdminContent>
      {isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={deletee.length === 0}
          deleteButtonText={t('homophone.deleteSelected')}
          onClickDelete={async () => {
            const result = await confirmDelete.open({
              title: t('homophone.deleteSelectedsg'),
              message: t('template.unbackable'),
              okColor: 'primary',
            });
            if (result) {
              const serverDeletee: string[] = [];
              deletee.forEach((d) => {
                const val = form.getValues(`homophones.${Number(d)}`);
                if (val.id != null) serverDeletee.push(val.id);
                else {
                  form.setValue(
                    'homophones',
                    form
                      .getValues('homophones')
                      .filter((_, i) => i !== Number(d))
                  );
                }
              });
              await removeHomophones({
                variables: { input: { ids: serverDeletee } },
              });
              setDeletee([]);
              setIsInDeleteState(false);
              homophones.refetch();
              showSnackbar('success', t('homophone.deletedSelected'));
            }
          }}
          onClickCancel={() => setIsInDeleteState(false)}
        />
      )}
      {form.formState.isDirty && !isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={false}
          deleteButtonText={tCommon('command.saveChanges')}
          onClickDelete={form.handleSubmit(async (data) => {
            await importHomophones({
              variables: {
                input: {
                  homophones: data.homophones.map((d) => ({
                    disabled: d.disabled,
                    homophoneId: d.id ?? '',
                    kana: d.kana,
                    sourceWord: d.sourceWord,
                    targetWord: d.targetWord,
                    homophoneCategoryId: data.categoryId,
                    homophoneCategoryName: data.categoryName,
                  })),
                },
              },
            });
            setDeletee([]);
            setIsInDeleteState(false);
            homophones.refetch();
            showSnackbar('success', tCommon('message.successSaveChanges'));
          })}
          onClickCancel={() => navigate('/admin/homophone')}
        />
      )}
    </Box>
  );
};

export default HomophoneIndex;

// RowSpan用に拡張したDataGridセル
const MyCell = (props: GridCellProps) => {
  let style = {
    // TODO, shouldn't be needed, fix in the core.
    minWidth: props.width,
    maxWidth: props.width,
    minHeight: props.height,
    maxHeight: props.height === 'auto' ? 'none' : props.height,
    ...props.style,
  };
  const apiRef = useGridApiContext();
  const row = apiRef.current.getRow(props.rowId);
  if (row && row.rowSpan && row.rowSpan[props.field]) {
    const span = row.rowSpan[props.field];
    style = {
      ...style,
      minHeight: (props.height as any) * span,
      maxHeight: (props.height as any) * span,
      backgroundColor: '#fff',
      zIndex: 1,
    };
  }
  return <GridCell {...props} style={style} />;
};
