import { sleep } from '@remote-voice/utilities';
import { Howler } from 'howler';
import Enumerable from 'linq';
import { useCallback, useEffect, useRef, useState } from 'react';

const useAudioInputTester = () => {
  const [runningDeviceId, setRunningDeviceId] = useState<string>();
  const volumeRef = useRef(0);
  const [denied, setDenied] = useState(false);

  useEffect(() => {
    // マイクデバイス切替時、ボリューム計算を行う
    if (runningDeviceId != null) {
      let unmounted = false;
      const context = Howler.ctx;
      let stream: MediaStream | undefined;
      let workletNode: AudioWorkletNode | undefined;
      let sourceNode: MediaStreamAudioSourceNode | undefined;
      (async () => {
        try {
          // ソースノード取得
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true, // デバイス切替は不要になった
            // audio: {
            //   deviceId: runningDeviceId,
            // },
          });
          if (unmounted) return;
          sourceNode = context.createMediaStreamSource(stream);

          // ワークレットからボリューム取得ノード生成
          await context.audioWorklet.addModule('/pcm-encode-audioworklet.js');
          if (unmounted) return;
          workletNode = new AudioWorkletNode(
            context,
            'PcmEncodeAudioWorkletProcessor'
          );
          let counter = 0;
          workletNode.port.onmessage = (ev: MessageEvent<Int16Array>) => {
            if (unmounted) return;
            counter = (counter + 1) % 10;
            if (counter > 0) return; //重いので適度に間引く
            const volume = Enumerable.from(ev.data)
              .take(50) // 重いので数サンプルのみ
              .select((x) => Math.abs(x) / 32768)
              .max(); // 波形の前数値の絶対値を0～1の範囲に収める
            volumeRef.current = Math.min(volume * 4000, 100); // ここの計算は適当
          };
          sourceNode.connect(workletNode); // ソースに接続
          setDenied(false);
        } catch (e: any) {
          console.error(e);
          setDenied(true);
          // cleanup
          workletNode?.port.postMessage('kill-process');
          workletNode?.disconnect();
          sourceNode?.disconnect();
          stream?.getTracks().forEach((track) => track.stop());
          // if (context.state !== 'closed') context.close();
        }
      })();
      return () => {
        // cleanup
        workletNode?.port.postMessage('kill-process');
        workletNode?.disconnect();
        sourceNode?.disconnect();
        stream?.getTracks().forEach((track) => track.stop());
        // if (context.state !== 'closed') context.close();
        unmounted = true;
      };
    }
  }, [runningDeviceId]);

  const start = useCallback(async () => {
    // デバイス選択が不要になったのでロジック変更
    // start: async (deviceId: string) => {
    volumeRef.current = 0;
    if (runningDeviceId != null) {
      setRunningDeviceId(undefined); // 実行中であれば一度停止
      await sleep(0); // 雑実装。いったんUIに処理を返すことでステート更新を通知
    }
    setRunningDeviceId(''); // デバイス選択が出来た頃の名残で文字列を代入
    // setRunningDeviceId(deviceId);
  }, [runningDeviceId]);

  const stop = useCallback(() => {
    setRunningDeviceId(undefined);
    volumeRef.current = 0;
  }, []);

  return {
    running: runningDeviceId != null,
    volume: volumeRef,
    denied,
    start,
    stop,
  };
};
export default useAudioInputTester;
