import { validLanguages } from '@remote-voice/utilities';

export type CsvData = {
  ID: string;
  categoryId: string;
  categoryName: string;
  ja: string;
  en: string;
  ko: string;
  zh: string;
  'zh-TW': string;
  es: string;
  fil: string;
  fr: string;
  id: string;
  my: string;
  'pt-BR': string;
  th: string;
  vi: string;
};

const templateMessageCsvStringify = (records: CsvData[]) => {
  const result: string[] = [];
  const headers = ['ID', 'categoryId', 'categoryName', ...validLanguages].join(
    ','
  );
  result.push(headers);
  for (const record of records) {
    result.push(
      [
        record.ID,
        record.categoryId,
        record.categoryName,
        ...validLanguages.map((lang) => record[lang]),
      ]
        .map((x) =>
          x.indexOf('"') === -1 && x.indexOf(',') === -1
            ? x
            : '"' + x.replaceAll('"', '""') + '"'
        )
        .join(',')
    );
  }
  return '\uFEFF' + result.join('\n');
};
export default templateMessageCsvStringify;
