import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import AudioLinearProgress from '@/components/organisms/audioTest/AudioLinearProgress';

export type AudioOutputTestDialogProps = {
  open: boolean;
  onClose: (isOk: boolean) => void;
  deviceId?: string;
};

const AudioOutputTestDialog = (props: AudioOutputTestDialogProps) => {
  const { t } = useTranslation('chat');
  const volume = useRef(0);
  const analyserTimerRef = useRef(0);
  const howlRef = useRef<Howl | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const getHowl = () => {
    if (howlRef.current === null) {
      howlRef.current = new Howl({
        src: ['/sample_audio.mp3'],
        onplay: () => {
          setIsPlaying(true);
          const analyser = Howler.ctx.createAnalyser();
          Howler.masterGain.connect(analyser);
          analyser.fftSize = 2048;
          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);

          analyserTimerRef.current = window.setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            let sum = 0;
            for (let i = 0; i < bufferLength; i++) {
              sum += Math.abs(dataArray[i]);
            }
            volume.current = Math.min((sum / dataArray.length) * 8, 100);
          }, 5);
        },
        onstop: () => setIsPlaying(false),
      });
    }
    return howlRef.current;
  };

  const onClose = (isOk: boolean) => {
    getHowl().unload();
    props.onClose(isOk);
  };

  return (
    <Dialog open={props.open} PaperProps={{ sx: { minHeight: 280 } }}>
      <DialogTitle>
        {
          // スピーカーテストをします
          t('deviceTest.label002')
        }
      </DialogTitle>
      <DialogContent>
        <Typography mt={1}>
          {
            // 着信音が聞こえますか
            t('deviceTest.label003')
          }
        </Typography>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            onClick={() => {
              if (isPlaying) {
                getHowl().stop();
              } else {
                getHowl().play();
              }
            }}
          >
            {isPlaying ? t('deviceTest.label022') : t('deviceTest.label021')}
          </Button>
        </Box>

        <Box mt={2} mb={1}>
          <Typography>
            {
              //出力レベル
              t('deviceTest.label006')
            }
          </Typography>
        </Box>
        <AudioLinearProgress valueRef={volume} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => onClose(false)}>
          {t('deviceTest.label020')}
        </CancelButton>
        <Button
          onClick={() => {
            onClose(true);
          }}
        >
          {
            // はい
            t('deviceTest.label007')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AudioOutputTestDialog;

// デバイス選択ができた頃のダイアログを念のためコメントで保持

// export type AudioOutputSelectDialogProps = {
//   open: boolean;
//   onClose: (deviceId: string | undefined) => void;
//   deviceId?: string;
// };

// const AudioOutputSelectDialog = (props: AudioOutputSelectDialogProps) => {
//   const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
//   const audioTester = useAudioOutputTester();
//   const [inited, setInited] = useState(false);
//   const [selectedDeviceId, setSelectedDeviceId] = useState<string>();

//   useEffect(() => {
//     if (props.open === false) {
//       setInited(false);
//       return;
//     }
//     if (inited) return;
//     setInited(true);
//     (async () => {
//       // デバイスをリスト
//       const devices = await navigator.mediaDevices.enumerateDevices();
//       const inputDevices = devices.filter(
//         (x) => x.kind === 'audiooutput' && x.deviceId !== ''
//       );
//       setDevices(inputDevices);
//       const deviceId = props.deviceId ?? inputDevices[0]?.deviceId;
//       setSelectedDeviceId(deviceId);
//     })();
//   }, [props.open, props.deviceId, inited]);

//   const onClose = (deviceId: string | undefined) => {
//     audioTester.stop();
//     props.onClose(deviceId);
//   };

//   return (
//     <Dialog open={props.open} onClose={() => onClose(undefined)}>
//       <DialogTitle>再生に使用するスピーカーを選択します</DialogTitle>
//       <DialogContent>
//         {devices.length === 0 ? (
//           <>
//             スピーカーが選択できません。スピーカーを選択できる環境かどうかを確認してください。
//           </>
//         ) : (
//           <>
//             <Select
//               fullWidth
//               value={selectedDeviceId ?? ''}
//               onChange={(v) => {
//                 setSelectedDeviceId(v.target.value);
//                 audioTester.stop();
//               }}
//             >
//               {devices.map((x) => (
//                 <MenuItem key={x.deviceId} value={x.deviceId}>
//                   {x.label}
//                 </MenuItem>
//               ))}
//             </Select>
//             <Box mt={2} mb={1}>
//               出力レベル
//             </Box>
//             <LinearProgress variant="determinate" value={audioTester.volume} />
//           </>
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button
//           disabled={selectedDeviceId == null}
//           onClick={async () => {
//             if (audioTester.running) {
//               audioTester.stop();
//             } else if (selectedDeviceId != null) {
//               audioTester.start(selectedDeviceId);
//             }
//           }}
//         >
//           {audioTester.running ? '再生停止' : 'テスト再生'}
//         </Button>
//         <div style={{ flex: '1 0 0' }} />
//         <Button
//           onClick={() => {
//             onClose(selectedDeviceId);
//           }}
//         >
//           選択
//         </Button>
//         <CancelButton onClick={() => onClose(undefined)} />
//       </DialogActions>
//     </Dialog>
//   );
// };
// export default AudioOutputSelectDialog;
