import { assertNotNull } from '@remote-voice/utilities';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { TemplateForm } from '@/components/molecules/admin/TemplateForm';
import {
  File,
  useAddFilesMutation,
  useAddTemplateMessageMutation,
} from '@/types/graphql';

const TemplateAdd = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.templateAdmin'));

  const [addTemplateMessage, addTemplateMessageResult] =
    useAddTemplateMessageMutation();
  const [addFiles, addFilesResult] = useAddFilesMutation();

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  return (
    <TemplateForm
      onSubmit={async (input, options) => {
        // 添付ファイルを保存
        let uploadedFiles: File[] = [];
        const newFiles = input.attachedFiles?.filter(
          (file) => file.fileData != null
        );
        if (newFiles != null && newFiles.length >= 1) {
          const filesResult = await addFiles({
            variables: {
              input: {
                files: newFiles.map((x) => ({
                  fileName: x.fileName,
                  fileType: x.fileType,
                })),
              },
            },
          });
          if (filesResult.errors) throw filesResult.errors;
          const addFilesData = filesResult.data?.addFiles;
          assertNotNull(addFilesData);

          uploadedFiles = await Promise.all(
            addFilesData.map(async (file, i) => {
              // ファイルアップロード
              await axios.put(file.signedURL, newFiles[i].fileData, {
                headers: { 'Content-Type': file.fileType },
              });
              return file;
            })
          );
        }

        const result = await addTemplateMessage({
          variables: {
            input: {
              templateCategoryId: input.templateCategoryId,
              languages: input.languages.map((x) => ({
                language: x.language,
                message: x.message,
                readonly: x.readonly,
              })),
              files: uploadedFiles.map((file) => ({
                id: file.id,
                fileType: file.fileType,
                fileName: file.fileName,
              })),
            },
          },
        });
        if (result.errors) throw result.errors;

        navigate('/admin/template');
        showSnackbar(
          'success',
          commonT('message.registered') +
            (options.isReplacedBreak ? t('template.replacedBreak') : '')
        );
      }}
      loading={addTemplateMessageResult.loading || addFilesResult.loading}
    />
  );
};
export default TemplateAdd;
