import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import ReverseTranslationDialog, {
  ReverseTranslationDialogProps,
} from '@/components/organisms/reverseTranslationDialog/ReverseTranslationDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const ReverseTranslationDialogEx = (
  props: Omit<ReverseTranslationDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return (
    <ChatThemeProvider>
      <ReverseTranslationDialog open={isOpen} {...props} />
    </ChatThemeProvider>
  );
};

const useReverseTranslationDialog = () => {
  const openAwaiter = useOpenAwaiter<string | undefined | null>();
  return {
    open: (props: Omit<ReverseTranslationDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <ReverseTranslationDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useReverseTranslationDialog;
