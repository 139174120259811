import { joiResolver } from '@hookform/resolvers/joi';
import {
  Dialog,
  TextField,
  Button,
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';

export type CategoryDialogProps = {
  open: boolean;
  title?: string;
  onRegist: (value: string) => void;
  onCancel: () => void;
};

const CategoryDialog = (props: CategoryDialogProps) => {
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  const joi = useLocalizedJoi();

  const form = useForm<{ name: string }>({
    resolver: joiResolver(
      joi.object<{ name: string }>({
        name: joi.string().trim().max(50).required(),
      })
    ),
  });

  return (
    <Dialog open={props.open}>
      <DialogTitle> {props.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={1}>
          <TextField
            {...form.register('name')}
            fullWidth
            label={t('template.categoryName')}
            error={'name' in form.formState.errors}
            helperText={form.formState.errors.name?.message ?? ''}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={1}
          sx={{ mt: 2, width: 1 }}
        >
          <CancelButton
            onClick={() => {
              props.onCancel();
              form.reset({ name: '' });
            }}
          />
          <Button
            onClick={form.handleSubmit((data) => {
              props.onRegist(data.name);
            })}
            disabled={form.watch('name') === ''}
          >
            {commonT('command.ok')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
