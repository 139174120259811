import { Languages } from '@remote-voice/utilities';
import { useAtom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useTranslation } from 'react-i18next';

import { browserLocale } from '@/locales/i18n';

const languageAtom = atomWithStorage<Languages>(
  'RV_USER_LANGUAGE',
  browserLocale
);

const useLanguage = () => useAtom(languageAtom)[0];
export const useSetLanguage = () => {
  const atom = useSetAtom(languageAtom);
  const { i18n } = useTranslation();
  return (language: Languages) => {
    i18n.changeLanguage(language);
    atom(language);
  };
};
export default useLanguage;
