import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridColDef, GridLogicOperator } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import { useOwnerCompaniesQuery } from '../../../types/graphql';

import AddButton from '@/components/atoms/AddButton';
import AdminDataGrid, {
  customPagination,
} from '@/components/atoms/AdminDataGrid';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { AdminIndexTab } from '@/components/molecules/admin/AdminIndexTab';
import useLogDownloadByOwnerCompanyIdDialog from '@/components/organisms/logDownloadDialog/useLogDownloadByOwnerCompanyIdDialog';

const OwnerIndex = () => {
  useCheckPermission({ superAdmin: true });

  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.ownerAdmin'));
  const navigate = useNavigate();

  const ownerCompaniesResult = useOwnerCompaniesQuery({
    variables: { input: {} },
  });
  const logDownloadDialog = useLogDownloadByOwnerCompanyIdDialog();

  const [searchingOwnerName, setSearchingOwnerName] = useState('');
  const [allSearchConditions, setAllSearchConditions] = useState<
    { field: string; value: string }[]
  >([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const lastPage = {
    page: Math.floor(
      (ownerCompaniesResult.data?.ownerCompanies.length ?? 0) / 10
    ),
    itemNumber: (ownerCompaniesResult.data?.ownerCompanies.length ?? 0) % 10,
  };
  const getLastItemIndex = () => {
    if (paginationModel.page !== lastPage.page) {
      return paginationModel.page * paginationModel.pageSize + 10;
    } else {
      return (
        paginationModel.page * paginationModel.pageSize + lastPage.itemNumber
      );
    }
  };

  const columns: GridColDef[] = [
    { field: 'code', headerName: t('company.companyCode'), flex: 2 },
    { field: 'name', headerName: t('company.companyName'), flex: 3 },
    {
      field: 'canUseSimulteneous',
      headerName: t('translate.translateMethod'),
      flex: 2,
      renderCell: (cellValue) => {
        if (cellValue.value) {
          return <Typography>{t('translate.simulteneous')}</Typography>;
        } else {
          return <Typography>{t('translate.consecutive')}</Typography>;
        }
      },
    },
    {
      field: 'logDownload',
      flex: 1,
      minWidth: 125,
      headerName: t('common.logDownload'),
      renderCell: (cellValues) => (
        <Stack direction={'row'} spacing={1} sx={{ ml: 'auto' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              logDownloadDialog.open({
                ownerCompanyId: cellValues.id.toString(),
              });
            }}
          >
            <DownloadIcon color="primary" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <AdminContent>
      <LoadingBackdrop open={ownerCompaniesResult.loading} />
      <AdminIndexTab
        selected={1}
        tab1={{ label: '企業一覧' }}
        tab2={{ label: 'アナリティクス', redirectTo: '/admin/owner/analytics' }}
      />
      <Box
        sx={{
          bgcolor: (t) => t.palette.common.white,
          p: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ my: 2 }}
        >
          <TextField
            label={t('common.freeWordSearch')}
            size="small"
            onChange={(e) => {
              setSearchingOwnerName(e.target.value);
            }}
            onBlur={() => {
              setAllSearchConditions([
                { field: 'name', value: searchingOwnerName },
              ]);
            }}
          />
          <AddButton
            onClick={() => {
              navigate('add');
            }}
          />
        </Stack>
        <Stack direction={'row'} sx={{ mt: 3, mb: 1 }} alignItems={'flex-end'}>
          <Typography>{t('common.beforeCount')}</Typography>
          <Typography fontWeight={800}>
            {ownerCompaniesResult.data?.ownerCompanies.length}
          </Typography>
          <Typography>{t('common.afterCount')}</Typography>
          <Typography>{`（${
            paginationModel.page * paginationModel.pageSize + 1
          }-${getLastItemIndex()}）`}</Typography>
        </Stack>
        <AdminDataGrid
          pageSizeOptions={[10]}
          autoHeight
          pagination
          slots={{
            pagination: customPagination,
          }}
          onPaginationModelChange={setPaginationModel}
          initialState={{
            pagination: { paginationModel: paginationModel },
          }}
          columns={columns}
          rows={ownerCompaniesResult.data?.ownerCompanies ?? []}
          filterModel={{
            items: allSearchConditions.map((condition, index) => ({
              id: index,
              field: condition.field,
              operator: 'contains',
              value: condition.value,
            })),
            logicOperator: GridLogicOperator.And,
          }}
          onCellClick={(p) => {
            navigate(`${p.id}`);
          }}
        />
      </Box>
    </AdminContent>
  );
};
export default OwnerIndex;
