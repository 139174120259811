import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Divider, Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { assertNotNull } from '@remote-voice/utilities';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';

import AdminDataGrid, {
  customPagination,
} from '@/components/atoms/AdminDataGrid';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import {
  DateRangeParams,
  useResolveDateRange,
} from '@/components/hooks/useResolveDateRange';
import { AdminIndexTab } from '@/components/molecules/admin/AdminIndexTab';
import { PieCenterLabel } from '@/components/molecules/PieCenterLabel';
import { AnalyticsUserCountMuiltiTooltip } from '@/components/organisms/tooltip/AnalyticsUserCountMultiTooltip';
import { useAnalyticsUserCountMulti } from '@/components/templates/analytics/useAnalyticsUserCountMulti';
import {
  Interval,
  useAnalyticsUsageAllQuery,
  useAnalyticsUserLanguageQuery,
} from '@/types/graphql';
import {
  CHART_COLORS,
  dateRangePickerCustmize,
  formatDate,
  pieChartCustomize,
  pieChartSeriesCustmize,
  Shortcut,
  shortcutItems,
} from '@/utils/chart';

// 設定値は画面切り替え後も保持されるようにCookieに保存
const dateRangeParamsOwnerAtom = atomWithStorage<DateRangeParams>(
  'dateRangeParamsOwner',
  {
    shortcut: 'today',
    dateRange: null,
  }
);
const ownerCompanyIdsAtom = atomWithStorage<string[]>('ownerCompanyIds', []);

const OwnerAnalytics = () => {
  useCheckPermission({ superAdmin: true });
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.ownerAdmin'));

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [dateRangeParams, setDateRangeParams] = useResolveDateRange(
    dateRangeParamsOwnerAtom
  );
  const dateStart = dayjs(dateRangeParams.dateRange[0]).toISOString();
  const dateEnd = dayjs(dateRangeParams.dateRange[1]).toISOString() ?? '';
  const [ownerCompanyIds, setOwnerCompanyIds] = useAtom(ownerCompanyIdsAtom);

  // ユーザー数折れ線グラフ
  const userCount = useAnalyticsUserCountMulti({
    dateStart,
    dateEnd,
    ownerCompanyIds,
    skip: dateStart === '' || dateEnd === '',
  });
  const xAxisData = userCount.results?.[0]?.result.data?.analyticsUserCount;
  // 累計利用者数
  const sumUserCount = userCount.results?.every((x) => x.result.data != null)
    ? userCount.results?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (a, b) => a + b.result.data!.analyticsUserCount.sumUserCount,
        0
      )
    : undefined;

  // 企業ごとの利用状況一覧
  const usageAll = useAnalyticsUsageAllQuery({
    variables: { input: { dateStart, dateEnd } },
    skip: dateStart === '' || dateEnd === '',
  });

  // 言語円グラフ
  const languageCount = useAnalyticsUserLanguageQuery({
    variables: { input: { dateRange: { dateStart, dateEnd } } },
    skip: dateStart === '' || dateEnd === '',
  });
  const languageCountData = languageCount.data?.analyticsUserLanguage;
  const languageCountSum = languageCountData?.reduce((a, b) => a + b.count, 0);

  return (
    <AdminContent>
      <AdminIndexTab
        selected={2}
        tab1={{ label: '企業一覧', redirectTo: '/admin/owner' }}
        tab2={{ label: 'アナリティクス' }}
      />
      <Stack
        sx={{
          bgcolor: (t) => t.palette.common.white,
        }}
      >
        <Stack
          p={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="body2" color={(t) => t.palette.grey[600]}>
            {shortcutItems[dateRangeParams.shortcut].label}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs as any}>
            <DateRangePicker
              {...dateRangePickerCustmize}
              sx={{ flex: 1 }}
              value={dateRangeParams.dateRange}
              onChange={(value, context) => {
                const shortcut = Object.entries(shortcutItems).find(
                  ([_, value]) => value.label === context.shortcut?.label
                )?.[0] as Shortcut;
                setDateRangeParams(shortcut ?? 'custom', value);
              }}
            />
          </LocalizationProvider>
        </Stack>
        <Divider />

        <Stack p={2} spacing={2}>
          <Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6">利用者数</Typography>
              <Typography
                variant="body2"
                p={1}
                sx={{
                  backgroundColor: (t) => t.palette.grey[200],
                  borderRadius: 2,
                }}
              >
                {'累計利用者数 '}
                <b>{sumUserCount}</b>人
              </Typography>
            </Stack>
            <LineChart
              height={300}
              tooltip={{
                trigger: 'axis',
                axisContent: (props) => (
                  <AnalyticsUserCountMuiltiTooltip
                    {...props}
                    interval={xAxisData?.interval ?? Interval.Dayly}
                  />
                ),
              }}
              series={
                userCount.results?.map((x, i) => ({
                  curve: 'linear',
                  showMark: false,
                  color: CHART_COLORS[i % CHART_COLORS.length],
                  data:
                    x.result.data?.analyticsUserCount.data.map(
                      (d) => d.count
                    ) ?? [],
                })) ?? []
              }
              xAxis={[
                {
                  data: xAxisData?.data.map((d) => d.date) ?? [],
                  scaleType: 'point',
                  valueFormatter: (val) => {
                    const interval = xAxisData?.interval;
                    assertNotNull(interval);
                    return formatDate(val, interval);
                  },
                },
              ]}
            />
          </Stack>

          <Stack>
            <Typography variant="h6">企業ごとの利用者数・ルーム数</Typography>
            <AdminDataGrid
              pageSizeOptions={[10]}
              autoHeight
              pagination
              checkboxSelection
              keepNonExistentRowsSelected
              rowSelectionModel={ownerCompanyIds}
              onRowSelectionModelChange={(selection) =>
                setOwnerCompanyIds(selection.map((x) => x.toString()))
              }
              slots={{
                pagination: customPagination,
              }}
              onPaginationModelChange={setPaginationModel}
              initialState={{
                pagination: { paginationModel: paginationModel },
              }}
              columns={[
                {
                  field: 'ownerCompanyName',
                  headerName: '企業名',
                  flex: 3,
                  renderCell: (params) => (
                    <>
                      <FiberManualRecordIcon
                        fontSize="small"
                        htmlColor={
                          CHART_COLORS[params.row['idx'] % CHART_COLORS.length]
                        }
                      />
                      <Typography>{params.value}</Typography>
                    </>
                  ),
                },
                {
                  field: 'maxUserCount',
                  headerName: '最大同時利用者数',
                  flex: 1,
                },
                {
                  field: 'sumUserCount',
                  headerName: '累積利用者数',
                  flex: 1,
                },
                {
                  field: 'activeRoomCount',
                  headerName: '利用ルーム数',
                  flex: 1,
                },
              ]}
              rows={
                usageAll.data?.analyticsUsageAll?.map((d, i) => ({
                  ...d,
                  id: d.ownerCompanyId,
                  idx: i,
                })) ?? []
              }
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant="h6">言語使用率</Typography>
            <PieChart
              {...pieChartCustomize}
              series={[
                {
                  ...pieChartSeriesCustmize,
                  arcLabel: (item) =>
                    languageCountSum != null
                      ? `${Math.round((item.value / languageCountSum) * 100)}%`
                      : '',
                  data:
                    languageCountData?.map((d) => ({
                      id: d.language,
                      value: d.count,
                      label: tCommon(`languages.${d.language}` as any),
                    })) ?? [],
                },
              ]}
            >
              {languageCountData != null && languageCountData.length >= 1 && (
                <PieCenterLabel count={languageCountData.length ?? 0} />
              )}
            </PieChart>
          </Stack>
          <LoadingBackdrop open={userCount.loading} />
        </Stack>
      </Stack>
    </AdminContent>
  );
};
export default OwnerAnalytics;
