import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminInnerBox } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import AboutVadDialog from '@/components/organisms/admin/AboutVadDialog';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import {
  useRemoveVadPresetMutation,
  useVadPresetsQuery,
} from '@/types/graphql';

const VadFormContainer = () => {
  const confirmDeleteDialog = useConfirmDeleteDialog();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');

  const vadPresets = useVadPresetsQuery({ variables: { input: {} } });
  const data = vadPresets.data?.vadPresets?.slice();
  const [isOpenVadInfo, setIsOpenVadInfo] = useState(false);
  const [removeVadPreset, removeVadPresetResult] = useRemoveVadPresetMutation();

  return (
    <AdminInnerBox sx={{ bgcolor: (t) => t.palette.common.white }}>
      <LoadingBackdrop
        open={vadPresets.loading || removeVadPresetResult.loading}
      />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ px: 1, py: 2 }}
      >
        <Typography variant="h6">{t('vadSetting.admin')}</Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography>{t('vadSetting.aboutParameter')}</Typography>
          <IconButton onClick={() => setIsOpenVadInfo(true)}>
            <HelpIcon />
          </IconButton>
          <AboutVadDialog
            open={isOpenVadInfo}
            onClose={() => setIsOpenVadInfo(false)}
          />
        </Stack>
      </Stack>
      <MyDataGrid
        autoHeight
        sx={{
          mb: 3,
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: '#F2F6F8',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'initial',
            lineHeight: 'initial',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            bgcolor: (t) => t.palette.common.white,
          },
        }}
        columns={[
          {
            field: 'label',
            headerName: t('vadSetting.labelTitle'),
            flex: 3,
          },
          {
            field: 'smoothingTimeConstant',
            headerName: 'Smoothing Time Constant',
            flex: 2,
          },
          {
            field: 'energyOffset',
            headerName: 'Energy Offset',
            flex: 2,
            valueFormatter: (params) =>
              Number(params.value).toLocaleString('en-US', {
                maximumFractionDigits: 50,
                useGrouping: false,
              }),
          },
          {
            field: 'energyThresholdRatioPos',
            headerName: 'Energy Threshold Ratio Pos',
            flex: 2,
          },
          {
            field: 'energyThresholdRatioNeg',
            headerName: 'Energy Threshold Ratio Neg',
            flex: 2,
          },
          {
            field: 'energyIntegration',
            headerName: 'Energy Integration',
            flex: 2,
          },
          {
            field: 'edit',
            headerName: tCommon('command.edit'),
            flex: 1,
            renderCell: (cellValues) => (
              <IconButton
                onClick={() => {
                  navigate(`${cellValues.id}`);
                }}
                sx={{ ml: '-5px' }}
              >
                <EditIcon />
              </IconButton>
            ),
          },
          {
            field: 'remove',
            headerName: tCommon('command.delete'),
            flex: 1,
            renderCell: (cellValues) =>
              cellValues.row['default'] ? (
                'ー'
              ) : (
                <IconButton
                  onClick={async () => {
                    if (
                      await confirmDeleteDialog.open({
                        title: t('common.removeMessage', {
                          name: cellValues.row.label,
                        }),
                      })
                    ) {
                      await removeVadPreset({
                        variables: { input: { id: String(cellValues.id) } },
                      });
                    }
                    vadPresets.refetch();
                    snackbar('success', tCommon('message.removed'));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ),
          },
        ]}
        // デフォルトを先に表示
        rows={data?.sort((a) => (a.default ? -1 : 1)) ?? []}
        hideFooter
      />
      <Button
        size="large"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => navigate('/admin/vad/add')}
      >
        {t('vadSetting.addLabel')}
      </Button>
    </AdminInnerBox>
  );
};
export default VadFormContainer;
