import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Fab } from '@mui/material';
import { sleep } from '@remote-voice/utilities';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import NetworkAlert from '@/components/atoms/NetworkAlerts';
import { useOpenNetworkAlert } from '@/components/hooks/useOpenNetworkAlert';
import Comment from '@/components/molecules/Comment';
import HintBalloon from '@/components/molecules/HintBalloon';
import SystemMessage from '@/components/molecules/SystemMessage';
import { ChatMessageItem } from '@/components/organisms/chat/ChatMessageCache';
import { ChatReaction } from '@/components/organisms/chat/ChatReactionList';
import {
  ChatReactionType,
  ChatSessionUser,
  ChatUserRole,
} from '@/types/graphql';
import { isChatEditor, isChatHost } from '@/utils/userRole';

const commentMenuHintEnabledAtom = atomWithStorage<boolean>(
  'COMMENT_MENU_HINT_ENABLED',
  true
);

const ChatBody = (props: {
  messages: [number, ChatMessageItem][];
  getIsContinuedMessage: (key: number, msg: ChatMessageItem) => boolean; // 前のメッセージが同一ユーザーかどうかを返してもらう
  userId: string;
  language: string;
  reverseLanguage?: string;
  loading?: boolean;
  getUser: (id: string) => ChatSessionUser | undefined;
  loadPrevious: () => Promise<ChatMessageItem[]>;
  onEditMessage: (msg: string) => void;
  onReplyMessage: (commentId: string, userId: string, message: string) => void;
  onDeleteMessage: (commentId: string) => Promise<void>;
  onPlayMessage?: (text: string, language: string, messageId: string) => void;
  onStopMessage: () => void;
  onReaction: (commentId: string, type: ChatReactionType) => Promise<void>;
  onOpenReaction: (reactions: ChatReaction[]) => void;
  onCheckFlag?: (commentId: string, check: boolean) => void;
  playingMessageId: string | undefined;
  replaceNgWord: (msg: string) => string;
  reactionIsOnlyQuestion: boolean;

  // バケツリレー何とかしたい
  chatRoomId: string;
  sessionEntryCode: string;
  userRole: ChatUserRole;
  isGuestInputRestricted: boolean;
}) => {
  const { t } = useTranslation('chat');
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [bottomTimeout, setBottomTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const isOpenedAlert = useOpenNetworkAlert();

  const chatbody = document.getElementById('chatbody');
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const [hintAnchor, setHintAnchor] = useState<HTMLElement | null>(null);
  const [hintIsRight, setHintIsRight] = useState(false);
  const hintIndex = useRef(0);
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const [commentMenuHintEnabled, setCommentMenuHintEnabled] = useAtom(
    commentMenuHintEnabledAtom
  );

  const onBottomStateChange = useCallback(
    (atBottom: boolean) => {
      if (bottomTimeout) {
        clearTimeout(bottomTimeout);
        setBottomTimeout(null);
      }
      if (atBottom) {
        return setIsAtBottom(atBottom);
      }
      const t = setTimeout(() => {
        setIsAtBottom(atBottom);
      }, 200);
      setBottomTimeout(t);
    },
    [setIsAtBottom, bottomTimeout]
  );

  const goToBottom = useCallback(() => {
    virtuosoRef?.current?.scrollToIndex({
      align: 'end',
      behavior: 'smooth',
      index: 'LAST',
    });
    return false;
  }, []);

  // トップ位置にスクロールが来た際、以前のデータを読み込ませる
  const isScrollTop = useRef(false);
  const isPreviousAllLoading = useRef(false);

  // TODO: ルームID等が変化してデータが変わった際に、過去のデータを再度読み込めるか要確認。
  const loadPrevious = props.loadPrevious;
  const onTopStateChange = useCallback(
    async (atTop: boolean) => {
      if (atTop) {
        isScrollTop.current = true;
        while (isScrollTop.current && isPreviousAllLoading.current === false) {
          const previousMessages = await loadPrevious();
          if (previousMessages.length === 0) {
            isPreviousAllLoading.current = true;
          } else {
            await sleep(100);
          }
        }
      } else {
        isScrollTop.current = false;
      }
    },
    [loadPrevious]
  );

  useEffect(() => {
    if (chatbody && isAtBottom) {
      chatbody.scrollTo(0, chatbody.scrollHeight);
    }
  }, [chatbody, isAtBottom, props.messages]);

  return (
    <>
      <Box position="relative">
        <Box position="absolute" width={1} height={1}>
          <NetworkAlert scrollElement={chatbody} isOpened={isOpenedAlert} />
          <Virtuoso
            ref={virtuosoRef}
            id="chatbody"
            atBottomThreshold={100}
            atBottomStateChange={onBottomStateChange}
            atTopThreshold={300}
            atTopStateChange={onTopStateChange}
            followOutput={(isAtBottom: boolean) =>
              isAtBottom ? 'smooth' : false
            }
            components={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Header: () => <Box height="20px" />,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Footer: () => <Box height="20px" />,
              // Scroller: CustomScrollbar
            }}
            style={{ height: '100%', width: '100%' }}
            data={props.messages}
            firstItemIndex={props.messages[0]?.[1].sequence}
            itemContent={(
              index: number,
              [key, msg]: [number, ChatMessageItem]
            ) => {
              const isMine = msg.userId === props.userId;
              const showReverse = isMine && props.reverseLanguage != null;
              const messageInfo = isMine
                ? msg.languages.find((x) => x.isOriginal)
                : msg.languages.find((x) => x.language === props.language);
              const user = props.getUser(msg.userId);
              const isSystem =
                msg.userId === '00000000-0000-0000-0000-000000000000';
              if (isSystem) {
                return (
                  <SystemMessage
                    command={msg.languages[0].message}
                    timestamp={new Date(msg.timestamp)}
                    canGuideControl={isChatHost(props.userRole)}
                    getUserName={(id) =>
                      props.getUser(id)?.userName ?? 'Unknown'
                    }
                  />
                );
              }

              if (
                hintIndex.current === 0 &&
                index >= visibleRange.startIndex &&
                isSystem === false
              ) {
                hintIndex.current = index;
              }

              return (
                <div
                  ref={(v) => {
                    if (hintIndex.current === index) {
                      setHintAnchor(v);
                      setHintIsRight(isMine);
                    }
                  }}
                >
                  <Comment
                    key={msg.sequence}
                    message={messageInfo?.message ?? ''}
                    reply={
                      msg.reply == null
                        ? undefined
                        : {
                            message:
                              msg.reply.languages.find(
                                (x) => x.language === props.language
                              )?.message ?? '',
                            userName:
                              props.getUser(msg.reply.userId)?.userName ??
                              'Unknown',
                          }
                    }
                    user={user}
                    userRole={props.userRole}
                    isGuestInputRestricted={props.isGuestInputRestricted}
                    isUserVisibled={
                      isMine === false &&
                      props.getIsContinuedMessage(key, msg) === false
                    }
                    timestamp={new Date(msg.timestamp)}
                    isMine={isMine}
                    isSending={
                      msg.id === '' &&
                      msg.error !== true &&
                      msg.isTyping === false
                    }
                    isTyping={msg.isTyping}
                    isError={msg.error === true}
                    originalMessage={
                      isMine || messageInfo?.isOriginal === true
                        ? undefined
                        : msg.languages.find((l) => l.isOriginal)?.message
                    }
                    transedMessage={
                      showReverse
                        ? msg.languages.find(
                            (l) => l.language === msg.reverseLanguage
                          )?.message
                        : undefined
                    }
                    reverseMessage={
                      showReverse ? msg.reverseMessage ?? undefined : undefined
                    }
                    isRemoved={msg.isRemoved}
                    reactions={msg.reactions}
                    files={msg.files.map((x) => ({ id: x.id }))}
                    onEdit={() =>
                      props.onEditMessage(messageInfo?.message ?? '')
                    }
                    onReply={() =>
                      props.onReplyMessage(
                        msg.id,
                        user?.userId ?? '',
                        messageInfo?.message ?? ''
                      )
                    }
                    onDelete={async () => {
                      await props.onDeleteMessage(msg.id);
                    }}
                    onReaction={async (type) => {
                      await props.onReaction(msg.id, type);
                    }}
                    onPlay={
                      props.onPlayMessage
                        ? () => {
                            if (messageInfo && messageInfo.message !== '') {
                              props.onPlayMessage?.(
                                messageInfo.message,
                                messageInfo.language,
                                msg.id
                              );
                            }
                          }
                        : undefined
                    }
                    onStop={props.onStopMessage}
                    onChangeFlag={
                      props.onCheckFlag != null
                        ? (check) => props.onCheckFlag?.(msg.id, check)
                        : undefined
                    }
                    onShowMenu={() => {
                      setCommentMenuHintEnabled(false);
                    }}
                    chatRoomId={props.chatRoomId}
                    sessionEntryCode={props.sessionEntryCode}
                    isPlaying={msg.id === props.playingMessageId}
                    replaceNgWord={props.replaceNgWord}
                    flag={props.onCheckFlag != null ? msg.isMessageFlag : false}
                    onOpenReactions={
                      isChatEditor(props.isGuestInputRestricted, props.userRole)
                        ? () => {
                            const reactions = msg.reactions.map<ChatReaction>(
                              (x) => ({
                                userName:
                                  props.getUser(x.userId)?.userName ??
                                  'Unknown',
                                reactionType: x.reactionType,
                              })
                            );
                            props.onOpenReaction(reactions);
                          }
                        : undefined
                    }
                    reactionIsOnlyQuestion={props.reactionIsOnlyQuestion}
                  />
                </div>
              );
            }}
            rangeChanged={(v) => {
              if (commentMenuHintEnabled) {
                setVisibleRange(v);
                hintIndex.current = 0;
              }
            }}
          />
        </Box>
        {!isAtBottom && (
          <Fab
            onClick={goToBottom}
            size="small"
            color="primary"
            sx={{
              opacity: 0.5,
              position: 'absolute',
              bottom: '12px',
              right: '16px',
            }}
          >
            <KeyboardArrowDownIcon />
          </Fab>
        )}
      </Box>

      {isChatEditor(props.isGuestInputRestricted, props.userRole) && (
        <HintBalloon
          caption={t('commentMenuHintMessage')}
          anchorEl={commentMenuHintEnabled ? hintAnchor : null}
          anchorPosition={hintIsRight ? 'RightBottom' : 'LeftBottom'}
          onClose={() => setCommentMenuHintEnabled(false)}
        />
      )}

      <LoadingBackdrop open={props.loading ?? false} />
    </>
  );
};
export default ChatBody;
