import {
  DialogTitle,
  Dialog,
  CircularProgress,
  Button,
  DialogActions,
} from '@mui/material';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useDownloadCsvByRoomIdLazyQuery } from '@/types/graphql';

const DownloadCsvDialogByRoomId = (props: {
  open: boolean;
  onClose: () => void;
  roomId: string;
}) => {
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');

  const { open, onClose, roomId } = props;
  const [getUrl, { data, error, loading }] = useDownloadCsvByRoomIdLazyQuery({
    variables: { input: { roomId } },
  });

  useEffect(() => {
    if (open) getUrl();
  }, [open, getUrl]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('room.downloadLogs')}</DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        {loading && (
          <CircularProgress size={24} sx={{ position: 'absolute' }} />
        )}
        <Button
          variant={error && 'outlined'}
          disabled={loading}
          href={data?.downloadCsvByRoomId.downloadUrl}
          onClick={() => onClose()}
        >
          {error
            ? t('room.noLogsMsg')
            : loading
            ? commonT('message.wating')
            : t('room.startDownloadLogs')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadCsvDialogByRoomId;
