import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import isMobile from 'is-mobile';
import { ReactNode } from 'react';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

import { env } from '../env';

const ThemeProvider = (props: { children: ReactNode }) => {
  LicenseInfo.setLicenseKey(env.REACT_APP_MUI_LICENSE);
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};
export default ThemeProvider;

// declare module '@mui/material/styles' {
//   // テーマの拡張
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

// パレットの拡張
declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
    delete: Palette['primary'];
    commercial: Palette['primary'];
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
    delete?: PaletteOptions['primary'];
    commercial?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    delete: true;
    commercial: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    accent: true;
    delete: true;
    commercial: true;
  }
}
declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    accent: true;
    delete: true;
    commercial: true;
  }
}

// タイポグラフィーの拡張
declare module '@mui/material/styles' {
  interface TypographyVariants {
    small: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true;
  }
}

// button
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    shadow: true;
  }
}

// const primaryColor = '#3364A0';
const primaryColor = '#40A1E5';

// スタイルの定義
export const theme = createTheme({
  palette: {
    // mode: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: primaryColor,
      dark: '#1980CD',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D0E1EE',
      // contrastText: '#fff',
    },
    warning: {
      main: '#bb6060',
    },
    accent: {
      // primary が accentの色になったのに伴い、アクセントカラーは変更する必要あり。
      // コブラウズでしか使用していないので、グローバルメニューと同じようなハイライトが出来ればいい？
      main: '#3364A0',
      light: '#5b83b3',
      dark: '#234670',
      contrastText: '#fff',
    },
    delete: {
      main: '#6c777c',
      contrastText: '#fff',
    },
    info: {
      main: '#16a7e6',
      contrastText: '#fff',
    },
    // commercial: {
    //   // main: '#40A1E5',
    //   main: '#41a1e5',
    //   contrastText: '#fff',
    // },

    // text: {
    //   primary: 'rgba(0,0,0,0.87)',
    //   secondary: 'rgba(0,0,0,0.6)',
    //   disabled: 'rgba(0,0,0,0.38)',
    // },
    divider: 'rgba(0,0,0,0.12)',

    background: {
      default: '#f2f6f8',
    },
  },
  typography: {
    fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '26px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '22px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 700,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 700,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    caption: {
      fontSize: '10px',
    },
    small: {
      // システム共通の少し小さくしたい文字(拡張)
      fontSize: '8px',
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 600,
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        disableElevation: true, // 影無し
      },
      styleOverrides: {
        root: {
          minWidth: 120,
          borderRadius: 7,
        },
      },
      variants: [
        {
          props: { variant: 'shadow' },
          style: (t) => ({
            boxShadow: t.theme.shadows[2],
            borderColor: '#0000',
            backgroundColor: t.theme.palette.background.default,
            '&.MuiButton-shadow': {
              color: 'black',
            },
            '&:hover': {
              backgroundColor: '#eee',
            },
            '&>.MuiButton-startIcon, &>.MuiButton-endIcon': {
              color: t.theme.palette.primary.main,
            },
            '&.Mui-disabled': {
              color: t.theme.palette.action.disabled,
              backgroundColor: t.theme.palette.action.disabled,
            },
          }),
        },
        {
          props: { variant: 'shadow', color: 'secondary' },
          style: () => ({
            path: { color: '#aaa' },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: (t) => ({
            backgroundColor: t.theme.palette.common.white,
          }),
        },
        // {
        //   props: { variant: 'shadow', color: 'warning' },
        //   style: (t) => ({
        //     path: { color: t.theme.palette.warning.main },
        //     '&.Mui-disabled': {
        //       path: { color: t.theme.palette.action.disabled },
        //     },
        //   }),
        // },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputBase-root': {
            backgroundColor: '#fff',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.secondary.light,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.secondary.main,
            },
          },
        }),
      },
      variants: [
        {
          props: { size: 'small' },
          style: () => ({
            '& .MuiInputBase-input': {
              height: 23,
            },
          }),
        },
      ],
    },

    MuiInputLabel: {
      styleOverrides: {
        shrink: ({ theme }) => ({
          color: theme.palette.grey[700],
          fontWeight: 600,
        }),
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          // iOSで画面が自動拡大されるのを防ぐため
          fontSize: isMobile() ? '16px' : undefined,
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.paper,
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
          },
        }),
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },

    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          // この指定だとダークモードでぴったり同じにならない（要調査）
          backgroundColor: primaryColor,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          gridRow: 2,
          border: 'none',
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: '#f5f5f5',
          },
          '& .MuiCircularProgress-root': {
            zIndex: 1, // グリッドのローディングが消えない様にする
          },
          '& .MuiDataGrid-columnSeparator--sideRight': {
            display: 'none',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        textAlign: 'center',
      },
      styleOverrides: {
        root: {
          paddingTop: 3 * 8,
          paddingBottom: 1 * 8,
          paddingRight: 4 * 8,
          paddingLeft: 4 * 8,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: 4 * 8,
          paddingLeft: 4 * 8,
          paddingBottom: 0 * 8,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          paddingRight: 0 * 8,
          paddingLeft: 0 * 8,
          paddingTop: 2 * 8,
          paddingBottom: 1 * 8,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: 2 * 8,
          paddingLeft: 2 * 8,
          paddingTop: 1.5 * 8,
          paddingBottom: 2 * 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.05)',
        },
      },
    },
  },
});
