import LogoutIcon from '@mui/icons-material/Logout';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ログアウトボタン
const LogoutButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');
  const { children, startIcon, ...others } = props;
  return (
    <Button startIcon={startIcon ?? <LogoutIcon />} {...others}>
      {children ?? t('command.logout')}
    </Button>
  );
};
export default LogoutButton;
