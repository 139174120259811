import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

// 新規登録ボタン
const AddButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');
  const { children, startIcon, ...others } = props;
  return (
    <Button startIcon={startIcon ?? <ControlPointIcon />} {...others}>
      {children ?? t('command.new')}
    </Button>
  );
};
export default AddButton;
