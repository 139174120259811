import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import EditButton from '@/components/atoms/EditButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import usePreviewImageBackdrop from '@/components/organisms/previewImageBackdrop/usePreviewImageBackdrop';
import { useFilesQuery, useTemplateMessagesQuery } from '@/types/graphql';

const TemplateEdit = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t, i18n } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.templateAdmin'));

  const userLang = i18n.language;
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const params = useParams();
  const templateId = params.templateId;
  assertNotNull(templateId);

  const templateMessages = useTemplateMessagesQuery({
    variables: { input: { id: templateId } },
  });
  const currentItem = templateMessages.data?.templateMessages[0];

  const getFiles = useFilesQuery({
    variables: {
      input: {
        ids:
          (currentItem?.files
            .map((x) => x.id)
            .filter((x) => x != null) as string[]) ?? [],
      },
    },
  });
  const previewImageBackdrop = usePreviewImageBackdrop();

  return (
    <Box>
      <LoadingBackdrop open={templateMessages.loading} />
      <BreadcrumbBar keyFrom="/admin/template" />
      <AdminContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{t('template.detail')}</Typography>
          <EditButton onClick={() => navigate('edit')} />
        </Stack>

        <Stack mt={3} spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h6">{t('template.category')}</Typography>
            <Typography>{currentItem?.category.name ?? ' '}</Typography>
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography variant="h6">{t('template.text')}</Typography>
            <Typography>
              {currentItem?.languages.find((x) => x.language === userLang)
                ?.message ?? ' '}
            </Typography>
            <ImageList
              cols={matchDownSm ? 3 : 6}
              rowHeight={matchDownSm ? 100 : 164}
            >
              {currentItem?.files?.map((file, i) => {
                const src =
                  getFiles.data?.files.find((x) => x.id === file.id)
                    ?.signedURL ?? '';
                return (
                  <ImageListItem
                    key={i}
                    sx={{
                      border: (t) => `1px solid ${t.palette.secondary.main}`,
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={src}
                      alt={file.fileName}
                      loading="lazy"
                      onClick={() => {
                        previewImageBackdrop.open({
                          imgProps: {
                            src: src,
                            alt: file.fileName,
                            style: {
                              maxWidth: '100%',
                              maxHeight: '100%',
                            },
                          },
                        });
                      }}
                    />
                  </ImageListItem>
                );
              }) ?? false}
            </ImageList>
          </Stack>
          <Divider />
          <Stack spacing={2}>
            <Typography variant="h6">{t('template.translation')}</Typography>
            {currentItem?.languages.map((x) => (
              <Stack key={x.language} spacing={1}>
                <Typography variant="h6">
                  {tCommon(`languages.${x.language}` as any)}
                </Typography>
                <Box
                  sx={{
                    borderRadius: 2,
                    py: 1.5,
                    px: 1,
                    backgroundColor: (t) => t.palette.common.white,
                  }}
                >
                  <Typography>{x.message}</Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </AdminContent>
    </Box>
  );
};
export default TemplateEdit;
