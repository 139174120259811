import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export type DropzoneDialogProps = {
  open: boolean;
  onClose: (files: File[] | undefined) => void;
};

const DropzoneDialog = (props: DropzoneDialogProps) => {
  const { t } = useTranslation('admin');

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(undefined)}
      maxWidth="md"
    >
      <DialogTitle textAlign="center">{t('template.csvUpload')}</DialogTitle>
      <DialogContent>
        <Dropzone
          onDrop={(files) => {
            if (files.length >= 1) props.onClose(files);
          }}
          accept={{ 'text/csv': [] }}
        >
          {({ getRootProps, getInputProps, open, isDragActive }) => (
            <Stack
              spacing={1}
              alignItems="center"
              {...getRootProps()}
              sx={{
                pt: 5,
                pb: 7,
                borderRadius: 2,
                border: (t) => `2px dashed ${t.palette.divider}`,
                backgroundColor: (t) =>
                  isDragActive
                    ? t.palette.secondary.light
                    : t.palette.background.default,
              }}
            >
              <input {...getInputProps()} />
              <UploadFileIcon
                sx={{
                  fontSize: '60px',
                  color: (t) => t.palette.grey[isDragActive ? 500 : 300],
                }}
              />
              <Stack alignItems="center">
                <Typography variant="body1">
                  {t('template.dragFile')}
                </Typography>
                <Typography variant="body1">
                  {t('template.dragFileOr')}
                </Typography>
              </Stack>
              <Button onClick={open} variant="outlined">
                {t('template.selectFile')}
              </Button>
            </Stack>
          )}
        </Dropzone>
        <Stack my={2}>
          <Typography variant="body2">
            {t('template.uploadWarning_1')}
          </Typography>
          <Typography variant="body2">
            {t('template.uploadWarning_2')}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DropzoneDialog;
