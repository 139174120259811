import { Box, Typography } from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities/lib/guard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  useSuperUsersQuery,
  useRemoveSuperUserMutation,
} from '../../../types/graphql';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import DetailView from '@/components/molecules/admin/DetailView';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';

const SuperUserDetail = () => {
  // Util関連
  const { t } = useTranslation('admin');
  useSetHeaderTitle('スーパーユーザー');
  useCheckPermission({ superAdmin: true });
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const params = useParams();
  const superuserId = params.superuserId ?? '';
  const confirmDeleteDialog = useConfirmDeleteDialog();

  // 企業詳細情報関連データ
  const superUserResult = useSuperUsersQuery({
    variables: { input: { id: superuserId } },
  });
  const [removeSuperUser, removeSuperUserResult] = useRemoveSuperUserMutation();
  const data = superUserResult.data?.superUsers[0];

  return (
    <Box>
      <LoadingBackdrop
        open={superUserResult.loading || removeSuperUserResult.loading}
      />
      <BreadcrumbBar keyFrom="/admin/superuser" />
      <AdminContent>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {data?.userName}
        </Typography>
        <DetailView
          companyData={[
            { name: t('company.email'), data: data?.userName ?? '' },
          ]}
          title="スーパーユーザー情報"
          onEdit={() => navigate('edit')}
          onDelete={async () => {
            // 確認ダイアログ表示
            if (
              (await confirmDeleteDialog.open({
                title: t('common.removeMessage', { name: data?.userName }),
              })) === false
            ) {
              return; // キャンセル
            }
            assertNotNull(data?.id);
            await removeSuperUser({
              variables: { input: { id: data?.id } },
            });

            navigate('/admin/superuser');
            snackbar('success', '削除しました');
          }}
        />
      </AdminContent>
    </Box>
  );
};
export default SuperUserDetail;
