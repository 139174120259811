import { Stack, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

import { ResponsiveTwoSplit } from '@/components/molecules/admin/ResponsiveTwoSplit';

export const ResponsiveFormHeader = (props: {
  title: string;
  titleVarient: TypographyProps['variant'];
  toolbuttons: ReactNode[];
}) => (
  <ResponsiveTwoSplit
    cellA={
      <Stack justifyContent="flex-end" height={1}>
        <Typography variant={props.titleVarient}>{props.title}</Typography>
      </Stack>
    }
    cellB={
      <Stack
        direction="row"
        spacing={1}
        sx={(theme) => ({
          [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
          },
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
          },
        })}
      >
        {props.toolbuttons}
      </Stack>
    }
  />
);
