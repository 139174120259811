import {
  Box,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
} from '@mui/material';

interface LabeledIconButtonProps extends IconButtonProps {
  label: string;
}

const LabeledIconButton = (props: LabeledIconButtonProps) => {
  const { label, children, color, ...iconButtonProps } = props;
  return (
    <IconButton {...iconButtonProps} color={color ?? 'default'}>
      <Stack alignItems="center" spacing="4px">
        <Box height="24px">{children}</Box>
        <Typography variant="caption" component="div" lineHeight={1.2}>
          {label}
        </Typography>
      </Stack>
    </IconButton>
  );
};
export default LabeledIconButton;
