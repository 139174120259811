import { useOpenableElementIsOpen } from '../../hooks/useOpenableElement';

import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import AudioTestResultDialog, {
  AudioTestResultDialogProps,
} from '@/components/organisms/audioTest/AudioTestResultDialog';

const AudioTestResultDialogEx = (
  props: Omit<AudioTestResultDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <AudioTestResultDialog open={isOpen} {...props} />;
};

const useAudioTestResultDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: (props: Omit<AudioTestResultDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <AudioTestResultDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useAudioTestResultDialog;
