import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import Enumerable from 'linq';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import useConfirmContinueDialog from '@/components/organisms/confirmDialog/useConfirmContinueDialog';
import { ChatRoomsTemplateMessage } from '@/types/graphql';

type ChatFooterTemplateProps = {
  messages: ChatRoomsTemplateMessage[];
  language: string;
  onSend: (id: string) => void;
  onCancel: () => void;
};

const ChatFooterTemplate = (props: ChatFooterTemplateProps) => {
  const { t } = useTranslation('chat');
  const boxShadow = '0px 0px 6px rgba(0, 0, 0, 0.2)';

  const categories = Enumerable.from(props.messages)
    .groupBy((x) => x.templateMessage.category.id)
    .toArray()
    .sort(
      (a, b) =>
        (a.first().templateMessage.category.order ?? 0) -
        (b.first().templateMessage.category.order ?? 0)
    );

  const [initialTemplate, setInitialTemplate] = useState(() => {
    // 定型文選択を開く際、これまでの送信状況から初期表示を決定する。
    // 定型文未送信時は１つ目のカテゴリを開き、１件目が先頭に来る状態で開く。
    // いずれかの定型文送信時は、最後に送信した定型文が一番上に来るスクロール位置で開く。
    // 特定のカテゴリの最後の定型文が送信されていれば、次のカテゴリの１件目を先頭に開く。
    // 最後のカテゴリの最後の定型文が送信されていれば、カテゴリ一覧を開く。

    const lastTemplate = Enumerable.from(props.messages)
      .where((x) => x.lastSentTime != null)
      .orderByDescending((x) => x.lastSentTime)
      .firstOrDefault()?.templateMessage;

    if (lastTemplate == null) {
      // テンプレートが未送信であれば一つ目を初期表示する。
      return categories[0]?.firstOrDefault()?.templateMessage;
    } else {
      const category = categories.find(
        (x) => x.key() === lastTemplate.category.id
      );
      assertNotNull(category);
      const lastTemplateInCategory = category
        .orderBy((x) => x.templateMessage.order)
        .last().templateMessage;

      if (lastTemplate.id === lastTemplateInCategory.id) {
        // カテゴリの最後のテンプレートを選んでいたら次のカテゴリの先頭を初期表示する。
        const lastCatIndex = categories.findIndex(
          (x) => x.key() === lastTemplate.category.id
        );
        if (lastCatIndex === categories.length - 1) {
          // 一番最後のカテゴリが最後の送信のため、カテゴリ一覧を初期表示する。
          return undefined;
        } else {
          // 一番最後のカテゴリではないので、次のカテゴリの最初を初期表示する。
          return categories[lastCatIndex + 1].firstOrDefault()?.templateMessage;
        }
      } else {
        // 最後のテンプレートではないので、それを初期表示する。
        return lastTemplate;
      }
    }
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    () => initialTemplate?.category.id
  );

  const resendConfirmDialog = useConfirmContinueDialog();

  return (
    <Box
      width={1}
      height="300px"
      boxShadow={boxShadow}
      bgcolor={'white'}
      display="grid"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Box
        mt={1}
        width={1}
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        alignItems="center"
      >
        {selectedCategoryId == null ? (
          <>
            <CancelButton
              onClick={props.onCancel}
              variant="text"
              sx={{ mr: 'auto' }}
            />
            <Typography variant="h6">{t('templateSelect.title')}</Typography>
          </>
        ) : (
          <>
            <IconButton
              onClick={() => setSelectedCategoryId(undefined)}
              sx={{ ml: 1, mr: 'auto' }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography variant="h6">
              {categories.find((x) => x.key() === selectedCategoryId)?.first()
                .templateMessage.category.name ?? ''}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box width={1} height={1} pb={1} overflow="auto" id="template-scroll">
        {selectedCategoryId == null ? (
          <List sx={{ p: 0 }} key="folders">
            {categories.map((x, i) => (
              <Box key={x.key()}>
                {i > 0 && <Divider variant="middle" />}
                <ListItem
                  secondaryAction={
                    <Typography color={(t) => t.palette.grey[600]}>
                      {props.messages.filter(
                        (msg) => msg.templateMessage.category.id === x.key()
                      ).length + t('templateSelect.unit')}
                    </Typography>
                  }
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => setSelectedCategoryId(x.key())}
                  >
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <FolderIcon
                        sx={{ fontSize: '1.8rem' }}
                        htmlColor="#9dc5f5"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <LineClampTypography variant="body1" rowCount={2}>
                          {x.first().templateMessage.category.name}
                        </LineClampTypography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
            ))}
          </List>
        ) : (
          <List sx={{ p: 0, overflow: 'auto' }} key="messages">
            {Enumerable.from(
              categories
                .find((x) => x.key() === selectedCategoryId)
                ?.toArray() ?? []
            )
              .orderBy((x) => x.templateMessage.order)
              .select((x, i) => (
                <Box key={x.templateMessage.id}>
                  {i > 0 && <Divider variant="middle" component="li" />}
                  <Box
                    display="grid"
                    gridTemplateColumns="8px 1fr 8px"
                    width={1}
                    mt={1}
                  >
                    <Button
                      onClick={async () => {
                        // 送信されている場合はダイアログを表示
                        if (
                          x.lastSentTime != null &&
                          !(await resendConfirmDialog.open({
                            title: '',
                            message: t('templateSelect.confirmResend'),
                          }))
                        ) {
                          return;
                        }
                        props.onSend(x.templateMessage.id);
                      }}
                      sx={{
                        width: 1,
                        gridColumn: 2,
                        backgroundColor:
                          x.lastSentTime != null
                            ? (t) => `${t.palette.grey[200]} !important`
                            : undefined,
                        borderColor:
                          x.lastSentTime != null
                            ? (t) => `${t.palette.grey[300]} !important`
                            : undefined,
                        textTransform: 'none',
                      }}
                      variant="outlined"
                      ref={(element) => {
                        const parent =
                          document.getElementById('template-scroll');
                        if (
                          element &&
                          parent &&
                          x.templateMessage.id === initialTemplate?.id
                        ) {
                          const targetTop = element.getBoundingClientRect().top;
                          const parentTop = parent.getBoundingClientRect().top;
                          const scrollY = targetTop - parentTop - 8;
                          parent.scrollTo({ top: scrollY });
                          setInitialTemplate(undefined);
                        }
                      }}
                    >
                      <Box
                        width={1}
                        display="grid"
                        gridTemplateColumns="1fr auto"
                        alignItems="center"
                      >
                        <Typography
                          textAlign="left"
                          sx={{
                            // 2行まで表示とし、それより多い場合は３点リーダー
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                          }}
                        >
                          {x.templateMessage.languages.find(
                            (y) => y.language === props.language
                          )?.message ?? ''}
                        </Typography>
                        <IconButton
                          size="small"
                          sx={{
                            width: 26,
                            height: 26,
                            mr: -2,
                            ml: '-4px',
                          }}
                          onMouseDown={(e) => {
                            // 親ボタンへのイベントを抑制
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const message =
                              x.templateMessage.languages.find(
                                (y) => y.language === props.language
                              )?.message ?? '';
                            if (
                              await resendConfirmDialog.open({
                                title:
                                  x.lastSentTime != null
                                    ? t('templateSelect.confirmResendBelow')
                                    : t('templateSelect.confirmSendBelow'),
                                message,
                              })
                            ) {
                              props.onSend(x.templateMessage.id);
                            }
                          }}
                        >
                          <OpenInFullIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              ))}
          </List>
        )}
      </Box>
    </Box>
  );
};
export default ChatFooterTemplate;
