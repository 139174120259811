import BookmarkIcon from '@mui/icons-material/Bookmark';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {
  Stack,
  Box,
  Typography,
  CircularProgress,
  SxProps,
  IconButton,
  Divider,
} from '@mui/material';
import Linkify from 'linkify-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLongPress } from '@/components/hooks/useLongPress';
import ChatUserAvator from '@/components/molecules/ChatUserAvator';
import CommentMenu from '@/components/molecules/CommentMenu';
import ImageFiles from '@/components/molecules/ImageFiles';
import ReactionCounter from '@/components/molecules/ReactionCounter';
import {
  ChatReactionType,
  ChatSessionUser,
  ChatUserRole,
} from '@/types/graphql';
import { isChatEditor, isChatGuest } from '@/utils/userRole';

export type CommentProps = {
  user?: ChatSessionUser;
  userRole: ChatUserRole;
  message: string;
  timestamp: Date;
  isUserVisibled: boolean;
  isMine: boolean;
  isError: boolean;
  isSending: boolean;
  transedMessage?: string;
  reverseMessage?: string;
  originalMessage?: string;
  flag: boolean;
  reactions: { userId: string; reactionType: ChatReactionType }[];
  files: { id: string }[];
  isRemoved: boolean;
  isTyping: boolean;
  reply?: { message: string; userName: string };
  reactionIsOnlyQuestion: boolean;
  onShowMenu?: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onReply: () => void;
  onReaction: (type: ChatReactionType) => void;
  onPlay?: () => void;
  onStop: () => void;
  onChangeFlag?: (value: boolean) => void;
  isPlaying?: boolean;
  replaceNgWord: (msg: string) => string;
  onOpenReactions?: () => void;

  // バケツリレー何とかしたい
  chatRoomId: string;
  sessionEntryCode: string;
  isGuestInputRestricted: boolean;
};

/**
 * 単一のチャットコメントを表現する
 * @param props
 * @returns
 */
const Comment = (props: CommentProps) => {
  const sentTime = new Date(props.timestamp);
  const borderRadius = props.isMine ? '10px 0 10px 10px' : '0 10px 10px 10px';
  const { t } = useTranslation('chat');
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenReply, setIsOpenReply] = useState(false);

  const longPressProps = useLongPress(() => setIsOpenMenu(true), 300);
  const sxWithMarginForChatContent: SxProps = {
    ml: props.isMine ? 1 : '43px',
    mr: props.isMine ? 2 : 1,
  };

  const chatUser = useMemo(
    () => ({
      chatRoomId: props.chatRoomId,
      sessionEntryCode: props.sessionEntryCode,
    }),
    [props.chatRoomId, props.sessionEntryCode]
  );
  const imageIdsString = props.files.map((x) => x.id).join(','); // 配列の中身に依存させたい苦肉の処理
  const imageIds = useMemo(() => imageIdsString.split(','), [imageIdsString]);

  // NGワードの削除
  const replaceNgWord = props.replaceNgWord;
  const srcMessage = props.message;
  const srcReplyMessage = props.reply?.message;
  const message = useMemo(
    () => replaceNgWord(srcMessage),
    [replaceNgWord, srcMessage]
  );
  const replyMessage = useMemo(
    () =>
      srcReplyMessage != null ? replaceNgWord(srcReplyMessage) : undefined,
    [replaceNgWord, srcReplyMessage]
  );
  const isGuest = props.user == null || isChatGuest(props.user.userRole);

  return (
    <Stack
      alignItems={props.isMine ? 'end' : 'start'}
      py="6px"
      sx={
        isGuest
          ? undefined
          : {
              paddingTop: '10px',
              borderBottom: 1,
              borderTop: 1,
              borderColor: (t) => t.palette.grey[300],
              background: (t) => t.palette.background.paper,
            }
      }
    >
      {/* ユーザーアイコンとユーザー名 */}
      {props.isUserVisibled && (
        <Stack direction="row" alignItems="center" ml="10px">
          <ChatUserAvator userRole={props.userRole} />
          <Typography
            variant="body2"
            color={(t) => t.palette.grey[700]}
            mx={1}
            fontWeight={'bold'}
          >
            {props.user?.userName ?? 'Unknown'}
          </Typography>
        </Stack>
      )}
      {/* 時間とメッセージ表示 */}
      <Stack
        direction={props.isMine ? 'row-reverse' : 'row'}
        alignItems="flex-end"
        sx={sxWithMarginForChatContent}
        spacing={1}
      >
        {props.isRemoved === false ? (
          <Box
            // 吹き出しBOX
            ref={setMenuAnchor}
            border={1}
            borderColor={(t) =>
              props.isPlaying ? t.palette.primary.main : '#0000'
            }
            bgcolor={props.isMine ? '#CFEBFA' : '#DBE2E5'}
            sx={{
              textAlign: 'left',
              borderRadius: borderRadius,
              cursor: props.onPlay == null ? 'default' : 'pointer',
              position: 'relative',

              // 音声再生時のブルーアニメーション
              animation: props.isPlaying
                ? 'width-increase 1s ease-in-out infinite'
                : undefined,
              '@keyframes width-increase': {
                '0%': {
                  boxShadow: (t) =>
                    `0px 0px 0px rgb(${t.palette.primary.main},0)`,
                },
                '50%': {
                  boxShadow: (t) => `0px 0px 16px ${t.palette.primary.main}`,
                },
                '100%': {
                  boxShadow: (t) =>
                    `0px 0px 0px rgb(${t.palette.primary.main},0)`,
                },
              },
            }}
            onContextMenu={
              isChatEditor(props.isGuestInputRestricted, props.userRole)
                ? (e) => {
                    e.preventDefault();
                    props.onShowMenu?.();
                    setIsOpenMenu(true);
                  }
                : undefined
            }
            onClick={() => props.onPlay?.()}
            {...(isChatEditor(props.isGuestInputRestricted, props.userRole)
              ? longPressProps
              : {})}
          >
            {props.reply != null && (
              <>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenReply((x) => !x);
                  }}
                >
                  <Stack>
                    <Typography
                      variant="caption"
                      width="100%"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      fontWeight="bold"
                      px={2}
                      mt={1}
                    >
                      {props.reply.userName}
                    </Typography>
                    <Box
                      position="relative"
                      width="100%"
                      height={isOpenReply ? undefined : '23px'}
                      pb={1}
                    >
                      <Typography
                        width="100%"
                        px={2}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        position={isOpenReply ? undefined : 'absolute'}
                        whiteSpace={isOpenReply ? undefined : 'nowrap'}
                        variant={isOpenReply ? undefined : 'caption'}
                      >
                        {replyMessage}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Divider />
              </>
            )}
            <Box px={2} py={1}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  overflowWrap: 'anywhere',
                }}
                fontWeight={isGuest ? 'normal' : 'bold'}
              >
                {isGuest ? message : <Linkify>{message}</Linkify>}
                {props.isTyping && (
                  <CircularProgress size={14} sx={{ ml: '4px', mb: '-2px' }} />
                )}
              </Typography>
              {props.files.length > 0 && (
                <ImageFiles imageIds={imageIds} chatUser={chatUser} />
              )}
            </Box>
            {props.flag && (
              <BookmarkIcon
                color="primary"
                fontSize="small"
                sx={{
                  position: 'absolute',
                  right: props.isMine ? undefined : '-1px',
                  left: props.isMine ? '-1px' : undefined,
                  top: '-5px',
                }}
              />
            )}
          </Box>
        ) : (
          <Typography variant="caption">
            {t('comment.removedMessage')}
          </Typography>
        )}
        {props.isError && (
          <ErrorOutlineOutlinedIcon color="error" sx={{ mb: '6px' }} />
        )}
        {props.isSending && (
          <Typography variant="caption">{t('comment.sending')}</Typography>
        )}
        {props.isError === false && props.isSending === false && (
          <Stack
            direction={'column'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            {props.isPlaying && (
              <IconButton
                sx={{ p: 0, mt: '-100px', mb: '-4px' }}
                onClick={props.onStop}
              >
                <StopCircleIcon />
              </IconButton>
            )}
            <Typography variant="caption" color={(t) => t.palette.grey[600]}>
              {`${sentTime.getHours().toString().padStart(2, '0')}:${sentTime
                .getMinutes()
                .toString()
                .padStart(2, '0')}`}
            </Typography>
          </Stack>
        )}
      </Stack>
      {/* 翻訳 */}
      {props.isRemoved === false && props.originalMessage && (
        <Typography sx={sxWithMarginForChatContent} mt={0.5} variant="caption">
          {props.originalMessage}
        </Typography>
      )}
      {props.isRemoved === false && props.transedMessage && (
        <Typography sx={sxWithMarginForChatContent} mt={0.5} variant="caption">
          {props.transedMessage}
        </Typography>
      )}
      {props.isRemoved === false && props.reverseMessage && (
        <Typography sx={sxWithMarginForChatContent} mt={0.5} variant="caption">
          {props.reverseMessage}
        </Typography>
      )}
      {/* リアクション */}
      <Stack
        sx={sxWithMarginForChatContent}
        mt={0.5}
        alignItems={props.isMine ? 'flex-end' : 'flex-start'}
      >
        <ReactionCounter
          reactions={props.reactions}
          onOpenReactions={props.onOpenReactions}
        />
      </Stack>
      <CommentMenu
        onReaction={props.isError ? undefined : props.onReaction}
        onDelete={
          props.isError || props.isMine === false ? undefined : props.onDelete
        }
        onEdit={props.isMine === false ? undefined : props.onEdit}
        onReply={props.isError ? undefined : props.onReply}
        onPlay={props.onPlay}
        onChangeFlag={props.onChangeFlag}
        flag={props.flag}
        isOwnMenu={props.isMine}
        reactionIsOnlyQuestion={props.reactionIsOnlyQuestion}
        open={Boolean(isOpenMenu)}
        onClose={() => setIsOpenMenu(false)}
        anchorEl={menuAnchor}
      />
    </Stack>
  );
};
export default Comment;
