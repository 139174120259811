import { AdminContent } from '@/components/atoms/AdminPageParts';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import VadFormContainer from '@/components/organisms/admin/VadFormContainer';

const VadIndex = () => {
  useCheckPermission({ superAdmin: true });

  useSetHeaderTitle('VAD設定');

  return (
    <AdminContent>
      <VadFormContainer />
    </AdminContent>
  );
};

export default VadIndex;
