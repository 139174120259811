import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import PreviewImageBackdrop, {
  PreviewImageBackdropProps,
} from '@/components/organisms/previewImageBackdrop/PreviewImageBackdrop';

const PreviewImageBackdropEx = (
  props: Omit<PreviewImageBackdropProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <PreviewImageBackdrop open={isOpen} {...props} />;
};

const useTemplateSelectorDialog = () => {
  const openAwaiter = useOpenAwaiter<void>();
  return {
    open: (props: Omit<PreviewImageBackdropProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <PreviewImageBackdropEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useTemplateSelectorDialog;
