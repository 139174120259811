import { styled } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

export const PieCenterLabel = ({ count }: { count: number }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText x={left + width / 2} y={top + height / 2 - 10}>
        使用言語数
      </StyledText>
      <StyledText x={left + width / 2} y={top + height / 2 + 15}>
        {count.toString()}
      </StyledText>
    </>
  );
};
