import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type AddChatRoomInput = {
  /** 最大人数 */
  capacity: Scalars['Int'];
  /** 同音異義語置換辞書カテゴリー */
  homophoneCategoryIds: Array<Scalars['String']>;
  /** ゲストの発言や入力を制限するかどうか */
  isGuestInputRestricted: Scalars['Boolean'];
  /** 参加者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuestMicControlLinked: Scalars['Boolean'];
  /** 主催者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuideMicControlLinked: Scalars['Boolean'];
  /** 発言時に参加者へプッシュ通知を送るかどうか */
  isNotifyOnMessageEnabled: Scalars['Boolean'];
  /** 待機室を付けるか */
  isWaitingRoomEnabled: Scalars['Boolean'];
  /** 待機室入室時に通知を出すかどうか */
  isWaitingRoomNotificationEnabled: Scalars['Boolean'];
  /** 部屋名 */
  name: Scalars['String'];
  /** 定型文カテゴリー */
  templateCategoryIds: Array<Scalars['String']>;
  /** テナントカンパニー（チャットルームの作成者） */
  tenantCompanyId: Scalars['ID'];
  /** 通訳方式 */
  translateMethod: TranslateMethod;
};

export type AddChatRoomResult = ChatRoom | ExcessRoomCreationLimit;

export type AddChatSessionInput = {
  /** チャットルームID */
  chatRoomId: Scalars['ID'];
  /** シーケンス番号 */
  currentSequence?: InputMaybe<Scalars['Int']>;
  /** 入室コード */
  entryCode?: InputMaybe<Scalars['String']>;
  /** セッション名 */
  name: Scalars['String'];
  /** チャットセッションステート */
  state?: InputMaybe<ChatSessionState>;
};

export type AddChatTemplateMessageInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** 言語名 */
  language: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** 定型文ID */
  templateMessageId: Scalars['ID'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type AddFileChatUserInput = {
  chatRoomId: Scalars['String'];
  sessionEntryCode: Scalars['String'];
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type AddFileInput = {
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
};

export type AddFilesInput = {
  /** チャットユーザーとして画像を登録する場合に指定。未指定時はログイン必須 */
  chatUser?: InputMaybe<AddFileChatUserInput>;
  /** ファイルリスト */
  files: Array<AddFileInput>;
};

export type AddHomophoneCategoryInput = {
  /** 名前 */
  name: Scalars['String'];
  /** テナント企業ID */
  tenantCompanyId: Scalars['ID'];
};

export type AddHomophoneInput = {
  /** 無効かどうか */
  disabled: Scalars['Boolean'];
  /** カテゴリID */
  homophoneCategoryId: Scalars['ID'];
  /** 読み（ひらがな） */
  kana: Scalars['String'];
  /** 修正したい文字 */
  sourceWord: Scalars['String'];
  /** 修正後の文字 */
  targetWord: Scalars['String'];
};

export type AddNgWordCategoryInput = {
  /** 名前 */
  name: Scalars['String'];
};

export type AddNgWordInput = {
  /** カテゴリ */
  ngWordCategoryId: Scalars['ID'];
  /** 単語 */
  word: Scalars['String'];
};

export type AddOwnerCompanyInput = {
  /** 同時翻訳選択可否 */
  canUseSimulteneous: Scalars['Boolean'];
  /** 企業コード */
  code: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 備考 */
  note?: InputMaybe<Scalars['String']>;
  /** アクティブ可能ルーム数 */
  roomLimit: Scalars['Int'];
  /** 初期パスワード */
  temporaryPassword: Scalars['String'];
  /** 登録ユーザー数上限 */
  userLimit: Scalars['Int'];
  /** ユーザー名 */
  userName: Scalars['String'];
};

export type AddOwnerCompanyResult = OwnerCompany | UserNameAlreadyExists;

export type AddSuperUserInput = {
  /** 初期パスワード */
  temporaryPassword: Scalars['String'];
  /** ユーザー名 */
  userName: Scalars['String'];
};

export type AddSuperUserResult = SuperUser | UserNameAlreadyExists;

export type AddTemplateMessageAttachedFileInput = {
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  /** 定型文ID */
  templateMessageId: Scalars['ID'];
};

export type AddTemplateMessageCategoryInput = {
  /** カテゴリ名 */
  name: Scalars['String'];
  /** テナントカンパニー */
  tenantCompanyId: Scalars['ID'];
};

export type AddTemplateMessageInput = {
  /** ファイル */
  files: Array<TemplateMessageFileInput>;
  /** 言語ごとのメッセージ */
  languages: Array<TemplateMessageLanguageInput>;
  /** カテゴリ */
  templateCategoryId: Scalars['String'];
};

export type AddTenantCompanyInput = {
  /** ガイド管理者名 */
  guideManagerName: Scalars['String'];
  /** 音声再生 */
  isEnableVoice: Scalars['Boolean'];
  /** 備考 */
  note?: InputMaybe<Scalars['String']>;
  ownerCompanyId: Scalars['ID'];
  /** ルーム作成可能数 */
  roomCreationLimit: Scalars['Int'];
  /** アクティブルーム数 */
  roomLimit: Scalars['Int'];
  /** 初期パスワード */
  temporaryPassword: Scalars['String'];
  /** ユーザー名 */
  userName: Scalars['String'];
  /** VADプリセットID */
  vadPresetId?: InputMaybe<Scalars['ID']>;
};

export type AddTenantCompanyResult = ExcessTenantCompanyNumberLimit | ExcessTenantCompanyRoomNumberLimit | TenantCompany | UserNameAlreadyExists;

export type AddUserInput = {
  /** チャット入室のためのユーザーID */
  chatUserId?: InputMaybe<Scalars['String']>;
  /** チャット入室のためのユーザーパスワード */
  chatUserPassword?: InputMaybe<Scalars['String']>;
  /** 利用企業ID */
  ownerCompanyId?: InputMaybe<Scalars['String']>;
  /** ユーザーロール */
  role: UserRole;
  /** ガイド管理者ID */
  tenantCompanyId?: InputMaybe<Scalars['String']>;
};

export type AddVadPresetInput = {
  energyIntegration: Scalars['Float'];
  energyOffset: Scalars['Float'];
  energyThresholdRatioNeg: Scalars['Float'];
  energyThresholdRatioPos: Scalars['Float'];
  /** ラベル */
  label: Scalars['String'];
  /** VAD設定 */
  smoothingTimeConstant: Scalars['Float'];
};

export type AdminSettings = {
  __typename?: 'AdminSettings';
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

export type AdminSettingsInput = {
  /** Key */
  key?: InputMaybe<Scalars['String']>;
};

export type AnalyticsInput = {
  /** 日付範囲 */
  dateRange: DateRange;
  /** 利用企業ID（指定なしの場合は全件取得） */
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
};

export type AnalyticsRoom = {
  __typename?: 'AnalyticsRoom';
  /** ルームID */
  chatRoomId: Scalars['ID'];
  /** ガイド言語 */
  guideLanguage: Scalars['String'];
  /** 発言数 */
  messageCount: Scalars['Int'];
  /** ルーム名 */
  name: Scalars['String'];
  /** 参加者数 */
  userCount: Scalars['Int'];
};

export type AnalyticsUsageAll = {
  __typename?: 'AnalyticsUsageAll';
  /** 利用ルーム数 */
  activeRoomCount: Scalars['Int'];
  /** 最大同時利用者数 */
  maxUserCount: Scalars['Int'];
  /** 利用企業ID */
  ownerCompanyId: Scalars['ID'];
  /** 利用企業名 */
  ownerCompanyName: Scalars['String'];
  /** 累積利用者数 */
  sumUserCount: Scalars['Int'];
};

export type AnalyticsUsageByTime = {
  __typename?: 'AnalyticsUsageByTime';
  /** データ本体 */
  data: Array<AnalyticsUsageByTimeData>;
  /** 間隔 */
  interval: Interval;
};

export type AnalyticsUsageByTimeData = {
  __typename?: 'AnalyticsUsageByTimeData';
  /** 利用ルーム数 */
  activeRooms: Array<AnalyticsRoom>;
  /** 日付 */
  date: Scalars['DateTime'];
  /** 最大同時利用者数 */
  maxUserCount: Scalars['Int'];
  /** 累積利用者数 */
  sumUserCount: Scalars['Int'];
};

export type AnalyticsUserCount = {
  __typename?: 'AnalyticsUserCount';
  /** データ本体 */
  data: Array<AnalyticsUserCountData>;
  /** 間隔 */
  interval: Interval;
  /** 累計ユーザー数 */
  sumUserCount: Scalars['Int'];
};

export type AnalyticsUserCountData = {
  __typename?: 'AnalyticsUserCountData';
  /** 人数 */
  count: Scalars['Int'];
  /** 日付 */
  date: Scalars['DateTime'];
};

export type AnalyticsUserLanguage = {
  __typename?: 'AnalyticsUserLanguage';
  /** 人数 */
  count: Scalars['Int'];
  /** 言語 */
  language: Scalars['String'];
};

export type ChatCommand = {
  __typename?: 'ChatCommand';
  commandType: Scalars['String'];
  value: Scalars['String'];
};

export type ChatCommandExecutedInput = {
  /** QRコードの部屋番号に対応する */
  chatRoomId: Scalars['String'];
  /** ワンタイムパスワードに対応する */
  sessionEntryCode: Scalars['String'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  /** ファイル */
  files: Array<ChatMessageFile>;
  /** メッセージ自体の固有ID */
  id: Scalars['ID'];
  /** フラグ */
  isMessageFlag: Scalars['Boolean'];
  /** 削除済みフラグ */
  isRemoved: Scalars['Boolean'];
  /** テンプレートメッセージフラグ */
  isTemplate: Scalars['Boolean'];
  /** 入力中フラグ */
  isTyping: Scalars['Boolean'];
  /** 言語ごとのメッセージ */
  languages: Array<ChatMessageLanguage>;
  /** リアクション */
  reactions: Array<ChatReaction>;
  /** 返信情報 */
  reply?: Maybe<ReplyInfo>;
  /** 逆翻訳の経由言語 */
  reverseLanguage?: Maybe<Scalars['String']>;
  /** 逆翻訳したメッセージ */
  reverseMessage?: Maybe<Scalars['String']>;
  /** セッション内シーケンス */
  sequence: Scalars['Int'];
  /** 送信タイムスタンプ(UTC) */
  timestamp: Scalars['DateTime'];
  /** 更新タイムスタンプ(UTC) */
  updateAt: Scalars['DateTime'];
  /** メッセージの送信者ID */
  userId: Scalars['String'];
};

export type ChatMessageAddedInput = {
  /** QRコードの部屋番号に対応する */
  chatRoomId: Scalars['String'];
  /** ユーザーID */
  chatUserId: Scalars['String'];
  /** 言語名 */
  language: Scalars['String'];
  /** Push通知への購読情報（JSONデータ） */
  pushSubscription?: InputMaybe<Scalars['String']>;
  /** 入室コードに対応する */
  sessionEntryCode: Scalars['String'];
};

export type ChatMessageFile = {
  __typename?: 'ChatMessageFile';
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
};

export type ChatMessageLanguage = {
  __typename?: 'ChatMessageLanguage';
  /** 送信されたオリジナルの言語かどうか */
  isOriginal: Scalars['Boolean'];
  /** 言語名 */
  language: Scalars['String'];
  /** 本文 */
  message: Scalars['String'];
};

export type ChatMessagesInput = {
  /** どのシーケンスより後のデータを取得するかを指定 */
  afterUpdateAt?: InputMaybe<Scalars['DateTime']>;
  /** どのシーケンスより前のデータを取得するかを指定 */
  beforeSequence?: InputMaybe<Scalars['Int']>;
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** 取得するデータ件数 */
  count: Scalars['Int'];
  /** 取得する言語 */
  language: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
};

/** リアクション情報 */
export type ChatReaction = {
  __typename?: 'ChatReaction';
  /** リアクション種別 */
  reactionType: ChatReactionType;
  /** リアクションを実行したユーザー */
  userId: Scalars['String'];
};

export enum ChatReactionType {
  Clap = 'CLAP',
  Happy = 'HAPPY',
  Like = 'LIKE',
  Love = 'LOVE',
  None = 'NONE',
  Question = 'QUESTION',
  Struggle = 'STRUGGLE'
}

export type ChatRoom = {
  __typename?: 'ChatRoom';
  /** 最大人数 */
  capacity: Scalars['Int'];
  /** 作成日時 */
  createdAt: Scalars['DateTime'];
  /** 同音異義語置換辞書カテゴリー */
  homophoneCategoryIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  /** ゲストの発言や入力を制限するかどうか */
  isGuestInputRestricted: Scalars['Boolean'];
  /** 参加者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuestMicControlLinked: Scalars['Boolean'];
  /** 主催者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuideMicControlLinked: Scalars['Boolean'];
  /** 発言時に参加者へプッシュ通知を送るかどうか */
  isNotifyOnMessageEnabled: Scalars['Boolean'];
  /** 待機室を付けるか */
  isWaitingRoomEnabled: Scalars['Boolean'];
  /** 待機室入室時に通知を出すかどうか */
  isWaitingRoomNotificationEnabled: Scalars['Boolean'];
  /** 部屋名 */
  name: Scalars['String'];
  /** セッション */
  sessions: Array<ChatSession>;
  /** 定型文カテゴリー */
  templateCategoryIds: Array<Scalars['String']>;
  /** テナント企業ID */
  tenantCompanyId: Scalars['String'];
  /** 通訳方式 */
  translateMethod: TranslateMethod;
  /** 待機中のゲスト */
  waitingGuests: Array<ChatRoomWaitingGuest>;
};

export type ChatRoomWaitingGuest = {
  __typename?: 'ChatRoomWaitingGuest';
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
};

export type ChatRoomsInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** 利用企業ID */
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
};

export type ChatRoomsTemplateMessage = {
  __typename?: 'ChatRoomsTemplateMessage';
  /** セッション内で最後に送信された日時 */
  lastSentTime?: Maybe<Scalars['DateTime']>;
  templateMessage: TemplateMessage;
};

export type ChatRoomsTemplateMessageInput = {
  chatRoomId: Scalars['String'];
  chatUser: TemplateMessagesChatUserInput;
};

export type ChatSession = {
  __typename?: 'ChatSession';
  /** チャットルームID */
  chatRoomId: Scalars['ID'];
  /** シーケンス番号 */
  currentSequence: Scalars['Int'];
  /** 入室コード */
  entryCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** セッション名 */
  name: Scalars['String'];
  /** チャットセッションステート */
  state: ChatSessionState;
};

export type ChatSessionInfo = {
  __typename?: 'ChatSessionInfo';
  clearedSequence: Scalars['Int'];
  entryCode: Scalars['String'];
  isEnableVoice: Scalars['Boolean'];
  isGuestInputRestricted: Scalars['Boolean'];
  isGuestMicControlLinked: Scalars['Boolean'];
  isGuideMicControlLinked: Scalars['Boolean'];
  isNotifyOnMessageEnabled: Scalars['Boolean'];
  isQuestionMode: Scalars['Boolean'];
  isWaitingRoomNotificationEnabled: Scalars['Boolean'];
  roomName: Scalars['String'];
  sessionId: Scalars['String'];
  sessionName: Scalars['String'];
  state: ChatSessionState;
  translateMethod: TranslateMethod;
  ttsService: TtsService;
  vadEnergyIntegration: Scalars['Float'];
  vadEnergyOffset: Scalars['Float'];
  vadEnergyThresholdRatioNeg: Scalars['Float'];
  vadEnergyThresholdRatioPos: Scalars['Float'];
  vadSmoothingTimeConstant: Scalars['Float'];
};

export type ChatSessionInfoInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** 管理者ID */
  chatUserId?: InputMaybe<Scalars['String']>;
  /** 管理者パスワード */
  chatUserPassword?: InputMaybe<Scalars['String']>;
  /** セッションパスワード */
  sessionEntryCode: Scalars['String'];
};

export enum ChatSessionState {
  /** 終了 */
  Ended = 'ENDED',
  /** 存在しない */
  NotFound = 'NOT_FOUND',
  /** 開始前 */
  NotStarted = 'NOT_STARTED',
  /** 進行中 */
  Progressing = 'PROGRESSING'
}

/** セッションへのユーザー参加状況を受け取るための型 */
export type ChatSessionUser = {
  __typename?: 'ChatSessionUser';
  /** 音声入力が可能かどうか */
  canUseMic: Scalars['Boolean'];
  /** 音声出力が可能かどうか */
  canUseSpeaker: Scalars['Boolean'];
  /**
   * 参加中かどうかのフラグ
   * 退席時にはfalseとなる
   */
  isJoining: Scalars['Boolean'];
  /** 待機中の場合はtrueとなる */
  isWaiting: Scalars['Boolean'];
  /** ユーザーの言語 */
  language: Scalars['String'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザー名 */
  userName: Scalars['String'];
  /** ユーザーロール */
  userRole: ChatUserRole;
};

export type ChatSessionUserModifiedInput = {
  /** 音声入力が可能かどうか */
  canUseMic: Scalars['Boolean'];
  /** 音声出力が可能かどうか */
  canUseSpeaker: Scalars['Boolean'];
  /** QRコードの部屋番号に対応する */
  chatRoomId: Scalars['String'];
  /** ユーザーの言語名 */
  language: Scalars['String'];
  /** 入室コードに対応する */
  sessionEntryCode: Scalars['String'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザー名 */
  userName: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export enum ChatUserRole {
  /** ゲスト */
  Guest = 'GUEST',
  /** ガイド */
  Guide = 'GUIDE',
  /** システム */
  System = 'SYSTEM',
  /** ガイド管理者 */
  TenantCompanyAdmin = 'TENANT_COMPANY_ADMIN'
}

export type ClearChatSessionInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type DateRange = {
  dateEnd: Scalars['DateTime'];
  /** 日付範囲 */
  dateStart: Scalars['DateTime'];
};

export type DownloadCsvurl = {
  __typename?: 'DownloadCSVURL';
  /** CSVのダウンロードURL */
  downloadUrl: Scalars['String'];
};

export type EditAdminSettingsInput = {
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

export type EditChatMessageFlagInput = {
  /** メッセージ自体の固有ID */
  chatMessageId: Scalars['String'];
  /** フラグON/OFF */
  isMessageFlag: Scalars['Boolean'];
};

export type EditChatRoomInput = {
  /** 最大人数 */
  capacity: Scalars['Int'];
  /** 同音異義語置換辞書カテゴリー */
  homophoneCategoryIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  /** ゲストの発言や入力を制限するかどうか */
  isGuestInputRestricted: Scalars['Boolean'];
  /** 参加者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuestMicControlLinked: Scalars['Boolean'];
  /** 主催者の音声入力開始・終了ボタンをマイクと連携するかどうか */
  isGuideMicControlLinked: Scalars['Boolean'];
  /** 発言時に参加者へプッシュ通知を送るかどうか */
  isNotifyOnMessageEnabled: Scalars['Boolean'];
  /** 待機室を付けるか */
  isWaitingRoomEnabled: Scalars['Boolean'];
  /** 待機室入室時に通知を出すかどうか */
  isWaitingRoomNotificationEnabled: Scalars['Boolean'];
  /** 部屋名 */
  name: Scalars['String'];
  /** 定型文カテゴリー */
  templateCategoryIds: Array<Scalars['String']>;
  /** 通訳方式 */
  translateMethod: TranslateMethod;
};

export type EditChatSessionInput = {
  /** チャットルームID */
  chatRoomId: Scalars['ID'];
  /** 入室コード */
  entryCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type EditHomophoneCategoryInput = {
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
};

export type EditHomophoneInput = {
  /** 無効かどうか */
  disabled: Scalars['Boolean'];
  /** カテゴリID */
  homophoneCategoryId: Scalars['ID'];
  id: Scalars['ID'];
  /** 読み（ひらがな） */
  kana: Scalars['String'];
  /** 修正したい文字 */
  sourceWord: Scalars['String'];
  /** 修正後の文字 */
  targetWord: Scalars['String'];
};

export type EditNgWordCategoryInput = {
  /** 名前 */
  name: Scalars['String'];
};

export type EditNgWordInput = {
  id: Scalars['ID'];
  /** カテゴリ */
  ngWordCategoryId: Scalars['ID'];
  /** 単語 */
  word: Scalars['String'];
};

export type EditOwnerCompanyInput = {
  /** 同時翻訳選択可否 */
  canUseSimulteneous: Scalars['Boolean'];
  /** 企業コード */
  code: Scalars['String'];
  id: Scalars['ID'];
  /** 名称 */
  name: Scalars['String'];
  /** 新規パスワード */
  newPassword?: InputMaybe<Scalars['String']>;
  /** 備考 */
  note?: InputMaybe<Scalars['String']>;
  /** アクティブ可能ルーム数 */
  roomLimit: Scalars['Int'];
  /** 登録ユーザー数上限 */
  userLimit: Scalars['Int'];
  /** ユーザー名 */
  userName?: InputMaybe<Scalars['String']>;
};

export type EditOwnerCompanyResult = OwnerCompany | UserNameAlreadyExists;

export type EditSuperUserInput = {
  id: Scalars['ID'];
  /** 新規パスワード */
  newPassword?: InputMaybe<Scalars['String']>;
  /** ユーザー名 */
  userName?: InputMaybe<Scalars['String']>;
};

export type EditSuperUserResult = SuperUser | UserNameAlreadyExists;

export type EditTemplateMessageCategoryInput = {
  id: Scalars['ID'];
  /** カテゴリ名 */
  name: Scalars['String'];
};

export type EditTemplateMessageInput = {
  /** ファイル */
  files: Array<TemplateMessageFileInput>;
  id: Scalars['ID'];
  /** 言語ごとのメッセージ */
  languages: Array<TemplateMessageLanguageInput>;
  /** カテゴリ内での順番 */
  order?: InputMaybe<Scalars['Int']>;
  /** カテゴリ */
  templateCategoryId: Scalars['String'];
};

export type EditTenantCompanyInput = {
  /** ガイド管理者名 */
  guideManagerName: Scalars['String'];
  id: Scalars['ID'];
  /** 音声再生 */
  isEnableVoice: Scalars['Boolean'];
  /** 新規パスワード */
  newPassword?: InputMaybe<Scalars['String']>;
  /** 備考 */
  note?: InputMaybe<Scalars['String']>;
  /** ルーム作成可能数 */
  roomCreationLimit: Scalars['Int'];
  /** アクティブルーム数 */
  roomLimit: Scalars['Int'];
  /** ユーザー名 */
  userName?: InputMaybe<Scalars['String']>;
  /** VADプリセットID */
  vadPresetId?: InputMaybe<Scalars['ID']>;
};

export type EditTenantCompanyResult = ExcessTenantCompanyRoomNumberLimit | TenantCompany | UserNameAlreadyExists;

export type EditUserSettingsInput = {
  /** ニックネーム */
  name: Scalars['String'];
};

export type EditVadPresetInput = {
  energyIntegration: Scalars['Float'];
  energyOffset: Scalars['Float'];
  energyThresholdRatioNeg: Scalars['Float'];
  energyThresholdRatioPos: Scalars['Float'];
  id: Scalars['ID'];
  /** ラベル */
  label: Scalars['String'];
  /** VAD設定 */
  smoothingTimeConstant: Scalars['Float'];
};

export type EndChatSessionInput = {
  /** チャットセッションID */
  chatSessionId: Scalars['ID'];
  /** 入室コード */
  sessionEntryCode: Scalars['String'];
  /** ユーザーID */
  userId?: InputMaybe<Scalars['String']>;
  /** ユーザーパスワード */
  userPassword?: InputMaybe<Scalars['String']>;
};

export type ExcessRoomCreationLimit = {
  __typename?: 'ExcessRoomCreationLimit';
  limit: Scalars['Int'];
  message: Scalars['String'];
};

export type ExcessTenantCompanyNumberLimit = {
  __typename?: 'ExcessTenantCompanyNumberLimit';
  message: Scalars['String'];
};

export type ExcessTenantCompanyRoomNumberLimit = {
  __typename?: 'ExcessTenantCompanyRoomNumberLimit';
  limit: Scalars['Int'];
  message: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
  /** 署名付きURL。チャット履歴取得時などは空欄になるため、別途 filesから取得する必要がある */
  signedURL: Scalars['String'];
};

export type FileChatUserInput = {
  chatRoomId: Scalars['String'];
  sessionEntryCode: Scalars['String'];
};

export type FilesInput = {
  chatUser?: InputMaybe<FileChatUserInput>;
  ids: Array<Scalars['String']>;
};

export type FlagMessage = {
  __typename?: 'FlagMessage';
  /** チャットルーム */
  chatRoomId: Scalars['ID'];
  chatRoomName: Scalars['String'];
  /** メッセージ自体の固有ID */
  id: Scalars['ID'];
  /** フラグ */
  isMessageFlag: Scalars['Boolean'];
  /** 言語ごとのメッセージ */
  languages: Array<FlagMessageLanguage>;
};

export type FlagMessageLanguage = {
  __typename?: 'FlagMessageLanguage';
  /** 送信されたオリジナルの言語かどうか */
  isOriginal: Scalars['Boolean'];
  /** 言語名 */
  language: Scalars['String'];
  /** 本文 */
  message: Scalars['String'];
};

export type GuideUsersInput = {
  /** ガイド管理者ID */
  tenantCompanyId: Scalars['String'];
};

export type Homophone = {
  __typename?: 'Homophone';
  /** 作成日 */
  createdAt: Scalars['DateTime'];
  /** 無効かどうか */
  disabled: Scalars['Boolean'];
  /** カテゴリ */
  homophoneCategory: HomophoneCategory;
  id: Scalars['ID'];
  /** 読み（ひらがな） */
  kana: Scalars['String'];
  /** 修正したい文字 */
  sourceWord: Scalars['String'];
  /** 修正後の文字 */
  targetWord: Scalars['String'];
  /** 利用回数 */
  usageCount: Scalars['Int'];
};

export type HomophoneCategoriesInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type HomophoneCategory = {
  __typename?: 'HomophoneCategory';
  /** 作成日 */
  createdAt: Scalars['DateTime'];
  /** カテゴリ内のレコード件数（Queryでのみセットされる） */
  homophonesCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
};

export type HomophonesInput = {
  /** カテゴリ指定で複数取得 */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** IDで単体を取得 */
  id?: InputMaybe<Scalars['ID']>;
};

export type ImportHomophone = {
  __typename?: 'ImportHomophone';
  disabled: Scalars['Boolean'];
  /** 0はエラーなし */
  errorCode: Scalars['Int'];
  homophoneCategoryId: Scalars['String'];
  homophoneCategoryName: Scalars['String'];
  homophoneId: Scalars['String'];
  kana: Scalars['String'];
  sourceWord: Scalars['String'];
  targetWord: Scalars['String'];
};

export type ImportHomophoneInput = {
  /** 無効かどうか */
  disabled: Scalars['Boolean'];
  /** カテゴリID */
  homophoneCategoryId: Scalars['String'];
  /** カテゴリ名 */
  homophoneCategoryName: Scalars['String'];
  /** ID */
  homophoneId: Scalars['String'];
  /** 読み（ひらがな） */
  kana: Scalars['String'];
  /** 修正したい文字 */
  sourceWord: Scalars['String'];
  /** 修正後の文字 */
  targetWord: Scalars['String'];
};

export type ImportHomophonesInput = {
  homophones: Array<ImportHomophoneInput>;
};

export type ImportNgWord = {
  __typename?: 'ImportNgWord';
  /** 0はエラーなし */
  errorCode: Scalars['Int'];
  ngWordCategoryId: Scalars['String'];
  ngWordCategoryName: Scalars['String'];
  ngWordId: Scalars['String'];
  word: Scalars['String'];
};

export type ImportNgWordInput = {
  ngWordCategoryId: Scalars['String'];
  ngWordCategoryName: Scalars['String'];
  ngWordId: Scalars['String'];
  word: Scalars['String'];
};

export type ImportNgWordsInput = {
  ngWords: Array<ImportNgWordInput>;
};

export type ImportTemplateMessage = {
  __typename?: 'ImportTemplateMessage';
  /** 0はエラーなし */
  errorCode: Scalars['Int'];
  languages: Array<TemplateMessageLanguage>;
  templateMessageCategoryId: Scalars['String'];
  templateMessageCategoryName: Scalars['String'];
  templateMessageId: Scalars['String'];
};

export type ImportTemplateMessageInput = {
  languages: Array<ImportTemplateMessageLanguageInput>;
  templateMessageCategoryId: Scalars['String'];
  templateMessageCategoryName: Scalars['String'];
  templateMessageId: Scalars['String'];
};

export type ImportTemplateMessageLanguageInput = {
  language: Scalars['String'];
  message: Scalars['String'];
};

/** インポート関連 */
export type ImportTemplateMessagesInput = {
  templateMessages: Array<ImportTemplateMessageInput>;
};

export enum Interval {
  /** 日次 */
  Dayly = 'DAYLY',
  /** 時次 */
  Hourly = 'HOURLY',
  /** 月次 */
  Monthly = 'MONTHLY',
  /** 週次 */
  Weekly = 'WEEKLY',
  /** 年次 */
  Yearly = 'YEARLY'
}

export type LanguageUsage = {
  __typename?: 'LanguageUsage';
  /** 利用ユーザ数 */
  count: Scalars['Int'];
  /** 言語名 */
  language: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addChatRoom: AddChatRoomResult;
  addChatSession: ChatSession;
  /** チャットセッションに定型文メッセージを追加 */
  addChatTemplateMessage: ChatMessage;
  addFiles: Array<File>;
  addHomophone: Homophone;
  addHomophoneCategory: HomophoneCategory;
  addNgWord: NgWord;
  addNgWordCategory: NgWordCategory;
  addOwnerCompany: AddOwnerCompanyResult;
  addSuperUser: AddSuperUserResult;
  addTemplateMessage: TemplateMessage;
  addTemplateMessageCategory: TemplateMessageCategory;
  addTenantCompany: AddTenantCompanyResult;
  addUser: User;
  addVadPreset: VadPreset;
  /** セッションの全メッセージをクリア */
  clearChatSession: Scalars['Boolean'];
  editAdminSettings: AdminSettings;
  editChatMessageFlag: FlagMessage;
  editChatRoom: ChatRoom;
  editChatSession: ChatSession;
  editHomophone: Homophone;
  editHomophoneCategory: HomophoneCategory;
  editNgWord: NgWord;
  editNgWordCategory: NgWordCategory;
  editOwnerCompany: EditOwnerCompanyResult;
  editSuperUser: EditSuperUserResult;
  editTemplateMessage: TemplateMessage;
  editTemplateMessageCategory: TemplateMessageCategory;
  editTenantCompany: EditTenantCompanyResult;
  editUserSettings: UserSettings;
  editVadPreset: VadPreset;
  /** セッションの終了 */
  endChatSession: ChatSessionInfo;
  importHomophones: Array<ImportHomophone>;
  importNgWords: Array<ImportNgWord>;
  importTemplateMessages: Array<ImportTemplateMessage>;
  muteUserMic: Scalars['Boolean'];
  /** セッションへの入退室通知 */
  notifyJoin?: Maybe<Scalars['Boolean']>;
  notifyLeave?: Maybe<Scalars['Boolean']>;
  /** チャットメッセージを削除 */
  removeChatMessage: ChatMessage;
  removeChatRoom: ChatRoom;
  removeChatSessionUser: Scalars['Boolean'];
  removeHomophoneCategories: Scalars['Int'];
  removeHomophones: Scalars['Int'];
  removeNgWordCategories: Scalars['Int'];
  removeNgWords: Scalars['Int'];
  removeOwnerCompany: OwnerCompany;
  removeSuperUser: SuperUser;
  removeTemplateMessageCategories: Scalars['Int'];
  removeTemplateMessages: Scalars['Int'];
  removeTenantCompany: TenantCompany;
  removeUser: User;
  removeVadPreset: VadPreset;
  /** フラグの指定 */
  setChatMessageFlag: ChatMessage;
  /** リアクションの指定 */
  setChatReaction: ChatMessage;
  /** セッションの質問モード切替 */
  setChatSessionQuestionMode: Scalars['Boolean'];
  /** セッションの開始 */
  startChatSession: ChatSessionInfo;
  /** セッションユーザー情報の更新 */
  updateChatSessionUser: ChatSessionUser;
  /** チャットメッセージを追加または編集 */
  upsertChatMessage: ChatMessage;
  upsertNotifcation: Notification;
};


export type MutationAddChatRoomArgs = {
  input: AddChatRoomInput;
};


export type MutationAddChatSessionArgs = {
  input: AddChatSessionInput;
};


export type MutationAddChatTemplateMessageArgs = {
  input: AddChatTemplateMessageInput;
};


export type MutationAddFilesArgs = {
  input: AddFilesInput;
};


export type MutationAddHomophoneArgs = {
  input: AddHomophoneInput;
};


export type MutationAddHomophoneCategoryArgs = {
  input: AddHomophoneCategoryInput;
};


export type MutationAddNgWordArgs = {
  input: AddNgWordInput;
};


export type MutationAddNgWordCategoryArgs = {
  input: AddNgWordCategoryInput;
};


export type MutationAddOwnerCompanyArgs = {
  input: AddOwnerCompanyInput;
};


export type MutationAddSuperUserArgs = {
  input: AddSuperUserInput;
};


export type MutationAddTemplateMessageArgs = {
  input: AddTemplateMessageInput;
};


export type MutationAddTemplateMessageCategoryArgs = {
  input: AddTemplateMessageCategoryInput;
};


export type MutationAddTenantCompanyArgs = {
  input: AddTenantCompanyInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddVadPresetArgs = {
  input: AddVadPresetInput;
};


export type MutationClearChatSessionArgs = {
  input: ClearChatSessionInput;
};


export type MutationEditAdminSettingsArgs = {
  input: EditAdminSettingsInput;
};


export type MutationEditChatMessageFlagArgs = {
  input: EditChatMessageFlagInput;
};


export type MutationEditChatRoomArgs = {
  input: EditChatRoomInput;
};


export type MutationEditChatSessionArgs = {
  input: EditChatSessionInput;
};


export type MutationEditHomophoneArgs = {
  input: EditHomophoneInput;
};


export type MutationEditHomophoneCategoryArgs = {
  input?: InputMaybe<EditHomophoneCategoryInput>;
};


export type MutationEditNgWordArgs = {
  input: EditNgWordInput;
};


export type MutationEditNgWordCategoryArgs = {
  input?: InputMaybe<EditNgWordCategoryInput>;
};


export type MutationEditOwnerCompanyArgs = {
  input: EditOwnerCompanyInput;
};


export type MutationEditSuperUserArgs = {
  input: EditSuperUserInput;
};


export type MutationEditTemplateMessageArgs = {
  input: EditTemplateMessageInput;
};


export type MutationEditTemplateMessageCategoryArgs = {
  input: EditTemplateMessageCategoryInput;
};


export type MutationEditTenantCompanyArgs = {
  input: EditTenantCompanyInput;
};


export type MutationEditUserSettingsArgs = {
  input: EditUserSettingsInput;
};


export type MutationEditVadPresetArgs = {
  input: EditVadPresetInput;
};


export type MutationEndChatSessionArgs = {
  input: EndChatSessionInput;
};


export type MutationImportHomophonesArgs = {
  input: ImportHomophonesInput;
};


export type MutationImportNgWordsArgs = {
  input: ImportNgWordsInput;
};


export type MutationImportTemplateMessagesArgs = {
  input: ImportTemplateMessagesInput;
};


export type MutationMuteUserMicArgs = {
  input: MuteUserMicInput;
};


export type MutationNotifyJoinArgs = {
  input: NotifyJoinInput;
};


export type MutationNotifyLeaveArgs = {
  input: NotifyLeaveInput;
};


export type MutationRemoveChatMessageArgs = {
  input: RemoveChatMessageInput;
};


export type MutationRemoveChatRoomArgs = {
  input: RemoveChatRoomInput;
};


export type MutationRemoveChatSessionUserArgs = {
  input: RemoveChatSessionUserInput;
};


export type MutationRemoveHomophoneCategoriesArgs = {
  input: RemoveHomophoneCategoriesInput;
};


export type MutationRemoveHomophonesArgs = {
  input: RemoveHomophonesInput;
};


export type MutationRemoveNgWordCategoriesArgs = {
  input: RemoveNgWordCategoriesInput;
};


export type MutationRemoveNgWordsArgs = {
  input: RemoveNgWordsInput;
};


export type MutationRemoveOwnerCompanyArgs = {
  input: RemoveOwnerCompanyInput;
};


export type MutationRemoveSuperUserArgs = {
  input: RemoveSuperUserInput;
};


export type MutationRemoveTemplateMessageCategoriesArgs = {
  input: RemoveTemplateMessageCategoriesInput;
};


export type MutationRemoveTemplateMessagesArgs = {
  input: RemoveTemplateMessagesInput;
};


export type MutationRemoveTenantCompanyArgs = {
  input: RemoveTenantCompanyInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRemoveVadPresetArgs = {
  input: RemoveVadPresetInput;
};


export type MutationSetChatMessageFlagArgs = {
  input: SetChatMessageFlagInput;
};


export type MutationSetChatReactionArgs = {
  input: SetChatReactionInput;
};


export type MutationSetChatSessionQuestionModeArgs = {
  input: SetChatSessionQuestionModeInput;
};


export type MutationStartChatSessionArgs = {
  input: StartChatSessionInput;
};


export type MutationUpdateChatSessionUserArgs = {
  input?: InputMaybe<UpdateChatSessionUserInput>;
};


export type MutationUpsertChatMessageArgs = {
  input: UpsertChatMessageInput;
};


export type MutationUpsertNotifcationArgs = {
  input: UpsertNotificationInput;
};

export type MuteUserMicInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  targetUserId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type NgWord = {
  __typename?: 'NgWord';
  /** 作成日 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** カテゴリ */
  ngWordCategory: NgWordCategory;
  /** 単語 */
  word: Scalars['String'];
};

export type NgWordCategoriesInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type NgWordCategory = {
  __typename?: 'NgWordCategory';
  /** 作成日 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
};

export type NgWordCategoryWithCount = {
  __typename?: 'NgWordCategoryWithCount';
  /** 作成日 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
  /** 件数 */
  ngWordsCount: Scalars['Int'];
};

export type NgWordChatUserInput = {
  chatRoomId: Scalars['String'];
  sessionEntryCode: Scalars['String'];
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type NgWordsInput = {
  /** カテゴリ指定で複数取得 */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** チャットユーザーとして取得 */
  chatUser?: InputMaybe<NgWordChatUserInput>;
  /** IDで単体を取得 */
  id?: InputMaybe<Scalars['ID']>;
};

export type Notification = {
  __typename?: 'Notification';
  /** 非表示フラグ */
  disabled: Scalars['Boolean'];
  /** 掲載終了日 */
  endedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** お知らせ内容 */
  message: Scalars['String'];
  /** 利用企業ID */
  ownerCompanyId?: Maybe<Scalars['ID']>;
  /** 掲載開始日 */
  startedAt: Scalars['DateTime'];
};

export type NotificationsInput = {
  /** チャットルームID */
  chatRoomId?: InputMaybe<Scalars['ID']>;
  /** 有効なお知らせのみを取得するかどうか */
  filterOnlyEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * 利用企業ID
   * 指定時：利用企業に登録されているお知らせと、スーパーユーザーが登録したお知らせが取れる
   * 未指定時：スーパーユーザーが登録したお知らせのみが取れる
   */
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
};

export type NotifyJoinInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** ユーザー情報 */
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type NotifyLeaveInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** ユーザー情報 */
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type OwnerCompaniesInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OwnerCompany = {
  __typename?: 'OwnerCompany';
  /** 同時翻訳選択可否 */
  canUseSimulteneous: Scalars['Boolean'];
  /** 企業コード */
  code: Scalars['String'];
  id: Scalars['ID'];
  /** 名称 */
  name: Scalars['String'];
  /** 備考 */
  note?: Maybe<Scalars['String']>;
  /** アクティブ可能ルーム数 */
  roomLimit: Scalars['Int'];
  /** 登録ユーザー数上限 */
  userLimit: Scalars['Int'];
  /** ユーザー名 */
  userName: Scalars['String'];
};

export type Permission = {
  __typename?: 'Permission';
  /** CognitoのユーザーID。認証済みユーザーの場合は必ずセットされる */
  cognitoUserName: Scalars['ID'];
  isOwnerCompanyAdmin: Scalars['Boolean'];
  isSuperAdmin: Scalars['Boolean'];
  isTenantCompanyAdmin: Scalars['Boolean'];
  ownerCompanyId?: Maybe<Scalars['String']>;
  tenantCompanyId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adminSettings: Array<AdminSettings>;
  analyticsUsageAll: Array<AnalyticsUsageAll>;
  analyticsUsageByTime: AnalyticsUsageByTime;
  analyticsUserCount: AnalyticsUserCount;
  analyticsUserLanguage: Array<AnalyticsUserLanguage>;
  /** チャット履歴を取得 */
  chatMessages: Array<ChatMessage>;
  chatRooms: Array<ChatRoom>;
  chatRoomsTemplateMessages: Array<ChatRoomsTemplateMessage>;
  /** チャットセッションの状態を取得 */
  chatSessionInfo: ChatSessionInfo;
  downloadCsvByOwnerCompanyId: DownloadCsvurl;
  downloadCsvByRoomId: DownloadCsvurl;
  downloadCsvByTenantCompanyId: DownloadCsvurl;
  dummy?: Maybe<Scalars['String']>;
  files: Array<File>;
  flagMessages: Array<FlagMessage>;
  guideUsers: Array<User>;
  homophoneCategories: Array<HomophoneCategory>;
  homophones: Array<Homophone>;
  logout: Scalars['Boolean'];
  ngWordCategories: Array<NgWordCategoryWithCount>;
  ngWords: Array<NgWord>;
  notifications: Array<Notification>;
  ownerCompanies: Array<OwnerCompany>;
  permission: Permission;
  superUsers: Array<SuperUser>;
  templateMessageCategories: Array<TemplateMessageCategory>;
  templateMessages: Array<TemplateMessage>;
  tenantCompanies: Array<TenantCompany>;
  /** 音声入力API接続用のトークンを取得 */
  translateApiToken: TranslateApiToken;
  translateMessage: Array<TranslatedMessage>;
  userSettings: UserSettings;
  vadPresets: Array<VadPreset>;
};


export type QueryAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type QueryAnalyticsUsageAllArgs = {
  input: DateRange;
};


export type QueryAnalyticsUsageByTimeArgs = {
  input: AnalyticsInput;
};


export type QueryAnalyticsUserCountArgs = {
  input: AnalyticsInput;
};


export type QueryAnalyticsUserLanguageArgs = {
  input: AnalyticsInput;
};


export type QueryChatMessagesArgs = {
  input: ChatMessagesInput;
};


export type QueryChatRoomsArgs = {
  input: ChatRoomsInput;
};


export type QueryChatRoomsTemplateMessagesArgs = {
  input: ChatRoomsTemplateMessageInput;
};


export type QueryChatSessionInfoArgs = {
  input: ChatSessionInfoInput;
};


export type QueryDownloadCsvByOwnerCompanyIdArgs = {
  input: DownloadCsvByOwnerCompanyIdInput;
};


export type QueryDownloadCsvByRoomIdArgs = {
  input: DownloadCsvByRoomIdInput;
};


export type QueryDownloadCsvByTenantCompanyIdArgs = {
  input: DownloadCsvByTenantCompanyIdInput;
};


export type QueryFilesArgs = {
  input: FilesInput;
};


export type QueryGuideUsersArgs = {
  input: GuideUsersInput;
};


export type QueryHomophoneCategoriesArgs = {
  input?: InputMaybe<HomophoneCategoriesInput>;
};


export type QueryHomophonesArgs = {
  input?: InputMaybe<HomophonesInput>;
};


export type QueryNgWordCategoriesArgs = {
  input?: InputMaybe<NgWordCategoriesInput>;
};


export type QueryNgWordsArgs = {
  input?: InputMaybe<NgWordsInput>;
};


export type QueryNotificationsArgs = {
  input: NotificationsInput;
};


export type QueryOwnerCompaniesArgs = {
  input: OwnerCompaniesInput;
};


export type QuerySuperUsersArgs = {
  input: SuperUsersInput;
};


export type QueryTemplateMessageCategoriesArgs = {
  input: TemplateMessageCategoryInput;
};


export type QueryTemplateMessagesArgs = {
  input: TemplateMessagesInput;
};


export type QueryTenantCompaniesArgs = {
  input: TenantCompaniesInput;
};


export type QueryTranslateApiTokenArgs = {
  input: TranslateTokenInput;
};


export type QueryTranslateMessageArgs = {
  input: TranslateMessageInput;
};


export type QueryVadPresetsArgs = {
  input: VadPresetsInput;
};

export type RemoveChatMessageInput = {
  /** チャットメッセージID */
  chatMessageId: Scalars['ID'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type RemoveChatRoomInput = {
  id: Scalars['ID'];
};

export type RemoveChatSessionUserInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  targetUserId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type RemoveHomophoneCategoriesInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveHomophonesInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveNgWordCategoriesInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveNgWordsInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveOwnerCompanyInput = {
  id: Scalars['ID'];
};

export type RemoveSuperUserInput = {
  id: Scalars['ID'];
};

export type RemoveTemplateMessageCategoriesInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveTemplateMessagesInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveTenantCompanyInput = {
  id: Scalars['ID'];
};

export type RemoveUserInput = {
  id: Scalars['ID'];
};

export type RemoveVadPresetInput = {
  id: Scalars['ID'];
};

export type ReplyInfo = {
  __typename?: 'ReplyInfo';
  /** 返信元メッセージID */
  chatMessageId: Scalars['String'];
  /** 言語ごとのメッセージ */
  languages: Array<ChatMessageLanguage>;
  /** 返信元メッセージの発言ユーザー */
  userId: Scalars['String'];
};

export type SetChatMessageFlagInput = {
  /** メッセージ自体の固有ID */
  chatMessageId: Scalars['String'];
  /** フラグON/OFF */
  isMessageFlag: Scalars['Boolean'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type SetChatReactionInput = {
  /** メッセージ自体の固有ID */
  chatMessageId: Scalars['String'];
  /** リアクション種別 */
  reactionType: ChatReactionType;
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type SetChatSessionQuestionModeInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** 質問モードかどうかを指定 */
  isQuestionMode: Scalars['Boolean'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type StartChatSessionInput = {
  /** チャットセッションID */
  chatSessionId: Scalars['ID'];
  /** 入室コード */
  sessionEntryCode: Scalars['String'];
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  chatCommandExecuted: Scalars['String'];
  /** チャットが投下されたときに発火する。 */
  chatMessageAdded: ChatMessage;
  /**
   * 部屋への参加通知とユーザー一覧のリアルタイム取得を行う。
   *
   * 接続時にまとめてユーザー情報を返すため、配列を返すこととする。
   */
  chatSessionUserModified: Array<ChatSessionUser>;
};


export type SubscriptionChatCommandExecutedArgs = {
  input: ChatCommandExecutedInput;
};


export type SubscriptionChatMessageAddedArgs = {
  input: ChatMessageAddedInput;
};


export type SubscriptionChatSessionUserModifiedArgs = {
  input: ChatSessionUserModifiedInput;
};

export type SuperUser = {
  __typename?: 'SuperUser';
  id: Scalars['ID'];
  /** ユーザー名 */
  userName: Scalars['String'];
};

export type SuperUsersInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TemplateMessage = {
  __typename?: 'TemplateMessage';
  /** カテゴリ */
  category: TemplateMessageCategory;
  /** 作成日時 */
  createdAt: Scalars['DateTime'];
  /** ファイル */
  files: Array<TemplateMessageFile>;
  id: Scalars['ID'];
  /** 言語ごとのメッセージ */
  languages: Array<TemplateMessageLanguage>;
  /** カテゴリ内での順番 */
  order: Scalars['Int'];
};

export type TemplateMessageAttachedFile = {
  __typename?: 'TemplateMessageAttachedFile';
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
  /** 署名付きURL */
  signedURL: Scalars['String'];
  /** 定型文ID */
  templateMessageId: Scalars['ID'];
};

export type TemplateMessageAttachedFilesInput = {
  /** ファイル名 */
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 定型文ID */
  templateMessageId: Scalars['ID'];
};

export type TemplateMessageCategory = {
  __typename?: 'TemplateMessageCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** チャットルーム内での順番（チャットルームが確定している場合のみ） */
  order?: Maybe<Scalars['Int']>;
  /** カテゴリ内の定型文の件数（Queryでのみセットされる） */
  templateMessagesCount?: Maybe<Scalars['Int']>;
};

export type TemplateMessageCategoryInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TemplateMessageFile = {
  __typename?: 'TemplateMessageFile';
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
};

export type TemplateMessageFileInput = {
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
};

export type TemplateMessageLanguage = {
  __typename?: 'TemplateMessageLanguage';
  /** 翻訳言語 */
  language: Scalars['String'];
  /** メッセージ */
  message: Scalars['String'];
  /** 編集可否 */
  readonly?: Maybe<Scalars['Boolean']>;
};

export type TemplateMessageLanguageInput = {
  /** 翻訳言語 */
  language: Scalars['String'];
  /** メッセージ */
  message: Scalars['String'];
  /** 編集可否 */
  readonly?: InputMaybe<Scalars['Boolean']>;
};

export type TemplateMessageUsage = {
  __typename?: 'TemplateMessageUsage';
  /** 利用数 */
  count: Scalars['Int'];
  /** 定型文 */
  templateMessage: TemplateMessage;
};

export type TemplateMessagesChatUserInput = {
  chatUserId: Scalars['ID'];
  chatUserPassword: Scalars['String'];
};

export type TemplateMessagesInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type TenantCompaniesInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TenantCompany = {
  __typename?: 'TenantCompany';
  /** ガイド管理者名 */
  guideManagerName: Scalars['String'];
  id: Scalars['ID'];
  /** 音声再生 */
  isEnableVoice: Scalars['Boolean'];
  /** 備考 */
  note?: Maybe<Scalars['String']>;
  /** 利用企業名 */
  ownerCompanyName: Scalars['String'];
  /** ルーム作成可能数 */
  roomCreationLimit: Scalars['Int'];
  /** アクティブルーム数 */
  roomLimit: Scalars['Int'];
  /** ユーザー名 */
  userName: Scalars['String'];
  /** VADプリセット */
  vadPreset?: Maybe<VadPreset>;
};

export type TranslateApiToken = {
  __typename?: 'TranslateApiToken';
  /** 音声入力API接続用のトークン */
  accessToken: Scalars['String'];
};

export type TranslateMessageInput = {
  /** 逆翻訳をするかどうか */
  enableReverseTraslate?: InputMaybe<Scalars['Boolean']>;
  /** 原文の言語 */
  language: Scalars['String'];
  /** 原文 */
  message: Scalars['String'];
  /** 翻訳対象言語 */
  targetLanguages: Array<Scalars['String']>;
};

export enum TranslateMethod {
  /** 逐次翻訳 */
  Consecutive = 'CONSECUTIVE',
  /** 同時翻訳 */
  Simulteneous = 'SIMULTENEOUS'
}

export type TranslateTokenInput = {
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** リアルタイム翻訳用キー */
  realtime?: InputMaybe<Scalars['Boolean']>;
  /** セッションパスワード */
  sessionEntryCode: Scalars['String'];
};

export type TranslatedMessage = {
  __typename?: 'TranslatedMessage';
  language: Scalars['String'];
  message: Scalars['String'];
  reverseMessage?: Maybe<Scalars['String']>;
};

export enum TtsService {
  Azure = 'Azure',
  Feat = 'Feat',
  Mimi = 'Mimi',
  Unknown = 'Unknown'
}

export type UpdateChatSessionUserInput = {
  /** チャットルームID */
  chatRoomId: Scalars['String'];
  /** 待機状態 */
  isWaiting: Scalars['Boolean'];
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** 対象ユーザーID */
  targetUserId: Scalars['String'];
  /** ガイドユーザーID */
  userId: Scalars['String'];
  /** ガイドパスワード */
  userPassword: Scalars['String'];
};

export type UpsertChatMessageFileInput = {
  /** ファイル名 */
  fileName: Scalars['String'];
  /** ファイルタイプ */
  fileType: Scalars['String'];
  id: Scalars['ID'];
};

export type UpsertChatMessageInput = {
  /**
   * メッセージ自体の固有ID
   * 編集の場合は必須
   */
  chatMessageId?: InputMaybe<Scalars['String']>;
  /** 部屋番号 */
  chatRoomId: Scalars['String'];
  /** ファイル一覧 */
  files: Array<UpsertChatMessageFileInput>;
  /** 入力中かどうか */
  isTyping: Scalars['Boolean'];
  /** 言語ごとのメッセージ */
  languages: Array<UpsertChatMessageLanguageInput>;
  /** 逆翻訳の経由言語 */
  originalLanguage: Scalars['String'];
  /** 返信先メッセージID */
  replyMessageId?: InputMaybe<Scalars['String']>;
  /** 逆翻訳の経由言語 */
  reverseLanguage?: InputMaybe<Scalars['String']>;
  /** セッション番号 */
  sessionEntryCode: Scalars['String'];
  /** 同音異義語の置換を行うかどうか */
  shouldReplaceHomophone?: InputMaybe<Scalars['Boolean']>;
  /** ユーザーID */
  userId: Scalars['String'];
  /** ユーザーパスワード */
  userPassword: Scalars['String'];
};

export type UpsertChatMessageLanguageInput = {
  /** 言語名 */
  language: Scalars['String'];
  /** メッセージ本文 */
  message: Scalars['String'];
};

export type UpsertNotificationInput = {
  /** 非表示フラグ */
  disabled: Scalars['Boolean'];
  /** 掲載終了日 */
  endedAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  /** お知らせ内容 */
  message: Scalars['String'];
  /** 利用企業ID（スーパーユーザーの場合は指定しない） */
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
  /** 掲載開始日 */
  startedAt: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  /** チャット入室のためのユーザーID */
  chatUserId?: Maybe<Scalars['String']>;
  /** チャット入室のためのユーザーパスワード */
  chatUserPassword?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 利用企業ID */
  ownerCompanyId?: Maybe<Scalars['String']>;
  /** ユーザーロール */
  role: UserRole;
  /** ガイド管理者ID */
  tenantCompanyId?: Maybe<Scalars['String']>;
};

/** Altenative Results（ドメインエラー） */
export type UserNameAlreadyExists = {
  __typename?: 'UserNameAlreadyExists';
  message: Scalars['String'];
};

export enum UserRole {
  /** ガイド */
  Guide = 'GUIDE',
  /** 利用企業管理者 */
  OwnerCompanyAdmin = 'OWNER_COMPANY_ADMIN',
  /** スーパーユーザー */
  SuperAdmin = 'SUPER_ADMIN',
  /** ガイド管理者 */
  TenantCompanyAdmin = 'TENANT_COMPANY_ADMIN'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  /** ニックネーム */
  name: Scalars['String'];
};

export type VadPreset = {
  __typename?: 'VadPreset';
  default: Scalars['Boolean'];
  energyIntegration: Scalars['Float'];
  energyOffset: Scalars['Float'];
  energyThresholdRatioNeg: Scalars['Float'];
  energyThresholdRatioPos: Scalars['Float'];
  id: Scalars['ID'];
  label: Scalars['String'];
  smoothingTimeConstant: Scalars['Float'];
};

export type VadPresetsInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DownloadCsvByOwnerCompanyIdInput = {
  /** オーナーカンパニーID */
  ownerCompanyId?: InputMaybe<Scalars['ID']>;
};

export type DownloadCsvByRoomIdInput = {
  /** ルームID */
  roomId?: InputMaybe<Scalars['ID']>;
};

export type DownloadCsvByTenantCompanyIdInput = {
  /** テナントID */
  tenantCompanyId?: InputMaybe<Scalars['ID']>;
};

export type GuideUsersQueryVariables = Exact<{
  input: GuideUsersInput;
}>;


export type GuideUsersQuery = { __typename?: 'Query', guideUsers: Array<{ __typename?: 'User', id: string, role: UserRole, ownerCompanyId?: string | null, tenantCompanyId?: string | null, chatUserId?: string | null, chatUserPassword?: string | null }> };

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = { __typename?: 'Query', logout: boolean };

export type PermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionQuery = { __typename?: 'Query', permission: { __typename?: 'Permission', cognitoUserName: string, isSuperAdmin: boolean, isOwnerCompanyAdmin: boolean, isTenantCompanyAdmin: boolean, ownerCompanyId?: string | null, tenantCompanyId?: string | null } };

export type DownloadCsvByOwnerCompanyIdQueryVariables = Exact<{
  input: DownloadCsvByOwnerCompanyIdInput;
}>;


export type DownloadCsvByOwnerCompanyIdQuery = { __typename?: 'Query', downloadCsvByOwnerCompanyId: { __typename?: 'DownloadCSVURL', downloadUrl: string } };

export type DownloadCsvByRoomIdQueryVariables = Exact<{
  input: DownloadCsvByRoomIdInput;
}>;


export type DownloadCsvByRoomIdQuery = { __typename?: 'Query', downloadCsvByRoomId: { __typename?: 'DownloadCSVURL', downloadUrl: string } };

export type DownloadCsvByTenantCompanyIdQueryVariables = Exact<{
  input: DownloadCsvByTenantCompanyIdInput;
}>;


export type DownloadCsvByTenantCompanyIdQuery = { __typename?: 'Query', downloadCsvByTenantCompanyId: { __typename?: 'DownloadCSVURL', downloadUrl: string } };

export type FlagMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type FlagMessagesQuery = { __typename?: 'Query', flagMessages: Array<{ __typename?: 'FlagMessage', id: string, isMessageFlag: boolean, chatRoomId: string, chatRoomName: string, languages: Array<{ __typename?: 'FlagMessageLanguage', message: string, language: string, isOriginal: boolean }> }> };

export type EditChatMessageFlagMutationVariables = Exact<{
  input: EditChatMessageFlagInput;
}>;


export type EditChatMessageFlagMutation = { __typename?: 'Mutation', editChatMessageFlag: { __typename?: 'FlagMessage', id: string, isMessageFlag: boolean, chatRoomId: string, chatRoomName: string, languages: Array<{ __typename?: 'FlagMessageLanguage', message: string, language: string, isOriginal: boolean }> } };

export type SetChatMessageFlagMutationVariables = Exact<{
  input: SetChatMessageFlagInput;
}>;


export type SetChatMessageFlagMutation = { __typename?: 'Mutation', setChatMessageFlag: { __typename?: 'ChatMessage', id: string, isTemplate: boolean, isMessageFlag: boolean, userId: string, timestamp: string, updateAt: string, sequence: number, reverseMessage?: string | null, reverseLanguage?: string | null, isTyping: boolean, isRemoved: boolean, reactions: Array<{ __typename?: 'ChatReaction', userId: string, reactionType: ChatReactionType }>, files: Array<{ __typename?: 'ChatMessageFile', id: string, fileName: string, fileType: string }>, reply?: { __typename?: 'ReplyInfo', chatMessageId: string, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }> } | null, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }> } };

export type ChatSessionInfoQueryVariables = Exact<{
  input: ChatSessionInfoInput;
}>;


export type ChatSessionInfoQuery = { __typename?: 'Query', chatSessionInfo: { __typename?: 'ChatSessionInfo', roomName: string, state: ChatSessionState, sessionId: string, sessionName: string, entryCode: string, clearedSequence: number, isQuestionMode: boolean, isEnableVoice: boolean, vadSmoothingTimeConstant: number, vadEnergyOffset: number, vadEnergyThresholdRatioPos: number, vadEnergyThresholdRatioNeg: number, vadEnergyIntegration: number, translateMethod: TranslateMethod, ttsService: TtsService, isWaitingRoomNotificationEnabled: boolean, isGuestInputRestricted: boolean, isNotifyOnMessageEnabled: boolean, isGuideMicControlLinked: boolean, isGuestMicControlLinked: boolean } };

export type UpsertChatMessageMutationVariables = Exact<{
  input: UpsertChatMessageInput;
}>;


export type UpsertChatMessageMutation = { __typename?: 'Mutation', upsertChatMessage: { __typename?: 'ChatMessage', id: string, userId: string, timestamp: string, updateAt: string, sequence: number, reverseMessage?: string | null, reverseLanguage?: string | null, isTyping: boolean, isRemoved: boolean, isTemplate: boolean, isMessageFlag: boolean, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }>, reactions: Array<{ __typename?: 'ChatReaction', userId: string, reactionType: ChatReactionType }>, files: Array<{ __typename?: 'ChatMessageFile', id: string, fileName: string, fileType: string }>, reply?: { __typename?: 'ReplyInfo', chatMessageId: string, userId: string, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }> } | null } };

export type ChatMessageAddedSubscriptionVariables = Exact<{
  input: ChatMessageAddedInput;
}>;


export type ChatMessageAddedSubscription = { __typename?: 'Subscription', chatMessageAdded: { __typename?: 'ChatMessage', id: string, userId: string, timestamp: string, updateAt: string, sequence: number, reverseMessage?: string | null, reverseLanguage?: string | null, isTyping: boolean, isRemoved: boolean, isTemplate: boolean, isMessageFlag: boolean, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }>, reactions: Array<{ __typename?: 'ChatReaction', userId: string, reactionType: ChatReactionType }>, files: Array<{ __typename?: 'ChatMessageFile', id: string, fileName: string, fileType: string }>, reply?: { __typename?: 'ReplyInfo', chatMessageId: string, userId: string, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }> } | null } };

export type ChatMessagesQueryVariables = Exact<{
  input: ChatMessagesInput;
}>;


export type ChatMessagesQuery = { __typename?: 'Query', chatMessages: Array<{ __typename?: 'ChatMessage', id: string, userId: string, timestamp: string, updateAt: string, sequence: number, reverseMessage?: string | null, reverseLanguage?: string | null, isTyping: boolean, isRemoved: boolean, isTemplate: boolean, isMessageFlag: boolean, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }>, reactions: Array<{ __typename?: 'ChatReaction', userId: string, reactionType: ChatReactionType }>, files: Array<{ __typename?: 'ChatMessageFile', id: string, fileName: string, fileType: string }>, reply?: { __typename?: 'ReplyInfo', chatMessageId: string, userId: string, languages: Array<{ __typename?: 'ChatMessageLanguage', message: string, language: string, isOriginal: boolean }> } | null }> };

export type TranslateApiTokenQueryVariables = Exact<{
  input: TranslateTokenInput;
}>;


export type TranslateApiTokenQuery = { __typename?: 'Query', translateApiToken: { __typename?: 'TranslateApiToken', accessToken: string } };

export type ChatSessionUserModifiedSubscriptionVariables = Exact<{
  input: ChatSessionUserModifiedInput;
}>;


export type ChatSessionUserModifiedSubscription = { __typename?: 'Subscription', chatSessionUserModified: Array<{ __typename?: 'ChatSessionUser', userId: string, userName: string, userRole: ChatUserRole, language: string, isJoining: boolean, isWaiting: boolean, canUseMic: boolean, canUseSpeaker: boolean }> };

export type UpdateChatSessionUserMutationVariables = Exact<{
  input: UpdateChatSessionUserInput;
}>;


export type UpdateChatSessionUserMutation = { __typename?: 'Mutation', updateChatSessionUser: { __typename?: 'ChatSessionUser', userId: string, userRole: ChatUserRole, userName: string, language: string, isJoining: boolean, isWaiting: boolean, canUseMic: boolean, canUseSpeaker: boolean } };

export type AnalyticsUserCountQueryVariables = Exact<{
  input: AnalyticsInput;
}>;


export type AnalyticsUserCountQuery = { __typename?: 'Query', analyticsUserCount: { __typename?: 'AnalyticsUserCount', interval: Interval, sumUserCount: number, data: Array<{ __typename?: 'AnalyticsUserCountData', date: string, count: number }> } };

export type AnalyticsUsageAllQueryVariables = Exact<{
  input: DateRange;
}>;


export type AnalyticsUsageAllQuery = { __typename?: 'Query', analyticsUsageAll: Array<{ __typename?: 'AnalyticsUsageAll', ownerCompanyId: string, ownerCompanyName: string, maxUserCount: number, sumUserCount: number, activeRoomCount: number }> };

export type AnalyticsUserLanguageQueryVariables = Exact<{
  input: AnalyticsInput;
}>;


export type AnalyticsUserLanguageQuery = { __typename?: 'Query', analyticsUserLanguage: Array<{ __typename?: 'AnalyticsUserLanguage', language: string, count: number }> };

export type AnalyticsUsageByTimeQueryVariables = Exact<{
  input: AnalyticsInput;
}>;


export type AnalyticsUsageByTimeQuery = { __typename?: 'Query', analyticsUsageByTime: { __typename?: 'AnalyticsUsageByTime', interval: Interval, data: Array<{ __typename?: 'AnalyticsUsageByTimeData', date: string, maxUserCount: number, sumUserCount: number, activeRooms: Array<{ __typename?: 'AnalyticsRoom', chatRoomId: string, name: string, guideLanguage: string, userCount: number, messageCount: number }> }> } };

export type RemoveChatMessageMutationVariables = Exact<{
  input: RemoveChatMessageInput;
}>;


export type RemoveChatMessageMutation = { __typename?: 'Mutation', removeChatMessage: { __typename?: 'ChatMessage', isRemoved: boolean } };

export type SetChatReactionMutationVariables = Exact<{
  input: SetChatReactionInput;
}>;


export type SetChatReactionMutation = { __typename?: 'Mutation', setChatReaction: { __typename?: 'ChatMessage', id: string } };

export type ClearChatSessionMutationVariables = Exact<{
  input: ClearChatSessionInput;
}>;


export type ClearChatSessionMutation = { __typename?: 'Mutation', clearChatSession: boolean };

export type SetChatSessionQuestionModeMutationVariables = Exact<{
  input: SetChatSessionQuestionModeInput;
}>;


export type SetChatSessionQuestionModeMutation = { __typename?: 'Mutation', setChatSessionQuestionMode: boolean };

export type MuteUserMicMutationVariables = Exact<{
  input: MuteUserMicInput;
}>;


export type MuteUserMicMutation = { __typename?: 'Mutation', muteUserMic: boolean };

export type RemoveChatSessionUserMutationVariables = Exact<{
  input: RemoveChatSessionUserInput;
}>;


export type RemoveChatSessionUserMutation = { __typename?: 'Mutation', removeChatSessionUser: boolean };

export type AddFilesMutationVariables = Exact<{
  input: AddFilesInput;
}>;


export type AddFilesMutation = { __typename?: 'Mutation', addFiles: Array<{ __typename?: 'File', id: string, fileName: string, fileType: string, signedURL: string }> };

export type FilesQueryVariables = Exact<{
  input: FilesInput;
}>;


export type FilesQuery = { __typename?: 'Query', files: Array<{ __typename?: 'File', id: string, fileName: string, fileType: string, signedURL: string }> };

export type ChatCommandExecutedSubscriptionVariables = Exact<{
  input: ChatCommandExecutedInput;
}>;


export type ChatCommandExecutedSubscription = { __typename?: 'Subscription', chatCommandExecuted: string };

export type AddChatTemplateMessageMutationVariables = Exact<{
  input: AddChatTemplateMessageInput;
}>;


export type AddChatTemplateMessageMutation = { __typename?: 'Mutation', addChatTemplateMessage: { __typename?: 'ChatMessage', id: string } };

export type NotifyJoinMutationVariables = Exact<{
  input: NotifyJoinInput;
}>;


export type NotifyJoinMutation = { __typename?: 'Mutation', notifyJoin?: boolean | null };

export type NotifyLeaveMutationVariables = Exact<{
  input: NotifyLeaveInput;
}>;


export type NotifyLeaveMutation = { __typename?: 'Mutation', notifyLeave?: boolean | null };

export type StartChatSessionMutationVariables = Exact<{
  input: StartChatSessionInput;
}>;


export type StartChatSessionMutation = { __typename?: 'Mutation', startChatSession: { __typename?: 'ChatSessionInfo', sessionId: string, sessionName: string, state: ChatSessionState, entryCode: string, roomName: string, clearedSequence: number } };

export type EndChatSessionMutationVariables = Exact<{
  input: EndChatSessionInput;
}>;


export type EndChatSessionMutation = { __typename?: 'Mutation', endChatSession: { __typename?: 'ChatSessionInfo', sessionId: string, sessionName: string, state: ChatSessionState, entryCode: string, roomName: string, clearedSequence: number } };

export type UserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSettingsQuery = { __typename?: 'Query', userSettings: { __typename?: 'UserSettings', name: string } };

export type EditUserSettingsMutationVariables = Exact<{
  input: EditUserSettingsInput;
}>;


export type EditUserSettingsMutation = { __typename?: 'Mutation', editUserSettings: { __typename?: 'UserSettings', name: string } };

export type AdminSettingsQueryVariables = Exact<{
  input: AdminSettingsInput;
}>;


export type AdminSettingsQuery = { __typename?: 'Query', adminSettings: Array<{ __typename?: 'AdminSettings', key: string, value: string }> };

export type EditAdminSettingsMutationVariables = Exact<{
  input: EditAdminSettingsInput;
}>;


export type EditAdminSettingsMutation = { __typename?: 'Mutation', editAdminSettings: { __typename?: 'AdminSettings', key: string, value: string } };

export type HomophonesQueryVariables = Exact<{
  input: HomophonesInput;
}>;


export type HomophonesQuery = { __typename?: 'Query', homophones: Array<{ __typename?: 'Homophone', id: string, createdAt: string, disabled: boolean, kana: string, sourceWord: string, targetWord: string, usageCount: number, homophoneCategory: { __typename?: 'HomophoneCategory', id: string, name: string, createdAt: string, homophonesCount?: number | null } }> };

export type ImportHomophonesMutationVariables = Exact<{
  input: ImportHomophonesInput;
}>;


export type ImportHomophonesMutation = { __typename?: 'Mutation', importHomophones: Array<{ __typename?: 'ImportHomophone', disabled: boolean, kana: string, sourceWord: string, targetWord: string, homophoneId: string, homophoneCategoryId: string, homophoneCategoryName: string, errorCode: number }> };

export type RemoveHomophonesMutationVariables = Exact<{
  input: RemoveHomophonesInput;
}>;


export type RemoveHomophonesMutation = { __typename?: 'Mutation', removeHomophones: number };

export type HomophoneCategoriesQueryVariables = Exact<{
  input: HomophoneCategoriesInput;
}>;


export type HomophoneCategoriesQuery = { __typename?: 'Query', homophoneCategories: Array<{ __typename?: 'HomophoneCategory', id: string, name: string, createdAt: string, homophonesCount?: number | null }> };

export type RemoveHomophoneCategorysMutationVariables = Exact<{
  input: RemoveHomophoneCategoriesInput;
}>;


export type RemoveHomophoneCategorysMutation = { __typename?: 'Mutation', removeHomophoneCategories: number };

export type EditHomphoneCategoryMutationVariables = Exact<{
  input: EditHomophoneCategoryInput;
}>;


export type EditHomphoneCategoryMutation = { __typename?: 'Mutation', editHomophoneCategory: { __typename?: 'HomophoneCategory', id: string, name: string, createdAt: string, homophonesCount?: number | null } };

export type NgWordsQueryVariables = Exact<{
  input: NgWordsInput;
}>;


export type NgWordsQuery = { __typename?: 'Query', ngWords: Array<{ __typename?: 'NgWord', id: string, createdAt: string, word: string, ngWordCategory: { __typename?: 'NgWordCategory', id: string, name: string, createdAt: string } }> };

export type AddNgWordMutationVariables = Exact<{
  input: AddNgWordInput;
}>;


export type AddNgWordMutation = { __typename?: 'Mutation', addNgWord: { __typename?: 'NgWord', id: string, createdAt: string, word: string, ngWordCategory: { __typename?: 'NgWordCategory', id: string, name: string, createdAt: string } } };

export type EditNgWordMutationVariables = Exact<{
  input: EditNgWordInput;
}>;


export type EditNgWordMutation = { __typename?: 'Mutation', editNgWord: { __typename?: 'NgWord', id: string, createdAt: string, word: string, ngWordCategory: { __typename?: 'NgWordCategory', id: string, name: string, createdAt: string } } };

export type RemoveNgWordsMutationVariables = Exact<{
  input: RemoveNgWordsInput;
}>;


export type RemoveNgWordsMutation = { __typename?: 'Mutation', removeNgWords: number };

export type ImportNgWordsMutationVariables = Exact<{
  input: ImportNgWordsInput;
}>;


export type ImportNgWordsMutation = { __typename?: 'Mutation', importNgWords: Array<{ __typename?: 'ImportNgWord', ngWordId: string, ngWordCategoryId: string, ngWordCategoryName: string, word: string, errorCode: number }> };

export type NgWordCategoriesQueryVariables = Exact<{
  input: NgWordCategoriesInput;
}>;


export type NgWordCategoriesQuery = { __typename?: 'Query', ngWordCategories: Array<{ __typename?: 'NgWordCategoryWithCount', id: string, createdAt: string, name: string, ngWordsCount: number }> };

export type AddNgWordCategoryMutationVariables = Exact<{
  input: AddNgWordCategoryInput;
}>;


export type AddNgWordCategoryMutation = { __typename?: 'Mutation', addNgWordCategory: { __typename?: 'NgWordCategory', id: string, createdAt: string, name: string } };

export type RemoveNgWordCategoriesMutationVariables = Exact<{
  input: RemoveNgWordCategoriesInput;
}>;


export type RemoveNgWordCategoriesMutation = { __typename?: 'Mutation', removeNgWordCategories: number };

export type NotificationsQueryVariables = Exact<{
  input: NotificationsInput;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, startedAt: string, endedAt: string, message: string, disabled: boolean, ownerCompanyId?: string | null }> };

export type UpsertNotifcationMutationVariables = Exact<{
  input: UpsertNotificationInput;
}>;


export type UpsertNotifcationMutation = { __typename?: 'Mutation', upsertNotifcation: { __typename?: 'Notification', id: string, startedAt: string, endedAt: string, message: string, disabled: boolean, ownerCompanyId?: string | null } };

export type OwnerCompaniesQueryVariables = Exact<{
  input: OwnerCompaniesInput;
}>;


export type OwnerCompaniesQuery = { __typename?: 'Query', ownerCompanies: Array<{ __typename?: 'OwnerCompany', id: string, name: string, code: string, userName: string, userLimit: number, roomLimit: number, note?: string | null, canUseSimulteneous: boolean }> };

export type AddOwnerCompanyMutationVariables = Exact<{
  input: AddOwnerCompanyInput;
}>;


export type AddOwnerCompanyMutation = { __typename?: 'Mutation', addOwnerCompany: { __typename: 'OwnerCompany', id: string, name: string, code: string, userName: string, userLimit: number, roomLimit: number, canUseSimulteneous: boolean, note?: string | null } | { __typename: 'UserNameAlreadyExists', message: string } };

export type EditOwnerCompanyMutationVariables = Exact<{
  input: EditOwnerCompanyInput;
}>;


export type EditOwnerCompanyMutation = { __typename?: 'Mutation', editOwnerCompany: { __typename: 'OwnerCompany', id: string, name: string, code: string, userName: string, userLimit: number, roomLimit: number, canUseSimulteneous: boolean, note?: string | null } | { __typename: 'UserNameAlreadyExists', message: string } };

export type RemoveOwnerCompanyMutationVariables = Exact<{
  input: RemoveOwnerCompanyInput;
}>;


export type RemoveOwnerCompanyMutation = { __typename?: 'Mutation', removeOwnerCompany: { __typename?: 'OwnerCompany', id: string, name: string, code: string, userName: string, userLimit: number, roomLimit: number, canUseSimulteneous: boolean, note?: string | null } };

export type ChatRoomsQueryVariables = Exact<{
  input: ChatRoomsInput;
}>;


export type ChatRoomsQuery = { __typename?: 'Query', chatRooms: Array<{ __typename?: 'ChatRoom', id: string, name: string, capacity: number, createdAt: string, tenantCompanyId: string, templateCategoryIds: Array<string>, homophoneCategoryIds: Array<string>, translateMethod: TranslateMethod, isWaitingRoomEnabled: boolean, isWaitingRoomNotificationEnabled: boolean, isGuestInputRestricted: boolean, isNotifyOnMessageEnabled: boolean, isGuideMicControlLinked: boolean, isGuestMicControlLinked: boolean, sessions: Array<{ __typename?: 'ChatSession', id: string, entryCode?: string | null, name: string, chatRoomId: string, currentSequence: number, state: ChatSessionState }>, waitingGuests: Array<{ __typename?: 'ChatRoomWaitingGuest', id: string, name: string }> }> };

export type AddChatRoomMutationVariables = Exact<{
  input: AddChatRoomInput;
}>;


export type AddChatRoomMutation = { __typename?: 'Mutation', addChatRoom: { __typename: 'ChatRoom', id: string, name: string, capacity: number, createdAt: string, tenantCompanyId: string, translateMethod: TranslateMethod, isWaitingRoomEnabled: boolean, isWaitingRoomNotificationEnabled: boolean, isGuestInputRestricted: boolean, isNotifyOnMessageEnabled: boolean, isGuideMicControlLinked: boolean, isGuestMicControlLinked: boolean } | { __typename: 'ExcessRoomCreationLimit', message: string, limit: number } };

export type EditChatRoomMutationVariables = Exact<{
  input: EditChatRoomInput;
}>;


export type EditChatRoomMutation = { __typename?: 'Mutation', editChatRoom: { __typename?: 'ChatRoom', id: string, name: string, capacity: number, createdAt: string, templateCategoryIds: Array<string>, homophoneCategoryIds: Array<string>, translateMethod: TranslateMethod, isWaitingRoomEnabled: boolean, isWaitingRoomNotificationEnabled: boolean, isGuestInputRestricted: boolean, isGuideMicControlLinked: boolean, isGuestMicControlLinked: boolean } };

export type RemoveChatRoomMutationVariables = Exact<{
  input: RemoveChatRoomInput;
}>;


export type RemoveChatRoomMutation = { __typename?: 'Mutation', removeChatRoom: { __typename?: 'ChatRoom', id: string, name: string, capacity: number, createdAt: string, tenantCompanyId: string, templateCategoryIds: Array<string>, homophoneCategoryIds: Array<string>, isWaitingRoomEnabled: boolean, isWaitingRoomNotificationEnabled: boolean, isGuestInputRestricted: boolean, isGuideMicControlLinked: boolean, isGuestMicControlLinked: boolean } };

export type AddChatSessionMutationVariables = Exact<{
  input: AddChatSessionInput;
}>;


export type AddChatSessionMutation = { __typename?: 'Mutation', addChatSession: { __typename?: 'ChatSession', id: string, chatRoomId: string, currentSequence: number, entryCode?: string | null, name: string, state: ChatSessionState } };

export type EditChatSessionMutationVariables = Exact<{
  input: EditChatSessionInput;
}>;


export type EditChatSessionMutation = { __typename?: 'Mutation', editChatSession: { __typename?: 'ChatSession', id: string, entryCode?: string | null, chatRoomId: string, state: ChatSessionState } };

export type SuperUsersQueryVariables = Exact<{
  input: SuperUsersInput;
}>;


export type SuperUsersQuery = { __typename?: 'Query', superUsers: Array<{ __typename?: 'SuperUser', id: string, userName: string }> };

export type AddSuperUserMutationVariables = Exact<{
  input: AddSuperUserInput;
}>;


export type AddSuperUserMutation = { __typename?: 'Mutation', addSuperUser: { __typename: 'SuperUser', id: string, userName: string } | { __typename: 'UserNameAlreadyExists', message: string } };

export type EditSuperUserMutationVariables = Exact<{
  input: EditSuperUserInput;
}>;


export type EditSuperUserMutation = { __typename?: 'Mutation', editSuperUser: { __typename: 'SuperUser', id: string, userName: string } | { __typename: 'UserNameAlreadyExists', message: string } };

export type RemoveSuperUserMutationVariables = Exact<{
  input: RemoveSuperUserInput;
}>;


export type RemoveSuperUserMutation = { __typename?: 'Mutation', removeSuperUser: { __typename?: 'SuperUser', id: string, userName: string } };

export type TemplateMessagesQueryVariables = Exact<{
  input: TemplateMessagesInput;
}>;


export type TemplateMessagesQuery = { __typename?: 'Query', templateMessages: Array<{ __typename?: 'TemplateMessage', id: string, createdAt: string, order: number, category: { __typename?: 'TemplateMessageCategory', id: string, name: string }, languages: Array<{ __typename?: 'TemplateMessageLanguage', message: string, language: string, readonly?: boolean | null }>, files: Array<{ __typename?: 'TemplateMessageFile', id: string, fileName: string, fileType: string }> }> };

export type AddTemplateMessageMutationVariables = Exact<{
  input: AddTemplateMessageInput;
}>;


export type AddTemplateMessageMutation = { __typename?: 'Mutation', addTemplateMessage: { __typename?: 'TemplateMessage', id: string, createdAt: string, order: number, category: { __typename?: 'TemplateMessageCategory', id: string, name: string }, languages: Array<{ __typename?: 'TemplateMessageLanguage', message: string, language: string, readonly?: boolean | null }> } };

export type EditTemplateMessageMutationVariables = Exact<{
  input: EditTemplateMessageInput;
}>;


export type EditTemplateMessageMutation = { __typename?: 'Mutation', editTemplateMessage: { __typename?: 'TemplateMessage', id: string, order: number, category: { __typename?: 'TemplateMessageCategory', id: string, name: string }, languages: Array<{ __typename?: 'TemplateMessageLanguage', message: string, language: string }> } };

export type ImportTemplateMessagesMutationVariables = Exact<{
  input: ImportTemplateMessagesInput;
}>;


export type ImportTemplateMessagesMutation = { __typename?: 'Mutation', importTemplateMessages: Array<{ __typename?: 'ImportTemplateMessage', templateMessageId: string, templateMessageCategoryId: string, templateMessageCategoryName: string, errorCode: number, languages: Array<{ __typename?: 'TemplateMessageLanguage', language: string, message: string }> }> };

export type RemoveTemplateMessagesMutationVariables = Exact<{
  input: RemoveTemplateMessagesInput;
}>;


export type RemoveTemplateMessagesMutation = { __typename?: 'Mutation', removeTemplateMessages: number };

export type TemplateMessageCategoriesQueryVariables = Exact<{
  input: TemplateMessageCategoryInput;
}>;


export type TemplateMessageCategoriesQuery = { __typename?: 'Query', templateMessageCategories: Array<{ __typename?: 'TemplateMessageCategory', id: string, name: string, templateMessagesCount?: number | null }> };

export type AddTemplateMessageCategoryMutationVariables = Exact<{
  input: AddTemplateMessageCategoryInput;
}>;


export type AddTemplateMessageCategoryMutation = { __typename?: 'Mutation', addTemplateMessageCategory: { __typename?: 'TemplateMessageCategory', id: string, name: string } };

export type EditTemplateMessageCategoryMutationVariables = Exact<{
  input: EditTemplateMessageCategoryInput;
}>;


export type EditTemplateMessageCategoryMutation = { __typename?: 'Mutation', editTemplateMessageCategory: { __typename?: 'TemplateMessageCategory', id: string, name: string } };

export type RemoveTemplateMessageCategoriesMutationVariables = Exact<{
  input: RemoveTemplateMessageCategoriesInput;
}>;


export type RemoveTemplateMessageCategoriesMutation = { __typename?: 'Mutation', removeTemplateMessageCategories: number };

export type TranslateMessageQueryVariables = Exact<{
  input: TranslateMessageInput;
}>;


export type TranslateMessageQuery = { __typename?: 'Query', translateMessage: Array<{ __typename?: 'TranslatedMessage', message: string, language: string, reverseMessage?: string | null }> };

export type ChatRoomsTemplateMessagesQueryVariables = Exact<{
  input: ChatRoomsTemplateMessageInput;
}>;


export type ChatRoomsTemplateMessagesQuery = { __typename?: 'Query', chatRoomsTemplateMessages: Array<{ __typename?: 'ChatRoomsTemplateMessage', lastSentTime?: string | null, templateMessage: { __typename?: 'TemplateMessage', id: string, order: number, createdAt: string, category: { __typename?: 'TemplateMessageCategory', id: string, name: string, order?: number | null }, languages: Array<{ __typename?: 'TemplateMessageLanguage', language: string, message: string, readonly?: boolean | null }>, files: Array<{ __typename?: 'TemplateMessageFile', id: string, fileName: string, fileType: string }> } }> };

export type TenantCompaniesQueryVariables = Exact<{
  input: TenantCompaniesInput;
}>;


export type TenantCompaniesQuery = { __typename?: 'Query', tenantCompanies: Array<{ __typename?: 'TenantCompany', id: string, ownerCompanyName: string, userName: string, guideManagerName: string, roomLimit: number, roomCreationLimit: number, isEnableVoice: boolean, note?: string | null, vadPreset?: { __typename?: 'VadPreset', id: string, label: string } | null }> };

export type AddTenantCompanyMutationVariables = Exact<{
  input: AddTenantCompanyInput;
}>;


export type AddTenantCompanyMutation = { __typename?: 'Mutation', addTenantCompany: { __typename: 'ExcessTenantCompanyNumberLimit', message: string } | { __typename: 'ExcessTenantCompanyRoomNumberLimit', message: string, limit: number } | { __typename: 'TenantCompany', id: string, ownerCompanyName: string, userName: string, guideManagerName: string, roomLimit: number, roomCreationLimit: number, isEnableVoice: boolean, note?: string | null, vadPreset?: { __typename?: 'VadPreset', id: string, label: string } | null } | { __typename: 'UserNameAlreadyExists', message: string } };

export type EditTenantCompanyMutationVariables = Exact<{
  input: EditTenantCompanyInput;
}>;


export type EditTenantCompanyMutation = { __typename?: 'Mutation', editTenantCompany: { __typename: 'ExcessTenantCompanyRoomNumberLimit', message: string, limit: number } | { __typename: 'TenantCompany', id: string, ownerCompanyName: string, userName: string, guideManagerName: string, roomLimit: number, roomCreationLimit: number, isEnableVoice: boolean, note?: string | null, vadPreset?: { __typename?: 'VadPreset', id: string, label: string } | null } | { __typename: 'UserNameAlreadyExists', message: string } };

export type RemoveTenantCompanyMutationVariables = Exact<{
  input: RemoveTenantCompanyInput;
}>;


export type RemoveTenantCompanyMutation = { __typename?: 'Mutation', removeTenantCompany: { __typename?: 'TenantCompany', id: string, ownerCompanyName: string, userName: string, guideManagerName: string } };

export type VadPresetsQueryVariables = Exact<{
  input: VadPresetsInput;
}>;


export type VadPresetsQuery = { __typename?: 'Query', vadPresets: Array<{ __typename?: 'VadPreset', id: string, label: string, smoothingTimeConstant: number, energyOffset: number, energyThresholdRatioPos: number, energyThresholdRatioNeg: number, energyIntegration: number, default: boolean }> };

export type AddVadPresetMutationVariables = Exact<{
  input: AddVadPresetInput;
}>;


export type AddVadPresetMutation = { __typename?: 'Mutation', addVadPreset: { __typename?: 'VadPreset', id: string, label: string, smoothingTimeConstant: number, energyOffset: number, energyThresholdRatioPos: number, energyThresholdRatioNeg: number, energyIntegration: number } };

export type EditVadPresetMutationVariables = Exact<{
  input: EditVadPresetInput;
}>;


export type EditVadPresetMutation = { __typename?: 'Mutation', editVadPreset: { __typename?: 'VadPreset', id: string, label: string, smoothingTimeConstant: number, energyOffset: number, energyThresholdRatioPos: number, energyThresholdRatioNeg: number, energyIntegration: number } };

export type RemoveVadPresetMutationVariables = Exact<{
  input: RemoveVadPresetInput;
}>;


export type RemoveVadPresetMutation = { __typename?: 'Mutation', removeVadPreset: { __typename?: 'VadPreset', id: string, label: string, smoothingTimeConstant: number, energyOffset: number, energyThresholdRatioPos: number, energyThresholdRatioNeg: number, energyIntegration: number } };


export const GuideUsersDocument = gql`
    query guideUsers($input: GuideUsersInput!) {
  guideUsers(input: $input) {
    id
    role
    ownerCompanyId
    tenantCompanyId
    chatUserId
    chatUserPassword
  }
}
    `;

/**
 * __useGuideUsersQuery__
 *
 * To run a query within a React component, call `useGuideUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuideUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuideUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuideUsersQuery(baseOptions: Apollo.QueryHookOptions<GuideUsersQuery, GuideUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuideUsersQuery, GuideUsersQueryVariables>(GuideUsersDocument, options);
      }
export function useGuideUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuideUsersQuery, GuideUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuideUsersQuery, GuideUsersQueryVariables>(GuideUsersDocument, options);
        }
export type GuideUsersQueryHookResult = ReturnType<typeof useGuideUsersQuery>;
export type GuideUsersLazyQueryHookResult = ReturnType<typeof useGuideUsersLazyQuery>;
export type GuideUsersQueryResult = Apollo.QueryResult<GuideUsersQuery, GuideUsersQueryVariables>;
export const LogoutDocument = gql`
    query logout {
  logout
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: Apollo.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
      }
export function useLogoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = Apollo.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const PermissionDocument = gql`
    query permission {
  permission {
    cognitoUserName
    isSuperAdmin
    isOwnerCompanyAdmin
    isTenantCompanyAdmin
    ownerCompanyId
    tenantCompanyId
  }
}
    `;

/**
 * __usePermissionQuery__
 *
 * To run a query within a React component, call `usePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionQuery(baseOptions?: Apollo.QueryHookOptions<PermissionQuery, PermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionQuery, PermissionQueryVariables>(PermissionDocument, options);
      }
export function usePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionQuery, PermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionQuery, PermissionQueryVariables>(PermissionDocument, options);
        }
export type PermissionQueryHookResult = ReturnType<typeof usePermissionQuery>;
export type PermissionLazyQueryHookResult = ReturnType<typeof usePermissionLazyQuery>;
export type PermissionQueryResult = Apollo.QueryResult<PermissionQuery, PermissionQueryVariables>;
export const DownloadCsvByOwnerCompanyIdDocument = gql`
    query DownloadCsvByOwnerCompanyId($input: downloadCsvByOwnerCompanyIdInput!) {
  downloadCsvByOwnerCompanyId(input: $input) {
    downloadUrl
  }
}
    `;

/**
 * __useDownloadCsvByOwnerCompanyIdQuery__
 *
 * To run a query within a React component, call `useDownloadCsvByOwnerCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvByOwnerCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCsvByOwnerCompanyIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadCsvByOwnerCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<DownloadCsvByOwnerCompanyIdQuery, DownloadCsvByOwnerCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCsvByOwnerCompanyIdQuery, DownloadCsvByOwnerCompanyIdQueryVariables>(DownloadCsvByOwnerCompanyIdDocument, options);
      }
export function useDownloadCsvByOwnerCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCsvByOwnerCompanyIdQuery, DownloadCsvByOwnerCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCsvByOwnerCompanyIdQuery, DownloadCsvByOwnerCompanyIdQueryVariables>(DownloadCsvByOwnerCompanyIdDocument, options);
        }
export type DownloadCsvByOwnerCompanyIdQueryHookResult = ReturnType<typeof useDownloadCsvByOwnerCompanyIdQuery>;
export type DownloadCsvByOwnerCompanyIdLazyQueryHookResult = ReturnType<typeof useDownloadCsvByOwnerCompanyIdLazyQuery>;
export type DownloadCsvByOwnerCompanyIdQueryResult = Apollo.QueryResult<DownloadCsvByOwnerCompanyIdQuery, DownloadCsvByOwnerCompanyIdQueryVariables>;
export const DownloadCsvByRoomIdDocument = gql`
    query DownloadCsvByRoomId($input: downloadCsvByRoomIdInput!) {
  downloadCsvByRoomId(input: $input) {
    downloadUrl
  }
}
    `;

/**
 * __useDownloadCsvByRoomIdQuery__
 *
 * To run a query within a React component, call `useDownloadCsvByRoomIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvByRoomIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCsvByRoomIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadCsvByRoomIdQuery(baseOptions: Apollo.QueryHookOptions<DownloadCsvByRoomIdQuery, DownloadCsvByRoomIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCsvByRoomIdQuery, DownloadCsvByRoomIdQueryVariables>(DownloadCsvByRoomIdDocument, options);
      }
export function useDownloadCsvByRoomIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCsvByRoomIdQuery, DownloadCsvByRoomIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCsvByRoomIdQuery, DownloadCsvByRoomIdQueryVariables>(DownloadCsvByRoomIdDocument, options);
        }
export type DownloadCsvByRoomIdQueryHookResult = ReturnType<typeof useDownloadCsvByRoomIdQuery>;
export type DownloadCsvByRoomIdLazyQueryHookResult = ReturnType<typeof useDownloadCsvByRoomIdLazyQuery>;
export type DownloadCsvByRoomIdQueryResult = Apollo.QueryResult<DownloadCsvByRoomIdQuery, DownloadCsvByRoomIdQueryVariables>;
export const DownloadCsvByTenantCompanyIdDocument = gql`
    query DownloadCsvByTenantCompanyId($input: downloadCsvByTenantCompanyIdInput!) {
  downloadCsvByTenantCompanyId(input: $input) {
    downloadUrl
  }
}
    `;

/**
 * __useDownloadCsvByTenantCompanyIdQuery__
 *
 * To run a query within a React component, call `useDownloadCsvByTenantCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvByTenantCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCsvByTenantCompanyIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadCsvByTenantCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<DownloadCsvByTenantCompanyIdQuery, DownloadCsvByTenantCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCsvByTenantCompanyIdQuery, DownloadCsvByTenantCompanyIdQueryVariables>(DownloadCsvByTenantCompanyIdDocument, options);
      }
export function useDownloadCsvByTenantCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCsvByTenantCompanyIdQuery, DownloadCsvByTenantCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCsvByTenantCompanyIdQuery, DownloadCsvByTenantCompanyIdQueryVariables>(DownloadCsvByTenantCompanyIdDocument, options);
        }
export type DownloadCsvByTenantCompanyIdQueryHookResult = ReturnType<typeof useDownloadCsvByTenantCompanyIdQuery>;
export type DownloadCsvByTenantCompanyIdLazyQueryHookResult = ReturnType<typeof useDownloadCsvByTenantCompanyIdLazyQuery>;
export type DownloadCsvByTenantCompanyIdQueryResult = Apollo.QueryResult<DownloadCsvByTenantCompanyIdQuery, DownloadCsvByTenantCompanyIdQueryVariables>;
export const FlagMessagesDocument = gql`
    query flagMessages {
  flagMessages {
    id
    languages {
      message
      language
      isOriginal
    }
    isMessageFlag
    chatRoomId
    chatRoomName
  }
}
    `;

/**
 * __useFlagMessagesQuery__
 *
 * To run a query within a React component, call `useFlagMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlagMessagesQuery(baseOptions?: Apollo.QueryHookOptions<FlagMessagesQuery, FlagMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlagMessagesQuery, FlagMessagesQueryVariables>(FlagMessagesDocument, options);
      }
export function useFlagMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlagMessagesQuery, FlagMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlagMessagesQuery, FlagMessagesQueryVariables>(FlagMessagesDocument, options);
        }
export type FlagMessagesQueryHookResult = ReturnType<typeof useFlagMessagesQuery>;
export type FlagMessagesLazyQueryHookResult = ReturnType<typeof useFlagMessagesLazyQuery>;
export type FlagMessagesQueryResult = Apollo.QueryResult<FlagMessagesQuery, FlagMessagesQueryVariables>;
export const EditChatMessageFlagDocument = gql`
    mutation editChatMessageFlag($input: EditChatMessageFlagInput!) {
  editChatMessageFlag(input: $input) {
    id
    languages {
      message
      language
      isOriginal
    }
    isMessageFlag
    chatRoomId
    chatRoomName
  }
}
    `;
export type EditChatMessageFlagMutationFn = Apollo.MutationFunction<EditChatMessageFlagMutation, EditChatMessageFlagMutationVariables>;

/**
 * __useEditChatMessageFlagMutation__
 *
 * To run a mutation, you first call `useEditChatMessageFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChatMessageFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChatMessageFlagMutation, { data, loading, error }] = useEditChatMessageFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChatMessageFlagMutation(baseOptions?: Apollo.MutationHookOptions<EditChatMessageFlagMutation, EditChatMessageFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChatMessageFlagMutation, EditChatMessageFlagMutationVariables>(EditChatMessageFlagDocument, options);
      }
export type EditChatMessageFlagMutationHookResult = ReturnType<typeof useEditChatMessageFlagMutation>;
export type EditChatMessageFlagMutationResult = Apollo.MutationResult<EditChatMessageFlagMutation>;
export type EditChatMessageFlagMutationOptions = Apollo.BaseMutationOptions<EditChatMessageFlagMutation, EditChatMessageFlagMutationVariables>;
export const SetChatMessageFlagDocument = gql`
    mutation setChatMessageFlag($input: SetChatMessageFlagInput!) {
  setChatMessageFlag(input: $input) {
    id
    isTemplate
    reactions {
      userId
      reactionType
    }
    files {
      id
      fileName
      fileType
    }
    reply {
      chatMessageId
      languages {
        message
        language
        isOriginal
      }
    }
    isMessageFlag
    userId
    timestamp
    updateAt
    languages {
      message
      language
      isOriginal
    }
    sequence
    reverseMessage
    reverseLanguage
    isTyping
    isRemoved
  }
}
    `;
export type SetChatMessageFlagMutationFn = Apollo.MutationFunction<SetChatMessageFlagMutation, SetChatMessageFlagMutationVariables>;

/**
 * __useSetChatMessageFlagMutation__
 *
 * To run a mutation, you first call `useSetChatMessageFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChatMessageFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChatMessageFlagMutation, { data, loading, error }] = useSetChatMessageFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetChatMessageFlagMutation(baseOptions?: Apollo.MutationHookOptions<SetChatMessageFlagMutation, SetChatMessageFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetChatMessageFlagMutation, SetChatMessageFlagMutationVariables>(SetChatMessageFlagDocument, options);
      }
export type SetChatMessageFlagMutationHookResult = ReturnType<typeof useSetChatMessageFlagMutation>;
export type SetChatMessageFlagMutationResult = Apollo.MutationResult<SetChatMessageFlagMutation>;
export type SetChatMessageFlagMutationOptions = Apollo.BaseMutationOptions<SetChatMessageFlagMutation, SetChatMessageFlagMutationVariables>;
export const ChatSessionInfoDocument = gql`
    query chatSessionInfo($input: ChatSessionInfoInput!) {
  chatSessionInfo(input: $input) {
    roomName
    state
    sessionId
    sessionName
    entryCode
    clearedSequence
    isQuestionMode
    isEnableVoice
    vadSmoothingTimeConstant
    vadEnergyOffset
    vadEnergyThresholdRatioPos
    vadEnergyThresholdRatioNeg
    vadEnergyIntegration
    translateMethod
    ttsService
    isWaitingRoomNotificationEnabled
    isGuestInputRestricted
    isNotifyOnMessageEnabled
    isGuideMicControlLinked
    isGuestMicControlLinked
  }
}
    `;

/**
 * __useChatSessionInfoQuery__
 *
 * To run a query within a React component, call `useChatSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSessionInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSessionInfoQuery(baseOptions: Apollo.QueryHookOptions<ChatSessionInfoQuery, ChatSessionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatSessionInfoQuery, ChatSessionInfoQueryVariables>(ChatSessionInfoDocument, options);
      }
export function useChatSessionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatSessionInfoQuery, ChatSessionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatSessionInfoQuery, ChatSessionInfoQueryVariables>(ChatSessionInfoDocument, options);
        }
export type ChatSessionInfoQueryHookResult = ReturnType<typeof useChatSessionInfoQuery>;
export type ChatSessionInfoLazyQueryHookResult = ReturnType<typeof useChatSessionInfoLazyQuery>;
export type ChatSessionInfoQueryResult = Apollo.QueryResult<ChatSessionInfoQuery, ChatSessionInfoQueryVariables>;
export const UpsertChatMessageDocument = gql`
    mutation upsertChatMessage($input: UpsertChatMessageInput!) {
  upsertChatMessage(input: $input) {
    id
    userId
    timestamp
    updateAt
    sequence
    languages {
      message
      language
      isOriginal
    }
    reverseMessage
    reverseLanguage
    isTyping
    isRemoved
    isTemplate
    reactions {
      userId
      reactionType
    }
    files {
      id
      fileName
      fileType
    }
    reply {
      chatMessageId
      userId
      languages {
        message
        language
        isOriginal
      }
    }
    isMessageFlag
  }
}
    `;
export type UpsertChatMessageMutationFn = Apollo.MutationFunction<UpsertChatMessageMutation, UpsertChatMessageMutationVariables>;

/**
 * __useUpsertChatMessageMutation__
 *
 * To run a mutation, you first call `useUpsertChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertChatMessageMutation, { data, loading, error }] = useUpsertChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpsertChatMessageMutation, UpsertChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertChatMessageMutation, UpsertChatMessageMutationVariables>(UpsertChatMessageDocument, options);
      }
export type UpsertChatMessageMutationHookResult = ReturnType<typeof useUpsertChatMessageMutation>;
export type UpsertChatMessageMutationResult = Apollo.MutationResult<UpsertChatMessageMutation>;
export type UpsertChatMessageMutationOptions = Apollo.BaseMutationOptions<UpsertChatMessageMutation, UpsertChatMessageMutationVariables>;
export const ChatMessageAddedDocument = gql`
    subscription chatMessageAdded($input: ChatMessageAddedInput!) {
  chatMessageAdded(input: $input) {
    id
    userId
    timestamp
    updateAt
    sequence
    languages {
      message
      language
      isOriginal
    }
    reverseMessage
    reverseLanguage
    isTyping
    isRemoved
    isTemplate
    reactions {
      userId
      reactionType
    }
    files {
      id
      fileName
      fileType
    }
    reply {
      chatMessageId
      userId
      languages {
        message
        language
        isOriginal
      }
    }
    isMessageFlag
  }
}
    `;

/**
 * __useChatMessageAddedSubscription__
 *
 * To run a query within a React component, call `useChatMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageAddedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatMessageAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatMessageAddedSubscription, ChatMessageAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatMessageAddedSubscription, ChatMessageAddedSubscriptionVariables>(ChatMessageAddedDocument, options);
      }
export type ChatMessageAddedSubscriptionHookResult = ReturnType<typeof useChatMessageAddedSubscription>;
export type ChatMessageAddedSubscriptionResult = Apollo.SubscriptionResult<ChatMessageAddedSubscription>;
export const ChatMessagesDocument = gql`
    query chatMessages($input: ChatMessagesInput!) {
  chatMessages(input: $input) {
    id
    userId
    timestamp
    updateAt
    sequence
    languages {
      message
      language
      isOriginal
    }
    reverseMessage
    reverseLanguage
    isTyping
    isRemoved
    isTemplate
    reactions {
      userId
      reactionType
    }
    files {
      id
      fileName
      fileType
    }
    reply {
      chatMessageId
      userId
      languages {
        message
        language
        isOriginal
      }
    }
    isMessageFlag
  }
}
    `;

/**
 * __useChatMessagesQuery__
 *
 * To run a query within a React component, call `useChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, options);
      }
export function useChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, options);
        }
export type ChatMessagesQueryHookResult = ReturnType<typeof useChatMessagesQuery>;
export type ChatMessagesLazyQueryHookResult = ReturnType<typeof useChatMessagesLazyQuery>;
export type ChatMessagesQueryResult = Apollo.QueryResult<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const TranslateApiTokenDocument = gql`
    query translateApiToken($input: TranslateTokenInput!) {
  translateApiToken(input: $input) {
    accessToken
  }
}
    `;

/**
 * __useTranslateApiTokenQuery__
 *
 * To run a query within a React component, call `useTranslateApiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateApiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateApiTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTranslateApiTokenQuery(baseOptions: Apollo.QueryHookOptions<TranslateApiTokenQuery, TranslateApiTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateApiTokenQuery, TranslateApiTokenQueryVariables>(TranslateApiTokenDocument, options);
      }
export function useTranslateApiTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateApiTokenQuery, TranslateApiTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateApiTokenQuery, TranslateApiTokenQueryVariables>(TranslateApiTokenDocument, options);
        }
export type TranslateApiTokenQueryHookResult = ReturnType<typeof useTranslateApiTokenQuery>;
export type TranslateApiTokenLazyQueryHookResult = ReturnType<typeof useTranslateApiTokenLazyQuery>;
export type TranslateApiTokenQueryResult = Apollo.QueryResult<TranslateApiTokenQuery, TranslateApiTokenQueryVariables>;
export const ChatSessionUserModifiedDocument = gql`
    subscription chatSessionUserModified($input: ChatSessionUserModifiedInput!) {
  chatSessionUserModified(input: $input) {
    userId
    userName
    userRole
    language
    isJoining
    isWaiting
    canUseMic
    canUseSpeaker
  }
}
    `;

/**
 * __useChatSessionUserModifiedSubscription__
 *
 * To run a query within a React component, call `useChatSessionUserModifiedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatSessionUserModifiedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSessionUserModifiedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSessionUserModifiedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatSessionUserModifiedSubscription, ChatSessionUserModifiedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatSessionUserModifiedSubscription, ChatSessionUserModifiedSubscriptionVariables>(ChatSessionUserModifiedDocument, options);
      }
export type ChatSessionUserModifiedSubscriptionHookResult = ReturnType<typeof useChatSessionUserModifiedSubscription>;
export type ChatSessionUserModifiedSubscriptionResult = Apollo.SubscriptionResult<ChatSessionUserModifiedSubscription>;
export const UpdateChatSessionUserDocument = gql`
    mutation updateChatSessionUser($input: UpdateChatSessionUserInput!) {
  updateChatSessionUser(input: $input) {
    userId
    userRole
    userName
    language
    isJoining
    isWaiting
    canUseMic
    canUseSpeaker
  }
}
    `;
export type UpdateChatSessionUserMutationFn = Apollo.MutationFunction<UpdateChatSessionUserMutation, UpdateChatSessionUserMutationVariables>;

/**
 * __useUpdateChatSessionUserMutation__
 *
 * To run a mutation, you first call `useUpdateChatSessionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatSessionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatSessionUserMutation, { data, loading, error }] = useUpdateChatSessionUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChatSessionUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatSessionUserMutation, UpdateChatSessionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatSessionUserMutation, UpdateChatSessionUserMutationVariables>(UpdateChatSessionUserDocument, options);
      }
export type UpdateChatSessionUserMutationHookResult = ReturnType<typeof useUpdateChatSessionUserMutation>;
export type UpdateChatSessionUserMutationResult = Apollo.MutationResult<UpdateChatSessionUserMutation>;
export type UpdateChatSessionUserMutationOptions = Apollo.BaseMutationOptions<UpdateChatSessionUserMutation, UpdateChatSessionUserMutationVariables>;
export const AnalyticsUserCountDocument = gql`
    query analyticsUserCount($input: AnalyticsInput!) {
  analyticsUserCount(input: $input) {
    interval
    sumUserCount
    data {
      date
      count
    }
  }
}
    `;

/**
 * __useAnalyticsUserCountQuery__
 *
 * To run a query within a React component, call `useAnalyticsUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUserCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsUserCountQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsUserCountQuery, AnalyticsUserCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUserCountQuery, AnalyticsUserCountQueryVariables>(AnalyticsUserCountDocument, options);
      }
export function useAnalyticsUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUserCountQuery, AnalyticsUserCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUserCountQuery, AnalyticsUserCountQueryVariables>(AnalyticsUserCountDocument, options);
        }
export type AnalyticsUserCountQueryHookResult = ReturnType<typeof useAnalyticsUserCountQuery>;
export type AnalyticsUserCountLazyQueryHookResult = ReturnType<typeof useAnalyticsUserCountLazyQuery>;
export type AnalyticsUserCountQueryResult = Apollo.QueryResult<AnalyticsUserCountQuery, AnalyticsUserCountQueryVariables>;
export const AnalyticsUsageAllDocument = gql`
    query analyticsUsageAll($input: DateRange!) {
  analyticsUsageAll(input: $input) {
    ownerCompanyId
    ownerCompanyName
    maxUserCount
    sumUserCount
    activeRoomCount
  }
}
    `;

/**
 * __useAnalyticsUsageAllQuery__
 *
 * To run a query within a React component, call `useAnalyticsUsageAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUsageAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUsageAllQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsUsageAllQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsUsageAllQuery, AnalyticsUsageAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUsageAllQuery, AnalyticsUsageAllQueryVariables>(AnalyticsUsageAllDocument, options);
      }
export function useAnalyticsUsageAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUsageAllQuery, AnalyticsUsageAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUsageAllQuery, AnalyticsUsageAllQueryVariables>(AnalyticsUsageAllDocument, options);
        }
export type AnalyticsUsageAllQueryHookResult = ReturnType<typeof useAnalyticsUsageAllQuery>;
export type AnalyticsUsageAllLazyQueryHookResult = ReturnType<typeof useAnalyticsUsageAllLazyQuery>;
export type AnalyticsUsageAllQueryResult = Apollo.QueryResult<AnalyticsUsageAllQuery, AnalyticsUsageAllQueryVariables>;
export const AnalyticsUserLanguageDocument = gql`
    query analyticsUserLanguage($input: AnalyticsInput!) {
  analyticsUserLanguage(input: $input) {
    language
    count
  }
}
    `;

/**
 * __useAnalyticsUserLanguageQuery__
 *
 * To run a query within a React component, call `useAnalyticsUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUserLanguageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsUserLanguageQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsUserLanguageQuery, AnalyticsUserLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUserLanguageQuery, AnalyticsUserLanguageQueryVariables>(AnalyticsUserLanguageDocument, options);
      }
export function useAnalyticsUserLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUserLanguageQuery, AnalyticsUserLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUserLanguageQuery, AnalyticsUserLanguageQueryVariables>(AnalyticsUserLanguageDocument, options);
        }
export type AnalyticsUserLanguageQueryHookResult = ReturnType<typeof useAnalyticsUserLanguageQuery>;
export type AnalyticsUserLanguageLazyQueryHookResult = ReturnType<typeof useAnalyticsUserLanguageLazyQuery>;
export type AnalyticsUserLanguageQueryResult = Apollo.QueryResult<AnalyticsUserLanguageQuery, AnalyticsUserLanguageQueryVariables>;
export const AnalyticsUsageByTimeDocument = gql`
    query analyticsUsageByTime($input: AnalyticsInput!) {
  analyticsUsageByTime(input: $input) {
    interval
    data {
      date
      maxUserCount
      sumUserCount
      activeRooms {
        chatRoomId
        name
        guideLanguage
        userCount
        messageCount
      }
    }
  }
}
    `;

/**
 * __useAnalyticsUsageByTimeQuery__
 *
 * To run a query within a React component, call `useAnalyticsUsageByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUsageByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUsageByTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsUsageByTimeQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsUsageByTimeQuery, AnalyticsUsageByTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUsageByTimeQuery, AnalyticsUsageByTimeQueryVariables>(AnalyticsUsageByTimeDocument, options);
      }
export function useAnalyticsUsageByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUsageByTimeQuery, AnalyticsUsageByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUsageByTimeQuery, AnalyticsUsageByTimeQueryVariables>(AnalyticsUsageByTimeDocument, options);
        }
export type AnalyticsUsageByTimeQueryHookResult = ReturnType<typeof useAnalyticsUsageByTimeQuery>;
export type AnalyticsUsageByTimeLazyQueryHookResult = ReturnType<typeof useAnalyticsUsageByTimeLazyQuery>;
export type AnalyticsUsageByTimeQueryResult = Apollo.QueryResult<AnalyticsUsageByTimeQuery, AnalyticsUsageByTimeQueryVariables>;
export const RemoveChatMessageDocument = gql`
    mutation removeChatMessage($input: RemoveChatMessageInput!) {
  removeChatMessage(input: $input) {
    isRemoved
  }
}
    `;
export type RemoveChatMessageMutationFn = Apollo.MutationFunction<RemoveChatMessageMutation, RemoveChatMessageMutationVariables>;

/**
 * __useRemoveChatMessageMutation__
 *
 * To run a mutation, you first call `useRemoveChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChatMessageMutation, { data, loading, error }] = useRemoveChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChatMessageMutation, RemoveChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChatMessageMutation, RemoveChatMessageMutationVariables>(RemoveChatMessageDocument, options);
      }
export type RemoveChatMessageMutationHookResult = ReturnType<typeof useRemoveChatMessageMutation>;
export type RemoveChatMessageMutationResult = Apollo.MutationResult<RemoveChatMessageMutation>;
export type RemoveChatMessageMutationOptions = Apollo.BaseMutationOptions<RemoveChatMessageMutation, RemoveChatMessageMutationVariables>;
export const SetChatReactionDocument = gql`
    mutation setChatReaction($input: SetChatReactionInput!) {
  setChatReaction(input: $input) {
    id
  }
}
    `;
export type SetChatReactionMutationFn = Apollo.MutationFunction<SetChatReactionMutation, SetChatReactionMutationVariables>;

/**
 * __useSetChatReactionMutation__
 *
 * To run a mutation, you first call `useSetChatReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChatReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChatReactionMutation, { data, loading, error }] = useSetChatReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetChatReactionMutation(baseOptions?: Apollo.MutationHookOptions<SetChatReactionMutation, SetChatReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetChatReactionMutation, SetChatReactionMutationVariables>(SetChatReactionDocument, options);
      }
export type SetChatReactionMutationHookResult = ReturnType<typeof useSetChatReactionMutation>;
export type SetChatReactionMutationResult = Apollo.MutationResult<SetChatReactionMutation>;
export type SetChatReactionMutationOptions = Apollo.BaseMutationOptions<SetChatReactionMutation, SetChatReactionMutationVariables>;
export const ClearChatSessionDocument = gql`
    mutation clearChatSession($input: ClearChatSessionInput!) {
  clearChatSession(input: $input)
}
    `;
export type ClearChatSessionMutationFn = Apollo.MutationFunction<ClearChatSessionMutation, ClearChatSessionMutationVariables>;

/**
 * __useClearChatSessionMutation__
 *
 * To run a mutation, you first call `useClearChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearChatSessionMutation, { data, loading, error }] = useClearChatSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearChatSessionMutation(baseOptions?: Apollo.MutationHookOptions<ClearChatSessionMutation, ClearChatSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearChatSessionMutation, ClearChatSessionMutationVariables>(ClearChatSessionDocument, options);
      }
export type ClearChatSessionMutationHookResult = ReturnType<typeof useClearChatSessionMutation>;
export type ClearChatSessionMutationResult = Apollo.MutationResult<ClearChatSessionMutation>;
export type ClearChatSessionMutationOptions = Apollo.BaseMutationOptions<ClearChatSessionMutation, ClearChatSessionMutationVariables>;
export const SetChatSessionQuestionModeDocument = gql`
    mutation setChatSessionQuestionMode($input: SetChatSessionQuestionModeInput!) {
  setChatSessionQuestionMode(input: $input)
}
    `;
export type SetChatSessionQuestionModeMutationFn = Apollo.MutationFunction<SetChatSessionQuestionModeMutation, SetChatSessionQuestionModeMutationVariables>;

/**
 * __useSetChatSessionQuestionModeMutation__
 *
 * To run a mutation, you first call `useSetChatSessionQuestionModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChatSessionQuestionModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChatSessionQuestionModeMutation, { data, loading, error }] = useSetChatSessionQuestionModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetChatSessionQuestionModeMutation(baseOptions?: Apollo.MutationHookOptions<SetChatSessionQuestionModeMutation, SetChatSessionQuestionModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetChatSessionQuestionModeMutation, SetChatSessionQuestionModeMutationVariables>(SetChatSessionQuestionModeDocument, options);
      }
export type SetChatSessionQuestionModeMutationHookResult = ReturnType<typeof useSetChatSessionQuestionModeMutation>;
export type SetChatSessionQuestionModeMutationResult = Apollo.MutationResult<SetChatSessionQuestionModeMutation>;
export type SetChatSessionQuestionModeMutationOptions = Apollo.BaseMutationOptions<SetChatSessionQuestionModeMutation, SetChatSessionQuestionModeMutationVariables>;
export const MuteUserMicDocument = gql`
    mutation muteUserMic($input: MuteUserMicInput!) {
  muteUserMic(input: $input)
}
    `;
export type MuteUserMicMutationFn = Apollo.MutationFunction<MuteUserMicMutation, MuteUserMicMutationVariables>;

/**
 * __useMuteUserMicMutation__
 *
 * To run a mutation, you first call `useMuteUserMicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMuteUserMicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [muteUserMicMutation, { data, loading, error }] = useMuteUserMicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMuteUserMicMutation(baseOptions?: Apollo.MutationHookOptions<MuteUserMicMutation, MuteUserMicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MuteUserMicMutation, MuteUserMicMutationVariables>(MuteUserMicDocument, options);
      }
export type MuteUserMicMutationHookResult = ReturnType<typeof useMuteUserMicMutation>;
export type MuteUserMicMutationResult = Apollo.MutationResult<MuteUserMicMutation>;
export type MuteUserMicMutationOptions = Apollo.BaseMutationOptions<MuteUserMicMutation, MuteUserMicMutationVariables>;
export const RemoveChatSessionUserDocument = gql`
    mutation removeChatSessionUser($input: RemoveChatSessionUserInput!) {
  removeChatSessionUser(input: $input)
}
    `;
export type RemoveChatSessionUserMutationFn = Apollo.MutationFunction<RemoveChatSessionUserMutation, RemoveChatSessionUserMutationVariables>;

/**
 * __useRemoveChatSessionUserMutation__
 *
 * To run a mutation, you first call `useRemoveChatSessionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatSessionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChatSessionUserMutation, { data, loading, error }] = useRemoveChatSessionUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChatSessionUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChatSessionUserMutation, RemoveChatSessionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChatSessionUserMutation, RemoveChatSessionUserMutationVariables>(RemoveChatSessionUserDocument, options);
      }
export type RemoveChatSessionUserMutationHookResult = ReturnType<typeof useRemoveChatSessionUserMutation>;
export type RemoveChatSessionUserMutationResult = Apollo.MutationResult<RemoveChatSessionUserMutation>;
export type RemoveChatSessionUserMutationOptions = Apollo.BaseMutationOptions<RemoveChatSessionUserMutation, RemoveChatSessionUserMutationVariables>;
export const AddFilesDocument = gql`
    mutation addFiles($input: AddFilesInput!) {
  addFiles(input: $input) {
    id
    fileName
    fileType
    signedURL
  }
}
    `;
export type AddFilesMutationFn = Apollo.MutationFunction<AddFilesMutation, AddFilesMutationVariables>;

/**
 * __useAddFilesMutation__
 *
 * To run a mutation, you first call `useAddFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFilesMutation, { data, loading, error }] = useAddFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddFilesMutation, AddFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFilesMutation, AddFilesMutationVariables>(AddFilesDocument, options);
      }
export type AddFilesMutationHookResult = ReturnType<typeof useAddFilesMutation>;
export type AddFilesMutationResult = Apollo.MutationResult<AddFilesMutation>;
export type AddFilesMutationOptions = Apollo.BaseMutationOptions<AddFilesMutation, AddFilesMutationVariables>;
export const FilesDocument = gql`
    query files($input: FilesInput!) {
  files(input: $input) {
    id
    fileName
    fileType
    signedURL
  }
}
    `;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilesQuery(baseOptions: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const ChatCommandExecutedDocument = gql`
    subscription chatCommandExecuted($input: ChatCommandExecutedInput!) {
  chatCommandExecuted(input: $input)
}
    `;

/**
 * __useChatCommandExecutedSubscription__
 *
 * To run a query within a React component, call `useChatCommandExecutedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatCommandExecutedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCommandExecutedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCommandExecutedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatCommandExecutedSubscription, ChatCommandExecutedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatCommandExecutedSubscription, ChatCommandExecutedSubscriptionVariables>(ChatCommandExecutedDocument, options);
      }
export type ChatCommandExecutedSubscriptionHookResult = ReturnType<typeof useChatCommandExecutedSubscription>;
export type ChatCommandExecutedSubscriptionResult = Apollo.SubscriptionResult<ChatCommandExecutedSubscription>;
export const AddChatTemplateMessageDocument = gql`
    mutation addChatTemplateMessage($input: AddChatTemplateMessageInput!) {
  addChatTemplateMessage(input: $input) {
    id
  }
}
    `;
export type AddChatTemplateMessageMutationFn = Apollo.MutationFunction<AddChatTemplateMessageMutation, AddChatTemplateMessageMutationVariables>;

/**
 * __useAddChatTemplateMessageMutation__
 *
 * To run a mutation, you first call `useAddChatTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatTemplateMessageMutation, { data, loading, error }] = useAddChatTemplateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChatTemplateMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddChatTemplateMessageMutation, AddChatTemplateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChatTemplateMessageMutation, AddChatTemplateMessageMutationVariables>(AddChatTemplateMessageDocument, options);
      }
export type AddChatTemplateMessageMutationHookResult = ReturnType<typeof useAddChatTemplateMessageMutation>;
export type AddChatTemplateMessageMutationResult = Apollo.MutationResult<AddChatTemplateMessageMutation>;
export type AddChatTemplateMessageMutationOptions = Apollo.BaseMutationOptions<AddChatTemplateMessageMutation, AddChatTemplateMessageMutationVariables>;
export const NotifyJoinDocument = gql`
    mutation notifyJoin($input: NotifyJoinInput!) {
  notifyJoin(input: $input)
}
    `;
export type NotifyJoinMutationFn = Apollo.MutationFunction<NotifyJoinMutation, NotifyJoinMutationVariables>;

/**
 * __useNotifyJoinMutation__
 *
 * To run a mutation, you first call `useNotifyJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyJoinMutation, { data, loading, error }] = useNotifyJoinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyJoinMutation(baseOptions?: Apollo.MutationHookOptions<NotifyJoinMutation, NotifyJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyJoinMutation, NotifyJoinMutationVariables>(NotifyJoinDocument, options);
      }
export type NotifyJoinMutationHookResult = ReturnType<typeof useNotifyJoinMutation>;
export type NotifyJoinMutationResult = Apollo.MutationResult<NotifyJoinMutation>;
export type NotifyJoinMutationOptions = Apollo.BaseMutationOptions<NotifyJoinMutation, NotifyJoinMutationVariables>;
export const NotifyLeaveDocument = gql`
    mutation notifyLeave($input: NotifyLeaveInput!) {
  notifyLeave(input: $input)
}
    `;
export type NotifyLeaveMutationFn = Apollo.MutationFunction<NotifyLeaveMutation, NotifyLeaveMutationVariables>;

/**
 * __useNotifyLeaveMutation__
 *
 * To run a mutation, you first call `useNotifyLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyLeaveMutation, { data, loading, error }] = useNotifyLeaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyLeaveMutation(baseOptions?: Apollo.MutationHookOptions<NotifyLeaveMutation, NotifyLeaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyLeaveMutation, NotifyLeaveMutationVariables>(NotifyLeaveDocument, options);
      }
export type NotifyLeaveMutationHookResult = ReturnType<typeof useNotifyLeaveMutation>;
export type NotifyLeaveMutationResult = Apollo.MutationResult<NotifyLeaveMutation>;
export type NotifyLeaveMutationOptions = Apollo.BaseMutationOptions<NotifyLeaveMutation, NotifyLeaveMutationVariables>;
export const StartChatSessionDocument = gql`
    mutation startChatSession($input: StartChatSessionInput!) {
  startChatSession(input: $input) {
    sessionId
    sessionName
    state
    entryCode
    roomName
    clearedSequence
  }
}
    `;
export type StartChatSessionMutationFn = Apollo.MutationFunction<StartChatSessionMutation, StartChatSessionMutationVariables>;

/**
 * __useStartChatSessionMutation__
 *
 * To run a mutation, you first call `useStartChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChatSessionMutation, { data, loading, error }] = useStartChatSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartChatSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartChatSessionMutation, StartChatSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartChatSessionMutation, StartChatSessionMutationVariables>(StartChatSessionDocument, options);
      }
export type StartChatSessionMutationHookResult = ReturnType<typeof useStartChatSessionMutation>;
export type StartChatSessionMutationResult = Apollo.MutationResult<StartChatSessionMutation>;
export type StartChatSessionMutationOptions = Apollo.BaseMutationOptions<StartChatSessionMutation, StartChatSessionMutationVariables>;
export const EndChatSessionDocument = gql`
    mutation endChatSession($input: EndChatSessionInput!) {
  endChatSession(input: $input) {
    sessionId
    sessionName
    state
    entryCode
    roomName
    clearedSequence
  }
}
    `;
export type EndChatSessionMutationFn = Apollo.MutationFunction<EndChatSessionMutation, EndChatSessionMutationVariables>;

/**
 * __useEndChatSessionMutation__
 *
 * To run a mutation, you first call `useEndChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endChatSessionMutation, { data, loading, error }] = useEndChatSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndChatSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndChatSessionMutation, EndChatSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndChatSessionMutation, EndChatSessionMutationVariables>(EndChatSessionDocument, options);
      }
export type EndChatSessionMutationHookResult = ReturnType<typeof useEndChatSessionMutation>;
export type EndChatSessionMutationResult = Apollo.MutationResult<EndChatSessionMutation>;
export type EndChatSessionMutationOptions = Apollo.BaseMutationOptions<EndChatSessionMutation, EndChatSessionMutationVariables>;
export const UserSettingsDocument = gql`
    query userSettings {
  userSettings {
    name
  }
}
    `;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
      }
export function useUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
        }
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<UserSettingsQuery, UserSettingsQueryVariables>;
export const EditUserSettingsDocument = gql`
    mutation editUserSettings($input: EditUserSettingsInput!) {
  editUserSettings(input: $input) {
    name
  }
}
    `;
export type EditUserSettingsMutationFn = Apollo.MutationFunction<EditUserSettingsMutation, EditUserSettingsMutationVariables>;

/**
 * __useEditUserSettingsMutation__
 *
 * To run a mutation, you first call `useEditUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserSettingsMutation, { data, loading, error }] = useEditUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<EditUserSettingsMutation, EditUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserSettingsMutation, EditUserSettingsMutationVariables>(EditUserSettingsDocument, options);
      }
export type EditUserSettingsMutationHookResult = ReturnType<typeof useEditUserSettingsMutation>;
export type EditUserSettingsMutationResult = Apollo.MutationResult<EditUserSettingsMutation>;
export type EditUserSettingsMutationOptions = Apollo.BaseMutationOptions<EditUserSettingsMutation, EditUserSettingsMutationVariables>;
export const AdminSettingsDocument = gql`
    query adminSettings($input: AdminSettingsInput!) {
  adminSettings(input: $input) {
    key
    value
  }
}
    `;

/**
 * __useAdminSettingsQuery__
 *
 * To run a query within a React component, call `useAdminSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSettingsQuery(baseOptions: Apollo.QueryHookOptions<AdminSettingsQuery, AdminSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettingsQuery, AdminSettingsQueryVariables>(AdminSettingsDocument, options);
      }
export function useAdminSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettingsQuery, AdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettingsQuery, AdminSettingsQueryVariables>(AdminSettingsDocument, options);
        }
export type AdminSettingsQueryHookResult = ReturnType<typeof useAdminSettingsQuery>;
export type AdminSettingsLazyQueryHookResult = ReturnType<typeof useAdminSettingsLazyQuery>;
export type AdminSettingsQueryResult = Apollo.QueryResult<AdminSettingsQuery, AdminSettingsQueryVariables>;
export const EditAdminSettingsDocument = gql`
    mutation editAdminSettings($input: EditAdminSettingsInput!) {
  editAdminSettings(input: $input) {
    key
    value
  }
}
    `;
export type EditAdminSettingsMutationFn = Apollo.MutationFunction<EditAdminSettingsMutation, EditAdminSettingsMutationVariables>;

/**
 * __useEditAdminSettingsMutation__
 *
 * To run a mutation, you first call `useEditAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdminSettingsMutation, { data, loading, error }] = useEditAdminSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<EditAdminSettingsMutation, EditAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdminSettingsMutation, EditAdminSettingsMutationVariables>(EditAdminSettingsDocument, options);
      }
export type EditAdminSettingsMutationHookResult = ReturnType<typeof useEditAdminSettingsMutation>;
export type EditAdminSettingsMutationResult = Apollo.MutationResult<EditAdminSettingsMutation>;
export type EditAdminSettingsMutationOptions = Apollo.BaseMutationOptions<EditAdminSettingsMutation, EditAdminSettingsMutationVariables>;
export const HomophonesDocument = gql`
    query homophones($input: HomophonesInput!) {
  homophones(input: $input) {
    id
    createdAt
    disabled
    kana
    sourceWord
    targetWord
    usageCount
    homophoneCategory {
      id
      name
      createdAt
      homophonesCount
    }
  }
}
    `;

/**
 * __useHomophonesQuery__
 *
 * To run a query within a React component, call `useHomophonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomophonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomophonesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomophonesQuery(baseOptions: Apollo.QueryHookOptions<HomophonesQuery, HomophonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomophonesQuery, HomophonesQueryVariables>(HomophonesDocument, options);
      }
export function useHomophonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomophonesQuery, HomophonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomophonesQuery, HomophonesQueryVariables>(HomophonesDocument, options);
        }
export type HomophonesQueryHookResult = ReturnType<typeof useHomophonesQuery>;
export type HomophonesLazyQueryHookResult = ReturnType<typeof useHomophonesLazyQuery>;
export type HomophonesQueryResult = Apollo.QueryResult<HomophonesQuery, HomophonesQueryVariables>;
export const ImportHomophonesDocument = gql`
    mutation importHomophones($input: ImportHomophonesInput!) {
  importHomophones(input: $input) {
    disabled
    kana
    sourceWord
    targetWord
    homophoneId
    homophoneCategoryId
    homophoneCategoryName
    errorCode
  }
}
    `;
export type ImportHomophonesMutationFn = Apollo.MutationFunction<ImportHomophonesMutation, ImportHomophonesMutationVariables>;

/**
 * __useImportHomophonesMutation__
 *
 * To run a mutation, you first call `useImportHomophonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHomophonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHomophonesMutation, { data, loading, error }] = useImportHomophonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportHomophonesMutation(baseOptions?: Apollo.MutationHookOptions<ImportHomophonesMutation, ImportHomophonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportHomophonesMutation, ImportHomophonesMutationVariables>(ImportHomophonesDocument, options);
      }
export type ImportHomophonesMutationHookResult = ReturnType<typeof useImportHomophonesMutation>;
export type ImportHomophonesMutationResult = Apollo.MutationResult<ImportHomophonesMutation>;
export type ImportHomophonesMutationOptions = Apollo.BaseMutationOptions<ImportHomophonesMutation, ImportHomophonesMutationVariables>;
export const RemoveHomophonesDocument = gql`
    mutation removeHomophones($input: RemoveHomophonesInput!) {
  removeHomophones(input: $input)
}
    `;
export type RemoveHomophonesMutationFn = Apollo.MutationFunction<RemoveHomophonesMutation, RemoveHomophonesMutationVariables>;

/**
 * __useRemoveHomophonesMutation__
 *
 * To run a mutation, you first call `useRemoveHomophonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHomophonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHomophonesMutation, { data, loading, error }] = useRemoveHomophonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveHomophonesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHomophonesMutation, RemoveHomophonesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHomophonesMutation, RemoveHomophonesMutationVariables>(RemoveHomophonesDocument, options);
      }
export type RemoveHomophonesMutationHookResult = ReturnType<typeof useRemoveHomophonesMutation>;
export type RemoveHomophonesMutationResult = Apollo.MutationResult<RemoveHomophonesMutation>;
export type RemoveHomophonesMutationOptions = Apollo.BaseMutationOptions<RemoveHomophonesMutation, RemoveHomophonesMutationVariables>;
export const HomophoneCategoriesDocument = gql`
    query homophoneCategories($input: HomophoneCategoriesInput!) {
  homophoneCategories(input: $input) {
    id
    name
    createdAt
    homophonesCount
  }
}
    `;

/**
 * __useHomophoneCategoriesQuery__
 *
 * To run a query within a React component, call `useHomophoneCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomophoneCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomophoneCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomophoneCategoriesQuery(baseOptions: Apollo.QueryHookOptions<HomophoneCategoriesQuery, HomophoneCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomophoneCategoriesQuery, HomophoneCategoriesQueryVariables>(HomophoneCategoriesDocument, options);
      }
export function useHomophoneCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomophoneCategoriesQuery, HomophoneCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomophoneCategoriesQuery, HomophoneCategoriesQueryVariables>(HomophoneCategoriesDocument, options);
        }
export type HomophoneCategoriesQueryHookResult = ReturnType<typeof useHomophoneCategoriesQuery>;
export type HomophoneCategoriesLazyQueryHookResult = ReturnType<typeof useHomophoneCategoriesLazyQuery>;
export type HomophoneCategoriesQueryResult = Apollo.QueryResult<HomophoneCategoriesQuery, HomophoneCategoriesQueryVariables>;
export const RemoveHomophoneCategorysDocument = gql`
    mutation removeHomophoneCategorys($input: RemoveHomophoneCategoriesInput!) {
  removeHomophoneCategories(input: $input)
}
    `;
export type RemoveHomophoneCategorysMutationFn = Apollo.MutationFunction<RemoveHomophoneCategorysMutation, RemoveHomophoneCategorysMutationVariables>;

/**
 * __useRemoveHomophoneCategorysMutation__
 *
 * To run a mutation, you first call `useRemoveHomophoneCategorysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHomophoneCategorysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHomophoneCategorysMutation, { data, loading, error }] = useRemoveHomophoneCategorysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveHomophoneCategorysMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHomophoneCategorysMutation, RemoveHomophoneCategorysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHomophoneCategorysMutation, RemoveHomophoneCategorysMutationVariables>(RemoveHomophoneCategorysDocument, options);
      }
export type RemoveHomophoneCategorysMutationHookResult = ReturnType<typeof useRemoveHomophoneCategorysMutation>;
export type RemoveHomophoneCategorysMutationResult = Apollo.MutationResult<RemoveHomophoneCategorysMutation>;
export type RemoveHomophoneCategorysMutationOptions = Apollo.BaseMutationOptions<RemoveHomophoneCategorysMutation, RemoveHomophoneCategorysMutationVariables>;
export const EditHomphoneCategoryDocument = gql`
    mutation editHomphoneCategory($input: EditHomophoneCategoryInput!) {
  editHomophoneCategory(input: $input) {
    id
    name
    createdAt
    homophonesCount
  }
}
    `;
export type EditHomphoneCategoryMutationFn = Apollo.MutationFunction<EditHomphoneCategoryMutation, EditHomphoneCategoryMutationVariables>;

/**
 * __useEditHomphoneCategoryMutation__
 *
 * To run a mutation, you first call `useEditHomphoneCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHomphoneCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHomphoneCategoryMutation, { data, loading, error }] = useEditHomphoneCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditHomphoneCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditHomphoneCategoryMutation, EditHomphoneCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditHomphoneCategoryMutation, EditHomphoneCategoryMutationVariables>(EditHomphoneCategoryDocument, options);
      }
export type EditHomphoneCategoryMutationHookResult = ReturnType<typeof useEditHomphoneCategoryMutation>;
export type EditHomphoneCategoryMutationResult = Apollo.MutationResult<EditHomphoneCategoryMutation>;
export type EditHomphoneCategoryMutationOptions = Apollo.BaseMutationOptions<EditHomphoneCategoryMutation, EditHomphoneCategoryMutationVariables>;
export const NgWordsDocument = gql`
    query ngWords($input: NgWordsInput!) {
  ngWords(input: $input) {
    id
    createdAt
    ngWordCategory {
      id
      name
      createdAt
    }
    word
  }
}
    `;

/**
 * __useNgWordsQuery__
 *
 * To run a query within a React component, call `useNgWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNgWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNgWordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNgWordsQuery(baseOptions: Apollo.QueryHookOptions<NgWordsQuery, NgWordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NgWordsQuery, NgWordsQueryVariables>(NgWordsDocument, options);
      }
export function useNgWordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NgWordsQuery, NgWordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NgWordsQuery, NgWordsQueryVariables>(NgWordsDocument, options);
        }
export type NgWordsQueryHookResult = ReturnType<typeof useNgWordsQuery>;
export type NgWordsLazyQueryHookResult = ReturnType<typeof useNgWordsLazyQuery>;
export type NgWordsQueryResult = Apollo.QueryResult<NgWordsQuery, NgWordsQueryVariables>;
export const AddNgWordDocument = gql`
    mutation addNgWord($input: AddNgWordInput!) {
  addNgWord(input: $input) {
    id
    createdAt
    ngWordCategory {
      id
      name
      createdAt
    }
    word
  }
}
    `;
export type AddNgWordMutationFn = Apollo.MutationFunction<AddNgWordMutation, AddNgWordMutationVariables>;

/**
 * __useAddNgWordMutation__
 *
 * To run a mutation, you first call `useAddNgWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNgWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNgWordMutation, { data, loading, error }] = useAddNgWordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNgWordMutation(baseOptions?: Apollo.MutationHookOptions<AddNgWordMutation, AddNgWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNgWordMutation, AddNgWordMutationVariables>(AddNgWordDocument, options);
      }
export type AddNgWordMutationHookResult = ReturnType<typeof useAddNgWordMutation>;
export type AddNgWordMutationResult = Apollo.MutationResult<AddNgWordMutation>;
export type AddNgWordMutationOptions = Apollo.BaseMutationOptions<AddNgWordMutation, AddNgWordMutationVariables>;
export const EditNgWordDocument = gql`
    mutation editNgWord($input: EditNgWordInput!) {
  editNgWord(input: $input) {
    id
    createdAt
    ngWordCategory {
      id
      name
      createdAt
    }
    word
  }
}
    `;
export type EditNgWordMutationFn = Apollo.MutationFunction<EditNgWordMutation, EditNgWordMutationVariables>;

/**
 * __useEditNgWordMutation__
 *
 * To run a mutation, you first call `useEditNgWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNgWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNgWordMutation, { data, loading, error }] = useEditNgWordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNgWordMutation(baseOptions?: Apollo.MutationHookOptions<EditNgWordMutation, EditNgWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNgWordMutation, EditNgWordMutationVariables>(EditNgWordDocument, options);
      }
export type EditNgWordMutationHookResult = ReturnType<typeof useEditNgWordMutation>;
export type EditNgWordMutationResult = Apollo.MutationResult<EditNgWordMutation>;
export type EditNgWordMutationOptions = Apollo.BaseMutationOptions<EditNgWordMutation, EditNgWordMutationVariables>;
export const RemoveNgWordsDocument = gql`
    mutation removeNgWords($input: RemoveNgWordsInput!) {
  removeNgWords(input: $input)
}
    `;
export type RemoveNgWordsMutationFn = Apollo.MutationFunction<RemoveNgWordsMutation, RemoveNgWordsMutationVariables>;

/**
 * __useRemoveNgWordsMutation__
 *
 * To run a mutation, you first call `useRemoveNgWordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNgWordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNgWordsMutation, { data, loading, error }] = useRemoveNgWordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveNgWordsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNgWordsMutation, RemoveNgWordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNgWordsMutation, RemoveNgWordsMutationVariables>(RemoveNgWordsDocument, options);
      }
export type RemoveNgWordsMutationHookResult = ReturnType<typeof useRemoveNgWordsMutation>;
export type RemoveNgWordsMutationResult = Apollo.MutationResult<RemoveNgWordsMutation>;
export type RemoveNgWordsMutationOptions = Apollo.BaseMutationOptions<RemoveNgWordsMutation, RemoveNgWordsMutationVariables>;
export const ImportNgWordsDocument = gql`
    mutation importNgWords($input: ImportNgWordsInput!) {
  importNgWords(input: $input) {
    ngWordId
    ngWordCategoryId
    ngWordCategoryName
    word
    errorCode
  }
}
    `;
export type ImportNgWordsMutationFn = Apollo.MutationFunction<ImportNgWordsMutation, ImportNgWordsMutationVariables>;

/**
 * __useImportNgWordsMutation__
 *
 * To run a mutation, you first call `useImportNgWordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportNgWordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importNgWordsMutation, { data, loading, error }] = useImportNgWordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportNgWordsMutation(baseOptions?: Apollo.MutationHookOptions<ImportNgWordsMutation, ImportNgWordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportNgWordsMutation, ImportNgWordsMutationVariables>(ImportNgWordsDocument, options);
      }
export type ImportNgWordsMutationHookResult = ReturnType<typeof useImportNgWordsMutation>;
export type ImportNgWordsMutationResult = Apollo.MutationResult<ImportNgWordsMutation>;
export type ImportNgWordsMutationOptions = Apollo.BaseMutationOptions<ImportNgWordsMutation, ImportNgWordsMutationVariables>;
export const NgWordCategoriesDocument = gql`
    query ngWordCategories($input: NgWordCategoriesInput!) {
  ngWordCategories(input: $input) {
    id
    createdAt
    name
    ngWordsCount
  }
}
    `;

/**
 * __useNgWordCategoriesQuery__
 *
 * To run a query within a React component, call `useNgWordCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNgWordCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNgWordCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNgWordCategoriesQuery(baseOptions: Apollo.QueryHookOptions<NgWordCategoriesQuery, NgWordCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NgWordCategoriesQuery, NgWordCategoriesQueryVariables>(NgWordCategoriesDocument, options);
      }
export function useNgWordCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NgWordCategoriesQuery, NgWordCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NgWordCategoriesQuery, NgWordCategoriesQueryVariables>(NgWordCategoriesDocument, options);
        }
export type NgWordCategoriesQueryHookResult = ReturnType<typeof useNgWordCategoriesQuery>;
export type NgWordCategoriesLazyQueryHookResult = ReturnType<typeof useNgWordCategoriesLazyQuery>;
export type NgWordCategoriesQueryResult = Apollo.QueryResult<NgWordCategoriesQuery, NgWordCategoriesQueryVariables>;
export const AddNgWordCategoryDocument = gql`
    mutation addNgWordCategory($input: AddNgWordCategoryInput!) {
  addNgWordCategory(input: $input) {
    id
    createdAt
    name
  }
}
    `;
export type AddNgWordCategoryMutationFn = Apollo.MutationFunction<AddNgWordCategoryMutation, AddNgWordCategoryMutationVariables>;

/**
 * __useAddNgWordCategoryMutation__
 *
 * To run a mutation, you first call `useAddNgWordCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNgWordCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNgWordCategoryMutation, { data, loading, error }] = useAddNgWordCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNgWordCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddNgWordCategoryMutation, AddNgWordCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNgWordCategoryMutation, AddNgWordCategoryMutationVariables>(AddNgWordCategoryDocument, options);
      }
export type AddNgWordCategoryMutationHookResult = ReturnType<typeof useAddNgWordCategoryMutation>;
export type AddNgWordCategoryMutationResult = Apollo.MutationResult<AddNgWordCategoryMutation>;
export type AddNgWordCategoryMutationOptions = Apollo.BaseMutationOptions<AddNgWordCategoryMutation, AddNgWordCategoryMutationVariables>;
export const RemoveNgWordCategoriesDocument = gql`
    mutation removeNgWordCategories($input: RemoveNgWordCategoriesInput!) {
  removeNgWordCategories(input: $input)
}
    `;
export type RemoveNgWordCategoriesMutationFn = Apollo.MutationFunction<RemoveNgWordCategoriesMutation, RemoveNgWordCategoriesMutationVariables>;

/**
 * __useRemoveNgWordCategoriesMutation__
 *
 * To run a mutation, you first call `useRemoveNgWordCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNgWordCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNgWordCategoriesMutation, { data, loading, error }] = useRemoveNgWordCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveNgWordCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNgWordCategoriesMutation, RemoveNgWordCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNgWordCategoriesMutation, RemoveNgWordCategoriesMutationVariables>(RemoveNgWordCategoriesDocument, options);
      }
export type RemoveNgWordCategoriesMutationHookResult = ReturnType<typeof useRemoveNgWordCategoriesMutation>;
export type RemoveNgWordCategoriesMutationResult = Apollo.MutationResult<RemoveNgWordCategoriesMutation>;
export type RemoveNgWordCategoriesMutationOptions = Apollo.BaseMutationOptions<RemoveNgWordCategoriesMutation, RemoveNgWordCategoriesMutationVariables>;
export const NotificationsDocument = gql`
    query notifications($input: NotificationsInput!) {
  notifications(input: $input) {
    id
    startedAt
    endedAt
    message
    disabled
    ownerCompanyId
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UpsertNotifcationDocument = gql`
    mutation upsertNotifcation($input: UpsertNotificationInput!) {
  upsertNotifcation(input: $input) {
    id
    startedAt
    endedAt
    message
    disabled
    ownerCompanyId
  }
}
    `;
export type UpsertNotifcationMutationFn = Apollo.MutationFunction<UpsertNotifcationMutation, UpsertNotifcationMutationVariables>;

/**
 * __useUpsertNotifcationMutation__
 *
 * To run a mutation, you first call `useUpsertNotifcationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotifcationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotifcationMutation, { data, loading, error }] = useUpsertNotifcationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertNotifcationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertNotifcationMutation, UpsertNotifcationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertNotifcationMutation, UpsertNotifcationMutationVariables>(UpsertNotifcationDocument, options);
      }
export type UpsertNotifcationMutationHookResult = ReturnType<typeof useUpsertNotifcationMutation>;
export type UpsertNotifcationMutationResult = Apollo.MutationResult<UpsertNotifcationMutation>;
export type UpsertNotifcationMutationOptions = Apollo.BaseMutationOptions<UpsertNotifcationMutation, UpsertNotifcationMutationVariables>;
export const OwnerCompaniesDocument = gql`
    query ownerCompanies($input: OwnerCompaniesInput!) {
  ownerCompanies(input: $input) {
    id
    name
    code
    userName
    userLimit
    roomLimit
    note
    canUseSimulteneous
  }
}
    `;

/**
 * __useOwnerCompaniesQuery__
 *
 * To run a query within a React component, call `useOwnerCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnerCompaniesQuery(baseOptions: Apollo.QueryHookOptions<OwnerCompaniesQuery, OwnerCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnerCompaniesQuery, OwnerCompaniesQueryVariables>(OwnerCompaniesDocument, options);
      }
export function useOwnerCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnerCompaniesQuery, OwnerCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnerCompaniesQuery, OwnerCompaniesQueryVariables>(OwnerCompaniesDocument, options);
        }
export type OwnerCompaniesQueryHookResult = ReturnType<typeof useOwnerCompaniesQuery>;
export type OwnerCompaniesLazyQueryHookResult = ReturnType<typeof useOwnerCompaniesLazyQuery>;
export type OwnerCompaniesQueryResult = Apollo.QueryResult<OwnerCompaniesQuery, OwnerCompaniesQueryVariables>;
export const AddOwnerCompanyDocument = gql`
    mutation addOwnerCompany($input: AddOwnerCompanyInput!) {
  addOwnerCompany(input: $input) {
    __typename
    ... on OwnerCompany {
      id
      name
      code
      userName
      userLimit
      roomLimit
      canUseSimulteneous
      note
    }
    ... on UserNameAlreadyExists {
      message
    }
  }
}
    `;
export type AddOwnerCompanyMutationFn = Apollo.MutationFunction<AddOwnerCompanyMutation, AddOwnerCompanyMutationVariables>;

/**
 * __useAddOwnerCompanyMutation__
 *
 * To run a mutation, you first call `useAddOwnerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerCompanyMutation, { data, loading, error }] = useAddOwnerCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddOwnerCompanyMutation, AddOwnerCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOwnerCompanyMutation, AddOwnerCompanyMutationVariables>(AddOwnerCompanyDocument, options);
      }
export type AddOwnerCompanyMutationHookResult = ReturnType<typeof useAddOwnerCompanyMutation>;
export type AddOwnerCompanyMutationResult = Apollo.MutationResult<AddOwnerCompanyMutation>;
export type AddOwnerCompanyMutationOptions = Apollo.BaseMutationOptions<AddOwnerCompanyMutation, AddOwnerCompanyMutationVariables>;
export const EditOwnerCompanyDocument = gql`
    mutation editOwnerCompany($input: EditOwnerCompanyInput!) {
  editOwnerCompany(input: $input) {
    __typename
    ... on OwnerCompany {
      id
      name
      code
      userName
      userLimit
      roomLimit
      canUseSimulteneous
      note
    }
    ... on UserNameAlreadyExists {
      message
    }
  }
}
    `;
export type EditOwnerCompanyMutationFn = Apollo.MutationFunction<EditOwnerCompanyMutation, EditOwnerCompanyMutationVariables>;

/**
 * __useEditOwnerCompanyMutation__
 *
 * To run a mutation, you first call `useEditOwnerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOwnerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOwnerCompanyMutation, { data, loading, error }] = useEditOwnerCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOwnerCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditOwnerCompanyMutation, EditOwnerCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOwnerCompanyMutation, EditOwnerCompanyMutationVariables>(EditOwnerCompanyDocument, options);
      }
export type EditOwnerCompanyMutationHookResult = ReturnType<typeof useEditOwnerCompanyMutation>;
export type EditOwnerCompanyMutationResult = Apollo.MutationResult<EditOwnerCompanyMutation>;
export type EditOwnerCompanyMutationOptions = Apollo.BaseMutationOptions<EditOwnerCompanyMutation, EditOwnerCompanyMutationVariables>;
export const RemoveOwnerCompanyDocument = gql`
    mutation removeOwnerCompany($input: RemoveOwnerCompanyInput!) {
  removeOwnerCompany(input: $input) {
    id
    name
    code
    userName
    userLimit
    roomLimit
    canUseSimulteneous
    note
  }
}
    `;
export type RemoveOwnerCompanyMutationFn = Apollo.MutationFunction<RemoveOwnerCompanyMutation, RemoveOwnerCompanyMutationVariables>;

/**
 * __useRemoveOwnerCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveOwnerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnerCompanyMutation, { data, loading, error }] = useRemoveOwnerCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOwnerCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOwnerCompanyMutation, RemoveOwnerCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOwnerCompanyMutation, RemoveOwnerCompanyMutationVariables>(RemoveOwnerCompanyDocument, options);
      }
export type RemoveOwnerCompanyMutationHookResult = ReturnType<typeof useRemoveOwnerCompanyMutation>;
export type RemoveOwnerCompanyMutationResult = Apollo.MutationResult<RemoveOwnerCompanyMutation>;
export type RemoveOwnerCompanyMutationOptions = Apollo.BaseMutationOptions<RemoveOwnerCompanyMutation, RemoveOwnerCompanyMutationVariables>;
export const ChatRoomsDocument = gql`
    query chatRooms($input: ChatRoomsInput!) {
  chatRooms(input: $input) {
    id
    name
    capacity
    createdAt
    tenantCompanyId
    templateCategoryIds
    homophoneCategoryIds
    sessions {
      id
      entryCode
      name
      chatRoomId
      currentSequence
      state
    }
    translateMethod
    isWaitingRoomEnabled
    isWaitingRoomNotificationEnabled
    isGuestInputRestricted
    isNotifyOnMessageEnabled
    isGuideMicControlLinked
    isGuestMicControlLinked
    waitingGuests {
      id
      name
    }
  }
}
    `;

/**
 * __useChatRoomsQuery__
 *
 * To run a query within a React component, call `useChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatRoomsQuery(baseOptions: Apollo.QueryHookOptions<ChatRoomsQuery, ChatRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatRoomsQuery, ChatRoomsQueryVariables>(ChatRoomsDocument, options);
      }
export function useChatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatRoomsQuery, ChatRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatRoomsQuery, ChatRoomsQueryVariables>(ChatRoomsDocument, options);
        }
export type ChatRoomsQueryHookResult = ReturnType<typeof useChatRoomsQuery>;
export type ChatRoomsLazyQueryHookResult = ReturnType<typeof useChatRoomsLazyQuery>;
export type ChatRoomsQueryResult = Apollo.QueryResult<ChatRoomsQuery, ChatRoomsQueryVariables>;
export const AddChatRoomDocument = gql`
    mutation addChatRoom($input: AddChatRoomInput!) {
  addChatRoom(input: $input) {
    __typename
    ... on ChatRoom {
      id
      name
      capacity
      createdAt
      tenantCompanyId
      translateMethod
      isWaitingRoomEnabled
      isWaitingRoomNotificationEnabled
      isGuestInputRestricted
      isNotifyOnMessageEnabled
      isGuideMicControlLinked
      isGuestMicControlLinked
    }
    ... on ExcessRoomCreationLimit {
      message
      limit
    }
  }
}
    `;
export type AddChatRoomMutationFn = Apollo.MutationFunction<AddChatRoomMutation, AddChatRoomMutationVariables>;

/**
 * __useAddChatRoomMutation__
 *
 * To run a mutation, you first call `useAddChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatRoomMutation, { data, loading, error }] = useAddChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddChatRoomMutation, AddChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChatRoomMutation, AddChatRoomMutationVariables>(AddChatRoomDocument, options);
      }
export type AddChatRoomMutationHookResult = ReturnType<typeof useAddChatRoomMutation>;
export type AddChatRoomMutationResult = Apollo.MutationResult<AddChatRoomMutation>;
export type AddChatRoomMutationOptions = Apollo.BaseMutationOptions<AddChatRoomMutation, AddChatRoomMutationVariables>;
export const EditChatRoomDocument = gql`
    mutation editChatRoom($input: EditChatRoomInput!) {
  editChatRoom(input: $input) {
    id
    name
    capacity
    createdAt
    templateCategoryIds
    homophoneCategoryIds
    translateMethod
    isWaitingRoomEnabled
    isWaitingRoomNotificationEnabled
    isGuestInputRestricted
    isGuideMicControlLinked
    isGuestMicControlLinked
  }
}
    `;
export type EditChatRoomMutationFn = Apollo.MutationFunction<EditChatRoomMutation, EditChatRoomMutationVariables>;

/**
 * __useEditChatRoomMutation__
 *
 * To run a mutation, you first call `useEditChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChatRoomMutation, { data, loading, error }] = useEditChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<EditChatRoomMutation, EditChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChatRoomMutation, EditChatRoomMutationVariables>(EditChatRoomDocument, options);
      }
export type EditChatRoomMutationHookResult = ReturnType<typeof useEditChatRoomMutation>;
export type EditChatRoomMutationResult = Apollo.MutationResult<EditChatRoomMutation>;
export type EditChatRoomMutationOptions = Apollo.BaseMutationOptions<EditChatRoomMutation, EditChatRoomMutationVariables>;
export const RemoveChatRoomDocument = gql`
    mutation removeChatRoom($input: RemoveChatRoomInput!) {
  removeChatRoom(input: $input) {
    id
    name
    capacity
    createdAt
    tenantCompanyId
    templateCategoryIds
    homophoneCategoryIds
    isWaitingRoomEnabled
    isWaitingRoomNotificationEnabled
    isGuestInputRestricted
    isGuideMicControlLinked
    isGuestMicControlLinked
  }
}
    `;
export type RemoveChatRoomMutationFn = Apollo.MutationFunction<RemoveChatRoomMutation, RemoveChatRoomMutationVariables>;

/**
 * __useRemoveChatRoomMutation__
 *
 * To run a mutation, you first call `useRemoveChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChatRoomMutation, { data, loading, error }] = useRemoveChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChatRoomMutation, RemoveChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChatRoomMutation, RemoveChatRoomMutationVariables>(RemoveChatRoomDocument, options);
      }
export type RemoveChatRoomMutationHookResult = ReturnType<typeof useRemoveChatRoomMutation>;
export type RemoveChatRoomMutationResult = Apollo.MutationResult<RemoveChatRoomMutation>;
export type RemoveChatRoomMutationOptions = Apollo.BaseMutationOptions<RemoveChatRoomMutation, RemoveChatRoomMutationVariables>;
export const AddChatSessionDocument = gql`
    mutation addChatSession($input: AddChatSessionInput!) {
  addChatSession(input: $input) {
    id
    chatRoomId
    currentSequence
    entryCode
    name
    state
  }
}
    `;
export type AddChatSessionMutationFn = Apollo.MutationFunction<AddChatSessionMutation, AddChatSessionMutationVariables>;

/**
 * __useAddChatSessionMutation__
 *
 * To run a mutation, you first call `useAddChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatSessionMutation, { data, loading, error }] = useAddChatSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChatSessionMutation(baseOptions?: Apollo.MutationHookOptions<AddChatSessionMutation, AddChatSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChatSessionMutation, AddChatSessionMutationVariables>(AddChatSessionDocument, options);
      }
export type AddChatSessionMutationHookResult = ReturnType<typeof useAddChatSessionMutation>;
export type AddChatSessionMutationResult = Apollo.MutationResult<AddChatSessionMutation>;
export type AddChatSessionMutationOptions = Apollo.BaseMutationOptions<AddChatSessionMutation, AddChatSessionMutationVariables>;
export const EditChatSessionDocument = gql`
    mutation editChatSession($input: EditChatSessionInput!) {
  editChatSession(input: $input) {
    id
    entryCode
    chatRoomId
    state
  }
}
    `;
export type EditChatSessionMutationFn = Apollo.MutationFunction<EditChatSessionMutation, EditChatSessionMutationVariables>;

/**
 * __useEditChatSessionMutation__
 *
 * To run a mutation, you first call `useEditChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChatSessionMutation, { data, loading, error }] = useEditChatSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChatSessionMutation(baseOptions?: Apollo.MutationHookOptions<EditChatSessionMutation, EditChatSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChatSessionMutation, EditChatSessionMutationVariables>(EditChatSessionDocument, options);
      }
export type EditChatSessionMutationHookResult = ReturnType<typeof useEditChatSessionMutation>;
export type EditChatSessionMutationResult = Apollo.MutationResult<EditChatSessionMutation>;
export type EditChatSessionMutationOptions = Apollo.BaseMutationOptions<EditChatSessionMutation, EditChatSessionMutationVariables>;
export const SuperUsersDocument = gql`
    query superUsers($input: SuperUsersInput!) {
  superUsers(input: $input) {
    id
    userName
  }
}
    `;

/**
 * __useSuperUsersQuery__
 *
 * To run a query within a React component, call `useSuperUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuperUsersQuery(baseOptions: Apollo.QueryHookOptions<SuperUsersQuery, SuperUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperUsersQuery, SuperUsersQueryVariables>(SuperUsersDocument, options);
      }
export function useSuperUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperUsersQuery, SuperUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperUsersQuery, SuperUsersQueryVariables>(SuperUsersDocument, options);
        }
export type SuperUsersQueryHookResult = ReturnType<typeof useSuperUsersQuery>;
export type SuperUsersLazyQueryHookResult = ReturnType<typeof useSuperUsersLazyQuery>;
export type SuperUsersQueryResult = Apollo.QueryResult<SuperUsersQuery, SuperUsersQueryVariables>;
export const AddSuperUserDocument = gql`
    mutation addSuperUser($input: AddSuperUserInput!) {
  addSuperUser(input: $input) {
    __typename
    ... on SuperUser {
      id
      userName
    }
    ... on UserNameAlreadyExists {
      message
    }
  }
}
    `;
export type AddSuperUserMutationFn = Apollo.MutationFunction<AddSuperUserMutation, AddSuperUserMutationVariables>;

/**
 * __useAddSuperUserMutation__
 *
 * To run a mutation, you first call `useAddSuperUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSuperUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSuperUserMutation, { data, loading, error }] = useAddSuperUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSuperUserMutation(baseOptions?: Apollo.MutationHookOptions<AddSuperUserMutation, AddSuperUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSuperUserMutation, AddSuperUserMutationVariables>(AddSuperUserDocument, options);
      }
export type AddSuperUserMutationHookResult = ReturnType<typeof useAddSuperUserMutation>;
export type AddSuperUserMutationResult = Apollo.MutationResult<AddSuperUserMutation>;
export type AddSuperUserMutationOptions = Apollo.BaseMutationOptions<AddSuperUserMutation, AddSuperUserMutationVariables>;
export const EditSuperUserDocument = gql`
    mutation editSuperUser($input: EditSuperUserInput!) {
  editSuperUser(input: $input) {
    __typename
    ... on SuperUser {
      id
      userName
    }
    ... on UserNameAlreadyExists {
      message
    }
  }
}
    `;
export type EditSuperUserMutationFn = Apollo.MutationFunction<EditSuperUserMutation, EditSuperUserMutationVariables>;

/**
 * __useEditSuperUserMutation__
 *
 * To run a mutation, you first call `useEditSuperUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSuperUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSuperUserMutation, { data, loading, error }] = useEditSuperUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSuperUserMutation(baseOptions?: Apollo.MutationHookOptions<EditSuperUserMutation, EditSuperUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSuperUserMutation, EditSuperUserMutationVariables>(EditSuperUserDocument, options);
      }
export type EditSuperUserMutationHookResult = ReturnType<typeof useEditSuperUserMutation>;
export type EditSuperUserMutationResult = Apollo.MutationResult<EditSuperUserMutation>;
export type EditSuperUserMutationOptions = Apollo.BaseMutationOptions<EditSuperUserMutation, EditSuperUserMutationVariables>;
export const RemoveSuperUserDocument = gql`
    mutation removeSuperUser($input: RemoveSuperUserInput!) {
  removeSuperUser(input: $input) {
    id
    userName
  }
}
    `;
export type RemoveSuperUserMutationFn = Apollo.MutationFunction<RemoveSuperUserMutation, RemoveSuperUserMutationVariables>;

/**
 * __useRemoveSuperUserMutation__
 *
 * To run a mutation, you first call `useRemoveSuperUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSuperUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSuperUserMutation, { data, loading, error }] = useRemoveSuperUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSuperUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSuperUserMutation, RemoveSuperUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSuperUserMutation, RemoveSuperUserMutationVariables>(RemoveSuperUserDocument, options);
      }
export type RemoveSuperUserMutationHookResult = ReturnType<typeof useRemoveSuperUserMutation>;
export type RemoveSuperUserMutationResult = Apollo.MutationResult<RemoveSuperUserMutation>;
export type RemoveSuperUserMutationOptions = Apollo.BaseMutationOptions<RemoveSuperUserMutation, RemoveSuperUserMutationVariables>;
export const TemplateMessagesDocument = gql`
    query templateMessages($input: TemplateMessagesInput!) {
  templateMessages(input: $input) {
    id
    category {
      id
      name
    }
    languages {
      message
      language
      readonly
    }
    files {
      id
      fileName
      fileType
    }
    createdAt
    order
  }
}
    `;

/**
 * __useTemplateMessagesQuery__
 *
 * To run a query within a React component, call `useTemplateMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateMessagesQuery(baseOptions: Apollo.QueryHookOptions<TemplateMessagesQuery, TemplateMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateMessagesQuery, TemplateMessagesQueryVariables>(TemplateMessagesDocument, options);
      }
export function useTemplateMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateMessagesQuery, TemplateMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateMessagesQuery, TemplateMessagesQueryVariables>(TemplateMessagesDocument, options);
        }
export type TemplateMessagesQueryHookResult = ReturnType<typeof useTemplateMessagesQuery>;
export type TemplateMessagesLazyQueryHookResult = ReturnType<typeof useTemplateMessagesLazyQuery>;
export type TemplateMessagesQueryResult = Apollo.QueryResult<TemplateMessagesQuery, TemplateMessagesQueryVariables>;
export const AddTemplateMessageDocument = gql`
    mutation addTemplateMessage($input: AddTemplateMessageInput!) {
  addTemplateMessage(input: $input) {
    id
    category {
      id
      name
    }
    languages {
      message
      language
      readonly
    }
    createdAt
    order
  }
}
    `;
export type AddTemplateMessageMutationFn = Apollo.MutationFunction<AddTemplateMessageMutation, AddTemplateMessageMutationVariables>;

/**
 * __useAddTemplateMessageMutation__
 *
 * To run a mutation, you first call `useAddTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMessageMutation, { data, loading, error }] = useAddTemplateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMessageMutation, AddTemplateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateMessageMutation, AddTemplateMessageMutationVariables>(AddTemplateMessageDocument, options);
      }
export type AddTemplateMessageMutationHookResult = ReturnType<typeof useAddTemplateMessageMutation>;
export type AddTemplateMessageMutationResult = Apollo.MutationResult<AddTemplateMessageMutation>;
export type AddTemplateMessageMutationOptions = Apollo.BaseMutationOptions<AddTemplateMessageMutation, AddTemplateMessageMutationVariables>;
export const EditTemplateMessageDocument = gql`
    mutation editTemplateMessage($input: EditTemplateMessageInput!) {
  editTemplateMessage(input: $input) {
    id
    category {
      id
      name
    }
    languages {
      message
      language
    }
    order
  }
}
    `;
export type EditTemplateMessageMutationFn = Apollo.MutationFunction<EditTemplateMessageMutation, EditTemplateMessageMutationVariables>;

/**
 * __useEditTemplateMessageMutation__
 *
 * To run a mutation, you first call `useEditTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMessageMutation, { data, loading, error }] = useEditTemplateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTemplateMessageMutation(baseOptions?: Apollo.MutationHookOptions<EditTemplateMessageMutation, EditTemplateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTemplateMessageMutation, EditTemplateMessageMutationVariables>(EditTemplateMessageDocument, options);
      }
export type EditTemplateMessageMutationHookResult = ReturnType<typeof useEditTemplateMessageMutation>;
export type EditTemplateMessageMutationResult = Apollo.MutationResult<EditTemplateMessageMutation>;
export type EditTemplateMessageMutationOptions = Apollo.BaseMutationOptions<EditTemplateMessageMutation, EditTemplateMessageMutationVariables>;
export const ImportTemplateMessagesDocument = gql`
    mutation importTemplateMessages($input: ImportTemplateMessagesInput!) {
  importTemplateMessages(input: $input) {
    templateMessageId
    templateMessageCategoryId
    templateMessageCategoryName
    languages {
      language
      message
    }
    errorCode
  }
}
    `;
export type ImportTemplateMessagesMutationFn = Apollo.MutationFunction<ImportTemplateMessagesMutation, ImportTemplateMessagesMutationVariables>;

/**
 * __useImportTemplateMessagesMutation__
 *
 * To run a mutation, you first call `useImportTemplateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTemplateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTemplateMessagesMutation, { data, loading, error }] = useImportTemplateMessagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportTemplateMessagesMutation(baseOptions?: Apollo.MutationHookOptions<ImportTemplateMessagesMutation, ImportTemplateMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportTemplateMessagesMutation, ImportTemplateMessagesMutationVariables>(ImportTemplateMessagesDocument, options);
      }
export type ImportTemplateMessagesMutationHookResult = ReturnType<typeof useImportTemplateMessagesMutation>;
export type ImportTemplateMessagesMutationResult = Apollo.MutationResult<ImportTemplateMessagesMutation>;
export type ImportTemplateMessagesMutationOptions = Apollo.BaseMutationOptions<ImportTemplateMessagesMutation, ImportTemplateMessagesMutationVariables>;
export const RemoveTemplateMessagesDocument = gql`
    mutation removeTemplateMessages($input: RemoveTemplateMessagesInput!) {
  removeTemplateMessages(input: $input)
}
    `;
export type RemoveTemplateMessagesMutationFn = Apollo.MutationFunction<RemoveTemplateMessagesMutation, RemoveTemplateMessagesMutationVariables>;

/**
 * __useRemoveTemplateMessagesMutation__
 *
 * To run a mutation, you first call `useRemoveTemplateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTemplateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTemplateMessagesMutation, { data, loading, error }] = useRemoveTemplateMessagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTemplateMessagesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTemplateMessagesMutation, RemoveTemplateMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTemplateMessagesMutation, RemoveTemplateMessagesMutationVariables>(RemoveTemplateMessagesDocument, options);
      }
export type RemoveTemplateMessagesMutationHookResult = ReturnType<typeof useRemoveTemplateMessagesMutation>;
export type RemoveTemplateMessagesMutationResult = Apollo.MutationResult<RemoveTemplateMessagesMutation>;
export type RemoveTemplateMessagesMutationOptions = Apollo.BaseMutationOptions<RemoveTemplateMessagesMutation, RemoveTemplateMessagesMutationVariables>;
export const TemplateMessageCategoriesDocument = gql`
    query templateMessageCategories($input: TemplateMessageCategoryInput!) {
  templateMessageCategories(input: $input) {
    id
    name
    templateMessagesCount
  }
}
    `;

/**
 * __useTemplateMessageCategoriesQuery__
 *
 * To run a query within a React component, call `useTemplateMessageCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateMessageCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateMessageCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateMessageCategoriesQuery(baseOptions: Apollo.QueryHookOptions<TemplateMessageCategoriesQuery, TemplateMessageCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateMessageCategoriesQuery, TemplateMessageCategoriesQueryVariables>(TemplateMessageCategoriesDocument, options);
      }
export function useTemplateMessageCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateMessageCategoriesQuery, TemplateMessageCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateMessageCategoriesQuery, TemplateMessageCategoriesQueryVariables>(TemplateMessageCategoriesDocument, options);
        }
export type TemplateMessageCategoriesQueryHookResult = ReturnType<typeof useTemplateMessageCategoriesQuery>;
export type TemplateMessageCategoriesLazyQueryHookResult = ReturnType<typeof useTemplateMessageCategoriesLazyQuery>;
export type TemplateMessageCategoriesQueryResult = Apollo.QueryResult<TemplateMessageCategoriesQuery, TemplateMessageCategoriesQueryVariables>;
export const AddTemplateMessageCategoryDocument = gql`
    mutation addTemplateMessageCategory($input: AddTemplateMessageCategoryInput!) {
  addTemplateMessageCategory(input: $input) {
    id
    name
  }
}
    `;
export type AddTemplateMessageCategoryMutationFn = Apollo.MutationFunction<AddTemplateMessageCategoryMutation, AddTemplateMessageCategoryMutationVariables>;

/**
 * __useAddTemplateMessageCategoryMutation__
 *
 * To run a mutation, you first call `useAddTemplateMessageCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMessageCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMessageCategoryMutation, { data, loading, error }] = useAddTemplateMessageCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateMessageCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMessageCategoryMutation, AddTemplateMessageCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateMessageCategoryMutation, AddTemplateMessageCategoryMutationVariables>(AddTemplateMessageCategoryDocument, options);
      }
export type AddTemplateMessageCategoryMutationHookResult = ReturnType<typeof useAddTemplateMessageCategoryMutation>;
export type AddTemplateMessageCategoryMutationResult = Apollo.MutationResult<AddTemplateMessageCategoryMutation>;
export type AddTemplateMessageCategoryMutationOptions = Apollo.BaseMutationOptions<AddTemplateMessageCategoryMutation, AddTemplateMessageCategoryMutationVariables>;
export const EditTemplateMessageCategoryDocument = gql`
    mutation editTemplateMessageCategory($input: EditTemplateMessageCategoryInput!) {
  editTemplateMessageCategory(input: $input) {
    id
    name
  }
}
    `;
export type EditTemplateMessageCategoryMutationFn = Apollo.MutationFunction<EditTemplateMessageCategoryMutation, EditTemplateMessageCategoryMutationVariables>;

/**
 * __useEditTemplateMessageCategoryMutation__
 *
 * To run a mutation, you first call `useEditTemplateMessageCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMessageCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMessageCategoryMutation, { data, loading, error }] = useEditTemplateMessageCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTemplateMessageCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditTemplateMessageCategoryMutation, EditTemplateMessageCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTemplateMessageCategoryMutation, EditTemplateMessageCategoryMutationVariables>(EditTemplateMessageCategoryDocument, options);
      }
export type EditTemplateMessageCategoryMutationHookResult = ReturnType<typeof useEditTemplateMessageCategoryMutation>;
export type EditTemplateMessageCategoryMutationResult = Apollo.MutationResult<EditTemplateMessageCategoryMutation>;
export type EditTemplateMessageCategoryMutationOptions = Apollo.BaseMutationOptions<EditTemplateMessageCategoryMutation, EditTemplateMessageCategoryMutationVariables>;
export const RemoveTemplateMessageCategoriesDocument = gql`
    mutation removeTemplateMessageCategories($input: RemoveTemplateMessageCategoriesInput!) {
  removeTemplateMessageCategories(input: $input)
}
    `;
export type RemoveTemplateMessageCategoriesMutationFn = Apollo.MutationFunction<RemoveTemplateMessageCategoriesMutation, RemoveTemplateMessageCategoriesMutationVariables>;

/**
 * __useRemoveTemplateMessageCategoriesMutation__
 *
 * To run a mutation, you first call `useRemoveTemplateMessageCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTemplateMessageCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTemplateMessageCategoriesMutation, { data, loading, error }] = useRemoveTemplateMessageCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTemplateMessageCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTemplateMessageCategoriesMutation, RemoveTemplateMessageCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTemplateMessageCategoriesMutation, RemoveTemplateMessageCategoriesMutationVariables>(RemoveTemplateMessageCategoriesDocument, options);
      }
export type RemoveTemplateMessageCategoriesMutationHookResult = ReturnType<typeof useRemoveTemplateMessageCategoriesMutation>;
export type RemoveTemplateMessageCategoriesMutationResult = Apollo.MutationResult<RemoveTemplateMessageCategoriesMutation>;
export type RemoveTemplateMessageCategoriesMutationOptions = Apollo.BaseMutationOptions<RemoveTemplateMessageCategoriesMutation, RemoveTemplateMessageCategoriesMutationVariables>;
export const TranslateMessageDocument = gql`
    query translateMessage($input: TranslateMessageInput!) {
  translateMessage(input: $input) {
    message
    language
    reverseMessage
  }
}
    `;

/**
 * __useTranslateMessageQuery__
 *
 * To run a query within a React component, call `useTranslateMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateMessageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTranslateMessageQuery(baseOptions: Apollo.QueryHookOptions<TranslateMessageQuery, TranslateMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateMessageQuery, TranslateMessageQueryVariables>(TranslateMessageDocument, options);
      }
export function useTranslateMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateMessageQuery, TranslateMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateMessageQuery, TranslateMessageQueryVariables>(TranslateMessageDocument, options);
        }
export type TranslateMessageQueryHookResult = ReturnType<typeof useTranslateMessageQuery>;
export type TranslateMessageLazyQueryHookResult = ReturnType<typeof useTranslateMessageLazyQuery>;
export type TranslateMessageQueryResult = Apollo.QueryResult<TranslateMessageQuery, TranslateMessageQueryVariables>;
export const ChatRoomsTemplateMessagesDocument = gql`
    query chatRoomsTemplateMessages($input: ChatRoomsTemplateMessageInput!) {
  chatRoomsTemplateMessages(input: $input) {
    templateMessage {
      id
      category {
        id
        name
        order
      }
      languages {
        language
        message
        readonly
      }
      files {
        id
        fileName
        fileType
      }
      order
      createdAt
    }
    lastSentTime
  }
}
    `;

/**
 * __useChatRoomsTemplateMessagesQuery__
 *
 * To run a query within a React component, call `useChatRoomsTemplateMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomsTemplateMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomsTemplateMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatRoomsTemplateMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatRoomsTemplateMessagesQuery, ChatRoomsTemplateMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatRoomsTemplateMessagesQuery, ChatRoomsTemplateMessagesQueryVariables>(ChatRoomsTemplateMessagesDocument, options);
      }
export function useChatRoomsTemplateMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatRoomsTemplateMessagesQuery, ChatRoomsTemplateMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatRoomsTemplateMessagesQuery, ChatRoomsTemplateMessagesQueryVariables>(ChatRoomsTemplateMessagesDocument, options);
        }
export type ChatRoomsTemplateMessagesQueryHookResult = ReturnType<typeof useChatRoomsTemplateMessagesQuery>;
export type ChatRoomsTemplateMessagesLazyQueryHookResult = ReturnType<typeof useChatRoomsTemplateMessagesLazyQuery>;
export type ChatRoomsTemplateMessagesQueryResult = Apollo.QueryResult<ChatRoomsTemplateMessagesQuery, ChatRoomsTemplateMessagesQueryVariables>;
export const TenantCompaniesDocument = gql`
    query tenantCompanies($input: TenantCompaniesInput!) {
  tenantCompanies(input: $input) {
    id
    ownerCompanyName
    userName
    guideManagerName
    roomLimit
    roomCreationLimit
    isEnableVoice
    vadPreset {
      id
      label
    }
    note
  }
}
    `;

/**
 * __useTenantCompaniesQuery__
 *
 * To run a query within a React component, call `useTenantCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTenantCompaniesQuery(baseOptions: Apollo.QueryHookOptions<TenantCompaniesQuery, TenantCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantCompaniesQuery, TenantCompaniesQueryVariables>(TenantCompaniesDocument, options);
      }
export function useTenantCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantCompaniesQuery, TenantCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantCompaniesQuery, TenantCompaniesQueryVariables>(TenantCompaniesDocument, options);
        }
export type TenantCompaniesQueryHookResult = ReturnType<typeof useTenantCompaniesQuery>;
export type TenantCompaniesLazyQueryHookResult = ReturnType<typeof useTenantCompaniesLazyQuery>;
export type TenantCompaniesQueryResult = Apollo.QueryResult<TenantCompaniesQuery, TenantCompaniesQueryVariables>;
export const AddTenantCompanyDocument = gql`
    mutation addTenantCompany($input: AddTenantCompanyInput!) {
  addTenantCompany(input: $input) {
    __typename
    ... on TenantCompany {
      id
      ownerCompanyName
      userName
      guideManagerName
      roomLimit
      roomCreationLimit
      isEnableVoice
      vadPreset {
        id
        label
      }
      note
    }
    ... on UserNameAlreadyExists {
      message
    }
    ... on ExcessTenantCompanyNumberLimit {
      message
    }
    ... on ExcessTenantCompanyRoomNumberLimit {
      message
      limit
    }
  }
}
    `;
export type AddTenantCompanyMutationFn = Apollo.MutationFunction<AddTenantCompanyMutation, AddTenantCompanyMutationVariables>;

/**
 * __useAddTenantCompanyMutation__
 *
 * To run a mutation, you first call `useAddTenantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTenantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTenantCompanyMutation, { data, loading, error }] = useAddTenantCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTenantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddTenantCompanyMutation, AddTenantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTenantCompanyMutation, AddTenantCompanyMutationVariables>(AddTenantCompanyDocument, options);
      }
export type AddTenantCompanyMutationHookResult = ReturnType<typeof useAddTenantCompanyMutation>;
export type AddTenantCompanyMutationResult = Apollo.MutationResult<AddTenantCompanyMutation>;
export type AddTenantCompanyMutationOptions = Apollo.BaseMutationOptions<AddTenantCompanyMutation, AddTenantCompanyMutationVariables>;
export const EditTenantCompanyDocument = gql`
    mutation editTenantCompany($input: EditTenantCompanyInput!) {
  editTenantCompany(input: $input) {
    __typename
    ... on TenantCompany {
      id
      ownerCompanyName
      userName
      guideManagerName
      roomLimit
      roomCreationLimit
      isEnableVoice
      vadPreset {
        id
        label
      }
      note
    }
    ... on UserNameAlreadyExists {
      message
    }
    ... on ExcessTenantCompanyRoomNumberLimit {
      message
      limit
    }
  }
}
    `;
export type EditTenantCompanyMutationFn = Apollo.MutationFunction<EditTenantCompanyMutation, EditTenantCompanyMutationVariables>;

/**
 * __useEditTenantCompanyMutation__
 *
 * To run a mutation, you first call `useEditTenantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTenantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTenantCompanyMutation, { data, loading, error }] = useEditTenantCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTenantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditTenantCompanyMutation, EditTenantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTenantCompanyMutation, EditTenantCompanyMutationVariables>(EditTenantCompanyDocument, options);
      }
export type EditTenantCompanyMutationHookResult = ReturnType<typeof useEditTenantCompanyMutation>;
export type EditTenantCompanyMutationResult = Apollo.MutationResult<EditTenantCompanyMutation>;
export type EditTenantCompanyMutationOptions = Apollo.BaseMutationOptions<EditTenantCompanyMutation, EditTenantCompanyMutationVariables>;
export const RemoveTenantCompanyDocument = gql`
    mutation removeTenantCompany($input: RemoveTenantCompanyInput!) {
  removeTenantCompany(input: $input) {
    id
    ownerCompanyName
    userName
    guideManagerName
  }
}
    `;
export type RemoveTenantCompanyMutationFn = Apollo.MutationFunction<RemoveTenantCompanyMutation, RemoveTenantCompanyMutationVariables>;

/**
 * __useRemoveTenantCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveTenantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTenantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTenantCompanyMutation, { data, loading, error }] = useRemoveTenantCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTenantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTenantCompanyMutation, RemoveTenantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTenantCompanyMutation, RemoveTenantCompanyMutationVariables>(RemoveTenantCompanyDocument, options);
      }
export type RemoveTenantCompanyMutationHookResult = ReturnType<typeof useRemoveTenantCompanyMutation>;
export type RemoveTenantCompanyMutationResult = Apollo.MutationResult<RemoveTenantCompanyMutation>;
export type RemoveTenantCompanyMutationOptions = Apollo.BaseMutationOptions<RemoveTenantCompanyMutation, RemoveTenantCompanyMutationVariables>;
export const VadPresetsDocument = gql`
    query vadPresets($input: VadPresetsInput!) {
  vadPresets(input: $input) {
    id
    label
    smoothingTimeConstant
    energyOffset
    energyThresholdRatioPos
    energyThresholdRatioNeg
    energyIntegration
    default
  }
}
    `;

/**
 * __useVadPresetsQuery__
 *
 * To run a query within a React component, call `useVadPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVadPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVadPresetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVadPresetsQuery(baseOptions: Apollo.QueryHookOptions<VadPresetsQuery, VadPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VadPresetsQuery, VadPresetsQueryVariables>(VadPresetsDocument, options);
      }
export function useVadPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VadPresetsQuery, VadPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VadPresetsQuery, VadPresetsQueryVariables>(VadPresetsDocument, options);
        }
export type VadPresetsQueryHookResult = ReturnType<typeof useVadPresetsQuery>;
export type VadPresetsLazyQueryHookResult = ReturnType<typeof useVadPresetsLazyQuery>;
export type VadPresetsQueryResult = Apollo.QueryResult<VadPresetsQuery, VadPresetsQueryVariables>;
export const AddVadPresetDocument = gql`
    mutation addVadPreset($input: AddVadPresetInput!) {
  addVadPreset(input: $input) {
    id
    label
    smoothingTimeConstant
    energyOffset
    energyThresholdRatioPos
    energyThresholdRatioNeg
    energyIntegration
  }
}
    `;
export type AddVadPresetMutationFn = Apollo.MutationFunction<AddVadPresetMutation, AddVadPresetMutationVariables>;

/**
 * __useAddVadPresetMutation__
 *
 * To run a mutation, you first call `useAddVadPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVadPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVadPresetMutation, { data, loading, error }] = useAddVadPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVadPresetMutation(baseOptions?: Apollo.MutationHookOptions<AddVadPresetMutation, AddVadPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVadPresetMutation, AddVadPresetMutationVariables>(AddVadPresetDocument, options);
      }
export type AddVadPresetMutationHookResult = ReturnType<typeof useAddVadPresetMutation>;
export type AddVadPresetMutationResult = Apollo.MutationResult<AddVadPresetMutation>;
export type AddVadPresetMutationOptions = Apollo.BaseMutationOptions<AddVadPresetMutation, AddVadPresetMutationVariables>;
export const EditVadPresetDocument = gql`
    mutation editVadPreset($input: EditVadPresetInput!) {
  editVadPreset(input: $input) {
    id
    label
    smoothingTimeConstant
    energyOffset
    energyThresholdRatioPos
    energyThresholdRatioNeg
    energyIntegration
  }
}
    `;
export type EditVadPresetMutationFn = Apollo.MutationFunction<EditVadPresetMutation, EditVadPresetMutationVariables>;

/**
 * __useEditVadPresetMutation__
 *
 * To run a mutation, you first call `useEditVadPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVadPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVadPresetMutation, { data, loading, error }] = useEditVadPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVadPresetMutation(baseOptions?: Apollo.MutationHookOptions<EditVadPresetMutation, EditVadPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditVadPresetMutation, EditVadPresetMutationVariables>(EditVadPresetDocument, options);
      }
export type EditVadPresetMutationHookResult = ReturnType<typeof useEditVadPresetMutation>;
export type EditVadPresetMutationResult = Apollo.MutationResult<EditVadPresetMutation>;
export type EditVadPresetMutationOptions = Apollo.BaseMutationOptions<EditVadPresetMutation, EditVadPresetMutationVariables>;
export const RemoveVadPresetDocument = gql`
    mutation removeVadPreset($input: RemoveVadPresetInput!) {
  removeVadPreset(input: $input) {
    id
    label
    smoothingTimeConstant
    energyOffset
    energyThresholdRatioPos
    energyThresholdRatioNeg
    energyIntegration
  }
}
    `;
export type RemoveVadPresetMutationFn = Apollo.MutationFunction<RemoveVadPresetMutation, RemoveVadPresetMutationVariables>;

/**
 * __useRemoveVadPresetMutation__
 *
 * To run a mutation, you first call `useRemoveVadPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVadPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVadPresetMutation, { data, loading, error }] = useRemoveVadPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVadPresetMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVadPresetMutation, RemoveVadPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVadPresetMutation, RemoveVadPresetMutationVariables>(RemoveVadPresetDocument, options);
      }
export type RemoveVadPresetMutationHookResult = ReturnType<typeof useRemoveVadPresetMutation>;
export type RemoveVadPresetMutationResult = Apollo.MutationResult<RemoveVadPresetMutation>;
export type RemoveVadPresetMutationOptions = Apollo.BaseMutationOptions<RemoveVadPresetMutation, RemoveVadPresetMutationVariables>;