import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { memo, useCallback } from 'react';

import DraggableGridRow from './DraggableDataGridRow';

import MyDataGrid from '@/components/atoms/MyDataGrid';

// eslint-disable-next-line react/display-name
const DraggableDataGrid = memo(
  (
    props: DataGridProProps & {
      onChange: (values: any) => void;
    }
  ) => {
    // ドラッグ可能なセンサーを設定
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: { distance: 5 },
      })
    );

    // ドラッグ終了時の処理
    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const { active, over } = event;
        if (over) {
          const oldIndex = props.rows.findIndex(
            (rows) => rows.id === active.id
          );
          const newIndex = props.rows.findIndex((rows) => rows.id === over.id);
          props.onChange(arrayMove(props.rows as any, oldIndex, newIndex));
        }
      },
      [props]
    );

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[
          restrictToVerticalAxis,
          restrictToWindowEdges,
          restrictToParentElement,
        ]}
      >
        <SortableContext
          items={props.rows.map((row) => row.id)}
          strategy={verticalListSortingStrategy}
        >
          <MyDataGrid {...props} slots={{ row: DraggableGridRow }} />
        </SortableContext>
      </DndContext>
    );
  }
);

export default DraggableDataGrid;
