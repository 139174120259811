import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components/organisms/ErrorFallback';

const ErrorBoundaryProvider = (props: PropsWithChildren) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <AsyncErrorHandlerProvider>{props.children}</AsyncErrorHandlerProvider>
  </ErrorBoundary>
);

const AsyncErrorHandlerProvider = (props: PropsWithChildren) => {
  const { showBoundary } = useErrorBoundary();

  // 非同期のエラーはここでキャッチする
  useEffect(() => {
    const handler = (ev: PromiseRejectionEvent) => {
      showBoundary(ev);
    };
    window.addEventListener('unhandledrejection', handler);

    return () => window.removeEventListener('unhandledrejection', handler);
  }, [showBoundary]);

  return <>{props.children}</>;
};

export default ErrorBoundaryProvider;
