import { assertNotNull } from '@remote-voice/utilities';
import { openDB, IDBPDatabase, DBSchema } from 'idb';

import { ChatMessage } from '@/types/graphql';

export type ChatMessageItem = ChatMessage & {
  error?: boolean;
};

export interface ChatMessageConfig {
  lastUpdatedAt: number;
  lastSequence: number;
}

export const chatMessageConfigDefault: ChatMessageConfig = {
  lastUpdatedAt: 0,
  lastSequence: 0,
};

interface ChatMessageDBSchema extends DBSchema {
  // chatMessageテーブル
  chatMessage: {
    key: number;
    value: ChatMessage;
  };
  // configテーブル。1レコードに構造を詰める
  config: {
    key: 'config';
    value: ChatMessageConfig;
  };
}

// メッセージのキャッシュをIndexedDBに保存したりする処理をカプセル化
class ChatMessageCache {
  private db: IDBPDatabase<ChatMessageDBSchema> | undefined;

  private constructor() {
    // do nothing
  }

  public static async create(cacheId: string): Promise<ChatMessageCache> {
    const instance = new ChatMessageCache();
    instance.db = await openDB<ChatMessageDBSchema>('RV_CACHE_' + cacheId, 1, {
      upgrade(db) {
        db.createObjectStore('chatMessage');
        db.createObjectStore('config');
      },
    });
    return instance;
  }

  public async deleteChatMessage(sequence: number): Promise<void> {
    assertNotNull(this.db);
    await this.db.delete('chatMessage', sequence);
  }
  public async deleteChatMessages(
    firstSequence: number,
    lastSequence: number
  ): Promise<void> {
    assertNotNull(this.db);
    const query = IDBKeyRange.bound(firstSequence, lastSequence);
    await this.db.delete('chatMessage', query);
  }

  public async getConfig(): Promise<ChatMessageConfig> {
    assertNotNull(this.db);
    return (await this.db.get('config', 'config')) ?? chatMessageConfigDefault;
  }

  public async setConfig(config: ChatMessageConfig): Promise<void> {
    assertNotNull(this.db);
    await this.db.put('config', config, 'config');
  }

  public async getAllChatMessages(): Promise<ChatMessage[]> {
    assertNotNull(this.db);
    return await this.db.getAll('chatMessage');
  }

  public async putChatMessage(item: ChatMessage): Promise<void> {
    assertNotNull(this.db);
    await this.db.put('chatMessage', item, item.sequence);
  }
}
export default ChatMessageCache;
