import { useCallback, useEffect } from 'react';

export const useKeyupListener = (
  keycodes: string[], // 反応するキーコード
  onKeyup: () => void
) => {
  const keyupHandler = useCallback(
    (ev: KeyboardEvent) => {
      // 対象のキーが入力されたらコールバック呼び出し
      if (keycodes.some((code) => code === ev.code)) {
        onKeyup();
      }
    },
    [onKeyup, keycodes]
  );

  useEffect(() => {
    addEventListener('keyup', keyupHandler);
    return () => removeEventListener('keyup', keyupHandler);
  }, [keyupHandler]);
};
