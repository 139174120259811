import { Box, Typography } from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities/lib/guard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import DetailView from '@/components/molecules/admin/DetailView';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import {
  useRemoveTenantCompanyMutation,
  useTenantCompaniesQuery,
} from '@/types/graphql';

const TenantDetail = () => {
  const permission = useCheckPermission({
    superAdmin: true,
    ownerCompanyAdmin: true,
  });

  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.tenantAdmin'));

  const params = useParams();
  const tenantId = params.tenantId;

  const tenantCompanyResult = useTenantCompaniesQuery({
    variables: { input: { id: tenantId } },
  });

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [removeTenantCompany] = useRemoveTenantCompanyMutation();
  const confirmDeleteDialog = useConfirmDeleteDialog();

  const data = tenantCompanyResult.data?.tenantCompanies[0];

  const companyData = [
    { name: t('company.companyName'), data: data?.ownerCompanyName ?? '' },
    { name: t('company.userName'), data: data?.guideManagerName ?? '' },
    { name: t('company.email'), data: data?.userName ?? '' },
    { name: t('company.roomLimit'), data: data?.roomLimit ?? '' },
    {
      name: t('company.roomCreationLimit'),
      data: data?.roomCreationLimit ?? '',
    },
    {
      name: t('company.voiceDefault'),
      data: data?.isEnableVoice ? 'ON' : 'OFF',
    },
    {
      name: t('company.vadPreset'),
      data: data?.vadPreset?.label ?? 'デフォルト',
    },
  ];

  return (
    <Box>
      <LoadingBackdrop open={tenantCompanyResult.loading} />
      <BreadcrumbBar keyFrom="/admin/tenant" />
      <AdminContent>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {data?.guideManagerName}
        </Typography>

        <DetailView
          companyData={companyData}
          title={t('company.userInfo')}
          readonly={!permission.isOwnerCompanyAdmin}
          onEdit={() => navigate('edit')}
          onDelete={async () => {
            // 確認ダイアログ表示
            if (
              (await confirmDeleteDialog.open({
                title: t('common.removeMessage', {
                  name: data?.guideManagerName,
                }),
              })) === false
            ) {
              return; // キャンセル
            }
            assertNotNull(data?.id);
            await removeTenantCompany({
              variables: { input: { id: data?.id } },
            });

            navigate('/admin/tenant');
            snackbar('success', '削除しました');
          }}
        />
      </AdminContent>
    </Box>
  );
};
export default TenantDetail;
