import { joiResolver } from '@hookform/resolvers/joi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useFontSize, useSetFontSize } from '@/components/hooks/useFontSize';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import useLanguage, { useSetLanguage } from '@/components/hooks/useLanguage';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import FontSizeSwitcher from '@/components/molecules/FontSizeSwitcher';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher';
import {
  useEditUserSettingsMutation,
  useUserSettingsQuery,
} from '@/types/graphql';

type UserSettingsData = {
  name: string;
};

const UserIndex = () => {
  useCheckPermission({
    tenantCompanyAdmin: true,
    ownerCompanyAdmin: true,
    superAdmin: true,
  });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  useSetHeaderTitle(t('userSettings.userSettings'));

  const userSettings = useUserSettingsQuery();
  const [editUserSettings, editUserSettingsResult] =
    useEditUserSettingsMutation();

  const fontSize = useFontSize();
  const setFontSize = useSetFontSize();
  const [fontSizeValue, setFontSizeValue] = useState(`${fontSize}`);
  useEffect(() => {
    setFontSizeValue(`${fontSize}`);
  }, [setFontSize, fontSize]);

  const language = useLanguage();
  const setLanguage = useSetLanguage();
  const [languageValue, setLanguageValue] = useState(language);

  const joi = useLocalizedJoi();
  const form = useForm<UserSettingsData>({
    resolver: joiResolver(
      joi.object<UserSettingsData>({
        name: joi.string().trim().max(50).required(),
      })
    ),
  });

  useEffect(() => {
    console.debug(userSettings.data?.userSettings.name);
    if (userSettings.data?.userSettings != null) {
      form.reset({ name: userSettings.data.userSettings.name });
    }
  }, [form, userSettings.data?.userSettings]);

  return (
    <AdminContent>
      <LoadingBackdrop
        open={userSettings.loading || editUserSettingsResult.loading}
      />

      <FormControl fullWidth>
        <Stack
          spacing={2}
          component="form"
          onSubmit={form.handleSubmit(async (input, ev) => {
            ev?.preventDefault();
            await editUserSettings({
              variables: {
                input: {
                  name: input.name,
                },
              },
            });
            await userSettings.refetch();
            setLanguage(languageValue);
            setFontSize(Number(fontSizeValue));
            snackbar('success', tCommon('message.successSaveChanges'));
            navigate('/admin/room');
          })}
        >
          <Typography fontWeight="bold">{t('userSettings.title')}</Typography>

          <TextField
            {...form.register('name')}
            label={t('userSettings.name')}
            error={'name' in form.formState.errors}
            helperText={form.formState.errors.name?.message}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />

          <LanguageSwitcher
            label={tCommon('caption.language')}
            language={languageValue}
            setLanguage={setLanguageValue}
          />

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="body2" width={80}>
              {tCommon('caption.password')}
            </Typography>
            <Typography variant="body1" sx={{ flex: 1 }}>
              ●●●●●●●●
            </Typography>
            <Button
              variant="text"
              sx={{ minWidth: 0 }}
              onClick={() => navigate('password')}
              endIcon={<NavigateNextIcon />}
            >
              {tCommon('command.change')}
            </Button>
          </Stack>

          <Divider />

          <Stack pt={2}>
            <Typography fontWeight="bold">{t('fontSettings.title')}</Typography>
            <Typography variant="body2">
              {t('fontSettings.description')}
            </Typography>
          </Stack>

          <FontSizeSwitcher value={fontSizeValue} setValue={setFontSizeValue} />

          <Stack pt={2} direction="row" spacing={1} justifyContent="center">
            <CancelButton onClick={() => navigate('/admin/room')} />
            <Button variant="contained" type="submit">
              {tCommon('command.saveChanges')}
            </Button>
          </Stack>
        </Stack>
      </FormControl>
    </AdminContent>
  );
};
export default UserIndex;
