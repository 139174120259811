import { Button, ButtonProps } from '@mui/material';

import CancelButton from '@/components/atoms/CancelButton';
import useConfirmDialog from '@/components/organisms/confirmDialog/useConfirmDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const useConfirmContinueDialog = () => {
  const confirmDialog = useConfirmDialog(false);
  return {
    open: (props: {
      message?: string;
      title: string;
      okLabel?: string;
      okColor?: ButtonProps['color'];
    }) =>
      confirmDialog.open(
        {
          title: props.title,
          message: props.message,
          actions: [
            <CancelButton
              key="cancal"
              onClick={() => confirmDialog.close(false)}
            />,
            <Button
              key="ok"
              onClick={() => confirmDialog.close(true)}
              color={props.okColor ?? 'primary'}
            >
              {props.okLabel ?? 'OK'}
            </Button>,
          ],
        },
        (c) => <ChatThemeProvider>{c}</ChatThemeProvider>
      ),
  };
};
export default useConfirmContinueDialog;
