// Chat.tsxのロジックを実装

import { assertNotNull, escapeRegex } from '@remote-voice/utilities';
import axios from 'axios';
import Cookies from 'js-cookie';
import Enumerable from 'linq';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useLanguage from '@/components/hooks/useLanguage';
import { AudioDeviceEventCallbacks } from '@/components/hooks/useMiniRecognition';
import { useSetOpenNetworkAlert } from '@/components/hooks/useOpenNetworkAlert';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { useUserName } from '@/components/hooks/useUserName';
import useChatMessages from '@/components/organisms/chat/useChatMessages';
import useChatMini from '@/components/organisms/chat/useChatMini';
import useChatPlayer from '@/components/organisms/chat/useChatPlayer';
import useChatUserCookieInfo from '@/components/organisms/chat/useChatUserCookieInfo';
import useChatUsers from '@/components/organisms/chat/useChatUsers';
import {
  ChatUserRole,
  useChatCommandExecutedSubscription,
  useClearChatSessionMutation,
  useEndChatSessionMutation,
  useMuteUserMicMutation,
  useRemoveChatMessageMutation,
  useSetChatReactionMutation,
  useRemoveChatSessionUserMutation,
  ChatReactionType,
  useAddChatTemplateMessageMutation,
  useAddFilesMutation,
  useChatRoomsTemplateMessagesQuery,
  useNgWordsQuery,
  useSetChatMessageFlagMutation,
  TtsService,
  useSetChatSessionQuestionModeMutation,
} from '@/types/graphql';
import SystemCommand from '@/types/SystemCommand';
import { isChatGuest, isChatHost } from '@/utils/userRole';

const useChat = (props: {
  ttsService?: TtsService;
  audioDeviceEventCallbacks?: AudioDeviceEventCallbacks;
}) => {
  const { t } = useTranslation('chat');

  // 各種パラメータをクッキーなどから取得
  const params = useParams();
  const showSnackbar = useSnackbar();
  const chatRoomId = params.chatRoomId ?? '';
  const chatSessionId = params.chatSessionId ?? '';
  const sessionEntryCode =
    Cookies.get('RV_SESSION_PASS_' + chatSessionId) ?? '';
  const { userId, userPassword } = useChatUserCookieInfo();
  const userName = useUserName();
  const language = useLanguage();

  const [isQuestionMode, setIsQuestionMode] = useState(false);

  const navigate = useNavigate();
  const [reverseLanguage, setReverseLanguage] = useState<string | undefined>();

  const [previousMessageLoaded, setPreviousMessageLoaded] = useState(false);

  // 入力欄のテキスト
  const [chatText, setChatText] = useState('');
  const [isMicMode, setIsMicMode] = useState(true);
  const [isTemplateMode, setIsTemplateMode] = useState(false);

  const [playingMessageId, setPlayingMessageId] = useState<
    string | undefined
  >();
  const playingMessageCounter = useRef(0);

  // 各種ミューテーション
  const [removeChatMessage] = useRemoveChatMessageMutation();
  const [setChatReaction] = useSetChatReactionMutation();
  const [clearChatSession] = useClearChatSessionMutation();
  const [setChatSessionQuestionMode] = useSetChatSessionQuestionModeMutation();
  const [muteUserMic] = useMuteUserMicMutation();
  const [removeChatSessionUser] = useRemoveChatSessionUserMutation();
  const [endChatSession] = useEndChatSessionMutation();
  const [addChatTemplateMessage, addChatTemplateMessageResult] =
    useAddChatTemplateMessageMutation();
  const [addFiles] = useAddFilesMutation();
  const [setChatMessageFlag] = useSetChatMessageFlagMutation();

  // チャットに参加しているユーザーのリスト
  const { users, loading: usersLoading } = useChatUsers({
    variables: {
      chatRoomId,
      sessionEntryCode,
      userId,
      userPassword,
      userName,
      language,
    },
    onMaxUser: () => {
      showSnackbar('error', t('wating.maxUsers'));
      redirectToTop();
      return;
    },
  });
  const joiningUsers = users.filter(
    (x) => x.isJoining && x.isWaiting === false
  );

  // 参加リストから自分の権限を判定
  const chatUserRole = users.find((x) => x.userId === userId)?.userRole;

  // チャットのメッセージリスト
  const {
    messages,
    upsertMessage,
    loadPreviousMessages,
    getPreviousMessage,
    getNextMessage,
    loading,
    sessionInfo,
    isInitialized,
  } = useChatMessages({
    chatRoomId: chatRoomId,
    chatSessionId: chatSessionId,
    sessionEntryCode: sessionEntryCode,
    userId: userId,
    userPassword: userPassword,
    language: language,
    reverseLanguage: reverseLanguage,
    chatUserRole: chatUserRole,
  });

  const setOpenNetworkAlert = useSetOpenNetworkAlert();

  // NGワードリスト
  const ngWords = useNgWordsQuery({
    variables: {
      input: {
        chatUser: {
          chatRoomId,
          sessionEntryCode,
          userId,
          userPassword,
        },
      },
    },
  });

  const ngWordRegExp = useMemo(
    () =>
      new RegExp(
        `(${
          ngWords.data?.ngWords
            .map((nw) => escapeRegex(nw.word.toLocaleLowerCase()))
            .flat()
            .join('|') ?? ''
        })`,
        'gi'
      ),
    [ngWords.data?.ngWords]
  );

  // NGワード削除
  const replaceNgWord = useMemo(
    () => (src: string, replaceWith?: string) => {
      // NGワードが未取得であれば空文字を返す
      if (ngWords.data?.ngWords == null) return '';
      else {
        if (ngWords.data.ngWords.length === 0) return src;
        else return src.replace(ngWordRegExp, replaceWith ?? '****');
      }
    },
    [ngWordRegExp, ngWords.data?.ngWords]
  );

  // 返信のための情報
  const [replyInfo, setReplyInfo] = useState<
    { id: string; userId: string; text: string } | undefined
  >();
  const replyMessageId = useRef<string>();
  const replyRowText = replyInfo?.text;
  const replyText = useMemo(
    () => (replyRowText != null ? replaceNgWord(replyRowText) : undefined),
    [replaceNgWord, replyRowText]
  );
  const replyUserId = replyInfo?.userId;
  const replyUser = useMemo(
    () => users.find((x) => x.userId === replyUserId),
    [users, replyUserId]
  );

  // 音声系エンジン
  const mini = useChatMini({
    userId,
    chatRoomId,
    sessionEntryCode,
    segmentType: 'chunkAndSentence', // とりあえず固定
    sourceLanguage: language,
    targetLanguages: Enumerable.from(users)
      .where((x) => x.isJoining)
      .select((x) => x.language)
      .distinct()
      .where((x) => x !== language)
      .toArray(),
    ttsService: props.ttsService,
    audioDeviceEventCallbacks:
      sessionInfo?.isGuestMicControlLinked ||
      (isChatHost(chatUserRole) && sessionInfo?.isGuideMicControlLinked)
        ? props.audioDeviceEventCallbacks
        : undefined,
    onTextChange: setChatText,
    onUpsertMessage: (params) => {
      const result = upsertMessage({
        ...params,
        replyMessageId: params.replyMessageId ?? replyMessageId.current,
        shouldReplaceHomophone: true,
      });
      setReplyInfo(undefined);
      replyMessageId.current = undefined;
      return result;
    },
    onSendSlowError: () => {
      console.error(t('voiceSendSlow'));
      setOpenNetworkAlert(true);
    },
  });

  const { playMessageEnabled, setPlayMessageEnabled, play, stop } =
    useChatPlayer({
      userId,
      language,
      play: async (
        text: string,
        language: string,
        messageId: string,
        deviceId?: string
      ) => {
        // NGワードの置換
        const replacedText = replaceNgWord(text, '');
        if (replacedText == '') return;

        playingMessageCounter.current++;
        const counter = playingMessageCounter.current;
        try {
          setPlayingMessageId(messageId);
          await mini.play(replacedText, language, deviceId);
        } catch (e: any) {
          console.error(e);
          showSnackbar('error', t('playFailed'));
        }
        if (counter === playingMessageCounter.current) {
          setPlayingMessageId(undefined);
        }
      },
      getNextMessage,
      getPreviousMessage,
    });

  const stopChat = useCallback(() => {
    mini.stop();
    stop();
  }, [mini, stop]);

  // 音声自動再生が指定されていれば開始
  const historyLoaded = messages.length > 0 || previousMessageLoaded; // 以前のメッセージを一度は読み込んでいるか
  useEffect(() => {
    setPlayMessageEnabled(
      isInitialized && sessionInfo?.isEnableVoice === true && historyLoaded
    );
  }, [sessionInfo, isInitialized, setPlayMessageEnabled, historyLoaded]);

  useEffect(() => {
    setIsQuestionMode(sessionInfo?.isQuestionMode ?? false);
  }, [sessionInfo]);

  // チャットコマンド
  useChatCommandExecutedSubscription({
    variables: {
      input: {
        chatRoomId: chatRoomId,
        sessionEntryCode: sessionEntryCode,
      },
    },
    onData: (result) => {
      const commandResult = result.data.data?.chatCommandExecuted;
      assertNotNull(commandResult);
      const command = JSON.parse(commandResult) as SystemCommand;
      // セッションからの離脱要求
      if (command.commandType === 'removeChatSessionUser') {
        if (
          (command.targetUserId == null &&
            command.userId !== userId &&
            isChatGuest(chatUserRole)) ||
          command.targetUserId === userId
        ) {
          navigate('/chat/thanks');
        }
      } else if (command.commandType === 'changeQuestionMode') {
        setIsQuestionMode(command.isQuestionMode);
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  const removeUser = useCallback(
    async (targetUserId?: string) => {
      await removeChatSessionUser({
        variables: {
          input: {
            chatRoomId,
            sessionEntryCode,
            userId,
            userPassword,
            targetUserId,
          },
        },
      });
    },
    [removeChatSessionUser, chatRoomId, sessionEntryCode, userId, userPassword]
  );

  const muteUserMicFunc = useCallback(
    async (targetUserId?: string) => {
      await muteUserMic({
        variables: {
          input: {
            chatRoomId,
            sessionEntryCode,
            userId,
            userPassword,
            targetUserId,
          },
        },
      });
    },
    [muteUserMic, chatRoomId, sessionEntryCode, userId, userPassword]
  );

  const clearMessages = useCallback(async () => {
    await clearChatSession({
      variables: {
        input: {
          chatRoomId,
          sessionEntryCode,
          userId,
          userPassword,
        },
      },
    });
  }, [clearChatSession, chatRoomId, sessionEntryCode, userId, userPassword]);

  const setQuestionMode = useCallback(
    async (isQuestionMode: boolean) => {
      return (
        (
          await setChatSessionQuestionMode({
            variables: {
              input: {
                chatRoomId,
                sessionEntryCode,
                userId,
                userPassword,
                isQuestionMode,
              },
            },
          })
        ).data?.setChatSessionQuestionMode ?? false
      );
    },
    [
      setChatSessionQuestionMode,
      chatRoomId,
      sessionEntryCode,
      userId,
      userPassword,
    ]
  );

  const setReaction = useCallback(
    async (messageId: string, type: ChatReactionType) => {
      const result = await setChatReaction({
        variables: {
          input: {
            userId: userId,
            userPassword: userPassword,
            chatMessageId: messageId,
            reactionType: type,
          },
        },
      });
      assertNotNull(result.data);
    },
    [setChatReaction, userId, userPassword]
  );

  const removeMessage = useCallback(
    async (messageId: string) => {
      await removeChatMessage({
        variables: {
          input: {
            chatMessageId: messageId,
            userId: userId,
            userPassword: userPassword,
          },
        },
      });
    },
    [removeChatMessage, userId, userPassword]
  );

  const endSession = useCallback(async () => {
    await endChatSession({
      variables: {
        input: {
          chatSessionId: chatSessionId,
          sessionEntryCode: sessionEntryCode,
          userId: userId,
          userPassword: userPassword,
        },
      },
    });
  }, [endChatSession, chatSessionId, sessionEntryCode, userId, userPassword]);

  const templateMessages = useChatRoomsTemplateMessagesQuery({
    variables: {
      input: {
        chatRoomId: chatRoomId,
        chatUser: {
          chatUserId: userId,
          chatUserPassword: userPassword,
        },
      },
    },
    skip: isTemplateMode === false,
  });

  const sendTemplate = useCallback(
    async (messageId: string) => {
      await addChatTemplateMessage({
        variables: {
          input: {
            chatRoomId: chatRoomId,
            language: language,
            userId: userId,
            sessionEntryCode: sessionEntryCode,
            templateMessageId: messageId,
            userPassword: userPassword,
          },
        },
      });
      await templateMessages.refetch();
    },
    [
      addChatTemplateMessage,
      chatRoomId,
      language,
      userId,
      sessionEntryCode,
      userPassword,
      templateMessages,
    ]
  );

  const sendImages = useCallback(
    async (files: File[]) => {
      try {
        const addFilesResult = await addFiles({
          variables: {
            input: {
              files: files.map((x) => ({
                fileName: x.name,
                fileType: x.name.split('.')[1].toLowerCase(),
              })),
              chatUser: {
                chatRoomId: chatRoomId,
                sessionEntryCode: sessionEntryCode,
                userId: userId,
                userPassword: userPassword,
              },
            },
          },
        });
        assertNotNull(addFilesResult.data);

        // ファイルアップロード
        await Promise.all(
          addFilesResult.data.addFiles.map((x, i) =>
            axios.put(x.signedURL, files[i], {
              headers: { 'Content-Type': files[i].type },
            })
          )
        );

        await upsertMessage({ files: addFilesResult.data.addFiles });
      } catch (e) {
        console.error(e);
        enqueueSnackbar('ファイルを送信できませんでした', { variant: 'error' });
      }
    },
    [
      chatRoomId,
      sessionEntryCode,
      userId,
      userPassword,
      addFiles,
      upsertMessage,
    ]
  );

  const sendMessage = useCallback(
    (message: string) => {
      upsertMessage({
        languages: [{ message, language: language }],
        replyMessageId: replyInfo?.id,
      });
      setChatText('');
    },
    [upsertMessage, language, replyInfo?.id]
  );

  const setPlayMessageEnabledCallback = useCallback(
    (value: boolean) => {
      if (isInitialized) {
        setPlayMessageEnabled(value);
      }
    },
    [isInitialized, setPlayMessageEnabled]
  );

  // それぞれのロールに応じたトップページへのリダイレクト処理
  const redirectToTop = useCallback(() => {
    if (chatUserRole === ChatUserRole.Guide) {
      navigate(
        `/chat/${chatRoomId}/?chatUserId=${userId}&chatUserPassword=${userPassword}`
      );
    } else if (chatUserRole === ChatUserRole.TenantCompanyAdmin) {
      navigate('/admin/room');
    } else {
      navigate(`/chat/${chatRoomId}`);
    }
  }, [chatRoomId, chatUserRole, navigate, userId, userPassword]);

  const loadPreviousMessageCallback = useCallback(async () => {
    const result = await loadPreviousMessages();
    setPreviousMessageLoaded(true);
    return result;
  }, [loadPreviousMessages]);

  // メッセージのフラグセット
  const setFlag = useCallback(
    async (messageId: string, value: boolean) => {
      const result = await setChatMessageFlag({
        variables: {
          input: {
            userId: userId,
            userPassword: userPassword,
            chatMessageId: messageId,
            isMessageFlag: value,
          },
        },
      });
      assertNotNull(result.data);
    },
    [setChatMessageFlag, userId, userPassword]
  );

  return {
    userId,
    userPassword,
    chatRoomId,
    sessionEntryCode,
    language,
    reverseLanguage,
    setReverseLanguage,
    chatUserRole,
    sessionInfo,
    users,
    joiningUsers,
    isQuestionMode,

    sendMessage,
    removeMessage,
    sendTemplate,
    sendImages,
    setQuestionMode,
    clearMessages,
    setReaction,
    removeUser,
    muteUserMic: muteUserMicFunc,
    endSession,
    setFlag,

    messages,
    loadPreviousMessages: loadPreviousMessageCallback,
    getPreviousMessage,

    chatText,
    setChatText,
    isMicMode,
    setIsMicMode,
    isTemplateMode,
    setIsTemplateMode,
    templateMessages: templateMessages.data?.chatRoomsTemplateMessages,

    playMessageEnabled,
    setPlayMessageEnabled: setPlayMessageEnabledCallback,
    playChat: play,
    stopChat,
    mini,
    playingMessageId,

    replaceNgWord,

    setReplyInfo: (
      value: { id: string; userId: string; text: string } | undefined
    ) => {
      setReplyInfo(value);
      replyMessageId.current = value?.id;
    },
    replyUser,
    replyText,

    loading:
      loading ||
      usersLoading ||
      templateMessages.loading ||
      addChatTemplateMessageResult.loading,
  };
};
export default useChat;
