import { joiResolver } from '@hookform/resolvers/joi';
import HelpIcon from '@mui/icons-material/Help';

import {
  Box,
  TextField,
  Typography,
  Button,
  Stack,
  IconButton,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { AdminContent, AdminInnerBox } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import AboutVadDialog from '@/components/organisms/admin/AboutVadDialog';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';

export type VadData = {
  label: string;
  smoothingTimeConstant: number;
  energyOffset: string;
  energyThresholdRatioPos: number;
  energyThresholdRatioNeg: number;
  energyIntegration: number;
};

const VadForm = (props: {
  loading: boolean;
  onSubmit: (input: VadData) => Promise<void>;
  defaultData?: VadData; // 編集のみ
  readonly?: boolean;
}) => {
  const initialValues: Omit<VadData, 'label'> = {
    smoothingTimeConstant: 0.985,
    energyOffset: '0.00000000001', // 1e-11
    energyThresholdRatioPos: 2,
    energyThresholdRatioNeg: 0.5,
    energyIntegration: 0,
  };
  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
    setValue,
  } = useForm<VadData>({
    defaultValues: {
      ...initialValues,
      ...props.defaultData,
    },
    resolver: joiResolver(
      joi.object<VadData>({
        label: joi.string().trim().max(50).required(),
        smoothingTimeConstant: joi.number().required(),
        energyOffset: joi.number().required(),
        energyThresholdRatioPos: joi.number().required(),
        energyThresholdRatioNeg: joi.number().required(),
        energyIntegration: joi.number().required(),
      })
    ),
  });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();
  const [isOpenVadInfo, setIsOpenVadInfo] = useState(false);

  useEffect(() => {
    if (!props.defaultData) {
      return;
    }
    const defaultData = props.defaultData;
    reset(defaultData);
  }, [props.defaultData, reset, setValue]);

  return (
    <Box>
      <LoadingBackdrop open={props.loading} />
      <BreadcrumbBar keyFrom="/admin/vad" />
      <AdminContent>
        <AdminInnerBox>
          <Typography variant="h4">
            {props.defaultData
              ? tCommon('command.edit')
              : tCommon('command.new')}
          </Typography>
        </AdminInnerBox>
        <AdminInnerBox sx={{ bgcolor: (t) => t.palette.common.white }}>
          <Box
            component={'form'}
            onSubmit={handleSubmit((input, ev) => {
              ev?.preventDefault();
              props.onSubmit(input);
            })}
          >
            <Stack
              sx={{ pt: 3, pb: 2, mx: 'auto', maxWidth: '320px' }}
              spacing={2}
            >
              <TextField
                label={t('vadSetting.labelTitle')}
                size="small"
                {...register('label')}
                error={'label' in errors}
                helperText={errors.label?.message}
                focused={watch('label')?.length > 0}
                disabled={props.readonly}
                InputLabelProps={{ shrink: !!watch('label') }}
              />

              {/* パラメーター */}
              <Box sx={{ pt: 3 }}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  sx={{ mb: 1 }}
                >
                  <Typography>{t('vadSetting.parameterInput')}</Typography>

                  <IconButton onClick={() => setIsOpenVadInfo(true)}>
                    <HelpIcon />
                  </IconButton>
                  <AboutVadDialog
                    open={isOpenVadInfo}
                    onClose={() => setIsOpenVadInfo(false)}
                  />
                </Stack>
                {/* <Typography>{t('vadSetting.parameterSpecify')}</Typography> */}
              </Box>
              <TextField
                label="Smoothing Time Constant"
                size="small"
                {...register('smoothingTimeConstant')}
                error={'smoothingTimeConstant' in errors}
                helperText={errors.smoothingTimeConstant?.message}
              />
              <TextField
                label="Energy Offset"
                size="small"
                {...register('energyOffset')}
                error={'energyOffset' in errors}
                helperText={errors.energyOffset?.message}
              />
              <TextField
                label="Energy Threshold Ratio Pos"
                size="small"
                {...register('energyThresholdRatioPos')}
                error={'energyThresholdRatioPos' in errors}
                helperText={errors.energyThresholdRatioPos?.message}
              />
              <TextField
                label="Energy Threshold Ratio Neg"
                size="small"
                {...register('energyThresholdRatioNeg')}
                error={'energyThresholdRatioNeg' in errors}
                helperText={errors.energyThresholdRatioNeg?.message}
              />
              <TextField
                label="Energy Integration"
                size="small"
                {...register('energyIntegration')}
                error={'energyIntegration' in errors}
                helperText={errors.energyIntegration?.message}
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
              sx={{ my: 3 }}
            >
              <CancelButton onClick={() => navigate('/admin/vad')} />
              <Button type="submit" disabled={!isDirty}>
                {props.defaultData
                  ? tCommon('command.save')
                  : tCommon('command.register')}
              </Button>
            </Stack>
          </Box>
        </AdminInnerBox>
      </AdminContent>
    </Box>
  );
};
export default VadForm;
