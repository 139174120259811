import { atom, useAtomValue, useSetAtom } from 'jotai';

const openNWAlert = atom(false);

export const useSetOpenNetworkAlert = () => {
  let timer: number
  const set = useSetAtom(openNWAlert);

  return (value: boolean) => {
    set(value);
    if (value) {
      clearTimeout(timer)
      timer = window.setTimeout(() => {
        set(false);
      }, 10_000);
    }
  };
};
export const useOpenNetworkAlert = () => useAtomValue(openNWAlert);
