import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Footer from '@/components/atoms/Footer';

const Thanks = () => {
  const { t } = useTranslation('chat');

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      px={1}
    >
      <Stack flex={1} justifyContent="center" spacing={2} alignItems="center">
        <Typography
          variant="h3"
          color={'primary'}
          fontWeight="400"
          textAlign={'center'}
        >
          {t('thanks')}
        </Typography>
      </Stack>
      <Footer />
    </Stack>
  );
};
export default Thanks;
