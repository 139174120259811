import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Divider } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { assertNotNull } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminContent } from '../../../components/atoms/AdminPageParts';
import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../components/organisms/confirmDialog/useConfirmDeleteDialog';
import {
  TemplateMessage,
  useEditTemplateMessageMutation,
  useRemoveTemplateMessagesMutation,
  useTemplateMessageCategoriesQuery,
  useTemplateMessagesQuery,
} from '../../../types/graphql';

import AddButton from '@/components/atoms/AddButton';
import DeleteButton from '@/components/atoms/DeleteButton';
import DraggableDataGrid from '@/components/atoms/DraggableDataGrid';
import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { DeletionFooterMenu } from '@/components/molecules/admin/DeletionFooterMenu';
import { ResponsiveFormHeader } from '@/components/molecules/admin/ResponsiveFormHeader';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';

const TemplateIndex = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t, i18n } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.templateAdmin'));
  const userLang = i18n.language;

  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const categoryId = params.categoryId;

  const categoriesResult = useTemplateMessageCategoriesQuery({
    variables: { input: { id: categoryId } },
  });
  const templateMessagesResult = useTemplateMessagesQuery({
    variables: { input: { categoryId } },
  });

  const [removeTemplateMessages, removeTemplateMessagesResult] =
    useRemoveTemplateMessagesMutation();
  const [editTemplateMessage, editTemplateMessageResult] =
    useEditTemplateMessageMutation();

  const [rows, setRows] = useState<TemplateMessage[]>();
  useEffect(() => {
    setRows(templateMessagesResult.data?.templateMessages);
  }, [templateMessagesResult.data?.templateMessages]);

  const [isInDeleteState, setIsInDeleteState] = useState(false);
  const [deletee, setDeletee] = useState<string[]>([]);
  const confirmDelete = useConfirmDeleteDialog();

  return (
    <Box>
      <LoadingBackdrop
        open={categoriesResult.loading || templateMessagesResult.loading}
      />
      <BreadcrumbBar keyFrom="/admin/template" />

      <AdminContent>
        <ResponsiveFormHeader
          title={
            categoriesResult.data?.templateMessageCategories[0].name ?? '　'
          }
          titleVarient="h4"
          toolbuttons={[
            <AddButton
              key="add"
              onClick={() => navigate('/admin/template/add')}
            />,
            ...(isInDeleteState === false
              ? [
                  <DeleteButton
                    key="remove"
                    onClick={() => setIsInDeleteState(true)}
                  />,
                ]
              : []),
          ]}
        />
        <Divider sx={{ mt: 4 }} />
        <DraggableDataGrid
          autoHeight
          columnHeaderHeight={40}
          columns={[
            {
              field: 'templateText',
              headerName: t('template.template'),
              minWidth: 150,
              flex: 1,
              sortable: false,
              renderCell: (params) => (
                <LineClampTypography variant="body1" rowCount={3} my={1.5}>
                  {params.value}
                </LineClampTypography>
              ),
            },
            ...(isInDeleteState
              ? [
                  {
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    renderHeader: () => commonT('command.delete'),
                  },
                ]
              : [
                  {
                    field: ' ',
                    type: 'actions',
                    width: 50,
                    minWidth: 50,
                    renderCell: () => [
                      <KeyboardArrowRightIcon
                        key="move"
                        sx={{ color: (t) => t.palette.grey[500] }}
                      />,
                      <DragIndicatorIcon
                        key="drag"
                        sx={{ color: (t) => t.palette.grey[500] }}
                      />,
                    ],
                  },
                ]),
          ]}
          sx={{
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'transparent',
            },
          }}
          rows={(rows ?? [])
            .sort((a, b) => a.order - b.order)
            .map((message) => {
              const templateText = message.languages.find(
                (m) => m.language === userLang
              )?.message;
              return {
                category: message.category.name,
                id: message.id,
                templateText: templateText,
                date: new Date(message.createdAt).toLocaleDateString(),
                order: message.order,
              };
            })}
          onChange={async (params: any[]) => {
            // 並べ替え
            const newRows = params.map((p, i) => {
              const row = rows?.find((r) => r.id === p.id);
              assertNotNull(row);
              return { ...row, order: i + 1 };
            });
            setRows(newRows);

            await Promise.all(
              newRows.map((x) =>
                editTemplateMessage({
                  variables: {
                    input: {
                      id: x.id,
                      files: x.files.map((y) => ({
                        id: y.id,
                        fileName: y.fileName,
                        fileType: y.fileType,
                      })),
                      languages: x.languages.map((y) => ({
                        language: y.language,
                        message: y.message,
                        readonly: y.readonly,
                      })),
                      templateCategoryId: x.category.id,
                      order: x.order,
                    },
                  },
                })
              )
            );
          }}
          getRowHeight={() => 'auto'}
          hideFooter
          disableColumnMenu
          disableMultipleColumnsSorting
          onRowClick={(params) => {
            navigate(`${params.id}`);
          }}
          onRowSelectionModelChange={(selection) =>
            setDeletee(selection.map((x) => x.toString()))
          }
          checkboxSelection={isInDeleteState}
          loading={
            categoriesResult.loading ||
            templateMessagesResult.loading ||
            removeTemplateMessagesResult.loading ||
            editTemplateMessageResult.loading
          }
        />
      </AdminContent>
      {isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={deletee.length === 0}
          deleteButtonText={t('template.deleteSelected')}
          onClickDelete={async () => {
            const result = await confirmDelete.open({
              title: t('template.deleteSelectedMsg'),
              message: t('template.unbackable'),
              okColor: 'primary',
            });
            if (result) {
              await removeTemplateMessages({
                variables: { input: { ids: deletee } },
              });
              setDeletee([]);
              setIsInDeleteState(false);
              templateMessagesResult.refetch();
              showSnackbar('success', t('template.deletedSelected'));
            }
          }}
          onClickCancel={() => setIsInDeleteState(false)}
        />
      )}
    </Box>
  );
};
export default TemplateIndex;
