import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const NetworkAlert = (props: {
  scrollElement: HTMLElement | null;
  isOpened: boolean;
}) => {
  const { t } = useTranslation('chat');
  const { scrollElement, isOpened } = { ...props };
  const [scrolling, setScrolling] = useState(false);

  //   windowのスクロール状態を監視
  useEffect(() => {
    let debounceTimer: string | number | NodeJS.Timeout | undefined;
    const eventHandler = () => {
      if (scrolling === false) {
        setScrolling(true);
      }
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setScrolling(false);
      }, 1000);
    };
    if (!scrollElement) return;
    scrollElement.addEventListener('scroll', eventHandler);
    return () => {
      scrollElement.removeEventListener('scroll', eventHandler);
    };
  }, [scrollElement, scrolling]);

  return isOpened && !scrolling ? (
    <Alert
      severity="warning"
      sx={{ position: 'fixed', right: 0, zIndex: 100, m: 1 }}
    >
      {t('networkAlert')}
    </Alert>
  ) : (
    <></>
  );
};

export default NetworkAlert;
