import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SystemCommand from '@/types/SystemCommand';

const MsgBox = (props: { text: string; timestamp: Date }) => {
  const sentTime = new Date(props.timestamp);
  return (
    <Box mx={4}>
      <Box height="4px" />
      <Box display="grid" gridTemplateColumns="1fr auto 1fr">
        <Typography
          gridColumn={2}
          variant="caption"
          color={(t) => t.palette.grey[600]}
        >
          {`${sentTime.getHours().toString().padStart(2, '0')}:${sentTime
            .getMinutes()
            .toString()
            .padStart(2, '0')}`}
        </Typography>
      </Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 10,
          borderColor: (t) => t.palette.grey[500],
          maxWidth: 400,
          mx: 'auto',
        }}
      >
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ color: (t) => t.palette.grey[700] }}
        >
          {props.text}
        </Typography>
      </Box>
      <Box height="8px" />
    </Box>
  );
};

const SystemMessage = (props: {
  command: string;
  timestamp: Date;
  canGuideControl: boolean;
  getUserName: (userId: string) => string;
}) => {
  const { t } = useTranslation('chat');

  try {
    const command = JSON.parse(props.command) as SystemCommand;
    if (command.commandType === 'clearChat') {
      if (props.canGuideControl) {
        return (
          <MsgBox
            text={t('systemMessage.clearedScreen')}
            timestamp={props.timestamp}
          />
        );
      } else {
        return <Box height="1px"></Box>;
      }
    } else if (command.commandType === 'muteUserMic') {
      return (
        <MsgBox
          text={t('systemMessage.mutedAllMics')}
          timestamp={props.timestamp}
        />
      );
    } else if (command.commandType === 'joinUser') {
      return (
        <MsgBox
          text={t('systemMessage.joinedUser', {
            name: props.getUserName(command.userId),
          })}
          timestamp={props.timestamp}
        />
      );
    } else if (command.commandType === 'leaveUser') {
      return (
        <MsgBox
          text={t('systemMessage.leftUser', {
            name: props.getUserName(command.userId),
          })}
          timestamp={props.timestamp}
        />
      );
    } else if (command.commandType === 'removeChatSessionUser') {
      return <Box height="1px"></Box>;
      // return <Box>{`参加者が一斉退出されました`}</Box>;
    } else {
      return <Box height="1px"></Box>;
    }
  } catch (e: any) {
    //パースに失敗するケースを考慮
    console.error(e);
    return <Box height="1px"></Box>;
  }
};
export default SystemMessage;
