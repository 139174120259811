import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';

import CancelButton from '@/components/atoms/CancelButton';
import LabeledIconButton from '@/components/molecules/LabeledIconButton';

export type RoomInvitation = {
  url: string;
  guideUrl?: string;
  entryCode?: string;
};

export type InvitingRoomDialogProps = {
  open: boolean;
  onClose: () => void;
  invitation: RoomInvitation;
  // caller: 'chat' | 'admin';
};

const MyAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: 0,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const InvitingRoomDialog = (props: InvitingRoomDialogProps) => {
  const { t } = useTranslation('chat');
  const { t: tCommon } = useTranslation('common');

  return (
    <Dialog open={props.open} onClose={props.onClose} scroll="body">
      <DialogTitle>{t('invitation.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={1} mb={1} mt={-1}>
          <Typography variant="h6">{t('invitation.inviteGuest')}</Typography>
          <Box pt={1}>
            <Typography variant="subtitle1">
              {t('invitation.inviteViaUrl')}
            </Typography>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr auto"
            alignItems="center"
          >
            <Stack>
              <Typography variant="body1">
                {t('invitation.roomUrl')} :
              </Typography>
              <Typography
                sx={{ overflowWrap: 'anywhere', textAlign: 'left' }}
                variant="body2"
              >
                <Link to={props.invitation.url} component={ReactLink}>
                  {props.invitation.url}
                </Link>
              </Typography>
              {props.invitation.entryCode !== '' && (
                <Typography variant="body1" my={1}>
                  {tCommon('caption.entryCode')} : {props.invitation.entryCode}
                </Typography>
              )}
            </Stack>
            <LabeledIconButton
              color="primary"
              sx={{ width: 60, height: 60 }}
              label={tCommon('command.copy')}
              onClick={() => {
                navigator.clipboard.writeText(props.invitation.url);
              }}
            >
              <ContentCopyIcon />
            </LabeledIconButton>
          </Box>
        </Stack>
        <Stack spacing={1} my={3}>
          <Typography variant="subtitle1">
            {t('invitation.inviteViaQrCode')}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="1fr auto 1fr"
            alignItems="center"
          >
            <Box></Box>
            <QRCodeCanvas
              value={props.invitation.url}
              id={'qrCodeEl'}
              size={100}
            />
            <LabeledIconButton
              color="primary"
              label={tCommon('command.download')}
              sx={{ width: 60, height: 60, ml: 'auto' }}
              onClick={(ev) => {
                ev.preventDefault();
                const qrCodeUrl = (
                  document.getElementById('qrCodeEl') as HTMLCanvasElement
                )?.toDataURL('image/png');
                const aEl = document.createElement('a');
                aEl.href = qrCodeUrl;
                aEl.download = 'QR_Code.png';
                document.body.appendChild(aEl);
                aEl.click();
                document.body.removeChild(aEl);
              }}
            >
              <DownloadIcon />
            </LabeledIconButton>
          </Box>
        </Stack>
      </DialogContent>

      {props.invitation.guideUrl && (
        <MyAccordion sx={{ m: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" mx={2}>
              {t('invitation.inviteGuide')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mx: 2, mt: -1.5 }}>
              <Stack spacing={1} mb={1}>
                <Box pt={1}>
                  <Typography variant="subtitle1">
                    {t('invitation.inviteViaUrl')}
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr auto"
                  alignItems="center"
                >
                  <Stack>
                    <Typography variant="body1">
                      {t('invitation.roomUrl') + '：'}
                    </Typography>
                    <Typography
                      sx={{ overflowWrap: 'anywhere', textAlign: 'left' }}
                      variant="body2"
                    >
                      <Link
                        to={props.invitation.guideUrl}
                        component={ReactLink}
                      >
                        {props.invitation.guideUrl}
                      </Link>
                    </Typography>
                  </Stack>
                  <LabeledIconButton
                    color="primary"
                    sx={{ width: 60, height: 60 }}
                    label={tCommon('command.copy')}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        props.invitation.guideUrl ?? ''
                      );
                    }}
                  >
                    <ContentCopyIcon />
                  </LabeledIconButton>
                </Box>
              </Stack>
              <Stack spacing={1} my={3}>
                <Typography variant="subtitle1">
                  {t('invitation.inviteViaQrCode')}
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr auto 1fr"
                  alignItems="center"
                >
                  <Box></Box>
                  <QRCodeCanvas
                    value={props.invitation.guideUrl ?? ''}
                    id={'qrCodeElGuide'}
                    size={100}
                  />
                  <LabeledIconButton
                    color="primary"
                    label={tCommon('command.download')}
                    sx={{ width: 60, height: 60, ml: 'auto' }}
                    onClick={(ev) => {
                      ev.preventDefault();
                      const qrCodeUrl = (
                        document.getElementById(
                          'qrCodeElGuide'
                        ) as HTMLCanvasElement
                      )?.toDataURL('image/png');
                      const aEl = document.createElement('a');
                      aEl.href = qrCodeUrl;
                      aEl.download = 'QR_Code.png';
                      document.body.appendChild(aEl);
                      aEl.click();
                      document.body.removeChild(aEl);
                    }}
                  >
                    <DownloadIcon />
                  </LabeledIconButton>
                </Box>
              </Stack>
            </Box>
          </AccordionDetails>
        </MyAccordion>
      )}
      <Divider />
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CancelButton onClick={() => props.onClose()} />
      </DialogActions>
    </Dialog>
  );
};
export default InvitingRoomDialog;
