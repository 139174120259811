import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CSVOperationMenu = (
  props: MenuProps & {
    onClickDownload: () => void;
    onClickExport: () => void;
    onClickImport: () => void;
  }
) => {
  const { t } = useTranslation('admin');
  const { onClickDownload, onClickExport, onClickImport, ...menuProps } = props;
  return (
    <Menu {...menuProps}>
      <MenuItem onClick={onClickDownload}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t('template.download')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClickExport}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t('template.csvExport')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClickImport}>
        <ListItemIcon>
          <UploadIcon />
        </ListItemIcon>
        <ListItemText>{t('template.csvImport')}</ListItemText>
      </MenuItem>
    </Menu>
  );
};
