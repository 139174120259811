import { Box, Table, TableBody, styled } from '@mui/material';

import { adminMaxWidth } from '@/utils/constants';

export const AdminInnerBox = styled(Box)({
  padding: '16px',
  margin: '16px auto',
  borderRadius: 10,
}) as typeof Box;

// MAX1100pxでセンタリングするBox
export const AdminContent = styled(Box)({
  maxWidth: adminMaxWidth,
  width: '100%',
  margin: '0 auto',
  padding: 20,
});

export const AdminTable = styled(Table)({
  margin: '16px auto',
  width: 'calc(100% - 32px)',
  border: 'solid #D0DFE6 1px',
});

export const AdminTableBody = styled(TableBody)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));
