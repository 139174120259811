import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GridRow, GridRowProps } from '@mui/x-data-grid-pro';
import { memo } from 'react';

// eslint-disable-next-line react/display-name
const DraggableGridRow = memo((params: GridRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: params.rowId });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none',
      }}
      {...attributes}
      {...listeners}
    >
      <GridRow {...params} />
    </div>
  );
});

export default DraggableGridRow;
