import {
  Select,
  MenuItem,
  Box,
  FormControl,
  FormHelperText,
  Button,
  InputLabel,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useState, useEffect } from 'react';

import {
  ControllerRenderProps,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import { TemplateMessageData } from '@/components/molecules/admin/TemplateForm';
import { useCategoryDialog } from '@/components/organisms/categoryDialog/useCategoryDialog';
import {
  useAddTemplateMessageCategoryMutation,
  useTemplateMessageCategoriesQuery,
} from '@/types/graphql';

export const TemplateCategorySelector = (props: {
  register: UseFormRegister<TemplateMessageData>;
  errors: FieldErrors<TemplateMessageData>;
  belongedCategoryId: string;
  field: ControllerRenderProps<TemplateMessageData, 'templateCategoryId'>;
}) => {
  const { t } = useTranslation('admin');
  const permission = useUserPermission();
  const templateMessageCategoriesResult = useTemplateMessageCategoriesQuery({
    variables: { input: {} },
  });
  const gotCategories =
    templateMessageCategoriesResult.data?.templateMessageCategories;
  const [categories, setCategories] = useState(gotCategories);

  const categoryDialog = useCategoryDialog();
  const [addTemplateMessageCategory] = useAddTemplateMessageCategoryMutation();

  useEffect(() => {
    setCategories(gotCategories);
  }, [gotCategories, setCategories]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="categoryselector-label">
          {t('template.selectCategory')}
        </InputLabel>
        <Select
          labelId="categoryselector-label"
          {...props.field}
          {...props.register('templateCategoryId')}
          value={props.field.value ?? ''}
          label={t('template.selectCategory')}
          renderValue={(value) => (
            <LineClampTypography rowCount={1}>
              {categories?.filter((cat) => cat.id === value)[0].name}
            </LineClampTypography>
          )}
        >
          {(categories ?? []).map((cat) => (
            <MenuItem key={cat.id} value={cat.id}>
              <LineClampTypography flex={1} rowCount={1}>
                {cat.name}
              </LineClampTypography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          sx={{ color: (t) => t.palette.error.main }}
          error={props.errors.templateCategoryId?.message != null}
        >
          {props.errors.templateCategoryId?.message}
        </FormHelperText>
      </FormControl>

      <Button
        variant="text"
        onClick={async () => {
          const result = await categoryDialog.open({
            title: t('template.createNewCategory'),
          });
          if (result == null) return undefined;
          const added = await addTemplateMessageCategory({
            variables: {
              input: {
                name: result,
                tenantCompanyId: permission.tenantCompanyId ?? '',
              },
            },
          });
          if (added.errors) throw added.errors;
          assertNotNull(added.data);
          setCategories([
            ...(categories ?? []),
            added.data.addTemplateMessageCategory,
          ]);
        }}
      >
        {t('template.createNewCategory') + '+'}
      </Button>
    </Box>
  );
};
