import { Typography, Stack, Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Footer from '@/components/atoms/Footer';
import Logo from '@/components/atoms/Logo';
import useLanguage, { useSetLanguage } from '@/components/hooks/useLanguage';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher';
import { env } from '@/env';
import { useNotificationsQuery } from '@/types/graphql';

const TenantLogin = () => {
  const { t } = useTranslation('admin');
  const { t: tChat } = useTranslation('chat');

  const language = useLanguage();
  const setLanguage = useSetLanguage();

  const notifications = useNotificationsQuery({
    variables: { input: { filterOnlyEnabled: true } },
  });

  const navigate = useNavigate();
  const permission = useUserPermission();
  useEffect(() => {
    if (permission != null && permission.cognitoUserName != '') {
      navigate('/admin');
    }
  }, [navigate, permission]);

  return (
    <Stack
      alignItems="center"
      justifyContent={'center'}
      sx={{
        minHeight: '100%',
        width: '100%',
      }}
      spacing={2}
    >
      <Logo sx={{ width: 1, alignItems: 'flex-start', p: 3, pb: 6 }} />

      <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
        {t('login.login')}
      </Typography>

      <Stack width="100%" flex={1} spacing={4} p={2}>
        <Stack
          width="100%"
          alignItems="center"
          component={'form'}
          spacing={3}
          onSubmit={(ev) => {
            ev.preventDefault();
            location.href = env.REACT_APP_UNAUTHRIZED_REDIRECT_URL;
          }}
        >
          <LanguageSwitcher language={language} setLanguage={setLanguage} />
          <Button sx={{ width: '240px' }} size="large" type="submit">
            {t('login.login')}
          </Button>
        </Stack>

        <Stack
          width="100%"
          textAlign="left"
          p={2}
          borderRadius={3}
          sx={{ backgroundColor: (t) => t.palette.background.default }}
          spacing={2}
        >
          <Typography variant="subtitle1">{tChat('news')}</Typography>
          {notifications.data != null &&
            notifications.data.notifications.length >= 1 &&
            notifications.data?.notifications.map((x) => (
              <Typography
                key={x.id}
                variant="body2"
                sx={{ whiteSpace: 'pre-line' }}
              >
                {x.message}
              </Typography>
            ))}
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
};
export default TenantLogin;
