// チャットメッセージを管理するHooks
// IndexedDBへのデータ保存や取得もここで管理してしまう。

import { assertNotNull, sleep } from '@remote-voice/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGraphqlSubscriptionConnection } from '@/components/hooks/useGraphqlSubscriptionConnection';
import { ChatMessageItem } from '@/components/organisms/chat/ChatMessageCache';
import ChatMessages from '@/components/organisms/chat/ChatMessages';
import { env } from '@/env';
import {
  useUpsertChatMessageMutation,
  useChatCommandExecutedSubscription,
  useChatMessageAddedSubscription,
  useChatMessagesLazyQuery,
  ChatMessage,
  ChatSessionInfo,
  useChatSessionInfoLazyQuery,
  ChatUserRole,
} from '@/types/graphql';
import SystemCommand from '@/types/SystemCommand';
import { urlBase64ToUint8Array } from '@/utils/string';
import { isChatGuest } from '@/utils/userRole';

export type UpsertMessageParams = {
  languages?: { message: string; language: string }[];
  files?: {
    id: string;
    fileName: string;
    fileType: string;
    // signedURL: string;
  }[];
  overwriteChatMessageId?: string; // 上書きの場合は指定
  isTyping?: boolean; // 入力中かどうか。
  replyMessageId?: string;
  shouldReplaceHomophone?: boolean; // 同音異義語置換を実施するかどうか
};

const useChatMessages = (options: {
  chatRoomId: string;
  chatSessionId: string;
  sessionEntryCode: string;
  userId: string;
  userPassword: string;
  language: string;
  chatUserRole?: ChatUserRole;
  reverseLanguage?: string;
}) => {
  // チャットメッセージの管理クラス
  const chatMessages = useRef<ChatMessages | undefined>(undefined);

  // 戻り値用の、常にシーケンス番号でソートされたリスト
  const [messages, setMessages] = useState<[number, ChatMessageItem][]>([]);

  // 初期化済みかどうか（最新データが全取得されたら初期化済み）
  const [isInitialized, setIsInitialized] = useState(false);
  const isInitializedRef = useRef(isInitialized);

  // キャッシュデータを取得済みかどうか
  const [isCacheLoaded, setIsCacheLoaded] = useState(false);
  const isCacheLoadedRef = useRef(isCacheLoaded);

  const [loadingCount, setLoadingCount] = useState(0);

  const mountCount = useRef(0);

  // 初期化完了前に受信したメッセージを保持
  const beforeInitMessages = useRef<ChatMessage[]>([]);

  const [getChatMessages] = useChatMessagesLazyQuery();
  const [upsertChatMessage] = useUpsertChatMessageMutation();
  const [getChatSessionInfo] = useChatSessionInfoLazyQuery();
  const [sessionInfo, setSessionInfo] = useState<ChatSessionInfo | undefined>();
  const subscriptionConnection = useGraphqlSubscriptionConnection();

  // orderedから配列に複製する
  const refreshMessageArray = useCallback(() => {
    assertNotNull(chatMessages.current); // 初期化されていなければエラー
    setMessages(chatMessages.current.toArray());
  }, []); // ← ここは無条件であること

  // ルームや言語が変わるタイミングで、キャッシュと最新データの読み込みを行う。
  useEffect(() => {
    if (
      options.chatRoomId === '' ||
      options.chatSessionId === '' ||
      options.language === '' ||
      subscriptionConnection === false
    ) {
      return;
    }
    const mc = mountCount.current;
    chatMessages.current = undefined;
    beforeInitMessages.current = [];
    setIsInitialized((isInitializedRef.current = false));
    setIsCacheLoaded((isCacheLoadedRef.current = false));
    (async () => {
      // データ管理クラス生成
      const cacheId = `${options.chatSessionId}_${options.language}`;
      // セッション情報取得
      const chatSessionInfo = await getChatSessionInfo({
        variables: {
          input: {
            chatRoomId: options.chatRoomId,
            sessionEntryCode: options.sessionEntryCode,
          },
        },
      });
      if (chatSessionInfo.error) throw chatSessionInfo.error;
      assertNotNull(chatSessionInfo.data);
      const newCM = await ChatMessages.create(cacheId);
      if (mountCount.current !== mc) return;
      setSessionInfo(chatSessionInfo.data.chatSessionInfo);
      setIsCacheLoaded((isCacheLoadedRef.current = true));
      chatMessages.current = newCM;
      // 最新のデータを取得
      {
        const requestCount = 10;
        const getLatest = async () => {
          if (newCM.getLastUpdatedAt() === 0) return [];
          const messages = await getChatMessages({
            variables: {
              input: {
                chatRoomId: options.chatRoomId,
                sessionEntryCode: options.sessionEntryCode,
                language: options.language,
                afterUpdateAt: new Date(newCM.getLastUpdatedAt()).toISOString(),
                count: requestCount,
              },
            },
          });
          if (messages.error) throw messages.error;
          assertNotNull(messages.data);
          for (const message of messages.data.chatMessages) {
            await newCM.upsertMessage(message.sequence * 1000, message, true);
          }
          if (messages.data.chatMessages.length > 0) {
            console.debug(
              'loadLatestMessages',
              messages.data.chatMessages.length
            );
          }
          return messages.data.chatMessages;
        };
        while ((await getLatest()).length === requestCount) {
          await sleep(100); // 要求数のデータが取得できなくなるまで繰り返し取得
        }
      }
      if (mountCount.current !== mc) return;
      // 初期化前にメッセージを受信していた場合はそれらを追加
      for (const message of beforeInitMessages.current) {
        await chatMessages.current.upsertMessage(
          message.sequence * 1000,
          message
        );
      }
      if (beforeInitMessages.current.length > 0) {
        console.debug(
          'loadReceivedLatestMessages',
          beforeInitMessages.current.length
        );
      }
      if (mountCount.current !== mc) return;
      beforeInitMessages.current = [];
      // clearedSequenceが変更されているかもしれないので実行
      await chatMessages.current.clearMessages(
        chatSessionInfo.data.chatSessionInfo.clearedSequence
      );
      if (mountCount.current !== mc) return;
      // ステートを更新
      setIsInitialized((isInitializedRef.current = true));
      refreshMessageArray();
    })();
    return () => {
      mountCount.current = mountCount.current + 1;
    };
  }, [
    options.chatRoomId,
    options.chatSessionId,
    options.sessionEntryCode,
    options.language,
    getChatMessages,
    getChatSessionInfo,
    refreshMessageArray,
    subscriptionConnection,
  ]);

  // Push通知購読用のサブスクリプションオブジェクト
  const [subscription, setSubscription] = useState<PushSubscription>();

  // Push通知が有効になっている場合はサブスクライブ
  useEffect(() => {
    if (
      // ゲストかつ通知がONのルームならサブスクライブ
      isChatGuest(options.chatUserRole) &&
      sessionInfo?.isNotifyOnMessageEnabled &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    ) {
      (async () => {
        try {
          // 通知の許可をリクエスト
          if (Notification.permission === 'default') {
            await Notification.requestPermission();
          }

          const swReg = await navigator.serviceWorker.register(
            '/workers/webpush-worker.js'
          );
          const subscription = await swReg.pushManager.getSubscription();
          if (subscription === null) {
            const vapidPublicKey = env.REACT_APP_WEBPUSH_VAPID_PUBLIC_KEY ?? '';
            const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

            const subscription = await swReg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            });
            setSubscription(subscription);
          } else {
            // 既に購読済み
            setSubscription(subscription);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [options.chatUserRole, sessionInfo?.isNotifyOnMessageEnabled]);

  // チャットメッセージが送信されてきた際の処理を記述
  useChatMessageAddedSubscription({
    variables: {
      input: {
        chatRoomId: options.chatRoomId,
        sessionEntryCode: options.sessionEntryCode,
        language: options.language,
        chatUserId: options.userId,
        pushSubscription: subscription
          ? JSON.stringify(subscription)
          : undefined,
      },
    },
    onData: async (onDataResult) => {
      const addedMessage = onDataResult.data.data?.chatMessageAdded;
      assertNotNull(addedMessage);
      if (chatMessages.current == null) return;
      const mc = mountCount.current;
      if (isInitializedRef.current === false) {
        // 初期化前であれば表示のリストには加えずに保持
        beforeInitMessages.current.push(addedMessage);
      } else {
        // サブスクリプションの方が先に届いた場合に自分のメッセージが2つ表示されないよう、ちょっとだけ待機。
        if (addedMessage.userId === options.userId) await sleep(100);
        if (mountCount.current !== mc) return;
        await chatMessages.current.upsertMessage(
          addedMessage.sequence * 1000,
          addedMessage
        );
        if (mountCount.current !== mc) return;
        refreshMessageArray();
      }
    },
    onError: (error) => {
      throw error;
    },
    skip: isCacheLoaded === false, // キャッシュの取得が終わってから動作開始
  });

  // チャットコマンド
  useChatCommandExecutedSubscription({
    variables: {
      input: {
        chatRoomId: options.chatRoomId,
        sessionEntryCode: options.sessionEntryCode,
      },
    },
    onData: async (result) => {
      const commandResult = result.data.data?.chatCommandExecuted;
      assertNotNull(commandResult);
      const command = JSON.parse(commandResult) as SystemCommand;
      if (chatMessages.current == null) return;
      const mc = mountCount.current;
      // クリア
      if (command.commandType === 'clearChat') {
        const sequence = command.clearedSequence;
        if (Number.isInteger(sequence) == false) throw new Error();
        setLoadingCount((v) => v + 1);
        try {
          await chatMessages.current.clearMessages(sequence);
        } finally {
          setLoadingCount((v) => v - 1);
        }
        if (mountCount.current !== mc) return;
        refreshMessageArray();
      }
    },
    onError: (error) => {
      throw error;
    },
    skip: isInitialized === false, // 全ての初期化が終わってから動作開始
  });

  // 新規メッセージを送信する
  const upsertMessage = useCallback(
    async (params: UpsertMessageParams) => {
      assertNotNull(chatMessages.current);
      const mc = mountCount.current;
      if (params.languages?.find((l) => l.message.length === 0)) {
        // いずれかの言語空文字の場合は処理をしない
        return;
      }
      const newKey = chatMessages.current.getLastOrderKey() + 1;
      if (newKey % 1000 === 0) throw new Error(); // 通常はあり得ないが、1000回以上未送信が続いたらエラーにしておく

      // ローカル上でチャットリストを更新（TODO: 上書きメッセージの場合、この部分の処理は意味をなさないが、分かりづらい）
      const timestamp = new Date();
      const newMessage: ChatMessageItem = {
        timestamp: timestamp.toISOString(),
        updateAt: timestamp.toISOString(),
        userId: options.userId,
        languages:
          params.languages?.map((x) => ({
            message: x.message,
            language: x.language,
            isOriginal: x.language === options.language,
          })) ?? [],
        id: '',
        sequence: 0,
        isTyping: params.isTyping ?? false,
        isRemoved: false,
        isTemplate: false,
        reactions: [],
        files:
          params.files?.map((x) => ({
            id: x.id,
            fileName: x.fileName,
            fileType: x.fileType,
            // signedURL: x.signedURL,
          })) ?? [],
        isMessageFlag: false,
      };

      // 返信指定があればIDから値を検索（送信されるまでのキャッシュ用。低速処理のため注意）
      if (params.replyMessageId != null) {
        let msgKey: number | undefined;
        let item: ChatMessageItem | undefined;
        do {
          const prevMsg = chatMessages.current.getPreviousMessage(msgKey);
          if (prevMsg != null) {
            msgKey = prevMsg[0];
          }
          item = prevMsg?.[1];
          if (item?.id === params.replyMessageId) {
            const lang = item.languages.find(
              (x) => x.language === options.language
            );
            if (lang != null) {
              newMessage.reply = {
                chatMessageId: params.replyMessageId,
                languages: [lang],
                userId: item.userId,
              };
            }
          }
        } while (item != null);
      }

      // 初期化が終わっていないか上書きであればメモリ上は更新しない（受信に任せる）
      if (isInitializedRef.current && params.overwriteChatMessageId == null) {
        await chatMessages.current.upsertMessage(newKey, newMessage);
        if (mountCount.current !== mc) return;
        refreshMessageArray();
      }

      // サーバーにメッセージを送信
      try {
        const result = await upsertChatMessage({
          variables: {
            input: {
              chatMessageId: params.overwriteChatMessageId,
              chatRoomId: options.chatRoomId,
              sessionEntryCode: options.sessionEntryCode,
              originalLanguage: options.language,
              languages:
                params.languages?.map((x) => ({
                  message: x.message,
                  language: x.language,
                })) ?? [],
              userId: options.userId,
              userPassword: options.userPassword,
              reverseLanguage: params.isTyping
                ? undefined
                : options.reverseLanguage,
              isTyping: params.isTyping ?? false,
              files:
                params.files?.map((x) => ({
                  id: x.id,
                  fileName: x.fileName,
                  fileType: x.fileType,
                  // signedURL: x.signedURL,
                })) ?? [],
              replyMessageId: params.replyMessageId,
              shouldReplaceHomophone: params.shouldReplaceHomophone,
            },
          },
        });
        if (mountCount.current !== mc) return;
        if (result.errors) throw result.errors;
        assertNotNull(result.data);
        // 上書きであればメモリ上は更新しない
        if (params.overwriteChatMessageId != null) {
          return result.data.upsertChatMessage;
        }
        // 初期化が終わっていなければメモリ上は更新しない（受信に任せる）
        if (isInitializedRef.current === false) return;

        // 送信成功なので、送信前データを削除し、受信したデータを挿入
        chatMessages.current.eraseMessage(newKey);
        await chatMessages.current.upsertMessage(
          result.data.upsertChatMessage.sequence * 1000,
          result.data.upsertChatMessage,
          true // 自分のメッセージは強制表示
        );
        if (mountCount.current !== mc) return;
        refreshMessageArray();
        return result.data.upsertChatMessage;
      } catch (e) {
        // 送信失敗なので、errorを有効にしたオブジェクトに差し替え
        if (mountCount.current !== mc) return;
        await chatMessages.current.upsertMessage(
          newKey,
          Object.assign({}, newMessage, { error: true })
        );
        if (mountCount.current !== mc) return;
        refreshMessageArray();
      }
    },
    [
      options.userId,
      options.language,
      options.chatRoomId,
      options.sessionEntryCode,
      options.userPassword,
      options.reverseLanguage,
      refreshMessageArray,
      upsertChatMessage,
    ]
  );

  // 最初のシーケンスより前のデータを取得
  const loadPreviousMessages = useCallback(async () => {
    const mc = mountCount.current;

    // 最新データのロードが完了するまで待機
    while (isInitializedRef.current === false) {
      await sleep(100);
      if (mountCount.current !== mc) return [];
    }
    assertNotNull(chatMessages.current);

    const firstOrderKey = chatMessages.current.getFirstOrderKey();
    const beforeSequence = (firstOrderKey - (firstOrderKey % 1000)) / 1000;
    const messages = await getChatMessages({
      variables: {
        input: {
          chatRoomId: options.chatRoomId,
          sessionEntryCode: options.sessionEntryCode,
          language: options.language,
          beforeSequence: beforeSequence,
          count: 10,
        },
      },
    });
    if (messages.error) throw messages.error;
    assertNotNull(messages.data);
    if (mountCount.current !== mc) return [];
    for (const message of messages.data.chatMessages) {
      await chatMessages.current.upsertMessage(
        message.sequence * 1000,
        message,
        true
      );
      if (mountCount.current !== mc) return [];
    }
    refreshMessageArray();
    if (messages.data.chatMessages.length > 0) {
      console.debug('loadPreviousMessages', messages.data.chatMessages.length);
    }
    return messages.data.chatMessages as ChatMessageItem[];
  }, [
    getChatMessages,
    refreshMessageArray,
    options.chatRoomId,
    options.sessionEntryCode,
    options.language,
  ]);

  // waitingリストを更新して、表示用リストも更新する
  useEffect(() => {
    const queueChecker = setInterval(async () => {
      const mc = mountCount.current;
      const updateWaitingResult = await chatMessages.current?.updateWaiting();
      const updateTypingResult = await chatMessages.current?.updateTyping();
      if (updateWaitingResult || updateTypingResult) {
        if (mountCount.current !== mc) return;
        refreshMessageArray();
      }
    }, 500);
    return () => clearTimeout(queueChecker);
  }, [refreshMessageArray]);

  const getPreviousMessageCallback = useCallback(
    (sequence: number | undefined) => {
      return chatMessages.current?.getPreviousMessage(sequence);
    },
    []
  );

  const getNextMessageCallback = useCallback((sequence: number | undefined) => {
    return chatMessages.current?.getNextMessage(sequence);
  }, []);

  return {
    messages,
    upsertMessage,
    loadPreviousMessages,
    isInitialized,
    loading: isInitialized === false || loadingCount > 0,
    sessionInfo,
    getPreviousMessage: getPreviousMessageCallback,
    getNextMessage: getNextMessageCallback,
  };
};

export default useChatMessages;
