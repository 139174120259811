import { useTranslation } from 'react-i18next';

import useConfirmLogoutDialog from '@/components/organisms/confirmDialog/useConfirmLogoutDialog';
import { env } from '@/env';
import { useLogoutLazyQuery } from '@/types/graphql';

const useLogout = () => {
  const confirmDialog = useConfirmLogoutDialog();

  const { t } = useTranslation('admin');
  const [logout] = useLogoutLazyQuery();
  return async () => {
    // 確認ダイアログ表示
    if (
      (await confirmDialog.open({
        title: t('userSettings.logoutMessage'),
      })) === false
    ) {
      return; // キャンセル
    }
    await logout();
    window.location.href = env.REACT_APP_LOGOUT_ENDPOINT;
  };
};
export default useLogout;
