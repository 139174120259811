import { useCallback } from 'react';

import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import WaitingChatSessionDialog, {
  WaitingChatSessionDialogProps,
} from '@/components/organisms/chat/WaitingChatSessionDialog';

const WaitingChatSessionDialogEx = (
  props: Omit<WaitingChatSessionDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <WaitingChatSessionDialog open={isOpen} {...props} />;
};

const useWaitingChatSessionDialog = () => {
  const openAwaiter = useOpenAwaiter<'cancel' | 'start' | 'join'>();
  const onJoin = useCallback(
    (withStart: boolean) => {
      openAwaiter.close(withStart ? 'start' : 'join');
    },
    [openAwaiter]
  );

  return {
    open: async (
      props: Omit<WaitingChatSessionDialogProps, 'open' | 'onCancel' | 'onJoin'>
    ) => {
      return await openAwaiter.open(
        <WaitingChatSessionDialogEx
          isOpenKey={openAwaiter.key}
          onCancel={() => openAwaiter.close('cancel')}
          onJoin={onJoin}
          {...props}
        />
      );
    },
  };
};

export default useWaitingChatSessionDialog;
