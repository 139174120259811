export type CsvData = {
  ID: string;
  categoryId: string;
  categoryName: string;
  kana: string;
  sourceWord: string;
  targetWord: string;
  enabled: boolean;
};

const homophoneCsvStringify = (records: CsvData[]) => {
  const result: string[] = [];
  const headers = [
    'ID',
    'categoryId',
    'categoryName',
    'kana',
    'sourceWord',
    'targetWord',
    'enabled',
  ].join(',');
  result.push(headers);
  for (const record of records) {
    result.push(
      [
        record.ID,
        record.categoryId,
        record.categoryName,
        record.kana,
        record.sourceWord,
        record.targetWord,
        record.enabled ? '✔' : '',
      ]
        .map((x) =>
          x.indexOf('"') === -1 && x.indexOf(',') === -1
            ? x
            : '"' + x.replaceAll('"', '""') + '"'
        )
        .join(',')
    );
  }
  return '\uFEFF' + result.join('\n');
};
export default homophoneCsvStringify;
