import { atom, useAtom, useSetAtom } from 'jotai';

type UserPermission = {
  cognitoUserName: string;
  isSuperAdmin: boolean;
  isOwnerCompanyAdmin: boolean;
  isTenantCompanyAdmin: boolean;
  ownerCompanyId?: string;
  tenantCompanyId?: string;
};

const userPermissionAtom = atom<UserPermission>({
  cognitoUserName: '',
  isSuperAdmin: false,
  isOwnerCompanyAdmin: false,
  isTenantCompanyAdmin: false,
});

export const useSetUserPermission = () => useSetAtom(userPermissionAtom);
export const useUserPermission = () => useAtom(userPermissionAtom)[0];
