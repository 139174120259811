import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Divider, List, ListItem, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import { ReactionQuestionIcon } from '@/components/atoms/ReactionQuestionIcon';
import { ChatReactionType } from '@/types/graphql';
// const types = [
//   {
//     type: ChatReactionType.Like,
//     value: '👍',
//   },
//   {
//     type: ChatReactionType.Clap,
//     value: '👏',
//   },
//   {
//     type: ChatReactionType.Love,
//     value: '💛',
//   },
//   {
//     type: ChatReactionType.Happy,
//     value: '😆',
//   },
//   {
//     type: ChatReactionType.Struggle,
//     value: '😟',
//   },
//   {
//     type: ChatReactionType.Question,
//     value: '❓',
//   },
// ];

export type ChatReaction = {
  userName: string;
  reactionType: ChatReactionType;
};

type ChatFooterReactionersProps = {
  reactions: ChatReaction[];
  onCancel: () => void;
};

const ChatFooterReactioners = (props: ChatFooterReactionersProps) => {
  const boxShadow = '0px 0px 6px -6px rgba(0, 0, 0, 0.2)';
  const { t } = useTranslation('chat');

  return (
    <Box
      width={1}
      height="300px"
      boxShadow={boxShadow}
      bgcolor={'white'}
      display="grid"
      gridTemplateRows="auto auto auto 1fr"
    >
      <Box
        mt={1}
        width={1}
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        alignItems="center"
      >
        <>
          <CancelButton
            onClick={props.onCancel}
            variant="text"
            sx={{ mr: 'auto' }}
          />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ReactionQuestionIcon width={30} height={30} />
            <Typography variant="h6">
              {`${t('reactionListTitle')} (${props.reactions.length})`}
            </Typography>
          </Stack>
        </>
      </Box>
      <Divider />
      <Box width={1} height={1} pb={1} overflow="auto" id="template-scroll">
        <List sx={{ pt: 0, px: 2, pb: 0 }}>
          {props.reactions.map((x, i) => (
            <Box key={i}>
              <ListItem sx={{ py: 1, px: 0 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="auto 1fr auto auto auto"
                  width={1}
                  height={1}
                  alignItems="center"
                >
                  {/* <Box sx={{ gridRow: '1/3', mr: 2 }}>
                    {types.find((y) => y.type == x.reactionType)?.value ?? ''}
                  </Box> */}
                  <AccountCircleIcon color="primary" fontSize="large" />
                  <Stack ml={1}>
                    <Typography
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      variant="body1"
                    >
                      {x.userName}
                    </Typography>
                  </Stack>
                </Box>
              </ListItem>
              {/* <Divider /> */}
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  );
};
export default ChatFooterReactioners;
