import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import LeaveChatRoomDialog, {
  LeaveChatRoomDialogProps,
} from '@/components/organisms/chat/LeaveChatRoomDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const ExitChatRoomDialogEx = (
  props: Omit<LeaveChatRoomDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return (
    <ChatThemeProvider>
      <LeaveChatRoomDialog open={isOpen} {...props} />
    </ChatThemeProvider>
  );
};

const useLeaveChatRoomDialog = () => {
  const openAwaiter = useOpenAwaiter<'leave' | 'exit' | 'cancel'>();
  return {
    open: async (
      props?: Omit<LeaveChatRoomDialogProps, 'open' | 'onCancel' | 'onLeave'>
    ) => {
      return await openAwaiter.open(
        <ExitChatRoomDialogEx
          isOpenKey={openAwaiter.key}
          onCancel={() => openAwaiter.close('cancel')}
          onLeave={(withExit) => openAwaiter.close(withExit ? 'exit' : 'leave')}
          {...props}
        />
      );
    },
  };
};
export default useLeaveChatRoomDialog;
