import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Fab, Stack, TextField } from '@mui/material';

import jsQR from 'jsqr';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Footer from '@/components/atoms/Footer';
import Logo from '@/components/atoms/Logo';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';

const Home = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const intervalRef = useRef<number>();
  const [cameraState, setCameraState] = useState<'off' | 'on'>('off');
  const [url, setUrl] = useState<string>('');
  const [urlError, setUrlError] = useState(false);
  const loadingBackdrop = useLoadingBackdrop();

  const stopCameraStream = useCallback(() => {
    // コンポーネントがアンマウントされたらカメラストリームを停止
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.style.display = 'none';
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      setCameraState('off');
    }
  }, []);

  const scanQRCode = useCallback(() => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (
        context &&
        videoRef.current?.videoWidth > 0 &&
        videoRef.current?.videoHeight > 0
      ) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;

        canvas.width = videoWidth;
        canvas.height = videoHeight;
        context.drawImage(videoRef.current, 0, 0);

        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code?.data != null) {
          try {
            const url = new URL(code.data);
            const pathOnly = `${url.pathname}${url.search}${url.hash}`;
            stopCameraStream();
            navigate(pathOnly);
          } catch (error) {
            console.error('Invalid URL:', error);
          }
        }
      }
    }
  }, [navigate, stopCameraStream]);

  const startCamera = useCallback(async () => {
    try {
      await loadingBackdrop.open(async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });
        await new Promise<void>((resolve, reject) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;

            // 動画がロードされたタイミングで再生を開始
            videoRef.current.onloadedmetadata = () => {
              videoRef.current
                ?.play()
                .then(() => {
                  intervalRef.current = window.setInterval(scanQRCode, 500);
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            };
          }
        });
      });
      setCameraState('on');
    } catch (e) {
      setCameraState('off');
      snackbar.enqueueSnackbar('カメラの起動に失敗しました', {
        variant: 'error',
      });
      console.error(e);
    }
  }, [loadingBackdrop, scanQRCode, snackbar]);

  useEffect(() => {
    return () => {
      stopCameraStream();
    };
  }, [stopCameraStream]);

  return (
    <>
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{
          backgroundColor: 'black',
          display: cameraState === 'on' ? 'block' : 'none',
          width: '100vw',
          height: '100dvh',
          objectFit: 'contain',
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'scaleX(1)', // 反転を解除
          zIndex: 100,
        }}
      />
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      {cameraState === 'on' && (
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 101 }}
          onClick={() => {
            stopCameraStream();
          }}
        >
          <CloseIcon />
        </Fab>
      )}
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100dvh"
      >
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="center"
          spacing={10}
          width={1}
          p={2}
        >
          <Logo />
          <Button startIcon={<CameraAltIcon />} onClick={() => startCamera()}>
            カメラを起動
          </Button>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            width={1}
          >
            <TextField
              variant="outlined"
              label="ルームURLを入力"
              onChange={(ev) => {
                setUrlError(false);
                setUrl(ev.target.value);
              }}
              sx={{ width: { lg: '400px', md: '400px', xs: 1 } }}
              error={urlError}
              helperText={urlError ? '正しいURLを入力してください' : undefined}
            />
            <Button
              onClick={() => {
                let urloptions: URL | undefined;
                try {
                  urloptions = new URL(url);
                } catch (e) {
                  setUrlError(true);
                }

                if (urloptions != null) {
                  // オリジンが一致しないリクエストははじく
                  if (window.location.origin !== urloptions.origin) {
                    setUrlError(true);
                    return;
                  } else {
                    const pathOnly = `${urloptions.pathname}${urloptions.search}${urloptions.hash}`;
                    navigate(pathOnly);
                  }
                }
              }}
            >
              参加する
            </Button>
          </Stack>
          <Button
            variant="text"
            onClick={() => {
              navigate('/admin');
            }}
          >
            アカウントをお持ちの方はこちら
          </Button>
        </Stack>
        <Footer />
      </Stack>
    </>
  );
};
export default Home;
