import { Box, SxProps, Theme } from '@mui/material';

const OnboardingVideo = (props: {
  sx?: SxProps<Theme>;
  isSimulteneous: boolean;
  step: number;
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        aspectRatio: '750 / 1048', //動画の比率に合わせる
        ...props.sx,
      }}
    >
      <Box
        sx={{
          positon: 'absolute',
          pt: '15.5%',
          pb: '18.5%',
          px: '18.6%',
          height: 1,
          width: 1,
        }}
      >
        <Box
          sx={{
            background: 'white',
            borderRadius: '9%/5.5%',
            width: 1,
            height: 1,
          }}
        ></Box>
      </Box>
      <video
        src={
          props.isSimulteneous
            ? `/assets/zyouzi_0${props.step}.mp4`
            : `/assets/tikuzi_0${props.step}.mp4`
        }
        autoPlay
        muted
        loop
        playsInline
        style={{
          width: '100%',
          // 動画に黒背景が入ってしまうのでカットする。
          clipPath: 'inset(12% 19.5% 14% 19.5% round 4%)',
          position: 'absolute',
          top: '0px',
          margin: '0px',
          pointerEvents: 'none',
        }}
      ></video>
    </Box>
  );
};
export default OnboardingVideo;
