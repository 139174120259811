import { Box } from '@mui/material';
import { sleep } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';

import ChatFooterMic from '@/components/organisms/chat/ChatFooterMic';
import ChatFooterReactioners from '@/components/organisms/chat/ChatFooterReactioners';
import ChatFooterTemplate from '@/components/organisms/chat/ChatFooterTemplate';
import ChatFooterText from '@/components/organisms/chat/ChatFooterText';
import { ChatReaction } from '@/components/organisms/chat/ChatReactionList';
import { ChatRoomsTemplateMessage } from '@/types/graphql';

const ChatFooter = (props: {
  chatRoomId: string;
  sessionEntryCode: string;
  userId: string;
  userPassword: string;
  language: string;

  isMicMode: boolean; // マイクかテキストか
  onChangeMicMode: (value: boolean) => void;
  isTemplateMode: boolean;
  onChangeTemplateMode: (value: boolean) => void;
  isReactionMode: boolean;
  onChangeReactionMode: (value: boolean) => void;
  chatText: string;
  onChangeChatText: (text: string) => void;

  onSendMessage: (text: string) => void;
  onSendTemplate: (templateId: string) => void;
  onSendImage: () => void;
  onClickTemplateMessage?: () => void;

  canUseMic: boolean; // 利用可能なマイクがあるか
  isReadyMic: boolean; // 開始する準備が出来ているか
  isSimulteneous: boolean; // 同通
  isRecording: boolean;
  onMicStart: () => void;
  onMicEnd: () => void;
  onMicCancel: () => void;

  ngWordRegExp?: RegExp;

  templateMessages: ChatRoomsTemplateMessage[];
  reactions?: ChatReaction[];
}) => {
  const [isWaitingEnd, setIsWaitingEnd] = useState(false);

  // キーボードに切り替えた際、音声入力の終了を待機するロジック
  const isReadyMic = props.isReadyMic;
  const onChangeMicMode = props.onChangeMicMode;
  useEffect(() => {
    if (isWaitingEnd && isReadyMic === true) {
      setIsWaitingEnd(false);
      onChangeMicMode(false);
      (async () => {
        await sleep(0);
        const el = document.getElementById('chat-input-text-area');
        el?.focus();
      })();
    }
  }, [isWaitingEnd, isReadyMic, onChangeMicMode]);

  return props.isTemplateMode ? (
    <ChatFooterTemplate
      messages={props.templateMessages}
      language={props.language}
      onSend={props.onSendTemplate}
      onCancel={() => props.onChangeTemplateMode(false)}
    />
  ) : props.isMicMode ? (
    <>
      {props.isReactionMode && (
        // ChatFooterMicの角丸分だけ下げる
        <Box mb="-28px" pb="28px" bgcolor="white">
          <ChatFooterReactioners
            onCancel={() => props.onChangeReactionMode(false)}
            reactions={props.reactions ?? []}
          />
        </Box>
      )}
      <ChatFooterMic
        canUseMic={props.canUseMic}
        isReadyMic={props.isReadyMic}
        isSimulteneous={props.isSimulteneous}
        isRecording={props.isRecording}
        onSendImage={props.onSendImage}
        onClickTemplateMessage={props.onClickTemplateMessage}
        onMicStart={props.onMicStart}
        onMicEnd={props.onMicEnd}
        onMicCancel={props.onMicCancel}
        onChangeToKeyboard={async () => {
          if (props.isRecording) {
            //マイク入力の終了を待機してから切り替える。
            props.onMicEnd();
            setIsWaitingEnd(true);
          } else {
            props.onChangeMicMode(false);
            await sleep(0);
            const el = document.getElementById('chat-input-text-area');
            el?.focus();
          }
        }}
      />
    </>
  ) : (
    <ChatFooterText
      text={props.chatText}
      onChangeText={props.onChangeChatText}
      onSendMessage={(text) => {
        props.onSendMessage(text);
      }}
      onSendImage={props.onSendImage}
      onChangeToMic={() => {
        props.onChangeChatText('');
        props.onChangeMicMode(true);
        if (props.isSimulteneous && props.isReadyMic) {
          props.onMicStart();
        }
      }}
    />
  );
};
export default ChatFooter;
