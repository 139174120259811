import { useEffect, useState } from 'react';

import { useAnalyticsUserCountLazyQuery } from '@/types/graphql';

// ユーザー数のデータを複数まとめてリクエストするHooks
export const useAnalyticsUserCountMulti = (props: {
  dateStart: string;
  dateEnd: string;
  ownerCompanyIds: string[];
  skip?: boolean;
}) => {
  const [query] = useAnalyticsUserCountLazyQuery();
  const [results, setResults] = useState<
    {
      ownerCompanyId: string;
      result: Awaited<ReturnType<typeof query>>;
    }[]
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    (async () => {
      try {
        if (props.ownerCompanyIds.length === 0 || props.skip) {
          setResults([]);
          return;
        }

        setLoading(true);
        const results = await Promise.all(
          props.ownerCompanyIds.map((id) =>
            query({
              variables: {
                input: {
                  ownerCompanyId: id,
                  dateRange: {
                    dateStart: props.dateStart,
                    dateEnd: props.dateEnd,
                  },
                },
              },
            })
          )
        );
        if (!didCancel) {
          setResults(
            results.map((result, i) => ({
              ownerCompanyId: props.ownerCompanyIds[i],
              result,
            }))
          );
        }
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [
    props.dateEnd,
    props.dateStart,
    props.ownerCompanyIds,
    props.skip,
    query,
  ]);

  return {
    results,
    loading,
  };
};
