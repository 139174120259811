import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import TemplateSelectorDialog, {
  TemplateSelectorDialogProps,
} from '@/components/organisms/templateSelectorDialog/TemplateSelectorDialog';
import { TemplateMessageCategory } from '@/types/graphql';

const TemplateSelectorDialogEx = (
  props: Omit<TemplateSelectorDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <TemplateSelectorDialog open={isOpen} {...props} />;
};

const useTemplateSelectorDialog = () => {
  const openAwaiter = useOpenAwaiter<string[] | undefined>();
  return {
    open: (
      props: Omit<TemplateSelectorDialogProps, 'open' | 'onClose'> & {
        templateCategories: TemplateMessageCategory[];
      }
    ) =>
      openAwaiter.open(
        <TemplateSelectorDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useTemplateSelectorDialog;
