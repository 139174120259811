import Joi from 'joi';
import { useTranslation } from 'react-i18next';

const useLocalizedJoi = () => {
  const { i18n } = useTranslation();

  const myJoi = Joi.defaults((schema) =>
    schema.options({
      messages: {
        en: {
          'string.empty': 'This is a required field',
          'string.min':
            'Length must be less than or equal to {{#limit}} characters long',
          'string.max':
            'Length must be more than or equal to {{#limit}} characters long',
          // 'string.email': 'Email address format is incorrect',
          'string.length': 'Must be {{#limit}} digits',
          'string.pattern.base': 'Please enter a number',
          'number.base': 'Please enter a number',
          'number.min': 'You cannot enter a value less than {{#limit}}',
          'number.max': 'You cannot enter a value more than {{#limit}}',
          'any.only': 'You cannot specify anything other than {{#valids}}',
          // 'date.greater': 'Must be on or after the publication start date',
        },
        ja: {
          'string.empty': '入力必須の項目です',
          'string.min': '{{#limit}}文字未満の文字列は入力できません',
          'string.max': '{{#limit}}文字を超える文字列は入力できません',
          'string.email': 'メールアドレスの形式が正しくありません',
          'string.length': '{{#limit}}桁である必要があります',
          'string.pattern.base': '数字を入力してください',
          'string.pattern.name': '半角英数字記号以外は入力できません',
          'number.base': '数値を入力してください',
          'number.min': '{{#limit}}未満の値は入力できません',
          'number.max': '{{#limit}}を超える値は入力できません',
          'any.only': '{{#valids}}以外は指定できません',
          'date.greater': '掲載開始日以降である必要があります',
        },
      } as any,
      errors: {
        language: i18n.language === 'ja' ? 'ja' : 'en',
      },
    })
  );

  return myJoi;
};

export default useLocalizedJoi;
