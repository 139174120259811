import { joiResolver } from '@hookform/resolvers/joi';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Typography, TextField, Stack, Button } from '@mui/material';
import { assertNotNull, sleep } from '@remote-voice/utilities';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Footer from '@/components/atoms/Footer';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import Logo from '@/components/atoms/Logo';
import useLanguage, { useSetLanguage } from '@/components/hooks/useLanguage';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { useSetUserName, useUserName } from '@/components/hooks/useUserName';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher';
import useAudioInputTestDialog from '@/components/organisms/audioTest/useAudioInputTestDialog';
import useAudioOutputTestDialog from '@/components/organisms/audioTest/useAudioOutputTestDialog';
import useAudioTestResultDialog from '@/components/organisms/audioTest/useAudioTestResultDialog';
import { useChatroomsWaitingGuestsEnter } from '@/components/organisms/chat/useChatroomsWaitingGuestsEnter';
import useWaitingChatSessionDialog from '@/components/organisms/chat/useWaitingChatSessionDialog';
import ConfirmPolicyDialog from '@/components/organisms/confirmPolicyDialog/ConfirmPolicyDialog';
import useOnboardingDialog from '@/components/organisms/onboardingDialog/useOnboardingDialog';
import {
  ChatSessionState,
  TranslateMethod,
  useChatSessionInfoLazyQuery,
  useChatSessionInfoQuery,
  useNotificationsQuery,
  useNotifyJoinMutation,
  useStartChatSessionMutation,
  useUserSettingsQuery,
} from '@/types/graphql';
import { getCookieOrSetDefault } from '@/utils/cookie';

type ChatLoginInput = {
  userName: string;
  sessionEntryCode?: string;
};

const ChatLogin = () => {
  const { t } = useTranslation('chat');
  const { t: tCommon } = useTranslation('common');

  useEffect(() => {
    // このコンポーネントの中では直接使わないが，一度コールしてHowlを有効化する
    // 以下の行がないとチャット画面をリロードした際にWeb Audio APIのセキュリティを突破できない
    new Howl({
      src: ['/sample_audio.mp3'],
      onunlock: () => console.log('audio unlocked'),
    });
  }, []);

  const showSnackbar = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chatRoomId = params.chatRoomId ?? '';

  const language = useLanguage();
  const setLanguage = useSetLanguage();

  const userName = useUserName();
  const setUserName = useSetUserName();
  const paramChatUserId = searchParams.get('chatUserId');
  const paramChatUserPassword = searchParams.get('chatUserPassword');
  const isGuide = paramChatUserId != null && paramChatUserPassword != null;

  const audioInputTestDialog = useAudioInputTestDialog();
  const audioOutputTestDialog = useAudioOutputTestDialog();
  const audioTestResultDialog = useAudioTestResultDialog();
  const loadingBackdrop = useLoadingBackdrop();
  const onboardingDialog = useOnboardingDialog();

  // ガイド管理者の場合ゲストの待機室入室時の通知を購読
  useChatroomsWaitingGuestsEnter({});

  // ポリシーに同意済みかどうか（ガイドなら無条件でTrue）
  const [policyAgreed, setPolicyAgreed] = useState(true /*isGuide*/);

  // 空欄パスワードで情報取得が NOT_FOUND 以外であれば空欄OKということ
  const emptyCodeChatSessionInfo = useChatSessionInfoQuery({
    variables: {
      input: {
        chatRoomId: chatRoomId,
        sessionEntryCode: '',
      },
    },
  });

  const emptyCodeChatSessionState =
    emptyCodeChatSessionInfo.data?.chatSessionInfo.state;
  const entryCodeIsEmpty =
    emptyCodeChatSessionState === 'NOT_STARTED' ||
    emptyCodeChatSessionState === 'PROGRESSING';
  const isSimulteneous =
    emptyCodeChatSessionInfo.data?.chatSessionInfo.translateMethod ===
    TranslateMethod.Simulteneous;

  const notifications = useNotificationsQuery({
    variables: { input: { chatRoomId, filterOnlyEnabled: true } },
  });

  const [notifyJoin] = useNotifyJoinMutation();

  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ChatLoginInput>({
    defaultValues: { userName: userName }, // ガイド管理者など、予めユーザー名が設定されている場合はそれをデフォルトにする
    resolver: joiResolver(
      joi.object<ChatLoginInput>({
        userName: joi.string().trim().required().min(1).max(100),
        sessionEntryCode:
          isGuide === false && entryCodeIsEmpty === false
            ? joi.string().required().length(4).regex(/^\d+$/)
            : joi.any(),
      })
    ),
  });
  {
    // ガイド管理者の場合は、DBに設定されているユーザー名をデフォルトにするための処理
    const userSettings = useUserSettingsQuery({
      skip: useUserPermission().isTenantCompanyAdmin === false,
    });
    useEffect(() => {
      const name = userSettings.data?.userSettings.name;
      if (name != null) {
        setValue('userName', name);
      }
    }, [setValue, userSettings.data?.userSettings.name]);
  }

  const [chatSessionInfo] = useChatSessionInfoLazyQuery();
  const [startChatSession] = useStartChatSessionMutation();
  const waitingChatSessionDialog = useWaitingChatSessionDialog();

  // 入室コードの検証と、セッションの取得を行う
  const validateAndGetSession = useCallback(
    async (sessionEntryCode?: string) => {
      const result = await loadingBackdrop.open(() =>
        chatSessionInfo({
          variables: {
            input: {
              chatRoomId,
              sessionEntryCode: sessionEntryCode ?? '',
              chatUserId: paramChatUserId,
              chatUserPassword: paramChatUserPassword,
            },
          },
        })
      );
      if (result.error) throw result.error;
      assertNotNull(result.data);
      const sessionState = result.data.chatSessionInfo.state;
      if (sessionState === ChatSessionState.NotFound) {
        showSnackbar('error', t('unloginableMsg'));
        return null;
      }

      return result;
    },
    [
      chatRoomId,
      chatSessionInfo,
      loadingBackdrop,
      paramChatUserId,
      paramChatUserPassword,
      showSnackbar,
      t,
    ]
  );

  const chatLogin = useCallback(
    async (input: ChatLoginInput) => {
      const session = await validateAndGetSession(input.sessionEntryCode);
      if (session == null) return;
      assertNotNull(session.data);

      // 参加者の場合通知の許可をここで取る
      if (
        'Notification' in window &&
        !isGuide &&
        session.data.chatSessionInfo.isNotifyOnMessageEnabled &&
        Notification.permission === 'default'
      ) {
        Notification.requestPermission();
      }

      // 入室コードとニックネームを保存
      const sessionId = session.data.chatSessionInfo.sessionId;
      const entryCode = session.data.chatSessionInfo.entryCode;
      Cookies.set('RV_SESSION_PASS_' + sessionId, entryCode, { expires: 400 });
      setUserName(input.userName);

      // ユーザーIDとパスワードをクッキーに保存
      const chatUserId =
        paramChatUserId ??
        getCookieOrSetDefault('RV_DEFAULT_USER_ID', uuidv4());
      Cookies.set('RV_USER_ID', chatUserId, { expires: 400 });
      const chatUserPassword =
        paramChatUserPassword ??
        getCookieOrSetDefault('RV_DEFAULT_USER_PASS', uuidv4());
      Cookies.set('RV_USER_PASS', chatUserPassword, { expires: 400 });

      // オンボーディング画面
      await onboardingDialog.open({
        isSimulteneous:
          session.data.chatSessionInfo.translateMethod ===
          TranslateMethod.Simulteneous,
      });

      // デバイスチェック画面
      let isTestOk = false;
      while (isTestOk === false) {
        const isOutputOk = await audioOutputTestDialog.open();
        const isInputOk = await audioInputTestDialog.open();
        if (
          await audioTestResultDialog.open({
            isOutputOk,
            isInputOk,
          })
        ) {
          isTestOk = true;
          // デバイス利用可能状況をクッキーに保存
          Cookies.set('RV_CAN_USE_MIC', `${isInputOk}`, { expires: 400 });
          Cookies.set('RV_CAN_USE_SPEAKER', `${isOutputOk}`, { expires: 400 });
        }
      }

      // 開始していなければ待機画面を表示。ゲストの場合、人数制限のチェックのために画面を表示
      if (
        session.data.chatSessionInfo.state === ChatSessionState.NotStarted ||
        isGuide === false
      ) {
        const joinResult = await waitingChatSessionDialog.open({
          chatRoomId,
          sessionEntryCode: entryCode,
          chatUserId: chatUserId,
          chatUserPassword: chatUserPassword,
          userName: input.userName,
          language: language,
          isGuide,
        });

        if (joinResult === 'cancel') {
          return;
        } else if (joinResult === 'start') {
          // 待機画面表示中にセッションステートが変更されている可能性があるため再フェッチ
          const session = await validateAndGetSession(input.sessionEntryCode);
          if (session == null) return;
          assertNotNull(session.data);

          if (
            session.data.chatSessionInfo.state === ChatSessionState.NotStarted
          ) {
            const startResult = await loadingBackdrop.open(() =>
              startChatSession({
                variables: {
                  input: {
                    chatSessionId: sessionId,
                    sessionEntryCode: entryCode,
                    userId: chatUserId,
                    userPassword: chatUserPassword,
                  },
                },
                onError: (error) => {
                  // 上限に達しているエラーかをチェック
                  if (
                    error.graphQLErrors.find(
                      (x) => (x.extensions as any).code === 'MAX_TENANT_SESSION'
                    )
                  ) {
                    showSnackbar('error', t('maxNumberOfPeople'));
                    return;
                  } else {
                    throw error;
                  }
                },
              })
            );

            if (startResult.errors) {
              return; // エラーが発生していたら処理を抜ける
            }
          }
        }

        //すぐに切り替えると 参加→退出 の順になってしまい、参加の表示が出なくなってしまうので、少し待機
        await loadingBackdrop.open(() => sleep(500));
      }

      // チャット画面に移動
      await notifyJoin({
        variables: {
          input: {
            chatRoomId: chatRoomId,
            sessionEntryCode: entryCode,
            userId: chatUserId,
            userPassword: chatUserPassword,
          },
        },
      });
      navigate(sessionId);
    },
    [
      audioInputTestDialog,
      audioOutputTestDialog,
      audioTestResultDialog,
      chatRoomId,
      isGuide,
      language,
      loadingBackdrop,
      navigate,
      notifyJoin,
      onboardingDialog,
      paramChatUserId,
      paramChatUserPassword,
      setUserName,
      showSnackbar,
      startChatSession,
      t,
      validateAndGetSession,
      waitingChatSessionDialog,
    ]
  );

  return (
    <>
      <ConfirmPolicyDialog
        open={policyAgreed === false}
        onClose={() => setPolicyAgreed(true)}
      />

      <LoadingBackdrop open={emptyCodeChatSessionInfo.loading} />
      {emptyCodeChatSessionInfo.loading === false && (
        <Stack
          alignItems="center"
          justifyContent={'center'}
          sx={{
            minHeight: '100%',
            width: '100%',
          }}
          spacing={2}
        >
          <Logo sx={{ width: 1, alignItems: 'flex-start', p: 3, pb: 4 }} />

          <Stack width="100%" flex={1} spacing={4} p={2}>
            {isGuide && (
              <Stack
                width="100%"
                textAlign="left"
                py={1}
                px={2}
                borderRadius={3}
                sx={{ backgroundColor: '#FEF4D1' }}
                spacing={1}
                direction="row"
                alignItems="center"
              >
                <RecordVoiceOverIcon
                  sx={{ color: (t) => t.palette.grey[700] }}
                />
                <Typography variant="body1">
                  {isSimulteneous
                    ? t('translateModeIsSimulteneous')
                    : t('translateModeIsConsecutive')}
                </Typography>
              </Stack>
            )}

            <Stack
              width="100%"
              alignItems="center"
              component={'form'}
              spacing={3}
              onSubmit={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  /**
                   * iOSのバグ対応
                   * キーボードが格納されるタイミングでモーダルが表示されると
                   * 位置がズレてしまうため，遅延処理を入れる
                   */
                  handleSubmit(chatLogin)();
                }, 800);
              }}
            >
              <TextField
                {...register('userName')}
                fullWidth
                label={t('userName')}
                error={'userName' in errors}
                helperText={errors.userName?.message}
                InputLabelProps={{ shrink: !!watch('userName') }}
                autoComplete="off"
              />
              <LanguageSwitcher language={language} setLanguage={setLanguage} />
              {isGuide === false &&
              entryCodeIsEmpty === false &&
              emptyCodeChatSessionInfo.loading === false ? (
                <TextField
                  {...register('sessionEntryCode')}
                  label={tCommon('caption.entryCode')}
                  fullWidth
                  error={'sessionEntryCode' in errors}
                  helperText={errors.sessionEntryCode?.message}
                  InputLabelProps={{ shrink: !!watch('sessionEntryCode') }}
                  inputProps={{ inputMode: 'numeric' }}
                  autoComplete="off"
                />
              ) : (
                <input type="hidden" {...register('sessionEntryCode')} />
              )}
              <Button
                sx={{ width: '240px' }}
                size="large"
                type="submit"
                disabled={!watch('userName')}
              >
                {t('join')}
              </Button>
            </Stack>

            <Stack
              width="100%"
              textAlign="left"
              p={2}
              borderRadius={3}
              sx={{ backgroundColor: (t) => t.palette.background.default }}
              spacing={2}
            >
              <Typography variant="subtitle1">{t('news')}</Typography>
              {notifications.data?.notifications.map((x) => (
                <Typography
                  key={x.id}
                  variant="body2"
                  sx={{ whiteSpace: 'pre-line' }}
                >
                  {x.message}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Footer />
        </Stack>
      )}
    </>
  );
};
export default ChatLogin;
