import {
  Dialog,
  Typography,
  Box,
  Stack,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';

const AboutVadDialog = (props: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation('admin');
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('vadSetting.aboutParameter')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} my={2}>
          <Box>
            <Typography>Smoothing TimeConstant</Typography>
            <Typography>{t('vadSetting.smoothingTimeConstant')}</Typography>
          </Box>
          <Box>
            <Typography>Energy Offset</Typography>
            <Typography>{t('vadSetting.energyOffset')}</Typography>
          </Box>
          <Box>
            <Typography>Energy Threshold Ratio Pos</Typography>
            <Typography>{t('vadSetting.energyThresholdRatioPos')}</Typography>
          </Box>
          <Box>
            <Typography>Energy Threshold Ratio Neg</Typography>
            <Typography>{t('vadSetting.energyThresholdRatioNeg')}</Typography>
          </Box>
          <Box>
            <Typography>Energy Integration</Typography>
            <Typography>{t('vadSetting.energyIntegration')}</Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CancelButton onClick={() => props.onClose()} />
      </DialogActions>
    </Dialog>
  );
};
export default AboutVadDialog;
