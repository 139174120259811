import Cookies from 'js-cookie';
import { useRef, useState } from 'react';

import {
  ChatSessionUser,
  useChatSessionUserModifiedSubscription,
} from '@/types/graphql';

const useChatUsers = (options: {
  variables: {
    chatRoomId: string;
    sessionEntryCode: string;
    userId: string;
    userPassword: string;
    userName: string;
    language: string;
  };
  onMaxUser?: () => void;
  skip?: boolean;
}) => {
  const [users, setUsers] = useState<ChatSessionUser[]>([]);
  const usersRef = useRef<ChatSessionUser[]>([]);

  const result = useChatSessionUserModifiedSubscription({
    variables: {
      input: {
        ...options.variables,
        canUseMic: Cookies.get('RV_CAN_USE_MIC') === 'true',
        canUseSpeaker: Cookies.get('RV_CAN_USE_SPEAKER') === 'true',
      },
    },
    skip: options.skip,
    onData: (options) => {
      // ユーザー変更が通知された
      const modifiedUsers = options.data.data?.chatSessionUserModified;
      if (modifiedUsers) {
        // onDataが高速に呼ばれた場合？useStateの値が間に合っていないことがある。
        // 完全な解決方法はまだ不明だが、useRefを使えば問題ない。
        const newUsers = usersRef.current.concat();
        for (const u of modifiedUsers) {
          const existIndex = newUsers.findIndex((x) => x.userId === u.userId);
          if (existIndex === -1) {
            newUsers.push(u);
          } else {
            newUsers[existIndex] = u;
          }
        }
        usersRef.current = newUsers;
        setUsers(newUsers);
      }
    },
    onError(error) {
      if (
        error.graphQLErrors?.find(
          (x) => (x.extensions as any)?.code === 'MAX_SESSION_USER'
        )
      ) {
        // 人数が上限。
        options.onMaxUser?.();
      } else {
        throw error;
      }
    },
  });

  return { users, loading: result.loading };
};

export default useChatUsers;
