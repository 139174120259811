// 現在のところモック実装です

import { joiResolver } from '@hookform/resolvers/joi';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from '@/components/atoms/Logo';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';

const ResetPasswordRequest = () => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();
  const width = 240;

  const joi = useLocalizedJoi();
  const form = useForm<{ email: string }>({
    resolver: joiResolver(
      joi.object<{ email: string }>({
        email: joi.string().required(),
      })
    ),
  });

  const [sendedEmail, setSendedEmail] = useState(false);

  return (
    <Box sx={{ w: 1, h: 1, bgcolor: (t) => t.palette.background.default }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        component="form"
        onSubmit={form.handleSubmit(async (input, ev) => {
          ev?.preventDefault();
          // APIを叩く
          console.debug('send email address', {
            email: input.email,
          });
        })}
      >
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Stack alignItems="center" width={1}>
            <Logo />
          </Stack>
          <Stack
            alignItems="center"
            spacing={2}
            sx={{ bgcolor: (t) => t.palette.background.paper }}
            py={3}
            px={3}
            minWidth={400}
          >
            {sendedEmail === false ? (
              <>
                <Typography variant="h6">
                  {t('myPasswordReset.title')}
                </Typography>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {t('myPasswordReset.caption1')}
                  </Typography>
                  <Typography variant="body2">
                    {t('myPasswordReset.caption2')}
                  </Typography>
                </Stack>
                <TextField
                  fullWidth
                  label={t('myPasswordReset.email')}
                  {...form.register('email')}
                  error={'email' in form.formState.errors}
                  helperText={form.formState.errors.email?.message}
                  autoComplete="off"
                  sx={{ width }}
                  // InputLabelProps={{ shrink: !!form.watch('userId') }}
                />

                <Stack spacing={1} width={width}>
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={(form.watch('email') ?? '') == ''}
                    sx={{ mt: 2, width }}
                    onClick={() => {
                      setSendedEmail(true);
                    }}
                  >
                    {t('myPasswordReset.sendEmail')}
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Stack direction="row" spacing={1}>
                  <CheckCircleOutlineIcon color="success" />
                  <Typography variant="h6">
                    {t('myPasswordReset.sendEmailSuccess')}
                  </Typography>
                </Stack>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {t('myPasswordReset.sendEmailSuccessCaption1')}
                  </Typography>
                  <Typography variant="body2">
                    {t('myPasswordReset.sendEmailSuccessCaption2')}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>

          <Stack alignItems="center" width={1}>
            <Button
              variant="text"
              size="small"
              sx={{ width }}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => {
                navigate('/adminLogin');
              }}
            >
              {t('passwordReset.backToLogin')}
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ mt: 'auto', mb: 2, position: 'relative', bottom: 0 }}>
          <Typography variant="caption" color={(t) => t.palette.grey[500]}>
            Copyright © 2023 TOPPAN INC.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
export default ResetPasswordRequest;
