import { DataGridPro, DataGridProProps, jaJP } from '@mui/x-data-grid-pro';

import { useTranslation } from 'react-i18next';

const MyDataGrid = (props: DataGridProProps) => {
  const { i18n } = useTranslation();

  return (
    <DataGridPro
      localeText={
        i18n.language === 'ja'
          ? jaJP.components.MuiDataGrid.defaultProps.localeText
          : undefined
      }
      {...props}
    />
  );
};

export default MyDataGrid;
