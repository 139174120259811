import { assertNotNull } from '@remote-voice/utilities';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  OwnerCompanyData,
  OwnerForm,
} from '../../../components/molecules/admin/OwnerForm';
import {
  useEditOwnerCompanyMutation,
  useOwnerCompaniesQuery,
} from '../../../types/graphql';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';

const OnwerEdit = () => {
  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.ownerAdmin'));

  useCheckPermission({ superAdmin: true });
  const params = useParams();
  const ownerId = params.ownerId;

  const [editOwnerCompany, editOwnerCompanyResult] =
    useEditOwnerCompanyMutation();
  const navigate = useNavigate();

  const snackbar = useSnackbar();

  // 初期値
  const ownerCompanyResult = useOwnerCompaniesQuery({
    variables: { input: { id: ownerId } },
  });

  const data = ownerCompanyResult.data?.ownerCompanies[0];

  return (
    <OwnerForm
      loading={ownerCompanyResult.loading || editOwnerCompanyResult.loading}
      onSubmit={async (input: OwnerCompanyData) => {
        assertNotNull(ownerId);

        const result = (
          await editOwnerCompany({
            variables: {
              input: {
                id: ownerId,
                code: input.code,
                roomLimit: input.roomLimit,
                userLimit: input.userLimit,
                userName:
                  data?.userName === input.userName
                    ? undefined
                    : input.userName,
                newPassword:
                  input.temporaryPassword === ''
                    ? undefined
                    : input.temporaryPassword,
                name: input.name,
                canUseSimulteneous: input.canUseSimulteneous,
                note: input.note,
              },
            },
          })
        ).data?.editOwnerCompany;

        if (result?.__typename === 'UserNameAlreadyExists') {
          return [
            {
              type: 'UserNameAlreadyExists',
              message: result.message,
            },
          ];
        } else {
          navigate('/admin/owner');
          snackbar('success', '変更しました ');
        }
      }}
      defaultData={useMemo(
        () => ({
          id: data?.id ?? '',
          code: data?.code ?? '',
          userName: data?.userName ?? '',
          temporaryPassword: '',
          name: data?.name ?? '',
          roomLimit: data?.roomLimit ?? 0,
          canUseSimulteneous: data?.canUseSimulteneous ?? false,
          userLimit: data?.userLimit ?? 0,
          note: data?.note ?? undefined,
        }),
        [
          data?.userName,
          data?.canUseSimulteneous,
          data?.code,
          data?.id,
          data?.name,
          data?.note,
          data?.roomLimit,
          data?.userLimit,
        ]
      )}
    />
  );
};
export default OnwerEdit;
