import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';

export type ConfirmDialogProps = {
  open: boolean;
  title: string;
  message?: string;
  actions: ReactElement[];
  onClose?: () => void;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, title, message, actions } = { ...props };
  return (
    <Dialog open={open} onClose={props.onClose}>
      {title != null && <DialogTitle>{title}</DialogTitle>}
      {message != null && (
        <DialogContent>
          <Typography textAlign="center">{message}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          {actions}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
