import { assertNotNull, validLanguages } from '@remote-voice/utilities';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import {
  TemplateForm,
  TemplateMessageData,
} from '@/components/molecules/admin/TemplateForm';
import {
  File,
  useAddFilesMutation,
  useEditTemplateMessageMutation,
  useTemplateMessagesQuery,
  useTranslateMessageLazyQuery,
} from '@/types/graphql';

const TemplateEdit = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t } = useTranslation('admin');
  const { t: commonT, i18n } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.templateAdmin'));

  const userLang = i18n.language;
  const navigate = useNavigate();
  const params = useParams();
  const showSnackbar = useSnackbar();
  const templateId = params.templateId;
  assertNotNull(templateId);

  const [addFiles, addFilesResult] = useAddFilesMutation();
  const [translateMessage, translateMessageResult] =
    useTranslateMessageLazyQuery();
  const [editTemplateMessage, editTemplateMessageResult] =
    useEditTemplateMessageMutation();

  const templateMessages = useTemplateMessagesQuery({
    variables: { input: { id: templateId } },
  });
  const defaultItem = templateMessages.data?.templateMessages[0];
  const sourceMessage =
    defaultItem?.languages.find((x) => x.language === userLang)?.message ?? '';

  const [defaultItemWithRevMsg, setDefaultItemWithRevMsg] =
    useState<TemplateMessageData>();

  useEffect(() => {
    let unmounted = true;

    if (defaultItem == null) return;

    // 逆翻訳の読込前でも画面表示させたいのでいったんステートにセット
    const item = {
      message: sourceMessage,
      templateCategoryId: defaultItem.category.id,
      languages: validLanguages.map((l) => {
        const item = defaultItem.languages.find((x) => x.language === l);
        return {
          language: l,
          message: item?.message ?? '',
          readonly: item?.readonly ?? false,
          reverseMessage: '',
        };
      }),
      attachedFiles: defaultItem.files,
    };
    setDefaultItemWithRevMsg(item);

    // 逆翻訳結果を取得
    (async () => {
      const foreignLangs = item.languages.filter(
        (x) => x.language !== userLang
      );
      for (let i = 0; i < foreignLangs.length; i++) {
        const x = foreignLangs[i];
        // 翻訳実行
        const result = await translateMessage({
          variables: {
            input: {
              language: x.language,
              message: x.message,
              targetLanguages: [userLang],
            },
          },
        });
        // 翻訳結果のセット
        const revMsg = item.languages.find((y) => x.language === y.language);
        assertNotNull(revMsg);
        revMsg.reverseMessage =
          result.data?.translateMessage.find((y) => y.language === userLang)
            ?.message ?? '';
      }

      // 逆翻訳結果をセットして、フォームのデフォルト値とする
      if (unmounted) setDefaultItemWithRevMsg({ ...item });
    })();

    return () => {
      unmounted = false;
    };
  }, [defaultItem, sourceMessage, translateMessage, userLang]);

  return (
    <Fragment>
      <LoadingBackdrop
        open={
          translateMessageResult.loading ||
          editTemplateMessageResult.loading ||
          templateMessages.loading ||
          addFilesResult.loading
        }
      />
      <TemplateForm
        onSubmit={async (input, options) => {
          // 追加するファイル
          assertNotNull(defaultItem);
          const newFiles = input.attachedFiles?.filter((f) => f.id == null);
          let uploadedFiles: File[] = [];
          if (newFiles != null && newFiles.length >= 1) {
            const filesResult = await addFiles({
              variables: {
                input: {
                  files: newFiles.map((x) => ({
                    fileName: x.fileName,
                    fileType: x.fileType,
                  })),
                },
              },
            });
            if (filesResult.errors) throw filesResult.errors;
            const addFilesData = filesResult.data?.addFiles;
            assertNotNull(addFilesData);

            uploadedFiles = await Promise.all(
              addFilesData.map(async (file, i) => {
                // ファイルアップロード
                await axios.put(file.signedURL, newFiles[i].fileData, {
                  headers: { 'Content-Type': file.fileType },
                });
                return file;
              })
            );
          }

          await editTemplateMessage({
            variables: {
              input: {
                id: templateId,
                templateCategoryId: input.templateCategoryId,
                languages: input.languages.map((x) => ({
                  language: x.language,
                  message: x.message,
                  readonly: x.readonly,
                })),
                files: input.attachedFiles
                  .filter((f) => f.id != null)
                  .concat(uploadedFiles)
                  .map((f) => ({
                    fileName: f.fileName,
                    fileType: f.fileType,
                    id: f.id ?? '',
                  })),
              },
            },
          });

          navigate('/admin/template');
          showSnackbar(
            'success',
            commonT('message.registered') +
              (options.isReplacedBreak ? t('template.replacedBreak') : '')
          );
        }}
        defaultData={defaultItemWithRevMsg ? defaultItemWithRevMsg : undefined}
        loading={
          addFilesResult.loading ||
          translateMessageResult.loading ||
          editTemplateMessageResult.loading ||
          templateMessages.loading
        }
      />
    </Fragment>
  );
};
export default TemplateEdit;
