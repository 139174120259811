import { joiResolver } from '@hookform/resolvers/joi';
import { Box, TextField, Typography, Button, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminContent, AdminInnerBox } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { NGWordCategorySelector } from '@/components/molecules/admin/NGWordCategorySelector';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';

export type NGWordData = {
  ngWordCategoryId: string;
  word: string;
};

const NGWordForm = (props: {
  loading: boolean;
  onSubmit: (input: NGWordData) => Promise<void>;
  defaultData?: NGWordData; // 編集のみ
}) => {
  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
  } = useForm<NGWordData>({
    defaultValues: props.defaultData,
    resolver: joiResolver(
      joi.object<NGWordData>({
        ngWordCategoryId: joi.string().required(),
        word: joi.string().required().max(20),
      })
    ),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (props.defaultData != null) reset(props.defaultData);
  }, [props.defaultData, reset, setValue]);

  return (
    <Box>
      <LoadingBackdrop open={props.loading} />
      <BreadcrumbBar keyFrom="/admin/ngword" />
      <AdminContent>
        <AdminInnerBox>
          <Typography variant="h4">
            {props.defaultData ? '編集' : '新規登録'}
          </Typography>
        </AdminInnerBox>
        <AdminInnerBox sx={{ bgcolor: (t) => t.palette.common.white }}>
          <Box
            component={'form'}
            onSubmit={handleSubmit((input, ev) => {
              ev?.preventDefault();
              props.onSubmit(input);
            })}
          >
            <Stack
              sx={{ pt: 3, pb: 2, mx: 'auto', maxWidth: '320px' }}
              spacing={2}
            >
              <Controller
                control={control}
                name={'ngWordCategoryId'}
                render={({ field }) => (
                  <NGWordCategorySelector
                    belongedCategoryId={
                      props.defaultData?.ngWordCategoryId ?? ''
                    }
                    field={field}
                    register={register}
                    errors={errors}
                  />
                )}
              />

              <TextField
                multiline
                rows={1}
                {...register('word')}
                sx={{ width: 1 }}
                placeholder="NGワード"
                error={'word' in errors}
                helperText={errors.word?.message}
                size="small"
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
              sx={{ my: 3 }}
            >
              <CancelButton onClick={() => navigate('/admin/ngword')}>
                キャンセル
              </CancelButton>
              <Button type="submit" disabled={!isDirty}>
                {props.defaultData ? '登録内容を保存する' : '登録する'}
              </Button>
            </Stack>
          </Box>
        </AdminInnerBox>
      </AdminContent>
    </Box>
  );
};
export default NGWordForm;
