import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent, AdminInnerBox } from '../../atoms/AdminPageParts';

import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { PositiveIntegerField } from '@/components/atoms/PositiveIntegerField';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { PasswordPolicyButton } from '@/components/molecules/PasswordPolicyButton';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import { getPasswordValidator } from '@/utils/joi';

export type OwnerCompanyData = {
  id: string;
  userName: string;
  temporaryPassword: string;
  code: string;
  name: string;
  userLimit: number;
  roomLimit: number;
  canUseSimulteneous: boolean;
  note?: string;
};

export type OwnerCompanyError = {
  type: 'UserNameAlreadyExists';
  message: string;
};

export const OwnerForm = (props: {
  loading: boolean;
  onSubmit: (input: OwnerCompanyData) => Promise<OwnerCompanyError[] | void>;
  defaultData?: OwnerCompanyData; // 新規登録は defaultDataなし
  children?: ReactElement;
  requirePassword?: boolean;
}) => {
  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
    reset,
    watch,
    control,
  } = useForm<OwnerCompanyData>({
    defaultValues: { canUseSimulteneous: false, ...props.defaultData },
    resolver: joiResolver(
      joi.object<OwnerCompanyData>({
        name: joi.string().trim().max(50).required(),
        code: joi.string().trim().max(50).required(),
        userName: joi
          .string()
          .trim()
          .max(50)
          .required()
          .pattern(/^[ -~]+$/, 'username'),
        temporaryPassword: props.requirePassword
          ? getPasswordValidator(joi).required()
          : getPasswordValidator(joi).allow(''),
        userLimit: joi.number().min(0).max(100).required(),
        roomLimit: joi.number().min(0).max(100).required(),
        canUseSimulteneous: joi.boolean().required(),
        note: joi.string().allow('').max(200),
      })
    ),
  });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.defaultData) {
      return;
    }
    const defaultData: Omit<OwnerCompanyData, 'id'> = {
      userName: props.defaultData.userName,
      temporaryPassword: props.defaultData.temporaryPassword,
      name: props.defaultData.name,
      code: props.defaultData.code,
      roomLimit: props.defaultData.roomLimit,
      canUseSimulteneous: props.defaultData.canUseSimulteneous,
      userLimit: props.defaultData.userLimit,
      note: props.defaultData.note,
    };
    reset(defaultData);
  }, [props.defaultData, reset, setValue]);

  return (
    <Box>
      <LoadingBackdrop open={props.loading} />
      <BreadcrumbBar keyFrom="/admin/owner" />
      <AdminContent>
        <AdminInnerBox>
          {props.defaultData ? (
            <Typography variant="h4">{`${props.defaultData.name} ${tCommon(
              'command.edit'
            )}`}</Typography>
          ) : (
            <Typography variant="h4">{tCommon('command.new')}</Typography>
          )}
        </AdminInnerBox>
        <AdminInnerBox
          component="form"
          onSubmit={handleSubmit(async (input, ev) => {
            ev?.preventDefault();
            const errors = await props.onSubmit(input);
            if (errors != null) {
              errors.forEach((e) => {
                if (e.type === 'UserNameAlreadyExists') {
                  setError('userName', {
                    message: 'ユーザー名は既に存在します',
                  });
                }
              });
            }
          })}
          sx={{ bgcolor: (t) => t.palette.common.white }}
        >
          <Stack sx={{ maxWidth: '320px', mx: 'auto', mt: 4 }} spacing={3}>
            {/* 企業コード */}
            <TextField
              {...register('code')}
              label={t('company.companyCode')}
              size="small"
              error={'code' in errors}
              helperText={errors.code?.message}
              InputLabelProps={{ shrink: !!watch('code') }}
            />
            {/* 企業名 */}
            <TextField
              {...register('name')}
              label={t('company.companyName')}
              error={'name' in errors}
              helperText={errors.name?.message}
              size="small"
              InputLabelProps={{ shrink: !!watch('name') }}
            />
            {/* ユーザー名 */}
            <TextField
              size="small"
              {...register('userName')}
              label={t('company.email')}
              error={'userName' in errors}
              helperText={errors.userName?.message}
              InputLabelProps={{ shrink: !!watch('userName') }}
            />
            {/* ユーザー一時パスワード */}
            <TextField
              type="password"
              size="small"
              {...register('temporaryPassword')}
              label={t('company.tempraryPassword')}
              error={'temporaryPassword' in errors}
              helperText={errors.temporaryPassword?.message}
              InputLabelProps={{ shrink: !!watch('temporaryPassword') }}
              InputProps={{
                endAdornment: <PasswordPolicyButton />,
              }}
            />
            {/* 登録ユーザ数上限 */}
            <Stack spacing={1}>
              <PositiveIntegerField
                {...register('userLimit')}
                size="small"
                label={t('company.userLimit')}
                helperText={errors.userLimit?.message}
                error={'userLimit' in errors}
              />
              <Typography variant="caption">
                {t('common.0forUnlimited')}
              </Typography>
            </Stack>
            {/* アクティブルーム数 */}
            <Stack spacing={1}>
              <PositiveIntegerField
                {...register('roomLimit')}
                size="small"
                label={t('company.roomLimit')}
                helperText={errors.roomLimit?.message}
                error={'roomLimit' in errors}
              />
              <Typography variant="caption">
                {t('common.0forUnlimited')}
              </Typography>
            </Stack>
            {/* 翻訳方式 */}
            <Controller
              control={control}
              name="canUseSimulteneous"
              render={({ field }) => (
                <FormControl>
                  <FormLabel>
                    <Typography variant="subtitle2">
                      {t('translate.translateMethod')}
                    </Typography>
                  </FormLabel>
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={t('translate.consecutive')}
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={t('translate.simulteneous')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
            {/* 備考 */}
            <TextField
              {...register('note')}
              label={`${t('common.note')}${t('common.optional')}`}
              size="small"
              multiline
              minRows={3}
              error={'note' in errors}
              helperText={errors.note?.message}
              InputLabelProps={{ shrink: !!watch('note') }}
            />
          </Stack>

          <Stack direction={'row'} justifyContent={'center'} sx={{ py: 3 }}>
            <CancelButton
              onClick={() => navigate('/admin/owner')}
              sx={{ mx: 'auto', display: 'block', m: 1 }}
            />
            <Button
              type="submit"
              sx={{ mx: 'auto', display: 'block', m: 1, width: '260px' }}
              disabled={!isDirty}
            >
              {props.defaultData
                ? tCommon('command.saveChanges')
                : tCommon('command.register')}
            </Button>
          </Stack>
        </AdminInnerBox>
        {props.children}
      </AdminContent>
    </Box>
  );
};
