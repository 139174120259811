import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { HomophoneForm } from '@/components/molecules/admin/HomophoneForm';

import { useImportHomophonesMutation } from '@/types/graphql';

const HomophoneAdd = () => {
  useCheckPermission({ tenantCompanyAdmin: true });
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.homophoneAdmin'));

  const [importHomophones, importHomophonesResult] =
    useImportHomophonesMutation();

  return (
    <HomophoneForm
      loading={importHomophonesResult.loading}
      onSubmit={async (input) => {
        const result = await importHomophones({
          variables: {
            input: {
              homophones: input.homophones.map((h) => ({
                homophoneCategoryId: input.categoryId,
                homophoneCategoryName: input.categoryName,
                homophoneId: '',
                kana: h.kana,
                sourceWord: h.sourceWord,
                targetWord: h.targetWord,
                disabled: h.disabled,
              })),
            },
          },
        });

        if (result.errors) throw result.errors;
        if (result.data?.importHomophones.every((h) => h.errorCode === 0)) {
          navigate('/admin/homophone');
          showSnackbar('success', commonT('message.registered'));
        }
      }}
    />
  );
};
export default HomophoneAdd;
