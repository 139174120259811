import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';

import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import ChatUserSettingsDialog, {
  ChatUserSetting,
  ChatUserSettingsDialogProps,
} from '@/components/organisms/chatUserSettingsDialog/ChatUserSettingsDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const ChatUserSettingsDialogEx = (
  props: Omit<ChatUserSettingsDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return (
    <ChatThemeProvider>
      <ChatUserSettingsDialog open={isOpen} {...props} />
    </ChatThemeProvider>
  );
};

const useChatUserSettingsDialog = () => {
  const openAwaiter = useOpenAwaiter<ChatUserSetting | undefined>();
  return {
    open: (props: Omit<ChatUserSettingsDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <ChatUserSettingsDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useChatUserSettingsDialog;
