// ダイアログなど、クローズを待機して後続処理を行いたい場合に使用する

import { assertNotNull } from '@remote-voice/utilities';
import { ReactElement, useCallback, useRef } from 'react';

import useOpenableElement from './useOpenableElement';

// await可能なopenと、closeの際に値を指定してopenを抜けさせるロジックを提供する。
// openの際に指定したエレメントを表示する。ダイアログ表示などで使用。
const useOpenAwaiter = <T,>(variables?: any) => {
  const resolve = useRef<(value: T) => void>();
  const openableElement = useOpenableElement(variables);
  const openableElementOpen = openableElement.open;
  const openableElementClose = openableElement.close;

  const open = useCallback(
    async (element: ReactElement) => {
      openableElementOpen(element);
      return await new Promise<T>((r) => {
        resolve.current = r;
      });
    },
    [openableElementOpen]
  );

  const close = useCallback(
    (value: T) => {
      assertNotNull(resolve.current);
      resolve.current(value);
      openableElementClose();
    },
    [openableElementClose]
  );

  return {
    open,
    close,
    isOpen: openableElement.isOpen,
    key: openableElement.key,
  };
};
export default useOpenAwaiter;
