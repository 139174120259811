import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useNgWordCategoriesQuery } from '@/types/graphql';

const DynamicNGWordCategoryBreadcrumbs = (props: BreadcrumbComponentProps) => {
  const category = useNgWordCategoriesQuery({
    variables: {
      input: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: props.match.params.categoryId!,
      },
    },
    skip: props.match.params.categoryId == null,
  });

  return <>{category.data?.ngWordCategories[0].name}</>;
};

export default DynamicNGWordCategoryBreadcrumbs;
