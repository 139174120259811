import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import VadForm from '@/components/molecules/admin/VadForm';
import { useEditVadPresetMutation, useVadPresetsQuery } from '@/types/graphql';

const VadEdit = () => {
  useCheckPermission({ superAdmin: true });

  const { t } = useTranslation('common');
  useSetHeaderTitle('VAD設定');

  const params = useParams();
  const snackbar = useSnackbar();
  const VadId = params.VadId;
  const navigate = useNavigate();
  const VadPresetResult = useVadPresetsQuery({
    variables: { input: { id: VadId } },
  });
  const [editVadPreset, editVadPresetResult] = useEditVadPresetMutation();
  const data = VadPresetResult.data?.vadPresets[0];

  return (
    <VadForm
      loading={editVadPresetResult.loading}
      readonly={data?.default}
      defaultData={useMemo(
        () => ({
          label: data?.label ?? '',
          smoothingTimeConstant: data?.smoothingTimeConstant ?? 0.985,
          energyOffset:
            data?.energyOffset != null
              ? Number(data.energyOffset).toLocaleString('en-US', {
                  maximumFractionDigits: 50,
                  useGrouping: false,
                })
              : '0.00000000001', // 1e-11
          energyThresholdRatioPos: data?.energyThresholdRatioPos ?? 2,
          energyThresholdRatioNeg: data?.energyThresholdRatioNeg ?? 0.5,
          energyIntegration: data?.energyIntegration ?? 0,
        }),
        [
          data?.label,
          data?.smoothingTimeConstant,
          data?.energyOffset,
          data?.energyThresholdRatioPos,
          data?.energyThresholdRatioNeg,
          data?.energyIntegration,
        ]
      )}
      onSubmit={async (input) => {
        const vadPreset = await editVadPreset({
          variables: {
            input: {
              id: VadId ?? '',
              ...input,
              energyOffset: Number(input.energyOffset),
            },
          },
        });
        if (vadPreset.errors) throw vadPreset.errors;
        navigate('/admin/vad');
        snackbar('success', t('message.successSaveChanges'));
      }}
    />
  );
};
export default VadEdit;
