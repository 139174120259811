import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import NGWordForm from '@/components/molecules/admin/NGWordForm';
import { useEditNgWordMutation, useNgWordsQuery } from '@/types/graphql';

const NGWordEdit = () => {
  useCheckPermission({ superAdmin: true });

  const { t } = useTranslation('common');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.ngWord'));

  const params = useParams();
  const snackbar = useSnackbar();
  const ngwordId = params.ngwordId;
  const navigate = useNavigate();
  const ngWordResult = useNgWordsQuery({
    variables: { input: { id: ngwordId } },
  });
  const [editNgWord, editNgWordResult] = useEditNgWordMutation();
  const data = ngWordResult.data?.ngWords[0];

  return (
    <NGWordForm
      loading={editNgWordResult.loading}
      defaultData={useMemo(
        () => ({
          ngWordCategoryId: data?.ngWordCategory.id ?? '',
          word: data?.word ?? '',
        }),
        [data?.ngWordCategory.id, data?.word]
      )}
      onSubmit={async (input) => {
        const ngword = await editNgWord({
          variables: {
            input: {
              id: ngwordId ?? '',
              ...input,
            },
          },
        });
        if (ngword.errors) throw ngword.errors;
        navigate('/admin/ngword');
        snackbar('success', t('message.successSaveChanges'));
      }}
    />
  );
};
export default NGWordEdit;
