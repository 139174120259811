import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useSuperUsersQuery } from '@/types/graphql';

const DynamicSuperUserBreadcrumbs = (props: BreadcrumbComponentProps) => {
  const superuser = useSuperUsersQuery({
    variables: {
      input: { id: props.match.params.superuserId },
    },
    skip: props.match.params.ownerId == null,
  });

  return <>{superuser.data?.superUsers[0].userName}</>;
};

export default DynamicSuperUserBreadcrumbs;
