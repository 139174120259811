import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  ApolloProvider as OriginalApolloProvider,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { useSetGraphqlSubscriptionConnection } from '@/components/hooks/useGraphqlSubscriptionConnection';
import { useSetOpenNetworkAlert } from '@/components/hooks/useOpenNetworkAlert';
import { env } from '@/env';

const ApolloProvider = (props: {
  children: any;
  gqlGatewayUrl: string;
  gqlSubscriptionUrl: string;
}) => {
  const { gqlGatewayUrl, gqlSubscriptionUrl } = props;
  const setOpenNetworkAlert = useSetOpenNetworkAlert();
  const setGraphqlSubscriptionConnection =
    useSetGraphqlSubscriptionConnection();

  let timedOut: number;
  // let latency: number;
  let pingTimeOut: number;
  const wsClient = createClient({
    url: gqlSubscriptionUrl,
    connectionParams: () => {
      return {};
    },
    shouldRetry: () => true, // iPhone等で再接続させるにはこれが必要
    keepAlive: env.REACT_APP_OVERWRITE_PING_INTERVAL_MS
      ? Number(env.REACT_APP_OVERWRITE_PING_INTERVAL_MS)
      : 1_000, // ping server every 1 seconds
    // disablePong: true,
    on: {
      connected: () => {
        setGraphqlSubscriptionConnection(true);
      },
      closed: () => {
        setGraphqlSubscriptionConnection(false);
      },
      error: (_) => {
        setGraphqlSubscriptionConnection(false);
        setOpenNetworkAlert(true);
      },
      ping: (received) => {
        // latency = Date.now();
        if (!received /* sent */) {
          timedOut = window.setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            wsClient.terminate();
          }, 5_000);
          pingTimeOut = window.setTimeout(
            () => {
              setOpenNetworkAlert(true);
            },
            env.REACT_APP_OVERWRITE_PING_THRESHOLD_INTERVAL_MS
              ? Number(env.REACT_APP_OVERWRITE_PING_THRESHOLD_INTERVAL_MS)
              : 400
          );
        }
      },
      pong: (received) => {
        if (received) {
          // console.log(Date.now() - latency);
          clearTimeout(timedOut);
          clearTimeout(pingTimeOut);
        }
      },
    },
  });
  const wsLink = new GraphQLWsLink(wsClient);

  const httpLink = new HttpLink({ uri: gqlGatewayUrl });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
    connectToDevTools: false, // これが有効な場合、拡張機能が利用できるが、コンソールに毎回情報が出力される。あまり使用されていないのでOFF
  });

  return (
    <OriginalApolloProvider client={client}>
      {props.children}
    </OriginalApolloProvider>
  );
};

export default ApolloProvider;

// import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   uri: "http://localhost:4000/graphql"
// });
