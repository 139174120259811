import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Grid,
  Typography,
  Button,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import DownloadCsvDialogByRoomId from '@/components/organisms/admin/DownloadCsvDialogByRoomId';
import { RoomInvitation } from '@/components/organisms/invitingRoomDialog/InvitingRoomDialog';
import {
  ChatRoom,
  ChatSessionState,
  UserRole,
  useGuideUsersLazyQuery,
} from '@/types/graphql';

const RoomListItem = (props: {
  chatRoom: ChatRoom;
  onInvite: (value: RoomInvitation) => void;
  onDelete: () => Promise<void>;
}) => {
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');

  const [roomMenuAnchor, setRoomMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const [showDownlaodCsvDialog, setShowDownlaodCsvDialog] = useState(false);
  const [guideUsersLazy] = useGuideUsersLazyQuery();
  const navigate = useNavigate();

  const sm = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Box
        sx={{
          bgcolor: (t) => t.palette.background.default,
          borderRadius: 2,
          px: 2,
          py: 3,
        }}
      >
        <Grid container alignItems={sm ? 'center' : 'strech'}>
          <Grid item xs={11} sm={6} order={1}>
            <Stack justifyContent="flex-start" spacing={1}>
              <LineClampTypography rowCount={2} variant="h6">
                {props.chatRoom.name}
              </LineClampTypography>
              <Typography
                variant="body2"
                sx={{ color: (t) => t.palette.grey[600] }}
              >
                {(() => {
                  const date = new Date(props.chatRoom.createdAt);
                  return `${t(
                    'room.createdDate'
                  )}：${date.toLocaleDateString()}`;
                })()}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1} order={sm ? 3 : 2}>
            <IconButton
              sx={{ p: sm ? 'auto' : 0 }}
              onClick={(e) => setRoomMenuAnchor(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={5} order={sm ? 2 : 3}>
            <Stack
              justifyContent={'center'}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ height: '100%' }}
              mt={sm ? 0 : 1}
            >
              <Button
                variant="outlined"
                sx={{
                  bgcolor: (t) => t.palette.common.white,
                  minWidth: '100px',
                  width: '45%',
                }}
                onClick={async () => {
                  // 入室コードの取得
                  const currentSession = props.chatRoom.sessions.find(
                    (session) =>
                      session.state === ChatSessionState.NotStarted ||
                      session.state === ChatSessionState.Progressing
                  );
                  assertNotNull(currentSession);

                  // ルームに紐づくガイドユーザ情報の取得
                  const users = await guideUsersLazy({
                    variables: {
                      input: {
                        tenantCompanyId: props.chatRoom.tenantCompanyId,
                      },
                    },
                  });
                  if (users.error) throw users.error;
                  // 現状ガイドユーザーは各テナントにつき一つしか存在しないため、配列の最初の要素を取得している
                  const guide = users.data?.guideUsers.filter(
                    (x) => x.role === UserRole.Guide
                  )?.[0];
                  if (guide == null) throw new Error('Guide is not found');

                  const entryCode = currentSession.entryCode;
                  props.onInvite({
                    url: `${location.origin}/chat/${props.chatRoom.id}`,
                    entryCode: entryCode ?? undefined,
                    guideUrl: `${location.origin}/chat/${props.chatRoom.id}/?chatUserId=${guide.chatUserId}&chatUserPassword=${guide.chatUserPassword}`,
                  });
                }}
              >
                {t('room.invite')}
              </Button>
              <Button
                sx={{ ml: 2, minWidth: '80px', width: '45%' }}
                onClick={async () => {
                  // テナントアドミンで参加
                  const users = await guideUsersLazy({
                    variables: {
                      input: {
                        tenantCompanyId: props.chatRoom.tenantCompanyId,
                      },
                    },
                  });
                  if (users.error) throw users.error;
                  // 現状テナントアドミンユーザーは各テナントにつき一つしか存在しないため、配列の最初の要素を取得している
                  const tenantAdmin = users.data?.guideUsers.filter(
                    (x) => x.role === UserRole.TenantCompanyAdmin
                  )?.[0];
                  if (tenantAdmin == null)
                    throw new Error('Tenant admin user is not found');
                  navigate(
                    `/chat/${props.chatRoom.id}/?chatUserId=${tenantAdmin.chatUserId}&chatUserPassword=${tenantAdmin.chatUserPassword}`
                  );
                }}
              >
                {t('room.start')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Menu
        open={!!roomMenuAnchor}
        anchorEl={roomMenuAnchor}
        onClose={() => setRoomMenuAnchor(null)}
      >
        <MenuItem onClick={() => navigate(`${props.chatRoom.id}/edit`)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{commonT('command.edit')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRoomMenuAnchor(null);
            props.onDelete();
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{commonT('command.delete')}</ListItemText>
        </MenuItem>
        {/* TODO: 画面サイズによって出現の条件分岐 */}
        <MenuItem
          onClick={() => {
            setShowDownlaodCsvDialog(true);
            setRoomMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>{t('room.downloadLogs')}</ListItemText>
        </MenuItem>
      </Menu>
      <DownloadCsvDialogByRoomId
        open={showDownlaodCsvDialog}
        onClose={() => setShowDownlaodCsvDialog(false)}
        roomId={props.chatRoom.id}
      />
    </>
  );
};
export default RoomListItem;
