import { Button, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

const AdminMenuButton = (props: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}) => {
  return (
    <Button
      onClick={props.onClick}
      variant="outlined"
      sx={{
        bgcolor: (t) => t.palette.common.white,
        width: '200px',
        height: '200px',
        '&:hover': {
          backgroundColor: (t) => t.palette.grey[100],
        },
      }}
    >
      <Stack
        alignItems={'center'}
        spacing={2}
        sx={{ '& > .MuiSvgIcon-root': { fontSize: '56px' } }}
      >
        {props.icon}
        <Typography sx={{ fontSize: '1.25em' }}>{props.title}</Typography>
      </Stack>
    </Button>
  );
};

export default AdminMenuButton;
