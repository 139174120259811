import CheckIcon from '@mui/icons-material/Check';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  SxProps,
} from '@mui/material';
import { Languages, validLanguages } from '@remote-voice/utilities';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = (props: {
  sx?: SxProps;
  label?: string;
  language: Languages;
  setLanguage: (language: Languages) => void;
}) => {
  const { t: tCommon } = useTranslation('common');
  const label = props.label ?? tCommon('caption.language');
  return (
    <FormControl fullWidth>
      <InputLabel id="chat-login-language">{label}</InputLabel>
      <Select
        sx={props.sx}
        labelId="chat-login-language"
        autoWidth={false}
        value={props.language}
        label={label}
        onChange={(e) => {
          props.setLanguage(e.target.value as Languages);
        }}
        renderValue={(value) => tCommon(`localeLanguages.${value}`)}
      >
        {validLanguages.map((l) => (
          <MenuItem key={l} value={l} sx={{ width: '100%' }}>
            <ListItemText
              primary={tCommon(`localeLanguages.${l}`)}
              secondary={tCommon(`languages.${l}`)}
            />
            {props.language === l && <CheckIcon />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default LanguageSwitcher;
