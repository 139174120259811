import {
  Stack,
  StackProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const Footer = (props: StackProps) => {
  const isMatchDownSm = useMediaQuery(useTheme().breakpoints.down('sm'));
  return (
    <Stack
      width="100%"
      height={`${isMatchDownSm ? '93' : '167'}px`}
      color={(t) => t.palette.grey[500]}
      alignItems="center"
      justifyContent="flex-end"
      {...props}
      sx={props.sx}
    >
      <Typography variant="caption" mb={1}>
        Copyright © 2023 TOPPAN INC.
      </Typography>
    </Stack>
  );
};

export default Footer;
