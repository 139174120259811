import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const openableElementsAtom = atom<
  { key: string; element: ReactElement; isOpen: boolean; variables: any }[]
>([]);

// エレメントのOpenableElementのIsOpen状態を取得する。
export const useOpenableElementIsOpen = (key: string) => {
  const openElements = useAtomValue(openableElementsAtom);
  return openElements.find((x) => x.key === key)?.isOpen ?? false;
};

export const useOpenableElementVariables = (key: string) => {
  const openElements = useAtomValue(openableElementsAtom);
  return openElements.find((x) => x.key === key)?.variables;
};

// オープン可能なエレメントを動的に生成する際に使用する。
// 生成したエレメントは、OpenableElementsProviderで表示している。
const useOpenableElement = (variables?: any) => {
  const key = useRef(uuidv4());
  const setElements = useSetAtom(openableElementsAtom);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const keepKey = key.current;
    return () => {
      // cleanup
      setElements((elements) => {
        const index = elements.findIndex((x) => x.key === keepKey);
        if (index === -1) return elements;
        const newElements = [...elements];
        newElements.splice(index, 1);
        return newElements;
      });
    };
  }, [setElements]);

  useEffect(() => {
    // variables更新
    setElements((elements) => {
      const el = elements.find((x) => x.key === key.current);
      const newElements = [...elements];
      if (el != null) {
        el.variables = variables;
      }
      return newElements;
    });
    setIsOpen(false);
  }, [setElements, variables]);

  const open = useCallback(
    (element: ReactElement) => {
      // elements更新
      setElements((elements) => {
        const el = elements.find((x) => x.key === key.current);
        const newElements = [...elements];
        if (el == null) {
          newElements.push({
            key: key.current,
            element,
            variables,
            isOpen: true,
          });
        } else {
          el.element = element;
          el.isOpen = true;
        }
        return newElements;
      });
      setIsOpen(true);
    },
    [setElements, variables]
  );

  const close = useCallback(() => {
    // elements更新
    setElements((elements) => {
      const el = elements.find((x) => x.key === key.current);
      const newElements = [...elements];
      if (el != null) {
        el.isOpen = false;
      }
      return newElements;
    });
    setIsOpen(false);
  }, [setElements]);

  return {
    open,
    close,
    key: key.current,
    isOpen,
  };
};
export default useOpenableElement;
