import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export const FooterMenu = (props: PropsWithChildren) => (
  <>
    <Box height="70px" />
    <Stack
      direction="row"
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(8px)',
        width: 1,
        py: 2,
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 10,
      }}
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {props.children}
    </Stack>
  </>
);
