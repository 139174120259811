import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useOwnerCompaniesQuery } from '@/types/graphql';

const DynamicOwnerBreadcrumbs = (props: BreadcrumbComponentProps) => {
  const owner = useOwnerCompaniesQuery({
    variables: {
      input: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: props.match.params.ownerId!,
      },
    },
    skip: props.match.params.ownerId == null,
  });

  return <>{owner.data?.ownerCompanies[0].name}</>;
};

export default DynamicOwnerBreadcrumbs;
