import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getCookieOrSetDefault } from '@/utils/cookie';

const useChatUserCookieInfo = () => {
  // レンダリングのたびに取得するのを避けるため、stateの初期化関数で実行
  const [value] = useState(() => {
    const defaultUserId = getCookieOrSetDefault('RV_DEFAULT_USER_ID', uuidv4());
    const defaultPass = getCookieOrSetDefault('RV_DEFAULT_USER_PASS', uuidv4());
    return {
      userId: getCookieOrSetDefault('RV_USER_ID', defaultUserId),
      userPassword: getCookieOrSetDefault('RV_USER_PASS', defaultPass),
    };
  });

  return {
    ...value,
  };
};
export default useChatUserCookieInfo;
