import { Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Footer from '@/components/atoms/Footer';
import Logo from '@/components/atoms/Logo';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import { env } from '@/env';
import { useNotificationsQuery } from '@/types/graphql';

const AdminLogin = () => {
  const { t } = useTranslation('admin');
  const width = 240;

  const notifications = useNotificationsQuery({
    variables: { input: { filterOnlyEnabled: true } },
  });

  const navigate = useNavigate();
  const permission = useUserPermission();
  useEffect(() => {
    if (permission != null && permission.cognitoUserName != '') {
      navigate('/admin');
    }
  }, [navigate, permission]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      component="form"
      onSubmit={(ev) => {
        ev.preventDefault();
        location.href = env.REACT_APP_UNAUTHRIZED_REDIRECT_URL;
      }}
    >
      <Stack
        flex={1}
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
        width={width}
      >
        <Stack alignItems="center" width={1}>
          <Logo />
        </Stack>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h6">{t('login.title')}</Typography>
        </Stack>
        <Stack spacing={1}>
          <Button size="large" type="submit" fullWidth sx={{ mt: 2 }}>
            {t('login.login')}
          </Button>
        </Stack>

        <Stack
          width="100%"
          textAlign="left"
          p={2}
          borderRadius={3}
          sx={{ backgroundColor: (t) => t.palette.background.default }}
          spacing={2}
        >
          <Typography variant="subtitle1">お知らせ</Typography>
          {notifications.data != null &&
            notifications.data.notifications.length >= 1 &&
            notifications.data?.notifications.map((x) => (
              <Typography
                key={x.id}
                variant="body2"
                sx={{ whiteSpace: 'pre-line' }}
              >
                {x.message}
              </Typography>
            ))}
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
};
export default AdminLogin;
