import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
export const PositiveIntegerField = forwardRef<
  HTMLInputElement,
  TextFieldProps
>((props, ref) => (
  <TextField
    {...props}
    ref={ref}
    type="number"
    inputProps={{
      ...props.inputProps,
      min: 0,
    }}
    // min=0を設定しただけだとキー入力でマイナスいれられるので、ここで禁止
    onKeyDown={(ev) => {
      if (ev.key === '-') ev.preventDefault();
      props.onKeyDown?.(ev);
    }}
  />
));
