import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import ChatUserList from '@/components/organisms/chat/ChatUserList';
import {
  ChatSessionUser,
  useRemoveChatSessionUserMutation,
  useUpdateChatSessionUserMutation,
} from '@/types/graphql';

export type ChatUserListDialogProps = {
  open: boolean;
  onClose: () => void;
  users: ChatSessionUser[];
  // ガイドの場合に指定するパラメータ
  guideOptions?: {
    chatRoomId: string;
    entryCode: string;
    userId: string;
    userPassword: string;
  };
};

const ChatUserListDialog = (props: ChatUserListDialogProps) => {
  const { t } = useTranslation('chat');
  const joiningUsers = props.users.filter(
    (x) => x.isJoining && x.isWaiting === false
  );
  const waitingUsers = props.users.filter((x) => x.isJoining && x.isWaiting);

  const loadingBackdrop = useLoadingBackdrop();
  const updateUser = useUpdateChatSessionUserMutation();
  const removeUser = useRemoveChatSessionUserMutation();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { minHeight: 350 } }}
    >
      <DialogTitle>{t('menu.participantsTitle')}</DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <ChatUserList users={joiningUsers} />

        {props.guideOptions != null && waitingUsers.length > 0 && (
          <>
            <DialogTitle>{t('wating.waitingUsers')}</DialogTitle>
            <Divider sx={{ mx: 2 }} />
            <ChatUserList
              users={waitingUsers}
              onClickApprove={async (user) => {
                await loadingBackdrop.open(() =>
                  updateUser[0]({
                    variables: {
                      input: {
                        chatRoomId: props.guideOptions?.chatRoomId ?? '',
                        sessionEntryCode: props.guideOptions?.entryCode ?? '',
                        userId: props.guideOptions?.userId ?? '',
                        userPassword: props.guideOptions?.userPassword ?? '',
                        targetUserId: user.userId,
                        isWaiting: false,
                      },
                    },
                  })
                );
              }}
              onClickReject={async (user) => {
                await loadingBackdrop.open(() =>
                  removeUser[0]({
                    variables: {
                      input: {
                        chatRoomId: props.guideOptions?.chatRoomId ?? '',
                        sessionEntryCode: props.guideOptions?.entryCode ?? '',
                        userId: props.guideOptions?.userId ?? '',
                        userPassword: props.guideOptions?.userPassword ?? '',
                        targetUserId: user.userId,
                      },
                    },
                  })
                );
              }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <CancelButton onClick={() => props.onClose()} />
      </DialogActions>
    </Dialog>
  );
};
export default ChatUserListDialog;
