import BusinessIcon from '@mui/icons-material/Business';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminInnerBox } from '../../components/atoms/AdminPageParts';
import { useCheckPermission } from '../../components/hooks/useCheckPermission';
import { useUserPermission } from '../../components/hooks/useUserPermission';

import { AdminLogo } from '@/components/atoms/AdminLogo';
import AdminMenuButton from '@/components/atoms/AdminMenuButton';
import Footer from '@/components/atoms/Footer';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { env } from '@/env';

const AdminIndex = () => {
  useSetHeaderTitle(AdminLogo);

  const navigate = useNavigate();
  const userPermission = useUserPermission();
  useCheckPermission({
    superAdmin: true,
    tenantCompanyAdmin: true,
    ownerCompanyAdmin: true,
  });

  useEffect(() => {
    if (!userPermission.isSuperAdmin && !userPermission.isOwnerCompanyAdmin) {
      navigate('/admin/room');
    }
  }, [navigate, userPermission]);

  return (
    <>
      <Footer sx={{ position: 'fixed', bottom: 0 }} />
      <AdminInnerBox>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            gap: 3.5,
            mx: 5,
            my: 10,
          }}
        >
          {userPermission.isSuperAdmin && (
            <AdminMenuButton
              icon={<BusinessIcon />}
              onClick={() => navigate('/admin/owner')}
              title="企業管理"
            />
          )}
          {(userPermission.isSuperAdmin ||
            userPermission.isOwnerCompanyAdmin) && (
            <AdminMenuButton
              icon={<PeopleAltIcon />}
              onClick={() => navigate('/admin/tenant')}
              title="ユーザー管理"
            />
          )}
          {userPermission.isSuperAdmin && (
            <AdminMenuButton
              icon={<GraphicEqIcon />}
              onClick={() => navigate('/admin/vad')}
              title="VAD設定"
            />
          )}
          {userPermission.isSuperAdmin && (
            <AdminMenuButton
              icon={<FeedbackOutlinedIcon />}
              onClick={() => navigate('/admin/ngword')}
              title="NGワード管理"
            />
          )}
          {(userPermission.isSuperAdmin ||
            userPermission.isOwnerCompanyAdmin) && (
            <AdminMenuButton
              icon={<FeedOutlinedIcon />}
              onClick={() => navigate('/admin/notification')}
              title="お知らせ登録"
            />
          )}
          {userPermission.isSuperAdmin && (
            <AdminMenuButton
              icon={<SpeedIcon />}
              onClick={() =>
                window.open(env.REACT_APP_CLOUDWATCH_DASHBOARD_URL, '_blank')
              }
              title="システム利用状況"
            />
          )}
        </Box>
      </AdminInnerBox>
    </>
  );
};
export default AdminIndex;
