import {
  Button,
  Stack,
  Box,
  Skeleton,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';

export type DownloadableImageDialogProps = {
  open: boolean;
  onClose: () => void;
  imgProps: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
};
const DownloadableImageDialog = (props: DownloadableImageDialogProps) => {
  const imgEl = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (props.open === true) {
      setIsLoaded(false);
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={false}>
      <DialogContent>
        <Stack direction={'column'} spacing={2} alignItems={'center'}>
          <Box
            width={props.imgProps.width}
            height={props.imgProps.height}
            maxWidth={'100%'}
            maxHeight={'100%'}
            sx={{ m: 'auto', textAlign: 'center' }}
          >
            {isLoaded ? (
              <></>
            ) : (
              <Skeleton variant="rectangular" width={210} height={150} />
            )}
            <img
              ref={imgEl}
              src={props.imgProps.src}
              crossOrigin="anonymous"
              style={{ maxWidth: '80dvw', maxHeight: '80dvh' }}
              id="chat_img"
              onLoad={() => setIsLoaded(true)}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={() => props.onClose()}></CancelButton>
          <Button
            onClick={(ev) => {
              ev.preventDefault();
              const imgCanvas = document.createElement('canvas');
              const context = imgCanvas.getContext('2d');

              if (context && imgEl.current !== null && imgCanvas) {
                imgCanvas.width = imgEl.current.width;
                imgCanvas.height = imgEl.current.height;
                context.drawImage(
                  imgEl.current,
                  0,
                  0,
                  imgEl.current.width,
                  imgEl.current.height
                );
              }

              if (imgCanvas !== null) {
                const anchor: HTMLAnchorElement = document.createElement('a');
                imgCanvas.toBlob((blob) => {
                  if (anchor !== null && blob) {
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = props.imgProps.title ?? 'chat_img';
                    anchor.click();
                  }
                });
              }
            }}
          >
            {t('command.download')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadableImageDialog;
