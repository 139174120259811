import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  FormHelperText,
  Button,
  InputLabel,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useState, useEffect } from 'react';

import {
  ControllerRenderProps,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';

import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import { NGWordData } from '@/components/molecules/admin/NGWordForm';
import { useCategoryDialog } from '@/components/organisms/categoryDialog/useCategoryDialog';
import {
  useAddNgWordCategoryMutation,
  useNgWordCategoriesQuery,
} from '@/types/graphql';

export const NGWordCategorySelector = (props: {
  register: UseFormRegister<NGWordData>;
  errors: FieldErrors<NGWordData>;
  belongedCategoryId: string;
  field: ControllerRenderProps<NGWordData, 'ngWordCategoryId'>;
}) => {
  const ngWordCategoriesResult = useNgWordCategoriesQuery({
    variables: { input: {} },
  });
  const gotCategories = ngWordCategoriesResult.data?.ngWordCategories;
  const [categories, setCategories] = useState<
    { id: string; name: string }[] | undefined
  >(gotCategories);

  const categoryDialog = useCategoryDialog();
  const [addNgWordCategory] = useAddNgWordCategoryMutation();

  useEffect(() => {
    setCategories(gotCategories);
  }, [gotCategories, setCategories]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel size="small" id="categoryselector-label">
          カテゴリ
        </InputLabel>
        <Select
          labelId="categoryselector-label"
          {...props.field}
          {...props.register('ngWordCategoryId')}
          size="small"
          value={props.field.value ?? ''}
          label="カテゴリ"
          renderValue={(value) => (
            <LineClampTypography rowCount={1}>
              {categories?.filter((cat) => cat.id === value)[0].name}
            </LineClampTypography>
          )}
        >
          {(categories ?? []).map((cat) => (
            <MenuItem key={cat.id} value={cat.id}>
              <LineClampTypography flex={1} rowCount={1}>
                {cat.name}
              </LineClampTypography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          sx={{ color: (t) => t.palette.error.main }}
          error={props.errors.ngWordCategoryId?.message != null}
        >
          {props.errors.ngWordCategoryId?.message}
        </FormHelperText>
      </FormControl>

      <Button
        variant="text"
        onClick={async () => {
          const result = await categoryDialog.open({
            title: '新規カテゴリを作成',
          });
          if (result == null) return undefined;
          const added = await addNgWordCategory({
            variables: {
              input: {
                name: result,
              },
            },
          });
          if (added.errors) throw added.errors;
          assertNotNull(added.data);
          setCategories([...(categories ?? []), added.data.addNgWordCategory]);
        }}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規カテゴリを作成
      </Button>
    </Box>
  );
};
