import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { validLanguages } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';

export type RoomInvitation = {
  url: string;
  guideUrl?: string;
  entryCode?: string;
};

export type ReverseTranslationDialogProps = {
  open: boolean;
  // undefはnoneを選択, nullはキャンセルして終了
  onClose: (language: string | undefined | null) => void;
  defaultRevLanguage: string | undefined;
  enableRevLanguages: string[];
};

const ReverseTranslationDialog = (props: ReverseTranslationDialogProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('chat');

  const [selection, setSelection] = useState<string | undefined>(
    props.defaultRevLanguage
  );
  useEffect(() => {
    setSelection(props.defaultRevLanguage);
  }, [props.defaultRevLanguage]);

  return (
    <Dialog open={props.open}>
      <DialogTitle>{t('menu.reverseTranslationTitle')}</DialogTitle>
      <Typography textAlign="center" mb={1}>
        {t('menu.reverseTranslationDescription')}
      </Typography>
      <DialogContent dividers>
        <RadioGroup
          value={selection ?? 'none'}
          onChange={(ev) => {
            const value = (ev.target as HTMLInputElement).value;
            setSelection(value !== 'none' ? value : undefined);
          }}
          sx={{ mb: 2 }}
        >
          {['none', ...validLanguages].map((language) => (
            <FormControlLabel
              value={language}
              key={language}
              disabled={
                props.enableRevLanguages.find((cond) => cond === language) ==
                  null && language !== 'none'
              }
              control={<Radio />}
              label={tCommon(`languages.${language}` as any)}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={() => props.onClose(null)} />
          <Button
            key="ok"
            onClick={() => {
              props.onClose(selection);
            }}
          >
            {tCommon('command.ok')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default ReverseTranslationDialog;
