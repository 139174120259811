import { assertNotNull } from '@remote-voice/utilities';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  TenantCompanyError,
  TenantForm,
} from '../../../components/molecules/admin/TenantForm';
import {
  useEditTenantCompanyMutation,
  useTenantCompaniesQuery,
} from '../../../types/graphql';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';

const TenantEdit = () => {
  useCheckPermission({
    ownerCompanyAdmin: true,
  });

  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.tenantAdmin'));

  const params = useParams();
  const tenantId = params.tenantId;

  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [editTenantCompany, editTenantCompanyResult] =
    useEditTenantCompanyMutation();

  //   初期値
  const tenantCompanyResult = useTenantCompaniesQuery({
    variables: { input: { id: tenantId } },
  });
  const data = tenantCompanyResult.data?.tenantCompanies[0];

  return (
    <TenantForm
      loading={tenantCompanyResult.loading || editTenantCompanyResult.loading}
      ownerCompanyName={data?.ownerCompanyName ?? ''}
      onSubmit={async (input) => {
        assertNotNull(tenantId);

        const result = (
          await editTenantCompany({
            variables: {
              input: {
                id: tenantId,
                guideManagerName: input.guideManagerName,
                isEnableVoice: input.isEnableVoice,
                roomLimit: input.roomLimit,
                roomCreationLimit: input.roomCreationLimit,
                note: input.note,
                vadPresetId: input.vadPresetId,
                userName:
                  data?.userName === input.userName
                    ? undefined
                    : input.userName,
                newPassword:
                  input.temporaryPassword === ''
                    ? undefined
                    : input.temporaryPassword,
              },
            },
          })
        ).data?.editTenantCompany;

        const serverValidationErrors: TenantCompanyError[] = [];
        if (result?.__typename === 'UserNameAlreadyExists') {
          serverValidationErrors.push({
            type: 'UserNameAlreadyExists',
            message: result.message,
          });
        } else if (
          result?.__typename === 'ExcessTenantCompanyRoomNumberLimit'
        ) {
          serverValidationErrors.push({
            type: 'ExcessTenantCompanyRoomNumberLimit',
            message: result.message,
            limit: result.limit,
          });
        } else {
          navigate('/admin/tenant');
          snackbar('success', '変更しました');
        }

        return serverValidationErrors;
      }}
      defaultData={useMemo(
        () => ({
          guideManagerName: data?.guideManagerName ?? '',
          userName: data?.userName ?? '',
          temporaryPassword: '',
          note: data?.note ?? undefined,
          roomLimit: data?.roomLimit ?? 0,
          roomCreationLimit: data?.roomCreationLimit ?? 0,
          isEnableVoice: data?.isEnableVoice ?? false,
          vadPresetId: data?.vadPreset?.id ?? undefined,
        }),
        [
          data?.guideManagerName,
          data?.userName,
          data?.note,
          data?.roomLimit,
          data?.roomCreationLimit,
          data?.isEnableVoice,
          data?.vadPreset?.id,
        ]
      )}
    />
  );
};
export default TenantEdit;
