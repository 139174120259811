import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import AudioInputTestDialog, {
  AudioInputTestDialogProps,
} from '@/components/organisms/audioTest/AudioInputTestDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const AudioInputTestDialogEx = (
  props: Omit<AudioInputTestDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return (
    <ChatThemeProvider>
      <AudioInputTestDialog open={isOpen} {...props} />
    </ChatThemeProvider>
  );
};

const useAudioInputTestDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: (props?: Omit<AudioInputTestDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <AudioInputTestDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useAudioInputTestDialog;
