import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

// キャンセルボタン
const CancelButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');
  const { children, variant, ...others } = props;
  return (
    <Button variant={variant ?? 'outlined'} {...others}>
      {children ?? t('command.cancel')}
    </Button>
  );
};
export default CancelButton;
