import { ButtonProps } from '@mui/material';

import CancelButton from '@/components/atoms/CancelButton';
import DeleteButton from '@/components/atoms/DeleteButton';
import { FooterMenu } from '@/components/molecules/admin/FooterMenu';

// フッター部分の削除メニュー
export const DeletionFooterMenu = (props: {
  deleteButtonText: string;
  deleteButtonDisabled: boolean;
  onClickDelete: ButtonProps['onClick'];
  onClickCancel: ButtonProps['onClick'];
}) => (
  <FooterMenu>
    <CancelButton onClick={props.onClickCancel} />
    <DeleteButton
      disabled={props.deleteButtonDisabled}
      onClick={props.onClickDelete}
      color="primary"
    >
      {props.deleteButtonText}
    </DeleteButton>
  </FooterMenu>
);
