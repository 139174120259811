import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Chat from './Chat';

import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import useChatUserCookieInfo from '@/components/organisms/chat/useChatUserCookieInfo';
import { useChatSessionInfoQuery } from '@/types/graphql';

import { ChatSessionState } from '@/types/graphql';
export const ChatValidator = () => {
  const { t } = useTranslation('chat');
  const showSnackbar = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const chatRoomId = params.chatRoomId ?? '';

  const { userId, userPassword } = useChatUserCookieInfo();

  const chatSessionId = params.chatSessionId ?? '';
  const sessionEntryCode =
    Cookies.get('RV_SESSION_PASS_' + chatSessionId) ?? '';

  //トップページへのリダイレクト処理
  const redirectToTop = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  const result = useChatSessionInfoQuery({
    variables: {
      input: {
        chatRoomId: chatRoomId,
        sessionEntryCode: sessionEntryCode,
        chatUserId: userId,
        chatUserPassword: userPassword,
      },
    },
    onError: () => {
      showSnackbar('error', t('unloginableMsg'));
      redirectToTop();
    },
  });
  if (result.loading) {
    return <LoadingBackdrop open />;
  } else {
    const sessionState = result?.data?.chatSessionInfo.state;
    if (sessionState === ChatSessionState.Progressing) {
      return <Chat ttsService={result?.data?.chatSessionInfo.ttsService} />;
    } else {
      showSnackbar('error', t('unloginableMsg'));
      redirectToTop();
      return <></>;
    }
  }
};
