import ImageIcon from '@mui/icons-material/Image';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

type ChatFooterTextProps = {
  text: string;
  onChangeText: (text: string) => void;
  onSendMessage: (text: string) => void;
  onSendImage: () => void;
  onChangeToMic: () => void;
};

const ChatFooterText = (props: ChatFooterTextProps) => {
  const textMaxLength = 1000;

  const sendMessage = () => {
    const value = props.text.trim();
    if (value !== '') {
      props.onSendMessage(value);
      props.onChangeText('');
    }
  };

  // Enterで送信できるようにする
  const onPressEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
    e.nativeEvent.preventDefault();
    sendMessage();
  };

  const { t } = useTranslation('chat');
  const boxShadow = '0px 0px 6px rgba(0, 0, 0, 0.2)';

  return (
    <Stack width={1} boxShadow={boxShadow} zIndex={2} bgcolor={'white'}>
      <Stack direction="row" px={2} py="13px">
        <IconButton onClick={() => props.onSendImage()} sx={{ ml: -1 }}>
          <ImageIcon />
        </IconButton>
        <IconButton onClick={() => props.onChangeToMic()} sx={{ mx: -1 }}>
          <MicIcon />
        </IconButton>

        <FormControl
          fullWidth
          sx={{
            ml: 1,
            '& div.MuiInputBase-root': {
              borderRadius: '20px',
              // bgcolor: '#E5E9EB', // 入力欄の背景色。グレーっぽくて無効に見えてしまうのでとりあえず解除
            },
            '& .MuiInputLabel-shrink': {
              display: 'none!important',
            },
          }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-password"
            size="small"
            sx={{ opacity: 0.7 }}
          >
            {t('menu.inputMessage')}
          </InputLabel>
          <OutlinedInput
            inputProps={{
              shrink: 'false',
              maxLength: textMaxLength,
              enterKeyHint: 'send',
            }}
            size="small"
            id="chat-input-text-area"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => sendMessage()}
                  aria-label="toggle password visibility"
                  edge="end"
                  disabled={props.text.trim() === ''}
                >
                  <SendIcon
                    color={props.text.trim() === '' ? undefined : 'primary'}
                  />
                </IconButton>
              </InputAdornment>
            }
            value={props.text}
            onChange={(ev) => props.onChangeText(ev.currentTarget.value)}
            onKeyDown={(e) => onPressEnter(e as any)}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};
export default ChatFooterText;
