import { Box, SxProps, Theme } from '@mui/material';

const Logo = (props?: { sx?: SxProps<Theme> }) => {
  return (
    <Box sx={props?.sx}>
      <img height={34} src="/assets/logo.png" />
    </Box>
  );
};

export default Logo;
