import { createTheme, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

import { useFontSize } from '@/components/hooks/useFontSize';
import { theme as parentTheme } from '@/providers/ThemeProvider';

const ChatThemeProvider = (props: { children: ReactNode }) => {
  const fontSize = useFontSize();

  const theme = createTheme(parentTheme, {
    typography: {
      fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        fontSize: `${26 * fontSize}px`,
        fontWeight: 700,
      },
      h2: {
        fontSize: `${24 * fontSize}px`,
        fontWeight: 700,
      },
      h3: {
        fontSize: `${22 * fontSize}px`,
        fontWeight: 700,
      },
      h4: {
        fontSize: `${20 * fontSize}px`,
        fontWeight: 700,
      },
      h5: {
        fontSize: `${18 * fontSize}px`,
        fontWeight: 700,
      },
      h6: {
        fontSize: `${16 * fontSize}px`,
        fontWeight: 700,
      },
      body1: {
        fontSize: `${14 * fontSize}px`,
      },
      body2: {
        fontSize: `${12 * fontSize}px`,
      },
      caption: {
        fontSize: `${10 * fontSize}px`,
      },
      small: {
        // システム共通の少し小さくしたい文字(拡張)
        fontSize: `${8 * fontSize}px`,
      },
      subtitle1: {
        fontSize: `${14 * fontSize}px`,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: `${12 * fontSize}px`,
        fontWeight: 600,
      },
      button: {
        fontSize: `${14 * fontSize}px`,
      },
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
export default ChatThemeProvider;
