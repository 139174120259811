import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { AdminIndexTab } from '@/components/molecules/admin/AdminIndexTab';
import OwnerAnalyticsTemplate from '@/components/templates/analytics/OwnerAnalyticsTemplate';
import { useOwnerCompaniesQuery } from '@/types/graphql';

const TenantAnalytics = () => {
  const permission = useCheckPermission({
    ownerCompanyAdmin: true,
  });
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.tenantAdmin'));

  const owner = useOwnerCompaniesQuery({
    variables: { input: { id: permission.ownerCompanyId } },
  });

  return (
    <AdminContent>
      <AdminIndexTab
        selected={2}
        tab1={{ label: 'ユーザー一覧', redirectTo: '/admin/tenant' }}
        tab2={{ label: 'アナリティクス' }}
      />
      <Stack
        sx={{
          bgcolor: (t) => t.palette.common.white,
        }}
      >
        <OwnerAnalyticsTemplate
          id={permission.ownerCompanyId ?? ''}
          companyName={owner.data?.ownerCompanies[0]?.name ?? ''}
        />
      </Stack>
    </AdminContent>
  );
};
export default TenantAnalytics;
