import { Chip, Stack, Typography } from '@mui/material';

import { ChatReactionType } from '../../types/graphql';

import { ReactionQuestionIcon } from '@/components/atoms/ReactionQuestionIcon';

const types = [
  {
    type: ChatReactionType.Like,
    value: '👍',
  },
  {
    type: ChatReactionType.Clap,
    value: '👏',
  },
  {
    type: ChatReactionType.Love,
    value: '💛',
  },
  {
    type: ChatReactionType.Happy,
    value: '😆',
  },
  {
    type: ChatReactionType.Struggle,
    value: '😟',
  },
  {
    type: ChatReactionType.Question,
    value: <ReactionQuestionIcon width={23} height={23} />,
  },
];

const ReactionCounter = (props: {
  reactions: { userId: string; reactionType: string }[];
  onOpenReactions?: () => void;
}) => {
  const components = types
    .map((x) => ({
      type: x.type,
      value: x.value,
      count: props.reactions.filter((y) => y.reactionType === x.type).length,
    }))
    .filter((x) => x.count > 0)
    .map((x) => (
      <Chip
        key={x.type}
        label={
          <Stack direction="row" spacing={0.5} alignItems="center">
            {x.value}
            <Typography variant="body2">{x.count.toString()}</Typography>
          </Stack>
        }
        variant="outlined"
        size="small"
      />
    ));
  return (
    <Stack
      direction="row"
      spacing={0.5}
      onClick={() => props.onOpenReactions?.()}
      sx={{ cursor: props.onOpenReactions != null ? 'pointer' : 'default' }}
    >
      {components}
    </Stack>
  );
};
export default ReactionCounter;
