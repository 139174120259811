import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import DownloadableImageDialog, {
  DownloadableImageDialogProps,
} from '@/components/organisms/downloadableImageDialog/DownloadableImageDialog';

const DownloadableImageDialogEx = (
  props: Omit<DownloadableImageDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <DownloadableImageDialog open={isOpen} {...props} />;
};

const useDownloadableImageDialog = () => {
  const openAwaiter = useOpenAwaiter<void>();
  return {
    open: (props: Omit<DownloadableImageDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <DownloadableImageDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useDownloadableImageDialog;
