import { Stack, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FontSizeSwitcher = (props: {
  value: string;
  setValue: (value: string) => void;
}) => {
  const { t } = useTranslation('chat');
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ my: 2 }}
    >
      <RadioGroup
        value={props.value}
        onChange={(x) => props.setValue((x.target as HTMLInputElement).value)}
        sx={{ width: 1 }}
      >
        <Stack spacing={1}>
          {[
            { size: 1, name: t('fontSettings.size1Default') }, //    14px
            { size: 1.15, name: t('fontSettings.size2Default') }, // 16.1px
            { size: 1.3, name: t('fontSettings.size3Default') }, //  18.2px
          ].map((x) => (
            <FormControlLabel
              key={x.size}
              value={x.size.toString()}
              control={<Radio />}
              label={
                <Stack fontSize={`${14 * x.size}px`}>
                  <Box
                    sx={
                      props.value === x.size.toString()
                        ? {
                            color: (x) => x.palette.primary.main,
                            fontWeight: 'bold',
                          }
                        : undefined
                    }
                  >
                    {x.name}
                  </Box>
                  <Box>{t('fontSettings.preview')}</Box>
                </Stack>
              }
              sx={{
                width: 1,
                border: 1,
                borderRadius: 3,
                borderColor: (t) =>
                  props.value === x.size.toString()
                    ? t.palette.primary.main
                    : t.palette.grey[300],
                background: (t) => t.palette.background.paper,
                m: 0,
                p: 1,
                textAlign: 'left',
              }}
            />
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

export default FontSizeSwitcher;

// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import { Stack, IconButton, FormLabel } from '@mui/material';

// import { useFontSize, useSetFontSize } from '@/components/hooks/useFontSize';

// const FontSizeSwitcher = () => {
//   const setFontSize = useSetFontSize();
//   const fontSize = useFontSize();

//   return (
//     <Stack direction={'row'} alignItems={'center'}>
//       <FormLabel>Font Size</FormLabel>
//       <IconButton
//         onClick={() => {
//           setFontSize(fontSize - 0.1);
//         }}
//         disabled={fontSize < 0.6}
//         color="primary"
//       >
//         <RemoveCircleIcon />
//       </IconButton>
//       <IconButton
//         onClick={() => {
//           setFontSize(fontSize + 0.1);
//         }}
//         disabled={fontSize > 2}
//         color="primary"
//       >
//         <AddCircleIcon />
//       </IconButton>
//     </Stack>
//   );
// };

// export default FontSizeSwitcher;
