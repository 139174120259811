import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { MenuItem, Divider, ListItemIcon, ListItemText } from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import { SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogout from '@/components/hooks/useLogout';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import { env } from '@/env';

const AdminMenu = (props: {
  accountMenuAnchor: HTMLElement | null;
  setAccountMenuAnchor: (value: SetStateAction<HTMLElement | null>) => void;
}) => {
  const userPermission = useUserPermission();
  const logout = useLogout();
  const navigate = useNavigate();

  return (
    <Menu
      open={!!props.accountMenuAnchor}
      anchorEl={props.accountMenuAnchor}
      onClose={() => props.setAccountMenuAnchor(null)}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {userPermission.isSuperAdmin && (
        <MenuItem
          key="owner"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/owner');
          }}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText>企業管理</ListItemText>
        </MenuItem>
        // <MenuItem disabled>システム</MenuItem>
      )}
      {(userPermission.isSuperAdmin || userPermission.isOwnerCompanyAdmin) && (
        <MenuItem
          key="tenant"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/tenant');
          }}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText>ユーザー管理</ListItemText>
        </MenuItem>
      )}
      {userPermission.isSuperAdmin && (
        <MenuItem
          key="vad"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/vad');
          }}
        >
          <ListItemIcon>
            <GraphicEqIcon />
          </ListItemIcon>
          <ListItemText>VAD設定</ListItemText>
        </MenuItem>
      )}
      {userPermission.isSuperAdmin && (
        <MenuItem
          key="ngword"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/ngword');
          }}
        >
          <ListItemIcon>
            <FeedbackOutlinedIcon />
          </ListItemIcon>
          <ListItemText>NGワード管理</ListItemText>
        </MenuItem>
      )}
      {(userPermission.isSuperAdmin || userPermission.isOwnerCompanyAdmin) && (
        <MenuItem
          key="news"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/notification');
          }}
        >
          <ListItemIcon>
            <FeedOutlinedIcon />
          </ListItemIcon>
          <ListItemText>お知らせ登録</ListItemText>
        </MenuItem>
      )}

      {userPermission.isSuperAdmin && (
        <MenuItem
          key="system"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            window.open(env.REACT_APP_CLOUDWATCH_DASHBOARD_URL, '_blank');
          }}
        >
          <ListItemIcon>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText>システム利用状況</ListItemText>
        </MenuItem>
      )}
      {userPermission.isSuperAdmin && (
        <MenuItem
          key="superuser"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/superuser');
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText>スーパーユーザー管理</ListItemText>
        </MenuItem>
      )}
      {userPermission.isSuperAdmin && (
        <MenuItem
          key="tts"
          onClick={() => {
            props.setAccountMenuAnchor(null);
            navigate('/admin/settings/tts');
          }}
        >
          <ListItemIcon>
            <VolumeUpIcon />
          </ListItemIcon>
          <ListItemText>音声合成機能設定</ListItemText>
        </MenuItem>
      )}

      {userPermission.isTenantCompanyAdmin && <Divider key="div2" />}
      {userPermission.isTenantCompanyAdmin && (
        <MenuItem key="room" onClick={() => navigate('/admin/room')}>
          <ListItemIcon>
            <DoorBackIcon />
          </ListItemIcon>
          <ListItemText>ルーム管理</ListItemText>
        </MenuItem>
      )}
      <Divider key="div1" />
      <MenuItem key="logoug" onClick={() => logout()}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>ログアウト</ListItemText>
      </MenuItem>
    </Menu>
  );
};
export default AdminMenu;
