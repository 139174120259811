import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import ImageIcon from '@mui/icons-material/Image';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MicOffIcon from '@mui/icons-material/MicOff';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Box,
  Chip,
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Switch,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatUserRole } from '@/types/graphql';
import { isChatEditor, isChatHost } from '@/utils/userRole';

const ChatMenu = (props: {
  userRole: ChatUserRole;
  isGuestInputRestricted: boolean;
  onClickParticipants: () => void;
  userCount: number;
  onClickInvite: () => void;
  onClickMuteAllUser: () => void;
  onClickRemoveAllUser: () => void;
  onClickTemplateMessage?: () => void;
  onClickClearMessages: () => void;
  onClickTogglePlayMessageEnabled: () => void;
  playMessageEnabled: boolean;
  onClickReverseTranslation: () => void;
  onClickSendImage: () => void;
  onClickChatSettings: () => void;
  onClickFontSettings: () => void;
  onClickDeviceTest: () => void;
  onClickLeaveSession: () => void;
}) => {
  const { t } = useTranslation('chat');

  const canGuideControl = isChatHost(props.userRole);
  const canEditControl = isChatEditor(
    props.isGuestInputRestricted,
    props.userRole
  );
  const [openConfig, setOpenConfig] = useState(canGuideControl === false);

  return (
    <Box display="grid" gridTemplateRows="1fr auto" height={1}>
      <MenuList>
        {canEditControl && (
          <MenuItem onClick={() => props.onClickParticipants()}>
            <ListItemIcon>
              <PeopleAltIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.participants')} />
            <Chip label={props.userCount} size="small" sx={{ ml: 1, mr: 1 }} />
          </MenuItem>
        )}
        {canGuideControl && (
          <MenuItem onClick={() => props.onClickInvite()}>
            <ListItemIcon>
              <PersonAddIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.invitation')} />
          </MenuItem>
        )}
        {canGuideControl && (
          <MenuItem onClick={() => props.onClickMuteAllUser()}>
            <ListItemIcon>
              <MicOffIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.muteAllMics')} />
          </MenuItem>
        )}
        {canGuideControl && (
          <MenuItem onClick={() => props.onClickRemoveAllUser()}>
            <ListItemIcon>
              <GroupRemoveIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.removeAllUsers')} />
          </MenuItem>
        )}
        {canEditControl && <Divider sx={{ mx: 3 }} />}
        {canGuideControl && (
          <MenuItem
            onClick={() => props.onClickTemplateMessage?.()}
            disabled={props.onClickTemplateMessage == null}
          >
            <ListItemIcon>
              <MenuBookIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.templates')} />
          </MenuItem>
        )}
        {canGuideControl && (
          <MenuItem onClick={() => props.onClickClearMessages()}>
            <ListItemIcon>
              <CleaningServicesIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.claerScreen')} />
          </MenuItem>
        )}
        {canEditControl && (
          <MenuItem onClick={() => props.onClickSendImage()}>
            <ListItemIcon>
              <ImageIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.sendImage')} />
          </MenuItem>
        )}
        {canEditControl && (
          <MenuItem onClick={() => props.onClickTogglePlayMessageEnabled()}>
            <ListItemIcon>
              <VolumeUpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.readAloud')} />
            <Switch
              checked={props.playMessageEnabled}
              sx={{ pointerEvents: 'none', my: '-100px', mr: -1 }}
            />
          </MenuItem>
        )}
        {canEditControl && (
          <MenuItem onClick={() => props.onClickReverseTranslation()}>
            <ListItemIcon>
              <GTranslateIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.reverseTranslation')} />
          </MenuItem>
        )}
        {canEditControl && <Divider />}

        <MenuItem onClick={() => setOpenConfig((v) => !v)}>
          <ListItemText
            primary={t('menu.settings')}
            primaryTypographyProps={{
              fontSize: (t) => t.typography.body2.fontSize,
            }}
          />
          {openConfig ? (
            <ExpandLess sx={{ color: (t) => t.palette.grey[600] }} />
          ) : (
            <ExpandMore sx={{ color: (t) => t.palette.grey[600] }} />
          )}
        </MenuItem>
        <Collapse in={openConfig} timeout="auto">
          <MenuItem onClick={() => props.onClickChatSettings()}>
            <ListItemIcon>
              <ManageAccountsIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('menu.userSetting')}
              primaryTypographyProps={{
                fontSize: (t) => t.typography.body2.fontSize,
              }}
            />
          </MenuItem>
          <MenuItem onClick={() => props.onClickFontSettings()}>
            <ListItemIcon>
              <FormatSizeIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('menu.fontSetting')}
              primaryTypographyProps={{
                fontSize: (t) => t.typography.body2.fontSize,
              }}
            />
          </MenuItem>
          {canEditControl && (
            <MenuItem onClick={() => props.onClickDeviceTest()}>
              <ListItemIcon>
                <GraphicEqIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t('settings.TestSpeakersAndMicrophones')}
                primaryTypographyProps={{
                  fontSize: (t) => t.typography.body2.fontSize,
                }}
              />
            </MenuItem>
          )}
        </Collapse>
      </MenuList>
      <MenuList>
        <Divider sx={{ mb: 1, mt: -1 }} />
        <MenuItem onClick={() => props.onClickLeaveSession()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.leave')} />
        </MenuItem>
      </MenuList>
    </Box>
  );
};

export default ChatMenu;
