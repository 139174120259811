export const validLanguages = [
  "ja",
  "en",
  "ko",
  "zh",
  "zh-TW",
  "es",
  "fil",
  "fr",
  "id",
  "my",
  "pt-BR",
  "th",
  "vi",
] as const;
export type Languages = (typeof validLanguages)[number];

export const convertToFeatLanguageCode = (mimiLangCode: string) => {
  if (mimiLangCode === "fil") return "fp";
  else if (mimiLangCode === "zh-TW") return "zh_taiwan";
  else if (mimiLangCode === "pt-BR") return "pt_brazil";
  else return mimiLangCode;
};
