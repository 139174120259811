import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack } from '@mui/material';
import Popper from '@mui/material/Popper';
import { ReactNode } from 'react';

const boxShadow = '0px 0px 6px rgba(0, 0, 0, 0.2)';

type AnchorPosition = 'CenterTop' | 'LeftBottom' | 'RightBottom';

const HintBalloon = (props: {
  anchorEl: HTMLElement | null;
  caption: string;
  onClose?: () => void;
  anchorPosition: AnchorPosition;
  icon?: ReactNode;
}) => {
  return (
    <Popper
      open={props.anchorEl != null}
      anchorEl={props.anchorEl}
      placement={
        props.anchorPosition === 'CenterTop'
          ? 'top'
          : props.anchorPosition === 'LeftBottom'
          ? 'bottom-start'
          : 'bottom-end'
      }
      sx={{
        zIndex: 1500, // 1500 = tooltip
        pointerEvents: 'none',
      }}
    >
      <Box my={3} mx={2} sx={{ pointerEvents: 'all' }}>
        <Box
          sx={{
            background: (t) => t.palette.grey[500],
            borderRadius: '10px',
            boxShadow: boxShadow,
            px: 1,
            py: 0.5,
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            alignItems: 'center',
            color: 'white',
          }}
        >
          <Stack sx={{ mx: 1 }}>{props.icon}</Stack>
          <Box
            my={1}
            sx={{ zIndex: 1501 }} // 1500 = tooltip
          >
            {props.caption}
          </Box>
          {props.onClose ? (
            <IconButton
              onClick={() => {
                props.onClose?.();
              }}
            >
              <CloseIcon
                sx={{
                  color: (t) => t.palette.primary.contrastText,
                }}
                fontSize="small"
              />
            </IconButton>
          ) : (
            <Box sx={{ mx: 1 }} />
          )}
          <Box
            sx={{
              width: '16px',
              height: '16px',
              position: 'absolute',
              right: 0,
              left: 0,
              background: (t) => t.palette.grey[500],
              transform: 'rotate(45deg)',
              ...(props.anchorPosition === 'CenterTop'
                ? {
                    bottom: '18px',
                    marginRight:
                      props.anchorPosition === 'CenterTop' ? 'auto' : '50px',
                    marginLeft: 'auto',
                  }
                : props.anchorPosition === 'RightBottom'
                ? {
                    top: '18px',
                    marginRight: '50px',
                    marginLeft: 'auto',
                  }
                : {
                    top: '18px',
                    marginRight: 'auto',
                    marginLeft: '50px',
                  }),
            }}
          />
        </Box>
      </Box>
    </Popper>
  );
};
export default HintBalloon;
