import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';

import ChatUserAvator from '@/components/molecules/ChatUserAvator';
import { ChatUserRole } from '@/types/graphql';

const ChatFooterReply = (props: {
  reply?: string;
  onCancelReply: () => void;
  sx?: SxProps<Theme>;
  userRole: ChatUserRole;
  userName: string;
}) => {
  return (
    <Box sx={props.sx}>
      <Box
        bgcolor="#EDF8FF"
        borderTop={1}
        borderColor={(t) => t.palette.grey[300]}
        // マイクのフッターが丸みを帯びているので少し下に伸ばす
        pb="50px"
        mb="-50px"
      >
        <Box display="grid" gridTemplateColumns="auto 1fr auto" p={1.5}>
          <ChatUserAvator userRole={props.userRole} sx={{ my: 'auto' }} />
          <Box ml={1} display="grid">
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              fontWeight="bold"
              color={(t) => t.palette.grey[700]}
              ml={0.5}
              pt="9px"
              padding={0}
            >
              {props.userName}
            </Typography>
            <Typography
              variant="caption"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              ml={0.5}
              pt="9px"
              padding={0}
            >
              {props.reply}
            </Typography>
          </Box>
          <IconButton
            onClick={() => props.onCancelReply()}
            size="small"
            sx={{
              mb: 'auto',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatFooterReply;
