import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Languages } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher';

export type ChatUserSetting = {
  userName: string;
  language: Languages;
};

export type ChatUserSettingsDialogProps = {
  open: boolean;
  defaultValue: ChatUserSetting;
  onClose: (value: ChatUserSetting | undefined) => void;
};
type ChatUserSettingsInput = {
  userName: string;
};

const ChatUserSettingsDialog = (props: ChatUserSettingsDialogProps) => {
  const { t } = useTranslation('chat');
  const { t: tCommon } = useTranslation('common');
  const [language, setLanguage] = useState(props.defaultValue.language);

  const joi = useLocalizedJoi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ChatUserSettingsInput>({
    defaultValues: { userName: props.defaultValue.userName },
    resolver: joiResolver(
      joi.object<ChatUserSettingsInput>({
        userName: joi.string().trim().required().min(1).max(100),
      })
    ),
  });

  useEffect(() => {
    if (props.open) {
      setValue('userName', props.defaultValue.userName);
      setLanguage(props.defaultValue.language);
    }
  }, [
    props.open,
    setValue,
    props.defaultValue.userName,
    props.defaultValue.language,
  ]);

  return (
    <Dialog open={props.open} scroll="body" maxWidth="xs">
      <DialogTitle>{t('settings.title')}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ my: 2 }}
        >
          <Stack spacing={2} sx={{ my: 1, width: '100%' }}>
            <TextField
              label={t('userName')}
              {...register('userName')}
              error={'userName' in errors}
              helperText={errors.userName?.message}
              sx={{
                width: 1,
              }}
              variant="outlined"
            />
          </Stack>

          <LanguageSwitcher
            sx={{ width: '100%', textAlign: 'left' }}
            label={tCommon('caption.language')}
            language={language}
            setLanguage={setLanguage}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={() => props.onClose(undefined)}></CancelButton>
          <Button
            onClick={handleSubmit(async (input) => {
              props.onClose({
                userName: input.userName,
                language: language,
              });
            })}
          >
            OK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default ChatUserSettingsDialog;
