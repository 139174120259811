import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useUserPermission } from '@/components/hooks/useUserPermission';
import { ChatRoomWaitingGuest, useChatRoomsQuery } from '@/types/graphql';

const waitingGuestsAtom = atom<Record<string, ChatRoomWaitingGuest[]>>({});

export const useChatroomsWaitingGuestsEnter = (props: {
  onNotifyClick?: (roomId: string) => void;
}) => {
  const { t } = useTranslation('chat');
  const permission = useUserPermission();
  const chatRoomsResult = useChatRoomsQuery({
    variables: { input: {} },
    skip: !permission.isTenantCompanyAdmin,
    pollInterval: 5000,
  });
  const onNotifyClick = props.onNotifyClick;
  const [waitingGuests, setWaitingGuests] = useAtom(waitingGuestsAtom);

  useEffect(() => {
    if (permission.isTenantCompanyAdmin && chatRoomsResult.data != null) {
      chatRoomsResult.data?.chatRooms
        // 通知が有効なルームのみが対象
        .filter((cr) => cr.isWaitingRoomNotificationEnabled)
        .forEach((cr) => {
          // 部屋ごとの現在の待機数と比較し、増えていたら通知を出す
          const current = waitingGuests[cr.id] ?? [];
          const newGuests = cr.waitingGuests.filter(
            (x) => current.find((y) => x.id === y.id) == null
          );
          const removedGuests = current.filter(
            (x) => cr.waitingGuests.find((y) => x.id === y.id) == null
          );

          if (newGuests.length === 0 && removedGuests.length === 0) return;

          if (newGuests.length >= 1) {
            newGuests.forEach((x) => {
              const notification = new Notification(
                t('wating.waitingUserNotifictionTitle'),
                {
                  body: t('wating.waitingUserNotifictionContent', {
                    roomname: cr.name,
                    username: x.name,
                  }),
                }
              );
              if (onNotifyClick)
                notification.onclick = () => onNotifyClick?.(cr.id);
            });
          }

          waitingGuests[cr.id] = cr.waitingGuests;
          setWaitingGuests({ ...waitingGuests });
        });
    }
  }, [
    chatRoomsResult.data,
    onNotifyClick,
    permission.isTenantCompanyAdmin,
    setWaitingGuests,
    t,
    waitingGuests,
  ]);

  return chatRoomsResult;
};
