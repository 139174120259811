import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { env } from '@/env';

const getUserPool = () =>
  new CognitoUserPool({
    UserPoolId: env.REACT_APP_AWS_USER_POOL_ID,
    ClientId: env.REACT_APP_AWS_CLIENT_ID,
  });

export const authenticateUser = (params: {
  username: string;
  password: string;
  onSuccess: (session: CognitoUserSession) => void;
  onFailure: (error: any) => void;
  newPasswordRequired: () => Promise<string | null>;
}) => {
  const authenticationData = {
    Username: params.username,
    Password: params.password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userPool = getUserPool();
  const userData = {
    Username: params.username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: params.onSuccess,
    onFailure: params.onFailure,
    newPasswordRequired: async function () {
      // 新しいパスワードを取得
      const newPassword = await params.newPasswordRequired();
      if (newPassword != null)
        cognitoUser.completeNewPasswordChallenge(newPassword, {}, this);
    },
  });
};

export const signOut = (params: { onSuccess: () => void }) => {
  const userPool = getUserPool();
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.signOut(params.onSuccess);
  }
};

export const changePassword = async (params: {
  oldPwd: string;
  newPwd: string;
  cognitoUserName: string;
}) => {
  const { oldPwd, newPwd, cognitoUserName } = params;
  return new Promise<void>((resolve, reject) => {
    const userPool = getUserPool();
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: cognitoUserName,
    });
    if (cognitoUser != null) {
      const authenticationDetails = new AuthenticationDetails({
        Username: cognitoUserName,
        Password: oldPwd,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          // パスワード変更処理
          cognitoUser.changePassword(oldPwd, newPwd, (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          });
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    } else {
      // ログイン情報がない
      reject('no logined user');
    }
  });
};
