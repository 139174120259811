import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import DropzoneDialog, {
  DropzoneDialogProps,
} from '@/components/organisms/dropzoneDialog/DropzoneDialog';

const DropzoneDialogEx = (
  props: Omit<DropzoneDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <DropzoneDialog open={isOpen} {...props} />;
};

const useDropzoneDialog = () => {
  const openAwaiter = useOpenAwaiter<File[] | undefined>();
  return {
    open: (props: Omit<DropzoneDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <DropzoneDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useDropzoneDialog;
