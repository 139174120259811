import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridColDef, GridLogicOperator } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent } from '../../../components/atoms/AdminPageParts';
import { useCheckPermission } from '../../../components/hooks/useCheckPermission';

import AddButton from '@/components/atoms/AddButton';
import AdminDataGrid, {
  customPagination,
} from '@/components/atoms/AdminDataGrid';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';

import { AdminIndexTab } from '@/components/molecules/admin/AdminIndexTab';
import useLogDownloadByTenantCompanyIdDialog from '@/components/organisms/logDownloadDialog/useLogDownloadByTenantCompanyIdDialog';
import { useTenantCompaniesQuery } from '@/types/graphql';

const TenantIndex = () => {
  const permission = useCheckPermission({
    superAdmin: true,
    ownerCompanyAdmin: true,
  });

  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.tenantAdmin'));
  const navigate = useNavigate();

  const tenantCompaniesResult = useTenantCompaniesQuery({
    variables: { input: {} },
  });
  const logDownloadDialog = useLogDownloadByTenantCompanyIdDialog();

  const [searchingOwnerName, setSearchingOwnerName] = useState('');
  const [searchingGuidemanagerName, setSearchingGuidemanagerName] =
    useState('');
  const [allSearchConditions, setAllSearchConditions] = useState<
    { field: string; value: string }[]
  >([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const lastPage = {
    page: Math.floor(
      (tenantCompaniesResult.data?.tenantCompanies.length ?? 0) / 10
    ),
    itemNumber: (tenantCompaniesResult.data?.tenantCompanies.length ?? 0) % 10,
  };
  const getLastItemIndex = () => {
    if (paginationModel.page !== lastPage.page) {
      return paginationModel.page * paginationModel.pageSize + 10;
    } else {
      return (
        paginationModel.page * paginationModel.pageSize + lastPage.itemNumber
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'ownerCompanyName',
      headerName: t('company.companyName'),
      flex: 2,
    },
    {
      field: 'guideManagerName',
      headerName: t('company.guideManagerName'),
      flex: 2,
    },
    { field: 'roomLimit', headerName: t('company.roomLimit'), flex: 1 },
    {
      field: 'logDownload',
      minWidth: 125,
      headerName: t('common.logDownload'),
      renderCell: (cellValues) => (
        <Stack direction={'row'} spacing={1} sx={{ ml: 'auto' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              logDownloadDialog.open({
                tenantCompanyId: cellValues.id.toString(),
              });
            }}
          >
            <DownloadIcon color="primary" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <AdminContent>
      <LoadingBackdrop open={tenantCompaniesResult.loading} />
      {permission.isOwnerCompanyAdmin && (
        <AdminIndexTab
          selected={1}
          tab1={{ label: 'ユーザー一覧' }}
          tab2={{
            label: 'アナリティクス',
            redirectTo: '/admin/tenant/analytics',
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: (t) => t.palette.common.white,
          p: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ my: 2 }}
        >
          <TextField
            label={t('common.freeWordSearch')}
            size="small"
            onChange={(e) => {
              setSearchingOwnerName(e.target.value);
              setSearchingGuidemanagerName(e.target.value);
            }}
            onBlur={() => {
              setAllSearchConditions([
                { field: 'name', value: searchingOwnerName },
                { field: 'guideManagerName', value: searchingGuidemanagerName },
              ]);
            }}
          />
          {permission.isOwnerCompanyAdmin && (
            <AddButton
              onClick={() => {
                navigate('add');
              }}
            />
          )}
        </Stack>
        <Stack direction={'row'} sx={{ mt: 3, mb: 1 }} alignItems={'flex-end'}>
          <Typography>{t('common.beforeCount')}</Typography>
          <Typography fontWeight={800}>
            {tenantCompaniesResult.data?.tenantCompanies.length}
          </Typography>
          <Typography>{t('common.afterCount')}</Typography>
          <Typography>{`（${
            paginationModel.page * paginationModel.pageSize + 1
          }-${getLastItemIndex()}）`}</Typography>
        </Stack>

        <AdminDataGrid
          autoHeight
          pagination
          slots={{
            pagination: customPagination,
          }}
          onPaginationModelChange={setPaginationModel}
          initialState={{
            pagination: { paginationModel: paginationModel },
          }}
          columns={columns}
          rows={tenantCompaniesResult.data?.tenantCompanies ?? []}
          filterModel={{
            items: allSearchConditions.map((condition, index) => ({
              id: index,
              field: condition.field,
              operator: 'contains',
              value: condition.value,
            })),
            logicOperator: GridLogicOperator.Or,
          }}
          onCellClick={(p) => {
            navigate(`${p.id}`);
          }}
        />
      </Box>
    </AdminContent>
  );
};
export default TenantIndex;
