import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import VadForm, { VadData } from '@/components/molecules/admin/VadForm';
import { useAddVadPresetMutation } from '@/types/graphql';

const VadAdd = () => {
  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.vad'));

  useCheckPermission({ superAdmin: true });
  const navigate = useNavigate();

  const snackbar = useSnackbar();
  const [addVadPreset, addVadPresetResult] = useAddVadPresetMutation();

  return (
    <VadForm
      loading={addVadPresetResult.loading}
      onSubmit={async (input: VadData) => {
        await addVadPreset({
          variables: {
            input: {
              ...input,
              energyOffset: Number(input.energyOffset),
            },
          },
        });
        navigate('../');
        snackbar('success', t('message.registered'));
      }}
    />
  );
};
export default VadAdd;
