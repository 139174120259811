import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

// 削除ボタン
const DeleteButton = (props: ButtonProps) => {
  const { children, startIcon, ...others } = props;
  const { t } = useTranslation('common');
  return (
    <Button color="delete" startIcon={startIcon ?? <DeleteIcon />} {...others}>
      {children ?? t('command.delete')}
    </Button>
  );
};
export default DeleteButton;
