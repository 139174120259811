import { CircularProgress, Stack } from '@mui/material';
import { useState, useEffect, ReactNode } from 'react';

import { useSetUserPermission } from '../components/hooks/useUserPermission';
import { usePermissionQuery } from '../types/graphql';

const PermissionProvider = (props: { children: ReactNode }) => {
  const [isSetPermission, setIsSetPermission] = useState(false);
  const { loading, error, data } = usePermissionQuery({
    pollInterval: 1000 * 60 * 10, // check every 10 min
  });
  const setUserPermission = useSetUserPermission();
  useEffect(() => {
    if (loading) {
      setIsSetPermission(false);
      return;
    }
    if (error) {
      throw error;
    } else if (data?.permission) {
      const { ownerCompanyId, tenantCompanyId, ...permission } =
        data.permission;
      setUserPermission({
        ...permission,
        ownerCompanyId: ownerCompanyId ?? undefined,
        tenantCompanyId: tenantCompanyId ?? undefined,
      });
      setIsSetPermission(true);
    }
  }, [loading, error, data, setUserPermission]);

  return (
    <>
      {isSetPermission ? (
        <>{props.children}</>
      ) : (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ height: '100vh' }}
        >
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};
export default PermissionProvider;
