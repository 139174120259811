import { assertNotNull } from '@remote-voice/utilities';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  SuperUserData,
  SuperUserForm,
} from '../../../components/molecules/admin/SuperUserForm';
import {
  useEditSuperUserMutation,
  useSuperUsersQuery,
} from '../../../types/graphql';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';

const SuperUserEdit = () => {
  useSetHeaderTitle('スーパーユーザー');

  useCheckPermission({ superAdmin: true });
  const params = useParams();
  const superuserId = params.superuserId;

  const [editSuperUser, editSuperUserResult] = useEditSuperUserMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  // 初期値
  const superUserResult = useSuperUsersQuery({
    variables: { input: { id: superuserId } },
  });

  const data = superUserResult.data?.superUsers[0];

  return (
    <SuperUserForm
      loading={superUserResult.loading || editSuperUserResult.loading}
      onSubmit={async (input: SuperUserData) => {
        assertNotNull(superuserId);

        const result = (
          await editSuperUser({
            variables: {
              input: {
                id: superuserId,
                userName:
                  data?.userName === input.userName
                    ? undefined
                    : input.userName,
                newPassword:
                  input.temporaryPassword === ''
                    ? undefined
                    : input.temporaryPassword,
              },
            },
          })
        ).data?.editSuperUser;

        if (result?.__typename === 'UserNameAlreadyExists') {
          return [
            {
              type: 'UserNameAlreadyExists',
              message: result.message,
            },
          ];
        } else {
          navigate('/admin/superuser');
          snackbar('success', '変更しました ');
        }
      }}
      defaultData={useMemo(
        () => ({
          id: data?.id ?? '',
          userName: data?.userName ?? '',
          temporaryPassword: '',
        }),
        [data?.id, data?.userName]
      )}
    />
  );
};
export default SuperUserEdit;
