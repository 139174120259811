import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import FontSizeSwitcher from '@/components/molecules/FontSizeSwitcher';

export type RoomInvitation = {
  url: string;
  guideUrl?: string;
  entryCode?: string;
};

export type ChatFontSettingsDialogProps = {
  open: boolean;
  defaultValue: number;
  onClose: (fontSize: number | undefined) => void;
};

const ChatFontSettingsDialog = (props: ChatFontSettingsDialogProps) => {
  const { t } = useTranslation('chat');
  const [value, setValue] = useState(`${props.defaultValue}`);
  useEffect(() => {
    if (props.open) {
      setValue(`${props.defaultValue}`);
    }
  }, [props.open, props.defaultValue]);

  return (
    <Dialog open={props.open} scroll="body" maxWidth="xs">
      <DialogTitle sx={{ textAlign: 'center', px: 1.5 }}>
        <Stack spacing={0.5}>
          <Typography variant="h6">{t('fontSettings.title')}</Typography>
          <Typography variant="body2">
            {t('fontSettings.description')}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ textAlign: 'center', p: 0, px: 2 }}>
        <FontSizeSwitcher value={value} setValue={setValue} />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={() => props.onClose(undefined)}></CancelButton>
          <Button onClick={() => props.onClose(Number(value))}>OK</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default ChatFontSettingsDialog;
