import Joi from 'joi';

export const getPasswordValidator = (joi: Joi.Root) => {
  return joi
    .string()
    .regex(/[A-Z]/)
    .message('アルファベット大文字を1文字以上含めてください')
    .regex(/[a-z]/)
    .message('アルファベット小文字を1文字以上含めてください')
    .regex(/[0-9]/)
    .message('数字を1文字以上含めてください')
    .min(8)
    .message('8文字以上にしてください')
    .regex(/[ -/:-@[-`{-~]/)
    .message('記号かスペースを1文字以上含めてください')
    .regex(/^\S.*\S$/)
    .message('先頭や末尾にスペースは含められません');
};
