import { Backdrop } from '@mui/material';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

export type PreviewImageBackdropProps = {
  open: boolean;
  onClose: () => void;
  imgProps: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
};
const PreviewImageBackdrop = (props: PreviewImageBackdropProps) => (
  <Backdrop sx={{ zIndex: 100 }} open={props.open} onClick={props.onClose}>
    <img {...props.imgProps} />
  </Backdrop>
);

export default PreviewImageBackdrop;
