import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';
import InvitingRoomDialog, {
  InvitingRoomDialogProps,
} from 'src/components/organisms/invitingRoomDialog/InvitingRoomDialog';

const InvitingRoomDialogEx = (
  props: Omit<InvitingRoomDialogProps, 'open'> & {
    isOpenKey: string;
    container?: (children: React.ReactElement) => React.ReactElement;
  }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  const { container, ...otherProps } = props;
  return container ? (
    container(<InvitingRoomDialog open={isOpen} {...otherProps} />)
  ) : (
    <InvitingRoomDialog open={isOpen} {...otherProps} />
  );
};

const useInvitingRoomDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: async (
      props: Omit<InvitingRoomDialogProps, 'open' | 'onClose'>,
      container?: (children: React.ReactElement) => React.ReactElement //テーマを渡せるようにする
    ) => {
      await openAwaiter.open(
        <InvitingRoomDialogEx
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(true)}
          container={container}
          {...props}
        />
      );
    },
  };
};
export default useInvitingRoomDialog;
