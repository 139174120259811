import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';

export type LeaveChatRoomDialogProps = {
  open: boolean;
  showExitSessionCheck?: boolean;
  onCancel: () => void;
  onLeave: (withExit: boolean) => void;
};

const LeaveChatRoomDialog = (props: LeaveChatRoomDialogProps) => {
  const { t } = useTranslation('chat');
  const [checkedFinishSession, setCheckedFinishSession] = useState(
    props.showExitSessionCheck ?? false
  );
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>{t('menu.leaveTitle')}</DialogTitle>
      {props.showExitSessionCheck && (
        <DialogContent>
          <Typography>
            {t('menu.leaveWithSubscriptionRemoveMessage')}
          </Typography>
          <Typography>{t('menu.leaveWithUserRemoveMessage')}</Typography>
          <FormControlLabel
            sx={{ m: 1 }}
            control={
              <Checkbox
                checked={checkedFinishSession}
                onChange={(ev) => {
                  setCheckedFinishSession(ev.target.checked);
                }}
              />
            }
            label={t('menu.leaveWithUserRemove')}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={props.onCancel} />
          <Button
            onClick={async () => {
              props.onLeave(checkedFinishSession);
            }}
          >
            {t('menu.leaveOk')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default LeaveChatRoomDialog;
