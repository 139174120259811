import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import LogDownloadDialog from '@/components/organisms/logDownloadDialog/LogDownloadDialog';
import { useDownloadCsvByOwnerCompanyIdQuery } from '@/types/graphql';

const LogDownloadByOwnerCompanyIdDialog = (props: {
  onClose: () => void;
  isOpenKey: string;
  ownerCompanyId: string;
}) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);

  const result = useDownloadCsvByOwnerCompanyIdQuery({
    variables: { input: { ownerCompanyId: props.ownerCompanyId } },
  });

  return (
    <LogDownloadDialog
      open={isOpen}
      onClose={props.onClose}
      loading={result.loading}
      hasError={result.error != null}
      downloadUrl={result.data?.downloadCsvByOwnerCompanyId.downloadUrl}
    />
  );
};

const useLogDownloadByOwnerCompanyIdDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: async (props: { ownerCompanyId: string }) => {
      await openAwaiter.open(
        <LogDownloadByOwnerCompanyIdDialog
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(true)}
          {...props}
        />
      );
    },
  };
};
export default useLogDownloadByOwnerCompanyIdDialog;
