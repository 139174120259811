import { Grid } from '@mui/material';
import { ReactNode } from 'react';

// PCレイアウトだと２分割・スマホレイアウトだと２段にするコンポートネント
export const ResponsiveTwoSplit = (props: {
  cellA: ReactNode;
  cellB: ReactNode;
}) => (
  <Grid container spacing={2} alignItems="flex-end">
    <Grid item xs={12} sm={6}>
      {props.cellA}
    </Grid>
    <Grid item xs={12} sm={6}>
      {props.cellB}
    </Grid>
  </Grid>
);
