import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import { HomophoneData } from '@/components/molecules/admin/HomophoneForm';
import HomophoneKanaDialog, {
  HomophoneKanaDialogProps,
} from '@/components/organisms/homophoneKanaDialog/HomophoneKanaDialog';

const HomophoneKanaDialogEx = (
  props: Omit<HomophoneKanaDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <HomophoneKanaDialog open={isOpen} {...props} />;
};

export const useHomophoneKanaDialog = () => {
  const openAwaiter = useOpenAwaiter<
    (HomophoneData & { disabled: boolean })[] | undefined
  >();
  return {
    open: (
      props: Omit<HomophoneKanaDialogProps, 'open' | 'onCancel' | 'onRegist'>
    ) =>
      openAwaiter.open(
        <HomophoneKanaDialogEx
          isOpenKey={openAwaiter.key}
          onCancel={() => openAwaiter.close(undefined)}
          onRegist={(v) => openAwaiter.close(v)}
          {...props}
        />
      ),
  };
};
