import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import Footer from '@/components/atoms/Footer';
import Logo from '@/components/atoms/Logo';
import LogoutButton from '@/components/atoms/LogoutButton';
import useLogout from '@/components/hooks/useLogout';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import { useNotificationsQuery } from '@/types/graphql';

const AdminRoomLayout = () => {
  const navigate = useNavigate();
  const userPermission = useUserPermission();
  const { t } = useTranslation('admin');
  const logout = useLogout();

  const notifications = useNotificationsQuery({
    variables: {
      input: {
        filterOnlyEnabled: true,
        ownerCompanyId: userPermission.ownerCompanyId,
      },
    },
  });
  const notificationCount = notifications.data?.notifications.length ?? 0;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  return (
    <Stack bgcolor={(t) => t.palette.background.default} minHeight="100vh">
      {/* logo header */}
      <Stack
        pt={2}
        px={2}
        width={1}
        direction="row"
        justifyContent="space-between"
      >
        <Logo sx={{ mx: 1 }} />
        <Stack direction="row" spacing={0.5}>
          {userPermission.isTenantCompanyAdmin && (
            <Button
              variant="text"
              sx={{ minWidth: 0, px: 1 }}
              startIcon={<SettingsIcon />}
              onClick={() => navigate('/admin/user')}
            >
              {t('userSettings.userSettings')}
            </Button>
          )}
          <LogoutButton
            variant="text"
            sx={{ minWidth: 0, px: 1 }}
            onClick={() => logout()}
          />

          <IconButton
            color="primary"
            size="small"
            onClick={(ev) => {
              if (notificationCount >= 1) setAnchorEl(ev.currentTarget);
            }}
          >
            <Badge badgeContent={notificationCount} color="info">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={anchorEl != null}
          onClose={() => setAnchorEl(undefined)}
        >
          {notifications.data?.notifications.map((x) => (
            <MenuItem key={x.id}>{x.message}</MenuItem>
          ))}
        </Menu>
      </Stack>

      {/* admin body */}
      <Stack direction={'row'} sx={{ width: '100%', height: '100%', flex: 1 }}>
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            minHeight: '100%',
            height: 'max-content',
          }}
        >
          <Stack sx={{ pb: 2 }} alignItems={'center'}>
            <Outlet />
          </Stack>
        </Box>
      </Stack>

      {/* hooter */}
      <Footer />
    </Stack>
  );
};
export default AdminRoomLayout;
