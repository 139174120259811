import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import AdminMenu from '@/components/organisms/admin/AdminMenu';

const AdminLayout = (props: {
  showCopyright?: boolean;
  backUrl?: string;
  backIcon?: ReactNode;
}) => {
  const [accountMenuAnchor, setAccountMenuAnchor] =
    useState<null | HTMLElement>(null);
  const userPermission = useUserPermission();

  const title = useHeaderTitle();
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        minHeight: '100dvh',
        // height: '100%',
        backgroundColor: (t) => t.palette.background.default,
      }}
    >
      {(userPermission.isSuperAdmin || userPermission.isOwnerCompanyAdmin) && (
        <AdminMenu
          accountMenuAnchor={accountMenuAnchor}
          setAccountMenuAnchor={setAccountMenuAnchor}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: 1,
          p: 1,
          top: 0,
          position: 'sticky',
          zIndex: 10,
          bgcolor: (t) => t.palette.primary.main,
          color: (t) => t.palette.common.white,
        }}
      >
        {/* admin header */}
        <Box width={50}>
          <IconButton onClick={() => navigate(props.backUrl ?? '/admin')}>
            {props.backIcon ?? (
              <HomeIcon sx={{ color: (t) => t.palette.primary.contrastText }} />
            )}
          </IconButton>
        </Box>
        {typeof title === 'string' ? (
          <Typography variant="h6" sx={{ textAlign: 'center', flex: 1 }}>
            {title}
          </Typography>
        ) : (
          <Stack flex={1} justifyContent="center" alignItems="center">
            {title}
          </Stack>
        )}
        <Box width={50}>
          {(userPermission.isSuperAdmin ||
            userPermission.isOwnerCompanyAdmin) && (
            <IconButton
              sx={{ color: (t) => t.palette.common.white }}
              onClick={(e) => setAccountMenuAnchor(e.currentTarget)}
            >
              {accountMenuAnchor ? (
                <CloseIcon sx={{ color: (t) => t.palette.common.white }} />
              ) : (
                <MenuIcon sx={{ color: (t) => t.palette.common.white }} />
              )}
            </IconButton>
          )}
        </Box>
      </Stack>
      {/* admin body */}
      <Box width="100%" flex={1} position="relative">
        <Outlet />
      </Box>
      {/* footer */}
      {props.showCopyright && (
        <Box sx={{ mt: 'auto', mb: 2, position: 'relative', bottom: 0 }}>
          <Typography variant="caption" color={(t) => t.palette.grey[500]}>
            Copyright © 2023 TOPPAN INC.
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
export default AdminLayout;
