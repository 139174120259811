import { joiResolver } from '@hookform/resolvers/joi';

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent, AdminInnerBox } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import {
  useNotificationsQuery,
  useUpsertNotifcationMutation,
} from '@/types/graphql';

type NotificationData = {
  startedAt: Date;
  endedAt: Date;
  disabled: boolean;
  message: string;
};

const NotificationIndex = () => {
  const permission = useCheckPermission({
    superAdmin: true,
    ownerCompanyAdmin: true,
  });

  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.notification'));

  const notifications = useNotificationsQuery({
    variables: { input: { ownerCompanyId: permission.ownerCompanyId } },
  });
  const [upsertNotification, upsertNotificationResult] =
    useUpsertNotifcationMutation();

  // 表示するデータ
  const notification = notifications.data?.notifications.filter((x) =>
    permission.isSuperAdmin
      ? x.ownerCompanyId == null
      : x.ownerCompanyId === permission.ownerCompanyId
  )[0];
  // 公開中かどうかのフラグ
  const timestamp = Date.now();
  const isOpen =
    notification != null &&
    notification.disabled === false &&
    new Date(notification.startedAt).getTime() <= timestamp &&
    timestamp <= new Date(notification.endedAt).getTime();

  const joi = useLocalizedJoi();
  const form = useForm<NotificationData>({
    resolver: joiResolver(
      joi.object<NotificationData>({
        startedAt: joi.date().required(),
        endedAt: joi.date().greater(joi.ref('startedAt')).required(),
        disabled: joi.boolean().required(),
        message: joi.string().trim().max(300).required(),
      })
    ),
  });

  useEffect(() => {
    if (notification != null)
      form.reset({
        startedAt: new Date(notification.startedAt),
        endedAt: new Date(notification.endedAt),
        message: notification.message,
        disabled: notification.disabled,
      });
  }, [form, notification]);

  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return (
    <AdminContent
      component="form"
      onSubmit={form.handleSubmit(async (data, ev) => {
        ev?.preventDefault();
        await upsertNotification({
          variables: {
            input: {
              id: notification?.id,
              startedAt: data.startedAt.toISOString(),
              endedAt: data.endedAt.toISOString(),
              message: data.message,
              disabled: data.disabled,
              ownerCompanyId: permission.ownerCompanyId,
            },
          },
        });
        notifications.refetch();
        snackbar('success', '更新しました');
      })}
    >
      <LoadingBackdrop
        open={upsertNotificationResult.loading || notifications.loading}
      />
      <AdminInnerBox>
        <Typography variant="body1">
          お知らせは掲載開始日に自動で公開され、掲載終了日に自動で非公開になります。
        </Typography>
      </AdminInnerBox>
      <AdminInnerBox
        sx={{ bgcolor: (t) => t.palette.common.white, p: 3, my: 2 }}
      >
        <Stack spacing={2} sx={{ mx: 'auto', maxWidth: '700px' }}>
          <Box>
            <Chip
              color={isOpen ? 'info' : 'default'}
              label={isOpen ? t('notification.open') : '非公開'}
              sx={{ height: '2em', px: 1, mb: 2 }}
            />
          </Box>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Stack direction="row" spacing={1}>
              <Controller
                name="startedAt"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      label={t('notification.startedAt')}
                      format="YYYY/MM/DD"
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={(value) => field.onChange(value?.toString())}
                      slotProps={{
                        textField: {
                          error: 'startedAt' in errors,
                          helperText: errors.startedAt?.message,
                        },
                      }}
                      sx={{ maxWidth: '160px' }}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="startedAt"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      {...field}
                      label={t('notification.time')}
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={(value) => field.onChange(value?.toString())}
                      slotProps={{
                        textField: {
                          error: 'startedAt' in errors,
                          helperText: errors.startedAt?.message,
                        },
                      }}
                      sx={{ maxWidth: '160px' }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Stack>
            <Typography>{t('notification.rangeSymbol')}</Typography>
            <Stack direction="row" spacing={1}>
              <Controller
                name="endedAt"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      label={t('notification.endedAt')}
                      format="YYYY/MM/DD"
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={(value) => field.onChange(value?.toString())}
                      slotProps={{
                        textField: {
                          error: 'endedAt' in errors,
                          helperText: errors.endedAt?.message,
                        },
                      }}
                      sx={{ maxWidth: '160px' }}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="endedAt"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      {...field}
                      label={t('notification.time')}
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={(value) => field.onChange(value?.toString())}
                      slotProps={{
                        textField: {
                          error: 'endedAt' in errors,
                          helperText: errors.endedAt?.message,
                        },
                      }}
                      sx={{ maxWidth: '160px' }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Stack>
          </Stack>

          <Stack>
            <TextField
              label={t('notification.message')}
              multiline
              rows={5}
              InputLabelProps={{ shrink: form.watch('message') != null }}
              {...form.register('message')}
              error={'message' in form.formState.errors}
              helperText={form.formState.errors.message?.message}
            />
          </Stack>

          <Stack>
            <Controller
              name="disabled"
              control={form.control}
              defaultValue={false}
              render={({ field }) => (
                <FormGroup {...field}>
                  <FormControlLabel
                    control={
                      <Checkbox name="check" checked={field.value ?? false} />
                    }
                    label={t('notification.disable')}
                  />
                </FormGroup>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'center'}
            alignItems={'flex-end'}
          >
            <CancelButton size="large" onClick={() => navigate(-1)} />
            <Stack spacing={1}>
              <Typography variant="body2" textAlign={'center'}>
                {t('notification.overwrite')}
              </Typography>
              <Button size="large" variant="contained" type="submit">
                {t('notification.update')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </AdminInnerBox>
    </AdminContent>
  );
};
export default NotificationIndex;
