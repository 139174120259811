import { joiResolver } from '@hookform/resolvers/joi';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpIcon from '@mui/icons-material/Help';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import useHomophoneSelectorDialog from '@/components/organisms/homophoneSelectorDialog/useHomophoneSelectorDialog';
import useTemplateSelectorDialog from '@/components/organisms/templateSelectorDialog/useTemplateSelectorDialog';
import {
  TranslateMethod,
  useHomophoneCategoriesQuery,
  useTemplateMessageCategoriesQuery,
} from '@/types/graphql';

export type ChatRoomData = {
  capacity: number;
  name: string;
  templateCategoryIds: string[];
  homophoneCategoryIds: string[];
  entryCode?: string;
  translateMethod: TranslateMethod;
  isWaitingRoomEnabled: boolean;
  isWaitingRoomNotificationEnabled: boolean;
  isGuestInputRestricted: boolean;
  isNotifyOnMessageEnabled: boolean;
  isGuideMicControlLinked: boolean;
  isGuestMicControlLinked: boolean;
};

const MyHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const RoomForm = (props: {
  loading: boolean;
  onSubmit: (input: ChatRoomData) => Promise<void>;
  defaultData?: ChatRoomData; // 新規登録は defaultDataなし
  children?: ReactElement;
  sessionEditable?: boolean;
  canUseSimulteneous: boolean;
}) => {
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');
  const joi = useLocalizedJoi();

  const templateSelectorDialog = useTemplateSelectorDialog();
  const homophoneSelectorDialog = useHomophoneSelectorDialog();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue,
    getValues,
    control,
  } = useForm<ChatRoomData>({
    defaultValues: {
      ...props.defaultData,
      translateMethod:
        props.defaultData?.translateMethod ?? TranslateMethod.Consecutive,
      templateCategoryIds: props.defaultData?.templateCategoryIds ?? [],
      homophoneCategoryIds: props.defaultData?.homophoneCategoryIds ?? [],
      isWaitingRoomEnabled: props.defaultData?.isWaitingRoomEnabled ?? false,
      isWaitingRoomNotificationEnabled:
        props.defaultData?.isWaitingRoomNotificationEnabled ?? false,
      isGuestInputRestricted:
        props.defaultData?.isGuestInputRestricted ?? false,
      isNotifyOnMessageEnabled:
        props.defaultData?.isNotifyOnMessageEnabled ?? false,
      isGuideMicControlLinked:
        props.defaultData?.isGuideMicControlLinked ?? false,
      isGuestMicControlLinked:
        props.defaultData?.isGuestMicControlLinked ?? false,
      // このプロパティは使用されていないが、今後使用される可能性を考慮してUIからだけ隠す
      capacity: 0,
    },
    resolver: joiResolver(
      joi.object<ChatRoomData>({
        name: joi.string().trim().max(50).required(),
        capacity: joi.number().min(0).max(100).required(),
        templateCategoryIds: joi.array().items(joi.string().uuid()),
        homophoneCategoryIds: joi.array().items(joi.string().uuid()),
        entryCode: joi
          .string()
          .allow('')
          .pattern(/^[0-9]+$/, 'numbers')
          .trim()
          .length(4),
        translateMethod: joi
          .string()
          .valid(TranslateMethod.Consecutive, TranslateMethod.Simulteneous)
          .required(),
        isWaitingRoomEnabled: joi.boolean().required(),
        isWaitingRoomNotificationEnabled: joi.boolean().required(),
        isGuestInputRestricted: joi.boolean().required(),
        isNotifyOnMessageEnabled: joi.boolean().required(),
        isGuideMicControlLinked: joi.boolean().required(),
        isGuestMicControlLinked: joi.boolean().required(),
      })
    ),
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.defaultData) {
      return;
    }
    const defaultData = props.defaultData;
    reset(defaultData);
  }, [props.defaultData, reset, setValue]);

  const templateMessageCategoriesResult = useTemplateMessageCategoriesQuery({
    variables: { input: {} },
  });
  const categories =
    templateMessageCategoriesResult.data?.templateMessageCategories;

  const homophoneCategoriesResult = useHomophoneCategoriesQuery({
    variables: { input: {} },
  });
  const homophoneCategories =
    homophoneCategoriesResult.data?.homophoneCategories;

  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(async (input, ev) => {
        ev?.preventDefault();

        // 通知の許可をリクエスト
        if (
          input.isWaitingRoomNotificationEnabled &&
          Notification.permission === 'default'
        ) {
          await Notification.requestPermission();
        }

        // 保存処理
        props.onSubmit?.(input);
      })}
      py={2}
    >
      <LoadingBackdrop open={props.loading} />

      <AdminContent>
        <Stack spacing={2}>
          <TextField
            {...register('name')}
            label={t('room.name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            InputLabelProps={{ shrink: watch('name') != '' }}
          />
        </Stack>
      </AdminContent>
      <Divider />
      <AdminContent>
        <Stack spacing={1} alignItems={'flex-start'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ mr: 1 }} variant="h5">
              {t('room.standardPhrasesForRoom')}
            </Typography>
            {watch('templateCategoryIds').length > 0 && (
              <>
                <CheckCircleOutlineIcon color="primary" />
                <Typography color={'primary'}>{t('room.selected')}</Typography>
              </>
            )}
          </Stack>
          <Button
            variant="outlined"
            startIcon={<MenuBookIcon />}
            onClick={async () => {
              const selectedCategories = await templateSelectorDialog.open({
                templateCategories: categories ?? [],
                defaultSelection: getValues().templateCategoryIds,
              });
              if (selectedCategories != null)
                setValue('templateCategoryIds', selectedCategories, {
                  shouldDirty: true,
                });
            }}
            sx={{ textTransform: 'none' }}
          >
            {t('room.selectTemplateMessages')}
          </Button>

          {watch('templateCategoryIds').length >= 1 && (
            <Box>
              <Typography>{`＜${t('room.selected')}＞`}</Typography>
              <Stack direction={'row'} justifyContent="flex-start">
                {watch('templateCategoryIds').map((category, index) => (
                  <Typography key={category}>
                    {`${index < 1 ? '' : ' / '}${index + 1}.${
                      categories?.find((cat) => cat.id === category)?.name
                    }`}
                  </Typography>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      </AdminContent>
      <Divider />
      <AdminContent>
        <Stack spacing={1} alignItems={'flex-start'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ mr: 1 }} variant="h5">
              {t('room.homophonesForRoom')}
            </Typography>
            {watch('homophoneCategoryIds').length > 0 && (
              <>
                <CheckCircleOutlineIcon color="primary" />
                <Typography color={'primary'}>{t('room.selected')}</Typography>
              </>
            )}
          </Stack>
          <Button
            variant="outlined"
            startIcon={<BookmarkIcon />}
            onClick={async () => {
              const selectedHomophoneCategories =
                await homophoneSelectorDialog.open({
                  homophoneCategories: homophoneCategories ?? [],
                  defaultSelection: getValues().homophoneCategoryIds,
                });
              if (selectedHomophoneCategories != null)
                setValue('homophoneCategoryIds', selectedHomophoneCategories, {
                  shouldDirty: true,
                });
            }}
            sx={{ textTransform: 'none' }}
          >
            {t('room.homophonesSelector')}
          </Button>

          {watch('homophoneCategoryIds').length >= 1 && (
            <Box>
              <Typography>{`＜${t('room.selected')}＞`}</Typography>
              <Stack direction={'row'} justifyContent="flex-start">
                {watch('homophoneCategoryIds').map((category, index) => (
                  <Typography key={category}>
                    {`${index < 1 ? '' : ' / '}${
                      homophoneCategories?.find((cat) => cat.id === category)
                        ?.name
                    }`}
                  </Typography>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      </AdminContent>
      <Divider />
      <AdminContent>
        <Stack spacing={1} alignItems={'flex-start'}>
          <Typography variant="h5">{t('room.translationMethod')}</Typography>
          <Controller
            control={control}
            name="translateMethod"
            render={({ field }) => (
              <RadioGroup row {...field}>
                <Stack direction={'row'} alignItems={'center'} sx={{ mr: 3 }}>
                  <FormControlLabel
                    control={
                      <Radio disabled={props.canUseSimulteneous === false} />
                    }
                    label={t('room.translationRealtime')}
                    value={TranslateMethod.Simulteneous}
                    sx={{ mr: 1 }}
                  />
                  <Tooltip
                    title={t('room.translationRealtimeHelpMsg')}
                    arrow
                    placement="top"
                  >
                    <MyHelpIcon />
                  </Tooltip>
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                  <FormControlLabel
                    control={<Radio />}
                    label={t('room.translationConsective')}
                    value={TranslateMethod.Consecutive}
                    sx={{ mr: 1 }}
                  />
                  <Tooltip
                    title={t('room.translationConsectiveHelpMsg')}
                    arrow
                    placement="top"
                  >
                    <MyHelpIcon />
                  </Tooltip>
                </Stack>
              </RadioGroup>
            )}
          />
        </Stack>
      </AdminContent>
      <Divider />
      <AdminContent>
        <Stack spacing={1} alignItems={'flex-start'}>
          <Typography variant="h5">{t('room.otherSettings')}</Typography>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!!props.defaultData && !props.sessionEditable}
                  checked={!!watch('entryCode')}
                  onChange={(ev) => {
                    if (ev.target.checked)
                      setValue(
                        'entryCode',
                        ('000' + Math.floor(Math.random() * 10000)).slice(-4),
                        { shouldDirty: true }
                      );
                    else
                      setValue('entryCode', undefined, { shouldDirty: true });
                  }}
                />
              }
              label={t('room.generateEntiryCode')}
              sx={{ mr: 1 }}
            />
            <Tooltip
              title={t('room.generateEntiryCodeHelpMsg')}
              arrow
              placement="top"
            >
              <HelpIcon sx={{ color: (t) => t.palette.grey[600] }} />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
            <Controller
              name="isWaitingRoomEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(ev, checked) => {
                        field.onChange(ev, checked);
                        if (checked === false)
                          setValue('isWaitingRoomNotificationEnabled', false);
                      }}
                      checked={field.value}
                    />
                  }
                  label={t('room.isWaitingRoomEnabled')}
                />
              )}
            />
            <Tooltip
              title={t('room.isWaitingRoomEnabledTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 4 }}>
            <Controller
              name="isWaitingRoomNotificationEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  disabled={watch('isWaitingRoomEnabled') === false}
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t('room.isWaitingRoomNotificationEnabled')}
                />
              )}
            />
            <Tooltip
              title={t('room.isWaitingRoomNotificationEnabledTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
            <Controller
              name="isGuestInputRestricted"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t('room.isGuestInputRestricted')}
                />
              )}
            />
            <Tooltip
              title={t('room.isGuestInputRestrictedTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
            <Controller
              name="isNotifyOnMessageEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t('room.isNotifyOnMessageEnabled')}
                />
              )}
            />
            <Tooltip
              title={t('room.isNotifyOnMessageEnabledTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
            <Controller
              name="isGuideMicControlLinked"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(ev, checked) => {
                        field.onChange(ev, checked);
                        if (checked === false)
                          setValue('isGuestMicControlLinked', false);
                      }}
                      checked={field.value}
                    />
                  }
                  label={t('room.isGuideMicControlLinked')}
                />
              )}
            />
            <Tooltip
              title={t('room.isGuideMicControlLinkedTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 4 }}>
            <Controller
              name="isGuestMicControlLinked"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  disabled={watch('isGuideMicControlLinked') === false}
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t('room.isGuestMicControlLinked')}
                />
              )}
            />
            <Tooltip
              title={t('room.isGuestMicControlLinkedTooltip')}
              arrow
              placement="top"
            >
              <MyHelpIcon />
            </Tooltip>
          </Stack>
        </Stack>
      </AdminContent>
      <Divider />
      <AdminContent>
        <Stack direction={'row'} justifyContent={'center'}>
          <CancelButton
            onClick={() => navigate('/admin/room')}
            sx={{
              mx: 'auto',
              display: 'block',
              m: 1,
              bgcolor: (t) => t.palette.common.white,
            }}
          />
          <Button
            type="submit"
            sx={{ mx: 'auto', display: 'block', m: 1 }}
            disabled={!isDirty}
          >
            {props.defaultData
              ? commonT('command.saveChanges')
              : t('room.issueRoom')}
          </Button>
        </Stack>
      </AdminContent>
    </Box>
  );
};
