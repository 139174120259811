import HideImageIcon from '@mui/icons-material/HideImage';
import { Box, CircularProgress } from '@mui/material';
import { assertNotNull, sleep } from '@remote-voice/utilities';
import axios from 'axios';
import { openDB, DBSchema } from 'idb';
import Queue from 'promise-queue';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import useDownloadableImageDialog from '@/components/organisms/downloadableImageDialog/useDownloadableImageDialog';
import { useFilesLazyQuery } from '@/types/graphql';

export interface ChatImage {
  data: string; // base64
  type: string;
}

interface ChatImageDBSchema extends DBSchema {
  chatImage: {
    key: string;
    value: ChatImage;
  };
}

function bufferToBase64(arrayBuffer: any) {
  let binaryString = '';
  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binaryString += String.fromCharCode(bytes[i]); // 非効率な気がする
  }
  return btoa(binaryString);
}

type ImageData = {
  id: string;
  type: string;
  data: string; // base64
};

const promiseQueue = new Queue(1);

// gql-admin上で管理しているS3ファイルのGUIDを渡すことで、一時URLを取得して画像を表示するコンポーネント
const ImageFiles = (props: {
  imageIds: string[];
  chatUser?: {
    chatRoomId: string;
    sessionEntryCode: string;
  };
}) => {
  const { t } = useTranslation('common');
  const [getFiles] = useFilesLazyQuery();
  const [images, setImages] = useState<ImageData[]>([]);
  const loadingBackdrop = useLoadingBackdrop();
  const showSnackbar = useSnackbar();

  const imageBackdrop = useDownloadableImageDialog();

  useEffect(() => {
    const imageIds = props.imageIds;

    // ステートをクリア
    setImages(imageIds.map((x) => ({ id: x, data: '', type: '' })));

    // 並列実行実行されることを抑制しつつ、バックグラウンド実行
    promiseQueue.add(async () => {
      // キャッシュからデータを取得する
      const existIds: string[] = [];
      {
        const db = await openDB<ChatImageDBSchema>('RV_CACHE_IMAGE', 1, {
          upgrade: (db) => db.createObjectStore('chatImage'),
        });
        for (const id of imageIds) {
          const image = await db.get('chatImage', id);
          if (image != null) {
            setImages((images) => {
              const newImages = images.concat();
              const index = newImages.findIndex((x) => x.id === id);
              newImages[index].data = image.data;
              newImages[index].type = image.type;
              return newImages;
            });
            existIds.push(id);
          }
        }
      }
      // DLの必要があるIDを抽出
      const noExistIds = imageIds.filter(
        (x) => existIds.find((y) => y === x) == null
      );

      if (noExistIds.length > 0) {
        // キャッシュがない画像のDLリンクを取得
        let filesInfo = await getFiles({
          variables: { input: { ids: noExistIds, chatUser: props.chatUser } },
        });
        if (filesInfo.error != null) {
          // サーバー側でオブジェクトが取得できなかった場合は、もう一度だけリトライ
          if (
            filesInfo.error.graphQLErrors.some(
              (e) => e.extensions?.code === 'FILEOBJECT_NOT_FOUND'
            )
          ) {
            console.info('retrying fetch object.');
            await sleep(1000);
            filesInfo = await getFiles({
              variables: {
                input: { ids: noExistIds, chatUser: props.chatUser },
              },
            });
            if (filesInfo.error) throw filesInfo.error;
          } else throw filesInfo.error;
        }
        assertNotNull(filesInfo.data);
        const files = filesInfo.data.files;

        for (const file of files) {
          const errors = ['error', 'not exists', 'something wrong'];

          let base64str = 'notfound';
          if (errors.find((x) => x === file.signedURL) == null) {
            // エラーでなければ画像データを取得
            const dlFile = await axios.get(file.signedURL, {
              responseType: 'arraybuffer',
            });
            base64str = bufferToBase64(dlFile.data); // base64文字に変換
          }

          // ステート更新
          setImages((images) => {
            const newImages = images.concat();
            const index = newImages.findIndex((x) => x.id === file.id);
            newImages[index].data = base64str;
            newImages[index].type = file.fileType;
            return newImages;
          });

          // DBにキャッシュを保存する
          {
            const db = await openDB<ChatImageDBSchema>('RV_CACHE_IMAGE', 1, {
              upgrade: (db) => db.createObjectStore('chatImage'),
            });
            await db.put(
              'chatImage',
              { type: file.fileType, data: base64str },
              file.id
            );
          }
        }
      }
    });
  }, [getFiles, props.imageIds, props.chatUser]);

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {props.imageIds
        .map((x) => images.find((y) => y.id === x))
        .map((image, i) => {
          const data = image?.data ?? '';
          return (
            <Box
              width={100}
              height={100}
              key={i}
              onClick={() => {
                loadingBackdrop.open(async () => {
                  // 一時DL用URLを取得して別ウィンドウで表示する
                  assertNotNull(image);
                  const filesInfo = await getFiles({
                    variables: {
                      input: { ids: [image.id], chatUser: props.chatUser },
                    },
                  });
                  if (filesInfo.error) throw filesInfo.error;
                  const file = filesInfo.data?.files[0];
                  assertNotNull(file);

                  const errors = ['error', 'not exists', 'something wrong'];
                  if (errors.find((x) => x === file.signedURL)) {
                    // エラーによりURLを取得できない場合スナックバー表示
                    showSnackbar('error', t('message.notfound'));
                    return;
                  }

                  // モーダルで画像を出す
                  imageBackdrop.open({
                    imgProps: {
                      src: file.signedURL,
                      alt: file.fileName,
                      style: {
                        maxWidth: '100%',
                        maxHeight: '100%',
                      },
                    },
                  });
                });
              }}
            >
              <Box
                width={1}
                height={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {data === '' ? (
                  <CircularProgress color="primary" sx={{ mx: '0' }} />
                ) : data === 'notfound' ? (
                  <HideImageIcon sx={{ opacity: 0.5 }} />
                ) : (
                  <img
                    src={`data:image/${image?.type};base64,` + image?.data}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
export default ImageFiles;
