import { assertNotNull } from '@remote-voice/utilities';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { ChatRoomData, RoomForm } from '@/components/molecules/admin/RoomForm';
import {
  ChatSessionState,
  TranslateMethod,
  useChatRoomsQuery,
  useEditChatRoomMutation,
  useEditChatSessionMutation,
  useOwnerCompaniesQuery,
} from '@/types/graphql';

const RoomEdit = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.roomEdit'));

  const snackbar = useSnackbar();

  const params = useParams();
  const roomId = params.roomId;

  const [editChatRoom, editChatRoomResult] = useEditChatRoomMutation();
  const [editChatSession, editChatSessionResult] = useEditChatSessionMutation();
  const ownerCompanyResult = useOwnerCompaniesQuery({
    variables: { input: {} },
  });

  const navigate = useNavigate();

  const chatRoomResult = useChatRoomsQuery({
    variables: { input: { id: roomId } },
  });
  const currentSession = chatRoomResult.data?.chatRooms[0]?.sessions.find(
    (session) =>
      session.state === ChatSessionState.NotStarted ||
      session.state === ChatSessionState.Progressing
  );

  const onSubmit = async (input: ChatRoomData) => {
    const chatRoom = await editChatRoom({
      variables: {
        input: {
          name: input.name,
          templateCategoryIds: input.templateCategoryIds,
          homophoneCategoryIds: input.homophoneCategoryIds,
          capacity: input.capacity,
          id: roomId ?? '',
          translateMethod: input.translateMethod,
          isWaitingRoomEnabled: input.isWaitingRoomEnabled,
          isWaitingRoomNotificationEnabled:
            input.isWaitingRoomNotificationEnabled,
          isGuestInputRestricted: input.isGuestInputRestricted,
          isNotifyOnMessageEnabled: input.isNotifyOnMessageEnabled,
          isGuideMicControlLinked: input.isGuideMicControlLinked,
          isGuestMicControlLinked: input.isGuestMicControlLinked,
        },
      },
    });
    if (chatRoom.errors) throw chatRoom.errors;
    assertNotNull(chatRoom.data);

    const chatSession = await editChatSession({
      variables: {
        input: {
          id: currentSession?.id ?? '',
          chatRoomId: chatRoom.data.editChatRoom.id,
          entryCode: input.entryCode ?? '',
        },
      },
    });
    if (chatSession.errors) throw chatSession.errors;

    navigate('/admin/room');
    snackbar('success', t('message.successSaveChanges'));
  };

  const data = chatRoomResult.data?.chatRooms[0];
  return (
    <RoomForm
      loading={editChatRoomResult.loading || editChatSessionResult.loading}
      defaultData={useMemo(
        () => ({
          capacity: data?.capacity ?? 0,
          name: data?.name ?? '',
          templateCategoryIds: data?.templateCategoryIds ?? [],
          homophoneCategoryIds: data?.homophoneCategoryIds ?? [],
          translateMethod:
            data?.translateMethod ?? TranslateMethod.Simulteneous,
          entryCode: currentSession?.entryCode ?? '',
          isWaitingRoomEnabled: data?.isWaitingRoomEnabled ?? false,
          isWaitingRoomNotificationEnabled:
            data?.isWaitingRoomNotificationEnabled ?? false,
          isGuestInputRestricted: data?.isGuestInputRestricted ?? false,
          isNotifyOnMessageEnabled: data?.isNotifyOnMessageEnabled ?? false,
          isGuideMicControlLinked: data?.isGuideMicControlLinked ?? false,
          isGuestMicControlLinked: data?.isGuestMicControlLinked ?? false,
        }),
        [
          data?.capacity,
          data?.name,
          data?.templateCategoryIds,
          data?.homophoneCategoryIds,
          data?.translateMethod,
          data?.isWaitingRoomEnabled,
          data?.isWaitingRoomNotificationEnabled,
          data?.isGuestInputRestricted,
          data?.isNotifyOnMessageEnabled,
          data?.isGuideMicControlLinked,
          data?.isGuestMicControlLinked,
          currentSession?.entryCode,
        ]
      )}
      canUseSimulteneous={
        ownerCompanyResult.data?.ownerCompanies[0]?.canUseSimulteneous ?? false
      }
      onSubmit={onSubmit}
      sessionEditable={currentSession?.state === ChatSessionState.NotStarted}
    />
  );
};
export default RoomEdit;
