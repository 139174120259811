import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  OwnerCompanyData,
  OwnerForm,
} from '../../../components/molecules/admin/OwnerForm';
import { useAddOwnerCompanyMutation } from '../../../types/graphql';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';

const OnwerAdd = () => {
  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.ownerAdmin'));

  useCheckPermission({ superAdmin: true });
  const [addOwnerCompany, addOwnerCompanyResult] = useAddOwnerCompanyMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  return (
    <OwnerForm
      requirePassword
      onSubmit={async (input: OwnerCompanyData) => {
        const result = (await addOwnerCompany({ variables: { input } })).data
          ?.addOwnerCompany;

        if (result?.__typename === 'UserNameAlreadyExists') {
          return [
            {
              type: 'UserNameAlreadyExists',
              message: result?.message,
            },
          ];
        } else {
          navigate('/admin/owner');
          snackbar('success', '登録しました');
        }
      }}
      loading={addOwnerCompanyResult.loading}
    />
  );
};
export default OnwerAdd;
