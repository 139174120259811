/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_GQL_GATEWAY_URL: string;
  REACT_APP_GQL_SUBSCRIPTION_URL: string;
  REACT_APP_LOCAL_DEV: string;
  REACT_APP_LOGOUT_ENDPOINT: string;
  REACT_APP_MUI_LICENSE: string;
  REACT_APP_AWS_USER_POOL_ID: string;
  REACT_APP_AWS_CLIENT_ID: string;
  REACT_APP_CLOUDWATCH_DASHBOARD_URL: string;
  REACT_APP_MINI_BACKEND_ID: string;
  REACT_APP_TTS_FORMAT: string;
  REACT_APP_MINI_RECOGNITION_URL: string;
  REACT_APP_MINI_SIMULTANEOUS_TRANSLATION_URL: string;
  REACT_APP_MINI_TTS_MIMI_URL: string;
  REACT_APP_MINI_TTS_AZURE_URL: string;
  REACT_APP_MIMI_TTS_FEAT_URL: string;
  REACT_APP_SEQ_ERR_TIMEOUT: string;
  REACT_APP_UNAUTHRIZED_REDIRECT_URL: string;
  REACT_APP_OVERWRITE_PING_INTERVAL_MS?: number;
  REACT_APP_OVERWRITE_PING_THRESHOLD_INTERVAL_MS?: number;
  REACT_APP_SIMULTANEOUS_TRANSLATION_RESPONSE_TIMEOUT: string;
  REACT_APP_WEBPUSH_VAPID_PUBLIC_KEY: string;
};
export const env: EnvType = { ...process.env, ...window.env };
