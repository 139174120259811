import { Typography, TypographyProps } from '@mui/material';

export const LineClampTypography = (
  props: TypographyProps & { rowCount: number }
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rowCount, ...typographyProps } = props;
  return (
    <Typography
      {...typographyProps}
      sx={{
        display: '-webkit-box',
        whiteSpace: 'pre-wrap',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: rowCount,
        ...(props.sx ?? {}),
      }}
    />
  );
};
