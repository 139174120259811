import { useAtom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const userNameAtom = atomWithStorage<string>(
  'RV_USER_NAME',
  'Guest_' +
    Math.round(Math.random() * 999)
      .toString()
      .padStart(3, '0')
);

export const useSetUserName = () => useSetAtom(userNameAtom);
export const useUserName = () => useAtom(userNameAtom)[0];
