import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import { SuperUserData } from '../../../components/molecules/admin/SuperUserForm';
import { useAddSuperUserMutation } from '../../../types/graphql';

import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { SuperUserForm } from '@/components/molecules/admin/SuperUserForm';

const SuperUserAdd = () => {
  useSetHeaderTitle('スーパーユーザー');

  useCheckPermission({ superAdmin: true });
  const [addSuperUser, addSuperUserResult] = useAddSuperUserMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  return (
    <SuperUserForm
      requirePassword
      onSubmit={async (input: SuperUserData) => {
        const result = (await addSuperUser({ variables: { input } })).data
          ?.addSuperUser;

        if (result?.__typename === 'UserNameAlreadyExists') {
          return [
            {
              type: 'UserNameAlreadyExists',
              message: result?.message,
            },
          ];
        } else {
          navigate('/admin/superuser');
          snackbar('success', '登録しました');
        }
      }}
      loading={addSuperUserResult.loading}
    />
  );
};
export default SuperUserAdd;
