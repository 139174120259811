import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef, useState } from 'react';

// eslint-disable-next-line react/display-name
export const PasswordField = forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const [openPwd, setOpenPwd] = useState(false);

    return (
      <TextField
        {...props}
        ref={ref}
        type={openPwd ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                onClick={() => setOpenPwd(!openPwd)}
                edge="end"
              >
                {openPwd ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
