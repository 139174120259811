import AnalyticsIcon from '@mui/icons-material/Analytics';
import ListIcon from '@mui/icons-material/List';
import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AdminIndexTab = (props: {
  selected: 1 | 2;
  tab1: {
    label: string;
    redirectTo?: string;
  };
  tab2: {
    label: string;
    redirectTo?: string;
  };
}) => {
  const navigate = useNavigate();

  return (
    <Tabs
      value={1}
      sx={{
        '& .MuiTabs-flexContainer': {
          gap: 2,
        },
        '& .MuiTab-root': {
          width: 200,
          minHeight: 50,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      }}
      TabIndicatorProps={{
        hidden: true,
      }}
    >
      <Tab
        sx={{
          backgroundColor: (t) =>
            props.selected === 1
              ? t.palette.common.white
              : t.palette.secondary.main,
        }}
        label={props.tab1.label}
        value={1}
        icon={<ListIcon />}
        iconPosition="start"
        onClick={() => {
          if (props.tab1.redirectTo != null) navigate(props.tab1.redirectTo);
        }}
      />
      <Tab
        sx={{
          backgroundColor: (t) =>
            props.selected === 2
              ? t.palette.common.white
              : t.palette.secondary.main,
        }}
        label={props.tab2.label}
        value={2}
        icon={<AnalyticsIcon />}
        iconPosition="start"
        onClick={() => {
          if (props.tab2.redirectTo != null) navigate(props.tab2.redirectTo);
        }}
      />
    </Tabs>
  );
};
