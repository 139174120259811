import { Divider, Popover, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import { AnalyticsRoom } from '@/types/graphql';

export const AnalyticsRoomDetailTooltip = (
  props: AnalyticsRoom & { date: string; isOpenKey: string; ancherEl: Element }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  const { t: tCommon } = useTranslation('common');

  return (
    <Popover
      sx={{
        pointerEvents: 'none',
      }}
      open={isOpen}
      anchorEl={props.ancherEl}
      disableRestoreFocus
    >
      <Stack spacing={1} m={2} minWidth={200}>
        <Typography variant="h6">{props.date}</Typography>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">ガイド言語</Typography>
          <Typography variant="subtitle1">
            {tCommon(`languages.${props.guideLanguage}` as any)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">参加者数</Typography>
          <Typography variant="subtitle1">{props.userCount}人</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">発言数</Typography>
          <Typography variant="subtitle1">{props.messageCount}件</Typography>
        </Stack>
      </Stack>
    </Popover>
  );
};
