import { Box, Stack, TextField, Typography } from '@mui/material';
import { GridColDef, GridLogicOperator } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import { useSuperUsersQuery } from '../../../types/graphql';

import AddButton from '@/components/atoms/AddButton';
import AdminDataGrid, {
  customPagination,
} from '@/components/atoms/AdminDataGrid';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';

const SuperUserIndex = () => {
  useCheckPermission({ superAdmin: true });

  const { t } = useTranslation('admin');
  useSetHeaderTitle('スーパーユーザー');
  const navigate = useNavigate();

  const superUsersResult = useSuperUsersQuery({
    variables: { input: {} },
  });

  const [searchingSuperUserName, setSearchingSuperUserName] = useState('');
  const [allSearchConditions, setAllSearchConditions] = useState<
    { field: string; value: string }[]
  >([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const lastPage = {
    page: Math.floor((superUsersResult.data?.superUsers.length ?? 0) / 10),
    itemNumber: (superUsersResult.data?.superUsers.length ?? 0) % 10,
  };
  const getLastItemIndex = () => {
    if (paginationModel.page !== lastPage.page) {
      return paginationModel.page * paginationModel.pageSize + 10;
    } else {
      return (
        paginationModel.page * paginationModel.pageSize + lastPage.itemNumber
      );
    }
  };

  const columns: GridColDef[] = [
    { field: 'userName', headerName: t('company.email'), flex: 1 },
  ];

  return (
    <AdminContent>
      <LoadingBackdrop open={superUsersResult.loading} />
      <Box
        sx={{
          bgcolor: (t) => t.palette.common.white,
          p: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ my: 2 }}
        >
          <TextField
            label={t('common.freeWordSearch')}
            size="small"
            onChange={(e) => {
              setSearchingSuperUserName(e.target.value);
            }}
            onBlur={() => {
              setAllSearchConditions([
                { field: 'userName', value: searchingSuperUserName },
              ]);
            }}
          />
          <AddButton
            onClick={() => {
              navigate('add');
            }}
          />
        </Stack>
        <Stack direction={'row'} sx={{ mt: 3, mb: 1 }} alignItems={'flex-end'}>
          <Typography>{t('common.beforeCount')}</Typography>
          <Typography fontWeight={800}>
            {superUsersResult.data?.superUsers.length}
          </Typography>
          <Typography>{t('common.afterCount')}</Typography>
          <Typography>{`（${
            paginationModel.page * paginationModel.pageSize + 1
          }-${getLastItemIndex()}）`}</Typography>
        </Stack>
        <AdminDataGrid
          pageSizeOptions={[10]}
          autoHeight
          pagination
          slots={{
            pagination: customPagination,
          }}
          onPaginationModelChange={setPaginationModel}
          initialState={{
            pagination: { paginationModel: paginationModel },
          }}
          columns={columns}
          rows={superUsersResult.data?.superUsers ?? []}
          filterModel={{
            items: allSearchConditions.map((condition, index) => ({
              id: index,
              field: condition.field,
              operator: 'contains',
              value: condition.value,
            })),
            logicOperator: GridLogicOperator.And,
          }}
          onCellClick={(p) => {
            navigate(`${p.id}`);
          }}
        />
      </Box>
    </AdminContent>
  );
};
export default SuperUserIndex;
