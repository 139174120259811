/* eslint-disable @typescript-eslint/no-unused-vars */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Input,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import EditButton from '@/components/atoms/EditButton';
import useDisplayLanguage from '@/components/hooks/useDisplayLanguage';
import { TemplateMessageData } from '@/components/molecules/admin/TemplateForm';
import useConfirmDialog from '@/components/organisms/confirmDialog/useConfirmDialog';

export const TemplateTranslate = (props: {
  form: ReturnType<typeof useForm<TemplateMessageData>>;
  templateIndex: number;
  language: string;
  checked: boolean;
  onChangeMessage: (value: string) => void;
  onChangeSorceMessage: (value: string) => void;
  onCheck: (checked: boolean) => void;
}) => {
  const { t, i18n } = useTranslation('admin');
  const confirmDialog = useConfirmDialog(false);
  const userLang = i18n.language;
  const idx = props.templateIndex;
  const errorLangMsg = (
    props.form.formState.errors.languages?.[idx]?.message as any
  )?.message.toString();
  const errorSourceMsg = (
    props.form.formState.errors.languages?.[idx]?.sourceMsg as any
  )?.message.toString();

  const [expanded, setExpanded] = useState(false);

  const language = useDisplayLanguage();

  // 訳文の変更を検知して、アコーディオンを開く
  const detectChangeMessage = props.form.watch(`languages.${idx}.message`);
  useEffect(() => {
    if (detectChangeMessage) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [detectChangeMessage]);

  // エラーが発生した場合は、アコーディオンを自動で開く
  useEffect(() => {
    if (props.form.formState.errors.languages?.[props.templateIndex]?.message) {
      setExpanded(true);
    }
  }, [props.form.formState.errors, props.templateIndex]);

  // 訳文への入力を待機し、一定時間入力が無ければ逆翻訳を掛けるタイマー
  let transMsgTimer: NodeJS.Timeout;

  // readonlyフラグの変更を監視
  const isCsvImported = props.form.watch(`languages.${idx}.readonly`);

  return (
    <Accordion
      disabled={userLang === props.language}
      onChange={() => setExpanded(!expanded)}
      expanded={userLang === props.language ? false : expanded}
      sx={{
        margin: '0 !important',
        boxShadow: 'none',
        borderBottom: (t) => `2px solid ${t.palette.divider}`,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 1,
          '& .MuiAccordionSummary-content': {
            my: 1.5,
          },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          pl={1}
          onClick={(ev) => ev.stopPropagation()}
        >
          <Checkbox
            sx={{ p: 0.5 }}
            checked={props.checked}
            onChange={(_, checked) => props.onCheck(checked)}
          />
          <Typography variant="h6">{language(props.language)}</Typography>
        </Stack>
        {/* 言語名もフォーム項目の一部として管理 */}
        <input
          type="hidden"
          value={props.language}
          {...props.form.register(`languages.${idx}.language`)}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ px: 1, py: 0 }}>
        <Stack spacing={1} pb={3}>
          <Stack spacing={1}>
            <Typography variant="body1">
              {t('template.sourceTextForEachLanguages')}
            </Typography>
            <TextField
              {...props.form.register(`languages.${idx}.sourceMsg`)}
              multiline
              rows={3}
              sx={{ lineHeight: '1.5em' }}
              onChange={(ev) => {
                clearTimeout(transMsgTimer);
                transMsgTimer = setTimeout(() => {
                  const value = ev.target.value as string;
                  if (value.length) props.onChangeSorceMessage(ev.target.value);
                }, 2000);
              }}
              error={errorSourceMsg != null}
              helperText={errorSourceMsg ?? ''}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography sx={{ pt: 2 }}>{t('template.translation')}</Typography>
            <Tooltip
              title={isCsvImported ? t('template.csvUnEditableMsg') : ''}
              onClick={
                isCsvImported
                  ? async () => {
                      if (
                        await confirmDialog.open({
                          title: t('template.csvConfirmForceEditMsg'),
                          actions: [
                            <CancelButton
                              key="cancel"
                              onClick={() => confirmDialog.close(false)}
                            />,
                            <EditButton
                              key="edit"
                              onClick={() => confirmDialog.close(true)}
                            ></EditButton>,
                          ],
                        })
                      ) {
                        props.form.setValue(`languages.${idx}.readonly`, false);
                      }
                    }
                  : undefined
              }
            >
              <TextField
                multiline
                rows={3}
                sx={{ lineHeight: '2em' }}
                InputProps={{ readOnly: isCsvImported ?? false }}
                {...props.form.register(`languages.${idx}.message`)}
                onChange={(ev) => {
                  clearTimeout(transMsgTimer);
                  transMsgTimer = setTimeout(() => {
                    props.form
                      .register(`languages.${idx}.message`)
                      .onChange(ev);
                    props.onChangeMessage(
                      props.form.getValues().languages[idx].message
                    );
                  }, 2000);
                }}
                helperText={errorLangMsg ?? ''}
                error={errorLangMsg != null}
              />
            </Tooltip>
          </Stack>
          <Stack spacing={1}>
            <Typography sx={{ pt: 2 }}>
              {t('template.reverseTranslation')}
            </Typography>
            <Input
              multiline
              rows={3}
              readOnly
              sx={{
                borderRadius: 2,
                background: (t) => t.palette.grey[100],
                py: 2,
                px: 1.5,
                lineHeight: '1.5em',
                '&::before': {
                  border: 'none !important',
                },
                '&::after': {
                  border: 'none !important',
                },
              }}
              {...props.form.register(`languages.${idx}.reverseMessage`)}
            />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
