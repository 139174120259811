import heic2any from 'heic2any';

export const convertHeicToJpeg = async (heic: File) => {
  const jpeg = await heic2any({
    blob: new Blob([heic], { type: heic.type }),
    toType: 'image/jpeg',
    quality: 0.8,
  });
  return new File([jpeg as BlobPart], heic.name.replace('.heic', '.jpg'));
};
