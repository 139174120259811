import {
  useOpenableElementIsOpen,
  useOpenableElementVariables,
} from '../../hooks/useOpenableElement';
import useOpenAwaiter from '../../hooks/useOpenAwaiter';

import ChatUserListDialog, {
  ChatUserListDialogProps,
} from '@/components/organisms/chat/ChatUserListDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';
import { ChatSessionUser } from '@/types/graphql';

const ChatUserListDialogEx = (
  props: Omit<ChatUserListDialogProps, 'open' | 'users'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);

  // usersは変動するのでhooksで取得した物を渡す
  const users = useOpenableElementVariables(
    props.isOpenKey
  ) as ChatSessionUser[];

  return (
    <ChatThemeProvider>
      <ChatUserListDialog open={isOpen} {...props} users={users} />
    </ChatThemeProvider>
  );
};

const useChatUserListDialog = (users: ChatSessionUser[]) => {
  const openAwaiter = useOpenAwaiter<boolean>(users);

  return {
    open: async (
      props: Omit<ChatUserListDialogProps, 'open' | 'onClose' | 'users'>
    ) => {
      await openAwaiter.open(
        <ChatUserListDialogEx
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(true)}
          {...props}
        />
      );
    },
  };
};
export default useChatUserListDialog;
