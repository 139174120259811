import { Stack, TableRow, Typography } from '@mui/material';

import {
  AdminInnerBox,
  AdminTable,
  AdminTableBody,
} from '@/components/atoms/AdminPageParts';
import { AdminTableRow } from '@/components/atoms/AdminTableRow';
import DeleteButton from '@/components/atoms/DeleteButton';
import EditButton from '@/components/atoms/EditButton';

const DetailView = (props: {
  companyData: {
    name: string;
    data?: number | string;
  }[];
  title: string;
  readonly?: boolean;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  return (
    <AdminInnerBox sx={{ bgcolor: (t) => t.palette.common.white }}>
      <Stack direction={'row'} sx={{ mx: 2, mt: 3 }} alignItems={'center'}>
        <Typography variant="h6" sx={{ mr: 'auto' }}>
          {props.title}
        </Typography>
        {!props.readonly && (
          <Stack direction={'row'} spacing={2}>
            <EditButton onClick={props.onEdit} />
            <DeleteButton onClick={props.onDelete} />
          </Stack>
        )}
      </Stack>

      <AdminTable>
        <AdminTableBody>
          {props.companyData.map((companyData) => (
            <TableRow key={companyData.name}>
              <AdminTableRow title={companyData.name}>
                <Typography>{companyData.data}</Typography>
              </AdminTableRow>
            </TableRow>
          ))}
        </AdminTableBody>
      </AdminTable>
    </AdminInnerBox>
  );
};

export default DetailView;
