import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import OpenableElementsProvider from './components/organisms/OpenableElementsProvider';
import { env } from './env';
import ApolloProvider from './providers/ApolloProvider';
import PermissionProvider from './providers/PermissionProvider';
import RouteProvider from './providers/RouteProvider';
import ThemeProvider from './providers/ThemeProvider';
import reportWebVitals from './reportWebVitals';

import './locales/i18n';
import './index.css';
import ErrorBoundaryProvider from '@/components/organisms/ErrorBoundaryProvider';
import SnackbarProvider from '@/providers/SnackbarProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const gqlGatewayUrl = env.REACT_APP_GQL_GATEWAY_URL;
const gqlSubscriptionUrl = env.REACT_APP_GQL_SUBSCRIPTION_URL;

root.render(
  <React.StrictMode>
    <ApolloProvider
      gqlGatewayUrl={gqlGatewayUrl}
      gqlSubscriptionUrl={gqlSubscriptionUrl}
    >
      <ThemeProvider>
        <SnackbarProvider>
          <PermissionProvider>
            <ErrorBoundaryProvider>
              <BrowserRouter>
                <RouteProvider />
                <OpenableElementsProvider />
              </BrowserRouter>
            </ErrorBoundaryProvider>
          </PermissionProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
