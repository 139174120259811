import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

// ヘッダタイトルを動的に変更するためのAtom
export const headerTitleAtom = atom<string | JSX.Element>('');

export const useHeaderTitle = () => useAtomValue(headerTitleAtom);
export const useSetHeaderTitle = (title: string | JSX.Element) => {
  const setter = useSetAtom(headerTitleAtom);

  useEffect(() => {
    setter(title);
  }, [setter, title]);
};
