import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
// 編集ボタン
const EditButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');
  const { children, ...others } = props;
  return (
    <Button variant="contained" startIcon={<EditIcon />} {...others}>
      {children ?? t('command.edit')}
    </Button>
  );
};
export default EditButton;
