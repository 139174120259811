import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import { useRouteConfig } from '@/providers/RouteProvider';

const BreadcrumbBar = (props: { keyFrom?: string; maxWidth?: number }) => {
  const breadcrumbs = useBreadcrumbs(useRouteConfig());

  return (
    <Stack
      justifyContent="center"
      sx={{
        width: 1,
        backgroundColor: '#fff',
        height: 45,
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <AdminContent sx={{ maxWidth: props.maxWidth }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs
            .splice(
              breadcrumbs.findIndex((b) => b.key === (props.keyFrom ?? '/'))
            )
            .map((b) => (
              <Link
                key={b.key}
                underline="none"
                to={b.match.pathname}
                component={ReactLink}
              >
                <Typography variant="body2"> {b.breadcrumb}</Typography>
              </Link>
            ))}
        </Breadcrumbs>
      </AdminContent>
    </Stack>
  );
};

export default BreadcrumbBar;
