import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTranslation } from 'react-i18next';
import { Outlet, useRoutes } from 'react-router-dom';

import AdminLayout from '../components/organisms/admin/AdminLayout';
import AdminIndex from '../pages/admin/AdminIndex';
import NotificationIndex from '../pages/admin/notification/NotificationIndex';
import OnwerAdd from '../pages/admin/owner/OnwerAdd';
import OnwerEdit from '../pages/admin/owner/OnwerEdit';
import OwnerDetail from '../pages/admin/owner/OwnerDetail';
import OwnerIndex from '../pages/admin/owner/OwnerIndex';
import RoomAdd from '../pages/admin/room/RoomAdd';
import RoomEdit from '../pages/admin/room/RoomEdit';
import RoomIndex from '../pages/admin/room/RoomIndex';
import TemplateAdd from '../pages/admin/template/TemplateAdd';
import TemplateDetail from '../pages/admin/template/TemplateDetail';
import TemplateEdit from '../pages/admin/template/TemplateEdit';
import TemplateIndex from '../pages/admin/template/TemplateIndex';
import TenantAdd from '../pages/admin/tenant/TenantAdd';
import TenantDetail from '../pages/admin/tenant/TenantDetail';
import TenantEdit from '../pages/admin/tenant/TenantEdit';
import TenantIndex from '../pages/admin/tenant/TenantIndex';
import UserIndex from '../pages/admin/user/UserIndex';
import ChatLogin from '../pages/ChatLogin';
import { ChatValidator } from '../pages/ChatValidator';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Thanks from '../pages/Thanks';

import AdminRoomLayout from '@/components/organisms/admin/AdminRoomLayout';
import AdminLogin from '@/pages/admin/AdminLogin';
import TtsIndex from '@/pages/admin/adminSettings/TtsIndex';
import HomophoneAdd from '@/pages/admin/homophone/HomophoneAdd';
import HomophoneCategoryIndex from '@/pages/admin/homophone/HomophoneCategoryIndex';
import HomophoneIndex from '@/pages/admin/homophone/HomophoneIndex';
import NGWordAdd from '@/pages/admin/ngword/NGWordAdd';
import NGWordCategoryIndex from '@/pages/admin/ngword/NGWordCategoryIndex';
import NGWordEdit from '@/pages/admin/ngword/NGWordEdit';
import NGWordIndex from '@/pages/admin/ngword/NGWordIndex';
import OwnerAnalytics from '@/pages/admin/owner/OwnerAnalytics';
import ResetPassword from '@/pages/admin/ResetPassword';
import ResetPasswordRequest from '@/pages/admin/ResetPasswordRequest';
import SuperUserAdd from '@/pages/admin/superuser/SuperUserAdd';
import SuperUserDetail from '@/pages/admin/superuser/SuperUserDetail';
import SuperUserEdit from '@/pages/admin/superuser/SuperUserEdit';
import SuperUserIndex from '@/pages/admin/superuser/SuperUserIndex';
import CategoryIndex from '@/pages/admin/template/CategoryIndex';
import TenantAnalytics from '@/pages/admin/tenant/TenantAnalytics';
import TenantLogin from '@/pages/admin/TenantLogin';
import UserPassword from '@/pages/admin/user/UserPassword';
import VadAdd from '@/pages/admin/vad/VadAdd';
import VadEdit from '@/pages/admin/vad/VadEdit';
import VadIndex from '@/pages/admin/vad/VadIndex';
import Forbidden from '@/pages/Forbidden';
import Rejected from '@/pages/Rejected';
import DynamicCategoryBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicCategoryBreadcrumbs';
import DynamicHomophoneCategoryBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicHomophoneCategoryBreadcrumbs';
import DynamicNGWordCategoryBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicNGWordCategoryBreadcrumbs';
import DynamicOwnerBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicOwnerBreadcrumbs';
import DynamicSuperUserBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicSuperUserBreadcrumbs';
import DynamicTenantBreadcrumbs from '@/providers/dynamicBreadcrumbs/DynamicTenantBreadcrumbs';

const RouteProvider = () => {
  const Routes = () => useRoutes(useRouteConfig());
  return <Routes />;
};

export const useRouteConfig = () => {
  const { t } = useTranslation('common');

  return [
    {
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: 'forbidden',
          element: <Forbidden />,
        },
        {
          path: 'adminLogin',
          element: <AdminLogin />,
        },
        {
          path: 'tenantLogin',
          element: <TenantLogin />,
        },
        {
          path: 'resetPasswordRequest',
          element: <ResetPasswordRequest />,
        },
        {
          path: 'resetPassword',
          element: <ResetPassword />,
        },
        {
          path: 'chat',
          element: <Outlet />,
          children: [
            {
              path: ':chatRoomId',
              children: [
                {
                  index: true,
                  element: <ChatLogin />,
                },
                {
                  path: ':chatSessionId',
                  children: [
                    {
                      index: true,
                      element: <ChatValidator />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'thanks',
              element: <Thanks />,
            },
            {
              path: 'rejected',
              element: <Rejected />,
            },
          ],
        },
        {
          path: 'admin',
          element: <AdminLayout />,
          children: [
            {
              index: true,
              element: <AdminIndex />,
            },
          ],
        },
        {
          path: 'admin/room',
          element: <AdminRoomLayout />,
          children: [
            {
              index: true,
              element: <RoomIndex />,
            },
          ],
        },
        {
          path: 'admin',
          element: <AdminLayout backUrl="/admin/room" />,
          children: [
            {
              path: 'room',
              children: [
                {
                  path: 'add',
                  element: <RoomAdd />,
                },
                {
                  path: ':roomId',
                  children: [
                    {
                      path: 'edit',
                      element: <RoomEdit />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'user',
              element: <UserIndex />,
            },
            {
              path: 'template',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <CategoryIndex />,
                  breadcrumb: t('breadcrumb.templateAdmin'),
                },
                {
                  path: 'add',
                  element: <TemplateAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':categoryId',
                  children: [
                    {
                      index: true,
                      element: <TemplateIndex />,
                      breadcrumb: DynamicCategoryBreadcrumbs,
                    },
                    {
                      path: ':templateId',
                      children: [
                        {
                          index: true,
                          element: <TemplateDetail />,
                          breadcrumb: t('breadcrumb.detail'),
                        },
                        {
                          path: 'edit',
                          element: <TemplateEdit />,
                          breadcrumb: t('command.edit'),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'homophone',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <HomophoneCategoryIndex />,
                  breadcrumb: t('breadcrumb.homophoneAdmin'),
                },
                {
                  path: 'add',
                  element: <HomophoneAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':categoryId',
                  children: [
                    {
                      index: true,
                      element: <HomophoneIndex />,
                      breadcrumb: DynamicHomophoneCategoryBreadcrumbs,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'admin/user',
          element: (
            <AdminLayout
              backUrl="/admin/user"
              backIcon={
                <NavigateBeforeIcon
                  sx={{ color: (t) => t.palette.primary.contrastText }}
                />
              }
            />
          ),
          children: [
            {
              path: 'password',
              element: <UserPassword />,
            },
          ],
        },

        {
          path: 'admin',
          element: <AdminLayout showCopyright />,
          children: [
            {
              path: 'settings',
              children: [
                {
                  path: 'tts',
                  element: <TtsIndex />,
                },
              ],
            },
            {
              path: 'superuser',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <SuperUserIndex />,
                  breadcrumb: 'スーパーユーザー',
                },
                {
                  path: 'add',
                  element: <SuperUserAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':superuserId',
                  children: [
                    {
                      index: true,
                      element: <SuperUserDetail />,
                      breadcrumb: DynamicSuperUserBreadcrumbs,
                    },
                    {
                      path: 'edit',
                      element: <SuperUserEdit />,
                      breadcrumb: t('command.edit'),
                    },
                  ],
                },
              ],
            },
            {
              path: 'owner',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <OwnerIndex />,
                  breadcrumb: t('breadcrumb.ownerAdmin'),
                },
                {
                  path: 'analytics',
                  element: <OwnerAnalytics />,
                },
                {
                  path: 'add',
                  element: <OnwerAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':ownerId',
                  children: [
                    {
                      index: true,
                      element: <OwnerDetail />,
                      breadcrumb: DynamicOwnerBreadcrumbs,
                    },
                    {
                      path: 'edit',
                      element: <OnwerEdit />,
                      breadcrumb: t('command.edit'),
                    },
                  ],
                },
              ],
            },
            {
              path: 'tenant',
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element: <TenantIndex />,
                  breadcrumb: t('breadcrumb.tenantAdmin'),
                },
                {
                  path: 'analytics',
                  element: <TenantAnalytics />,
                },
                {
                  path: 'add',
                  element: <TenantAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':tenantId',
                  children: [
                    {
                      index: true,
                      element: <TenantDetail />,
                      breadcrumb: DynamicTenantBreadcrumbs,
                    },
                    {
                      path: 'edit',
                      element: <TenantEdit />,
                      breadcrumb: t('command.edit'),
                    },
                  ],
                },
              ],
            },

            {
              path: 'vad',
              children: [
                {
                  index: true,
                  element: <VadIndex />,
                  breadcrumb: t('breadcrumb.vad'),
                },
                {
                  path: 'add',
                  element: <VadAdd />,
                  breadcrumb: t('breadcrumb.vadAdd'),
                },
                {
                  path: ':VadId',
                  children: [
                    {
                      index: true,
                      element: <VadEdit />,
                      breadcrumb: t('breadcrumb.vadEdit'),
                    },
                  ],
                },
              ],
            },
            {
              path: 'ngword',
              children: [
                {
                  index: true,
                  element: <NGWordCategoryIndex />,
                  breadcrumb: t('breadcrumb.ngWord'),
                },
                {
                  path: 'add',
                  element: <NGWordAdd />,
                  breadcrumb: t('command.new'),
                },
                {
                  path: ':categoryId',
                  children: [
                    {
                      index: true,
                      element: <NGWordIndex />,
                      breadcrumb: DynamicNGWordCategoryBreadcrumbs,
                    },
                    {
                      path: ':ngwordId',
                      children: [
                        {
                          index: true,
                          element: <NGWordEdit />,
                          breadcrumb: t('command.edit'),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'notification',
              element: <NotificationIndex />,
            },
          ],
        },
      ],
    },
  ];
};

export default RouteProvider;
