import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useCheckPermission } from '../../../components/hooks/useCheckPermission';
import {
  useAdminSettingsQuery,
  useEditAdminSettingsMutation,
} from '../../../types/graphql';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';

const TtsIndex = () => {
  useCheckPermission({ superAdmin: true });

  useSetHeaderTitle('音声合成機能設定');
  const snackbar = useSnackbar();

  const ttsResult = useAdminSettingsQuery({
    variables: { input: { key: 'tts' } },
  });

  const [tts, setTts] = useState<'Azure' | 'Feat' | 'Mimi' | undefined>();
  useEffect(() => {
    setTts(
      ttsResult.data?.adminSettings[0].value as
        | 'Azure'
        | 'Feat'
        | 'Mimi'
        | undefined
    );
  }, [ttsResult.data?.adminSettings]);

  const [editAdminSettings, editAdminSettingsResult] =
    useEditAdminSettingsMutation();

  return (
    <AdminContent>
      <LoadingBackdrop
        open={ttsResult.loading || editAdminSettingsResult.loading}
      />
      <Stack
        component="form"
        spacing={2}
        sx={{
          bgcolor: (t) => t.palette.common.white,
          p: 2,
        }}
        onSubmit={async (e) => {
          try {
            e.preventDefault();
            if (tts != null) {
              await editAdminSettings({
                variables: { input: { key: 'tts', value: tts } },
              });
              snackbar('success', '保存しました');
            }
          } catch (e) {
            console.error(e);
          }
        }}
      >
        <FormControl>
          <FormLabel>音声合成サービス</FormLabel>
          <RadioGroup value={tts ?? ''}>
            <FormControlLabel
              value="Mimi"
              control={<Radio />}
              label="Mimi"
              onChange={(_, checked) => {
                if (checked) setTts('Mimi');
              }}
            />
            <FormControlLabel
              value="Azure"
              control={<Radio />}
              label="Azure"
              onChange={(_, checked) => {
                if (checked) setTts('Azure');
              }}
            />
            <FormControlLabel
              value="Feat"
              control={<Radio />}
              label="Feat"
              onChange={(_, checked) => {
                if (checked) setTts('Feat');
              }}
            />
          </RadioGroup>
        </FormControl>
        <Button disabled={tts == null} type="submit" variant="contained">
          保存
        </Button>
      </Stack>
    </AdminContent>
  );
};
export default TtsIndex;
