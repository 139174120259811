type Filter = number[];
type Shape = { f: number; v: number }[];
type RequiredOptions = {
  source: MediaStreamAudioSourceNode;
  context: AudioContext;
};
type OptionalOptions = {
  fftSize?: number;
  bufferLen?: number;
  voiceStop?: () => void;
  voiceStart?: () => void;
  smoothingTimeConstant?: number;
  energyOffset?: number; // The initial offset.
  energyThresholdRatioPos?: number; // Signal must be twice the offset
  energyThresholdRatioNeg?: number; // Signal must be half the offset
  energyIntegration?: number; // Size of integration change compared to the signal per second.
  filter?: Shape; // 0 -> 200 is 0
};
type VadOptions = RequiredOptions & OptionalOptions;

class Vad {
  private options: Required<OptionalOptions> & RequiredOptions;
  private analyser: AnalyserNode;
  private energy = 0;
  private energyOffset: number;
  private energyThresholdNeg: number;
  private energyThresholdPos: number;
  private filter: Filter;
  private floatFrequencyData: Float32Array;
  private floatFrequencyDataLinear: Float32Array;
  private hertzPerBin: number;
  private iterationFrequency: number;
  private iterationPeriod: number;
  private logI: number;
  private logLimit: number;
  private logging: boolean;
  private ready: { energy?: boolean } = {};
  private scriptProcessorNode: ScriptProcessorNode;
  private vadState = false;
  private voiceTrend: number;
  private voiceTrendEnd: number;
  private voiceTrendMax: number;
  private voiceTrendMin: number;
  private voiceTrendStart: number;

  constructor(options: VadOptions) {
    this.options = {
      fftSize: options.fftSize ?? 512,
      bufferLen: options.bufferLen ?? 512,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      voiceStop: options.voiceStop ?? (() => {}),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      voiceStart: options.voiceStart ?? (() => {}),
      smoothingTimeConstant: options.smoothingTimeConstant ?? 0.985, // 0.99
      energyOffset: options.energyOffset ?? 1e-11, // 1e-8, // The initial offset.
      energyThresholdRatioPos: options.energyThresholdRatioPos ?? 2, // Signal must be twice the offset
      energyThresholdRatioNeg: options.energyThresholdRatioNeg ?? 0.5, // Signal must be half the offset
      energyIntegration: options.energyIntegration ?? 0, // 1 // Size of integration change compared to the signal per second.
      filter: options.filter ?? [
        { f: 200, v: 0 }, // 0 -> 200 is 0
        { f: 2000, v: 1 }, // 200 -> 2k is 1
      ],
      source: options.source,
      context: options.context,
    };

    // Calculate time relationships
    this.hertzPerBin = this.options.context.sampleRate / this.options.fftSize;
    this.iterationFrequency =
      this.options.context.sampleRate / this.options.bufferLen;
    this.iterationPeriod = 1 / this.iterationFrequency;

    this.filter = this.setFilter(this.options.filter);

    // Energy detector props
    this.energyOffset = this.options.energyOffset;
    this.energyThresholdPos =
      this.energyOffset * this.options.energyThresholdRatioPos;
    this.energyThresholdNeg =
      this.energyOffset * this.options.energyThresholdRatioNeg;

    this.voiceTrend = 0;
    this.voiceTrendMax = 10;
    this.voiceTrendMin = -10;
    this.voiceTrendStart = 5;
    this.voiceTrendEnd = -5;

    // Create analyser
    this.analyser = this.options.context.createAnalyser();
    this.analyser.smoothingTimeConstant = this.options.smoothingTimeConstant; // 0.99;
    this.analyser.fftSize = this.options.fftSize;

    this.floatFrequencyData = new Float32Array(this.analyser.frequencyBinCount);

    // Setup local storage of the Linear FFT data
    this.floatFrequencyDataLinear = new Float32Array(
      this.floatFrequencyData.length
    );

    // Connect this.analyser
    this.options.source.connect(this.analyser);

    // Create ScriptProcessorNode
    this.scriptProcessorNode = this.options.context.createScriptProcessor(
      this.options.bufferLen,
      1,
      1
    );

    // Connect scriptProcessorNode (Theretically, not required)
    this.scriptProcessorNode.connect(this.options.context.destination);

    this.scriptProcessorNode.onaudioprocess = (_e: AudioProcessingEvent) => {
      this.analyser.getFloatFrequencyData(this.floatFrequencyData);
      this.update();
      this.monitor();
    };

    // Connect scriptProcessorNode
    this.options.source.connect(this.scriptProcessorNode);

    // log stuff
    this.logging = false;
    this.logI = 0;
    this.logLimit = 100;
  }

  public pause = () => {
    this.analyser.disconnect();
    this.scriptProcessorNode.disconnect();
    // this.options.source.mediaStream.getTracks().forEach((t) => t.stop());
    return;
  };

  private setFilter = (shape: Shape) => {
    const filter: Filter = [];
    for (let i = 0, iLen = this.options.fftSize / 2; i < iLen; i++) {
      filter[i] = 0;
      for (let j = 0, jLen = shape.length; j < jLen; j++) {
        if (i * this.hertzPerBin < shape[j].f) {
          filter[i] = shape[j].v;
          break; // Exit j loop
        }
      }
    }
    this.filter = filter;
    return filter;
  };

  //   private triggerLog = (limit: number) => {
  //     this.logging = true;
  //     this.logI = 0;
  //     this.logLimit = limit;
  //   };

  private log = (msg: string) => {
    if (this.logging && this.logI < this.logLimit) {
      this.logI++;
      console.log(msg);
    } else {
      this.logging = false;
    }
  };

  private update = () => {
    // Update the local version of the Linear FFT
    const fft = this.floatFrequencyData;
    for (let i = 0, iLen = fft.length; i < iLen; i++) {
      this.floatFrequencyDataLinear[i] = Math.pow(10, fft[i] / 10);
    }
    this.ready = {};
  };

  private getEnergy = () => {
    if (this.ready.energy) {
      return this.energy;
    }

    let energy = 0;
    const fft = this.floatFrequencyDataLinear;

    for (let i = 0, iLen = fft.length; i < iLen; i++) {
      energy += this.filter[i] * fft[i] * fft[i];
    }

    this.energy = energy;
    this.ready.energy = true;

    return energy;
  };

  private monitor = () => {
    const energy = this.getEnergy();
    const signal = energy - this.energyOffset;

    if (signal > this.energyThresholdPos) {
      this.voiceTrend =
        this.voiceTrend + 1 > this.voiceTrendMax
          ? this.voiceTrendMax
          : this.voiceTrend + 1;
    } else if (signal < -this.energyThresholdNeg) {
      this.voiceTrend =
        this.voiceTrend - 1 < this.voiceTrendMin
          ? this.voiceTrendMin
          : this.voiceTrend - 1;
    } else {
      // voiceTrend gets smaller
      if (this.voiceTrend > 0) {
        this.voiceTrend--;
      } else if (this.voiceTrend < 0) {
        this.voiceTrend++;
      }
    }

    let start = false,
      end = false;
    if (this.voiceTrend > this.voiceTrendStart) {
      // Start of speech detected
      start = true;
    } else if (this.voiceTrend < this.voiceTrendEnd) {
      // End of speech detected
      end = true;
    }

    // Integration brings in the real-time aspect through the relationship with the frequency this functions is called.
    const integration =
      signal * this.iterationPeriod * this.options.energyIntegration;

    // Idea?: The integration is affected by the voiceTrend magnitude? - Not sure. Not doing atm.

    // The !end limits the offset delta boost till after the end is detected.
    if (integration > 0 || !end) {
      this.energyOffset += integration;
    } else {
      this.energyOffset += integration * 10;
    }
    this.energyOffset = this.energyOffset < 0 ? 0 : this.energyOffset;
    this.energyThresholdPos =
      this.energyOffset * this.options.energyThresholdRatioPos;
    this.energyThresholdNeg =
      this.energyOffset * this.options.energyThresholdRatioNeg;

    // Broadcast the messages
    if (start && !this.vadState) {
      this.vadState = true;
      this.options.voiceStart();
    }
    if (end && this.vadState) {
      this.vadState = false;
      this.options.voiceStop();
    }

    this.log(
      'e: ' +
        energy +
        ' | e_of: ' +
        this.energyOffset +
        ' | e+_th: ' +
        this.energyThresholdPos +
        ' | e-_th: ' +
        this.energyThresholdNeg +
        ' | signal: ' +
        signal +
        ' | int: ' +
        integration +
        ' | voiceTrend: ' +
        this.voiceTrend +
        ' | start: ' +
        start +
        ' | end: ' +
        end
    );

    return signal;
  };
}

export default Vad;

// (function (window) {
//   let VAD = function (options) {
//     // Default options
//     this.options = {
//       fftSize: 512,
//       bufferLen: 512,
//       voiceStop: function () {},
//       voiceStart: function () {},
//       smoothingTimeConstant: 0.99,
//       energyOffset: 1e-8, // The initial offset.
//       energyThresholdRatioPos: 2, // Signal must be twice the offset
//       energyThresholdRatioNeg: 0.5, // Signal must be half the offset
//       energyIntegration: 1, // Size of integration change compared to the signal per second.
//       filter: [
//         { f: 200, v: 0 }, // 0 -> 200 is 0
//         { f: 2000, v: 1 }, // 200 -> 2k is 1
//       ],
//       source: null,
//       context: null,
//     };

//     // User options
//     for (var option in options) {
//       if (options.hasOwnProperty(option)) {
//         this.options[option] = options[option];
//       }
//     }

//     // Require source
//     if (!this.options.source)
//       throw new Error('The options must specify a MediaStreamAudioSourceNode.');

//     // Set this.options.context
//     this.options.context = this.options.source.context;

//     // Calculate time relationships
//     this.hertzPerBin = this.options.context.sampleRate / this.options.fftSize;
//     this.iterationFrequency =
//       this.options.context.sampleRate / this.options.bufferLen;
//     this.iterationPeriod = 1 / this.iterationFrequency;

//     var DEBUG = true;
//     if (DEBUG)
//       console.log(
//         'Vad' +
//           ' | sampleRate: ' +
//           this.options.context.sampleRate +
//           ' | hertzPerBin: ' +
//           this.hertzPerBin +
//           ' | iterationFrequency: ' +
//           this.iterationFrequency +
//           ' | iterationPeriod: ' +
//           this.iterationPeriod
//       );

//     this.setFilter = function (shape) {
//       this.filter = [];
//       for (var i = 0, iLen = this.options.fftSize / 2; i < iLen; i++) {
//         this.filter[i] = 0;
//         for (var j = 0, jLen = shape.length; j < jLen; j++) {
//           if (i * this.hertzPerBin < shape[j].f) {
//             this.filter[i] = shape[j].v;
//             break; // Exit j loop
//           }
//         }
//       }
//     };

//     this.setFilter(this.options.filter);

//     this.ready = {};
//     this.vadState = false; // True when Voice Activity Detected

//     // Energy detector props
//     this.energyOffset = this.options.energyOffset;
//     this.energyThresholdPos =
//       this.energyOffset * this.options.energyThresholdRatioPos;
//     this.energyThresholdNeg =
//       this.energyOffset * this.options.energyThresholdRatioNeg;

//     this.voiceTrend = 0;
//     this.voiceTrendMax = 10;
//     this.voiceTrendMin = -10;
//     this.voiceTrendStart = 5;
//     this.voiceTrendEnd = -5;

//     // Create analyser
//     this.analyser = this.options.context.createAnalyser();
//     this.analyser.smoothingTimeConstant = this.options.smoothingTimeConstant; // 0.99;
//     this.analyser.fftSize = this.options.fftSize;

//     this.floatFrequencyData = new Float32Array(this.analyser.frequencyBinCount);

//     // Setup local storage of the Linear FFT data
//     this.floatFrequencyDataLinear = new Float32Array(
//       this.floatFrequencyData.length
//     );

//     // Connect this.analyser
//     this.options.source.connect(this.analyser);

//     // Create ScriptProcessorNode
//     this.scriptProcessorNode = this.options.context.createScriptProcessor(
//       this.options.bufferLen,
//       1,
//       1
//     );

//     // Connect scriptProcessorNode (Theretically, not required)
//     this.scriptProcessorNode.connect(this.options.context.destination);

//     // Create callback to update/analyze floatFrequencyData
//     var self = this;
//     this.scriptProcessorNode.onaudioprocess = function (event) {
//       self.analyser.getFloatFrequencyData(self.floatFrequencyData);
//       self.update();
//       self.monitor();
//     };

//     // Connect scriptProcessorNode
//     this.options.source.connect(this.scriptProcessorNode);

//     // log stuff
//     this.logging = false;
//     this.logI = 0;
//     this.logLimit = 100;

//     this.triggerLog = function (limit) {
//       this.logging = true;
//       this.logI = 0;
//       this.logLimit = typeof limit === 'number' ? limit : this.logLimit;
//     };

//     this.log = function (msg) {
//       if (this.logging && this.logI < this.logLimit) {
//         this.logI++;
//         console.log(msg);
//       } else {
//         this.logging = false;
//       }
//     };

//     this.update = function () {
//       // Update the local version of the Linear FFT
//       var fft = this.floatFrequencyData;
//       for (var i = 0, iLen = fft.length; i < iLen; i++) {
//         this.floatFrequencyDataLinear[i] = Math.pow(10, fft[i] / 10);
//       }
//       this.ready = {};
//     };

//     this.getEnergy = function () {
//       if (this.ready.energy) {
//         return this.energy;
//       }

//       var energy = 0;
//       var fft = this.floatFrequencyDataLinear;

//       for (var i = 0, iLen = fft.length; i < iLen; i++) {
//         energy += this.filter[i] * fft[i] * fft[i];
//       }

//       this.energy = energy;
//       this.ready.energy = true;

//       return energy;
//     };

//     this.monitor = function () {
//       var energy = this.getEnergy();
//       var signal = energy - this.energyOffset;

//       if (signal > this.energyThresholdPos) {
//         this.voiceTrend =
//           this.voiceTrend + 1 > this.voiceTrendMax
//             ? this.voiceTrendMax
//             : this.voiceTrend + 1;
//       } else if (signal < -this.energyThresholdNeg) {
//         this.voiceTrend =
//           this.voiceTrend - 1 < this.voiceTrendMin
//             ? this.voiceTrendMin
//             : this.voiceTrend - 1;
//       } else {
//         // voiceTrend gets smaller
//         if (this.voiceTrend > 0) {
//           this.voiceTrend--;
//         } else if (this.voiceTrend < 0) {
//           this.voiceTrend++;
//         }
//       }

//       let start = false,
//         end = false;
//       if (this.voiceTrend > this.voiceTrendStart) {
//         // Start of speech detected
//         start = true;
//       } else if (this.voiceTrend < this.voiceTrendEnd) {
//         // End of speech detected
//         end = true;
//       }

//       // Integration brings in the real-time aspect through the relationship with the frequency this functions is called.
//       let integration =
//         signal * this.iterationPeriod * this.options.energyIntegration;

//       // Idea?: The integration is affected by the voiceTrend magnitude? - Not sure. Not doing atm.

//       // The !end limits the offset delta boost till after the end is detected.
//       if (integration > 0 || !end) {
//         this.energyOffset += integration;
//       } else {
//         this.energyOffset += integration * 10;
//       }
//       this.energyOffset = this.energyOffset < 0 ? 0 : this.energyOffset;
//       this.energyThresholdPos =
//         this.energyOffset * this.options.energyThresholdRatioPos;
//       this.energyThresholdNeg =
//         this.energyOffset * this.options.energyThresholdRatioNeg;

//       // Broadcast the messages
//       if (start && !this.vadState) {
//         this.vadState = true;
//         this.options.voiceStart();
//       }
//       if (end && this.vadState) {
//         this.vadState = false;
//         this.options.voiceStop();
//       }

//       this.log(
//         'e: ' +
//           energy +
//           ' | e_of: ' +
//           this.energyOffset +
//           ' | e+_th: ' +
//           this.energyThresholdPos +
//           ' | e-_th: ' +
//           this.energyThresholdNeg +
//           ' | signal: ' +
//           signal +
//           ' | int: ' +
//           integration +
//           ' | voiceTrend: ' +
//           this.voiceTrend +
//           ' | start: ' +
//           start +
//           ' | end: ' +
//           end
//       );

//       return signal;
//     };
//   };

//   window.VAD = VAD;
// })(window);
