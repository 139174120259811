import { ButtonProps } from '@mui/material';

import CancelButton from '@/components/atoms/CancelButton';
import DeleteButton from '@/components/atoms/DeleteButton';
import useConfirmDialog from '@/components/organisms/confirmDialog/useConfirmDialog';

const useConfirmDeleteDialog = () => {
  const confirmDialog = useConfirmDialog(false);
  return {
    open: (props: {
      message?: string;
      title: string;
      okLabel?: string;
      okColor?: ButtonProps['color'];
    }) =>
      confirmDialog.open({
        title: props.title,
        message: props.message,
        actions: [
          <CancelButton
            key="cancal"
            onClick={() => confirmDialog.close(false)}
          />,
          <DeleteButton
            key="delete"
            onClick={() => confirmDialog.close(true)}
            color="primary"
          >
            {props.okLabel}
          </DeleteButton>,
        ],
      }),
  };
};
export default useConfirmDeleteDialog;
