export type CsvData = {
  ID: string;
  categoryId: string;
  categoryName: string;
  word: string;
};

const ngWordCsvStringify = (records: CsvData[]) => {
  const result: string[] = [];
  const headers = ['ID', 'categoryId', 'categoryName', 'word'].join(',');
  result.push(headers);
  for (const record of records) {
    result.push(
      [record.ID, record.categoryId, record.categoryName, record.word]
        .map((x) =>
          x.indexOf('"') === -1 && x.indexOf(',') === -1
            ? x
            : '"' + x.replaceAll('"', '""') + '"'
        )
        .join(',')
    );
  }
  return '\uFEFF' + result.join('\n');
};
export default ngWordCsvStringify;
