import { Button, Stack, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = (props: FallbackProps) => {
  return (
    <Stack
      sx={{ width: '100vw', height: '100vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>Something went wrong</Typography>
      <Typography>{props.error.message ?? ''}</Typography>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Try again
      </Button>
    </Stack>
  );
};
