import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import useAudioInputTester from '@/components/organisms/audioTest/useAudioInputTester';

export type AudioTestResultDialogProps = {
  open: boolean;
  onClose: (isOk: boolean) => void;
  isInputOk: boolean;
  isOutputOk: boolean;
};

const AudioTestResultDialog = (props: AudioTestResultDialogProps) => {
  const { t } = useTranslation('chat');
  const audioTester = useAudioInputTester();

  const onClose = (isOk: boolean) => {
    audioTester.stop();
    props.onClose(isOk);
  };

  const isOk = props.isInputOk && props.isOutputOk;

  return (
    <Dialog open={props.open} PaperProps={{ sx: { minHeight: 280 } }}>
      <DialogTitle>
        {isOk
          ? // デバイスは正常に動作しています
            t('deviceTest.label014')
          : // デバイスが正常に動作していません
            t('deviceTest.label018')}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3,
            py: 4,
            bgcolor: (t) => t.palette.grey[200],
          }}
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              {props.isOutputOk ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <ErrorOutlineIcon color="error" />
              )}
              <Box>
                {
                  // スピーカー
                  t('deviceTest.label015')
                }
              </Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              {props.isInputOk ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <ErrorOutlineIcon color="error" />
              )}
              <Box>
                {
                  // スピーカー
                  t('deviceTest.label016')
                }
              </Box>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {isOk === false && (
          <CancelButton
            onClick={() => {
              onClose(false);
            }}
          >
            {
              // 再試行
              t('deviceTest.label019')
            }
          </CancelButton>
        )}
        <Button
          onClick={() => {
            onClose(true);
          }}
        >
          {
            // テスト終了
            t('deviceTest.label017')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AudioTestResultDialog;
