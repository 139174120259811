import { assertNotNull } from '@remote-voice/utilities';
import { OrderedMap } from 'js-sdsl';

import ChatMessageCache, {
  ChatMessageItem,
  chatMessageConfigDefault,
} from '@/components/organisms/chat/ChatMessageCache';
import { env } from '@/env';

class ChatMessages {
  // 内部的に管理するシーケンス番号でソート済みのリスト
  private orderedMessages = new OrderedMap<number, ChatMessageItem>();
  private waitingMessages = new OrderedMap<number, ChatMessageItem>();
  private typingMessages = new OrderedMap<number, ChatMessageItem>();
  private config = chatMessageConfigDefault;
  private cache: ChatMessageCache | undefined = undefined;

  private messageWaitMilliseconds = 3000;
  private constructor() {
    const timeout = Number(env.REACT_APP_SEQ_ERR_TIMEOUT);
    if (isNaN(timeout) === false && timeout != null) {
      this.messageWaitMilliseconds = timeout;
    }
  }

  /// 新規インスタンスを生成し、キャッシュからデータ取得
  public static async create(cacheId: string): Promise<ChatMessages> {
    const instance = new ChatMessages();
    instance.cache = await ChatMessageCache.create(cacheId);
    instance.config = await instance.cache.getConfig();
    for (const msg of await instance.cache.getAllChatMessages()) {
      instance.orderedMessages.setElement(msg.sequence * 1000, msg);
      if (msg.isTyping) {
        instance.typingMessages.setElement(msg.sequence * 1000, msg);
      }
    }
    if (instance.orderedMessages.length > 0) {
      console.debug('loadCacheMessages', instance.orderedMessages.length);
    }
    return instance;
  }

  getLastOrderKey = () =>
    this.orderedMessages.empty() ? 0 : this.orderedMessages.rBegin().pointer[0];

  getFirstOrderKey = () =>
    this.orderedMessages.empty()
      ? 2147483647000 //←ちょっと分かりにくい気もするので要検討。ちなみに MAX_SAFE_INT は 9007199254740991 なのでjS的には問題ない
      : this.orderedMessages.begin().pointer[0];

  getLastUpdatedAt = () => this.config.lastUpdatedAt;

  getPreviousMessage = (key: number | undefined) => {
    if (this.orderedMessages.empty()) return;
    if (key == null) {
      return this.orderedMessages.rBegin().pointer;
    } else if (key > this.orderedMessages.begin().pointer[0]) {
      return this.orderedMessages.reverseUpperBound(key).pointer;
    }
  };

  getNextMessage = (key: number | undefined) => {
    if (this.orderedMessages.empty()) return;
    if (key == null) {
      return this.orderedMessages.begin().pointer;
    } else if (key < this.orderedMessages.rBegin().pointer[0]) {
      return this.orderedMessages.upperBound(key).pointer;
    }
  };

  toArray = () => Array.from(this.orderedMessages);

  // 指定シーケンス以前のメッセージを削除する
  clearMessages = async (sequence: number) => {
    assertNotNull(this.cache);
    // メモリとDBから削除する
    for (const msgs of [this.orderedMessages, this.waitingMessages]) {
      const removes: { key: number; msg: ChatMessageItem }[] = [];
      msgs.forEach(([key, msg]) => {
        if (msg.sequence <= sequence) {
          removes.push({ key, msg });
        }
      });
      for (const item of removes) {
        msgs.eraseElementByKey(item.key); // メモリから削除
      }
      await this.cache.deleteChatMessages(0, sequence); // DBから一括削除
    }
  };

  // orderedにデータをupsertするする
  // 同じIDが既にあれば差分を上書きする
  upsertMessage = async (
    orderKey: number,
    newItem: ChatMessageItem,
    force?: boolean
  ) => {
    assertNotNull(this.cache);
    //
    // IDが既に存在している場合は更新する
    const hasOrdered = this.orderedMessages.getElementByKey(orderKey);
    const hasWaiting = this.waitingMessages.getElementByKey(orderKey);
    if (hasOrdered || hasWaiting) {
      const existsItem = Object.assign(
        {},
        hasOrdered ? hasOrdered : hasWaiting
      );

      // 新アイテムで上書き
      {
        // 同じ言語で新しい方にデータがあればそれを優先する
        const newLanguages = existsItem.languages
          .filter(
            (x) =>
              newItem.languages.some((y) => y.language === x.language) === false
          )
          .concat(newItem.languages);

        // 同じユーザーで新しい方にリアクションがあればそれを優先する
        const newReactions = existsItem.reactions
          .filter(
            (x) =>
              newItem.reactions.some((y) => y.userId === x.userId) === false
          )
          .concat(newItem.reactions);

        const reply = existsItem.reply ?? newItem.reply;
        Object.assign(existsItem, newItem);
        existsItem.languages = newLanguages;
        existsItem.reactions = newReactions;
        existsItem.reply = reply;
      }

      // 削除処理
      if (existsItem.isRemoved) {
        existsItem.languages = [];
        existsItem.reverseLanguage = undefined;
        existsItem.reverseMessage = undefined;
      }

      // 設定を更新
      await this.updateConfig(
        new Date(existsItem.updateAt).getTime(),
        existsItem.sequence
      );
      // チャットを更新
      const isWaiting = !(hasOrdered || force);
      (isWaiting ? this.waitingMessages : this.orderedMessages).setElement(
        orderKey,
        existsItem
      );

      // waitingからorderedに移動した場合はwaitingから削除
      if (hasWaiting && force) {
        this.waitingMessages.eraseElementByKey(orderKey);
      }

      // typingのリストを制御
      if (!isWaiting && existsItem.isTyping) {
        this.typingMessages.setElement(orderKey, existsItem);
      } else if (this.typingMessages.getElementByKey(orderKey)) {
        this.typingMessages.eraseElementByKey(orderKey);
      }

      // DBに保存（送信中以外）
      if (orderKey % 1000 === 0) {
        await this.cache.putChatMessage(existsItem);
      }
    } else {
      // まだ存在していなければ、orderedMessages か waitingMessages に格納

      // 最初の受信か、送信時の一時的な追加か、1つ前のシーケンスが存在していればそのままリストに追加
      if (
        force ||
        orderKey === (this.config.lastSequence + 1) * 1000 ||
        orderKey % 1000 !== 0 ||
        this.orderedMessages.getElementByKey(orderKey - 1000) != null
      ) {
        // 設定を更新
        await this.updateConfig(
          new Date(newItem.updateAt).getTime(),
          newItem.sequence
        );
        // チャットを更新
        this.orderedMessages.setElement(orderKey, newItem);

        // typingのリストを制御
        if (newItem.isTyping) {
          this.typingMessages.setElement(orderKey, newItem);
        }
      } else {
        // 存在していなければ待機リストに追加
        this.waitingMessages.setElement(orderKey, newItem);
      }

      // DBに保存（送信中以外）
      if (orderKey % 1000 === 0) {
        await this.cache.putChatMessage(newItem);
      }
    }
  };

  updateWaiting = async () => {
    let processed = false;
    for (const [orderKey, message] of Array.from(this.waitingMessages)) {
      // 1つ前のデータが表示中になっていたら自分も表示する
      if (this.orderedMessages.getElementByKey(orderKey - 1000) != null) {
        await this.upsertMessage(orderKey, message, true);
        processed = true;
      } else {
        // そうでない場合、タイムアウトしていたら表示する
        const timeDiff = Date.now() - new Date(message.timestamp).getTime();
        if (timeDiff > this.messageWaitMilliseconds) {
          await this.upsertMessage(orderKey, message, true);
          processed = true;
        }
      }
    }
    return processed;
  };

  updateTyping = async () => {
    // 発言中状態で一定時間更新がない物は、発言中状態を解除する。
    const timeout = 30 * 1000;
    let processed = false;
    for (const [orderKey, message] of Array.from(this.typingMessages)) {
      if (Date.now() > new Date(message.updateAt).getTime() + timeout) {
        const newItems = Object.assign({}, message, { isTyping: false });
        await this.upsertMessage(orderKey, newItems, true);
        processed = true;
      }
    }
    return processed;
  };

  eraseMessage = (key: number) => {
    assertNotNull(this.cache);
    const result = [
      this.orderedMessages.eraseElementByKey(key),
      this.waitingMessages.eraseElementByKey(key),
      this.typingMessages.eraseElementByKey(key),
    ].some((x) => x);
    if (key % 1000 === 0) {
      this.cache.deleteChatMessage(key / 1000);
    }
    return result;
  };

  private async updateConfig(updatedAt: number, sequence: number) {
    assertNotNull(this.cache);
    if (
      this.config.lastUpdatedAt < updatedAt ||
      this.config.lastSequence < sequence
    ) {
      if (this.config.lastUpdatedAt < updatedAt) {
        this.config.lastUpdatedAt = updatedAt;
      }
      if (this.config.lastSequence < sequence) {
        this.config.lastSequence = sequence;
      }
      await this.cache.setConfig(this.config);
    }
  }
}
export default ChatMessages;
