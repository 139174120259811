import { Button } from '@mui/material';

import useConfirmDialog from './useConfirmDialog';

const useConfirmOkDialog = () => {
  const confirmDialog = useConfirmDialog(false);
  return {
    open: async (props: {
      title: string;
      message?: string;
      okLabel?: string;
    }) => {
      await confirmDialog.open({
        title: props.title,
        message: props.message,
        actions: [
          <Button key="ok" onClick={() => confirmDialog.close(true)}>
            {props.okLabel ?? 'OK'}
          </Button>,
        ],
      });
    },
  };
};
export default useConfirmOkDialog;
