import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import { PasswordField } from '@/components/atoms/PasswordField';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { useUserPermission } from '@/components/hooks/useUserPermission';
import { changePassword as cognitoChangePassword } from '@/utils/cognito';

type UserPasswordData = {
  oldPwd: string;
  newPwd: string;
};

const UserPassword = () => {
  const { t } = useTranslation('admin');
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  useSetHeaderTitle(t('passwordReset.changePassword'));

  const loadingBackdrop = useLoadingBackdrop();
  const userPermission = useUserPermission();
  const { cognitoUserName } = userPermission;

  const joi = useLocalizedJoi();
  const form = useForm<UserPasswordData>({
    resolver: joiResolver(
      joi.object<UserPasswordData>({
        oldPwd: joi.string().required(),
        newPwd: joi.string().min(8).max(50).required(),
      })
    ),
  });

  const changePassword = async (input: UserPasswordData) => {
    try {
      await cognitoChangePassword({ ...input, cognitoUserName });
      snackbar('success', t('passwordReset.savedChangePassword'));
      navigate('/admin/user');
    } catch (e) {
      snackbar('error', t('passwordReset.errorOnSaveChangePassword'));
    }
  };

  return (
    <AdminContent>
      <Stack
        spacing={3}
        component="form"
        onSubmit={form.handleSubmit(async (input, ev) => {
          ev?.preventDefault();
          await loadingBackdrop.open(() => changePassword(input));
        })}
      >
        <PasswordField
          autoComplete="old-password"
          label={t('passwordReset.currentPassword')}
          {...form.register('oldPwd')}
          error={'oldPwd' in form.formState.errors}
          helperText={form.formState.errors.oldPwd?.message}
        />
        <PasswordField
          autoComplete="new-password"
          label={t('passwordReset.newPassword')}
          {...form.register('newPwd')}
          error={'newPwd' in form.formState.errors}
          helperText={form.formState.errors.newPwd?.message}
        />
        <Stack direction="row" spacing={2} pt={1} justifyContent="center">
          <CancelButton onClick={() => navigate(-1)} />
          <Button type="submit" variant="contained">
            {t('passwordReset.changePasswordMsg')}
          </Button>
        </Stack>
      </Stack>
    </AdminContent>
  );
};
export default UserPassword;
