import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import CancelButton from '@/components/atoms/CancelButton';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import ChatUserList from '@/components/organisms/chat/ChatUserList';
import useChatUsers from '@/components/organisms/chat/useChatUsers';
import {
  useChatCommandExecutedSubscription,
  useRemoveChatSessionUserMutation,
} from '@/types/graphql';
import SystemCommand from '@/types/SystemCommand';

export type WaitingChatSessionDialogProps = {
  open: boolean;
  onCancel: () => void;
  onJoin: (withStart: boolean) => void;

  chatRoomId: string;
  sessionEntryCode: string;
  chatUserId: string;
  chatUserPassword: string;
  userName: string;
  language: string;
  isGuide: boolean;
};

const WaitingChatSessionDialog = (props: WaitingChatSessionDialogProps) => {
  const { t } = useTranslation('chat');
  const navigate = useNavigate();

  // ユーザー数超過の制御
  const [isMaxUser, setIsMaxUser] = useState(false);
  useEffect(() => {
    if (props.open) setIsMaxUser(false);
  }, [props.open]);

  // 待機中のユーザーリストを取得
  const { users } = useChatUsers({
    variables: {
      chatRoomId: props.chatRoomId,
      sessionEntryCode: props.sessionEntryCode,
      userId: props.chatUserId,
      userPassword: props.chatUserPassword,
      userName: props.userName,
      language: props.language,
    },
    skip: props.open === false,
    onMaxUser: () => setIsMaxUser(true),
  });
  const waitingUsers = users.filter(
    (x) => x.isJoining && x.userId !== props.chatUserId && x.isWaiting
  );

  // 待機拒否された場合の処理
  useChatCommandExecutedSubscription({
    variables: {
      input: {
        chatRoomId: props.chatRoomId,
        sessionEntryCode: props.sessionEntryCode,
      },
    },
    onData: (result) => {
      const commandResult = result.data.data?.chatCommandExecuted;
      assertNotNull(commandResult);
      const command = JSON.parse(commandResult) as SystemCommand;
      if (
        // セッションからの離脱要求
        command.commandType === 'removeChatSessionUser' &&
        // 全員退出指示 or 個別退出指示
        ((command.targetUserId == null &&
          command.userId !== props.chatUserId &&
          !props.isGuide) ||
          command.targetUserId === props.chatUserId)
      ) {
        navigate('/chat/rejected');
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  // 待機ユーザーのリジェクト処理
  const removeUser = useRemoveChatSessionUserMutation();
  const loadingBackdrop = useLoadingBackdrop();

  // 参加者でかつ入室が許可されていれば参加可能とする処理
  const { chatUserId, isGuide, onJoin } = props;
  useEffect(() => {
    const me = users.find((x) => x.userId === chatUserId);
    if (!isGuide && me?.isWaiting === false) onJoin(false);
  }, [chatUserId, isGuide, onJoin, users]);

  return (
    <Dialog open={props.open} PaperProps={{ sx: { minHeight: 280 } }}>
      {props.isGuide && isMaxUser === false ? (
        <>
          <DialogTitle>{t('wating.waitingUsers')}</DialogTitle>
          <DialogContent dividers sx={{ p: 0 }}>
            <ChatUserList
              users={waitingUsers}
              onClickReject={async (user) => {
                await loadingBackdrop.open(() =>
                  removeUser[0]({
                    variables: {
                      input: {
                        chatRoomId: props.chatRoomId,
                        sessionEntryCode: props.sessionEntryCode,
                        userId: props.chatUserId,
                        userPassword: props.chatUserPassword,
                        targetUserId: user.userId,
                      },
                    },
                  })
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              gap={1}
              width={1}
            >
              <CancelButton variant="outlined" onClick={props.onCancel} />
              <Button onClick={() => props.onJoin(true)}>
                {t('wating.start')}
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : isMaxUser ? (
        <>
          <DialogContent>
            <Stack
              spacing={2}
              mt={13}
              justifyContent="center"
              alignItems="center"
            >
              <Typography>{t('wating.maxUsers')}</Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCancel}>OK</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Stack
              spacing={2}
              mt={11}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
              {props.isGuide === false && (
                <Typography>{t('wating.waitingStart')}</Typography>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={props.onCancel} />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default WaitingChatSessionDialog;
