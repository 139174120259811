import { Languages, validLanguages } from '@remote-voice/utilities';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enAdmin from './en/admin.json';
import enChat from './en/chat.json';
import enCommon from './en/common.json';
import esAdmin from './es/admin.json';
import esChat from './es/chat.json';
import esCommon from './es/common.json';
import filAdmin from './fil/admin.json';
import filChat from './fil/chat.json';
import filCommon from './fil/common.json';
import frAdmin from './fr/admin.json';
import frChat from './fr/chat.json';
import frCommon from './fr/common.json';
import idAdmin from './id/admin.json';
import idChat from './id/chat.json';
import idCommon from './id/common.json';
import jaAdmin from './ja/admin.json';
import jaChat from './ja/chat.json';
import jaCommon from './ja/common.json';
import koAdmin from './ko/admin.json';
import koChat from './ko/chat.json';
import koCommon from './ko/common.json';
import myAdmin from './my/admin.json';
import myChat from './my/chat.json';
import myCommon from './my/common.json';
import ptBrAdmin from './pt-BR/admin.json';
import ptBrChat from './pt-BR/chat.json';
import ptBrCommon from './pt-BR/common.json';
import thAdmin from './th/admin.json';
import thChat from './th/chat.json';
import thCommon from './th/common.json';
import viAdmin from './vi/admin.json';
import viChat from './vi/chat.json';
import viCommon from './vi/common.json';
import zhAdmin from './zh/admin.json';
import zhChat from './zh/chat.json';
import zhCommon from './zh/common.json';
import zhTwAdmin from './zh-TW/admin.json';
import zhTwChat from './zh-TW/chat.json';
import zhTwCommon from './zh-TW/common.json';

export const defaultBrowserLocale =
  ((window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    (window.navigator as any).userLanguage ||
    (window.navigator as any).browserLanguage) ??
  '';

export const browserLocale = (() => {
  // RVの言語コードに合うよう修正する
  let code = defaultBrowserLocale.split('-')[0];
  if (code === 'pt') code = 'pt-BR';
  if (defaultBrowserLocale === 'zh-TW') code = 'zh-TW';

  // 万が一予期しないコードが渡された場合は、英語にしておく
  if (validLanguages.some((lng) => lng === code)) return code as Languages;
  else return 'en';
})();

const locale =
  localStorage.getItem('RV_USER_LANGUAGE')?.replaceAll('"', '') ??
  browserLocale;

i18n.use(initReactI18next).init({
  fallbackLng: ['en', 'ja'],
  resources: {
    en: {
      common: enCommon,
      chat: enChat,
      admin: enAdmin,
    },
    ja: {
      common: jaCommon,
      chat: jaChat,
      admin: jaAdmin,
    },
    zh: {
      common: zhCommon,
      chat: zhChat,
      admin: zhAdmin,
    },
    ko: {
      common: koCommon,
      chat: koChat,
      admin: koAdmin,
    },
    id: {
      common: idCommon,
      chat: idChat,
      admin: idAdmin,
    },
    th: {
      common: thCommon,
      chat: thChat,
      admin: thAdmin,
    },
    vi: {
      common: viCommon,
      chat: viChat,
      admin: viAdmin,
    },
    my: {
      common: myCommon,
      chat: myChat,
      admin: myAdmin,
    },
    fil: {
      common: filCommon,
      chat: filChat,
      admin: filAdmin,
    },
    'pt-BR': {
      common: ptBrCommon,
      chat: ptBrChat,
      admin: ptBrAdmin,
    },
    es: {
      common: esCommon,
      chat: esChat,
      admin: esAdmin,
    },
    fr: {
      common: frCommon,
      chat: frChat,
      admin: frAdmin,
    },
    'zh-TW': {
      common: zhTwCommon,
      chat: zhTwChat,
      admin: zhTwAdmin,
    },
  },
  lng: locale,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
