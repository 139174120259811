import FolderIcon from '@mui/icons-material/Folder';
import { Box, Button, Divider, Stack } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { assertNotNull, validLanguages } from '@remote-voice/utilities';
import csvtojson from 'csvtojson';
import isMobile from 'is-mobile';
import fileDownload from 'js-file-download';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AddButton from '@/components/atoms/AddButton';
import { AdminContent } from '@/components/atoms/AdminPageParts';
import DeleteButton from '@/components/atoms/DeleteButton';
import { LineClampTypography } from '@/components/atoms/LineClampTypography';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { CSVOperationMenu } from '@/components/molecules/admin/CSVOperationMenu';
import { DeletionFooterMenu } from '@/components/molecules/admin/DeletionFooterMenu';
import { ResponsiveFormHeader } from '@/components/molecules/admin/ResponsiveFormHeader';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import useConfirmOkDialog from '@/components/organisms/confirmDialog/useConfirmOkDialog';
import useDropzoneDialog from '@/components/organisms/dropzoneDialog/useDropzoneDialog';
import ImportErrorDialog from '@/components/organisms/ImportErrorDialog';

import {
  ImportTemplateMessage,
  useImportTemplateMessagesMutation,
  useRemoveTemplateMessageCategoriesMutation,
  useTemplateMessageCategoriesQuery,
  useTemplateMessagesLazyQuery,
} from '@/types/graphql';
import templateMessageCsvStringify from '@/utils/templateMessageCsvStringify';

const CategoryIndex = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  const { t } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  useSetHeaderTitle(tCommon('breadcrumb.templateAdmin'));

  const categoriesResult = useTemplateMessageCategoriesQuery({
    variables: { input: {} },
  });
  const [removeCategories, removeCategoriesResult] =
    useRemoveTemplateMessageCategoriesMutation();
  const [getTemplateMessages, getTemplateMessagesResult] =
    useTemplateMessagesLazyQuery();
  const [importTemplateMessages, importTemplateMessagesResult] =
    useImportTemplateMessagesMutation();

  // インポート結果表示用
  const [importedTemplateMessages, setImportedTemplateMessages] = useState<
    ImportTemplateMessage[] | undefined
  >();

  const confirmOkDialog = useConfirmOkDialog();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const [isInDeleteState, setIsInDeleteState] = useState(false);
  const [deletee, setDeletee] = useState<string[]>([]);
  const confirmDelete = useConfirmDeleteDialog();
  const dropzoneDialog = useDropzoneDialog();

  return (
    <Box>
      <LoadingBackdrop open={categoriesResult.loading} />
      <AdminContent>
        <ResponsiveFormHeader
          title={t('template.list')}
          titleVarient="h4"
          toolbuttons={[
            <AddButton key="add" onClick={() => navigate('add')} />,
            // PC端末の時のみCSV操作ボタンを表示
            ...(isMobile() === false
              ? [
                  <PopupState key="csv" variant="popover">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          {t('template.csvOperation')}
                        </Button>
                        <CSVOperationMenu
                          {...bindMenu(popupState)}
                          onClickDownload={() => {
                            popupState.close();
                            // 空のCSVをダウンロード
                            fileDownload(
                              templateMessageCsvStringify([]),
                              'rg-template.csv'
                            );
                          }}
                          onClickExport={async () => {
                            popupState.close();
                            const result = await getTemplateMessages({
                              variables: {
                                input: {},
                              },
                            });
                            if (result.error) {
                              throw result.error;
                            }
                            assertNotNull(result.data);

                            const text = templateMessageCsvStringify(
                              result.data.templateMessages.map((x) => {
                                const langToMsg = (lang: string) =>
                                  x.languages.find((y) => y.language === lang)
                                    ?.message ?? '';
                                return {
                                  ID: x.id,
                                  categoryId: x.category.id,
                                  categoryName: x.category.name,
                                  ja: langToMsg('ja'),
                                  en: langToMsg('en'),
                                  ko: langToMsg('ko'),
                                  zh: langToMsg('zh'),
                                  'zh-TW': langToMsg('zh-TW'),
                                  es: langToMsg('es'),
                                  fil: langToMsg('fil'),
                                  fr: langToMsg('fr'),
                                  id: langToMsg('id'),
                                  my: langToMsg('my'),
                                  'pt-BR': langToMsg('pt-BR'),
                                  th: langToMsg('th'),
                                  vi: langToMsg('vi'),
                                };
                              })
                            );
                            fileDownload(text, 'rg-export.csv');
                          }}
                          onClickImport={async () => {
                            popupState.close();
                            const fileList = await dropzoneDialog.open({});
                            const file = fileList?.[0];

                            if (file) {
                              const text = await file.text();
                              const records: {
                                [index: string]: string | undefined;
                              }[] = await csvtojson().fromString(text);

                              // カラムチェック
                              if (
                                records.some((record) => {
                                  const keys = Object.keys(record);
                                  const columns = [
                                    'ID',
                                    'categoryId',
                                    'categoryName',
                                    ...validLanguages,
                                  ];
                                  return (
                                    // 余計なカラムがあるか
                                    keys.filter(
                                      (x) =>
                                        columns.find((y) => x === y) == null
                                    ).length >= 1 ||
                                    // カラムが足りないか
                                    columns.filter(
                                      (x) => keys.find((y) => x === y) == null
                                    ).length >= 1
                                  );
                                })
                              ) {
                                showSnackbar(
                                  'error',
                                  t('template.csvImportColumnError')
                                );
                                return;
                              }

                              let replacedBreak = false;
                              const result = await (async () => {
                                try {
                                  const result = await importTemplateMessages({
                                    variables: {
                                      input: {
                                        templateMessages: records.map(
                                          (record) => ({
                                            templateMessageId: record.ID ?? '',
                                            templateMessageCategoryId:
                                              record.categoryId ?? '',
                                            templateMessageCategoryName:
                                              record.categoryName ?? '',
                                            languages: validLanguages.map(
                                              (x) => {
                                                const message = record[x] ?? '';
                                                const replacedMsg =
                                                  message.replace(/\n/g, ' ');
                                                if (message !== replacedMsg)
                                                  replacedBreak = true;
                                                return {
                                                  language: x,
                                                  message: replacedMsg,
                                                };
                                              }
                                            ),
                                          })
                                        ),
                                      },
                                    },
                                  });
                                  if (result.errors?.[0] != null) {
                                    throw result.errors[0];
                                  }
                                  return result;
                                } catch (err: any) {
                                  console.error(err);
                                  showSnackbar(
                                    'error',
                                    tCommon('message.unexpectedError')
                                  );
                                }
                              })();
                              if (result == null) {
                                return;
                              }
                              assertNotNull(result.data);

                              if (
                                result.data.importTemplateMessages.find(
                                  (x) => x.errorCode !== 0
                                )
                              ) {
                                // 結果を表示する
                                setImportedTemplateMessages(
                                  result.data.importTemplateMessages
                                );
                              } else {
                                // 成功したので画面更新
                                categoriesResult.refetch();
                                await confirmOkDialog.open({
                                  message:
                                    t('template.csvImportedMsg') +
                                    (replacedBreak
                                      ? t('template.replacedBreak')
                                      : ''),
                                  title: '',
                                });
                              }
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                  </PopupState>,
                ]
              : []),
            // 削除モードの時は削除ボタンを非表示
            ...(isInDeleteState === false
              ? [
                  <DeleteButton
                    key="remove"
                    onClick={() => setIsInDeleteState(true)}
                  />,
                ]
              : []),
          ]}
        />
        <Divider sx={{ mt: 4 }} />
        <MyDataGrid
          autoHeight
          columnHeaderHeight={40}
          columns={[
            {
              field: 'name',
              headerName: t('template.category'),
              minWidth: 150,
              flex: 1,
              sortable: false,
              renderCell: (params) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <FolderIcon sx={{ fontSize: '1.8rem' }} htmlColor="#9dc5f5" />
                  <LineClampTypography variant="body1" rowCount={2}>
                    {params.value}
                  </LineClampTypography>
                </Stack>
              ),
            },
            {
              field: 'templateMessagesCount',
              headerName: isInDeleteState ? '' : t('template.count'),
              width: 55,
              minWidth: 55,
              sortable: false,
              valueGetter: (params) => params.value + t('template.countUnit'),
            },
            ...(isInDeleteState
              ? [
                  {
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    renderHeader: () => tCommon('command.delete'),
                  },
                ]
              : []),
          ]}
          sx={{
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-cell[data-field="templateMessagesCount"]': {
              justifyContent: 'flex-end',
            },
          }}
          rows={categoriesResult.data?.templateMessageCategories ?? []}
          rowHeight={60}
          hideFooter
          disableColumnMenu
          disableMultipleColumnsSorting
          onRowClick={(params) => {
            navigate(`${params.id}/`);
          }}
          onRowSelectionModelChange={(selection) =>
            setDeletee(selection.map((x) => x.toString()))
          }
          checkboxSelection={isInDeleteState}
          loading={
            removeCategoriesResult.loading ||
            getTemplateMessagesResult.loading ||
            importTemplateMessagesResult.loading ||
            categoriesResult.loading
          }
        />
      </AdminContent>
      {isInDeleteState && (
        <DeletionFooterMenu
          deleteButtonDisabled={deletee.length === 0}
          deleteButtonText={t('template.deleteSelectedCategory')}
          onClickDelete={async () => {
            const result = await confirmDelete.open({
              title: t('template.deleteSelectedCategoryMsg'),
              message: t('template.unbackable'),
              okColor: 'primary',
            });
            if (result) {
              await removeCategories({
                variables: { input: { ids: deletee } },
              });
              setDeletee([]);
              setIsInDeleteState(false);
              categoriesResult.refetch();
              showSnackbar('success', t('template.deletedSelectedCategory'));
            }
          }}
          onClickCancel={() => setIsInDeleteState(false)}
        />
      )}
      <ImportErrorDialog
        open={importedTemplateMessages != null}
        onClose={() => setImportedTemplateMessages(undefined)}
        data={importedTemplateMessages ?? []}
        msgToError={(message: ImportTemplateMessage) => {
          if (message.errorCode === 0) {
            return t('template.importError.none');
          } else if (message.errorCode === 1) {
            return t('template.importError.categoryIsNull');
          } else if (message.errorCode === 2) {
            return t('template.importError.invalidCSVFormat');
          } else if (message.errorCode === 3) {
            return t('template.importError.idNotFound');
          } else if (message.errorCode === 4) {
            return t('template.importError.categoryIdNotFound');
          } else if (message.errorCode === 5) {
            return t('template.importError.messageNotSpecified', {
              name:
                message.languages.find((x) => x.message.trim() === '')
                  ?.language ?? '',
            });
          } else {
            return t('template.importError.other');
          }
        }}
      />
    </Box>
  );
};
export default CategoryIndex;
