import FolderIcon from '@mui/icons-material/Folder';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import MyDataGrid from '@/components/atoms/MyDataGrid';
import { HomophoneCategory } from '@/types/graphql';

export type RoomInvitation = {
  url: string;
  guideUrl?: string;
  entryCode?: string;
};

export type HomophoneSelectorDialogProps = {
  open: boolean;
  onClose: (selection: string[] | undefined) => void;
  defaultSelection: string[];
  homophoneCategories: HomophoneCategory[];
};

const HomophoneSelectorDialog = (props: HomophoneSelectorDialogProps) => {
  const { t } = useTranslation('admin');
  const { t: commonT } = useTranslation('common');

  const [selection, setSelection] = useState<string[]>(props.defaultSelection);
  useEffect(() => {
    setSelection(props.defaultSelection);
  }, [props]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(undefined)}
      scroll="body"
    >
      <DialogTitle variant="h5" display="grid" gridTemplateColumns="1fr auto">
        {t('room.selectHomophones')}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <MyDataGrid
          autoHeight
          columns={[
            {
              field: 'selected',
              width: 50,
              minWidth: 50,
              renderCell: (params) => (
                <Checkbox
                  color="info"
                  checked={selection.find((id) => id === params.id) != null}
                  onChange={(_, checked) => {
                    if (checked) {
                      setSelection([...selection, params.id.toString()]);
                    } else {
                      setSelection(selection.filter((id) => id !== params.id));
                    }
                  }}
                />
              ),
            },
            {
              field: 'name',
              flex: 1,
              renderCell: (params) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <FolderIcon sx={{ fontSize: '1.8rem' }} htmlColor="#9dc5f5" />
                  <Typography variant="body1">{params.value}</Typography>
                </Stack>
              ),
            },
            {
              field: 'homophonesCount',
              width: 50,
              minWidth: 50,
              valueGetter: (params) => params.value + '件',
            },
          ]}
          columnHeaderHeight={0}
          hideFooter
          rowHeight={60}
          rows={props.homophoneCategories}
          rowSelectionModel={selection}
          sx={{
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'transparent',
            },
            '& .MuiDataGrid-columnHeaders': {
              display: 'none',
            },
            '& .MuiDataGrid-cell[data-field="homophoneCount"]': {
              textAlign: 'right',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={1}
          width={1}
        >
          <CancelButton onClick={() => props.onClose(undefined)} />
          <Button
            onClick={() => {
              props.onClose(selection);
            }}
          >
            {commonT('command.confirm')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default HomophoneSelectorDialog;
