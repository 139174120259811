import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';

export type LogDownloadDialogProps = {
  open: boolean;
  onClose: () => void;
  downloadUrl?: string;
  loading?: boolean;
  hasError?: boolean;
};
const LogDownloadDialog = (props: LogDownloadDialogProps) => (
  <Dialog open={props.open}>
    <DialogTitle>ログダウンロード</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={props.onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogActions style={{ justifyContent: 'center' }}>
      {props.loading && (
        <CircularProgress size={24} sx={{ position: 'absolute' }} />
      )}
      <Button
        variant={props.hasError ? 'outlined' : 'contained'}
        disabled={props.loading}
        href={props.downloadUrl}
        onClick={(e) => {
          e.stopPropagation();
          props.onClose();
        }}
      >
        {props.hasError
          ? 'ファイルが存在しません'
          : props.loading
          ? 'しばらくお待ち下さい'
          : 'ダウンロード開始'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default LogDownloadDialog;
