import { Box, Drawer } from '@mui/material';
import { assertNotNull, sleep } from '@remote-voice/utilities';
import Cookies from 'js-cookie';
import Enumerable from 'linq';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFontSize, useSetFontSize } from '@/components/hooks/useFontSize';
import { useSetLanguage } from '@/components/hooks/useLanguage';
import useLoadingBackdrop from '@/components/hooks/useLoadingBackdrop';
import useOpenFile from '@/components/hooks/useOpenFile';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { useSetUserName, useUserName } from '@/components/hooks/useUserName';
import useAudioTest from '@/components/organisms/audioTest/useAudioTest';
import ChatBody from '@/components/organisms/chat/ChatBody';
import ChatFooter from '@/components/organisms/chat/ChatFooter';
import ChatFooterReply from '@/components/organisms/chat/ChatFooterReply';
import ChatHeader from '@/components/organisms/chat/ChatHeader';
import ChatMenu from '@/components/organisms/chat/ChatMenu';
import { ChatReaction } from '@/components/organisms/chat/ChatReactionList';
import useChat from '@/components/organisms/chat/useChat';
import { useChatroomsWaitingGuestsEnter } from '@/components/organisms/chat/useChatroomsWaitingGuestsEnter';
import useChatUserListDialog from '@/components/organisms/chat/useChatUserListDialog';
import useLeaveChatRoomDialog from '@/components/organisms/chat/useLeaveChatRoomDialog';
import useChatFontSettingsDialog from '@/components/organisms/chatFontSettingsDialog/useChatFontSettingsDialog';
import useChatUserSettingsDialog from '@/components/organisms/chatUserSettingsDialog/useChatUserSettingsDialog';
import useConfirmContinueDialog from '@/components/organisms/confirmDialog/useConfirmContinueDialog';
import useConfirmOkDialog from '@/components/organisms/confirmDialog/useConfirmOkDialog';
import useInvitingRoomDialog from '@/components/organisms/invitingRoomDialog/useInvitingRoomDialog';
import useReverseTranslationDialog from '@/components/organisms/reverseTranslationDialog/useReverseTranslationDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';
import {
  ChatUserRole,
  TranslateMethod,
  TtsService,
  useNotifyLeaveMutation,
} from '@/types/graphql';
import { isChatEditor, isChatHost } from '@/utils/userRole';

// useChatのロジックを、各種プレゼンテーションコンポーネントと接続する
const Chat = (props: { ttsService?: TtsService }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chat');

  useEffect(() => {
    // このコンポーネントの中では直接使わないが，一度コールしてHowlを有効化する
    // 以下の行がないとチャット画面をリロードした際にWeb Audio APIのセキュリティを突破できない
    new Howl({
      src: ['/sample_audio.mp3'],
      onunlock: () => console.log('audio unlocked'),
    });
  }, []);

  const micStartRef = useRef<(() => Promise<void>) | undefined>();
  const micEndRef = useRef<(() => void) | undefined>();
  const audioDeviceEventCallbacks = useMemo(
    () => ({
      onEnded: () => micEndRef.current?.(),
      onMuted: () => micEndRef.current?.(),
      onUnmuted: () => micStartRef.current?.(),
    }),
    []
  );

  const {
    userId,
    userPassword,
    chatRoomId,
    sessionEntryCode,
    language,
    reverseLanguage,
    setReverseLanguage,
    chatUserRole,
    sessionInfo,
    users,
    joiningUsers,
    isQuestionMode,

    sendMessage,
    removeMessage,
    sendTemplate,
    sendImages,
    clearMessages,
    setReaction,
    removeUser,
    muteUserMic,
    endSession,
    setFlag,
    setQuestionMode,

    messages,
    loadPreviousMessages,
    getPreviousMessage,

    chatText,
    setChatText,
    isMicMode,
    setIsMicMode,
    isTemplateMode,
    setIsTemplateMode,
    templateMessages,

    playMessageEnabled,
    setPlayMessageEnabled,
    playChat,
    stopChat,
    mini,
    playingMessageId,

    replaceNgWord,

    setReplyInfo,
    replyUser,
    replyText,

    loading,
  } = useChat({ ttsService: props.ttsService, audioDeviceEventCallbacks });

  const loadingBackdrop = useLoadingBackdrop();
  const confirmDialog = useConfirmContinueDialog();
  const okDialog = useConfirmOkDialog();
  const invitingRoomDialog = useInvitingRoomDialog();
  const userListDialog = useChatUserListDialog(users);
  const leaveChatRoomDialog = useLeaveChatRoomDialog();
  const chatFontSettingsDialog = useChatFontSettingsDialog();
  const chatUserSettingsDialog = useChatUserSettingsDialog();
  const revTranslationDialog = useReverseTranslationDialog();
  const audioTest = useAudioTest();
  const showSnackbar = useSnackbar();
  const canGuideControl = isChatHost(chatUserRole);

  // 参加者一覧ダイアログの表示
  const showUserListDialog = useCallback(() => {
    userListDialog.open({
      guideOptions: canGuideControl
        ? {
            chatRoomId: chatRoomId,
            entryCode: sessionEntryCode,
            userId: userId,
            userPassword: userPassword,
          }
        : undefined,
    });
  }, [
    chatRoomId,
    canGuideControl,
    sessionEntryCode,
    userId,
    userListDialog,
    userPassword,
  ]);
  // 待機室入室時の通知
  useChatroomsWaitingGuestsEnter({
    onNotifyClick: useCallback(
      (roomId: string) => {
        if (roomId === chatRoomId) showUserListDialog();
      },
      [chatRoomId, showUserListDialog]
    ),
  });

  const [notifyLeave] = useNotifyLeaveMutation();
  const openFile = useOpenFile();
  const [canUseMic] = useState(() => Cookies.get('RV_CAN_USE_MIC') === 'true');
  const [openMenu, setOpenMenu] = useState(false);
  const [reactions, setReactions] = useState<ChatReaction[]>();

  const fontSize = useFontSize();
  const setFontSize = useSetFontSize();
  const userName = useUserName();
  const setUserName = useSetUserName();
  const setLanguage = useSetLanguage();

  const [keepIsRecording, setKeepIsRecording] = useState(false);

  const isReadyMic =
    mini.initializing === false && mini.ending === false && sessionInfo != null;
  const micUsing = isReadyMic === false || mini.recording;
  const micUsingRef = useRef(micUsing);
  useEffect(() => {
    micUsingRef.current = micUsing;
  }, [micUsing]);

  const translateMethod = sessionInfo?.translateMethod;
  const keepTranslateMethod = useRef(translateMethod);

  const startVoiceRecognition = mini.startVoiceRecognition;
  micStartRef.current = useCallback(async () => {
    assertNotNull(sessionInfo);
    try {
      if (translateMethod === TranslateMethod.Consecutive) {
        keepTranslateMethod.current = translateMethod;
        await startVoiceRecognition({
          // deviceId: audioInputDeviceId,
          smoothingTimeConstant: sessionInfo.vadSmoothingTimeConstant,
          energyOffset: sessionInfo.vadEnergyOffset,
          energyThresholdRatioPos: sessionInfo.vadEnergyThresholdRatioPos,
          energyThresholdRatioNeg: sessionInfo.vadEnergyThresholdRatioNeg,
          energyIntegration: sessionInfo.vadEnergyIntegration,
          userId: userId,
          // 自動継続しない
          continuous: false,
          // 音声入力の終了検知も行わない
          disableVad: true,
          onSocketError: () => {
            showSnackbar('error', t('voiceStartFailed'));
          },
        });
      } else {
        keepTranslateMethod.current = translateMethod;
        await startVoiceRecognition({
          // deviceId: audioInputDeviceId,
          smoothingTimeConstant: sessionInfo.vadSmoothingTimeConstant,
          energyOffset: sessionInfo.vadEnergyOffset,
          energyThresholdRatioPos: sessionInfo.vadEnergyThresholdRatioPos,
          energyThresholdRatioNeg: sessionInfo.vadEnergyThresholdRatioNeg,
          energyIntegration: sessionInfo.vadEnergyIntegration,
          userId: userId,
          // 自動継続する
          continuous: true,
          onSocketError: () => {
            showSnackbar('error', t('voiceStartFailed'));
          },
        });
      }
    } catch {
      showSnackbar('error', t('voiceStartFailed'));
    }
  }, [
    sessionInfo,
    translateMethod,
    startVoiceRecognition,
    userId,
    showSnackbar,
    t,
  ]);
  const endVoiceRecognition = mini.endVoiceRecognition;
  micEndRef.current = useCallback(() => {
    if (
      keepTranslateMethod.current === TranslateMethod.Consecutive ||
      keepTranslateMethod.current === TranslateMethod.Simulteneous
    ) {
      endVoiceRecognition();
    }
  }, [endVoiceRecognition]);
  const cancelVoiceRecognition = mini.cancelVoiceRecognition;
  const micCancel = useCallback(() => {
    if (
      keepTranslateMethod.current === TranslateMethod.Consecutive ||
      keepTranslateMethod.current === TranslateMethod.Simulteneous
    ) {
      cancelVoiceRecognition();
    }
  }, [cancelVoiceRecognition]);

  const onClickTemplateMessage = isReadyMic
    ? async () => {
        if (isTemplateMode === false) {
          setKeepIsRecording(mini.recording);
          // 録音中であれば停止する
          if (mini.recording) {
            if (
              keepTranslateMethod.current === TranslateMethod.Consecutive ||
              keepTranslateMethod.current === TranslateMethod.Simulteneous
            ) {
              endVoiceRecognition();
            }
          }
          // 停止を待機する
          await loadingBackdrop.open(async () => {
            while (micUsingRef.current) {
              await sleep(100);
            }
          });
        } else {
          // 必要に応じて音声入力を再開
          if (keepIsRecording) {
            micStartRef.current?.();
          }
        }

        setOpenMenu(false);
        setChatText('');
        setIsTemplateMode(!isTemplateMode);
        setReactions(undefined);
      }
    : undefined;

  const onClickSendImage = useCallback(
    () =>
      openFile({
        accept:
          'image/jpg,image/jpeg,image/png,image/heic,.jpg,.jpeg,.png,.heic',
        multiple: true,
        onOpen: async (fileList) => {
          // アップロード先取得
          await sendImages(
            Enumerable.range(0, fileList.length)
              .select((x) => fileList[x])
              .toArray()
          );
        },
      }),
    [openFile, sendImages]
  );

  return (
    <Box
      display="grid"
      gridTemplateRows="auto 1fr auto"
      height={1}
      sx={{
        bgcolor: (t) => t.palette.background.default,
        userSelect: 'none',
      }}
    >
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)}>
        <ChatMenu
          userRole={chatUserRole ?? ChatUserRole.Guest}
          userCount={joiningUsers.length}
          isGuestInputRestricted={
            (sessionInfo?.isGuestInputRestricted ?? false) || !isQuestionMode
          }
          onClickParticipants={() => {
            setOpenMenu(false);
            showUserListDialog();
          }}
          onClickInvite={() => {
            setOpenMenu(false);
            invitingRoomDialog.open(
              {
                invitation: {
                  url: `${location.origin}/chat/${chatRoomId}`,
                  entryCode: sessionEntryCode,
                },
              },
              (c) => <ChatThemeProvider>{c}</ChatThemeProvider>
            );
          }}
          onClickMuteAllUser={async () => {
            setOpenMenu(false);
            if (
              await confirmDialog.open({
                title: t('menu.muteAllMicsTitle'),
                okLabel: t('menu.muteAllMicsOk'),
                okColor: 'warning',
              })
            ) {
              muteUserMic(undefined);
            }
          }}
          onClickRemoveAllUser={async () => {
            setOpenMenu(false);
            if (
              await confirmDialog.open({
                title: t('menu.removeAllUsersTitle'),
                okLabel: t('menu.removeAllUsersOk'),
                okColor: 'warning',
              })
            ) {
              removeUser(undefined);
            }
          }}
          onClickTemplateMessage={onClickTemplateMessage}
          onClickClearMessages={async () => {
            setOpenMenu(false);
            if (
              await confirmDialog.open({
                title: t('menu.clearScreenTitle'),
                message: t('menu.clearScreenMessage'),
                okLabel: t('menu.clearScreenOk'),
              })
            ) {
              clearMessages();
            }
          }}
          playMessageEnabled={playMessageEnabled}
          onClickTogglePlayMessageEnabled={() => {
            const newEnabled = !playMessageEnabled;
            setPlayMessageEnabled(newEnabled);
            if (newEnabled) {
              showSnackbar('success', t('voiceReadingToOn'));
            } else {
              showSnackbar('success', t('voiceReadingToOff'));
            }
          }}
          onClickReverseTranslation={async () => {
            setOpenMenu(false);
            const selected = await revTranslationDialog.open({
              defaultRevLanguage: reverseLanguage,
              // 参加中のユーザーのうち自分の選択言語以外の言語をリストに出す
              enableRevLanguages: Array.from(
                new Set(joiningUsers.map((user) => user.language))
              ).filter((lang) => lang !== language),
            });
            if (selected !== null) setReverseLanguage(selected);
          }}
          onClickChatSettings={async () => {
            setOpenMenu(false);
            const result = await chatUserSettingsDialog.open({
              defaultValue: { userName, language },
            });
            if (result) {
              setUserName(result.userName);
              setLanguage(result.language);

              // TODO: 本来はリロードせずにデータだけをリフェッチさせたい
              window.location.reload();
            }
          }}
          onClickFontSettings={async () => {
            setOpenMenu(false);
            const result = await chatFontSettingsDialog.open({
              defaultValue: fontSize,
            });
            if (result) {
              setFontSize(result);
            }
          }}
          onClickDeviceTest={() => {
            setOpenMenu(false);
            audioTest();
          }}
          onClickSendImage={onClickSendImage}
          onClickLeaveSession={async () => {
            setOpenMenu(false);
            const leaveResult = await leaveChatRoomDialog.open({
              showExitSessionCheck: canGuideControl, // ガイドの場合はチャットセッション自体のクローズ有無選択
            });
            if (leaveResult === 'cancel') {
              return;
            }
            // セッション終了処理
            if (leaveResult === 'exit') {
              // 但しガイドが他にいた場合は、セッション終了せず参加者の全退出のみ
              if (
                joiningUsers.filter((x) => isChatHost(x.userRole)).length >= 2
              ) {
                await removeUser(undefined);
              } else {
                await endSession();
              }
            } else {
              await notifyLeave({
                variables: {
                  input: {
                    chatRoomId,
                    sessionEntryCode,
                    userId,
                    userPassword,
                  },
                },
              });
            }
            // 退出・リダイレクト処理
            if (chatUserRole === ChatUserRole.Guide) {
              // navigate(
              //   `/chat/${chatRoomId}/?chatUserId=${userId}&chatUserPassword=${userPassword}`
              // );
              navigate('/chat/thanks');
            } else if (chatUserRole === ChatUserRole.TenantCompanyAdmin) {
              navigate('/admin/room');
              // navigate('/chat/thanks?role=guide');
            } else {
              navigate('/chat/thanks');
            }
          }}
        />
      </Drawer>
      <ChatHeader
        roomName={sessionInfo?.roomName ?? ''}
        joiningUsersCount={joiningUsers.length}
        waitingUsersCount={
          canGuideControl
            ? users.filter((x) => x.isJoining && x.isWaiting).length
            : 0
        }
        onOpenMenu={() => setOpenMenu(true)}
        onClickParticipants={
          isChatEditor(
            sessionInfo?.isGuestInputRestricted ?? false,
            chatUserRole
          )
            ? showUserListDialog
            : undefined
        }
        isQuestionMode={canGuideControl ? isQuestionMode : undefined}
        onChangeQuestionMode={(mode) => {
          setQuestionMode(mode); // ローカルのフラグセットはsubscription経由で行われる
        }}
      />
      <ChatThemeProvider>
        <ChatBody
          chatRoomId={chatRoomId}
          sessionEntryCode={sessionEntryCode}
          userId={userId}
          language={language}
          reverseLanguage={reverseLanguage}
          userRole={chatUserRole ?? ChatUserRole.Guest}
          //
          loading={loading}
          messages={messages}
          getIsContinuedMessage={
            (key, msg) => getPreviousMessage(key)?.[1].userId === msg.userId // 次のメッセージが同じユーザーであれば継続とする
          }
          getUser={(userId) => users.find((x) => x.userId === userId)}
          loadPrevious={loadPreviousMessages}
          isGuestInputRestricted={sessionInfo?.isGuestInputRestricted ?? false}
          onEditMessage={async (message) => {
            if (mini.recording) {
              await okDialog.open({
                okLabel: t('editMessage.warningOk'),
                title: t('editMessage.warningOnRecording'),
              });
              return;
            }
            if (
              chatText !== '' &&
              chatText !== message &&
              (await confirmDialog.open({
                title: t('editMessage.confirm'),
                okLabel: t('editMessage.confirmOK'),
              })) === false
            ) {
              return;
            }
            setChatText(message);
            setIsMicMode(false);
            setIsTemplateMode(false);
            setReactions(undefined);
            await sleep(0); // 文字が置き換わるのをsleepで待機
            document.getElementById('chat-input-text-area')?.focus();
          }}
          onDeleteMessage={removeMessage}
          onReplyMessage={async (id, userId, text) => {
            setReplyInfo({ id, text, userId });
            // setIsMicMode(false);
            setIsTemplateMode(false);
            setReactions(undefined);
            await sleep(0); // 文字が置き換わるのをsleepで待機
            document.getElementById('chat-input-text-area')?.focus();
          }}
          onReaction={setReaction}
          onOpenReaction={(reactions) => {
            setReactions(reactions);
            setIsMicMode(true);
          }}
          onPlayMessage={playMessageEnabled ? playChat : undefined}
          onStopMessage={stopChat}
          onCheckFlag={
            canGuideControl
              ? async (commandId, check) => {
                  await setFlag(commandId, check);
                  if (check) {
                    showSnackbar('success', t('flagAdded'));
                  } else {
                    showSnackbar('success', t('flagRemoved'));
                  }
                }
              : undefined
          }
          playingMessageId={playingMessageId}
          replaceNgWord={replaceNgWord}
          reactionIsOnlyQuestion={!isQuestionMode && !canGuideControl}
        />
        {replyText != null && (
          <ChatFooterReply
            reply={replyText}
            onCancelReply={() => setReplyInfo(undefined)}
            userName={replyUser?.userName ?? ''}
            userRole={chatUserRole ?? ChatUserRole.Guest}
            sx={isMicMode ? { pb: '22px' } : undefined}
          />
        )}
        {(canGuideControl ||
          isChatEditor(
            (sessionInfo?.isGuestInputRestricted ?? false) || !isQuestionMode,
            chatUserRole
          )) && (
          <ChatFooter
            chatRoomId={chatRoomId}
            sessionEntryCode={sessionEntryCode}
            userId={userId}
            userPassword={userPassword}
            language={language}
            //
            chatText={chatText}
            onChangeChatText={setChatText}
            isMicMode={isMicMode}
            onChangeMicMode={(v) => {
              setIsMicMode(v);
              setReactions(undefined);
              // setReplyId(undefined);
              // setReplyText(undefined);
            }}
            isTemplateMode={isTemplateMode && templateMessages != null}
            onChangeTemplateMode={(v) => {
              setIsTemplateMode(v);
              setReactions(undefined);
              setReplyInfo(undefined);
              // 必要に応じて音声入力を再開
              if (keepIsRecording) {
                micStartRef.current?.();
              }
            }}
            templateMessages={templateMessages ?? []}
            isReactionMode={reactions != null}
            reactions={reactions}
            onChangeReactionMode={(v) => {
              if (!v) setReactions(undefined);
            }}
            //
            onSendMessage={(text) => {
              sendMessage(text);
              setReplyInfo(undefined);
            }}
            onSendTemplate={sendTemplate}
            onSendImage={onClickSendImage}
            onClickTemplateMessage={
              canGuideControl ? onClickTemplateMessage : undefined
            }
            canUseMic={
              canUseMic &&
              (sessionInfo == null || mini.initializing
                ? true
                : mini.canVoiceRecognition)
            }
            isSimulteneous={translateMethod === TranslateMethod.Simulteneous}
            isRecording={mini.recording}
            isReadyMic={isReadyMic}
            onMicStart={micStartRef.current}
            onMicEnd={micEndRef.current}
            onMicCancel={micCancel}
          />
        )}
      </ChatThemeProvider>
      {playingMessageId != null && (
        <div id="fps-slowdown-make-sound-noisy"></div>
      )}
    </Box>
  );
};
export default Chat;
