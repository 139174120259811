import BookmarkIcon from '@mui/icons-material/Bookmark';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { assertNotNull } from '@remote-voice/utilities';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LoadingBackdrop from '../../../components/atoms/LoadingBackdrop';
import { useCheckPermission } from '../../../components/hooks/useCheckPermission';

import useConfirmDeleteDialog from '../../../components/organisms/confirmDialog/useConfirmDeleteDialog';
import {
  useRemoveChatRoomMutation,
  useTenantCompaniesQuery,
} from '../../../types/graphql';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import RoomListItem from '@/components/organisms/admin/RoomListItem';
import { useChatroomsWaitingGuestsEnter } from '@/components/organisms/chat/useChatroomsWaitingGuestsEnter';
import useInvitingRoomDialog from '@/components/organisms/invitingRoomDialog/useInvitingRoomDialog';
import { adminMaxWidth } from '@/utils/constants';

const RoomItemButton = styled(Button)(({ theme }) => ({
  bgcolor: theme.palette.common.white,
  display: 'flex',
  mx: 'auto',
  px: 10,
  py: 1,
  textTransform: 'none',
  width: '100%',
}));

const RoomIndex = () => {
  useCheckPermission({ tenantCompanyAdmin: true });

  // ルーム作成可能数上限を取得するため
  const tenantCompanies = useTenantCompaniesQuery({ variables: { input: {} } });
  const roomCreationLimit =
    tenantCompanies.data?.tenantCompanies[0]?.roomCreationLimit;

  const chatRoomsResult = useChatroomsWaitingGuestsEnter({});
  const [removeChatRoom, removeChatRoomResult] = useRemoveChatRoomMutation();
  const confirmDeleteDialog = useConfirmDeleteDialog();
  const invitingRoomDialog = useInvitingRoomDialog();

  const navigate = useNavigate();
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('admin');
  const snackbar = useSnackbar();

  useSetHeaderTitle(t('userSettings.userSettings'));

  return (
    <AdminContent>
      <LoadingBackdrop
        open={chatRoomsResult.loading || removeChatRoomResult.loading}
      />
      <Box
        sx={{
          width: 1,
          maxWidth: adminMaxWidth,
        }}
      >
        <Paper
          sx={{
            backgroundColor: (t) => t.palette.common.white,
            p: 2,
            my: 2,
            boxSizing: 'border-box',
            height: '100%',
            borderRadius: 2,
          }}
        >
          <Stack sx={{ height: '100%' }}>
            <Typography variant="h3" sx={{ textAlign: 'center', mb: 2, mt: 3 }}>
              {t('room.create')}
            </Typography>
            <Button
              sx={{ mx: 'auto', mb: 4, mt: 1, display: 'block', px: 10 }}
              disabled={
                roomCreationLimit == null || chatRoomsResult.data == null
              }
              onClick={async () => {
                assertNotNull(roomCreationLimit);
                assertNotNull(chatRoomsResult.data);
                // 作成上限を超えていないかチェック
                if (
                  roomCreationLimit > 0 &&
                  roomCreationLimit <= chatRoomsResult.data.chatRooms.length
                ) {
                  if (
                    await confirmDeleteDialog.open({
                      title: t('room.excessRoomCreationLimitMsg'),
                      okColor: 'primary',
                    })
                  ) {
                    const oldestRoom =
                      chatRoomsResult.data.chatRooms[
                        chatRoomsResult.data.chatRooms.length - 1
                      ];
                    await removeChatRoom({
                      variables: { input: { id: oldestRoom.id } },
                    });
                    chatRoomsResult.refetch();
                    snackbar('success', commonT('message.removed'));
                    navigate('add');
                  }
                } else {
                  navigate('add');
                }
              }}
              size="large"
            >
              {t('room.issue')}
            </Button>
            <Divider />
            <Typography variant="h3" sx={{ textAlign: 'center', my: 3 }}>
              {t('room.list')}
            </Typography>

            <Stack spacing={2}>
              {chatRoomsResult.data?.chatRooms.length === 0 && (
                <Typography
                  color={(t) => t.palette.grey[500]}
                  textAlign="center"
                >
                  {t('room.noRoomMsg')}
                </Typography>
              )}
              {chatRoomsResult.data?.chatRooms.map((chatRoom) => (
                <RoomListItem
                  chatRoom={chatRoom}
                  onInvite={async (invitation) => {
                    await invitingRoomDialog.open({ invitation });
                  }}
                  key={chatRoom.id}
                  onDelete={async () => {
                    const name = `${chatRoom.name.slice(0, 27)}${
                      chatRoom.name.length >= 28 ? '...' : ''
                    }`;
                    // 確認ダイアログ表示
                    if (
                      await confirmDeleteDialog.open({
                        title: t('room.confirmDeleteMsg', { name }),
                        okColor: 'primary',
                      })
                    ) {
                      await removeChatRoom({
                        variables: { input: { id: chatRoom.id } },
                      });
                      chatRoomsResult.refetch();
                      snackbar('success', commonT('message.removed'));
                    }
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Paper>
        <Stack
          spacing={1}
          alignItems={'center'}
          sx={{ width: 'max-content', margin: 'auto' }}
        >
          <RoomItemButton
            startIcon={<MenuBookIcon />}
            variant="outlined"
            size="large"
            onClick={() => navigate('/admin/template')}
          >
            {t('template.admin')}
          </RoomItemButton>
          <RoomItemButton
            startIcon={<BookmarkIcon />}
            variant="outlined"
            size="large"
            onClick={() => navigate('/admin/homophone')}
          >
            {t('homophone.correctionRecordAdmin')}
          </RoomItemButton>
        </Stack>
      </Box>
    </AdminContent>
  );
};
export default RoomIndex;
