import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import HomophoneSelectorDialog, {
  HomophoneSelectorDialogProps,
} from '@/components/organisms/homophoneSelectorDialog/HomophoneSelectorDialog';

import { HomophoneCategory } from '@/types/graphql';

const TemplateSelectorDialogEx = (
  props: Omit<HomophoneSelectorDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <HomophoneSelectorDialog open={isOpen} {...props} />;
};

const useTemplateSelectorDialog = () => {
  const openAwaiter = useOpenAwaiter<string[] | undefined>();
  return {
    open: (
      props: Omit<HomophoneSelectorDialogProps, 'open' | 'onClose'> & {
        homophoneCategories: HomophoneCategory[];
      }
    ) =>
      openAwaiter.open(
        <TemplateSelectorDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useTemplateSelectorDialog;
