import Cookies from 'js-cookie';

export const getCookieOrSetDefault = (name: string, def: string) => {
  const value = Cookies.get(name);
  if (value == null) {
    Cookies.set(name, def, { expires: 400 });
    return def;
  } else {
    return value;
  }
};
