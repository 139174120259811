import { Howler } from 'howler';

import { env } from '@/env';

class MiniRecognitionHandler {
  private accessToken = '';
  private language = '';

  private socket?: WebSocket;
  private mediaStreamAudioSourceNode?: MediaStreamAudioSourceNode;
  private audioWorkletNode?: AudioWorkletNode;
  private sendIntervalTimerId?: number;
  private opened = false;

  private miniBaseURL = env.REACT_APP_MINI_RECOGNITION_URL;

  private context = Howler.ctx;

  private isDetermined = false;

  private nictAsrOptions = [
    'response_format=v2',
    'progressive=false',
    'temporary=true',
  ].join(';');

  private contentType = [
    'audio/x-pcm',
    'bit=16',
    'rate=48000',
    'channels=1',
  ].join(';');

  private sendQueue: Int16Array[] = [];
  private zeroData?: Blob;

  // constructor() {
  //   this.context = Howler.ctx;
  // }

  // websocketを接続する前から前もって音声をバッファリングする場合にこの処理を呼び出す。
  // 実際にwebsocketに接続する場合、startSendMediaStreamを呼び出す
  startBuffering = async (stream: MediaStream) => {
    if (this.mediaStreamAudioSourceNode) {
      throw 'Can only be called once';
    }

    // src/audioworklet/pcm.tsのビルドされたものを読み込む
    await this.context.audioWorklet.addModule('/pcm-encode-audioworklet.js');

    this.mediaStreamAudioSourceNode =
      this.context.createMediaStreamSource(stream);

    this.audioWorkletNode = new AudioWorkletNode(
      this.context,
      'PcmEncodeAudioWorkletProcessor'
    );

    let queuePadding = 0;
    this.audioWorkletNode.port.onmessage = (ev: MessageEvent<Int16Array>) => {
      // 38kモノラルを16kモノラルに変換している
      // 前回サンプリングした要素のインデックスによって次にサンプリングする要素を決める
      this.sendQueue.push(
        ev.data.filter((_, i) => (i - queuePadding) % 3 === 0)
      );
      queuePadding = 2 - ((ev.data.length - 1 - queuePadding) % 3);

      if (this.socket == null) {
        // startSendMediaStream が呼ばれてない場合、一定量より古いものは削除する
        const bufferSamples = 16000 * 0.5; // 0.5秒
        let bufferCounter = 0;
        for (let i = 0; i < this.sendQueue.length; i++) {
          bufferCounter += this.sendQueue[this.sendQueue.length - i - 1].length;
          if (bufferCounter > bufferSamples) {
            // サイズがオーバーした場合、この部分までを使用する。
            this.sendQueue = this.sendQueue.slice(-(i + 1));
            break;
          }
        }
      }
    };

    this.mediaStreamAudioSourceNode.connect(this.audioWorkletNode);
  };

  startSendMediaStream = async (params: {
    stream: MediaStream;
    language: string;
    onDataReceivedCallback: OnDataReceivedCallback;
    onSendSlowError: () => void;
    userId: string; // 認識のシーケンスが崩れないようにするために渡している
    accessToken: string;
  }) => {
    this.zeroData = await (async () =>
      new Blob([new Int16Array(1280)], { type: 'audio/pcm' }))();

    // ローカルコピー
    this.language = params.language;
    this.accessToken = params.accessToken;
    // Websocketに接続
    const socket = new WebSocket(this.buildAsrWsUrl(params.userId));
    socket.onmessage = (event: MessageEvent<string>) => {
      const data = JSON.parse(event.data) as FeatApiResponse;
      this.isDetermined = true;
      params.onDataReceivedCallback(data.sr_text ?? '', true);
    };
    socket.onclose = () => {
      if (this.isDetermined === false) params.onDataReceivedCallback('', true);
    };
    this.socket = socket;

    // バッファリングがスタートしていなければ行う
    if (this.mediaStreamAudioSourceNode == null) {
      await this.startBuffering(params.stream);
    }

    return new Promise<void>((resolve, reject) => {
      socket.onerror = reject;
      socket.onopen = () => {
        this.opened = true;
        try {
          // 処理Loopスタート
          if (this.sendIntervalTimerId) {
            clearInterval(this.sendIntervalTimerId);
          }
          this.sendIntervalTimerId = window.setInterval(async () => {
            // バッファに未送信データが一定量以上たまっていればエラーとする
            if (socket.bufferedAmount > 300000) {
              this.opened = false;
              this.socket?.close();
              this.endSendMediaStream();
              params.onSendSlowError();
            } else {
              const readyToSend = this.sendQueue.splice(0);
              if (readyToSend.length > 0) {
                const data = await (async () =>
                  new Blob(readyToSend, { type: 'audio/pcm' }))();
                if (this.opened) {
                  this.socket?.send(data);
                }
              }
            }
          }, 20);
        } catch (e: any) {
          this.endSendMediaStream();
          reject(e);
        }
        resolve();
      };
    });
  };

  breakSendMediaStream = () => {
    this.socket?.send(JSON.stringify({ command: 'recog-break-continuous' }));
  };

  endSendMediaStream = () => {
    // AudioworkletProcessorのprocessを終了
    this.audioWorkletNode?.port.postMessage('kill-process');
    //音声が終了したことをサーバーに通知
    if (this.opened) {
      this.opened = false;
      this.socket?.send(JSON.stringify({ command: 'recog-break' }));
    }
    //音声をサーバーに投げる処理をストップ
    if (this.sendIntervalTimerId) {
      clearInterval(this.sendIntervalTimerId);
    }

    // このあと，サーバー側から切断されるのでこちらからSocket.closeする必要はない
    this.mediaStreamAudioSourceNode?.disconnect();
    this.audioWorkletNode?.disconnect();
  };

  private buildAsrWsUrl = (userId: string) => {
    return (
      this.miniBaseURL +
      '?input-language=' +
      encodeURIComponent(this.language) +
      '&user-id=' +
      encodeURIComponent(userId)
    );
    // return (
    //   this.miniBaseURL +
    //   '/?process=' +
    //   encodeURIComponent(`nict-asr`) +
    //   '&nict-asr-options=' +
    //   encodeURIComponent(this.nictAsrOptions) +
    //   '&content-type=' +
    //   encodeURIComponent(this.contentType) +
    //   '&access-token=' +
    //   encodeURIComponent(this.accessToken) +
    //   '&input-language=' +
    //   encodeURIComponent(this.language) +
    //   (env.REACT_APP_MINI_BACKEND_ID
    //     ? '&process=' +
    //       encodeURIComponent(`nict-asr#${env.REACT_APP_MINI_BACKEND_ID}`)
    //     : '')
    // );
  };
}

// type MiniWebsocketApiResponse = {
//   type: string;
//   // eslint-disable-next-line @typescript-eslint/naming-convention
//   session_id: string;
//   status: 'recog-in-progress' | 'recog-finished';
//   response: Array<{
//     result: string;
//     words: Array<string>;
//     determined: false;
//     time: 0;
//   }>;
// };

type FeatApiResponse = {
  result: 'ERROR' | 'SUCCESS';
  errorCode: number;
  errorMessage?: string;
  lang: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  sr_text?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  mt_text?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rmt_text?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ss_data?: string;
};

export type OnDataReceivedCallback = (result: string, final: boolean) => void;
export default MiniRecognitionHandler;
