import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Backdrop, Box, Button, Paper, Stack, Typography } from '@mui/material';
import { sleep } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OnboardingVideo from '@/components/organisms/onboardingDialog/OnboardingVideo';

export type OnboardingDialogProps = {
  open: boolean;
  onClose: () => void;
  // 同通
  isSimulteneous: boolean;
};

const OnboardingDialog = (props: OnboardingDialogProps) => {
  const { t } = useTranslation('chat');

  const [page, setPage] = useState(1);
  useEffect(() => {
    if (props.open === false) {
      (async () => {
        await sleep(100);
        setPage(1);
      })();
    }
  }, [props.open]);

  const activePageColor = '#E60012';
  const noActivePageColor = '#98A6B1';

  const [adjustLayout, setAdjustLayout] = useState(true);
  useEffect(() => {
    if (props.open) {
      const id = setInterval(() => {
        setAdjustLayout((v) => !v);
      }, 100);
      return () => clearInterval(id);
    }
  }, [props.open]);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <Paper
        // バックドロップ全体を白で覆う
        sx={{
          width: 1,
          minWidth: '350px',
          height: 1,
          borderRadius: 0,
          position: 'relative',
        }}
      >
        <Box
          // OnboardingBackground にコンテンツを重ねる
          sx={{
            width: 1,
            height: 1,
            display: 'grid',
            gridTemplateRows: '1fr minmax(0px, 760px) 1fr auto auto',
            overflow: 'hidden',
            position: 'absolute',
            // safariでなぜかレイアウトが崩れるため、定期的にレイアウトを少し調整する。
            ...(adjustLayout
              ? {
                  left: '0.01px',
                  width: 'calc(100% + 0.02px)',
                }
              : {}),
          }}
        >
          <Box
            // スライドアニメーションのコンテナ
            position="relative"
            overflow="hidden"
            gridRow={2}
          >
            {(props.isSimulteneous
              ? [
                  {
                    c1: t('onboarding.sP1.c1'),
                    c2: t('onboarding.sP1.c2'),
                    t1: t('onboarding.sP1.t1'),
                    t2: t('onboarding.sP1.t2'),
                    t3: t('onboarding.sP1.t3'),
                  },
                  {
                    c1: t('onboarding.sP2.c1'),
                    c2: t('onboarding.sP2.c2'),
                    t1: t('onboarding.sP2.t1'),
                    t2: t('onboarding.sP2.t2'),
                    t3: t('onboarding.sP2.t3'),
                  },
                  {
                    c1: t('onboarding.sP3.c1'),
                    c2: t('onboarding.sP3.c2'),
                    t1: t('onboarding.sP3.t1'),
                    t2: t('onboarding.sP3.t2'),
                    t3: t('onboarding.sP3.t3'),
                  },
                  {
                    c1: t('onboarding.sP4.c1'),
                    c2: t('onboarding.sP4.c2'),
                    t1: t('onboarding.sP4.t1'),
                    t2: t('onboarding.sP4.t2'),
                    t3: t('onboarding.sP4.t3'),
                  },
                ]
              : [
                  {
                    c1: t('onboarding.cP1.c1'),
                    c2: t('onboarding.cP1.c2'),
                    t1: t('onboarding.cP1.t1'),
                    t2: t('onboarding.cP1.t2'),
                    t3: t('onboarding.cP1.t3'),
                  },
                  {
                    c1: t('onboarding.cP2.c1'),
                    c2: t('onboarding.cP2.c2'),
                    t1: t('onboarding.cP2.t1'),
                    t2: t('onboarding.cP2.t2'),
                    t3: t('onboarding.cP2.t3'),
                  },
                  {
                    c1: t('onboarding.cP3.c1'),
                    c2: t('onboarding.cP3.c2'),
                    t1: t('onboarding.cP3.t1'),
                    t2: t('onboarding.cP3.t2'),
                    t3: t('onboarding.cP3.t3'),
                  },
                  {
                    c1: t('onboarding.cP4.c1'),
                    c2: t('onboarding.cP4.c2'),
                    t1: t('onboarding.cP4.t1'),
                    t2: t('onboarding.cP4.t2'),
                    t3: t('onboarding.cP4.t3'),
                  },
                ]
            ).map((x, i) => (
              <Box
                // スライドアニメーションのブロック
                key={i}
                position="absolute"
                sx={{
                  width: '100%',
                  left: '50%',
                  transitionProperty: 'transform',
                  transitionDuration: '0.4s',
                  transform:
                    page === i + 1
                      ? 'translateX(-50%)'
                      : page < i + 1
                      ? 'translateX(50%)'
                      : 'translateX(-150%)',
                }}
                height="100%"
                width="100%"
                display="grid"
                gridTemplateRows="1fr auto"
              >
                <Box sx={{ mx: 'auto' }}>
                  <OnboardingVideo
                    sx={{
                      maxWidth: '500px',
                      width: 'calc(100% + 140px)',
                      height: 1,
                      mt: '-10px',
                      mx: '-70px',
                    }}
                    step={i + 1}
                    isSimulteneous={props.isSimulteneous}
                  />
                </Box>
                <Box mt="-40px" mb="20px">
                  <Stack alignItems="center">
                    <Typography variant="h4">{x.c1}</Typography>
                    <Typography variant="h4">{x.c2}</Typography>
                  </Stack>
                  <Stack alignItems="center" mt={2}>
                    <Typography variant="body1">{x.t1}</Typography>
                    <Typography variant="body1">{x.t2}</Typography>
                    <Typography variant="body1">{x.t3}</Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Stack direction="row" spacing="6px" mx="auto" gridRow={4}>
            {
              // ページの「 ● ● ● ● 」表示
              [1, 2, 3, 4].map((x) => (
                <Box
                  key={x}
                  color={page === x ? activePageColor : noActivePageColor}
                >
                  ●
                </Box>
              ))
            }
          </Stack>
          <Box
            // フッター「次へ」ボタンなど
            mb={3}
            mt={1}
            display="grid"
            gridTemplateColumns="40px 1fr 40px"
            gridRow={5}
            maxWidth="300px"
            width={1}
            gap={1}
            mx="auto"
            px={2}
          >
            {page > 1 && (
              <Button
                variant="outlined"
                sx={{ minWidth: 0, gridColumn: 1 }}
                onClick={() => {
                  setPage((v) => v - 1);
                }}
              >
                <KeyboardArrowLeftIcon />
              </Button>
            )}
            <Button
              endIcon={
                page < 4 ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardDoubleArrowRightIcon />
                )
              }
              sx={{ gridColumn: page < 4 ? 2 : '2/4' }}
              onClick={() => {
                if (page === 4) {
                  props.onClose();
                } else {
                  setPage((v) => v + 1);
                }
              }}
            >
              {page < 4
                ? t('onboarding.toNextPage')
                : t('onboarding.toSpeakerTest')}
            </Button>
            {page < 4 && (
              <Button
                variant="outlined"
                sx={{ minWidth: 0, gridColumn: 3, textTransform: 'none' }}
                onClick={() => {
                  props.onClose();
                }}
              >
                skip
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Backdrop>
  );
};
export default OnboardingDialog;
