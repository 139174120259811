import Cookies from 'js-cookie';

import useAudioInputTestDialog from '@/components/organisms/audioTest/useAudioInputTestDialog';
import useAudioOutputTestDialog from '@/components/organisms/audioTest/useAudioOutputTestDialog';
import useAudioTestResultDialog from '@/components/organisms/audioTest/useAudioTestResultDialog';

const useAudioTest = () => {
  const audioInputTestDialog = useAudioInputTestDialog();
  const audioOutputTestDialog = useAudioOutputTestDialog();
  const audioTestResultDialog = useAudioTestResultDialog();

  return async () => {
    // デバイスチェック
    let isTestOk = false;
    while (isTestOk === false) {
      const isOutputOk = await audioOutputTestDialog.open();
      const isInputOk = await audioInputTestDialog.open();
      if (
        await audioTestResultDialog.open({
          isOutputOk,
          isInputOk,
        })
      ) {
        isTestOk = true;
        // デバイス利用可能状況をクッキーに保存
        Cookies.set('RV_CAN_USE_MIC', `${isInputOk}`, {
          expires: 400,
        });
        Cookies.set('RV_CAN_USE_SPEAKER', `${isOutputOk}`, {
          expires: 400,
        });
      }
    }
  };
};
export default useAudioTest;
