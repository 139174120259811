import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';
import CategoryDialog, {
  CategoryDialogProps,
} from '@/components/organisms/categoryDialog/CategoryDialog';

const CategoryDialogEx = (
  props: Omit<CategoryDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <CategoryDialog open={isOpen} {...props} />;
};

export const useCategoryDialog = () => {
  const openAwaiter = useOpenAwaiter<string | undefined>();
  return {
    open: (
      props: Omit<CategoryDialogProps, 'open' | 'onCancel' | 'onRegist'>
    ) =>
      openAwaiter.open(
        <CategoryDialogEx
          isOpenKey={openAwaiter.key}
          onCancel={() => openAwaiter.close(undefined)}
          onRegist={(v) => openAwaiter.close(v)}
          {...props}
        />
      ),
  };
};
