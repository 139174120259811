import { Divider, Paper, Stack, Typography } from '@mui/material';
import { ChartsAxisContentProps } from '@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent';

import { Interval } from '@/types/graphql';
import { formatDate } from '@/utils/chart';

export const AnalyticsUserCountMuiltiTooltip = (
  props: ChartsAxisContentProps & {
    interval: Interval;
  }
) => {
  const total = props.series.reduce(
    (a, b) => a + b.data[props.dataIndex ?? 0],
    0
  );

  return (
    <Paper>
      <Stack spacing={1} m={2} minWidth={200}>
        <Typography variant="h6">
          {formatDate(props.axisValue, props.interval, {
            hourly: 'MM/DD HH:mm',
            dayly: 'YYYY/MM/DD',
            weekly: 'YYYY/MM/DD',
            monthly: 'YYYY/MM',
            yearly: 'YYYY',
          })}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">最大同時総利用者数</Typography>
          <Typography variant="subtitle1">{total}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
