import { assertNotNull } from '@remote-voice/utilities';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import {
  TenantCompanyData,
  TenantCompanyError,
  TenantForm,
} from '@/components/molecules/admin/TenantForm';
import {
  useAddTenantCompanyMutation,
  useOwnerCompaniesQuery,
} from '@/types/graphql';

const TenantAdd = () => {
  const { t } = useTranslation('common');
  useSetHeaderTitle(t('breadcrumb.tenantAdmin'));

  const permission = useCheckPermission({
    ownerCompanyAdmin: true,
  });
  const ownerCompanyResult = useOwnerCompaniesQuery({
    variables: { input: { id: permission.ownerCompanyId } },
    skip: permission.ownerCompanyId == null,
  });

  const [addTenantCompany, addTenantCompanyResult] =
    useAddTenantCompanyMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  // onSubmit
  return (
    <TenantForm
      ownerCompanyName={ownerCompanyResult.data?.ownerCompanies[0]?.name ?? ''}
      requirePassword
      onSubmit={async (input: TenantCompanyData) => {
        const ownerComapanyId = permission.ownerCompanyId;
        assertNotNull(ownerComapanyId);

        const result = (
          await addTenantCompany({
            variables: {
              input: {
                ...input,
                ownerCompanyId: ownerComapanyId,
              },
            },
          })
        ).data?.addTenantCompany;

        const serverValidationErrors: TenantCompanyError[] = [];
        if (result?.__typename === 'UserNameAlreadyExists') {
          serverValidationErrors.push({
            type: 'UserNameAlreadyExists',
            message: result.message,
          });
        } else if (
          result?.__typename === 'ExcessTenantCompanyRoomNumberLimit'
        ) {
          serverValidationErrors.push({
            type: 'ExcessTenantCompanyRoomNumberLimit',
            message: result.message,
            limit: result.limit,
          });
        } else if (result?.__typename === 'ExcessTenantCompanyNumberLimit') {
          snackbar('error', 'ユーザー数の上限に達しているため追加できません。');
        } else {
          navigate('/admin/tenant');
          snackbar('success', '登録しました');
        }

        return serverValidationErrors;
      }}
      loading={addTenantCompanyResult.loading}
    />
  );
};
export default TenantAdd;
