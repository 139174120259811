import { useOpenableElementIsOpen } from '../../hooks/useOpenableElement';
import useOpenAwaiter from '../../hooks/useOpenAwaiter';

import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

const ConfirmDialogEx = (
  props: Omit<ConfirmDialogProps, 'open'> & {
    isOpenKey: string;
    container?: (children: React.ReactElement) => React.ReactElement;
  }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  const { container, ...otherProps } = props;
  return container ? (
    container(<ConfirmDialog open={isOpen} {...otherProps} />)
  ) : (
    <ConfirmDialog open={isOpen} {...otherProps} />
  );
};

const useConfirmDialog = <ResultType = boolean,>(cancelValue: ResultType) => {
  const openAwaiter = useOpenAwaiter<ResultType>();
  return {
    open: (
      props: Omit<ConfirmDialogProps, 'open' | 'onClose'>,
      container?: (children: React.ReactElement) => React.ReactElement //テーマを渡せるようにする
    ) =>
      openAwaiter.open(
        <ConfirmDialogEx
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(cancelValue)}
          container={container}
          {...props}
        />
      ),
    close: (result: ResultType) => openAwaiter.close(result),
  };
};
export default useConfirmDialog;
