import { TableCell, Typography } from '@mui/material';
import { ReactNode } from 'react';
interface TableHeaderProps {
  children: ReactNode | string;
  title: string;
}
export const AdminTableRow = (props: TableHeaderProps) => (
  <>
    <TableCell
      sx={{
        borderRight: 'solid #ddd 1px',
        width: '300px',
        bgcolor: '#D0DFE6',
        borderBottom: 'solid #e8f1f7 1px',
      }}
    >
      <Typography variant="subtitle1">{props.title}</Typography>
    </TableCell>
    <TableCell sx={{ '&>div': { width: '100%' } }}>{props.children}</TableCell>
  </>
);
