import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CancelButton from '@/components/atoms/CancelButton';
import AudioLinearProgress from '@/components/organisms/audioTest/AudioLinearProgress';
import useAudioInputTester from '@/components/organisms/audioTest/useAudioInputTester';

export type AudioInputTestDialogProps = {
  open: boolean;
  onClose: (isOk: boolean) => void;
  deviceId?: string;
};

const AudioInputTestDialog = (props: AudioInputTestDialogProps) => {
  const { t } = useTranslation('chat');
  const audioTester = useAudioInputTester();
  const audioStart = audioTester.start;
  const audioStop = audioTester.stop;
  const open = props.open;

  useEffect(() => {
    if (open) {
      audioStart();
    } else {
      audioStop();
    }
  }, [audioStart, audioStop, open]);

  return (
    <Dialog open={props.open} PaperProps={{ sx: { minHeight: 280 } }}>
      <DialogTitle>
        {
          // マイクをテストします
          t('deviceTest.label009')
        }
      </DialogTitle>
      <DialogContent>
        <Typography mt={1}>{t('deviceTest.label023')}</Typography>

        <Box mt={6} mb={1}>
          <Typography>
            {
              // 入力レベル
              t('deviceTest.label012')
            }
          </Typography>
        </Box>
        <AudioLinearProgress valueRef={audioTester.volume} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => props.onClose(false)}>
          {t('deviceTest.label020')}
        </CancelButton>
        <Button onClick={() => props.onClose(true)}>
          {
            // はい
            t('deviceTest.label007')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AudioInputTestDialog;

// デバイス選択ができた頃のダイアログを念のためコメントで保持

// export type AudioInputSelectDialogProps = {
//   open: boolean;
//   onClose: (deviceId: string | undefined) => void;
//   deviceId?: string;
// };

// const AudioInputSelectDialog = (props: AudioInputSelectDialogProps) => {
//   const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
//   const audioTester = useAudioInputTester();
//   const [inited, setInited] = useState(false);
//   const [retryCounter, setRetryCounter] = useState(0);
//   const [selectedDeviceId, setSelectedDeviceId] = useState<string>();

//   useEffect(() => {
//     if (props.open === false) {
//       setInited(false);
//       return;
//     }
//     if (inited) return;
//     setInited(true);
//     (async () => {
//       // デバイスをリスト
//       const devices = await navigator.mediaDevices.enumerateDevices();
//       const inputDevices = devices.filter(
//         (x) => x.kind === 'audioinput' && x.deviceId !== ''
//       );
//       setDevices(inputDevices);
//       const deviceId = props.deviceId ?? inputDevices[0]?.deviceId;
//       setSelectedDeviceId(deviceId);

//       // マイクのテストも実行（許可されていない場合は空欄IDでデバイスの作成を試みることで、deniedを検出できる）
//       await audioTester.start(deviceId ?? '');

//       // デバイス一覧が取得できていなければ、許可を求めている最中のため、繰り返し一覧を取得する。
//       if (inputDevices.length === 0) {
//         await sleep(1000); // 1秒ごとに繰り返す
//         setInited(false);
//         setRetryCounter((v) => v + 1); // リトライ
//       }
//     })();
//   }, [props.open, props.deviceId, audioTester, inited, retryCounter]);

//   const onClose = (deviceId: string | undefined) => {
//     audioTester.stop();
//     props.onClose(deviceId);
//   };

//   return (
//     <Dialog open={props.open} onClose={() => onClose(undefined)}>
//       <DialogTitle>マイク入力に使用するデバイスを選択</DialogTitle>
//       <DialogContent>
//         {audioTester.denied ? (
//           <>
//             マイクへのアクセスが拒否されています。マイクへのアクセスを許可するよう設定を変更してください。
//           </>
//         ) : devices.length === 0 ? (
//           <>
//             マイクへのアクセスを取得しています。マイクへのアクセスを許可してください。
//           </>
//         ) : (
//           <>
//             <Select
//               fullWidth
//               value={selectedDeviceId ?? ''}
//               onChange={async (v) => {
//                 setSelectedDeviceId(v.target.value);
//                 await audioTester.start(v.target.value);
//               }}
//             >
//               {devices.map((x) => (
//                 <MenuItem key={x.deviceId} value={x.deviceId}>
//                   {x.label}
//                 </MenuItem>
//               ))}
//             </Select>
//             <Box mt={2} mb={1}>
//               入力レベル
//             </Box>
//             <LinearProgress variant="determinate" value={audioTester.volume} />
//           </>
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button
//           disabled={selectedDeviceId == null}
//           onClick={() => {
//             onClose(selectedDeviceId);
//           }}
//         >
//           選択
//         </Button>
//         <CancelButton
//           onClick={() => {
//             onClose(undefined);
//           }}
//         />
//       </DialogActions>
//     </Dialog>
//   );
// };
// export default AudioInputSelectDialog;
