import LoadingBackdrop, {
  LoadingBackdropProps,
} from '@/components/atoms/LoadingBackdrop';
import { useOpenableElementIsOpen } from '@/components/hooks/useOpenableElement';
import useOpenAwaiter from '@/components/hooks/useOpenAwaiter';

const LoadingBackdropEx = (
  props: Omit<LoadingBackdropProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return <LoadingBackdrop open={isOpen} {...props} />;
};

const useLoadingBackdrop = () => {
  const openAwaiter = useOpenAwaiter();
  return {
    open: async <T,>(func: () => Promise<T>) => {
      openAwaiter.open(<LoadingBackdropEx isOpenKey={openAwaiter.key} />);
      try {
        return await func();
      } finally {
        openAwaiter.close(0);
      }
    },
  };
};
export default useLoadingBackdrop;
