import { LinearProgress } from '@mui/material';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

const AudioLinearProgress = (props: { valueRef: MutableRefObject<number> }) => {
  const timerRef = useRef(0);
  const [value, setValue] = useState(0);

  useEffect(() => {
    timerRef.current = window.setInterval(
      () => setValue(props.valueRef.current),
      10
    );
    setValue(props.valueRef.current);
    return () => {
      window.clearInterval(timerRef.current);
    };
  }, [props]);

  return <LinearProgress variant="determinate" value={value} sx={{ mb: 2 }} />;
};

export default AudioLinearProgress;
