import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReplyIcon from '@mui/icons-material/Reply';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  BottomNavigationAction,
  Box,
  Divider,
  Menu,
  Stack,
} from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ReactionSelector from '@/components/molecules/ReactionSelector';
import useConfirmDeleteDialog from '@/components/organisms/confirmDialog/useConfirmDeleteDialog';
import { ChatReactionType } from '@/types/graphql';

const CommentMenu = (props: {
  onReaction?: (type: ChatReactionType) => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onReply?: () => void;
  onPlay?: () => void;
  onChangeFlag?: (value: boolean) => void;
  flag: boolean;
  isOwnMenu: boolean;
  reactionIsOnlyQuestion: boolean;
  onClose: () => void;
  open: boolean;
  anchorEl: null | HTMLElement;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation('chat');
  const { t: tCommon } = useTranslation('common');

  const confirmDialog = useConfirmDeleteDialog();

  const { onClose, onDelete, onEdit, onPlay, onReply, onChangeFlag, flag } =
    props;
  const elements = useMemo(() => {
    const elements: { label: string; icon: ReactNode; onClick: () => void }[] =
      [];
    if (onReply) {
      elements.push({
        label: t('menu.reply'),
        icon: <ReplyIcon />,
        onClick: () => {
          onClose();
          onReply?.();
        },
      });
    }
    if (onPlay) {
      elements.push({
        label: t('menu.play'),
        icon: <VolumeUpIcon />,
        onClick: () => {
          onClose();
          onPlay?.();
        },
      });
    }
    if (onChangeFlag) {
      elements.push({
        label: flag ? t('menu.removeLabel') : t('menu.addLabel'),
        icon: flag ? <BookmarkRemoveIcon /> : <BookmarkAddIcon />,
        onClick: () => {
          onClose();
          onChangeFlag?.(flag === false);
        },
      });
    }
    if (onEdit) {
      elements.push({
        label: tCommon('command.edit'),
        icon: <EditIcon />,
        onClick: () => {
          onClose();
          onEdit?.();
        },
      });
    }
    if (onDelete) {
      elements.push({
        label: tCommon('command.delete'),
        icon: <DeleteIcon />,
        onClick: async () => {
          onClose();
          if (
            await confirmDialog.open({
              title: t('menu.confirmDelete'),
            })
          ) {
            onDelete?.();
          }
        },
      });
    }
    return elements;
  }, [
    onReply,
    onPlay,
    onEdit,
    onChangeFlag,
    onDelete,
    onClose,
    flag,
    t,
    tCommon,
    confirmDialog,
  ]);

  return (
    <Menu
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={
        props.isOwnMenu
          ? { horizontal: 'right', vertical: 'top' }
          : { horizontal: 'left', vertical: 'top' }
      }
      transformOrigin={
        props.isOwnMenu
          ? { horizontal: 'right', vertical: 'bottom' }
          : { horizontal: 'left', vertical: 'bottom' }
      }
      sx={{
        transform: 'translate(0px, -10px)', //ちょっと上に配置
      }}
    >
      <Stack
        alignItems={props.isOwnMenu ? 'flex-end' : 'flex-start'}
        sx={{ my: -1 }}
      >
        {props.onReaction != null && (
          <>
            <Box width={1} display="flex" justifyContent="center">
              <ReactionSelector
                onReaction={(type) => {
                  props.onReaction?.(type);
                  props.onClose();
                }}
                enabledReactions={
                  props.reactionIsOnlyQuestion
                    ? [ChatReactionType.Question]
                    : undefined
                }
                hideText={!props.reactionIsOnlyQuestion}
              />
            </Box>
            <Divider sx={{ width: 1 }} />
          </>
        )}
        <Box
          display="grid"
          width={1}
          gridTemplateColumns={`repeat(${Math.min(elements.length, 3)},1fr)`}
          gridAutoRows="60px"
        >
          {elements.map((element, i) => (
            <Box
              key={i}
              height={1}
              borderRight={
                (elements.length <= 3 && i + 1 === elements.length) ||
                i % 3 === 2
                  ? 0
                  : 1
              }
              borderBottom={
                Math.floor(i / 3) === Math.floor((elements.length - 1) / 3)
                  ? 0
                  : 1
              }
              borderColor={(t) => t.palette.grey[300]}
            >
              <BottomNavigationAction
                showLabel
                label={element.label}
                icon={element.icon}
                sx={{ width: 1, height: 1, maxWidth: '1000px' }}
                onClick={element.onClick}
              />
            </Box>
          ))}
        </Box>
      </Stack>
    </Menu>
  );
};
export default CommentMenu;
