import { Button, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Footer from '@/components/atoms/Footer';

const NotFound = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <Stack flex={1} justifyContent="center">
        <Typography variant="h5">{t('message.notfound')}</Typography>
        <Button variant="text" onClick={() => navigate(-1)}>
          {t('command.browserBack')}
        </Button>
      </Stack>
      <Footer />
    </Stack>
  );
};
export default NotFound;
