import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useHomophoneCategoriesQuery } from '@/types/graphql';

const DynamicHomophoneCategoryBreadcrumbs = (
  props: BreadcrumbComponentProps
) => {
  const homophoneCategory = useHomophoneCategoriesQuery({
    variables: { input: { id: props.match.params.categoryId } },
    skip: props.match.params.categoryId == null,
  });

  return <>{homophoneCategory.data?.homophoneCategories[0].name}</>;
};

export default DynamicHomophoneCategoryBreadcrumbs;
