import { joiResolver } from '@hookform/resolvers/joi';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { assertNotNull, validLanguages } from '@remote-voice/utilities';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdminContent } from '@/components/atoms/AdminPageParts';
import CancelButton from '@/components/atoms/CancelButton';
import LoadingBackdrop from '@/components/atoms/LoadingBackdrop';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import { FooterMenu } from '@/components/molecules/admin/FooterMenu';
import { TemplateCategorySelector } from '@/components/molecules/admin/TemplateCategorySelector';
import { TemplateTranslate } from '@/components/molecules/admin/TemplateTranslate';
import BreadcrumbBar from '@/components/organisms/BreadcrumbBar';
import usePreviewImageBackdrop from '@/components/organisms/previewImageBackdrop/usePreviewImageBackdrop';
import { useFilesQuery, useTranslateMessageLazyQuery } from '@/types/graphql';
import { convertHeicToJpeg } from '@/utils/convertHeicToJpeg';

export type TemplateMessageData = {
  languages: Array<{
    language: string;
    message: string;
    reverseMessage?: string | null;
    readonly?: boolean | null;
    sourceMsg?: string;
  }>;
  templateCategoryId: string;
  message: string;
  attachedFiles: Array<{
    id?: string;
    fileName: string;
    fileType: string;
    fileData?: File;
    signedURL?: string;
  }>;
};

export const TemplateForm = (props: {
  loading: boolean;
  onSubmit: (
    input: TemplateMessageData,
    options: {
      isReplacedBreak: boolean;
    }
  ) => Promise<void>;
  defaultData?: TemplateMessageData; // 新規登録は defaultDataなし
}) => {
  const { t, i18n } = useTranslation('admin');
  const { t: tCommon } = useTranslation('common');
  const joi = useLocalizedJoi();
  const showSnackbar = useSnackbar();

  const userLang = i18n.language;
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const previewImageBackdrop = usePreviewImageBackdrop();
  const getFiles = useFilesQuery({
    variables: {
      input: {
        ids:
          (props.defaultData?.attachedFiles
            .map((x) => x.id)
            .filter((x) => x != null) as string[]) ?? [],
      },
    },
  });

  // 原文のバリデーションスキーマ（翻訳を掛けるときにチェックするので切り出している）
  const messageValidationSchema = joi
    .string()
    .trim()
    .min(1)
    .max(200)
    .required()
    .replace(/\n/g, ' ');
  const form = useForm<TemplateMessageData>({
    resolver: joiResolver(
      joi.object<TemplateMessageData>({
        templateCategoryId: joi.string().required(),
        languages: joi.array().items(
          joi
            .object({
              message: joi.string().trim().required().replace(/\n/g, ' '),
              readonly: joi.boolean(),
            })
            .unknown(true)
        ),
        message: messageValidationSchema,
        attachedFiles: joi.array().items(
          joi
            .object({
              fileName: joi.string(),
              fileType: joi.valid('jpg', 'jpeg', 'png'),
              signedURL: joi.string(),
            })
            .unknown(true)
        ),
      })
    ),
  });
  const [translateMessage, translateMessageResult] =
    useTranslateMessageLazyQuery();
  // 翻訳対象の言語
  const [targetLanguages, setTargetLanguages] = useState<string[]>([]);

  useEffect(() => {
    if (props.defaultData != null) form.reset(props.defaultData);
  }, [form, props.defaultData]);

  return (
    <Box
      component="form"
      onSubmit={form.handleSubmit((input, ev) => {
        ev?.preventDefault();
        props.onSubmit(input, {
          isReplacedBreak: form.getValues().message !== input.message,
        });
      })}
    >
      <LoadingBackdrop open={translateMessageResult.loading} />
      <BreadcrumbBar keyFrom="/admin/template" />
      <AdminContent>
        <Typography variant="h4">
          {props.defaultData ? tCommon('command.edit') : tCommon('command.new')}
        </Typography>
        <Stack mt={3} spacing={2}>
          <Controller
            control={form.control}
            name={'templateCategoryId'}
            render={({ field }) => (
              <TemplateCategorySelector
                belongedCategoryId={props.defaultData?.templateCategoryId ?? ''}
                field={field}
                register={form.register}
                errors={form.formState.errors}
              />
            )}
          />
          <Stack spacing={1} width="100%" alignItems="flex-start">
            <Typography variant="h4">{t('template.text')}</Typography>
            <TextField
              multiline
              rows={4}
              {...form.register('message')}
              onChange={(ev) => {
                form.register('message').onChange(ev);
                // この言語の翻訳結果も更新する
                form.setValue(
                  `languages.${validLanguages.findIndex(
                    (lang) => lang === userLang
                  )}.message`,
                  ev.target.value
                );
              }}
              sx={{ width: 1 }}
              placeholder={t('template.enterTextMsg')}
              error={'message' in form.formState.errors}
              helperText={form.formState.errors.message?.message}
            />
            <label htmlFor="template-attached-file">
              <input
                id="template-attached-file"
                type="file"
                multiple
                accept="image/jpg,image/jpeg,image/png,image/heic,.jpg,.jpeg,.png,.heic"
                style={{
                  display: 'none',
                }}
                onChange={async (e) => {
                  let file = e.currentTarget.files?.[0];
                  if (file) {
                    if (file.type === 'image/heic') {
                      file = await convertHeicToJpeg(file);
                    }
                    form.setValue(
                      'attachedFiles',
                      [
                        ...(form.getValues().attachedFiles ?? []),
                        {
                          fileData: file,
                          fileType: file.name.split('.')[1].toLowerCase(),
                          fileName: file.name,
                        },
                      ],
                      { shouldDirty: true }
                    );
                  }
                }}
              />
              <Button
                variant="text"
                startIcon={<AddPhotoAlternateOutlinedIcon />}
                component="span"
              >
                {t('template.attachImage')}
              </Button>
            </label>

            <ImageList
              cols={matchDownSm ? 3 : 6}
              rowHeight={matchDownSm ? 100 : 164}
            >
              {form.watch('attachedFiles')?.map((file, i) => {
                const src =
                  file.fileData != null
                    ? URL.createObjectURL(file.fileData)
                    : getFiles.data?.files.find((x) => x.id === file.id)
                        ?.signedURL ?? '';
                return (
                  <ImageListItem
                    key={i}
                    sx={{
                      border: (t) => `1px solid ${t.palette.secondary.main}`,
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={src}
                      alt={file.fileName}
                      loading="lazy"
                      onClick={() => {
                        previewImageBackdrop.open({
                          imgProps: {
                            src: src,
                            alt: file.fileName,
                            style: {
                              maxWidth: '100%',
                              maxHeight: '100%',
                            },
                          },
                        });
                      }}
                    />
                    <ImageListItemBar
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiButtonBase-root': {
                          backgroundColor: (t) => t.palette.grey[700],
                        },
                      }}
                      actionIcon={
                        <IconButton
                          size="small"
                          onClick={() => {
                            form.setValue(
                              `attachedFiles`,
                              form
                                .getValues()
                                .attachedFiles.filter((_, j) => i !== j),
                              { shouldDirty: true }
                            );
                          }}
                        >
                          <DeleteIcon htmlColor="#fff" />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                );
              }) ?? false}
            </ImageList>
            {form.formState.errors?.attachedFiles?.map?.((err, i) => (
              <Typography color="error" key={i.toString()} variant="caption">
                {err?.fileType?.message ?? ''}
              </Typography>
            )) ?? false}
          </Stack>
        </Stack>
      </AdminContent>
      <Box
        borderTop={(theme) => `1px solid ${theme.palette.divider}`}
        sx={{ backgroundColor: '#e7eff4' }}
      >
        <AdminContent>
          <Stack spacing={1} py={2} alignItems="flex-start">
            <Typography variant="h6" pb={1}>
              {t('template.translation')}
            </Typography>
            <Typography variant="body1">
              {props.defaultData || translateMessageResult.called
                ? t('template.updateTranslationMsg')
                : t('template.submitTranslationMsg')}
            </Typography>
            <Button
              variant="text"
              startIcon={<LibraryAddCheckIcon />}
              onClick={() =>
                setTargetLanguages(
                  validLanguages.filter((lng) => lng !== userLang)
                )
              }
            >
              {t('template.checkAll')}
            </Button>
            <Stack width={1}>
              {validLanguages.map((lang, i) => (
                <TemplateTranslate
                  key={lang}
                  form={form}
                  language={lang}
                  templateIndex={i}
                  checked={targetLanguages.some((tl) => tl === lang)}
                  onCheck={(checked) => {
                    if (checked) {
                      setTargetLanguages([...targetLanguages, lang]);
                    } else {
                      setTargetLanguages(
                        targetLanguages.filter((l) => l !== lang)
                      );
                    }
                  }}
                  onChangeSorceMessage={async (value) => {
                    // 訳文ごとの原文が変更されたとき
                    const validation = messageValidationSchema.validate(value);
                    if (validation.error != null) {
                      form.setError(`languages.${i}.sourceMsg`, {
                        message: validation.error.message,
                      });
                      return;
                    } else {
                      form.clearErrors(`languages.${i}.sourceMsg`);
                    }

                    // 翻訳実行
                    const result = await translateMessage({
                      variables: {
                        input: {
                          language: userLang,
                          message: value,
                          targetLanguages: [lang],
                          enableReverseTraslate: true,
                        },
                      },
                    });
                    if (result.error != null) {
                      console.error('Translate message', result.error);
                      showSnackbar('error', tCommon('message.unexpectedError'));
                      return;
                    }

                    // 訳文表示
                    const translated = result.data?.translateMessage.find(
                      (x) => x.language === lang
                    );
                    assertNotNull(translated);
                    form.setValue(
                      `languages.${i}.message`,
                      translated.message,
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      }
                    );
                    form.setValue(
                      `languages.${i}.reverseMessage`,
                      translated.reverseMessage
                    );
                  }}
                  onChangeMessage={async (value) => {
                    // 翻訳実行
                    const result = await translateMessage({
                      variables: {
                        input: {
                          language: lang,
                          message: value,
                          targetLanguages: [userLang],
                        },
                      },
                    });
                    // 逆翻訳結果表示
                    const reverseMsg = result.data?.translateMessage.find(
                      (x) => x.language === userLang
                    );
                    if (reverseMsg != null) {
                      form.setValue(
                        `languages.${i}.reverseMessage`,
                        reverseMsg.message
                      );
                    }
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </AdminContent>
      </Box>
      <FooterMenu>
        <Stack
          spacing={2}
          sx={{ width: 1 }}
          px={2}
          justifyContent="center"
          alignItems="center"
        >
          {form.formState.isDirty && (
            <Button type="submit" sx={{ width: '200px' }}>
              {props.defaultData
                ? tCommon('command.saveChanges')
                : t('template.saveSelected')}
            </Button>
          )}
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: 1 }}
            justifyContent="center"
          >
            <CancelButton onClick={() => navigate('/admin/template')} />
            <Button
              variant={
                props.defaultData || translateMessageResult.called
                  ? 'outlined'
                  : 'contained'
              }
              sx={{ flex: 1, maxWidth: 300 }}
              disabled={
                form.watch('message')?.length < 1 ||
                targetLanguages.length === 0
              }
              onClick={async () => {
                const validation = messageValidationSchema.validate(
                  form.getValues('message')
                );
                if (validation.error != null) {
                  form.setError('message', {
                    message: validation.error.message,
                  });
                } else {
                  form.clearErrors('message');

                  // 翻訳実行
                  const result = await translateMessage({
                    variables: {
                      input: {
                        language: userLang,
                        message: form.getValues().message,
                        targetLanguages: targetLanguages,
                        enableReverseTraslate: true,
                      },
                    },
                  });
                  // 定義した言語順になるように並び替え
                  validLanguages.forEach((tl, i) => {
                    const lang = result.data?.translateMessage.find(
                      (r) => r.language === tl
                    );

                    if (lang != null) {
                      // フォームに結果をセット
                      form.setValue(`languages.${i}`, lang, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  });
                }
              }}
            >
              {props.defaultData || translateMessageResult.called
                ? t('template.reTranslate')
                : t('template.translate')}
            </Button>
          </Stack>
        </Stack>
      </FooterMenu>
    </Box>
  );
};
