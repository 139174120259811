import { Grow } from '@mui/material';
import { VariantType, useSnackbar as usNotistackSnackbar } from 'notistack';

export const useSnackbar = () => {
  const snackbar = usNotistackSnackbar();
  return (
    variant: VariantType,
    message: string,
    vertical?: 'top' | 'bottom'
  ) => {
    snackbar.enqueueSnackbar(message, {
      variant,
      hideIconVariant: variant === 'success',
      anchorOrigin: {
        vertical: vertical ?? 'bottom',
        horizontal: 'center',
      },
      TransitionComponent: Grow,
    });
  };
};
