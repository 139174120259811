import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ChatMessageItem } from '@/components/organisms/chat/ChatMessageCache';

const useChatPlayer = (props: {
  getNextMessage: (
    key: number | undefined
  ) => [number, ChatMessageItem] | undefined;
  getPreviousMessage: (
    key: number | undefined
  ) => [number, ChatMessageItem] | undefined;
  audioOutputDeviceId?: string;
  language: string;
  userId: string;
  play: (
    text: string,
    language: string,
    messageId: string,
    deviceId?: string
  ) => Promise<void>;
}) => {
  const {
    getNextMessage,
    language,
    userId,
    play,
    audioOutputDeviceId,
    getPreviousMessage,
  } = props;

  // 音声再生関連
  const [playMessageEnabled, setPlayMessageEnabled] = useState(false);
  const [isPlayingMessage, setIsPlayingMessage] = useState(false);
  const [playedKey, setPlayedKey] = useState<number | undefined>();
  const playCounter = useRef(0);

  // 音声の自動再生処理
  useEffect(() => {
    if (playMessageEnabled && isPlayingMessage === false) {
      const timeoutId = setInterval(async () => {
        let retry = false;
        let keepPlayedKey = playedKey;
        do {
          retry = false;
          const nextMsgObject = getNextMessage(keepPlayedKey);
          if (nextMsgObject != null) {
            const [nextKey, nextMsg] = nextMsgObject;
            if (nextMsg.isTyping === false) {
              const msg =
                nextMsg.languages.find((x) => x.language === language)
                  ?.message ?? '';
              keepPlayedKey = nextKey;
              setPlayedKey(nextKey);
              if (
                nextMsg.isRemoved === false && // 削除されていない
                msg !== '' && // 送信中以外
                nextMsg.userId !== userId && // 自分以外
                nextMsg.userId !== '00000000-0000-0000-0000-000000000000' // システムメッセージ
              ) {
                setIsPlayingMessage(true);
                const counter = playCounter.current;
                await play(msg, language, nextMsg.id, audioOutputDeviceId); // 読み上げ
                if (counter === playCounter.current) {
                  // 手動で再生された場合は何もしない
                  setIsPlayingMessage(false);
                }
              } else {
                retry = true;
              }
            }
          }
        } while (retry);
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [
    playMessageEnabled,
    playedKey,
    isPlayingMessage,
    getNextMessage,
    audioOutputDeviceId,
    language,
    play,
    userId,
  ]);

  const setPlayMessageEnabledCallback = useCallback(
    (value: SetStateAction<boolean>) => {
      setPlayMessageEnabled(value);
      setPlayedKey(getPreviousMessage(undefined)?.[0]); // 末尾から再生
    },
    [getPreviousMessage]
  );

  const playChatCallback = useCallback(
    async (text: string, language: string, messageId: string) => {
      playCounter.current++;
      const counter = playCounter.current;
      setIsPlayingMessage(true);
      await play(text, language, messageId, audioOutputDeviceId);
      if (counter === playCounter.current) {
        setIsPlayingMessage(false);
        setPlayedKey(getPreviousMessage(undefined)?.[0]); // 手動再生終了後は末尾から再生
      }
    },
    [play, audioOutputDeviceId, getPreviousMessage]
  );

  const stopChatCallback = useCallback(async () => {
    playCounter.current++;
    setIsPlayingMessage(false);
    setPlayedKey(getPreviousMessage(undefined)?.[0]); // 手動再生終了後は末尾から再生
  }, [getPreviousMessage]);

  return {
    playMessageEnabled,
    setPlayMessageEnabled: setPlayMessageEnabledCallback,
    play: playChatCallback,
    stop: stopChatCallback,
  };
};
export default useChatPlayer;
