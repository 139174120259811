import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckPermission } from '@/components/hooks/useCheckPermission';
import { useSetHeaderTitle } from '@/components/hooks/useHeaderTitle';
import { useSnackbar } from '@/components/hooks/useSnackbar';
import NGWordForm from '@/components/molecules/admin/NGWordForm';
import { useAddNgWordMutation } from '@/types/graphql';

const NGWordAdd = () => {
  const { t } = useTranslation('common');
  const { t: commonT } = useTranslation('common');
  useSetHeaderTitle(commonT('breadcrumb.ngWord'));

  useCheckPermission({ superAdmin: true });
  const navigate = useNavigate();

  const snackbar = useSnackbar();
  const [addNgWord, addNgWordResult] = useAddNgWordMutation();

  return (
    <NGWordForm
      loading={addNgWordResult.loading}
      onSubmit={async (input) => {
        await addNgWord({ variables: { input } });
        navigate('../');
        snackbar('success', t('message.registered'));
      }}
    />
  );
};
export default NGWordAdd;
