import InfoIcon from '@mui/icons-material/Info';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import CancelButton from '@/components/atoms/CancelButton';

export const PasswordPolicyButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <InputAdornment position="end">
        <Tooltip title="パスワードポリシーの表示">
          <IconButton onClick={() => setOpen(true)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </InputAdornment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>パスワードポリシー</DialogTitle>
        <DialogContent>
          <Stack spacing={1} my={2}>
            <Typography>1文字以上の小文字アルファベットを含む</Typography>
            <Typography>1文字以上の大文字アルファベットを含む</Typography>
            <Typography>1文字以上の数字を含む</Typography>
            <Typography>8文字以上</Typography>
            <Typography>1文字以上の記号もしくはスペースを含む</Typography>
            <Typography>先頭もしくは末尾にスペースを含まない</Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <CancelButton onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};
