import { Box, SxProps, Theme } from '@mui/material';

const GuideIcon = (props?: { sx?: SxProps<Theme> }) => (
  <Box width="24px" height="24px" sx={props?.sx} overflow={'hidden'}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25.164"
      height="25.164"
      viewBox="0 0 25.164 25.164"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="パス_53306"
            data-name="パス 53306"
            d="M78,90.521A12.521,12.521,0,1,0,90.521,78,12.52,12.52,0,0,0,78,90.521"
            transform="translate(-78 -78)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <circle
            id="楕円形_2"
            data-name="楕円形 2"
            cx="9.5"
            cy="9.5"
            r="9.5"
            transform="translate(1.688 1.688)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="icon_guest01" transform="translate(-16.14 -326.141)">
        <g
          id="グループ_15185"
          data-name="グループ 15185"
          transform="translate(16.262 326.141)"
        >
          <g
            id="グループ_15178"
            data-name="グループ 15178"
            clipPath="url(#clip-path)"
          >
            <path
              id="パス_53303"
              data-name="パス 53303"
              d="M156.509,187.811H31.3A31.3,31.3,0,0,1,0,156.509V31.3A31.3,31.3,0,0,1,31.3,0H156.509a31.3,31.3,0,0,1,31.3,31.3V156.509a31.3,31.3,0,0,1-31.3,31.3"
              transform="translate(-81.385 -81.385)"
              fill="#e60012"
            />
            <path
              id="パス_53304"
              data-name="パス 53304"
              d="M93.905,187.811H31.3A31.3,31.3,0,0,1,0,156.509V31.3A31.3,31.3,0,0,1,31.3,0h62.6Z"
              transform="translate(-81.385 -81.385)"
              fill="#0068b7"
            />
            <path
              id="パス_53305"
              data-name="パス 53305"
              d="M90,0h62.6a31.3,31.3,0,0,1,31.3,31.3V156.509a31.3,31.3,0,0,1-31.3,31.3H90Z"
              transform="translate(-77.479 -81.385)"
              fill="#e60012"
            />
          </g>
        </g>
        <g id="user" transform="translate(17.453 327.454)">
          <g
            id="マスクグループ_4"
            data-name="マスクグループ 4"
            transform="translate(0 0)"
            clipPath="url(#clip-path-2)"
          >
            <ellipse
              id="楕円形_6"
              data-name="楕円形 6"
              cx="8.724"
              cy="6.498"
              rx="8.724"
              ry="6.498"
              transform="translate(2.564 13.258)"
              fill="#fff"
            />
          </g>
          <circle
            id="楕円形_5"
            data-name="楕円形 5"
            cx="4.402"
            cy="4.402"
            r="4.402"
            transform="translate(6.889 2.688)"
            fill="#fff"
          />
        </g>
        <path
          id="bg"
          d="M12.582,0A12.582,12.582,0,1,1,0,12.582,12.582,12.582,0,0,1,12.582,0Z"
          transform="translate(16.14 326.141)"
          fill="none"
        />
      </g>
    </svg>

    {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="4 0 24 24"
      style={{
        borderRadius: '100%',
        height: '100%',
        width: '100%',
      }}
    >
      <defs>
        <clipPath id="clip-path">
          <rect transform="translate(0 0)" fill="none" />
        </clipPath>
      </defs>
      
      <g transform="translate(-1292.63 -462.317)">
        <g transform="translate(1292.63 462.317)">
          <path
            d="M1307.615,508.716c-1.156-1.048-7.039-3.68-4.333-10.122.9-2.152,3.623-3.938,4.4-6.135a7.239,7.239,0,0,0-1.573-7.763,31.158,31.158,0,0,0-4.211-2.457,23.408,23.408,0,0,0-2.776-.492c-2.935-.1-5.609.64-7.21,3.3a6.569,6.569,0,0,0,.346,7.709,36.275,36.275,0,0,1,2.224,3.3,20.28,20.28,0,0,1,1.9,4.036,3.99,3.99,0,0,1-2.809,4.927,5.578,5.578,0,0,1-4.348-.469c-2.3-1.154-3.348-3.187-3.986-5.547a41.746,41.746,0,0,0-1.509-4.9c-1.5-3.664-4.439-4.932-8.123-3.518a41.653,41.653,0,0,0-4.137,2.042c-2.829,1.464-4.356.893-5.46-2.084a8.8,8.8,0,0,1-.335-1.235c-.6-2.83.185-5.409,1.385-7.937.6-1.269-32.642,8.878-12.515,8.4a6.55,6.55,0,0,1,2.953,7.107c-.188,1.009-.807,4.087-.9,5.1.14,4.906,8.168,4.717,14.222,5.026,5.45.278,10.308,1.941,4.665,3.215-3.2.722-11.341-.207-11.76,2.613-.209,1.413,1.911,3.33,4.454,4.323,1.211.473,2.651.99,4.256,1.5,2.677-1.5,4.01-3.114,6.007-3.114,3.225,0,3.334,2.809,1.156,5a48.361,48.361,0,0,0,13.034,1.083c4.515-.32,17.815-.553,17.415-8.382a6.418,6.418,0,0,0-2.432-4.538m-44.513-21.4c.157-.3.516-.682.8-.694a1.186,1.186,0,0,1,.831.635,13.407,13.407,0,0,1,.6,1.812c.508,1.577,1.019,3.152,1.559,4.822-3.007-.312-5.1-4.033-3.787-6.575"
            transform="translate(-1258.538 -476.813)"
            fill="#d2d7da"
          />
          <path
            d="M1352.407,518.8a10.915,10.915,0,1,1-14.945,3.866,10.916,10.916,0,0,1,14.945-3.866"
            transform="translate(-1325.77 -504.371)"
            fill="#e60012"
          />
          <path
            d="M1344.6,525.371c-.743-.624-3.167,1.176-4.575,3.586-1.491,2.552-1.761,5.232-.942,5.733.843.515,2.773-1.164,4.29-3.721,1.564-2.637,2.048-5.185,1.227-5.6"
            transform="translate(-1327.818 -510.458)"
            fill="#fff"
          />
          <path
            d="M1343.556,533.116c-.439-.258-1.433.618-2.222,1.957s-1.073,2.634-.634,2.892,1.433-.618,2.222-1.956,1.072-2.634.634-2.892"
            transform="translate(-1329.246 -516.444)"
            fill="#0068b7"
          />
          <path
            d="M1380.989,551.756a8.183,8.183,0,0,0-8.32-7.672,8.547,8.547,0,0,0-7.614,4.75c-1.342,2.657-.727,7.807-1.892,10.1a12.343,12.343,0,0,1-5.9,5.607,10.787,10.787,0,0,1-8.87.13,17.015,17.015,0,0,0-20.353,6.142c-3.9,5.98-11.194,3.217-17.9,1.323-.145-.041-.3-.092-.445-.138-1.742,2.013-2.173,4.7,1.307,6.479,3.222,1.649,3.09,3.938,2.513,6.091,4.988-1.916,11.236-.161,14.054,4.67a16.951,16.951,0,0,0,31.41-8.853c-.433-5.838-2.979-10.64,1.709-15.751a10.744,10.744,0,0,1,7.11-3.731c2.962-.374,5.384.873,9.12-.936,3.119-1.511,4.272-5.356,4.067-8.209m-35.521,25.271a5.135,5.135,0,0,1-2.02-10.066,2.525,2.525,0,0,1,.257-.058,2.608,2.608,0,0,1,.261-.032,5.135,5.135,0,0,1,1.5,10.157"
            transform="translate(-1304.68 -524.869)"
            fill="#e60012"
          />
          <path
            d="M1412.5,438.125a15.542,15.542,0,1,1-15.542-15.542,15.543,15.543,0,0,1,15.542,15.542"
            transform="translate(-1360.554 -431.92)"
            fill="#e60012"
          />
          <path
            d="M1319.54,424.753a14.425,14.425,0,0,1-1.445-.328,1.389,1.389,0,0,1-1.022-1.849,7.641,7.641,0,0,1,1-2.023c.339-.476.622-.988.973-1.451,4.413-5.826,12.994-7.132,16.682-6.969,14.732.648,14.246-21.8-6.1-11.386-4.112,2.105-8.336,10.707-8.647,11.26-.427.76-.737,1.595-1.013,2.208-.113.25-.285.5-.413.389s-.058-.488.1-.868a7.774,7.774,0,0,0,.563-2.034,3.044,3.044,0,0,0-.957-3,3.2,3.2,0,0,0-3.37.145,13.948,13.948,0,0,1-1.361.71,6.5,6.5,0,0,1-1.63.554c-5.176.682-7.416-1.557-5.317-2.784,3.342-1.953-1.657-1.961-3.179-2.016-7.694-.278,4.938-6.315-5.283-4.753a22.469,22.469,0,0,0-4.65,1.443c-5.219,1.937-6.847-2.055-9.807-.842-1.587.65-3.174,6.849-1.64,9.63,2.556,4.636,11.2,8.261,13.9,8.261a13.178,13.178,0,0,0,8.5-3.092,2.073,2.073,0,0,0,3.277.109,7.228,7.228,0,0,1,1.538-.981,2.089,2.089,0,0,1,2.4.266,13.228,13.228,0,0,0,2.293,1.446,2.793,2.793,0,0,0,3.045-.267,1.4,1.4,0,0,1,.3-.175c.374-.151.673.067.55.45a4.377,4.377,0,0,1-.573,1.141,12.326,12.326,0,0,1-3.4,3.282c-.455.3-.915.6-1.341.936a8.693,8.693,0,0,0-3.01,3.6,3.772,3.772,0,0,0,1.078,4.566,5.609,5.609,0,0,0,2.567,1.1,8.732,8.732,0,0,0,3.576-.33,13.241,13.241,0,0,1,6.035-.223,9.309,9.309,0,0,0,1.874.145,61.386,61.386,0,0,0,10.739-.437c6.66-1.215,5.506-5.766-1.058-5.358a122.246,122.246,0,0,1-15.767-.471"
            transform="translate(-1284.804 -413.102)"
            fill="#0068b7"
          />
          <path
            d="M1225.6,406.42a13.217,13.217,0,1,1-13.217,13.217A13.232,13.232,0,0,1,1225.6,406.42"
            transform="translate(-1231.243 -419.555)"
            fill="#e60012"
          />
          <path
            d="M1309.131,442.1a7.534,7.534,0,1,1-7.534,7.535,7.543,7.543,0,0,1,7.534-7.535"
            transform="translate(-1299.49 -446.849)"
            fill="#e60012"
          />
          <path
            d="M1338.536,485.227c.384.539,2.292-.235,3.632-1.586,1.42-1.43,2.056-3.153,1.6-3.624s-2.03.3-3.467,1.724c-1.483,1.474-2.237,3.073-1.766,3.486"
            transform="translate(-1327.641 -475.748)"
            fill="#fff"
          />
          <path
            d="M1346.519,490.971c.245.245,1.052-.163,1.8-.912s1.157-1.555.912-1.8-1.052.163-1.8.912-1.158,1.555-.912,1.8"
            transform="translate(-1333.801 -482.108)"
            fill="#0068b7"
          />
          <path
            d="M1210.417,395.507c2.911,1.166,9.474,2.719,4.366,4.119-7.585,2.08-2.472,14.829,7.789,12.374a3.432,3.432,0,0,1,4.288,1.57c.487.791.811,2.364-2.516,2.983-2.282.572-3.356-.829-4.456-1.448a5.3,5.3,0,0,0-3.763.2c-2.312.856-3.172,4.365-2.486,5.5.464.767,1.339,1.139,2.878.387,4.229-2.065,1.208.356,1.316,2.375a2.451,2.451,0,0,0,2.144,2.1,14.163,14.163,0,0,1,1.508.348,7.925,7.925,0,0,1,1.611.644,1.49,1.49,0,0,1,.508,2.146c-.421.571-6.442.395-11.354-.609a5.986,5.986,0,0,0-.923-.11c-.706-.007-2.623.123-1.547,1.844a23.228,23.228,0,0,0,10.915,6.952c5.63-1.747,10.332-4.911,10.333-8.1.015-1.555-.479-2.683-2.45-2.655a7.292,7.292,0,0,1-1.821-.256,2.105,2.105,0,0,1-1.663-1.776,13.326,13.326,0,0,0-.809-2.609,2.815,2.815,0,0,0-2.507-1.791,1.4,1.4,0,0,1-.343-.061c-.386-.129-.471-.492-.126-.7a4.409,4.409,0,0,1,1.186-.5,12.425,12.425,0,0,1,4.758-.283c.545.069,1.092.142,1.64.161a8.759,8.759,0,0,0,4.667-.789,3.8,3.8,0,0,0,2.165-4.2,5.655,5.655,0,0,0-1.245-2.524,8.8,8.8,0,0,0-2.955-2.09c-1.847-.941-8.512-5.1-8.576-13-.035-4.273-6.638-6.211-12.951-6.1-5.262.094-7.523,2.712.422,5.894"
            transform="translate(-1225.768 -406.694)"
            fill="#0068b7"
          />
        </g>
      </g>
    </svg> */}
  </Box>
);

export default GuideIcon;

// import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
// <RecordVoiceOverOutlinedIcon />
