import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

export type LoadingBackdropProps = {
  open: boolean;
};

const LoadingBackdrop = (props: LoadingBackdropProps) => {
  //
  // このバックドロップは見えない所で常時表示されてている。
  // それが影響し、CircularProgressがCPUリソースを少し食っている。
  // それを回避するため、props.openに合わせて表示するようにする。
  // closeのフェード時、先に消えてしまうのを避けるため、effectで少しの間表示を維持している。
  const [opening, setOpening] = useState(false);
  useEffect(() => {
    if (props.open) {
      setOpening(true);
    } else {
      let released = false;
      const id = setTimeout(() => {
        if (released === false) {
          setOpening(false);
        }
      }, 500);
      return () => {
        released = true;
        clearTimeout(id);
      };
    }
  }, [props.open]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      {opening && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};
export default LoadingBackdrop;
