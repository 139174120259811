import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';

import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import ChatFontSettingsDialog, {
  ChatFontSettingsDialogProps,
} from '@/components/organisms/chatFontSettingsDialog/ChatFontSettingsDialog';
import ChatThemeProvider from '@/providers/ChatThemeProvider';

const ChatFontSettingsDialogEx = (
  props: Omit<ChatFontSettingsDialogProps, 'open'> & { isOpenKey: string }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  return (
    <ChatThemeProvider>
      <ChatFontSettingsDialog open={isOpen} {...props} />
    </ChatThemeProvider>
  );
};

const useChatFontSettingsDialog = () => {
  const openAwaiter = useOpenAwaiter<number | undefined>();
  return {
    open: (props: Omit<ChatFontSettingsDialogProps, 'open' | 'onClose'>) =>
      openAwaiter.open(
        <ChatFontSettingsDialogEx
          isOpenKey={openAwaiter.key}
          onClose={openAwaiter.close}
          {...props}
        />
      ),
  };
};
export default useChatFontSettingsDialog;
