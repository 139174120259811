import { useNavigate } from 'react-router-dom';

import { useUserPermission } from './useUserPermission';

export const useCheckPermission = (required: {
  superAdmin?: boolean;
  ownerCompanyAdmin?: boolean;
  tenantCompanyAdmin?: boolean;
}) => {
  const userPermission = useUserPermission();
  const navigate = useNavigate();

  if (userPermission.cognitoUserName === '') {
    if (required.tenantCompanyAdmin) {
      navigate('/tenantLogin');
    } else if (required.ownerCompanyAdmin || required.superAdmin) {
      navigate('/adminLogin');
    }

    return userPermission;
  }

  //   cognitoUserNameが空文字でないかつ権限がない場合は、Forbidden（専用ページ）に飛ばす。
  if (
    (required.tenantCompanyAdmin && userPermission.isTenantCompanyAdmin) ||
    (required.ownerCompanyAdmin && userPermission.isOwnerCompanyAdmin) ||
    (required.superAdmin && userPermission.isSuperAdmin)
  ) {
    // do nothing
  } else {
    navigate('/forbidden');
  }
  return userPermission;
};
