/* eslint-disable no-irregular-whitespace */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { useState } from 'react';

export type ConfirmPolicyDialogProps = {
  open: boolean;
  onClose: (value: boolean) => void;
};

const ConfirmPolicyDialog = (props: ConfirmPolicyDialogProps) => {
  const [language, setLanguage] = useState<'ja' | 'en'>('ja');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [policyAgreed, setPolicyAgreed] = useState(false);

  return (
    <Dialog open={props.open} fullScreen>
      <DialogContent dividers>
        <Stack spacing={4} width={1} alignItems="center">
          <ToggleButtonGroup
            color="primary"
            value={language}
            exclusive
            onChange={(_, value: 'ja' | 'en') => setLanguage(value)}
          >
            <ToggleButton value="ja">日本語</ToggleButton>
            <ToggleButton value="en">English</ToggleButton>
          </ToggleButtonGroup>

          <Stack width={1} spacing={1}>
            <Typography variant="h4">
              {language === 'ja' ? '利用規約' : 'Terms of Service'}
            </Typography>
            <Box>
              {language === 'ja' && (
                <>
                  <Typography>
                    本実証実験（以下、「本実証」という）は、TOPPAN株式会社が翻訳アプリ「RemoteVoice」のサービス向上を目指して、ガイドツアーでのサービス価値検証を行うための実証実験を実施し、アンケートによるご意見の収集を目的として実施しています。
                  </Typography>
                  <br />
                  <Typography>【個人情報の取り扱い】</Typography>
                  <Typography>
                    〇TOPPAN株式会社が、ガイドツアーによる価値検証の実証実験実施に利用する目的と
                    して、本実証に参加される方の情報（アンケート結果、および、実証実験に参加される
                    日時によっては、本実証への参加風景を撮影させていただくことがあります。※）を
                    取得します。※お顔が映らない形で撮影を行います。
                  </Typography>
                  <Typography>
                    〇また、取得した情報は第三者へ開示・提供することはありません。
                  </Typography>
                  <Typography>
                    〇取得した会話ログ、アンケート結果は、本実証終了後、実証の目的達成のための分析や成果物の作成が完了次第、破棄します。
                  </Typography>
                  <br />
                  <Typography>●ご同意事項について</Typography>
                  <Typography>
                    ☐　撮影した画像については、ご本人が特定できないように画像加工し、本実証の成果報告資料に使用させていただく場合があること
                  </Typography>
                  <Typography>
                    ☐　運営スタッフの指示に従わない場合や、運営スタッフが安全に操作できないと判断した場合は、本実証へ参加できないこと
                  </Typography>
                  <Typography>
                    ☐　本実証時に、運営スタッフ側から提示される資料または指示・注意事項に従わずに事故やケガ等が発生した際は、ご本人の責任となること
                  </Typography>
                  <Typography>
                    ☐　万が一、備品を故意に破損・紛失した場合は弁済金を請求する場合があること
                  </Typography>
                  <Typography>
                    ☐　本実証は予期せぬトラブル等により、中止となる場合があること
                  </Typography>
                </>
              )}
              {language === 'en' && (
                <>
                  <Typography>
                    This demonstration experiment (hereinafter referred to as
                    the “demonstration”) is performed by TOPPAN Inc. to verify
                    the service value of the translation application
                    “RemoteVoice” in guided tours and to collect customer
                    feedback through a survey, for the purpose of improving the
                    service quality of the application.
                  </Typography>
                  <br />
                  <Typography>[Handling of personal information]</Typography>
                  <Typography>
                    ○ TOPPAN Inc. will obtain information (feedback survey
                    results) from participants in the demonstration, for the
                    purpose of using the information in performing the
                    demonstration to verify the value of the application in
                    guided tours. Depending on the date and time of the
                    demonstration, we may also take pictures depicting
                    participation in the demonstration.* * The faces of
                    participants will not be shown in pictures if they are
                    taken.
                  </Typography>
                  <Typography>
                    ○ The information obtained will neither be disclosed nor
                    provided to third parties.
                  </Typography>
                  <Typography>
                    ○ The conversation logs and feedback survey results obtained
                    will be disposed of as soon as the analysis and the creation
                    of deliverables for achieving the purpose of the
                    demonstration are completed after the demonstration is
                    finished.
                  </Typography>
                  <br />
                  <Typography>● Please consent to the following:</Typography>
                  <Typography>
                    ☐ We may edit the pictures we take (if any) so that
                    participants cannot be identified and use the edited
                    pictures in our result report for the demonstration.
                  </Typography>
                  <Typography>
                    ☐ If you do not follow the instructions of the organizing
                    staff, or if the organizing staff decides it cannot operate
                    the application safely, you may not be able to participate
                    in the demonstration.
                  </Typography>
                  <Typography>
                    ☐ If, during the demonstration, you do not follow the
                    information, instructions, and/or precautions provided by
                    the organizing staff and that results in an accident or
                    injury, it will be your responsibility.
                  </Typography>
                  <Typography>
                    ☐ If you intentionally damage or lose the equipment, you may
                    be requested to pay for the damage or loss.
                  </Typography>
                  <Typography>
                    ☐ The demonstration may be canceled due to an event such as
                    unexpected trouble.
                  </Typography>
                </>
              )}
            </Box>
          </Stack>

          <Stack width={1} spacing={1}>
            <Typography variant="h4">
              {language === 'ja' ? 'プライバシーポリシー' : 'Privacy Policy'}
            </Typography>
            <Box>
              {language === 'ja' && (
                <>
                  <Typography>
                    「RemoteVoice」は、TOPPAN株式会社（以下当社とします）の運営するサービスです。本実証実験では、個人情報を取得いたしますが、当社はお客様の個人情報の取扱いに際し、関係する法令その他各種規範を遵守いたします。
                  </Typography>
                  <br />
                  <Typography>１． 個人情報の安全な管理について</Typography>
                  <Typography>
                    当社は、お客様から取得した個人情報を厳重に管理・保護いたします。不正なアクセスまたは紛失、破壊、改ざん、漏えい等から個人情報を保護するために、社内規程類に基づき、以下の安全管理のための措置を講ずるとともに、万一問題が発生した場合には適切かつ速やかに対処いたします。
                  </Typography>
                  <Typography>（基本方針の策定）</Typography>
                  <Typography>
                    当社では個人情報の保護が、個人の権利を全うするため、さらにはお客様の信頼に応えるための、経営上の重要課題であることを、「TOPPAN株式会社
                    個人情報保護方針」として定め、全部門に徹底します。
                  </Typography>
                  <Typography>
                    （個人データの取扱いに係わる規律の整備）
                  </Typography>
                  <Typography>
                    当社が取得する個人情報を、確実に受領・取得、処理・加工、返却・納品・廃棄するための役員および従業員等の遵守事項を確実にすることを目的として、個人データの取扱に関するルールを定めています。
                  </Typography>
                  <Typography>（組織的安全管理措置）</Typography>
                  <Typography>
                    当社では個人情報保護管理者を定めるとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱ルールに違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。また、個人データの取扱い状況について、定期的に自己点検および内部監査の実施や、外部の監査を受けています。
                  </Typography>
                  <Typography>（人的安全管理措置）</Typography>
                  <Typography>
                    当社では個人データの取扱いに関する留意事項について、役員および従業員等に定期的な教育を実施するとともに、個人データについての秘密保持に関する誓約を取得し、これらに違反した場合の罰則規定を就業規則に定めています。
                  </Typography>
                  <Typography>（物理的安全管理措置）</Typography>
                  <Typography>
                    当社では個人データを取り扱う区域を定め、役員および従業員等の入退室管理および持ち込む機器の制限を実施するとともに、権限を有しない者が個人データを閲覧することができない措置を実施しています。また、個人データを扱う機器の持ち出しや紛失、盗難を防止するための措置、電子媒体の持ち込み制限を実施するとともに、容易に個人データが判明しないよう措置を実施しています。
                  </Typography>
                  <Typography>（技術的安全管理措置）</Typography>
                  <Typography>
                    当社では個人データを扱う情報システムは外部からの不正アクセスの防止や、不正ソフトウェアから保護する仕組みを導入し、アクセス制御により、個人データの取扱い担当者および個人情報データベース等の範囲を定めています。
                  </Typography>
                  <br />
                  <Typography>
                    ２． 取得する個人情報の利用目的について
                  </Typography>
                  <Typography>
                    本実証実験で取得する個人情報および利用目的は下記となります。
                  </Typography>
                  <Typography>【撮影させていただく実証風景の写真】</Typography>
                  <Typography>
                    （１）自動翻訳サービスの機能向上のため
                  </Typography>
                  <Typography>
                    （２）実証実験の成果報告書に使用するため
                  </Typography>
                  <Typography>
                    ※使用する際には、個人が特定できないようにモザイク処理を行います。
                  </Typography>
                  <br />
                  <Typography>３． 任意性</Typography>
                  <Typography>
                    個人情報の提供は任意ですが、ご提供いただけない個人情報がある場合は、事前にスタッフへ申告してください。※撮影禁止であることが明示されたネックストラップをお渡しいたします。
                  </Typography>
                  <br />
                  <Typography>４． 個人情報の第三者への提供について</Typography>
                  <Typography>
                    当社が保有する個人データは、原則として以下に掲げる場合を除き、第三者に提供することはありません。それ以外で第三者に提供する必要が生じた場合には、電子メール等での送信により、その旨を通知し、同意をいただきます。
                  </Typography>
                  <Typography>
                    （１） お客様から予め同意をいただいている場合
                  </Typography>
                  <Typography>
                    （２）
                    ご本人様または他の第三者の生命、身体又は財産の保護のため、公衆衛生の向上又は児童の健全な育成の推進のため必要であって、お客様の同意を取るのが困難である場合
                  </Typography>
                  <Typography>
                    （３）
                    国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                  </Typography>
                  <Typography>
                    （４） 法的な命令等により個人情報の開示が求められた場合
                  </Typography>
                  <br />
                  <Typography>５． 個人情報の取扱いの委託について</Typography>
                  <Typography>
                    本サービスのシステム運営を委託する業者、商品の発送を行う業者など、２．の利用目的の達成に必要な限度において、個人情報の取扱いを外部業者に委託することがあります。その場合には、当該外部業者と個人情報の取扱いに関する契約を締結し、個人情報の漏えい等が起きないよう委託先の監督をいたします。
                  </Typography>
                  <br />
                  <Typography>
                    ６． 個人情報の開示・訂正・削除・利用停止等への対応について
                  </Typography>
                  <Typography>
                    当社は、お客様から取得した個人情報（開示対象個人情報）に関して、ご本人よりご自身の個人情報の利用目的の通知、開示、内容の訂正・追加または削除、利用の停止、消去および第三者への提供の停止（「開示等」といいます。）についてお問い合わせをいただいた場合には、ご本人のご意思を尊重し、適切かつ、遅滞なく対処いたします。（ただし、サービスの運営上個人情報が不可欠であるため、個人情報の修正・更新または削除の結果、やむを得ず当社サービスの一部または全てにつきご利用が出来なくなることがありますので、ご了承ください。）開示等の請求は、以下で受け付けております。
                  </Typography>
                  <Typography>
                    　開示等のお問い合わせ先：akira_1.ishii@toppan.co.jp
                  </Typography>
                  <Typography>
                    　開示等をお問い合わせ時にご提出いただきたい情報：実証実験への参加日
                  </Typography>
                  <br />
                  <Typography>
                    ７． 個人情報の取扱いに関する問い合わせ
                  </Typography>
                  <Typography>
                    受付時間10:00～17:00（土・日・祝・当社休業日を除く）
                  </Typography>
                  <Typography>
                    個人情報に関するお問い合わせは、「個人情報の取扱いについて」をお読みいただき、同意の上、下記連絡先にご連絡ください。
                  </Typography>
                  <Box border="1px solid black" padding={0.5}>
                    <Typography>【個人情報に関するお問い合わせ先】</Typography>
                    <Typography>
                      TOPPAN株式会社　情報コミュニケーション事業本部リスクマネジメント本部
                    </Typography>
                    <Typography>情報セキュリティ管理責任者</Typography>
                    <Typography>tpjkm_privacy@toppan.co.jp</Typography>
                  </Box>
                  <br />
                  <Typography>
                    なお、当社は下記の認定個人情報保護団体の対象事業者です。当社の個人情報の取扱いに関する苦情については、下記へお申し出いただくこともできます。
                  </Typography>
                  <Box border="1px solid black" padding={0.5}>
                    <Typography>
                      一般財団法人日本情報経済社会推進協会
                      　認定個人情報保護団体事務局
                    </Typography>
                    <Typography>
                      ＜住　　所＞〒106-0032 東京都港区六本木1-9-9
                      六本木ファーストビル
                    </Typography>
                    <Typography>＜電話番号＞0120-700-779</Typography>
                  </Box>
                  <br />
                  <Typography>
                    ８． 個人情報取扱事業者としての表示について
                  </Typography>
                  <Typography>
                    当社の名称、住所および代表者の氏名に関しては、当社コーポレートサイトの「会社概要」ページ（
                    <a href="https://www.toppan.com/ja/about-us/overview.html">
                      https://www.toppan.com/ja/about-us/overview.html
                    </a>
                    ）から確認できます。
                  </Typography>
                </>
              )}
              {language === 'en' && (
                <>
                  <Typography>
                    RemoteVoice is a service operated by TOPPAN Inc.
                    (hereinafter referred to as “TOPPAN”). Although personal
                    information will be obtained during the course of the
                    demonstration experiment, TOPPAN will abide by relevant laws
                    and regulations and other norms when handling customer
                    personal information.
                  </Typography>
                  <br />
                  <Typography>
                    １． Safe management of personal information
                  </Typography>
                  <Typography>
                    TOPPAN shall rigorously manage and protect personal
                    information obtained from customers. To protect personal
                    information from unauthorized access, loss, corruption,
                    tampering, leaks, etc., TOPPAN will implement the following
                    safe management measures based on in-house regulations, and
                    appropriately and promptly remedy the situation should any
                    problems occur:
                  </Typography>
                  <Typography>Establishment of the Basic Policy</Typography>
                  <Typography>
                    Because protection of personal information ensures the
                    fulfillment of personal rights, TOPPAN views this duty as an
                    important facet of its business. To live up to the trust
                    placed in it by customers, TOPPAN has established the TOPPAN
                    Inc. Personal Information Protection Policy, which is
                    thoroughly enforced across all departments.
                  </Typography>
                  <Typography>
                    Establishment of personal data handling rules
                  </Typography>
                  <Typography>
                    TOPPAN has established personal data handling rules for the
                    board members and employees, ensuring that personal
                    information TOPPAN has obtained is properly received,
                    gathered, processed, utilized, returned, delivered, and
                    disposed of.
                  </Typography>
                  <Typography>Systematic safe management measures</Typography>
                  <Typography>
                    TOPPAN has appointed a personal information protection
                    administrator, identified which employees will handle
                    personal data and the scope of such handling, as well as
                    establishing a framework for reporting to managers any
                    actual or indicated violations of laws or handling rules.
                    Self-inspections, internal and external audits are also
                    conducted regularly for the status of personal data
                    handling.
                  </Typography>
                  <Typography>Human safe management measures</Typography>
                  <Typography>
                    Regular training sessions that cover points of concern in
                    handling personal data are held for the board members and
                    employees. Staff also sign personal data nondisclosure
                    pledges and are subject to punitive measures established in
                    work regulations in case of any violations. Physical safe
                    management measures
                  </Typography>
                  <Typography>Physical safe management measures</Typography>
                  <Typography>
                    TOPPAN has established a specific area for handling personal
                    data, controlling access of both board members and
                    employees, limiting what devices are allowed in the area,
                    and allowing only authorized individuals to view personal
                    data. Measures are also in place to prevent the removal,
                    loss, and theft of equipment or devices that handle personal
                    data, as well as limiting what electronic media is allowed
                    in the personal data handling area, and other measures that
                    make it difficult to identify personal data.
                  </Typography>
                  <Typography>
                    Technological safe management measures
                  </Typography>
                  <Typography>
                    TOPPAN has introduced a framework to protect information
                    systems that handle personal data from outside unauthorized
                    access and from unauthorized software, and has appointed
                    persons in charge of personal data handling and established
                    the scope of personal information databases, etc. by
                    implementing access control.
                  </Typography>
                  <br />
                  <Typography>
                    ２． Purpose of using personal information obtained
                  </Typography>
                  <Typography>
                    The personal information obtained in this demonstration
                    experiment and the purpose of using the information are
                    detailed below.
                  </Typography>
                  <Typography>
                    [Photographs of demonstration scenery]
                  </Typography>
                  <Typography>
                    (1) For improvement in automatic translation service
                    functionality
                  </Typography>
                  <Typography>
                    (2) For use in demonstration experiment reports
                  </Typography>
                  <Typography>
                    Note: When photographs are used, individuals will be blurred
                    so that they cannot be identified.
                  </Typography>
                  <br />
                  <Typography>３． Voluntary</Typography>
                  <Typography>
                    Provision of personal information is voluntary, however, if
                    there is some information you do not wish to provide, please
                    let staff know in advance. Note that neck straps which show
                    that photography is prohibited will be provided.
                  </Typography>
                  <br />
                  <Typography>
                    ４． Provision of personal information to third parties
                  </Typography>
                  <Typography>
                    Excluding cases below, personal information in the
                    possession of TOPPAN will not be provided to third parties
                    in principle. If it becomes necessary to provide personal
                    information to third parties, excluding the cases below,
                    TOPPAN will notify customers of that effect via email or
                    other means and ask for their permission.
                  </Typography>
                  <Typography>
                    （１） When customers provide advance consent
                  </Typography>
                  <Typography>
                    （２） If it is difficult to obtain the customer’s consent
                    when necessary to protect the life, body, and property of
                    the individual or other third parties, or when necessary to
                    promote public hygiene or the healthy upbringing of children
                  </Typography>
                  <Typography>
                    （３） When necessary to assist in the execution of
                    legally-mandated duties by state organs, local governments,
                    or entities entrusted with such duties, and obtaining the
                    consent of the individual may hinder the execution of said
                    duties
                  </Typography>
                  <Typography>
                    （４） When disclosure of personal information is demanded
                    by legal order
                  </Typography>
                  <br />
                  <Typography>
                    ５． Outsourcing the handling of personal information
                  </Typography>
                  <Typography>
                    To the extent necessary to fulfill the purposes explained in
                    item 2 above, the handling of personal information may be
                    outsourced to outside contractors, including vendors who
                    operate the systems of this service and vendors sending
                    products. When doing so, an agreement on the handling of
                    personal information will be concluded with the contractors
                    concerned, and TOPPAN will monitor them to ensure that
                    leaks, etc. of personal information do not occur.
                  </Typography>
                  <br />
                  <Typography>
                    ６． Disclosure, amendment, deletion, and suspension of use
                    of personal information
                  </Typography>
                  <Typography>
                    When receiving a request from the individual regarding
                    notification of the purpose of use, disclosure, amendment,
                    addition or deletion of content, suspension of use, or
                    suspension of provision to third parties (hereinafter
                    referred to as “disclosure, etc.”) of personal information
                    obtained from the customer (personal information subject to
                    disclosure), TOPPAN shall respect the individual’s wishes,
                    abiding by their request appropriately and without delay.
                    (However, because personal information is essential for
                    service operation, any amendment, updating, or deletion of
                    personal information may render its services unavailable in
                    whole or in part. Thank you for understanding.) Please
                    direct inquiries on disclosure, etc. to the following email
                    address:
                  </Typography>
                  <Typography>
                    　Inquiries on disclosure, etc.:akira_1.ishii@toppan.co.jp
                  </Typography>
                  <Typography>
                    　Information to be provided when inquiring about
                    disclosure, etc.: Participation date in the demonstration
                    experiment
                  </Typography>
                  <br />
                  <Typography>
                    ７． Inquiries on the handling of personal information
                  </Typography>
                  <Typography>
                    Hours: 10:00 a.m. - 5:00 p.m. (excluding weekends, national
                    holidays, and company closures)
                  </Typography>
                  <Typography>
                    Before inquiring about personal information, please read
                    “Handling of Personal Information” and then contact the
                    relevant department below if you agree to the terms.
                  </Typography>
                  <Box border="1px solid black" padding={0.5}>
                    <Typography>[Personal information inquiries]</Typography>
                    <Typography>
                      Information Security Manager Risk Management Division
                    </Typography>
                    <Typography>
                      Information & Communication Division
                    </Typography>
                    <Typography>TOPPAN Inc.</Typography>
                    <Typography>tpjkm_privacy@toppan.co.jp</Typography>
                  </Box>
                  <br />
                  <Typography>
                    TOPPAN falls under the category of accredited personal
                    protection information organization according to the
                    following organization. Please contact the following
                    organization with any complaints about its handling of
                    personal information:
                  </Typography>
                  <Box border="1px solid black" padding={0.5}>
                    <Typography>
                      Personal Information Protection Consultation Service
                      Office, Japan Institute for Promotion of Digital Economy
                      and Community
                    </Typography>
                    <Typography>
                      Address: Roppongi First Building, 1-9-9 Roppongi,
                      Minato-ku, Tokyo, 106-0032
                    </Typography>
                    <Typography>Phone: 0120-700-779</Typography>
                  </Box>
                  <br />
                  <Typography>
                    ８． Notice of a business that handles personal information
                  </Typography>
                  <Typography>
                    Information on its company name, address, and names of
                    representatives can be found in the “About Us” section of
                    its corporate site (
                    <a href="https://www.toppan.com/ja/about-us/overview.html">
                      https://www.toppan.com/ja/about-us/overview.html
                    </a>
                    ).
                  </Typography>
                </>
              )}
            </Box>
          </Stack>

          <Stack width={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={termsAgreed}
                    onChange={(_, checked) => setTermsAgreed(checked)}
                  />
                }
                label={language === 'ja' ? '利用規約' : 'Terms of Service'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={policyAgreed}
                    onChange={(_, checked) => setPolicyAgreed(checked)}
                  />
                }
                label={
                  language === 'ja' ? 'プライバシーポリシー' : 'Privacy Policy'
                }
              />
            </FormGroup>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          disabled={termsAgreed === false || policyAgreed === false}
          onClick={() => props.onClose(true)}
        >
          {language === 'ja' ? '同意する' : 'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmPolicyDialog;
