import { useOpenableElementIsOpen } from 'src/components/hooks/useOpenableElement';
import useOpenAwaiter from 'src/components/hooks/useOpenAwaiter';

import OnboardingDialog, {
  OnboardingDialogProps,
} from '@/components/organisms/onboardingDialog/OnboardingDialog';

const OnboardingDialogEx = (
  props: Omit<OnboardingDialogProps, 'open'> & {
    isOpenKey: string;
    container?: (children: React.ReactElement) => React.ReactElement;
  }
) => {
  const isOpen = useOpenableElementIsOpen(props.isOpenKey);
  const { container, ...otherProps } = props;
  return container ? (
    container(<OnboardingDialog open={isOpen} {...otherProps} />)
  ) : (
    <OnboardingDialog open={isOpen} {...otherProps} />
  );
};

const useOnboardingDialog = () => {
  const openAwaiter = useOpenAwaiter<boolean>();
  return {
    open: async (
      props: Omit<OnboardingDialogProps, 'open' | 'onClose'>,
      container?: (children: React.ReactElement) => React.ReactElement //テーマを渡せるようにする
    ) => {
      await openAwaiter.open(
        <OnboardingDialogEx
          isOpenKey={openAwaiter.key}
          onClose={() => openAwaiter.close(true)}
          container={container}
          {...props}
        />
      );
    },
  };
};
export default useOnboardingDialog;
