// 現在のところモック実装です

import { joiResolver } from '@hookform/resolvers/joi';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from '@/components/atoms/Logo';
import { PasswordField } from '@/components/atoms/PasswordField';
import useLocalizedJoi from '@/components/hooks/useLocalizedJoi';

type UserPasswordData = {
  userName: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const ResetPassword = () => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();
  const width = 240;

  const joi = useLocalizedJoi();
  const form = useForm<UserPasswordData>({
    resolver: joiResolver(
      joi.object<UserPasswordData>({
        userName: joi.string().trim().required(),
        newPassword: joi.string().required(),
        newPasswordConfirm: joi.string().required(),
      })
    ),
  });

  const [sendedPassword, setSendedPassword] = useState(false);

  return (
    <Box sx={{ w: 1, h: 1, bgcolor: (t) => t.palette.background.default }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        component="form"
        onSubmit={form.handleSubmit(async (input, ev) => {
          ev?.preventDefault();
          // APIを叩く
          console.debug('send email address', {
            userName: input.userName,
            newPassword: input.newPassword,
            newPasswordConfirm: input.newPasswordConfirm,
          });
        })}
      >
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Stack alignItems="center" width={1}>
            <Logo />
          </Stack>
          <Stack
            alignItems="center"
            spacing={2}
            sx={{ bgcolor: (t) => t.palette.background.paper }}
            py={4}
            px={3}
            minWidth={400}
          >
            {sendedPassword === false ? (
              <>
                <Typography variant="h6">
                  {t('myPasswordReset.title')}
                </Typography>
                <TextField
                  fullWidth
                  label={t('myPasswordReset.userName')}
                  {...form.register('userName')}
                  error={'userName' in form.formState.errors}
                  helperText={form.formState.errors.userName?.message}
                  autoComplete="off"
                  sx={{ width }}
                />
                <PasswordField
                  label={t('myPasswordReset.newPassword')}
                  {...form.register('newPassword')}
                  error={'newPassword' in form.formState.errors}
                  helperText={form.formState.errors.newPassword?.message}
                  autoComplete="new-password"
                  sx={{ width }}
                />
                <PasswordField
                  label={t('myPasswordReset.newPasswordConfirm')}
                  {...form.register('newPasswordConfirm')}
                  error={'newPasswordConfirm' in form.formState.errors}
                  helperText={form.formState.errors.newPasswordConfirm?.message}
                  autoComplete="new-password"
                  sx={{ width }}
                />
                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  disabled={
                    (form.watch('userName') ?? '') == '' ||
                    (form.watch('newPassword') ?? '') == '' ||
                    (form.watch('newPasswordConfirm') ?? '') == ''
                  }
                  sx={{ width }}
                  onClick={() => {
                    setSendedPassword(true);
                  }}
                >
                  {t('myPasswordReset.resetPassword')}
                </Button>
              </>
            ) : (
              <>
                <Stack direction="row" spacing={1}>
                  <CheckCircleOutlineIcon color="success" />
                  <Typography variant="h6">
                    {t('myPasswordReset.resetPasswordSuccess')}
                  </Typography>
                </Stack>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {t('myPasswordReset.resetPasswordSuccessCaption')}
                  </Typography>
                </Stack>
                <Stack alignItems="center" width={1}>
                  <Button
                    variant="outlined"
                    // size="small"
                    sx={{ px: 2 }}
                    onClick={() => {
                      navigate('/adminLogin');
                    }}
                  >
                    {t('myPasswordReset.backToLogin')}
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        <Box sx={{ mt: 'auto', mb: 2, position: 'relative', bottom: 0 }}>
          <Typography variant="caption" color={(t) => t.palette.grey[500]}>
            Copyright © 2023 TOPPAN INC.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
export default ResetPassword;
