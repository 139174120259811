import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StopIcon from '@mui/icons-material/Stop';
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeyupListener } from '@/components/hooks/useKeyupListener';
import HintBalloon from '@/components/molecules/HintBalloon';

const micSpeechHintEnabledAtom = atomWithStorage<boolean>(
  'MIC_SPEECH_HINT_ENABLED',
  true
);

const boxShadow = '0px 0px 6px rgba(0, 0, 0, 0.2)';

const ChatFooterMic = (props: {
  canUseMic: boolean;
  isReadyMic: boolean;
  isSimulteneous: boolean; // 同通
  isRecording: boolean;
  onSendImage: () => void;
  onClickTemplateMessage?: () => void;
  onChangeToKeyboard: () => void;
  onMicStart: () => void;
  onMicEnd: () => void;
  onMicCancel: () => void;
}) => {
  const { t } = useTranslation('chat');
  const [hintAnchor, setHintAnchor] = useState<HTMLElement | null>(null);

  const [micSpeechHintEnabled, setMicSpeechHintEnabled] = useAtom(
    micSpeechHintEnabledAtom
  );
  const keepIsRecording = useRef(false);
  useEffect(() => {
    if (props.isRecording === false && keepIsRecording.current === true) {
      // 有効から無効に切り替わった場合はヒント表示を終了する
      setMicSpeechHintEnabled(false);
    }
    keepIsRecording.current = props.isRecording;
  }, [props.isRecording, setMicSpeechHintEnabled]);

  // キー操作をリッスン
  const onMicStart = props.onMicStart;
  const onMicEnd = props.onMicEnd;
  useKeyupListener(
    useMemo(() => ['ArrowDown'], []),
    useCallback(() => {
      if (props.isRecording) onMicEnd();
      else onMicStart();
    }, [onMicEnd, onMicStart, props.isRecording])
  );

  return (
    <Box
      width={1}
      height="74px"
      borderRadius="28px 28px 0px 0px"
      boxShadow={boxShadow}
      zIndex={2}
      bgcolor={'white'}
    >
      <Box
        // 丸影
        sx={{
          background: 'white',
          borderRadius: 100,
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -22px)',
          width: '74px',
          height: '74px',
          display: 'flex',
          boxShadow: boxShadow,
          zIndex: 1,
        }}
      />
      <Box
        // 影隠し
        sx={{
          background: 'white',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0px)',
          zIndex: 2,
          height: '54px',
          width: '100px',
        }}
      />

      {
        //   // 同通がスイッチ切り替えだった時のUI
        //   props.isSimulteneous && props.canUseMic === true ? (
        //   <>
        //     <Box
        //       // マイクの絵
        //       sx={{
        //         background: 'white',
        //         borderRadius: 100,
        //         position: 'absolute',
        //         left: '50%',
        //         transform: 'translate(-50%, -16px)',
        //         width: '62px',
        //         height: '62px',
        //         display: 'flex',
        //         zIndex: 3,
        //         cursor: 'pointer',
        //       }}
        //     >
        //       {props.isRecording ? (
        //         <MicIcon
        //           sx={{
        //             fontSize: '32px',
        //             position: 'absolute',
        //             left: '50%',
        //             top: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             color: (t) => t.palette.primary.main,
        //           }}
        //           onClick={() => props.onMicEnd()}
        //         />
        //       ) : (
        //         <MicOffIcon
        //           sx={{
        //             fontSize: '32px',
        //             position: 'absolute',
        //             left: '50%',
        //             top: '50%',
        //             transform: 'translate(-50%, -50%)',
        //             color: (t) => t.palette.grey[400],
        //           }}
        //           onClick={() => props.onMicStart()}
        //         />
        //       )}
        //       {props.isReadyMic === false && (
        //         <CircularProgress
        //           size={38}
        //           sx={{
        //             position: 'absolute',
        //             left: '12px',
        //             top: '10px',
        //             transform: 'translate(-50%, -50%)',
        //           }}
        //         />
        //       )}
        //     </Box>

        //     <FormControlLabel
        //       label={<Typography variant="body2">{t('alwaysMicOn')}</Typography>}
        //       // labelPlacement="end"
        //       checked={props.isRecording}
        //       disabled={props.isReadyMic === false}
        //       control={
        //         <Switch
        //           size="small"
        //           checked={props.isRecording}
        //           onChange={(ev) =>
        //             ev.target.checked ? props.onMicStart() : props.onMicEnd()
        //           }
        //         />
        //       }
        //       sx={{
        //         position: 'absolute',
        //         left: '50%',
        //         transform: 'translate(-50%, 30px)',
        //         zIndex: 3,
        //       }}
        //     />
        //   </>
        // ) : (
        <Fab
          ref={(v) => setHintAnchor(v)}
          onClick={() =>
            props.isRecording ? props.onMicEnd() : props.onMicStart()
          }
          disabled={props.isReadyMic === false || props.canUseMic === false}
          size="large"
          color="primary"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -16px)',
            width: '62px',
            height: '62px',
            boxShadow: 0,
            ...(props.isRecording
              ? {
                  border: 2,
                  background: (t) => t.palette.background.default,
                  borderColor: (t) => t.palette.primary.main,
                  color: (t) => t.palette.primary.main,
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }
              : {}),

            // 録音時のブルーアニメーション
            animation:
              props.isRecording && props.isReadyMic
                ? 'width-increase 1s ease-in-out infinite'
                : undefined,
            '@keyframes width-increase': {
              '0%': {
                boxShadow: (t) =>
                  `0px 0px 0px 0px rgb(${t.palette.primary.light},0)`,
              },
              '50%': {
                boxShadow: (t) => `0px 0px 32px 8px ${t.palette.primary.light}`,
              },
              '100%': {
                boxShadow: (t) =>
                  `0px 0px 0px 0px rgb(${t.palette.primary.light},0)`,
              },
            },
          }}
        >
          {props.canUseMic ? (
            props.isSimulteneous ? (
              props.isRecording ? (
                <MicIcon sx={{ fontSize: '32px' }} />
              ) : (
                <MicIcon sx={{ fontSize: '32px' }} />
              )
            ) : props.isRecording ? (
              <StopIcon sx={{ fontSize: '32px' }} />
            ) : (
              <MicIcon sx={{ fontSize: '32px' }} />
            )
          ) : (
            <MicOffIcon sx={{ fontSize: '32px' }} />
          )}
          {props.isReadyMic === false && (
            <CircularProgress
              size={38}
              sx={{
                position: 'absolute',
                left: props.isRecording ? '10px' : '12px',
                top: props.isRecording ? '10px' : '12px',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          <Typography
            // マイクアイコン下のキャプション
            variant="body2"
            color="primary"
            fontWeight="bold"
            noWrap
            sx={{
              position: 'absolute',
              top: props.isRecording ? '62px' : '64px',
              textTransform: 'none',
            }}
          >
            {props.isSimulteneous
              ? props.isRecording
                ? t('stopSimulteneousCaption')
                : t('startSimulteneousCaption')
              : props.isRecording
              ? t('stopConsecutiveCaption')
              : t('startConsecutiveCaption')}
          </Typography>
        </Fab>
      }

      {props.isRecording ? (
        !props.isSimulteneous && (
          <IconButton
            // 録音中、右に表示されるキャンセル操作
            onClick={() => props.onMicCancel()}
            size="medium"
            sx={{
              position: 'absolute',
              right: '-16px',
              transform: 'translate(-50%, 2px)',
            }}
          >
            <CloseIcon />
          </IconButton>
        )
      ) : (
        <>
          {props.onClickTemplateMessage != null ? (
            <IconButton
              // 定型文ボタン
              onClick={() => props.onClickTemplateMessage?.()}
              size="large"
              sx={{
                position: 'absolute',
                left: '20%',
                transform: 'translate(-50%, 6px)',
                width: '60px',
                height: '60px',
              }}
            >
              <MenuBookIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          ) : (
            <IconButton
              // 画像送信ボタン
              onClick={() => props.onSendImage()}
              size="large"
              sx={{
                position: 'absolute',
                left: '20%',
                transform: 'translate(-50%, 6px)',
                width: '60px',
                height: '60px',
              }}
            >
              <ImageIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          )}

          <IconButton
            // 文字入力への切り替えボタン
            onClick={() => props.onChangeToKeyboard()}
            disabled={props.isReadyMic === false}
            size="large"
            sx={{
              position: 'absolute',
              left: '80%',
              transform: 'translate(-50%, 6px)',
              width: '60px',
              height: '60px',
            }}
          >
            <KeyboardIcon sx={{ fontSize: '30px' }} />
          </IconButton>
        </>
      )}
      {props.isRecording && (
        <HintBalloon
          anchorEl={micSpeechHintEnabled ? hintAnchor : null}
          caption={t('micSpeechHintMessage')}
          anchorPosition="CenterTop"
          onClose={() => setMicSpeechHintEnabled(false)}
          icon={<RecordVoiceOverIcon />}
        />
      )}
    </Box>
  );
};
export default ChatFooterMic;
